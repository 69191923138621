import { ArrayKeyValueObject, KeyValueObject } from "@/UI/Interfaces/IBaseUI";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import produce from "immer";
import { ComponentType, LazyExoticComponent } from "react";

export enum EHelpType {
  SideHelp = 1,
  DocumentHelp = 2,
  ShortHelp = 3,
  DimensionHelp = 4,
  Normal = 5
}

export enum EHelpOpenFor {
  Grid = 1,
  Field = 2,
  Document = 3,
}

export enum EHelpOpenIn {
  EntryForm = 1,
  DataAnalytics = 2,
  ReportExplorer = 3,
}


interface IHelpConfig {
  helpObject: string;
  helpobjectno: string;
  singlecolumn: string;
  multiplereturn: boolean;
  multiplecolumn: string;
}

export interface IHelpHeader {
  ColumnText: string;
  ColumnID: string;
  controltype: string;
  fielddataformatno: string;
  ColumnWidth: number;
  ishidden: boolean;
  hyperlinktransactioncode: string;
}

interface IHelpConfigObject {
  Config: IHelpConfig;
  Header: Array<IHelpHeader>;
  Detail: ArrayKeyValueObject;
  Criteria: Array<IHelpHeader>;
}

export interface IReturnParam {
  fieldId: string,
  uniqueKey?: string,
  gridno?: string,
  gridRowIndex?: number,
}

export interface IReturnValues {
  fieldId: string;
  fieldValue: string;
  dimensionValues?: KeyValueObject<string>;

}
export interface IGridView {
  filterPrid: number;
  filterApproval: boolean;
  setting: {
    filterDefinition: string,
    frozenColumns: number,
    columnIndex: string[],
    columnWidth: number[],
    columnHidden: string[]
  }
}

interface IShortHelpOptions {
  top: number,
  left: number,
  isOpen: boolean,
  searchText: string
}
interface IHelp {
  [tabKey: string]: {
    loadedHelp?: {
      helpConfig: IHelpConfigObject;
      criteria: {
        [key: string]: any;
      };
      gridView: IGridView;
      helpType: EHelpType;
      helpOpenFor: EHelpOpenFor;
      multiSelect: boolean;
      helpOpenIn: EHelpOpenIn;
      returnParam: IReturnParam;
      shortHelpOptions?: IShortHelpOptions,
      // returnValues?: Array<IReturnValues> | Array<Array<IReturnValues>> // for Grid;
      // isOpen: boolean;
      isSubGrid: boolean;
      isDropDown: boolean;
    },
    returnOption?: {
      returnParam: IReturnParam;
      returnValues?: IReturnValues[][]
    }

  };
}

export interface IGetHelp {
  helpObject: string;
  helpWhere: string;
  returnParams: IReturnParam;
  multiSelect?: boolean;
  isSubGrid?: boolean
  isDropDown?: boolean;
  helpType?: EHelpType;
  helpOpenIn?: EHelpOpenIn;
  dimensionValues?: KeyValueObject;
  isAccountDimension?: boolean;
  shortHelpOptions?: IShortHelpOptions,
}

const InitialState: IHelp = {};

const HelpSlice = createSlice({
  name: "help",
  initialState: InitialState,
  reducers: {

    getHelpConfig(state, action: PayloadAction<IGetHelp>) { },
    reloadDocumentHelpData(state, action: PayloadAction<{ objectId: string, filterApproval: boolean, filteredPrid: number }>) { },
    setHelpConfig(state, action: PayloadAction<{ config: IHelpConfigObject; tabKey: string, returnParams: IReturnParam, helpType: EHelpType, helpOpenFor: EHelpOpenFor, multiSelect: boolean, helpOpenIn: EHelpOpenIn, isSubGrid: boolean, isDropDown?: boolean, view?: IGridView, shortHelpOptions?: IShortHelpOptions }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        draftState[payload.tabKey] = {
          loadedHelp: {
            helpConfig: payload.config,
            gridView: payload.view || {
              filterPrid: 0,
              filterApproval: false,
              setting: {
                columnHidden: [],
                columnIndex: [],
                columnWidth: [],
                filterDefinition: "",
                frozenColumns: 0
              }
            },
            criteria: {},
            helpType: payload.helpType,
            helpOpenFor: payload.helpOpenFor,
            multiSelect: payload.multiSelect,
            helpOpenIn: payload.helpOpenIn,
            returnParam: payload.returnParams,
            // isOpen: true,
            isSubGrid: payload.isSubGrid,
            isDropDown: payload.isDropDown || false,
            shortHelpOptions: payload.shortHelpOptions || undefined,
          }
        };
      });
    },
    setHelpDetailData(state, action: PayloadAction<{ tabKey: string, data: ArrayKeyValueObject }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        const help = draftState[payload.tabKey].loadedHelp;
        if (help)
          help.helpConfig.Detail = payload.data;
      });

    },
    setGridView(state, action: PayloadAction<{ objectId: string, filterApproval?: boolean, setting?: IGridView["setting"], filterPrid?: number }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        const help = draftState[payload.objectId].loadedHelp;
        if (help) {
          help.gridView.filterPrid = payload.filterPrid || 0;

          if (payload.filterApproval != undefined)
            help.gridView.filterApproval = payload.filterApproval;
          if (payload.setting)
            help.gridView.setting = payload.setting;
        }
      });
    },

    setHelpReturnValue(state, action: PayloadAction<{ objectId: string, returnValue: IReturnValues[][], returnParam: IReturnParam }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        if (!draftState[payload.objectId])
          draftState[payload.objectId] = {};

        draftState[payload.objectId].returnOption = {
          returnParam: payload.returnParam,
          returnValues: payload.returnValue
        }

      });
    },
    closeHelp(state, action: PayloadAction<{ objectId: string }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        delete draftState[payload.objectId];
      });
    },
    setSearchText(state, action: PayloadAction<{ objectId: string, searchText: string }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        const activeHelp = draftState[payload.objectId]?.loadedHelp;

        if (activeHelp && activeHelp.shortHelpOptions) {
          activeHelp.shortHelpOptions.searchText = payload.searchText;
        }
      });
    }
  }
});

export const { getHelpConfig, reloadDocumentHelpData, setHelpConfig, setHelpDetailData, setGridView, setHelpReturnValue, closeHelp, setSearchText } = HelpSlice.actions;

export default HelpSlice.reducer;
