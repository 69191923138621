import { useEffect, useRef, useState } from 'react'
import { IFieldConfig } from "@/UI/Interfaces/IFieldConfig";
import { useAppDispatch, useAppSelector } from '@/Hook/hooks';
import { IFieldProps } from '@/UI/Interfaces/IComponent';
import { ComboBox } from '@grapecity/wijmo.react.input';
import { ClickAction } from '@grapecity/wijmo.input';
import { closeHelp, EHelpOpenFor, EHelpOpenIn, EHelpType, getHelpConfig, setSearchText } from '@/Redux/Ducks/helpSlice';
import classNames from 'classnames';
import { getScreenConfig } from '@/Redux/Ducks/entryFormSlice';
import { Icon } from 'AlignComponents';
import { requestGetFieldValueInformation } from '@/Redux/Sagas/Requests/others';
import { ArrayKeyValueObject } from '@/UI/Interfaces/IBaseUI';
import FieldValueInformation from './FieldValueInformation';
import { RiExternalLinkLine } from 'react-icons/ri';

const Field = ({ config, fieldValueChange, fieldFocusOut, uniqueKey, enable, value, validateMandatory, hyperLinkJump, hideLabel, customHelpParams }: IFieldProps) => {
  const { fieldid } = config;
  const dispatch = useAppDispatch();
  const currentValue = useRef<string>("");
  const hoverTimeout = useRef<any>();
  const fieldInformationHideTimeout = useRef<any>();
  const closeShortHelpTimeout = useRef<any>();
  const helpConfigRef = useRef<any>();
  const comboboxRef = useRef<any>();
  const valueRef = useRef(value);

  const [fieldIsInEditing, setFieldIsInEditing] = useState<boolean>(false);
  const [fieldInformation, setFieldInformation] = useState<ArrayKeyValueObject | null>(null);

  const objectId = useAppSelector((x) => x.EntryForm.activeObjectId);
  const dropDownConfig = useAppSelector((x) => {
    const activeHelp = x.Help[x.EntryForm.activeObjectId]?.loadedHelp;
    if (activeHelp) {
      if (activeHelp.helpOpenFor.isEqual(EHelpOpenFor.Field) && activeHelp.isDropDown && activeHelp.returnParam.fieldId == fieldid) {
        return activeHelp.helpConfig
      }
    }
    return null;
  });

  const helpConfig = useAppSelector((x) => {
    const activeHelp = x.Help[x.Tab.activeTabKey]?.loadedHelp;
    if (activeHelp) {
      if (activeHelp.helpType.isEqual(EHelpType.ShortHelp) && activeHelp.helpOpenFor.isEqual(EHelpOpenFor.Field) && !activeHelp.isDropDown) {
        return activeHelp;
      }
    }
    return null;
  });

  useEffect(() => {
    helpConfigRef.current = helpConfig;
  }, [helpConfig]);

  useEffect(() => {
    if (value === undefined) {
      callBaseMethod(null);
    }
    valueRef.current = value;
  }, [value]);

  const callBaseMethod = (changeValue: any) => {
    fieldValueChange(fieldid, changeValue || null);
  }

  const change = (e: any) => {
    currentValue.current = e.text;
    // dispatch(setSearchText({ objectId, searchText: e.text }))
  }

  const focus = (e: any) => {
    if (!e.isReadOnly) {
      currentValue.current = e.text;

      // if (e.text.length >= 1 && !helpConfigRef.current) {
      //   var node = e.inputElement;
      //   var top = (node.offsetHeight + node.getBoundingClientRect().top);
      //   var left = node.getBoundingClientRect().left;
      //   const customHelpParameters = Object.assign({ helpOpenIn: EHelpOpenIn.EntryForm }, customHelpParams);

      //   dispatch(
      //     getHelpConfig({
      //       helpObject: config.helpobjectno,
      //       helpWhere: config.helpwhere || '',
      //       multiSelect: false,
      //       returnParams: {
      //         fieldId: config.fieldid
      //       },
      //       helpType: EHelpType.ShortHelp,
      //       shortHelpOptions: { top, left, isOpen: true },
      //       ...customHelpParameters
      //     })
      //   );
      // }

      setFieldIsInEditing(true);
    }
  }

  const blur = (e: any) => {
    if (!e.isReadOnly) {

      if (e.text != valueRef.current) {
        callBaseMethod(e.text);
      }

      currentValue.current = "";
      setFieldIsInEditing(false);

      fieldFocusOut(fieldid, e.text);

      // closeShortHelp();
    }
  }

  const openScreen = () => {
    dispatch(getScreenConfig({ processCode: hyperLinkJump, transactionNo: valueRef.current }));
  }

  const fieldInitialized = (FieldControl: any) => {

    FieldControl.hostElement.parentElement.addEventListener("mouseenter", (e: any) => {
      clearHideTimeoutFieldInformationContainer();

      if (hyperLinkJump && FieldControl.text && !fieldInformation && config.processcode != hyperLinkJump) {
        hoverTimeout.current = setTimeout(async () => {
          FieldControl.hostElement.parentElement.style.cursor = "wait";

          var response = await requestGetFieldValueInformation(hyperLinkJump, FieldControl.text);
          if (response) {
            var data: ArrayKeyValueObject = [];

            Object.entries(response.data[0]).map(([key, value]) => {
              const fieldConfig = response.column.find((x: any) => x.objectno == key);
              data.push({
                label: fieldConfig.objectlabel,
                value: value
              });
            });

            setFieldInformation(data);

          }

          FieldControl.hostElement.parentElement.style.cursor = null;

        }, 800);
      }

    });

    FieldControl.hostElement.parentElement.addEventListener("mouseleave", (e: any) => {
      if (hoverTimeout.current)
        clearTimeout(hoverTimeout.current);

      hideFieldInformationContainer();
    });

    // FieldControl.hostElement.parentElement.addEventListener("keyup", (e: any) => {
    //   var node = e.target;
    //   var value = node.value;
    //   var top = (node.offsetHeight + node.getBoundingClientRect().top);
    //   var left = node.getBoundingClientRect().left;

    //   if (value.length >= 1 && !helpConfigRef.current) {
    //     const customHelpParameters = Object.assign({ helpOpenIn: EHelpOpenIn.EntryForm }, customHelpParams);

    //     dispatch(
    //       getHelpConfig({
    //         helpObject: config.helpobjectno,
    //         helpWhere: config.helpwhere || '',
    //         multiSelect: false,
    //         returnParams: {
    //           fieldId: config.fieldid
    //         },
    //         helpType: EHelpType.ShortHelp,
    //         shortHelpOptions: { top, left, isOpen: true, searchText: value },
    //         ...customHelpParameters
    //       })
    //     );
    //   }
    // });

  }

  const hideFieldInformationContainer = () => {
    fieldInformationHideTimeout.current = setTimeout(() => {
      setFieldInformation(null);
    }, 800);
  }

  const clearHideTimeoutFieldInformationContainer = () => {
    if (fieldInformationHideTimeout.current)
      clearTimeout(fieldInformationHideTimeout.current);
  }

  const closeShortHelp = () => {
    closeShortHelpTimeout.current = setTimeout(() => {
      let mouseTargets = document.querySelectorAll(":hover");

      // close on outside click
      document.addEventListener('mouseup', (e: any) => {
        var container: any = document.getElementById('short-help-container');

        if (!container.contains(e.target)) {
          dispatch(closeHelp({ objectId }))
        }
        else {
          clearShortHelpTimeout();
        }
      });

    }, 180);
  }

  const clearShortHelpTimeout = () => {
    if (closeShortHelpTimeout.current)
      clearTimeout(closeShortHelpTimeout.current);
  }

  let inputType = "";
  if (config.controltypeno.isEqual("TXT"))
    inputType = "text";
  else if (config.controltypeno.isEqual("PWD"))
    inputType = "password";
  else
    inputType = "hidden";


  const helpProps: any = {};

  if (config.isdropdownhelpobject) {
    helpProps.wjItemTemplate = (context: any) => {

      let header = <></>

      if (dropDownConfig) {
        if (context.itemIndex == 0) {
          header = (
            <table className="header">
              <tr>
                {
                  Object.keys(context.item).map((key: any, i) => {
                    const columnConfig = dropDownConfig.Header.find(y => y.ColumnID == key);
                    if (!columnConfig?.ishidden)
                      return <td key={key} style={key == "prid" ? { display: "none" } : {}} className={classNames({ "header-column": key != "prid" })}>{columnConfig?.ColumnText || key}</td>

                    return null;
                  })
                }
              </tr>
            </table>
          )
        }
        return (
          <>
            {header}
            <table>
              <tr>
                {
                  Object.entries(context.item).map(([key, value]: any) => {
                    const columnConfig = dropDownConfig.Header.find(y => y.ColumnID == key);
                    if (!columnConfig?.ishidden)
                      return <td key={key} style={key == "prid" ? { display: "none" } : {}}>{value}</td>

                    return null
                  })
                }
              </tr>
            </table>
          </>
        )
      }
    };

    if (dropDownConfig) {
      helpProps.itemsSource = dropDownConfig.Detail;

      helpProps.headerPath = dropDownConfig.Config.singlecolumn;
      helpProps.selectedValuePath = dropDownConfig.Config.singlecolumn;
      helpProps.displayMemberPath = dropDownConfig.Config.singlecolumn;
    }
    else {
      helpProps.itemsSource = [
        { key: value }
      ];
      helpProps.headerPath = "key";
      helpProps.selectedValuePath = "key";
      helpProps.displayMemberPath = "key";

    }

    helpProps.onIsDroppedDownChanged = (e: any) => {
      if (comboboxRef.current.control.isDroppedDown) {

        if (!dropDownConfig) {
          dispatch(
            getHelpConfig({
              helpObject: config.helpobjectno,
              helpWhere: config.helpwhere,
              helpOpenIn: EHelpOpenIn.EntryForm,
              multiSelect: false,
              returnParams: {
                fieldId: config.fieldid
              },
              isDropDown: true
            })
          );
        }

      }

    }
    helpProps.showDropDownButton = true;
    helpProps.isRequired = false;
    helpProps.ClickAction = ClickAction.Open;

  }

  if (!config) return <></>;
  const id = objectId + (uniqueKey ? `-${uniqueKey}` : "") + "-" + fieldid;


  let size = "";
  if (config.objectlength <= 6)
    size = "input-xsm";
  else if (config.objectlength >= 7 && config.objectlength <= 15)
    size = "input-sm";
  else if (config.objectlength >= 15 && config.objectlength <= 25)
    size = "input";
  else if (config.objectlength > 26)
    size = "input-lg";

  return (
    <>
      {
        !hideLabel ?
          <label id={"lb-" + id} htmlFor={"field-" + id} >
            {config.fieldlabel}
            {validateMandatory ? (<span>*</span>) : null}
            {hyperLinkJump && config.processcode != hyperLinkJump ? (<span className='hyperlink'><RiExternalLinkLine style={{ color: '#007db8', margin: '0px 3px' }} size="16" onClick={openScreen} /></span>) : null}
          </label>
          : null
      }

      <div className={classNames([{ "wj-popup": fieldInformation }, size, "input-container"])}>
        <ComboBox
          dropDownCssClass="wijmo-dropdown" ref={comboboxRef} initialized={fieldInitialized} type={inputType}
          isDisabled={!enable}
          id={"field-" + id} gotFocus={(e: any) => focus(e)} selectedValue={fieldIsInEditing ? currentValue.current : (value || "")} textChanged={(e: any) => change(e)} lostFocus={(e: any) => blur(e)}
          {...helpProps}
        />
      </div>
      <FieldValueInformation data={fieldInformation} hideFieldInformationContainer={hideFieldInformationContainer} clearHideTimeout={clearHideTimeoutFieldInformationContainer} />
    </>
  )

}





export default Field;

