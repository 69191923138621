import { Button, Container, Dropdown, Form, Row } from "react-bootstrap";
import Banner from './Banner'
import Announcement from './Announcement'
import UserAction from './UserAction'
import ActiveApprovals from "./ActiveApprovals";
import Workspace from "./Workspace"
import Dashboard from './Dashboard'
import classNames from "classnames";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { MdSave } from "react-icons/md";
import { Modal } from "AlignComponents";
interface IProps {
  refreshData: () => void,
  addWorkspaceModalState: boolean,
  toggleAddWorkspaceModal: (state: boolean) => void,
  workspaceName: string,
  handleWorkspaceNameChange: (e: any) => void,
  addWorkspaceFunc: () => void,
  validated: boolean,
  handleSubmit: (e: any) => void,
  hidden: Array<string>,
  toggleHiddenFunc: (object: string) => void,
  bannerHidden: boolean
}

export const AlignUIRender = ({ refreshData, addWorkspaceModalState, addWorkspaceFunc, toggleAddWorkspaceModal, workspaceName, handleWorkspaceNameChange, validated, handleSubmit, hidden, toggleHiddenFunc, bannerHidden }: IProps) => (
  <>
    <Container fluid className='home'>
      <Banner />
      <Row className="home-section">
        <UserAction />
        <ActiveApprovals />
        <Workspace />
      </Row>
      <Dashboard />
    </Container>
  </>
);
