import { Component } from 'react'
import { connect } from 'react-redux';

import { IUserResetPassword, resetUserPassword } from '@/Redux/Ducks/userSlice';
import { setGlobalMessage } from '@/Redux/Ducks/messageSlice';
import { AlignUIRender } from './indexUI';
import { RootState } from '@/Redux/configureStore';
import { useParams } from 'react-router-dom';

class ResetPassword extends Component<any> {
  render = () => AlignUIRender(this);
  constructor(props: any) {
    super(props);
    this.resetPassword = this.resetPassword.bind(this);
    this.updateFieldState = this.updateFieldState.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.navigateToLogin = this.navigateToLogin.bind(this);
  }

  navigateToLogin() {
    const { history } = this.props;
    history.push('/login')
  }
  state = {
    password: "",
    confirmpassword: ""
  }

  resetPassword() {
    const queryParams = new URLSearchParams(window.location.search);
    const email = queryParams.get("email");
    const token = queryParams.get("token");

    if (this.state.password && this.state.confirmpassword) {
      if (this.state.password == this.state.confirmpassword)
        this.props.resetUserPassword({ email: email, password: this.state.password, token });
      else
        this.props.setGlobalMessage({ error: { message: "Passwords must be match" } });
    }
    else {
      this.props.setGlobalMessage({ error: { message: "Password & Confirm Password can not be empty" } });
    }
  }
  handleKeyDown(e: any) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.resetPassword();
    }
  }

  updateFieldState(fieldId: string, fieldValue: string) {
    this.setState({ [fieldId]: fieldValue });
  }

}

// for type
export { ResetPassword };

const mapStateToProps = (state: RootState, ownProps: any) => ({
  clientLanguage: state.User.clientLanguage
})

export default connect(mapStateToProps, {
  resetUserPassword,
  setGlobalMessage
})(ResetPassword);


//#region Components Props

declare type ComponentProps = DispatchFromProps;

interface DispatchFromProps {
  forgotPassword: (object: IUserResetPassword) => void;
}

//#endregion
