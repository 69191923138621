import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import { RootState } from "@/Redux/configureStore";
import { addGridRowDimensionSet, addSubGridNewRow, changeSubGridRow, closeSubGrid, deleteGridRowDimensionSet, deleteSubGridRow, fillDimensionFromDimensionSet, removeGridRowError, saveSubGrid, upsertDimension } from "@/Redux/Ducks/entryFormSlice";
import { closeHelp, IReturnParam, IReturnValues } from "@/Redux/Ducks/helpSlice";
import { ArrayKeyValueObject, EActivity, ISubGrid, KeyValueObject } from "@/UI/Interfaces/IBaseUI";
import { IGrid, IGridMethods, IGridRef, IWijmoGridHandle } from "@/UI/Interfaces/IGrid";
import { dimensionSetFields } from "@/Utils/constants";
import { Button } from 'react-bootstrap';
import { IoMdClose, IoMdSave } from 'react-icons/io';
import CustomModal from '../Modal';
import WijmoGrid from "../WijmoGrid";


const AlignGrid = forwardRef<IGridRef, IGridProps>((props, ref) => {
  const dispatch = useAppDispatch();
  const wijmoRef = useRef();
  const ownRef = useRef<IWijmoGridHandle>(null);

  const [showSubGrid, setShowSubGrid] = useState(true);

  // const [gridConfig, setGridConfig] = useState<IGrid>();
  // const [currentGridObject, setCurrentGridObject] = useState<ISubGrid>();

  // const activeObjectId = useAppSelector((state: RootState) => state.EntryForm.activeObjectId);
  // const subGrids = useAppSelector((state: RootState) => state.EntryForm.objects[activeObjectId].subGrid) as Array<ISubGrid>;

  const gridDimensionObject = useAppSelector((state: RootState) => {
    const objectId = state.EntryForm.activeObjectId;
    const object = state.EntryForm.objects[objectId];
    let relationKey = "";
    if (object.subGrid?.length) {
      const subGrid = object.subGrid[object.subGrid.length - 1];
      const subGridLengthForDimension = object.subGrid.length + 1;

      object.subGrid.map(x => {
        relationKey += `${x.parentGridno}-${x.parentRowIndex}-`
      });
      const dimensionsets = object.dimension.filter(x => x.dimensionRelations.findIndex(y => y.split("-").length == (2 * subGridLengthForDimension + 1) && y.includes(subGrid.gridno)) != -1);
      return {
        dimensionsets,
        relationKey
      }
    }
    return {
      dimensionsets: [],
      relationKey
    };
  });

  const gridDataRef = useRef<ISubGrid>();
  const activeTabId = useAppSelector(state => state.Tab.activeTabKey);
  const activity = useAppSelector(state => state.EntryForm.objects[state.EntryForm.activeObjectId].activity);

  const gridConfig: IGrid = useAppSelector(state => {
    const objectId = state.EntryForm.activeObjectId;
    let data: any = null;
    if (objectId) {
      const subGrids = state.EntryForm.objects[objectId].subGrid as Array<ISubGrid>;
      if (subGrids.length) {
        data = state.EntryForm.objects[objectId].ScreenConfig.Grids.GridConfig.find(x => x.Config.gridno == subGrids[subGrids.length - 1].gridno);
      }
    }
    return data;
  });

  const subGridObject: ISubGrid = useAppSelector(state => {
    const objectId = state.EntryForm.activeObjectId;
    let data: ISubGrid = {
      gridno: "",
      parentGridno: "",
      parentColumnId: "",
      parentRowIndex: -1,
      modelData: [],
      parentRowData: {},
      readOnly: false,
      allowAddNewRow,
      allowCopyRow,
      allowDeleteRow
    };
    if (objectId) {
      const subGrids = state.EntryForm.objects[objectId].subGrid as Array<ISubGrid>;
      if (subGrids.length) {
        // data = JSON.parse(JSON.stringify(subGrids[subGrids.length - 1]));
        data = subGrids[subGrids.length - 1];

        // const dateColumnConfig = gridConfig?.Header.filter((y) => BaseMethods.getControlType(y.controltypeno).isEqual("date", "time")) || [];

        // data.modelData = data.modelData.map((x: any) => {
        //   const data: any = {};
        //   dateColumnConfig.map(y => {
        //     if (x[y.fieldid])
        //       data[y.fieldid] = new Date(x[y.fieldid]);
        //   });

        //   return { ...x, ...data };
        // });
      }
    }
    return data;
  });

  gridDataRef.current = subGridObject;

  const gridOptions: any = useAppSelector(state => {
    const objectId = state.EntryForm.activeObjectId;
    let data: any = null;
    if (objectId) {
      const subGrids = state.EntryForm.objects[objectId].subGrid as Array<ISubGrid>;
      if (subGrids.length) {
        data = state.EntryForm.objects[objectId].gridOptions[subGrids[subGrids.length - 1].gridno];
      }
    }
    return data;
  });

  const helpSelected = useAppSelector(state => {
    const returnOption = state.Help[state.Tab.activeTabKey]?.returnOption;
    const loadedHelp = state.Help[state.Tab.activeTabKey]?.loadedHelp;

    // if (help && !help.isOpen && help.helpOpenFor == EHelpOpenFor.Grid && help.helpType.isEqual(EHelpType.SideHelp, EHelpType.ShortHelp)) {
    if (returnOption && returnOption.returnParam.gridno && gridConfig) {
      if (returnOption.returnParam.gridno == gridConfig.Config.gridno && returnOption.returnValues && returnOption.returnValues.length) {
        return {
          helpObject: loadedHelp?.helpConfig.Config.helpobjectno as string,
          ...returnOption
        };
      }
    }

    return null;
  });

  const isReadonly = activity == EActivity.View || subGridObject.readOnly;
  const allowAddNewRow = isReadonly ? false : (subGridObject.allowAddNewRow || true);
  const allowDeleteRow = isReadonly ? false : (subGridObject.allowDeleteRow || true);
  const allowCopyRow = isReadonly ? false : (subGridObject.allowCopyRow || true);


  useEffect(() => {


    if (helpSelected) {
      const helpReturnValues = helpSelected.returnValues as Array<Array<IReturnValues>>;
      const rowIndex = helpSelected.returnParam.gridRowIndex as number;
      const isDimensionHelp = helpSelected.returnParam["isDimension" as keyof IReturnParam];
      // when line information dimension help select
      if (isDimensionHelp) {
        updateDimensionValue(helpReturnValues[0][0].fieldId, helpReturnValues[0][0].fieldValue, rowIndex);
      }
      else {
        Promise.all(helpReturnValues.map(async (x, i) => {
          await Promise.all(x.map(async (y) => {
            if (y.fieldId) {
              if (y.fieldId.isEqual(...dimensionSetFields)) {
                if (y.dimensionValues)
                  dispatch(upsertDimension({ dimensionValues: y.dimensionValues, dimensionSetFieldid: y.fieldId, dimensionSetting: { gridno: subGridObject.gridno, rowIndex: (rowIndex + i) } }));
              }
              else {
                const preventValidateHelp = helpSelected.returnParam.fieldId == y.fieldId;
                await (ownRef.current?.setCellData((rowIndex + i), y.fieldId, y.fieldValue, true, preventValidateHelp));
              }
            }
          }));
        }));
      }

      dispatch(closeHelp({ objectId: activeTabId }));

      if (!isDimensionHelp) {
        const helpReturnArrayObject = helpReturnValues.map(x => {
          const obj = {} as KeyValueObject;
          x.map(y => {
            obj[y.fieldId] = y.fieldValue;
          });
          return obj;
        });

        props.BaseGridAfterHelpSelected && props.BaseGridAfterHelpSelected(subGridObject.gridno, helpSelected.returnParam.fieldId, rowIndex, helpSelected.helpObject, helpReturnArrayObject);
      }
    }

  }, [helpSelected]);

  useEffect(() => {
    setShowSubGrid(false);

    setTimeout(() => {
      setShowSubGrid(true);
    }, 50)

  }, [subGridObject.gridno])


  useImperativeHandle(ref, () => ({
    ownRef,
    wijmoRef,
    setGridDataByStartingRow,
    setGridData
  }));

  const addNewRow = (newItem: any, rowIndex: number, clone: boolean, addOnLastLine: boolean) => {
    dispatch(addSubGridNewRow({
      gridno: gridConfig.Config.gridno,
      data: newItem,
      rowIndex
    }));

    if (!addOnLastLine)
      addNewDimensionInternal(rowIndex, clone);
  }

  const deleteRow = async (oldItem: any, rowIndex: number) => {
    var evt: KeyValueObject = {
      cancel: false
    };

    await (props.BaseGridBeforeDeleteRow && props.BaseGridBeforeDeleteRow(gridConfig.Config.gridno, rowIndex, oldItem, evt));

    if (!evt.cancel) {
      dispatch(removeGridRowError({ gridno: gridConfig.Config.gridno, rowIndex: rowIndex }));

      dispatch(deleteSubGridRow({
        gridno: gridConfig.Config.gridno,
        rowIndex
      }));

      deleteGridDimensionRelationInternal(rowIndex);

      // ? code not done
      // dispatch(deleteGridLineAttachment({ gridno: props.gridno, lineId: oldItem?.lineid }));
    }
  }

  const changeRow = (newItem: any, rowIndex: number) => {
    dispatch(changeSubGridRow({
      gridno: gridConfig.Config.gridno,
      newItem,
      rowIndex
    }));
  }

  const HyperLinkClick = (columnId: string, rowIndex: number) => {
    const gridRow = {
      ...(gridDataRef.current?.modelData[rowIndex] || {})
    }
    props.BaseGridHyperLinkClick && props.BaseGridHyperLinkClick(gridConfig.Config.gridno, columnId, rowIndex, gridRow);
  }

  const beforeCellChanged = (columnId: string, rowIndex: number, oldValue: any, newValue: any, gridRow: KeyValueObject, ev: KeyValueObject) => {
    (props.BaseGridBeforeCellChanged && props.BaseGridBeforeCellChanged(gridConfig.Config.gridno, columnId, rowIndex, oldValue, newValue, false, gridRow, ev));
  }

  const cellChanged = async (columnId: string, rowIndex: number, oldValue: any, newValue: any, gridRow: KeyValueObject, helpSelected: boolean, preventValidateHelp: boolean, preventCellChange: boolean) => {
    // const gridRow = {
    //   ...(gridDataRef.current?.modelData[rowIndex] || {}),
    //   [columnId]: newValue
    // }
    await (props.BaseGridCellChanged && props.BaseGridCellChanged(gridConfig.Config.gridno, columnId, rowIndex, oldValue, newValue, helpSelected, preventValidateHelp, preventCellChange, true, gridRow));
  }

  const cellBeginningEdit = (columnId: string, rowIndex: number, value: any, event: any) => {
    const gridRow = {
      ...(gridDataRef.current?.modelData[rowIndex] || {}),
      [columnId]: value
    }
    props.BaseGridCellBeginningEdit && props.BaseGridCellBeginningEdit(gridConfig.Config.gridno, columnId, rowIndex, value, gridRow, event);
  }

  const setGridDataByStartingRow = (startingRow: number, gridRows: ArrayKeyValueObject) => {

    // gridRows.map(x => {
    //   gridConfig?.Header.map(y => {
    //     if (BaseMethods.getControlType(y.controltypeno) == "date") {
    //       if (x[y.fieldid] instanceof Date)
    //         x[y.fieldid] = x[y.fieldid].toJSON();
    //     }
    //     else if (BaseMethods.getControlType(y.controltypeno) == "text") {
    //       if (!x[y.fieldid])
    //         x[y.fieldid] = null;
    //     }
    //   });
    // });

    // dispatch(setGridRowStateByStartingRow({ gridno: props.gridno, gridRows, startingRow }));
  }

  const setGridData = (gridRows: ArrayKeyValueObject) => {

    // gridRows.map(x => {
    //   gridConfig?.Header.map(y => {
    //     if (BaseMethods.getControlType(y.controltypeno) == "date") {
    //       if (x[y.fieldid] instanceof Date)
    //         x[y.fieldid] = x[y.fieldid].toJSON();
    //     }
    //     else if (BaseMethods.getControlType(y.controltypeno) == "text") {
    //       if (!x[y.fieldid])
    //         x[y.fieldid] = null;
    //     }
    //   });
    // });

    // dispatch(setGridRowsState({ gridno: props.gridno, gridRows s
  }


  const saveAndCloseSubGridForm = () => {
    saveSubGridForm();
    closeSubGridForm();
  }

  const closeSubGridForm = () => {
    dispatch(closeSubGrid());
  }

  const saveSubGridForm = () => {
    props.BaseSaveSubGridForm && props.BaseSaveSubGridForm(gridConfig.Config.gridno, subGridObject.parentGridno, subGridObject.parentColumnId, subGridObject.parentRowIndex, subGridObject.modelData);
    dispatch(saveSubGrid());
  }

  const rowSelectionChanged = (rowIndex: number) => {
    const gridRow = {
      ...(gridDataRef.current?.modelData[rowIndex] || {})
    };
    props.BaseRowSelectionChanged && props.BaseRowSelectionChanged(gridConfig.Config.gridno, rowIndex, gridRow);
  }

  const updateDimensionValue = (columnId: string, value: any, rowIndex: number) => {
    dispatch(upsertDimension({ dimensionValues: { [columnId]: value }, dimensionSetting: { gridno: subGridObject.gridno, rowIndex } }));
  }

  const addNewDimensionInternal = (rowIndex: number, clone: boolean) => {
    rowIndex = clone ? rowIndex - 1 : rowIndex;
    dispatch(addGridRowDimensionSet({ gridno: subGridObject.gridno, rowIndex, clone }));
  }

  const deleteGridDimensionRelationInternal = (rowIndex: number) => {
    dispatch(deleteGridRowDimensionSet({ gridno: subGridObject.gridno, rowIndex }));
  }

  const toolbarClicked = (toolbarKey: string, gridno: string, rowIndex: number) => {
    props.BaseToolbarClicked && props.BaseToolbarClicked(toolbarKey, { gridno, rowIndex });
  }

  return (
    <CustomModal
      heading="Sub Grid"
      close={closeSubGridForm}
      className="sub-grid"
      show={gridConfig && showSubGrid}
      modalSize="xl"
      notCentered={true}
      footer={
        !isReadonly ?
          <>
            <Button variant="primary" onClick={saveAndCloseSubGridForm}>
              <IoMdClose className="me-2" size="16" />
              Save And Close
            </Button>
            <Button variant="secondary" onClick={saveSubGridForm}>
              <IoMdSave className="me-2" size="16" />
              Save
            </Button>
          </>
          :
          <>
            <Button variant="primary" onClick={closeSubGridForm}>
              <IoMdClose className="me-2" size="16" />
              Close
            </Button>
          </>
      }
    >
      <WijmoGrid
        ref={ownRef}
        wijmoRef={wijmoRef}
        gridConfig={gridConfig}
        gridData={subGridObject.modelData}
        gridOptions={gridOptions}
        wijmoAddGridNewRow={addNewRow}
        wijmoDeleteGridRow={deleteRow}
        wijmoChangeGridRow={changeRow}
        wijmoBeforeCellChanged={beforeCellChanged}
        wijmoCellChanged={cellChanged}
        wijmoHyperLinkClick={HyperLinkClick}
        wijmoCellBeginningEdit={cellBeginningEdit}
        wijmoSetGridData={setGridData}
        wijmoRowSelectionChanged={rowSelectionChanged}
        wijmoToolbarClicked={toolbarClicked}
        wijmoGridInitialized={props.BaseGridInitialized}
        isSubGrid={true}
        wijmoUpdateDimensionValue={updateDimensionValue}
        wijmoGridDimensionObject={gridDimensionObject.dimensionsets}
        parentDimensionRelationKey={gridDimensionObject.relationKey}
        isReadOnly={isReadonly}
        allowAddNewRow={allowAddNewRow}
        allowDeleteRow={allowDeleteRow}
        allowCopyRow={allowCopyRow}
      />
    </CustomModal>
  );

  // if (gridConfig && showSubGrid)
  //   return (
  //     <Modal className="sub-grid" show={gridConfig && showSubGrid} onHide={closeSubGridForm} scrollable size="xl">
  //       <Modal.Header closeButton>
  //         <h6 className="fw-bold m-0">Sub Grid</h6>
  //       </Modal.Header>
  //       <Modal.Body>
  //         <WijmoGrid
  //           ref={ownRef}
  //           wijmoRef={wijmoRef}
  //           gridConfig={gridConfig}
  //           gridData={subGridObject.modelData}
  //           gridOptions={gridOptions}
  //           wijmoAddGridNewRow={addNewRow}
  //           wijmoDeleteGridRow={deleteRow}
  //           wijmoChangeGridRow={changeRow}
  //           wijmoBeforeCellChanged={beforeCellChanged}
  //           wijmoCellChanged={cellChanged}
  //           wijmoHyperLinkClick={HyperLinkClick}
  //           wijmoCellBeginningEdit={cellBeginningEdit}
  //           wijmoSetGridData={setGridData}
  //           wijmoRowSelectionChanged={rowSelectionChanged}
  //           wijmoToolbarClicked={toolbarClicked}
  //           isSubGrid={true}
  //           wijmoUpdateDimensionValue={updateDimensionSet}
  //           wijmoGridDimensionObject={[]}
  //           isReadOnly={isReadonly}
  //           allowAddNewRow={allowAddNewRow}
  //           allowDeleteRow={allowDeleteRow}
  //           allowCopyRow={allowCopyRow}
  //         />
  //       </Modal.Body>
  //       <Modal.Footer>
  //         {
  //           !isReadonly ?
  //             <>
  //               <Button variant='secondary' onClick={saveAndCloseSubGridForm}>
  //                 <IoMdClose className="me-2" size="16" />
  //                 Save And Close
  //               </Button>
  //               <Button variant="secondary" onClick={saveSubGridForm}>
  //                 <IoMdSave className="me-2" size="16" />
  //                 Save
  //               </Button>
  //             </>
  //             :
  //             <>
  //               <Button variant='secondary' onClick={closeSubGridForm}>
  //                 <IoMdClose className="me-2" size="16" />
  //                 Close
  //               </Button>
  //             </>
  //         }
  //       </Modal.Footer>
  //     </Modal>
  //   );
  // else return null;
});

export default AlignGrid;

interface IGridProps extends IGridMethods {
  ref: any;
  // config: IGridObject;
  // gridno: string;
}
