import { useEffect } from "react";
import * as wjInput from "@grapecity/wijmo.react.input";
import { IFieldConfig } from "@/UI/Interfaces/IFieldConfig";
import { useAppSelector } from "@/Hook/hooks";
import { IFieldProps } from "@/UI/Interfaces/IComponent";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";

const Field = ({ config, fieldValueChange, uniqueKey, enable, value, validateMandatory, hideLabel }: IFieldProps) => {
  const { fieldid } = config;
  let defaultValue = false;

  // const dispatch = useDispatch();
  const objectId = useAppSelector((x) => x.EntryForm.activeObjectId);

  useEffect(() => {
    if (value === undefined) {
      callBaseMethod(defaultValue);
    }
  }, [value]);

  const callBaseMethod = (value: any) => {
    fieldValueChange(fieldid, value);
  };

  const OnValueChanged = (e: any) => {
    callBaseMethod(e.target.checked);
  };

  if (!config) return <></>;

  const id = objectId + (uniqueKey ? `-${uniqueKey}` : "") + "-" + fieldid;

  return (
    <>
      {
        !hideLabel ?
          <label id={"lb-" + id} htmlFor={"field-" + id}>
            {config.fieldlabel}
            {validateMandatory ? (<span>*</span>) : null}
          </label>
          : null
      }
      <Form.Check
        id={"field-" + id}
        type="switch"
        disabled={!enable}
        value={value || defaultValue}
        checked={value || defaultValue}
        onChange={OnValueChanged}
      />
    </>
  );
};

export default Field;
