import { getUserInsights, saveUserDashboardLayout } from "@/Redux/Ducks/dashboardSlice";
import { addComment, changeActivityToCreate, changeObjectActivity, deleteDocument, fieldHelpValidate, getAccountDimensions, getDocumentByPrid, copyDocumentByPrid, getScreenConfig, gridHelpValidate, loadDocumentHelp, removeUserDocumentHelp, reverseDocument, saveDocument, saveUserDocumentHelpFilter, setViewDefaultUserDocumentHelp, uploadAttachment, fillDimensionFromDimensionSet, shareDocument, getDimensionValuesFromDimensionSetPromiseResolver } from "@/Redux/Ducks/entryFormSlice";
import { getHelpConfig, reloadDocumentHelpData } from "@/Redux/Ducks/helpSlice";
import { forgotUserPassword, getLoginBasicData, getUserSpecificDetail, loginUser, logoutUser, resetUserPassword, getHidden, toggleHidden, getBannerHidden, getColors } from "@/Redux/Ducks/userSlice";
import { handleGetUserInsights, handleSaveUserDashboardLayout } from "@/Redux/Sagas/Handlers/dashboard";
import { handleApplyLayout, handleDeleteLayout, handleGetDataAnalyticsConfig, handleGetDataAnalyticsMenu, handleLoadDataAnalytics, handleStoreLayout, handleUpdateLayout } from "@/Redux/Sagas/Handlers/dataAnalytics";
import { handleAddComment, handleChangeActivityToCreate, handleChangeObjectActivity, handleDeleteDocument, handleFieldHelpValidate, handleGetAccountDimensions, handleGetDocumentByPrid, handleCopyDocumentByPrid, handleGetScreenConfig, handleGridHelpValidate, handleLoadDocumentHelp, handleReverseDocument, handlerRemoveUserDocumentHelp, handlerSaveUserDocumentHelpFilter, handlerSetViewDefaultUserDocumentHelp, handleSaveDocument, handlerUploadAttachment, handlerFillDimensionFromDimensionSet, handleShareDocument, handlerGetDimensionValuesFromDimensionSetPromiseResolver } from "@/Redux/Sagas/Handlers/entryForm";
import { handleGetHelpConfig, handlerReloadDocumentHelpData } from "@/Redux/Sagas/Handlers/help";
import { handleGetUserObjects, handleGetHomeUserSpecificData, handleGetUserEntryForms, handleGetUserDataAnalytics, handleGetUserReport, handleSetUserFavorite } from "@/Redux/Sagas/Handlers/home";
import { handleGetNotification, handleGetUserInboxInformation, handleSetMessageRead, handleSetWorkflowApproval, handleMarkImportant, handleMarkTrash } from "@/Redux/Sagas/Handlers/inbox";
import { handleGetReportConfig, handleGetReportMenu, handleLoadReport } from "@/Redux/Sagas/Handlers/reportExplorer";
import { handleCloseTab } from "@/Redux/Sagas/Handlers/tab";
import { handleForgotPassword, handleGetBannerHidden, handleGetColors, handleGetHidden, handleGetLoginBasicData, handleGetUserSpecificDetail, handleLoginUser, handleLogoutUser, handleResetPassword, handleToggleHidden } from "@/Redux/Sagas/Handlers/user";
import { all, cancel, fork, join, take, takeLatest } from "redux-saga/effects";
import { applyLayout, deleteLayout, getDataAnalyticsConfig, getDataAnalyticsMenu, loadDataAnalytics, storeLayout, updateLayout } from "../Ducks/dataAnalyticsSlice";
import { getHomeUserSpecificData, getUserEntryForms, getUserDataAnalytics, getUserReport, getUserObjects, setUserFavorite } from "../Ducks/homeSlice";
import { getNotification, getUserInboxInformation, setMessageRead, setWorkflowApproval, markImportant, markTrash } from "../Ducks/inboxSlice";
import { getReportConfig, getReportMenu, loadReport } from "../Ducks/reportExplorerSlice";
import { closeTab } from "../Ducks/tabSlice";
import { addToWorkspace, addWorkspace, deleteWorkspace, deleteWorkspaceObject, getUserWorkspace, getUserWorkspaceObject, reorderLists, reorderMenus, reorderTiles, toggleColumnLayout, updateMenuGroup, updateUserWorkspaceObject, updateWorkspace } from "../Ducks/workspaceSlice";
import { handleAddToWorkspace, handleAddWorkspace, handleDeleteWorkspace, handleDeleteWorkspaceObject, handleGetUserWorkspace, handleGetUserWorkspaceObject, handleReorderLists, handleReorderMenus, handleReorderTiles, handleToggleColumnLayout, handleUpdateMenuGroup, handleUpdateUserWorkspaceObject, handleUpdateWorkspace } from "./Handlers/workspace";

export function* watcherSaga() {
  yield all([
    takeLatest(setUserFavorite.type, handleSetUserFavorite),

    takeLatest(getLoginBasicData.type, handleGetLoginBasicData),
    takeLatest(getUserSpecificDetail.type, handleGetUserSpecificDetail),
    takeLatest(loginUser.type, handleLoginUser),
    takeLatest(forgotUserPassword.type, handleForgotPassword),
    takeLatest(resetUserPassword.type, handleResetPassword),
    takeLatest(logoutUser.type, handleLogoutUser),

    takeLatest(getHomeUserSpecificData.type, handleGetHomeUserSpecificData),
    takeLatest(getUserInsights.type, handleGetUserInsights),

    takeLatest(getUserWorkspace.type, handleGetUserWorkspace),
    takeLatest(addWorkspace.type, handleAddWorkspace),
    takeLatest(updateWorkspace.type, handleUpdateWorkspace),
    takeLatest(deleteWorkspace.type, handleDeleteWorkspace),

    takeLatest(getUserWorkspaceObject.type, handleGetUserWorkspaceObject),
    takeLatest(addToWorkspace.type, handleAddToWorkspace),
    takeLatest(updateUserWorkspaceObject.type, handleUpdateUserWorkspaceObject),
    takeLatest(deleteWorkspaceObject.type, handleDeleteWorkspaceObject),

    takeLatest(toggleColumnLayout.type, handleToggleColumnLayout),
    takeLatest(reorderTiles.type, handleReorderTiles),
    takeLatest(reorderLists.type, handleReorderLists),
    takeLatest(reorderMenus.type, handleReorderMenus),
    takeLatest(updateMenuGroup.type, handleUpdateMenuGroup),

    takeLatest(saveUserDashboardLayout.type, handleSaveUserDashboardLayout),

    takeLatest(getDataAnalyticsMenu.type, handleGetDataAnalyticsMenu),
    takeLatest(getDataAnalyticsConfig.type, handleGetDataAnalyticsConfig),
    takeLatest(loadDataAnalytics.type, handleLoadDataAnalytics),

    takeLatest(storeLayout.type, handleStoreLayout),
    takeLatest(updateLayout.type, handleUpdateLayout),
    takeLatest(deleteLayout.type, handleDeleteLayout),
    takeLatest(applyLayout.type, handleApplyLayout),

    takeLatest(getReportMenu.type, handleGetReportMenu),
    takeLatest(getReportConfig.type, handleGetReportConfig),
    takeLatest(loadReport.type, handleLoadReport),

    takeLatest(closeTab.type, handleCloseTab),

    takeLatest(getHelpConfig.type, handleGetHelpConfig),
    takeLatest(loadDocumentHelp.type, handleLoadDocumentHelp),
    takeLatest(reloadDocumentHelpData.type, handlerReloadDocumentHelpData),
    takeLatest(changeActivityToCreate.type, handleChangeActivityToCreate),

    takeLatest(getScreenConfig.type, handleGetScreenConfig),
    takeLatest(changeObjectActivity.type, handleChangeObjectActivity),

    takeLatest(getDocumentByPrid.type, handleGetDocumentByPrid),
    takeLatest(copyDocumentByPrid.type, handleCopyDocumentByPrid),
    takeLatest(saveDocument.type, handleSaveDocument),
    takeLatest(reverseDocument.type, handleReverseDocument),
    takeLatest(deleteDocument.type, handleDeleteDocument),
    takeLatest(getAccountDimensions.type, handleGetAccountDimensions),

    takeLatest(getUserInboxInformation.type, handleGetUserInboxInformation),
    takeLatest(setWorkflowApproval.type, handleSetWorkflowApproval),
    takeLatest(getNotification.type, handleGetNotification), // take every for temporary because of request is firing from authorize layout also every url change
    takeLatest(setMessageRead.type, handleSetMessageRead),
    takeLatest(markImportant.type, handleMarkImportant),
    takeLatest(markTrash.type, handleMarkTrash),

    takeLatestByGroup(fieldHelpValidate.type, handleFieldHelpValidate, "fieldId"),
    takeLatestByGroup(gridHelpValidate.type, handleGridHelpValidate, "gridno,rowIndex,columnId"),

    takeLatest(getUserEntryForms.type, handleGetUserEntryForms),
    takeLatest(getUserDataAnalytics.type, handleGetUserDataAnalytics),
    takeLatest(getUserReport.type, handleGetUserReport),
    takeLatest(getUserObjects.type, handleGetUserObjects),

    takeLatest(addComment.type, handleAddComment),

    takeLatest(saveUserDocumentHelpFilter.type, handlerSaveUserDocumentHelpFilter),
    takeLatest(removeUserDocumentHelp.type, handlerRemoveUserDocumentHelp),
    takeLatest(setViewDefaultUserDocumentHelp.type, handlerSetViewDefaultUserDocumentHelp),
    takeLatest(shareDocument.type, handleShareDocument),

    takeLatest(getHidden.type, handleGetHidden),
    takeLatest(getBannerHidden.type, handleGetBannerHidden),
    takeLatest(toggleHidden.type, handleToggleHidden),

    takeLatest(getColors.type, handleGetColors),

    takeLatest(uploadAttachment.type, handlerUploadAttachment),
    takeEveryInQueueByGroup(fillDimensionFromDimensionSet.type, handlerFillDimensionFromDimensionSet, "dimensionSet"),
    takeEveryInQueueByGroup(getDimensionValuesFromDimensionSetPromiseResolver.type, handlerGetDimensionValuesFromDimensionSetPromiseResolver, "dimensionSet"),
  ]);
}

const takeLatestByGroup = (patternOrChannel: string, saga: any, groupOn: string, ...args: any[]) => fork(function* (): any {
  // hold a reference to each forked saga identified by the type property
  const groupOnArray = groupOn.split(",").map(x => x.trim());
  let lastTasks: any = {};

  while (true) {
    const action = yield take(patternOrChannel);

    const groupOnValue = groupOnArray.map(x => action.payload[x]).join("-");

    // if there is a forked saga running with the same type, cancel it.
    if (lastTasks[groupOnValue]) {
      yield cancel(lastTasks[groupOnValue]);
    }

    lastTasks[groupOnValue] = yield fork(saga, ...args.concat(action));
  }

});


const takeEveryInQueueByGroup = (patternOrChannel: string, saga: any, groupOn: string, ...args: any[]) => fork(function* (): any {
  const groupOnArray = groupOn.split(",").map(x => x.trim());
  let lastRunningTasks: any = {};
  let queueTasks: any = {};

  function* RunProcess(groupOnValue: string, ...actions: any[]): any {

    lastRunningTasks[groupOnValue] = yield fork(saga, ...actions);

    const res = yield join(lastRunningTasks[groupOnValue]);

    if (queueTasks[groupOnValue]?.length) {
      const nextAction = queueTasks[groupOnValue].splice(0, 1);
      yield fork(RunProcess, groupOnValue, ...args.concat(nextAction));
    }
  }

  while (true) {
    const action = yield take(patternOrChannel);

    const groupOnValue = groupOnArray.map(x => action.payload[x]).join("-");

    if (lastRunningTasks[groupOnValue] && lastRunningTasks[groupOnValue].isRunning()) {
      if (!queueTasks[groupOnValue])
        queueTasks[groupOnValue] = [];

      queueTasks[groupOnValue].push(action);
    }
    else {
      yield fork(RunProcess, groupOnValue, ...args.concat(action))
    }

  }

});
