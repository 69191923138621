import { OverlayTrigger, Badge, Tooltip } from 'react-bootstrap'

const Marker = ({ text, tooltip }: any) => {
  return (
    <OverlayTrigger placement="bottom" overlay={<Tooltip className="marker-tooltip">{typeof (tooltip) == 'number' ? tooltip.toLocaleString() : tooltip}</Tooltip>}>
      <Badge className="marker" bg="danger">{text}</Badge>
    </OverlayTrigger >
  );
};

export default Marker;