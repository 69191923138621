

const API_URL = process.env.API_URL;

export {
	API_URL
};

export const constant = {
  ACCESS_TOKEN: "AccessToken",
  REFRESH_TOKEN: "RefreshToken",
  TIME_ZONE: "time_zone",
  SELECTED_LEGAL_ENTITY: "legal_entity",
  
  DefaultNumber: "n2",
  DefaultDateFormat: "dd - MMM - yyyy",
  DefaultTimeFormat: "hh:mm tt"
};

export const reverseFieldIds = ["reversedate", "reversereasonno", "reverseremarks"];
export const dimensionSetFields = ["dimensionset", "debitdimensionset", "creditdimensionset"];

export const filterOption = [
  {
    filterText: "Is Equal",
    filterSymbol: "=",
    fieldType: ["text", "number", "date", "time"]
  },
  {
    filterText: "Is Not Equal",
    filterSymbol: "!=",
    fieldType: ["text", "number", "date", "time"]
  },
  {
    filterText: "Is Between",
    filterSymbol: "between",
    fieldType: ["number", "date", "time"]
  },
  {
    filterText: "Is Not Between",
    filterSymbol: "not between",
    fieldType: ["number", "date", "time"]
  },
  {
    filterText: "Contains",
    filterSymbol: "like",
    fieldType: ["text"]
  },
  {
    filterText: "Not Contains",
    filterSymbol: "not like",
    fieldType: ["text"]
  },
  {
    filterText: "Start With",
    filterSymbol: "like%",
    fieldType: ["text"]
  },
  {
    filterText: "End With",
    filterSymbol: "%like",
    fieldType: ["text"]
  },
  {
    filterText: "Greater Than",
    filterSymbol: ">",
    fieldType: ["number", "date", "time"]
  },
  {
    filterText: "Less Than",
    filterSymbol: "<",
    fieldType: ["number", "date", "time"]
  },
  {
    filterText: "Greater Than and Equals to",
    filterSymbol: ">=",
    fieldType: ["number", "date", "time"]
  },
  {
    filterText: "Less Than and Equals to",
    filterSymbol: "<=",
    fieldType: ["number", "date", "time"]
  },
  {
    filterText: "Case Sensitive",
    filterSymbol: "cs",
    fieldType: ["text"]
  },
];
