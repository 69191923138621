import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/Hook/hooks";

import { EActivity } from "@/UI/Interfaces/IBaseUI";
import { AlignTab } from "AlignComponents";

import { toggleActivityModal } from "@/Redux/Ducks/entryFormSlice"
import classNames from "classnames";
import ReactTimeago from "react-timeago";
import { Modal } from "react-bootstrap";
import { BiComment } from 'react-icons/bi'
import BaseMethods from "AlignBaseUI/BaseMethods";
import { requestGetActivityByPrid } from "@/Redux/Sagas/Requests/entryForm";
import { toggleLoader } from "@/Redux/Ducks/loaderSlice";

const ProcessActivity = () => {
  const dispatch = useAppDispatch();
  const [activity, setActivity] = useState<any>([]);
  const isActivityModalOpen = useAppSelector(x => x.EntryForm.objects[x.EntryForm.activeObjectId].otherState.isActivityModalOpen);
  const processcode: string = useAppSelector(x => x.EntryForm.objects[x.EntryForm.activeObjectId].ScreenConfig.TcodeConfiguration.processcode);
  const prid: number = useAppSelector(x => x.EntryForm.objects[x.EntryForm.activeObjectId].model.prid) || 0;

  useEffect(() => {
    if (isActivityModalOpen) {
      getActivity();
    }
  }, [isActivityModalOpen])

  const getActivity = async () => {
    dispatch(toggleLoader(true));
    const response = await requestGetActivityByPrid(processcode, prid);
    setActivity(response);
    dispatch(toggleLoader(false));
  }


  return (
    <>
      <Modal show={isActivityModalOpen} onHide={() => { dispatch(toggleActivityModal(false)) }} scrollable centered size="lg">
        <Modal.Header closeButton>
          <h6 className="fw-bold m-0">Activity</h6>
        </Modal.Header>
        <Modal.Body>
          <div className="row my-2">
            <section className="timeline_area section_padding_130">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12 col-sm-8 col-lg-6">
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    {/* Timeline Area*/}
                    <div className="apland-timeline-area">
                      {
                        activity.map((x: any, i: any) => (
                          <div key={i} className="single-timeline-area">
                            <div className="timeline-date" data-wow-delay="0.1s">
                              <p><ReactTimeago date={x.activitydatetime} /></p>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <div className="single-timeline-content d-flex">
                                  <div className="timeline-icon">
                                    <img src={BaseMethods.getUserPhoto(x.userphoto)} alt="" />
                                  </div>
                                  <div className="timeline-text">
                                    <p>{x.message}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProcessActivity;
