import { IUserWorkspaceObject, reorderTiles } from '@/Redux/Ducks/workspaceSlice';
import { useCallback } from 'react';
import update from 'immutability-helper';
import { Col } from 'react-bootstrap';
import { Tile } from './Tile';

export interface IWorkspaceWidget extends IUserWorkspaceObject {
	index: number
}
interface IProps {
	tiles: Array<IWorkspaceWidget>,
	updateIndex: (objects: any, widgetType: string) => void,
	columnlayout: boolean,
	routeToScreen: (screenCode: string, gridView: string, objectType: string) => void,
	openUpdateWorkspace: (props: {
		widgetNo: string;
		widgetName: string;
		widgetType: string;
		icon?: string | undefined;
		widgetGroup?: string | undefined;
	}) => void,
	deleteWorkspace: (widgetNo: string) => void
}
export const Tiles = (props: IProps) => {
	const moveTiles = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			const dragCard = props.tiles[dragIndex];

			props.updateIndex(
				update(props.tiles, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragCard],
					],
				}), 'Tile');

		},
		[props.tiles]);

	return (
		<Col md={!props.columnlayout ? 12 : 3} className="mt-3">
			{
				props.tiles.length >= 1 ? (
					<h6 className="fw-bold">Tiles</h6>
				) : null
			}
			<div className="user-workspace mt-2">
				{
					props.tiles.map((tile, idx: number) => (
						<Tile key={tile.widgetNo} index={idx} tile={tile} routeToScreen={props.routeToScreen} openUpdateWorkspace={props.openUpdateWorkspace} deleteWorkspace={props.deleteWorkspace} moveTiles={moveTiles} />
					))
				}
			</div>
		</Col>
	)
}
