import { Icon } from "AlignComponents";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
// @ts-ignore
import Fade from 'react-reveal/Fade';
import { GiMove } from 'react-icons/gi'
import { BiLinkExternal } from "react-icons/bi";

interface IProps {
	widgetlabel: string;
	value: any;
	widgetprocesscode: string;
	routeToScreen: any;
	widget: any;
}

const formatValue = (val: any) => {
  if (typeof val === "number") {
    const isNegative = val < 0;
    val = Math.abs(val);

    if (val >= 1000000000) {
      return (isNegative ? '-' : '') + (val / 1000000000).toFixed(2) + "b";
    } else if (val >= 1000000) {
      return (isNegative ? '-' : '') + (val / 1000000).toFixed(2) + "m";
    } else if (val >= 1000) {
      return (isNegative ? '-' : '') + (val / 1000).toFixed(2) + "k";
    } else {
      return (isNegative ? '-' : '') + val;
    }
  } else {
    return val;
  }
};

const Information = (props: IProps) => {
	return (
		<div className="information-card">
			<Fade>
				<Card>
					<Card.Body>
						{
							props.widgetprocesscode ? (
								<OverlayTrigger placement="bottom" overlay={<Tooltip>Hyperlink</Tooltip>}>
									<div className="hyperlink" onClick={() => { props.routeToScreen(props.widgetprocesscode) }}>
										<BiLinkExternal size={16} />
									</div>
								</OverlayTrigger>
							) : null
						}
						<OverlayTrigger placement="bottom" overlay={<Tooltip>Move</Tooltip>}>
							<div className="drag-handle">
								<GiMove size={16} />
							</div>
						</OverlayTrigger>

						<div className="card-container">
							<div className={`rounded-circle p-3 bg-light-${props.widget.widgetcolor}`}>
								<Icon icon={props.widget.widgeticon as any} className={`text-${props.widget.widgetcolor}`} size={32} />
							</div>
							<div className="text-container">
								<span className="value">{formatValue(props.value)}</span>
								<span className="label">{props.widgetlabel}</span>
							</div>
						</div>
					</Card.Body>
				</Card>
			</Fade>
		</div>
	)
}

export default Information;