import { ArrayKeyValueObject, EActivity, EFieldErrorType, IAttachment, IComment, IDimension, IEntryFormReduxState, IOtherState, IScreenOptions, ISubGrid, ITModelFieldProperties, ITModelGridProperties, IToolbar, KeyValueObject, TFieldValue } from "@/UI/Interfaces/IBaseUI";
import { IFieldConfig } from "@/UI/Interfaces/IFieldConfig";
import { IGrid, IGridObjectConfig } from "@/UI/Interfaces/IGrid";
import { copyObject } from "@grapecity/wijmo.grid.immutable";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import BaseMethods from "AlignBaseUI/BaseMethods";
import produce from "immer";
import md5 from "js-md5";

export interface IShareDocument {
  toEmail: string,
  subject: string,
  ccEmails: string,
  attachReport: boolean,
  reportNo: string,
  documentNo: string,
  processCode: string,
  process: string,
  docKey: string,
  message: string,
}

// Define the initial state using that type
const initialState: IEntryFormReduxState = {
  objects: {},
  activeObjectId: "",
  isConfigLoaded: false
};

const EntryFormSlice = createSlice({
  name: "entryForm",
  initialState,
  reducers: {

    //#region Screen State Manage

    loadDocumentHelp(state, action: PayloadAction<string>) { },
    changeActivityToCreate(state, action: PayloadAction<{ objectId: string }>) { },
    getDocumentByPrid(state, action: PayloadAction<{ processcode: string, prid: number }>) { },
    copyDocumentByPrid(state, action: PayloadAction<{ prid: number }>) { },
    saveDocument: {
      reducer: (state, action: PayloadAction<{ objectId: string, options: KeyValueObject, approvalrequestmessage: string }, string, { afterSave: () => void } | undefined>) => { },
      prepare: (payload: { objectId: string, options: KeyValueObject, approvalrequestmessage: string }, meta?: { afterSave: () => void } | undefined) => ({
        payload,
        meta,
      })
    },
    
    reverseDocument(state, action: PayloadAction<{ processcode: string, model: any }>) { },
    deleteDocument(state, action: PayloadAction<{ processcode: string, prid: number }>) { },

    forceDisableActionButton(state, action: PayloadAction<Partial<{ edit: boolean; delete: boolean; reverse: boolean; disableAll: boolean }>>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        const activeObjectId = draftState.activeObjectId;
        draftState.objects[activeObjectId].otherState.forceDisabledButton.delete = payload.disableAll || payload.delete || false;
        draftState.objects[activeObjectId].otherState.forceDisabledButton.edit = payload.disableAll || payload.edit || false;
        draftState.objects[activeObjectId].otherState.forceDisabledButton.reverse = payload.disableAll || payload.reverse || false;
      });
    },

    getScreenConfig(state, action: PayloadAction<{ processCode: string, transactionNo?: string, gridView?: string }>) {

      return produce(state, (draftState) => {
        draftState.isConfigLoaded = false;
      });

    },

    setDocumentModelValue(state, action: PayloadAction<any>) {
      return produce(state, (draftState) => {
        draftState.objects[draftState.activeObjectId].model = action.payload;
      });
    },

    setObjectLoaded(state) {

      return produce(state, (draftState) => {
        draftState.isConfigLoaded = true;
      });

    },

    setScreenObject(state, action) {

      const screenData = action.payload;

      return produce(state, (draftState) => {

        const fieldConfigObject: KeyValueObject<ITModelFieldProperties<KeyValueObject>> = {};
        const gridConfigObject: KeyValueObject<KeyValueObject<ITModelGridProperties<KeyValueObject>>> = {};
        const toolbarSetting: Array<{ toolbarKey: string, gridno: string, visible: boolean, enable: boolean }> = [];

        screenData.GridConfig.map((x: IGrid) => {

          gridConfigObject[x.Config.gridno] = {};

          Object.entries(x.Header.groupBy("fieldid")).map(([key, y]) => {

            gridConfigObject[x.Config.gridno][key] = {
              fieldId: key,
              visible: true,
              enable: false,
              dimensionrequired: false,
              helpObject: "",
              helpWhere: "",
              columnLabel: y[0].fieldlabel,
              validateMandatory: false,
              validatingHelpRows: [],
              errorTypeInRows: [],
              hyperLinkJump: y[0].hyperlinkjump
            };

            if (y.find(z => z.dimensionrequired)) {
              gridConfigObject[x.Config.gridno][`dimension${key}values`] = {
                fieldId: `dimension${key}values`,
                visible: true,
                columnLabel: `${y[0].fieldlabel} (Dimension)`,
                enable: false,
                dimensionrequired: true,
                helpObject: "",
                helpWhere: "",
                validateMandatory: false,
                validatingHelpRows: [],
                errorTypeInRows: [],
                hyperLinkJump: ""
              };
            }

          });
        });

        Object.entries((screenData.FieldConfig as Array<IFieldConfig>).groupBy("fieldid")).map(([key, x]) => {
          fieldConfigObject[key] = {
            fieldId: key,
            visible: true,
            enable: false,
            dimensionrequired: x[0].dimensionrequired,
            helpObject: "",
            helpWhere: "",
            validateMandatory: false,
            errorType: EFieldErrorType.None,
            validatingHelp: false,
            hyperLinkJump: x[0].hyperlinkjump
          };
        });

        screenData.Toolbar.map((x: IToolbar) => {
          toolbarSetting.push({ toolbarKey: x.objectno, gridno: "", enable: true, visible: true });
        });

        screenData.GridConfig.map((x: IGrid) => {
          x.Toolbar.map((y: IToolbar & { gridno: string }) => {
            toolbarSetting.push({ toolbarKey: y.objectno, gridno: x.Config.gridno, enable: true, visible: true });
          })
        });

        draftState.objects[screenData.objectId] = {
          objectId: screenData.objectId,
          activity: EActivity.None,
          ScreenConfig: {
            TcodeConfiguration: screenData.TcodeConfiguration,
            Fields: {
              FieldConfig: screenData.FieldConfig
            },
            Grids: {
              objectId: screenData.objectId,
              GridConfig: screenData.GridConfig,
              ref: {}
            },
            ControlTranslation: screenData.ControlTranslation,
            CompanyDimensionFields: screenData.CompanyDimensionFields,
            Toolbar: screenData.Toolbar,
            ActionProcess: screenData.ActionProcess,
            GridUserConfig: screenData.GridUserConfig,
            UserDefaultParameter: screenData.UserDefaultParameter,
            ProcessReports: screenData.ProcessReports,
            ProcessCodeApplicationDataFormat: screenData.ProcessCodeApplicationDataFormat
          },
          Attachment: {
            uploadingAttachments: [],
            attachments: [],
            isModalOpen: false,
            isSavingModalOpen: false,
            openModalGridno: "",
            openModalGridLineId: 0,
            removeAttachmentIds: []
          },
          model: {},
          fieldOptions: fieldConfigObject,
          gridOptions: gridConfigObject,
          subGrid: [],
          otherState: {
            isActivityModalOpen: false,
            isCommentsModalOpen: false,
            userDocumentHelpFilter: screenData.userDocumentHelpFilter,
            userApprovalOption: {
              isApprover: false,
              approverPRID: 0,
              status: ""
            },
            activeScreenTab: { controlId: [] },
            groupLabelHide: [],
            toolbarSetting: toolbarSetting,
            screenTab: [],
            screenAccordion: {},
            isSummaryPopupOpen: false,
            isReversePopupOpen: false,
            requestInQueue: {},
            customState: {},
            comments: [],
            forceDisabledButton: {
              edit: false,
              delete: false,
              reverse: false
            }
          },
          screenOptions: {
            ScreenInitialized: false,
            isEntered: false
          },
          dimension: []
        };
        draftState.activeObjectId = screenData.objectId;

      });
    },

    setActiveObjectId(state, action: PayloadAction<string>) {
      return produce(state, (draftState) => {
        draftState.activeObjectId = action.payload;
      });
    },

    closeEntryFormObject(state, action: PayloadAction<string>) {
      const objectId = action.payload;
      return produce(state, (draftState) => {

        if (draftState.objects[objectId])
          delete draftState.objects[objectId];

        // if (draftState.activeObjectId == objectId) {
        //   const objectKeys = Object.keys(draftState.objects);
        //   if (objectKeys.length)
        //     draftState.activeObjectId = objectKeys[0];
        //   else
        //     draftState.activeObjectId = "";
        // }

      });
    },

    changeObjectActivity(state, action: PayloadAction<{ objectId: string, activity: EActivity }>) {
      const payload = action.payload;

      // On Edit Visible checks are commented because while display it's already set

      return produce(state, (draftState) => {

        const activeObject = draftState.objects[payload.objectId];
        const screenConfig = activeObject.ScreenConfig;

        activeObject.activity = payload.activity;
        //#region Field Setting
        Object.entries(screenConfig.Fields.FieldConfig.groupBy("fieldid")).map(([key, x]) => {

          const fieldConfig = x.filter((y) => y.activityno == payload.activity)[0] || x.filter((y) => y.activityno == "")[0];

          let enable = false;
          let visible = false;
          let validateMandatory = false;
          let helpobjectno = "";
          let helpwhere = "";

          if (fieldConfig) {

            if (payload.activity == EActivity.Create) {
              visible = fieldConfig.createvisible;
              if (!(screenConfig.TcodeConfiguration.dockey == key && screenConfig.TcodeConfiguration.numberrangeisapplied)) { // Reverse Check
                enable = fieldConfig.createenable;

                helpobjectno = fieldConfig.helpobjectno;
                helpwhere = fieldConfig.helpwhere;
                validateMandatory = fieldConfig.validatemandatory;
              }
            }
            else if (payload.activity == EActivity.Edit) {
              visible = activeObject.fieldOptions[key].visible;
              // visible = fieldConfig.changevisible;
              if (!(screenConfig.TcodeConfiguration.dockey == key && screenConfig.TcodeConfiguration.numberrangeisapplied)) { // Reverse Check
                enable = fieldConfig.changeenable;

                helpobjectno = fieldConfig.helpobjectno;
                helpwhere = fieldConfig.helpwhere;
                validateMandatory = fieldConfig.validatemandatory;
              }
            }
            else if (payload.activity == EActivity.View) {
              enable = false;
              visible = fieldConfig.changevisible;
            }

          }

          activeObject.fieldOptions[key].dimensionrequired = fieldConfig.dimensionrequired;
          activeObject.fieldOptions[key].visible = visible;
          activeObject.fieldOptions[key].enable = enable;
          activeObject.fieldOptions[key].helpObject = helpobjectno;
          activeObject.fieldOptions[key].helpWhere = helpwhere;
          activeObject.fieldOptions[key].validateMandatory = validateMandatory;
          activeObject.fieldOptions[key].errorType = EFieldErrorType.None;
          activeObject.fieldOptions[key].validatingHelp = false;

        });
        //#endregion

        //#region Grid Columns Setting 
        screenConfig.Grids.GridConfig.map(x => {

          Object.entries(x.Header.groupBy("fieldid")).map(([key, y]) => {
            const fieldConfig = y.filter((z) => z.activityno == payload.activity)[0] || y.filter((z) => z.activityno == "")[0];

            let enable = false;
            let visible = false;
            let validatemandatory = false;
            let helpobjectno = "";
            let helpwhere = "";

            if (fieldConfig) {
              const companyHidden = x.Config.companyGridCustomization.columnHidden.indexOf(key) != -1;
              const userHidden = x.Config.userGridCustomization.columnHidden.indexOf(key) != -1;

              if (payload.activity == EActivity.Create) {
                enable = fieldConfig.createenable;
                visible = (companyHidden || userHidden) ? false : fieldConfig.createvisible
              }
              else if (payload.activity == EActivity.Edit) {
                enable = fieldConfig.changeenable;
                visible = activeObject.gridOptions[x.Config.gridno][key].visible;
                // visible = (companyHidden || userHidden) ? false : fieldConfig.changevisible;
              }
              else if (payload.activity == EActivity.View) {
                enable = false;
                visible = (companyHidden || userHidden) ? false : fieldConfig.changevisible;
              }

              helpobjectno = fieldConfig.helpobjectno;
              helpwhere = fieldConfig.helpwhere;
              validatemandatory = fieldConfig.validatemandatory;
            }

            if (fieldConfig.fieldhyperlink) {
              enable = false;
            }

            activeObject.gridOptions[x.Config.gridno][key].enable = enable;
            activeObject.gridOptions[x.Config.gridno][key].visible = visible;
            activeObject.gridOptions[x.Config.gridno][key].helpObject = helpobjectno;
            activeObject.gridOptions[x.Config.gridno][key].helpWhere = helpwhere;
            activeObject.gridOptions[x.Config.gridno][key].validateMandatory = validatemandatory;

            activeObject.gridOptions[x.Config.gridno][key].errorTypeInRows = [];
            activeObject.gridOptions[x.Config.gridno][key].validatingHelpRows = [];

            if (y.find(z => z.dimensionrequired)) {
              // activeObject.gridOptions[x.Config.gridno][`dimension${key}values`].enable = enable;
              activeObject.gridOptions[x.Config.gridno][`dimension${key}values`].visible = visible;
              activeObject.gridOptions[x.Config.gridno][`dimension${key}values`].validateMandatory = false;
              activeObject.gridOptions[x.Config.gridno][`dimension${key}values`].errorTypeInRows = [];
              activeObject.gridOptions[x.Config.gridno][`dimension${key}values`].validatingHelpRows = [];
            }

          })

        });
        //#endregion

      });

    },

    changeScreenOptions(state, action: PayloadAction<Partial<IScreenOptions>>) {
      const payload = action.payload;

      return produce(state, (draftState) => {

        const screenOptions = draftState.objects[draftState.activeObjectId].screenOptions;
        draftState.objects[draftState.activeObjectId].screenOptions = {
          ...screenOptions,
          ...payload
        };

      });

    },

    resetScreenOptions(state) {

      return produce(state, (draftState) => {

        const object = draftState.objects[draftState.activeObjectId];
        const ScreenConfig = object.ScreenConfig;

        object.screenOptions = {
          ScreenInitialized: false,
          isEntered: false
        };

        // const fieldConfigObject: KeyValueObject<ITModelFieldProperties<KeyValueObject>> = {};
        // const gridConfigObject: KeyValueObject<KeyValueObject<ITModelGridProperties<KeyValueObject>>> = {};

        // // ! extra code
        // ScreenConfig.Grids.GridConfig.map((x: IGrid) => {

        //   gridConfigObject[x.Config.gridno] = {};

        //   x.Header.map((y) => {
        //     gridConfigObject[x.Config.gridno][y.fieldid] = {
        //       fieldId: y.fieldid,
        //       visible: y.createvisible,
        //       enable: y.createenable,
        //       helpObject: y.helpobjectno,
        //       helpWhere: y.helpwhere,
        //       validateMandatory: y.validatemandatory,
        //       validatingHelpRows: [],
        //       errorTypeInRows: [],
        //     };
        //     if (y.dimensionrequired) {
        //       gridConfigObject[x.Config.gridno]["dimension-" + y.fieldid] = {
        //         fieldId: "dimension-" + y.fieldid,
        //         visible: y.createvisible,
        //         enable: y.createenable,
        //         helpObject: y.helpobjectno,
        //         helpWhere: y.helpwhere,
        //         validateMandatory: y.validatemandatory,
        //         validatingHelpRows: [],
        //         errorTypeInRows: [],
        //       };
        //     }
        //   });

        // });

        // ScreenConfig.Fields.FieldConfig.map((x: any) => {
        //   fieldConfigObject[x.fieldid] = {
        //     fieldId: x.fieldid,
        //     visible: x.createvisible,
        //     enable: x.createenable,
        //     helpObject: x.helpobjectno,
        //     helpWhere: x.helpwhere,
        //     validateMandatory: x.validatemandatory,
        //     errorType: EFieldErrorType.None,
        //     validatingHelp: false
        //   };
        // });

        // object.fieldOptions = fieldConfigObject;
        // object.gridOptions = gridConfigObject;

        // ! reset option is not set correct

        if (object.otherState.screenAccordion["headerinformation"] == false)
          object.otherState.screenAccordion["headerinformation"] = true;
        object.otherState.userApprovalOption = {
          isApprover: false,
          approverPRID: 0,
          status: ""
        };
        object.otherState.customState = {};
        object.otherState.comments = [];
        object.otherState.groupLabelHide = [];
        object.otherState.toolbarSetting.map(x => {
          x.enable = true;
          x.visible = true;
        });
        object.Attachment = {
          uploadingAttachments: [],
          attachments: [],
          isModalOpen: false,
          isSavingModalOpen: false,
          openModalGridno: "",
          openModalGridLineId: 0,
          removeAttachmentIds: []
        };
        object.otherState.screenTab.map(x => {
          x.visible = true;
        });
        object.otherState.activeScreenTab = { controlId: [] };
        object.otherState.forceDisabledButton = {
          edit: false,
          delete: false,
          reverse: false
        };
        object.dimension = [];

      });
    },

    //#endregion

    //#region Field State Manage

    fieldHelpValidate(state, action: PayloadAction<{ fieldId: string; fieldValue: string, helpObject: string; helpWhere: string; }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        objects[state.activeObjectId].fieldOptions[payload.fieldId].validatingHelp = true;
      });
    },

    fieldHelpValidateEnd(state, action: PayloadAction<{ fieldId: string; }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        objects[state.activeObjectId].fieldOptions[payload.fieldId].validatingHelp = false;
      });
    },

    setFieldModelValue(state, action: PayloadAction<TFieldValue>) {
      const fieldData = action.payload;

      return produce(state, ({ objects }) => {
        const oldValue = objects[fieldData.objectId].model[fieldData.fieldId] || "";
        objects[fieldData.objectId].model[fieldData.fieldId] = fieldData.value;

        if ((fieldData.value || "") != oldValue && objects[fieldData.objectId].ScreenConfig.Fields.FieldConfig.find(x => x.fieldid == fieldData.fieldId)?.dimensionrequired) {
          let dimensionSetFieldid = fieldData.fieldId.replace("accountno", "") + "dimensionset";
          objects[fieldData.objectId].model[dimensionSetFieldid] = null;
          objects[fieldData.objectId].model[`dimension${fieldData.fieldId}values`] = null;
        }

      });

    },

    setFieldVisibility(state, action: PayloadAction<{ visible: boolean; fieldId: string; }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        if (!objects[state.activeObjectId].fieldOptions[payload.fieldId])
          console.error(`Field Id ${payload.fieldId} is not found in configuration`);

        objects[state.activeObjectId].fieldOptions[payload.fieldId].visible = payload.visible;
      });
    },

    setFieldEditable(state, action: PayloadAction<{ enable: boolean; fieldId: string; }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        objects[state.activeObjectId].fieldOptions[payload.fieldId].enable = payload.enable;
      });
    },

    setFieldHelpObject(state, action: PayloadAction<{ helpObject: string; fieldId: string; helpWhere: string }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        objects[state.activeObjectId].fieldOptions[payload.fieldId].helpObject = payload.helpObject;
        objects[state.activeObjectId].fieldOptions[payload.fieldId].helpWhere = payload.helpWhere;
      });
    },

    setFieldMandatory(state, action: PayloadAction<{ isFieldMandatory: boolean; fieldId: string; }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        objects[state.activeObjectId].fieldOptions[payload.fieldId].validateMandatory = payload.isFieldMandatory;
        if (!payload.isFieldMandatory) {
          if (objects[state.activeObjectId].fieldOptions[payload.fieldId].errorType == EFieldErrorType.EmptyFieldError) {
            objects[state.activeObjectId].fieldOptions[payload.fieldId].errorType = EFieldErrorType.None;
          }
        }
      });
    },

    setFieldError(state, action: PayloadAction<{ errorType: EFieldErrorType, isError: boolean; fieldId: string; }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        if (objects[state.activeObjectId].fieldOptions[payload.fieldId]) {
          if (payload.isError) {
            objects[state.activeObjectId].fieldOptions[payload.fieldId].errorType = payload.errorType;
          }
          else {
            if (objects[state.activeObjectId].fieldOptions[payload.fieldId].errorType == payload.errorType)
              objects[state.activeObjectId].fieldOptions[payload.fieldId].errorType = EFieldErrorType.None;
          }
        }
      });
    },

    //#endregion

    //#region Grid State Manage

    gridHelpValidate(state, action: PayloadAction<{ gridno: string; rowIndex: number; columnId: string; columnValue: string, isSubGrid: boolean, helpObject: string; helpWhere: string; }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        const gridColumnOptions = objects[state.activeObjectId].gridOptions[payload.gridno][payload.columnId];

        const findExistingIndex = gridColumnOptions.validatingHelpRows.findIndex(x => x.rowIndex == payload.rowIndex);
        if (findExistingIndex != -1)
          gridColumnOptions.validatingHelpRows.splice(findExistingIndex, 1);

        gridColumnOptions.validatingHelpRows.push({
          rowIndex: payload.rowIndex
        });

      });
    },

    gridHelpValidateEnd(state, action: PayloadAction<{ gridno: string; rowIndex: number; columnId: string; }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        const gridColumnOptions = objects[state.activeObjectId].gridOptions[payload.gridno][payload.columnId];

        const findExistingIndex = gridColumnOptions.validatingHelpRows.findIndex(x => x.rowIndex == payload.rowIndex);
        if (findExistingIndex != -1)
          gridColumnOptions.validatingHelpRows.splice(findExistingIndex, 1);

      });
    },

    setGridRowError(state, action: PayloadAction<{ gridno: string; rowIndex: number; columnId: string; errorType: EFieldErrorType, isError: boolean; }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        const gridColumnOptions = objects[state.activeObjectId].gridOptions?.[payload.gridno]?.[payload.columnId];

        if (gridColumnOptions) {
          const findRow = gridColumnOptions.errorTypeInRows.find(x => x.rowIndex == payload.rowIndex);

          if (payload.isError) {
            if (findRow) {
              findRow.errorType = payload.errorType;
            }
            else {
              gridColumnOptions.errorTypeInRows.push({
                rowIndex: payload.rowIndex,
                columnId: payload.columnId,
                errorType: payload.errorType
              });
            }
          }
          else {
            if (findRow && findRow.errorType == payload.errorType) {
              const findIndex = gridColumnOptions.errorTypeInRows.findIndex(x => x.rowIndex == payload.rowIndex);

              if (findIndex != -1)
                gridColumnOptions.errorTypeInRows.splice(findIndex, 1);

            }
          }
        }
      });
    },

    removeGridRowError(state, action: PayloadAction<{ gridno: string; rowIndex: number; }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        const gridColumnOptions = objects[state.activeObjectId].gridOptions?.[payload.gridno];

        Object.entries(gridColumnOptions).map(([key, value]) => {
          const findIndex = value.errorTypeInRows.findIndex(x => x.rowIndex == payload.rowIndex);

          gridColumnOptions[key].errorTypeInRows = gridColumnOptions[key].errorTypeInRows.map((x, i) => {
            if (x.rowIndex > payload.rowIndex)
              x.rowIndex--;
            return x;
          });

          if (findIndex != -1) {
            gridColumnOptions[key].errorTypeInRows.splice(findIndex, 1);
          }
        });
      });
    },

    setGridInitialModel(state, action: PayloadAction<{ gridno: string; }>) {
      const payload = action.payload;

      return produce(state, ({ objects }) => {
        const collectionName = payload.gridno.getCollectionNameFromGridno();

        if (!objects[state.activeObjectId].model[collectionName])
          objects[state.activeObjectId].model[collectionName] = [];

      });
    },

    addGridNewRow(state, action: PayloadAction<{ gridno: string, data: ArrayKeyValueObject, rowIndex: number }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        const emptyHeaderObject: any = {};
        const activeObject = objects[state.activeObjectId];
        const collectionName = payload.gridno.getCollectionNameFromGridno();

        const gridRow = payload.data;

        // activeObject.ScreenConfig.Grids.GridConfig
        //   .filter((x) => x.Config.gridno == payload.gridno)[0]
        //   .Header.map((x) => {
        //     if (BaseMethods.getControlType(x.controltypeno) == "checkbox")
        //       emptyHeaderObject[x.fieldid] = false;
        //     else if (BaseMethods.getControlType(x.controltypeno) == "number")
        //       emptyHeaderObject[x.fieldid] = 0;
        //     else if (BaseMethods.getControlType(x.controltypeno) == "date")
        //       emptyHeaderObject[x.fieldid] = new Date("1900-01-01T00:00:00").toJSON();
        //     else if (BaseMethods.getControlType(x.controltypeno) == "time")
        //       emptyHeaderObject[x.fieldid] = new Date("1900-01-01T00:00:00").toJSON();
        //     else if (x.fieldhyperlink)
        //       emptyHeaderObject[x.fieldid] = [];
        //     else
        //       emptyHeaderObject[x.fieldid] = null;
        //   });

        let newItem = gridRow;

        const modelData = (activeObject.model[collectionName] as ArrayKeyValueObject);
        modelData.splice(payload.rowIndex, 0, newItem);

        modelData.map((x, i) => {
          x.lineid = i + 1;
        });

      });
    },

    changeGridRow(state, action: PayloadAction<{ gridno: string, newItem: KeyValueObject, rowIndex: number }>) {
      const payload = action.payload;

      return produce(state, ({ objects }) => {
        const collectionName = payload.gridno.getCollectionNameFromGridno();

        const gridRow = payload.newItem;

        // objects[state.activeObjectId].ScreenConfig.Grids.GridConfig
        //   .filter((x) => x.Config.gridno == payload.gridno)[0]
        //   .Header.map((x) => {
        //     if (BaseMethods.getControlType(x.controltypeno) == "date")
        //       if (gridRow[x.fieldid] instanceof Date)
        //         gridRow[x.fieldid] = gridRow[x.fieldid].toJSON();
        //   });

        let items = objects[state.activeObjectId].model[collectionName],
          index = payload.rowIndex,
          oldItem = items[index],
          // create a cloned item with the property changes applied
          clonedItem = copyObject({}, oldItem, gridRow);


        objects[state.activeObjectId].model[collectionName] = items
          .slice(0, index)
          .concat([clonedItem])
          .concat(items.slice(index + 1));

      });
    },

    deleteGridRow(state, action: PayloadAction<{ gridno: string, rowIndex: number }>) {
      const payload = action.payload;

      return produce(state, ({ objects }) => {
        const collectionName = payload.gridno.getCollectionNameFromGridno();

        let items = objects[state.activeObjectId].model[collectionName] as Array<any>;
        let index = payload.rowIndex;
        let gridOptions = objects[state.activeObjectId].gridOptions[payload.gridno];

        let data = items
          .slice(0, index)
          .concat(items.slice(index + 1)) as Array<any>;

        if (gridOptions["lineid"]) {
          data = data.map((x, i) => {
            x.lineid = i + 1;

            return x;
          });
        }

        objects[state.activeObjectId].model[collectionName] = data;
      });
    },

    setGridColumnMandatory(state, action: PayloadAction<{ columnId: string; gridId: string; isMandatory: boolean; }>) {
      const payload = action.payload;

      return produce(state, ({ objects }) => {
        objects[state.activeObjectId].gridOptions[payload.gridId][payload.columnId].validateMandatory = payload.isMandatory;
      });
    },

    setGridColumnVisibility(state, action: PayloadAction<{ columnId: string; gridId: string; visible: boolean; }>) {
      const payload = action.payload;

      return produce(state, ({ objects }) => {

        objects[state.activeObjectId].gridOptions[payload.gridId][payload.columnId].visible = payload.visible;

        // if (payload.configAlso) {
        //   objects[state.activeObjectId].ScreenConfig.Grids.GridConfig.map(x => {
        //     if (x.Config.gridno == payload.gridId)
        //       x.Header.map(y => {
        //         if (y.fieldid == payload.columnId) {
        //           y.changevisible = payload.visible;
        //           y.createvisible = payload.visible;
        //         }
        //       })
        //   })
        // }

      });
    },

    setGridColumnLabel(state, action: PayloadAction<{ columnId: string; gridId: string; label: string; configAlso?: boolean }>) {
      const payload = action.payload;

      return produce(state, ({ objects }) => {

        objects[state.activeObjectId].gridOptions[payload.gridId][payload.columnId].columnLabel = payload.label;

        if (payload.configAlso) {
          objects[state.activeObjectId].ScreenConfig.Grids.GridConfig.map(x => {
            if (x.Config.gridno == payload.gridId)
              x.Header.map(y => {
                if (y.fieldid == payload.columnId) {
                  y.fieldlabel = payload.label;
                }
              })
          })
        }

      });
    },

    setGridColumnEditable(state, action: PayloadAction<{ columnId: string; gridId: string; enable: boolean; }>) {
      const payload = action.payload;

      return produce(state, ({ objects }) => {
        objects[state.activeObjectId].gridOptions[payload.gridId][payload.columnId].enable = payload.enable;
      });
    },

    setGridColumnHelpObject(state, action: PayloadAction<{ columnId: string; gridId: string; helpObjectno: string; helpWhere: string; }>) {
      const payload = action.payload;

      return produce(state, ({ objects }) => {
        objects[state.activeObjectId].gridOptions[payload.gridId][payload.columnId].helpObject = payload.helpObjectno;
        objects[state.activeObjectId].gridOptions[payload.gridId][payload.columnId].helpWhere = payload.helpWhere;
      });
    },

    setGridColumnHyperLinkJump(state, action: PayloadAction<{ columnId: string; gridId: string; hyperLinkJump: string; }>) {
      const payload = action.payload;

      return produce(state, ({ objects }) => {
        objects[state.activeObjectId].gridOptions[payload.gridId][payload.columnId].hyperLinkJump = payload.hyperLinkJump;
      });
    },

    setGridCellData(state, action: PayloadAction<{ gridno: string; rowIndex: number; columnId: string; value: any }>) {
      const payload = action.payload;

      return produce(state, ({ objects }) => {

        const collectionName = payload.gridno.getCollectionNameFromGridno();
        if (objects[state.activeObjectId].model[collectionName][payload.rowIndex])
          objects[state.activeObjectId].model[collectionName][payload.rowIndex][payload.columnId] = payload.value;
      });
    },

    setGridRowsState(state, action) {
      const payload = action.payload;

      return produce(state, ({ objects }) => {
        const gridRows = copyObject(payload.gridRows);
        let newRows = [] as any;
        const collectionName = payload.gridno.getCollectionNameFromGridno();

        const headers = objects[state.activeObjectId].ScreenConfig.Grids.GridConfig
          .filter((x) => x.Config.gridno == payload.gridno)[0].Header;

        gridRows.map((x: any, i: number) => {
          let rows = {} as any;

          rows = copyObject(rows, x);

          headers.map((y) => {

            if (BaseMethods.getControlType(y.controltypeno) == "checkbox")
              rows[y.fieldid] = rows[y.fieldid] && rows[y.fieldid].toString().isEqual("true", "1") ? rows[y.fieldid] : false;
            else if (BaseMethods.getControlType(y.controltypeno) == "number")
              rows[y.fieldid] = rows[y.fieldid] ? Number(rows[y.fieldid]) : 0;
            else if (BaseMethods.getControlType(y.controltypeno).isEqual("date", "time")) {
              var d = new Date(rows[y.fieldid]);
              if (rows[y.fieldid] && !isNaN(new Date(rows[y.fieldid]).getTime()))
                rows[y.fieldid] = `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${("0" + d.getDate()).slice(-2)}T00:00:00`;
              else
                rows[y.fieldid] = "1900-01-01T00:00:00";
            }
            else if (BaseMethods.getControlType(y.controltypeno).isEqual("time"))
              rows[y.fieldid] = rows[y.fieldid] && !isNaN(new Date(rows[y.fieldid]).getTime()) ? new Date(rows[y.fieldid]).toJSON() : "1900-01-01T00:00:00";
            else if (y.fieldhyperlink)
              rows[y.fieldid] = rows[y.fieldid] ? rows[y.fieldid] : [];
            else
              rows[y.fieldid] = rows[y.fieldid] && rows[y.fieldid].toString().trim() ? rows[y.fieldid].toString().trim() : null;

          });

          rows["lineid"] = i + 1;

          newRows.push(rows);
        });

        objects[state.activeObjectId].model[collectionName] = newRows;
      });
    },

    setGridRowStateByStartingRow(state, action) {
      const payload = action.payload;

      return produce(state, ({ objects }) => {
        const gridRows = copyObject(payload.gridRows);
        const collectionName = payload.gridno.getCollectionNameFromGridno();
        let newRows = [] as any;

        const headers = objects[state.activeObjectId].ScreenConfig.Grids.GridConfig
          .filter((x) => x.Config.gridno == payload.gridno)[0].Header;

        gridRows.map((x: any, i: number) => {
          let rows = {} as any;

          rows = copyObject(rows, x);

          headers.map((y) => {

            if (BaseMethods.getControlType(y.controltypeno) == "checkbox")
              rows[y.fieldid] = rows[y.fieldid] && rows[y.fieldid].toString().isEqual("true", "1") ? rows[y.fieldid] : false;
            else if (BaseMethods.getControlType(y.controltypeno) == "number")
              rows[y.fieldid] = rows[y.fieldid] ? Number(rows[y.fieldid]) : 0;
            else if (BaseMethods.getControlType(y.controltypeno).isEqual("date", "time"))
              rows[y.fieldid] = rows[y.fieldid] && !isNaN(new Date(rows[y.fieldid]).getTime()) ? new Date(rows[y.fieldid]).toJSON() : "1900-01-01T00:00:00";
            else if (y.fieldhyperlink)
              rows[y.fieldid] = rows[y.fieldid] ? rows[y.fieldid] : [];
            else
              rows[y.fieldid] = rows[y.fieldid] && rows[y.fieldid].toString().trim() ? rows[y.fieldid].toString().trim() : null;

          });

          rows["lineid"] = payload.startingRow + i + 1;

          newRows.push(rows);
        });

        const gridLength = objects[state.activeObjectId].model[collectionName].length;

        objects[state.activeObjectId].model[collectionName].splice(payload.startingRow, gridLength);

        objects[state.activeObjectId].model[collectionName] = objects[state.activeObjectId].model[collectionName].concat(newRows);
      });
    },

    setUserGridColumnSetting(state, action: PayloadAction<{ gridno: string, gridCustomization: IGridObjectConfig["userGridCustomization"], forCompany: boolean }>) {
      const payload = action.payload;

      return produce(state, ({ objects }) => {
        const object = objects[state.activeObjectId];

        const gridConfig = object.ScreenConfig.Grids.GridConfig.find(x => x.Config.gridno == payload.gridno)?.Config;

        if (gridConfig) {
          if (payload.forCompany) {
            gridConfig.companyGridCustomization.columnHidden = payload.gridCustomization.columnHidden;
          }
          else
            gridConfig.userGridCustomization = payload.gridCustomization;
        }

      });
    },

    //#endregion

    //#region SubGrid State Manage

    openSubGrid(state, action: PayloadAction<Omit<ISubGrid, "modelData" | "parentRowData"> & { modelData: Array<{ [key: string]: any }> | null }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {

        const activeObject = draftState.objects[state.activeObjectId];
        const subGrids = activeObject.subGrid as Array<ISubGrid>;
        const collectionName = payload.parentGridno.getCollectionNameFromGridno();
        let subGridData: any = [];
        let parentRowData: KeyValueObject = {};
        let readOnly = false;
        let allowAddNewRow = payload.allowAddNewRow;
        let allowCopyRow = payload.allowCopyRow;
        let allowDeleteRow = payload.allowDeleteRow;

        if (!subGrids.length) {
          parentRowData = activeObject.model[collectionName][payload.parentRowIndex];
        }
        else {
          parentRowData = subGrids.filter(x => x.gridno == payload.parentGridno)[0].modelData[payload.parentRowIndex];
        }

        if (payload.modelData == null) {
          if (!subGrids.length) {
            subGridData = activeObject.model[collectionName][payload.parentRowIndex][payload.parentColumnId] || [];
          }
          else {
            subGridData = subGrids.filter(x => x.gridno == payload.parentGridno)[0].modelData[payload.parentRowIndex][payload.parentColumnId];
          }
        }
        else {
          subGridData = payload.modelData;
        }

        if (subGrids.length) {
          readOnly = subGrids[subGrids.length - 1].readOnly;
        }
        if (!readOnly) {
          readOnly = payload.readOnly;
        }

        const object: ISubGrid = {
          gridno: payload.gridno,
          modelData: subGridData,
          parentGridno: payload.parentGridno,
          parentColumnId: payload.parentColumnId,
          parentRowIndex: payload.parentRowIndex,
          parentRowData,
          readOnly,
          allowAddNewRow,
          allowCopyRow,
          allowDeleteRow
          // parentRow: payload.parentRow,
          // parentIndex: payload.parentIndex,
        };

        activeObject.subGrid?.push(object);

        // const subGridLengthForDimension = subGrids.length + 1;
        if ((activeObject.subGrid?.length || 0) == 1) {
          const filterDimensionForSubGrid = activeObject.dimension.filter(x => x.dimensionRelations.findIndex(y => y.startsWith(payload.parentGridno + "-" + payload.parentRowIndex)) != -1).cloneArray();

          filterDimensionForSubGrid.map(x => {
            x.dimensionRelations = x.dimensionRelations.filter(x => x.startsWith(payload.parentGridno + "-" + payload.parentRowIndex));
            x.isDraftDimension = true;
          });

          activeObject.dimension.push(...filterDimensionForSubGrid);
        }

      });
    },

    addSubGridNewRow(state, action: PayloadAction<{ gridno: string, data: KeyValueObject, rowIndex: number }>) {
      const payload = action.payload;

      return produce(state, ({ objects }) => {
        const emptyHeaderObject: any = {};
        const activeObject = objects[state.activeObjectId];
        const subGrids = activeObject.subGrid as Array<ISubGrid>;
        const activeSubGrids = subGrids[subGrids.length - 1];

        // activeObject.ScreenConfig.Grids.GridConfig
        //   .filter((x) => x.Config.gridno == payload.gridno)[0]
        //   .Header.map((x) => {
        //     if (BaseMethods.getControlType(x.controltypeno) == "checkbox")
        //       emptyHeaderObject[x.fieldid] = false;
        //     else if (BaseMethods.getControlType(x.controltypeno) == "number")
        //       emptyHeaderObject[x.fieldid] = 0;
        //     else if (BaseMethods.getControlType(x.controltypeno) == "date")
        //       emptyHeaderObject[x.fieldid] = new Date("1900-01-01T00:00:00").toJSON();
        //     else if (BaseMethods.getControlType(x.controltypeno) == "time")
        //       emptyHeaderObject[x.fieldid] = new Date("1900-01-01T00:00:00").toJSON();
        //     else if (x.fieldhyperlink)
        //       emptyHeaderObject[x.fieldid] = [];
        //     else
        //       emptyHeaderObject[x.fieldid] = null;
        //   });

        // let newItem = copyObject(emptyHeaderObject, payload.data);
        let newItem = payload.data;

        const modelData = (activeSubGrids?.modelData as ArrayKeyValueObject);
        modelData.splice(payload.rowIndex, 0, newItem);

        modelData.map((x, i) => {
          x.lineid = i + 1;
        });

      });
    },

    changeSubGridRow(state, action: PayloadAction<{ gridno: string, newItem: KeyValueObject, rowIndex: number }>) {
      const payload = action.payload;

      return produce(state, ({ objects }) => {
        const subGrids = objects[state.activeObjectId].subGrid as Array<ISubGrid>;
        let items = subGrids[subGrids.length - 1].modelData,
          index = payload.rowIndex,
          oldItem = items[index],
          // create a cloned item with the property changes applied
          clonedItem = Object.freeze(copyObject({}, oldItem, payload.newItem));

        subGrids[subGrids.length - 1].modelData = items
          .slice(0, index)
          .concat([clonedItem])
          .concat(items.slice(index + 1));
      });
    },

    deleteSubGridRow(state, action: PayloadAction<{ gridno: string, rowIndex: number }>) {
      const payload = action.payload;

      return produce(state, ({ objects }) => {
        const subGrids = objects[state.activeObjectId].subGrid as Array<ISubGrid>;
        let items = subGrids[subGrids.length - 1].modelData as Array<any>;
        let index = payload.rowIndex;
        let gridOptions = objects[state.activeObjectId].gridOptions[payload.gridno];

        let data = items
          .slice(0, index)
          .concat(items.slice(index + 1)) as Array<any>;

        if (gridOptions["lineid"]) {
          data = data.map((x, i) => {
            x.lineid = i + 1;

            return x;
          });
        }

        subGrids[subGrids.length - 1].modelData = data;
      });
    },

    saveSubGrid(state) {
      return produce(state, ({ objects }) => {
        const activeObject = objects[state.activeObjectId];

        if (activeObject.subGrid) {

          const subGrids = activeObject?.subGrid as Array<ISubGrid>;
          const subGridObject = subGrids[subGrids.length - 1];

          if (subGrids.length > 1) {
            if ((subGrids.filter(x => x.gridno == subGridObject.parentGridno)[0] as ISubGrid)?.modelData) {
              let modelData: any = subGridObject.modelData;

              ((objects[state.activeObjectId].subGrid as Array<ISubGrid>).filter(x => x.gridno == subGridObject.parentGridno)[0] as ISubGrid).modelData[subGridObject.parentRowIndex][subGridObject.parentColumnId] = modelData;

            }
          }
          else {

            let modelData = subGridObject.modelData;

            const collectionName = subGridObject.parentGridno.getCollectionNameFromGridno();

            objects[state.activeObjectId].model[collectionName][subGridObject.parentRowIndex][subGridObject.parentColumnId] = modelData;

            activeObject.dimension.filter(x => x.isDraftDimension == true).cloneArray().map(x => {
              x.dimensionRelations.map(relationKey => {

                let dimensionEmptyRelationIndex = -1;

                activeObject.dimension.map((y, i) => {
                  if (y.isDraftDimension == false) {
                    const findIndex = y.dimensionRelations.indexOf(relationKey);
                    if (findIndex != -1) {
                      y.dimensionRelations.splice(findIndex, 1);
                      if (!y.dimensionRelations.length)
                        dimensionEmptyRelationIndex = i;
                    }
                  }
                });
                if (dimensionEmptyRelationIndex != -1)
                  activeObject.dimension.splice(dimensionEmptyRelationIndex, 1);

                if (x.dimensionSet) {

                  let dimensionSetFound = false;
                  activeObject.dimension.filter(x => x.isDraftDimension == false).map((y, i) => {
                    if (y.dimensionSet == x.dimensionSet) {
                      dimensionSetFound = true;
                      y.dimensionRelations.push(relationKey);
                    }
                  });

                  if (!dimensionSetFound)
                    activeObject.dimension.push({
                      dimensionSet: x.dimensionSet,
                      dimensionRelations: [relationKey],
                      dimensionValues: x.dimensionValues,
                      isDraftDimension: false
                    });

                }

              });
            });

          }

        }
      });
    },

    closeSubGrid(state) {
      return produce(state, ({ objects }) => {
        const activeObject = objects[state.activeObjectId];
        const subGrids = activeObject.subGrid as Array<ISubGrid>;

        (activeObject?.subGrid as Array<ISubGrid>).splice(subGrids.length - 1, subGrids.length)

        if (!subGrids.length)
          activeObject.dimension = activeObject.dimension.filter(x => x.isDraftDimension == false);

      });
    },

    setSubGridCellData(state, action: PayloadAction<{ gridno: string; rowIndex: number; columnId: string; value: any }>) {
      const payload = action.payload;

      return produce(state, ({ objects }) => {

        const subGrids = objects[state.activeObjectId].subGrid as Array<ISubGrid>;

        const lastSubGrid = subGrids[subGrids.length - 1];

        if (lastSubGrid.gridno == payload.gridno) {
          lastSubGrid.modelData[payload.rowIndex][payload.columnId] = payload.value;
        }

      });
    },

    //#endregion

    //#region Screen Tab
    toggleActiveScreenTab(state, action: PayloadAction<{ controlId: string, activeTabId?: string }>) {
      const payload = action.payload;
      return produce(state, (draftState) => {
        if (payload.activeTabId && draftState.objects[draftState.activeObjectId].otherState.activeScreenTab.controlId.includes(payload.activeTabId)) {
          const index = draftState.objects[draftState.activeObjectId].otherState.activeScreenTab.controlId.indexOf(payload.activeTabId);
          draftState.objects[draftState.activeObjectId].otherState.activeScreenTab.controlId.splice(index, 1)
        }
        if (payload.controlId)
          draftState.objects[draftState.activeObjectId].otherState.activeScreenTab.controlId.push(payload.controlId);

      });
    },

    pushScreenTabs(state, action: PayloadAction<Array<{ controlId: string; visible: boolean }>>) {
      return produce(state, (draftState) => {
        draftState.objects[draftState.activeObjectId].otherState.screenTab.push(...action.payload);
      });
    },

    changeScreenTabVisibility(state, action: PayloadAction<{ controlId: string, visible: boolean }>) {
      const payload = action.payload;
      return produce(state, (draftState) => {
        const otherState = draftState.objects[draftState.activeObjectId].otherState;
        const findTab = otherState.screenTab.find(x => x.controlId == payload.controlId);
        if (findTab) {
          if (!payload.visible) {
            const findActiveTabIndex = otherState.activeScreenTab.controlId.indexOf(payload.controlId);
            if (findActiveTabIndex != -1)
              otherState.activeScreenTab.controlId.splice(findActiveTabIndex, 1);
          }

          findTab.visible = payload.visible;
        }
      })
    },
    //#endregion

    //#region Screen Accordion

    toggleActiveScreenAccordion(state, action: PayloadAction<{ controlId: string }>) {
      const payload = action.payload;
      return produce(state, (draftState) => {
        const accordions = draftState.objects[draftState.activeObjectId].otherState.screenAccordion;

        accordions[payload.controlId] = !accordions[payload.controlId];

      });
    },

    toggleScreenAccordionByControlIds(state, action: PayloadAction<{ controlIds: string[], open: boolean }>) {
      const payload = action.payload;
      return produce(state, (draftState) => {
        const accordions = draftState.objects[draftState.activeObjectId].otherState.screenAccordion;

        payload.controlIds.map(x => {
          accordions[x] = payload.open;
        });

      });
    },

    //#endregion

    //#region Dimension


    getDimensionValuesFromDimensionSetPromiseResolver: {
      reducer: (state, action: PayloadAction<{ dimensionSet: string[] }, string, { resolve: (value: unknown) => void, reject: (value: unknown) => void } | undefined>) => { },
      prepare: (payload: { dimensionSet: string[] }, meta?: { resolve: (value: unknown) => void, reject: (value: unknown) => void } | undefined) => ({
        payload,
        meta,
      })
    },

    fillDimensionFromDimensionSet(state, action: PayloadAction<{ dimensionSet: string, relationKey?: string, dimensionSetFieldid?: string, dimensionSetting?: { gridno: string, rowIndex: number } }>) { },
    getAccountDimensions(state, action: PayloadAction<{ accountno: string, accountFieldId: string, dimensionSetFieldid: string, isSubGrid: boolean, gridno?: string, rowIndex?: number }>) { },
    setAccountDimensions(state, action: PayloadAction<{ accountno: string, fieldConfig: Array<IFieldConfig>, accountFieldId: string, dimensionSetFieldid: string, isSubGrid: boolean, gridno?: string, rowIndex?: number }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {

        let object = objects[state.activeObjectId];
        let otherState = object.otherState;
        let allDimensions = object.dimension;
        let subGrid = object.subGrid;

        let isDraftDimension = payload.isSubGrid;

        otherState.accountDimensionSetting = {
          isOpen: true,
          position: { x: 0, y: 0 },
          accountFieldId: payload.accountFieldId,
          dimensionValues: {},
          gridno: payload.gridno,
          rowIndex: payload.rowIndex,
          accountno: payload.accountno,
          accountDimensions: otherState.accountDimensionSetting?.accountDimensions || [],
          dimensionSetFieldid: payload.dimensionSetFieldid,
          isSubGrid: payload.isSubGrid
        };

        const dimension = otherState.accountDimensionSetting;

        const findDimensionIndex = dimension.accountDimensions.findIndex(x => x.accountno == payload.accountno);

        // ! Extra code
        if (findDimensionIndex != -1) {
          dimension.accountDimensions.splice(findDimensionIndex, 1);
        }

        dimension.accountDimensions.push({
          accountno: payload.accountno,
          fieldConfig: payload.fieldConfig
        });

        // payload.fieldConfig.map(x => {
        //   let value = "";

        //   if (payload.gridno && payload.rowIndex != undefined && payload.rowIndex != -1) {
        //     let model: any[] = [];

        //     if (subGrid?.length) {
        //       const activeSubGrid = subGrid[subGrid.length - 1];
        //       if (activeSubGrid.gridno == payload.gridno) {
        //         model = activeSubGrid.modelData;
        //       }
        //     }
        //     else {
        //       const collectionName = payload.gridno.getCollectionNameFromGridno();
        //       model = object.model[collectionName];
        //     }

        //     if (model[payload.rowIndex] && model[payload.rowIndex][x.fieldid])
        //       value = model[payload.rowIndex][x.fieldid];
        //   }
        //   else {
        //     value = object.model[x.fieldid];
        //   }

        //   dimension.dimensionValues[x.fieldid] = value;
        // });

        let dimensionSet = "";
        if (payload.gridno && payload.rowIndex != undefined && payload.rowIndex != -1) {
          let model: any[] = [];

          if (subGrid?.length) {
            const activeSubGrid = subGrid[subGrid.length - 1];
            if (activeSubGrid.gridno == payload.gridno) {
              model = activeSubGrid.modelData;
            }
          }
          else {
            const collectionName = payload.gridno.getCollectionNameFromGridno();
            model = object.model[collectionName];
          }

          if (model[payload.rowIndex] && model[payload.rowIndex][payload.dimensionSetFieldid])
            dimensionSet = model[payload.rowIndex][payload.dimensionSetFieldid];
        }
        else {
          dimensionSet = object.model[payload.dimensionSetFieldid];
        }

        if (dimensionSet) {
          dimension.dimensionValues = allDimensions.filter(x => x.isDraftDimension == isDraftDimension).find(x => x.dimensionSet == dimensionSet)?.dimensionValues || {};
        }

      });
    },

    setAccountDimensionValue(state, action: PayloadAction<{ fieldId: string, value: string }>) {

      const payload = action.payload;
      return produce(state, ({ objects }) => {
        const dimension = objects[state.activeObjectId].otherState.accountDimensionSetting;
        if (dimension)
          dimension.dimensionValues[payload.fieldId] = payload.value;
      });
    },

    closeDimension(state) {

      // const payload = action.payload;
      return produce(state, ({ objects }) => {
        const object = objects[state.activeObjectId];
        const dimension = object.otherState.accountDimensionSetting;

        if (dimension) {

          // if (payload.save) {

          //   if (dimension.gridno && dimension.rowIndex != undefined && dimension.rowIndex != -1) {

          //     let gridModel: any[] = [];

          //     if (subGrid?.length) {
          //       const lastSubGrid = subGrid[subGrid.length - 1];
          //       if (lastSubGrid.gridno == dimension.gridno) {
          //         gridModel = lastSubGrid.modelData;
          //       }
          //     }
          //     else {
          //       const collectionName = dimension.gridno.getCollectionNameFromGridno();
          //       gridModel = object.model[collectionName];
          //     }

          //     if (gridModel[dimension.rowIndex]) {
          //       gridModel[dimension.rowIndex] = {
          //         ...gridModel[dimension.rowIndex],
          //         ...dimension.dimensionValues
          //       }
          //     }

          //   }
          //   else {

          //     object.model = {
          //       ...object.model,
          //       ...dimension.dimensionValues
          //     }

          //   }
          // }

          dimension.isOpen = false;
          dimension.dimensionValues = {};
          dimension.accountno = "";
          dimension.accountFieldId = "";
          dimension.gridno = "";
          dimension.rowIndex = -1;

        }
      });

    },

    upsertDimension(state, action: PayloadAction<{ dimensionValues: KeyValueObject<string>, relationKey?: string, dimensionSetFieldid?: string, dimensionSetting?: { gridno?: string, rowIndex?: number }, replace?: boolean }>) {

      const payload = action.payload;
      const dimensionConfig = payload.dimensionSetting;
      const dimensionSetFieldid = payload.dimensionSetFieldid || "dimensionset";
      const dimensionSetFieldValuesKey = "dimensionaccountnovalues";
      let dimensionSetFieldValues = "";

      return produce(state, (draftState) => {

        const object = draftState.objects[state.activeObjectId];
        const subGrid = object.subGrid;
        let isDraftDimension = false;

        let dimensionKeyValues: KeyValueObject = {};

        let relationKey = payload.relationKey || "hdr";

        //#region Creating Relation Key Based on Sub grid or normal grid

        if (dimensionConfig && dimensionConfig.gridno && dimensionConfig.rowIndex != undefined && dimensionConfig.rowIndex != -1) {

          if (subGrid?.length)
            isDraftDimension = true;

          if (!payload.relationKey) {
            relationKey = "";
            if (subGrid?.length) {
              subGrid.map(x => {
                relationKey += x.parentGridno + "-" + x.parentRowIndex + "-";
              });
            }

            relationKey += dimensionConfig.gridno + "-" + dimensionConfig.rowIndex;
          }
        }
        relationKey += "-" + dimensionSetFieldid;
        //#endregion

        //#region remove dimension set where dimension relation is empty

        const dimension = object.dimension;

        let dimensionEmptyRelationIndex = -1;
        dimension.map((x, i) => {
          if (x.isDraftDimension == isDraftDimension) {
            const findIndex = x.dimensionRelations.indexOf(relationKey);
            if (findIndex != -1) {
              x.dimensionRelations.splice(findIndex, 1);
              dimensionKeyValues = x.dimensionValues;
              if (!x.dimensionRelations.length)
                dimensionEmptyRelationIndex = i;
            }
          }
        });

        if (dimensionEmptyRelationIndex != -1)
          dimension.splice(dimensionEmptyRelationIndex, 1);

        //#endregion

        if (payload.replace) {
          dimensionKeyValues = {
            ...payload.dimensionValues
          };
        }
        else {
          dimensionKeyValues = {
            ...dimensionKeyValues,
            ...payload.dimensionValues
          };
        }

        const dimensionValuesObject: KeyValueObject<string> = {};

        Object.keys(dimensionKeyValues).sort().map(key => {
          const value = dimensionKeyValues[key];
          if (value && value.toString().trim())
            dimensionValuesObject[key] = value.trim();
        });

        let dimensionSet = "";
        if (Object.keys(dimensionValuesObject).length)
          dimensionSet = md5(JSON.stringify(dimensionValuesObject).toLowerCase()).toLowerCase();

        dimensionSetFieldValues = Object.keys(dimensionValuesObject).map(x => dimensionValuesObject[x]).join("-");

        // dimensionSet = BaseMethods.uniqueIdGenerator(JSON.stringify(dimensionValuesObject));

        if (Object.keys(dimensionValuesObject).length || isDraftDimension) {

          let dimensionSetFound = false;
          dimension.filter(x => x.isDraftDimension == isDraftDimension).map((y, i) => {
            if (y.dimensionSet == dimensionSet) {
              dimensionSetFound = true;
              y.dimensionRelations.push(relationKey);
            }
          });

          if (!dimensionSetFound)
            dimension.push({
              dimensionSet,
              dimensionRelations: [relationKey],
              dimensionValues: dimensionValuesObject,
              isDraftDimension: isDraftDimension
            });
        }

        //#region set data to model
        if (!payload.relationKey) {
          if (dimensionConfig && dimensionConfig.gridno && dimensionConfig.rowIndex != undefined && dimensionConfig.rowIndex != -1) {

            let gridModel: any[] = [];

            if (subGrid?.length) {
              const lastSubGrid = subGrid[subGrid.length - 1];
              if (lastSubGrid.gridno == dimensionConfig.gridno) {
                gridModel = lastSubGrid.modelData || [];
              }
            }
            else {
              const collectionName = dimensionConfig.gridno.getCollectionNameFromGridno();
              gridModel = object.model[collectionName] || [];
            }

            if (gridModel[dimensionConfig.rowIndex]) {
              gridModel[dimensionConfig.rowIndex] = {
                ...gridModel[dimensionConfig.rowIndex],
                [dimensionSetFieldid]: dimensionSet,
                [dimensionSetFieldValuesKey]: dimensionSetFieldValues
              }
            }

          }
          else {
            object.model = {
              ...object.model,
              [dimensionSetFieldid]: dimensionSet,
              [dimensionSetFieldValuesKey]: dimensionSetFieldValues
            }
          }
        }

        //#endregion

      });
    },

    setDimensionSetModel(state, action: PayloadAction<IDimension[]>) {

      const payload = action.payload;
      return produce(state, ({ objects }) => {
        const object = objects[state.activeObjectId];
        object.dimension = payload;
      });

    },

    addGridRowDimensionSet(state, action: PayloadAction<{ gridno: string, rowIndex: number, clone: boolean }>) {

      const payload = action.payload;
      return produce(state, ({ objects }) => {

        const object = objects[state.activeObjectId];
        let isDraftDimension = false;

        let relationKey = "";

        if (object.subGrid?.length) {
          isDraftDimension = true;
          object.subGrid.map(x => {
            relationKey += x.parentGridno + "-" + x.parentRowIndex + "-";
          });
        }

        relationKey += payload.gridno;

        const rowIndex = payload.rowIndex;

        object.dimension.map(x => {
          if (x.isDraftDimension == isDraftDimension) {
            const findRelationToPush: string[] = [];

            x.dimensionRelations = x.dimensionRelations.map(y => {
              if (y.startsWith(relationKey)) {
                const splitRemain = y.substring(relationKey.length, y.length).split("-").filter(x => x);
                var similarKeyRowIndex = Number(splitRemain.shift());

                if (similarKeyRowIndex == rowIndex) {
                  findRelationToPush.push(y);
                }
                if (similarKeyRowIndex >= rowIndex) {
                  y = relationKey + "-" + (similarKeyRowIndex + 1).toString() + "-" + splitRemain.join("-");
                }
              }

              return y;
            });
            if (payload.clone)
              x.dimensionRelations.push(...findRelationToPush);
          }

        });

      });

    },

    deleteGridRowDimensionSet(state, action: PayloadAction<{ gridno: string, rowIndex: number, relationKey?: string }>) {

      const payload = action.payload;
      return produce(state, ({ objects }) => {

        const object = objects[state.activeObjectId];
        let isDraftDimension = false;

        let relationKey = payload.relationKey || "";

        if (object.subGrid?.length) {
          isDraftDimension = true;
          if (!payload.relationKey) {
            object.subGrid.map(x => {
              relationKey += x.parentGridno + "-" + x.parentRowIndex + "-";
            });
          }
        }

        relationKey += payload.gridno;

        const rowIndex = payload.rowIndex;
        const findRelationToRemove: string[] = [];

        object.dimension.map(x => {
          if (x.isDraftDimension == isDraftDimension) {

            x.dimensionRelations = x.dimensionRelations.map(y => {
              if (y.startsWith(relationKey)) {
                const splitRemain = y.substring(relationKey.length, y.length).split("-").filter(x => x);
                var similarKeyRowIndex = Number(splitRemain.shift());

                if (similarKeyRowIndex == rowIndex) {
                  findRelationToRemove.push(y);
                  y = "";
                }
                if (similarKeyRowIndex > rowIndex) {
                  y = relationKey + "-" + (similarKeyRowIndex - 1).toString() + "-" + splitRemain.join("-");
                }
              }

              return y;
            });
            x.dimensionRelations = x.dimensionRelations.filter(x => x);
          }
        });

        object.dimension = object.dimension.filter(x => x.dimensionRelations.length);

        if (isDraftDimension && findRelationToRemove.length) {
          const findEmptyDimensionSetForSubGrid = object.dimension.findIndex(x => x.isDraftDimension == true && x.dimensionSet == "");

          if (findEmptyDimensionSetForSubGrid != -1) {
            object.dimension[findEmptyDimensionSetForSubGrid].dimensionRelations.push(...findRelationToRemove);
          }
          else {
            object.dimension.push({
              dimensionSet: "",
              dimensionValues: {},
              isDraftDimension: true,
              dimensionRelations: findRelationToRemove
            });
          }
        }

        // const object = objects[state.activeObjectId];
        // const removeIndex = object.dimension.findIndex(x => x.dimensionRelations.indexOf(payload.key) != -1);

        // //flexvendorinvoicedtls-0-flexvendorinvoicecostelementlines-0-dimensionset

        // if (removeIndex != -1) {
        //   const dimension = object.dimension[removeIndex];
        //   dimension.dimensionRelations.splice(dimension.dimensionRelations.indexOf(payload.key), 1);
        //   if (!dimension.dimensionRelations.length)
        //     object.dimension.splice(removeIndex, 1);
        // }

        // const splitKey = payload.key.split("-");
        // const splitRowIndex = Number(splitKey.pop());

        // object.dimension.map(x => {

        //   x.dimensionRelations = x.dimensionRelations.map(y => {

        //     if (y.startsWith(splitKey.join("-"))) {

        //       const similarKeySplit = y.split("-");
        //       const similarKeyRowIndex = Number(similarKeySplit.pop());

        //       if (similarKeyRowIndex > splitRowIndex) {
        //         y = similarKeySplit.join("-") + "-" + (similarKeyRowIndex - 1).toString();
        //       }

        //     }

        //     return y;
        //   });

        // });

      });

    },



    //#endregion

    //#region Attachment

    uploadAttachment(state) { },

    toggleAttachment(state, action: PayloadAction<{ open: boolean, openModalGridno?: string, openModalGridLineId?: number }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        const object = objects[state.activeObjectId];
        const attachment = object.Attachment;

        attachment.isModalOpen = payload.open;
        attachment.openModalGridno = payload.openModalGridno || "";
        attachment.openModalGridLineId = payload.openModalGridLineId || 0;

      });
    },

    setAttachmentObject(state, action: PayloadAction<{ newAttachments: IAttachment[], removeAttachmentIds?: string[] }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        const object = objects[state.activeObjectId];
        const attachment = object.Attachment;
        const removeAttachmentIds = payload.removeAttachmentIds;

        if (removeAttachmentIds && removeAttachmentIds.length) {

          attachment.removeAttachmentIds = removeAttachmentIds.filter(x => attachment.attachments.find(y => !y.isNew && y.prid == x));

          attachment.attachments = attachment.attachments.filter(x => !(removeAttachmentIds.includes(x.prid)));

        }

        if (payload.newAttachments.length) {
          attachment.attachments.push(...payload.newAttachments);
        }

      });
    },

    updateAttachmentObject(state, action: PayloadAction<{ attachmentId: string, updatedObject: IAttachment }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        const object = objects[state.activeObjectId];
        const attachment = object.Attachment;

        const attachmentFindIndex = attachment.attachments.findIndex(x => x.prid == payload.attachmentId);
        if (attachmentFindIndex != -1) {
          attachment.attachments.splice(attachmentFindIndex, 1);
          attachment.attachments.push(payload.updatedObject);
        }

      });
    },

    clearAttachmentRemoveIds(state) {
      return produce(state, ({ objects }) => {
        const object = objects[state.activeObjectId];
        const attachment = object.Attachment;
        attachment.removeAttachmentIds = [];
      });
    },

    deleteGridLineAttachment(state, action: PayloadAction<{ gridno: string, lineId: number }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        const object = objects[state.activeObjectId];
        const attachment = object.Attachment;

        const findLineAttachments = [...attachment.attachments.filter(x => x.gridno == payload.gridno && x.lineid == payload.lineId)];
        if (findLineAttachments.length) {
          findLineAttachments.map(x => {
            if (!x.isNew)
              attachment.removeAttachmentIds.push(x.prid);
          });
          attachment.attachments = attachment.attachments.filter(x => !(x.gridno == payload.gridno && x.lineid == payload.lineId));
        }

        attachment.attachments.map(x => {
          if (x.gridno == payload.gridno && x.lineid > payload.lineId)
            x.lineid--;
        });

      });
    },

    moveAfterGridLineAttachment(state, action: PayloadAction<{ gridno: string, lineId: number }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        const object = objects[state.activeObjectId];
        const attachment = object.Attachment;

        attachment.attachments.map(x => {
          if (x.gridno == payload.gridno && x.lineid > payload.lineId)
            x.lineid++;
        });

      });
    },

    clearAllGridLineAttachment(state, action: PayloadAction<{ gridno: string }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        const object = objects[state.activeObjectId];
        const attachment = object.Attachment;

        attachment.attachments = attachment.attachments.filter(x => x.gridno != payload.gridno);

      });
    },

    setAttachmentProgress(state, action: PayloadAction<{ attachmentId: string, progress: number }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        const object = objects[state.activeObjectId];
        const attachment = object.Attachment;

        attachment.uploadingAttachments.map(x => {
          if (x.prid == payload.attachmentId)
            x.uploadingProgress = payload.progress;
        });

      });
    },

    setAttachmentError(state, action: PayloadAction<{ attachmentId: string, errorMessage: string }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        const object = objects[state.activeObjectId];
        const attachment = object.Attachment;

        attachment.uploadingAttachments.map(x => {
          if (x.prid == payload.attachmentId) {
            x.isUploadingError = true;
            x.uploadingErrorMessage = payload.errorMessage;
          }
        });

      });
    },

    toggleAttachmentUploadingModal(state, action: PayloadAction<{ open: boolean }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        const object = objects[state.activeObjectId];
        const attachment = object.Attachment;

        attachment.isSavingModalOpen = payload.open;

      });
    },

    setNewUploadingAttachments(state) {
      return produce(state, ({ objects }) => {
        const object = objects[state.activeObjectId];
        const attachment = object.Attachment;

        attachment.uploadingAttachments = attachment.attachments.filter(x => x.isNew).map(x => ({
          ...x,
          uploadingProgress: 0,
          isUploadingError: false,
          uploadingErrorMessage: ""
        }));

      });
    },

    //#endregion

    //#region Queue

    queueStart(state, action: PayloadAction<{ queueName: string; }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        objects[state.activeObjectId].otherState.requestInQueue[payload.queueName] = {
          loadingData: true
        };
      });
    },

    queueEnd(state, action: PayloadAction<{ queueName: string; }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        delete objects[state.activeObjectId].otherState.requestInQueue[payload.queueName];
      });
    },
    //#endregion

    //#region Modal 
    toggleSummary(state, action: PayloadAction<boolean>) {
      return produce(state, ({ objects }) => {
        objects[state.activeObjectId].otherState.isSummaryPopupOpen = action.payload;
      });
    },

    toggleReverseModal(state, action: PayloadAction<boolean>) {
      return produce(state, ({ objects }) => {
        objects[state.activeObjectId].otherState.isReversePopupOpen = action.payload;
      });
    },

    toggleActivityModal(state, action: PayloadAction<boolean>) {
      return produce(state, (draftState) => {
        draftState.objects[draftState.activeObjectId].otherState.isActivityModalOpen = action.payload;
      })
    },
    //#endregion

    //#region Other State
    saveUserDocumentHelpFilter(state, action: PayloadAction<KeyValueObject>) { },
    removeUserDocumentHelp(state, action: PayloadAction<{ prid: number }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        const viewIndex = objects[state.activeObjectId].otherState.userDocumentHelpFilter.findIndex(x => x.prid = payload.prid);
        if (viewIndex != -1) {
          objects[state.activeObjectId].otherState.userDocumentHelpFilter.splice(viewIndex, 1);
        }
      });
    },
    setViewDefaultUserDocumentHelp(state, action: PayloadAction<{ prid: number, processcode: string, isDefault: boolean }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        const view = objects[state.activeObjectId].otherState.userDocumentHelpFilter.find(x => x.prid == payload.prid);
        if (view) {
          view.isdefault = payload.isDefault;
        }
        if (payload.isDefault) {
          objects[state.activeObjectId].otherState.userDocumentHelpFilter.map(x => {
            if (x.isdefault && x.prid != payload.prid)
              x.isdefault = false;
          })
        }
      });
    },
    pushUserDocumentHelpFilter(state, action: PayloadAction<IOtherState["userDocumentHelpFilter"]>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        objects[state.activeObjectId].otherState.userDocumentHelpFilter.push(...payload);
      });
    },
    setCustomState(state, action: PayloadAction<{ key: string, value: any }>) {
      const payload = action.payload;
      return produce(state, ({ objects }) => {
        objects[state.activeObjectId].otherState.customState[payload.key] = payload.value;
      });
    },
    setUserHaveApprovalOption(state, action: PayloadAction<{ approverPRID: number, status: string }>) {
      return produce(state, ({ objects }) => {
        objects[state.activeObjectId].otherState.userApprovalOption = {
          isApprover: action.payload.approverPRID ? true : false,
          approverPRID: action.payload.approverPRID,
          status: action.payload.status
        };
      });
    },
    setGroupHeadingLabelVisibility(state, action: PayloadAction<{ controlId: string, visible: boolean }>) {
      const payload = action.payload;
      return produce(state, (draftState) => {
        const otherState = draftState.objects[draftState.activeObjectId].otherState;
        const findIndex = otherState.groupLabelHide.indexOf(payload.controlId);

        if (payload.visible) {
          if (findIndex != -1)
            otherState.groupLabelHide.splice(findIndex, 1);
        }
        else {
          if (findIndex == -1)
            otherState.groupLabelHide.push(payload.controlId);
        }

      })
    },
    setToolbarSetting(state, action: PayloadAction<{ toolbarKey: string } & Partial<{ gridno: string, visible: boolean, enable: boolean }>>) {
      const payload = action.payload;
      return produce(state, (draftState) => {
        const otherState = draftState.objects[draftState.activeObjectId].otherState;
        const gridnoFilter = payload.gridno ? payload.gridno : "";
        const findtoolbarSetting = otherState.toolbarSetting.find(x => x.toolbarKey == payload.toolbarKey && x.gridno == gridnoFilter)

        if (findtoolbarSetting) {
          if (payload.enable != undefined)
            findtoolbarSetting.enable = payload.enable;
          if (payload.visible != undefined)
            findtoolbarSetting.visible = payload.visible;
        }

      })
    },
    //#endregion

    //#region Comments
    addComment(state, action: PayloadAction<{ originprid: string, originprocesscode: string, commentstxt: string, username: any }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        draftState.objects[draftState.activeObjectId].otherState.comments.push({ commentstxt: payload.commentstxt, createdatetime: new Date().toJSON(), isread: true, createuser: payload.username, userphoto: '' })
      })
    },
    setComments(state, action: PayloadAction<Array<IComment>>) {
      return produce(state, (drafttState) => {
        drafttState.objects[drafttState.activeObjectId].otherState.comments = action.payload;
      })
    },
    toggleCommentsModal(state, action: PayloadAction<boolean>) {
      return produce(state, (draftState) => {
        draftState.objects[draftState.activeObjectId].otherState.isCommentsModalOpen = action.payload;
      })
    },
    readComment(state, action: PayloadAction<{ originprocesscode: string, originprid: number }>) {
      return produce(state, (draftState) => {
        draftState.objects[draftState.activeObjectId].otherState.comments.forEach(x => {
          x.isread = true
        })
      })
    },

    //#endregion

    setScreenFavorite(state, action: PayloadAction<{ objectcontrollerno: string }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        // reflect favorite in open screens
        const openScreens = Object.entries(draftState.objects).filter(([tabKey, object]) => object.ScreenConfig.TcodeConfiguration.processcode == payload.objectcontrollerno)
          .map(([tabKey, object]) => object.ScreenConfig.TcodeConfiguration.processcode);

        if (openScreens.length >= 1) {

          draftState.objects = Object.entries(draftState.objects).map(([tabKey, object]) => {
            if (openScreens.includes(object.ScreenConfig.TcodeConfiguration.processcode)) {
              object.ScreenConfig.TcodeConfiguration.isfavorite = !object.ScreenConfig.TcodeConfiguration.isfavorite;
            }

            return [tabKey, object];
          }).reduce((accum: any, [k, v]: any) => {
            accum[k] = v;
            return accum;
          }, {});

        }


        // if (drafttState.objects[payload.objectcontrollerno]) {
        //   drafttState.objects[payload.objectcontrollerno].ScreenConfig.TcodeConfiguration.isFavorite = !drafttState.objects[payload.objectcontrollerno].ScreenConfig.TcodeConfiguration.isFavorite;
        // }
      })
    },

    shareDocument(state, action: PayloadAction<IShareDocument>) { },
  },
});

export const {
  getDocumentByPrid, copyDocumentByPrid, saveDocument, reverseDocument, deleteDocument,
  forceDisableActionButton, resetScreenOptions, loadDocumentHelp, changeActivityToCreate, getScreenConfig, setScreenObject, setActiveObjectId, setObjectLoaded, setDocumentModelValue, changeObjectActivity, closeEntryFormObject, changeScreenOptions,
  fieldHelpValidate, fieldHelpValidateEnd, setFieldVisibility, setFieldEditable, setFieldModelValue, setFieldHelpObject, setFieldMandatory, setFieldError,
  gridHelpValidate, gridHelpValidateEnd, setGridInitialModel, setGridColumnMandatory, setGridColumnVisibility, setGridColumnEditable, setGridColumnHelpObject, setGridColumnHyperLinkJump, addGridNewRow, changeGridRow, deleteGridRow, setGridCellData, setGridRowsState, setGridRowStateByStartingRow, setUserGridColumnSetting, setGridRowError, removeGridRowError,
  openSubGrid, addSubGridNewRow, changeSubGridRow, deleteSubGridRow, closeSubGrid, saveSubGrid, setSubGridCellData, setGridColumnLabel,
  pushScreenTabs, changeScreenTabVisibility, toggleActiveScreenTab, toggleActiveScreenAccordion, toggleScreenAccordionByControlIds,
  fillDimensionFromDimensionSet, getDimensionValuesFromDimensionSetPromiseResolver, getAccountDimensions, setAccountDimensions, setAccountDimensionValue, closeDimension, upsertDimension, setDimensionSetModel, addGridRowDimensionSet, deleteGridRowDimensionSet,
  uploadAttachment, toggleAttachment, setAttachmentObject, updateAttachmentObject, clearAttachmentRemoveIds, deleteGridLineAttachment, moveAfterGridLineAttachment, clearAllGridLineAttachment, setAttachmentProgress, setAttachmentError, toggleAttachmentUploadingModal, setNewUploadingAttachments,
  queueStart, queueEnd, shareDocument,
  saveUserDocumentHelpFilter, setViewDefaultUserDocumentHelp, removeUserDocumentHelp, pushUserDocumentHelpFilter, setCustomState, setUserHaveApprovalOption, setGroupHeadingLabelVisibility, setToolbarSetting, addComment, setComments, readComment, toggleActivityModal,
  toggleSummary, toggleCommentsModal, toggleReverseModal,
  setScreenFavorite
} = EntryFormSlice.actions;


export default EntryFormSlice.reducer;
