import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import produce from "immer";

export interface IAddToWorkspace {
  workspaceNo: number,
  widgetName: string,
  showCount: boolean,
  gridView: string,
  processCode: string,
  widgetType: string,
  helpObject: string,
  widgetGroup: string,
  columns: string,
  icon: string,
  objectType: string
}

export interface IUserWorkspaceObject {
  workspaceNo: number,
  widgetNo: string,
  widgetName: string,
  index: number,
  widgetType: string,
  objectType: string,
  showCount: boolean,
  icon?: string,
  count?: number,
  header: Array<{ ColumnID: string, ColumnText: string, ColumnWidth: number, controltype: string, fielddataformatno: string, hyperlinkjump: string }>,
  detail: Array<{ [key: string]: any }>,
  widgetGroup: string,
  gridView: string,
  setting: string,
  processCode: string,
}
interface IUserWorkspace {
  workspaceno: number;
  workspacestxt: string;
  username: string;
  legalentityno: string;
  columnlayout: boolean;
}
interface IWorkspace {
  userWorkspace: Array<IUserWorkspace>,
  userWorkspaceObject: Array<IUserWorkspaceObject>,
}

const initialState: IWorkspace = {
  userWorkspace: [],
  userWorkspaceObject: []
}

const workspaceSlice = createSlice({
  name: "workspace",
  initialState,
  reducers: {
    getUserWorkspace() { },
    setUserWorkspace(state, action: PayloadAction<Array<IUserWorkspace>>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        draftState.userWorkspace = payload
      });
    },
    addWorkspace(state, action: PayloadAction<string>) { },
    updateWorkspace(state, action: PayloadAction<{ workspaceNo: number, workspaceName: string }>) { },
    setUpdatedWorkspace(state, action: PayloadAction<{ workspaceNo: number, workspaceName: string }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        draftState.userWorkspace = draftState.userWorkspace.map(x => {
          if (x.workspaceno == payload.workspaceNo) {
            x.workspacestxt = payload.workspaceName;
          }

          return x;
        })
      })
    },
    deleteWorkspace(state, action: PayloadAction<number>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        var findIndex = draftState.userWorkspace.findIndex(x => x.workspaceno == payload);
        if (findIndex != -1) {
          draftState.userWorkspace.splice(findIndex, 1);
        }
      })
    },

    getUserWorkspaceObject(state, action: PayloadAction<number>) { },
    setUserWorkspaceObject(state, action: PayloadAction<Array<IUserWorkspaceObject>>) {
      const payload = action.payload;
      return produce(state, (draftState) => {
        draftState.userWorkspaceObject = payload;
      });
    },
    addToWorkspace(state, action: PayloadAction<IAddToWorkspace>) { },
    updateUserWorkspaceObject(state, action: PayloadAction<{ widgetNo: string, widgetName: string, icon?: string, widgetGroup?: string, widgetType: string }>) { },
    setUpdatedUserWorkspaceObject(state, action: PayloadAction<{ widgetNo: string, widgetName: string, icon?: string, widgetGroup?: string, widgetType: string }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        draftState.userWorkspaceObject = draftState.userWorkspaceObject.map(x => {
          if (x.widgetNo == payload.widgetNo) {
            x.widgetName = payload.widgetName;
            let widgetType = payload.widgetType;

            if (widgetType == "Menu" && payload.widgetGroup) {
              x.widgetGroup = payload.widgetGroup;
            }

            if (widgetType == "Tile" && payload.icon) {
              x.icon = payload.icon;
            }
          }

          return x;
        })
      })
    },
    deleteWorkspaceObject(state, action: PayloadAction<string>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        var findIndex = draftState.userWorkspaceObject.findIndex(x => x.widgetNo == payload);
        if (findIndex != -1) {
          draftState.userWorkspaceObject.splice(findIndex, 1);
        }
      })
    },

    toggleColumnLayout(state, action: PayloadAction<number>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        const findWorkspace = draftState.userWorkspace.find(x => x.workspaceno == payload);

        if (findWorkspace) {
          findWorkspace.columnlayout = !draftState.userWorkspace.find(x => x.workspaceno == payload)?.columnlayout;
        }
      })
    },
    reorderTiles(state, action: PayloadAction<Array<IUserWorkspaceObject>>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        const updatedIdx = payload.map((x, idx) => {
          return { ...x, index: idx }
        })
        draftState.userWorkspaceObject = updatedIdx.concat(draftState.userWorkspaceObject.filter(x => x.widgetType != 'Tile'));
      })
    },
    reorderLists(state, action: PayloadAction<Array<IUserWorkspaceObject>>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        const updatedIdx = payload.map((x, idx) => {
          return { ...x, index: idx }
        })
        draftState.userWorkspaceObject = updatedIdx.concat(draftState.userWorkspaceObject.filter(x => x.widgetType != 'List'));
      })
    },
    reorderMenus(state, action: PayloadAction<Array<IUserWorkspaceObject>>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        const updatedIdx = payload.map((x, idx) => {
          return { ...x, index: idx }
        })
        draftState.userWorkspaceObject = updatedIdx.concat(draftState.userWorkspaceObject.filter(x => x.widgetType != 'Menu'));
      })
    },
    updateMenuGroup(state, action: PayloadAction<{ widgetNo: string, widgetGroup: string }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        var menu = draftState.userWorkspaceObject.find(x => x.widgetNo == payload.widgetNo);
        const updatedMenu: any = [{ ...menu, widgetGroup: payload.widgetGroup }];
        draftState.userWorkspaceObject = draftState.userWorkspaceObject.map(x => {
          if (x.widgetNo == payload.widgetNo) {
            return { ...x, widgetGroup: payload.widgetGroup }
          }

          return x;
        });
      })
    }
  }
});

export const { getUserWorkspace, setUserWorkspace, addWorkspace, updateWorkspace, setUpdatedWorkspace, deleteWorkspace, getUserWorkspaceObject, setUserWorkspaceObject, addToWorkspace, updateUserWorkspaceObject, setUpdatedUserWorkspaceObject, deleteWorkspaceObject, toggleColumnLayout, reorderMenus, reorderLists, reorderTiles, updateMenuGroup } = workspaceSlice.actions;

export default workspaceSlice.reducer;
