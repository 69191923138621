import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Modal from '../Modal';
import { MdAdd, MdDashboardCustomize, MdSave } from "react-icons/md";
import { addToWorkspace, addWorkspace } from "@/Redux/Ducks/workspaceSlice";
import Select from 'react-select';
import ReactSelect from "react-select";
import { ControlTranslation, IconPicker } from "AlignComponents";
import classNames from "classnames";

interface IProps {
  objectType: string
}

const Workspace = (props: IProps) => {
  const dispatch = useAppDispatch();
  const [addWorkspaceModalState, toggleAddWorkspaceModal] = useState<boolean>(false);
  const [addToWorkspaceModalState, toggleAddToWorkspaceModal] = useState<boolean>(false);
  const [workspaceName, setWorkspaceName] = useState<string>('');
  const [selectedWorkspace, selectWorkspace] = useState<number>(0);
  const [widgetName, setWidgetName] = useState<string>('');
  const [widgetGroup, setWidgetGroup] = useState<string>('');
  const [widgetType, setWidgetType] = useState<string>('Tile');
  const [showCount, toggleShowCount] = useState<boolean>(false);
  const [columns, setColumns] = useState<Array<string>>([]);
  const [icon, setIcon] = useState<string>("BarChart");
  const [addWorkspaceValidated, setAddWorkspaceValidated] = useState(false);
  const [addToWorkspaceValidated, setAddToWorkspaceValidated] = useState(false);
  const userWorkspace = useAppSelector(x => x.Workspace.userWorkspace);
  const { processCode, gridView, helpObject, documentHelpColumns } = useAppSelector(x => {
    let activeObjectId = "";
    let processCode = "";
    let gridView = "";
    let helpObject = "";
    let documentHelpColumns: any = [];
    let object: any = {};

    if (props.objectType == "E") {
      activeObjectId = x.EntryForm.activeObjectId;
      object = x.EntryForm.objects[activeObjectId];
      processCode = object.ScreenConfig.TcodeConfiguration.processcode;
      gridView = JSON.stringify(x.Help[activeObjectId].loadedHelp?.gridView);
      helpObject = x.Help[activeObjectId].loadedHelp?.helpConfig.Config.helpobjectno || "";
      documentHelpColumns = x.Help[activeObjectId].loadedHelp?.helpConfig.Header.map(x => ({ value: x.ColumnID, label: x.ColumnText }));
    }
    else if (props.objectType == "I") {
      activeObjectId = x.DataAnalytics.activeDataAnalyticsObjectno;
      object = x.DataAnalytics.dataAnalyticsDetailObject[activeObjectId];
      processCode = object.objectConfiguration.processcode;
      gridView = JSON.stringify({ modelValue: object.modelValue, gridView: object.gridView });
      helpObject = object.objectConfiguration.dataanalyticsno;
      documentHelpColumns = object.grid?.Header.map((x: any) => ({ value: x.ColumnID, label: x.ColumnText }));
    }
    else {
      activeObjectId = x.ReportExplorer.activeReportObjectno;
      object = x.ReportExplorer.reportDetailObject[activeObjectId];
      processCode = object.objectConfiguration.processcode;
      gridView = JSON.stringify(object.modelValue);
      helpObject = object.objectConfiguration.reportobjectno;
    }

    return { processCode, gridView, helpObject, documentHelpColumns }
  });
  // const processCode = useAppSelector(x => x.EntryForm.objects[activeObjectId].ScreenConfig.TcodeConfiguration.processcode);
  // const gridView = useAppSelector(x => JSON.stringify(x.Help[activeObjectId].loadedHelp?.gridView)) || "";
  // const helpObject = useAppSelector(x => x.Help[activeObjectId].loadedHelp?.helpConfig.Config.helpobjectno) || "";
  // const documentHelpColumns: any = useAppSelector(x => x.Help[activeObjectId].loadedHelp?.helpConfig.Header.map(x => ({ value: x.ColumnID, label: x.ColumnText })));

  useEffect(() => {
    if (props.objectType == "E" || props.objectType == "I") {
      setWidgetType("Tile")
    }
    else if (props.objectType == "R") {
      setWidgetType("Menu");
    }
  }, [props.objectType])

  useEffect(() => {
    if (widgetType == "List") {
      setColumns(documentHelpColumns.filter((x: any, i: any) => i <= 5));
    }
  }, [widgetType])

  const resetState = () => {
    setWorkspaceName('');
    setWidgetName('');
    setWidgetGroup('');
    setWidgetType('Tile');
    toggleShowCount(false);
    setColumns([]);
    setAddToWorkspaceValidated(false);
    setAddWorkspaceValidated(false);
  }

  // handler
  const handleWorkspaceNameChange = (e: any) => {
    setWorkspaceName(e.target.value)
  }

  const handleWorkspaceSelectedChange = (e: any) => {
    selectWorkspace(e.target.value)
  }

  const handleWorkspaceTypeChange = (e: any) => {
    setWidgetType(e.target.value)
  }

  const handleShowCountChange = (e: any) => {
    toggleShowCount(e.target.checked)
  }


  const handleWidgetNameChange = (e: any) => {
    setWidgetName(e.target.value)
  }

  const handleWidgetGroupChange = (e: any) => {
    setWidgetGroup(e.target.value)
  }

  const handleColumnsChange = (e: any) => {
    setColumns(e);
  }
  const handleIconChange = (icon: string) => {
    setIcon(icon);
  }

  // methods
  const addWorkspaceFunc = () => {
    dispatch(addWorkspace(workspaceName));
    toggleAddWorkspaceModal(false);
  }

  const addToWorkspaceFunc = () => {
    dispatch(addToWorkspace({ showCount, widgetName, workspaceNo: selectedWorkspace, widgetType, processCode, gridView, helpObject, widgetGroup, columns: columns.map((x: any) => x.value).join(','), icon, objectType: props.objectType }));
    toggleAddToWorkspaceModal(false);
    resetState();
  }

  const handleClick = (e: any) => {
    selectWorkspace(Number(e.target.id));
    toggleAddToWorkspaceModal(true);
  }

  const handleAddWorkspaceSubmit = (e: any) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setAddWorkspaceValidated(true);
    }
    else {
      addWorkspaceFunc();
    }
  };

  const handleAddToWorkspaceSubmit = (e: any) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setAddToWorkspaceValidated(true);
    }
    else {
      addToWorkspaceFunc();
    }
  };

  return (
    <>
      <Modal
        modalSize='sm'
        heading="Add Workspace"
        show={addWorkspaceModalState}
        close={() => toggleAddWorkspaceModal(false)}
        footer={
          <Button form="addWorkspace" variant="primary" type="submit">
            <MdSave className="me-2" size="16" />
            Save
          </Button>
        }
      >
        <Form id="addWorkspace" noValidate validated={addWorkspaceValidated} onSubmit={handleAddWorkspaceSubmit}>
          <Form.Group className="my-1" controlId="workspaceName">
            <Form.Label>Workspace Name</Form.Label>
            <Form.Control type="text" value={workspaceName} onChange={handleWorkspaceNameChange} required />
          </Form.Group>
        </Form>
      </Modal>

      <Modal
        modalSize='md'
        heading="Add To Workspace"
        show={addToWorkspaceModalState}
        close={() => toggleAddToWorkspaceModal(false)}
        footer={
          <Button form="addToWorkspace" variant="success" type="submit">
            <MdAdd className="me-2" size="16" />
            Add
          </Button>
        }
      >
        <Form id="addToWorkspace" noValidate validated={addToWorkspaceValidated} onSubmit={handleAddToWorkspaceSubmit}>
          <Form.Group className="mb-2" controlId="workspaceName" as={Row}>
            <Form.Label column sm="3">Workspace</Form.Label>
            <Col sm="9" className="d-flex align-items-center">
              <Form.Select value={selectedWorkspace} onChange={handleWorkspaceSelectedChange}>
                {
                  userWorkspace.map(x => (
                    <option key={x.workspaceno} value={x.workspaceno}>{x.workspacestxt}</option>
                  ))
                }
              </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group className="mb-2" controlId="workspaceType" as={Row}>
            <Form.Label column sm="3">Type</Form.Label>
            <Col sm="9" className="d-flex align-items-center">
              <Form.Select value={widgetType} onChange={handleWorkspaceTypeChange}>
                {
                  props.objectType != "R" ? (
                    <>
                      <option value="Tile">Tile</option>
                      <option value="List">List</option>
                    </>
                  ) : null
                }

                <option value="Menu">Menu</option>

              </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group className="mb-2" controlId="workspaceType" as={Row}>
            <Form.Label column sm="3">Name</Form.Label>
            <Col sm="9" className="d-flex align-items-center">
              <Form.Control required value={widgetName} type="text" onChange={handleWidgetNameChange} />
            </Col>
          </Form.Group>
          {
            widgetType == "Tile" ?
              (
                <>
                  <Form.Group className="mb-2" controlId="workspaceName" as={Row}>
                    <Form.Label column sm="3">Show Count</Form.Label>
                    <Col sm="9" className="d-flex align-items-center">
                      <Form.Check checked={showCount} type="checkbox" onChange={handleShowCountChange} />
                    </Col>
                  </Form.Group>
                  <Form.Group className="mb-2" controlId="workspaceIcon" as={Row}>
                    <Form.Label column sm="3">Icon</Form.Label>
                    <Col sm="9" className="d-flex align-items-center">
                      <IconPicker defaultIcon={icon} onChange={handleIconChange} />
                    </Col>
                  </Form.Group>
                </>
              ) : widgetType == "List" ? (
                <Form.Group className="mb-2" controlId="workspaceType" as={Row}>
                  <Form.Label column sm="3">Columns</Form.Label>
                  <Col sm="9" className="d-flex align-items-center">
                    <ReactSelect required className="w-100" options={documentHelpColumns} isMulti value={columns} onChange={handleColumnsChange} />
                  </Col>
                </Form.Group>
              ) : (
                <Form.Group className="mb-2" controlId="workspaceType" as={Row}>
                  <Form.Label column sm="3">Group</Form.Label>
                  <Col sm="9" className="d-flex align-items-center">
                    <Form.Control required={widgetType == "Menu" ? true : false} value={widgetGroup} type="text" onChange={handleWidgetGroupChange} />
                  </Col>
                </Form.Group>
              )
          }
        </Form>
      </Modal>

      <OverlayTrigger placement="top" overlay={<Tooltip><ControlTranslation system={true} controlId="btnaddtoworkspace" /></Tooltip>}>
        <DropdownButton variant="secondary" className={classNames(["lg-menu", { "ms-2": props.objectType == "E" }])} title={<MdDashboardCustomize size="16" className="mx-1" />}>
          {
            userWorkspace.map((x, i) => (
              <Dropdown.Item onClick={handleClick} key={x.workspaceno} id={x.workspaceno.toString()} role="button">
                {x.workspacestxt}
              </Dropdown.Item>
            ))
          }
          {
            userWorkspace.length >= 1 ?
              <Dropdown.Divider />
              : null
          }
          <Dropdown.Item role="button" className="text-center" onClick={() => { toggleAddWorkspaceModal(true) }}><ControlTranslation system={true} controlId="btnaddtoworkspace" /></Dropdown.Item>
        </DropdownButton>
      </OverlayTrigger>
    </>
  )

};

export default Workspace;
