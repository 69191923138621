import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import produce from "immer";

export enum ENotificationType {
  Approval = "APP",
  Notification = "NOT",
  Information = "INF"
}

export enum EApprovalStatusType {
  Active = "ACT",
  Hold = "HLD",
  Pending = "PEN",
  Reject = "REJ",
  Approve = "APR",
  NoResponse = "NOR",

  HoldText = "Hold",
  PendingText = "In Process",
  RejectText = "Rejected",
  ApproveText = "Approved",
  NotInitiatedText = "Not Initiated",
}

interface IInbox {
  messages: {
    [key: string]: {
      page: number,
      total: number,
      data: any[],
      dataLoading: boolean
    }
  }
  folders: any[],
  unReadCount: number,
  dataLoaded: boolean
}

const InitialState: IInbox = {
  messages: {},
  folders: [],
  unReadCount: 0,
  dataLoaded: false
};

const InboxSlice = createSlice({
  name: "inbox",
  initialState: InitialState,
  reducers: {
    getUserInboxInformation() { },
    setUserInboxInformation(state, action: PayloadAction<{ folders: any[], unReadCount: number }>) {
      const payload = action.payload;
      return produce(state, (draftState) => {
        draftState.folders = payload.folders;
        draftState.unReadCount = payload.unReadCount;
        draftState.dataLoaded = true;
      });
    },
    setWorkflowApproval(state, action: PayloadAction<{ approvalResponse: EApprovalStatusType, approvalprid: number, reason: string, messagePrid: number, key: string }>) {
      const payload = action.payload;
      return produce(state, (draftState) => {
        if (draftState.messages[payload.key]) {
          // ! check this to renoprid 
          const findMessage = draftState.messages[payload.key].data.find(x => x.prid == payload.messagePrid);

          if (findMessage != null)
            findMessage.actionable = false;
        }

      });
    },
    setMessageRead(state, action: PayloadAction<{ isRead: boolean, messagePrid: number, key: string }>) {
      const payload = action.payload;
      return produce(state, (draftState) => {
        const findMessage = draftState.messages[payload.key].data.find(x => x.prid == payload.messagePrid);

        if (findMessage != null)
          findMessage.viewied = payload.isRead;
      });
    },
    markImportant(state, action: PayloadAction<{ messagePrid: number, key: string }>) {
      const payload = action.payload;
      return produce(state, (draftState) => {
        const findMessage = draftState.messages[payload.key].data.find(x => x.prid == payload.messagePrid);

        if (findMessage != null)
          findMessage.isimportant = !findMessage.isimportant;
      });
    },
    markTrash(state, action: PayloadAction<{ messagePrid: number, key: string }>) {
      const payload = action.payload;
      return produce(state, ({ messages }) => {
        const index = messages[payload.key].data.find(x => x.prid == payload.messagePrid);

        if (index != -1)
          messages[payload.key].data.splice(index, 1);

      });
    },
    getNotification(state, action: PayloadAction<{ key: string, reload: boolean, searchParam: string }>) {
      const payload = action.payload;
      return produce(state, (draftState) => {
        if (!draftState.messages[payload.key]) {
          draftState.messages[payload.key] = {
            data: [],
            page: 0,
            total: 0,
            dataLoading: false
          };
        }

        draftState.messages[payload.key].dataLoading = true;
      });
    },
    addNotification(state, action: PayloadAction<{ data: [], key: string, reload: boolean }>) {
      const payload = action.payload;
      return produce(state, (draftState) => {
        if (payload.reload) {
          draftState.messages[payload.key] = {
            data: [],
            page: 0,
            total: 0,
            dataLoading: false
          };
        }
        draftState.messages[payload.key].data.push(...payload.data);
        draftState.messages[payload.key].dataLoading = false;
      });
    },
  },
});

export const { getNotification, addNotification, markImportant, markTrash, getUserInboxInformation, setUserInboxInformation, setMessageRead, setWorkflowApproval } = InboxSlice.actions;

export default InboxSlice.reducer;
