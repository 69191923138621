import React, { Children, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/Hook/hooks';
import { toggleActiveScreenAccordion } from '@/Redux/Ducks/entryFormSlice';
import classNames from "classnames";
import { EObjectType } from '@/Redux/Ducks/tabSlice';
import { ControlTranslation, Icon } from "AlignComponents";
import { Accordion as BsAccordion, Badge } from 'react-bootstrap'

interface IProps {
  children: any,
  controlId: string
  system?: boolean,
  uniqueKey?: string,
  customName?: string,
  customManageState?: boolean,
  customActive?: boolean,
  toggleAccordion?: (controlId: string, active: boolean) => void
}

const Accordion = (props: IProps) => {
  const dispatch = useAppDispatch();
  const HeaderInformation = "headerinformation";

  const id = props.controlId + (props.uniqueKey || "");

  const activeObjectId = useAppSelector((state) => state.EntryForm.activeObjectId);

  const isScreenDocumentIsReverse = useAppSelector((state) => state.EntryForm.objects[activeObjectId]?.model?.isreverse);

  let activeAccordionId = false;

  if (props.customManageState) {
    activeAccordionId = props.customActive || false;
  }
  else {
    // ! this i have to move
    activeAccordionId = useAppSelector((state) => state.EntryForm.objects[activeObjectId].otherState.screenAccordion[id]);
  }

  useEffect(() => {
    if (!props.customManageState) {
      if (activeAccordionId == undefined && id == HeaderInformation) {
        dispatch(toggleActiveScreenAccordion({ controlId: id }));
      }
    }
  }, []);

  const setActiveAccordion = (controlId: string) => {
    if (props.customManageState) {
      props.toggleAccordion?.(controlId, !props.customActive);
    }
    else {
      dispatch(toggleActiveScreenAccordion({ controlId }));
    }
  }

  return (
    <div className="accordion accordion-container" data-controlid={id}>
      <div className="accordion-item">
        <h2 className="accordion-header" id={`heading-${props.controlId}`} onClick={() => { setActiveAccordion(id) }}>
          <span className={classNames([{ collapsed: !activeAccordionId }, "accordion-button"])} data-bs-toggle="collapse" data-bs-target={`#collapse-${props.controlId}`} aria-expanded={activeAccordionId} aria-controls={`collapse-${props.controlId}`}>
            <span className="d-flex" style={{ flex: 1 }}>
              <span className="me-auto">
                {
                  props.customName || <ControlTranslation controlId={props.controlId} system={props.system} />
                }
              </span>
              {
                isScreenDocumentIsReverse && props.controlId == HeaderInformation ?
                  <Badge className="my-auto me-2" bg="danger">
                    Reversed
                  </Badge>
                  : null
              }
            </span>
          </span>
        </h2>
        <div id={`collapse-${props.controlId}`} className={classNames([{ show: activeAccordionId }, "accordion-collapse collapse"])} aria-labelledby={`heading-${props.controlId}`}>
          <div className="accordion-body">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Accordion;