import UnAuthorizeLayout from "@/UI/Layout/UnAuthorize";
import { getTokens } from "@/Utils/auth";
import { Redirect, Route } from "react-router-dom";

const RouteUnauthorize = (props: any) => {

  const currentUser = getTokens().AccessToken;

  if (currentUser) return <Redirect to="/" />;

  return (
    <UnAuthorizeLayout>

      <Route {...props} />

    </UnAuthorizeLayout>
  );
};

export default RouteUnauthorize;
