import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import { fillDimensionFromDimensionSet, setFieldError, upsertDimension } from "@/Redux/Ducks/entryFormSlice";
import {
  closeHelp, EHelpOpenIn, getHelpConfig,
  IReturnParam,
  IReturnValues
} from "@/Redux/Ducks/helpSlice";
import { EFieldErrorType, KeyValueObject } from "@/UI/Interfaces/IBaseUI";
import { dimensionSetFields } from "@/Utils/constants";
import { Icon } from "AlignComponents";
import { useEffect } from "react";

interface IProps {
  helpObject: string;
  helpWhere: string;
  enable: boolean;
  fieldId: string;
  uniqueKey?: string;
  custom?: boolean;
  validatingHelp: boolean;
  customHelpParams?: KeyValueObject;
  fieldValueChange(fieldId: string, value: any, helpSelected: boolean): void;
  fieldBeforeHelpReturn(fieldid: string, helpobject: string, helpReturnValues: IReturnValues[]): void;
}

const AlignHelp = (props: IProps) => {
  const dispatch = useAppDispatch();

  const activeTabId = useAppSelector((state) => state.Tab.activeTabKey);

  const helpSelected = useAppSelector((state) => {
    // const help = state.Help[state.Tab.activeTabKey]?.loadedHelp;
    const returnOption = state.Help[state.Tab.activeTabKey]?.returnOption;

    // if (help && !help.isOpen && help.helpOpenFor == EHelpOpenFor.Field && help.helpType.isEqual(EHelpType.SideHelp, EHelpType.ShortHelp)) {
    if (returnOption && !returnOption.returnParam.gridno) {
      if (
        returnOption.returnParam.fieldId == props.fieldId &&
        returnOption.returnParam.uniqueKey == props.uniqueKey &&
        returnOption.returnValues &&
        returnOption.returnValues.length
      ) {
        return returnOption;
      }
    }

    return null;
  });

  useEffect(() => {
    if (helpSelected) {
      let helpReturnValues = helpSelected.returnValues as IReturnValues[][];

      // This is for Dimension Fields
      if (helpSelected.returnParam["isDimension" as keyof IReturnParam]) {
        dispatch(upsertDimension({ dimensionValues: { [helpReturnValues[0][0].fieldId]: helpReturnValues[0][0].fieldValue } }));
      }

      // This is for normal Fields
      else {
        let helpReturnValueForFields = [] as IReturnValues[];

        helpReturnValues.map(x => {
          x.map(y => {
            const findExist = helpReturnValueForFields.find(z => z.fieldId == y.fieldId);
            // Field Multi Value return with comma separated (...,...,..)
            if (findExist != null)
              findExist.fieldValue += "," + y.fieldValue;
            else
              helpReturnValueForFields.push({ fieldId: y.fieldId, fieldValue: y.fieldValue, dimensionValues: y.dimensionValues });
          })
        });

        props.fieldBeforeHelpReturn(props.fieldId, props.helpObject, helpReturnValueForFields);

        Promise.all(
          helpReturnValueForFields.map(async (x) => {
            // This will not work in Custom Fields which are (Data Analytics, Report or in some screens)
            if (!props.custom) {
              if (x.fieldId.isEqual(...dimensionSetFields)) {
                if (x.dimensionValues)
                  dispatch(upsertDimension({ dimensionValues: x.dimensionValues, dimensionSetFieldid: x.fieldId }));
              }
              else
                dispatch(setFieldError({ errorType: EFieldErrorType.HelpDataNotFound, fieldId: x.fieldId, isError: false }));
            }

            await props.fieldValueChange(x.fieldId, x.fieldValue, true);
          })
        );

      }

      dispatch(closeHelp({ objectId: activeTabId }));
    }
  }, [helpSelected]);

  const helpHandler = () => {
    const customHelpParams = Object.assign({ helpOpenIn: EHelpOpenIn.EntryForm }, props.customHelpParams);

    dispatch(
      getHelpConfig({
        helpObject: props.helpObject,
        helpWhere: props.helpWhere,
        returnParams: {
          fieldId: props.fieldId,
          uniqueKey: props.uniqueKey,
        },
        ...customHelpParams
      })
    );
  };

  return (
    <button
      className="help-button"
      disabled={!props.enable || props.validatingHelp}
      onClick={helpHandler}
      tabIndex={-1}
    >
      <div data-rh="Data Look Up">
        {
          props.validatingHelp ?

            <Icon
              icon="Loader"
              size="16"
              color="#575757"
              className="help-loader mx-1"
              style={{ margin: "auto", flex: 1 }}
            />
            :
            <Icon
              icon="Search"
              size="16"
              color="#575757"
              className="mx-1"
            />
        }

      </div>
    </button>
  );
};

export default AlignHelp;
