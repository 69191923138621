import { Icon } from "@/Assets/Icons";
import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import { toggleLoader } from "@/Redux/Ducks/loaderSlice";
import { setActiveTab } from "@/Redux/Ducks/tabSlice";
import { logoutUser } from "@/Redux/Ducks/userSlice";
import { requestRefreshApplication } from "@/Redux/Sagas/Requests/user";
import { getCompanyDetails, setCompanyDetails } from "@/Utils/auth";
import { API_URL } from "@/Utils/constants";
import BaseMethods from "AlignBaseUI/BaseMethods";
import { ControlTranslation, Modal } from "AlignComponents";
import classNames from "classnames";
import QRCode from 'qrcode.react';
import { useEffect, useRef, useState } from "react";
import { Badge, Container, Form, Nav, Navbar, NavDropdown, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { AiOutlineQrcode, AiOutlineScan } from 'react-icons/ai';
import { FaCog, FaKeyboard, FaQrcode, FaSearch, FaSync } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { useHistory } from "react-router";
import Tab from '../Tab/index';

const Header = () => {
	const history = useHistory();
	const dispatch = useAppDispatch();

	var headerRef: any = useRef<HTMLDivElement>();
	const [leftChevronDisabled, disableLeftChevron] = useState<boolean>(true);
	const [rightChevronDisabled, disableRightChevron] = useState<boolean>(true);
	const [scrollLeft, setScrollLeft] = useState<number>(0);
	const [isHelpModalOpen, toggleHelpModal] = useState<boolean>(false);
	const [isQRCodeModalOpen, toggleQRCodeModal] = useState<boolean>(false);

	const getApiUrl = () => {
		if (API_URL?.includes("https") || API_URL?.includes("http"))
			return API_URL
		else
			return window.location.origin + API_URL?.substring(1)
	}

	//#region App State
	const { userLegalEntity, selectedLegalEntity, userFullName, selectedLegalEntityName, userphoto, defaultPage, allowMobile, qrCodeValue } = useAppSelector((state) => ({
		userLegalEntity: state.User.UserLegalEntity,
		selectedLegalEntity: state.User.selectedLegalEntity,
		selectedLegalEntityName: state.User.UserLegalEntity.find(x => x.legalentityno == state.User.selectedLegalEntity)?.legalentitystxt,
		userFullName: state.User.user.name?.split(' ').map(x => x.charAt(0).toUpperCase() + x.slice(1)).join(' '),
		userphoto: state.User.user.userphoto,
		// notification: {
		// 	unReadCount: state.Inbox.unReadCount,
		// 	messages: state.Inbox.messages["/notification"]
		// },
		defaultPage: state.User.defaultPage,
		allowMobile: state.User.user.allowmobile,
		qrCodeValue: JSON.stringify({
			authToken: state.User.authToken,
			apiUrl: getApiUrl()
		})
	}));
	const tabs = useAppSelector((x) => x.Tab.tabs);
	//#endregion

	//#region Methods
	const logoutFunc = () => {
		history.push("/");
		setTimeout(() => {
			dispatch(logoutUser());
		}, 10);
	};

	const refreshApplicationFunc = async () => {
		try {
			dispatch(toggleLoader(true));
			await requestRefreshApplication();
			location.reload();
		}
		catch (ex) {
		}
		finally {
			dispatch(toggleLoader(false));
		}
	};

	const navigateScreen = (routeTo: string) => {
		history.push(routeTo);
		dispatch(setActiveTab(""));
	}

	const changeLegalEntity = (x: any) => {
		console.log(x.target.value);

		const companyDetails = getCompanyDetails();
		setCompanyDetails({ timezone: companyDetails.timezone, selectedLegalEntity: x.target.value });
		location.reload();

	}

	//#endregion

	const userDropdown = (
		<div className="d-flex align-items-center">
			<img src={BaseMethods.getUserPhoto(userphoto)} alt="User" />
			<div className="ms-2 d-flex flex-column">
				<span className="user">{userFullName}</span>
				<span className="legal-entity">{selectedLegalEntityName}</span>
			</div>
		</div>
	);

	const helpDropdown = (
		<div dangerouslySetInnerHTML={{ __html: Icon.Help }} />
	)

	const scrollToRight = () => {
		if (headerRef.current) {
			var tabEl: any = headerRef.current.getElementsByClassName('navbar-nav')[0];
			var isHorizontalScroll = tabEl.scrollWidth > tabEl.clientWidth;
			var availableScroll = tabEl.scrollWidth - tabEl.clientWidth;
			var scrollLeft = tabEl.style.left ? Math.abs(parseInt(tabEl.style.left.replace("px", ""))) : 0;

			if (isHorizontalScroll) {
				if (scrollLeft + 20 <= availableScroll) {
					tabEl.style.left = `-${scrollLeft + 20}px`;
					setScrollLeft(scrollLeft);
				}
				else {
					tabEl.style.left = `-${availableScroll}px`;
					setScrollLeft(scrollLeft);
					disableRightChevron(true);
				}
			}
		}
	}

	const scrollToLeft = () => {
		if (headerRef.current) {
			var tabEl: any = headerRef.current.getElementsByClassName('navbar-nav')[0];
			var isHorizontalScroll = tabEl.scrollWidth > tabEl.clientWidth;
			var availableScroll = tabEl.scrollWidth - tabEl.clientWidth;
			var scrollLeft = tabEl.style.left ? Math.abs(parseInt(tabEl.style.left.replace("px", ""))) : 0;

			if (isHorizontalScroll) {
				if (scrollLeft != 0) {
					if (scrollLeft - 20 <= availableScroll) {
						tabEl.style.left = `-${scrollLeft - 20}px`;
						setScrollLeft(scrollLeft);
					}
					else {
						tabEl.style.left = "0px";
						setScrollLeft(scrollLeft);
						disableLeftChevron(true);
					}
				}
			}
		}
	}

	const routeToDocumentation = () => {
		const docUrl = 'http://businessflo.alignbsystem.com/documentation/';
		window.open(docUrl, '_blank')?.focus();
	}

	useEffect(() => {
		if (headerRef.current) {
			var tabEl = headerRef.current.getElementsByClassName('navbar-nav')[0];
			var isHorizontalScroll = tabEl.scrollWidth > tabEl.clientWidth;
			var availableScroll = tabEl.scrollWidth - tabEl.clientWidth;
			var scrollLeft = tabEl.style.left ? Math.abs(parseInt(tabEl.style.left.replace("px", ""))) : 0;

			if (isHorizontalScroll) {
				if (scrollLeft == 0) {
					disableLeftChevron(true);
					disableRightChevron(false);
				}
				else if (scrollLeft >= availableScroll) {
					disableLeftChevron(false);
					disableRightChevron(true);
				}
				else {
					disableLeftChevron(false);
					disableRightChevron(false);
				}
			}
			else {
				disableLeftChevron(true);
				disableRightChevron(true);
			}
		}
	}, [tabs, scrollLeft])

	const closeHelpModal = () => {
		toggleHelpModal(false);
	}

	const openHelpModal = () => {
		toggleHelpModal(true);
	}

	const closeQRCodeModal = () => {
		toggleQRCodeModal(false);
	}

	const openQRCodeModal = () => {
		toggleQRCodeModal(true);
	}

	return (
		<>
			<Modal
				close={closeHelpModal}
				heading="Keyboard Shortcuts"
				show={isHelpModalOpen}
				bodyStyle={{ minHeight: "350px", padding: '0' }}
				modalSize="md"
			>
				<Table hover>
					<thead>
						<tr>
							<th>Key</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<Badge className="me-1" bg="primary">Alt</Badge>
								+
								<Badge className="ms-1" bg="primary">s</Badge>
							</td>
							<td>
								Open Search Bar
							</td>
						</tr>
						<tr>
							<td>
								<Badge className="me-1" bg="primary">Alt</Badge>
								+
								<Badge className="ms-1" bg="primary">m</Badge>
							</td>
							<td>
								Open Module Menu
							</td>
						</tr>
						<tr>
							<td>
								<Badge className="me-1" bg="primary">Alt</Badge>
								+
								<Badge className="ms-1" bg="primary">q</Badge>
							</td>
							<td>
								Open Favorite Menu
							</td>
						</tr>
						<tr>
							<td>
								<Badge className="me-1" bg="primary">Alt</Badge>
								+
								<Badge className="ms-1" bg="primary">h</Badge>
							</td>
							<td>
								Navigate to Home
							</td>
						</tr>
						<tr>
							<td>
								<Badge className="me-1" bg="primary">Alt</Badge>
								+
								<Badge className="ms-1" bg="primary">c</Badge>
							</td>
							<td>
								Navigate to Dashboard
							</td>
						</tr>
						<tr>
							<td>
								<Badge className="me-1" bg="primary">Alt</Badge>
								+
								<Badge className="ms-1" bg="primary">i</Badge>
							</td>
							<td>
								Navigate to Inbox
							</td>
						</tr>
					</tbody>
				</Table>
			</Modal>

			<Modal
				close={closeQRCodeModal}
				heading="Link Device"
				show={isQRCodeModalOpen}
				headerStyle={{ display: 'none' }}
				bodyStyle={{ padding: "50px 0px 40px 0px" }}
				modalDialogStyle={{ textAlign: 'center', maxWidth: '350px' }}
				backdropStyle={{ opacity: "0.1" }}
				modalSize="sm"
				className="qr-code-modal"
			>
				<>
					<div className="modal-icon-container">
						<div className="modal-icon">
							<AiOutlineScan size={40} color="#fff" />
						</div>
					</div>
					<div className="qr-code-text">
						<h3>Scan QR Code</h3>
						<p>Scan this code to login to your mobile app</p>
					</div>
					<div className="qr-code-container">
						<QRCode value={qrCodeValue} size={200} />
						{/* <img src={require("@/Assets/Images/logo-light.png").default} alt="Logo" className="qr-code-logo" /> */}
					</div>
				</>
			</Modal>

			<div ref={headerRef}>
				<Navbar bg="white" expand={true}>
					<Container fluid className="px-0 align-items-end">
						<Tab />

						<Nav className="ms-auto border-start" id="userNav">
							<button
								className={classNames(["chevron", { "disabled": leftChevronDisabled }])}
								onClick={scrollToLeft}
								dangerouslySetInnerHTML={{ __html: Icon.LeftChevron }}
							/>
							<button
								className={classNames(["chevron", { "disabled": rightChevronDisabled }])}
								onClick={scrollToRight}
								dangerouslySetInnerHTML={{ __html: Icon.RightChevron }}
							/>
							<OverlayTrigger placement="bottom" overlay={<Tooltip>Inbox</Tooltip>}>
								<Nav.Link tabIndex={2} role="button" className="border-start" dangerouslySetInnerHTML={{ __html: Icon.Inbox }} onClick={() => navigateScreen("/approval")}></Nav.Link>
							</OverlayTrigger>
							<OverlayTrigger placement="bottom" overlay={<Tooltip>Default Page</Tooltip>}>
								<Nav.Link tabIndex={2} role="button" dangerouslySetInnerHTML={{ __html: Icon.DefaultPage }} onClick={() => navigateScreen(defaultPage)}></Nav.Link>
							</OverlayTrigger>
							<NavDropdown as={Nav.Link} tabIndex={1} title={helpDropdown} id="help-dropdown" className="border-end px-0">
								<NavDropdown.Item role="button" onClick={routeToDocumentation}>
									<FaSearch size={14} className="me-2" color="#adadad" />
									Documentation
								</NavDropdown.Item>
								<NavDropdown.Item role="button" onClick={openHelpModal}>
									<FaKeyboard size={14} className="me-2" color="#adadad" />
									Keyboard Shortcut
								</NavDropdown.Item>
							</NavDropdown>
							<NavDropdown tabIndex={3} title={userDropdown} id="user-dropdown">
								<NavDropdown.Item role="button">
									<FaCog size={14} className="me-2" color="#adadad" />
									<ControlTranslation system={true} controlId="lblusersettings" />
								</NavDropdown.Item>
								{
									allowMobile ? (
										<NavDropdown.Item role="button" onClick={openQRCodeModal}>
											<FaQrcode size={14} className="me-2" color="#adadad" />
											<ControlTranslation system={true} controlId="btnlinkdevice" />
										</NavDropdown.Item>
									) : null
								}
								<NavDropdown.Item role="button" onClick={refreshApplicationFunc}>
									<FaSync size={14} className="me-2" color="#adadad" />
									<ControlTranslation system={true} controlId="btnrefreshapplication" />
								</NavDropdown.Item>
								<NavDropdown.Divider />
								<NavDropdown.Item role="button" onClick={logoutFunc}>
									<FiLogOut size={14} className="me-2" color="#adadad" />
									<ControlTranslation system={true} controlId="btnlogout" />
								</NavDropdown.Item>
								<NavDropdown.Divider />
								<NavDropdown.ItemText role="button">
									<Form.Select value={selectedLegalEntity} onChange={changeLegalEntity} size="sm">
										<option value="" disabled>
											--Select Company--
										</option>
										{userLegalEntity.map((x, i) => (
											<option key={x.legalentityno} value={x.legalentityno}>
												{x.legalentitystxt}
											</option>
										))}
									</Form.Select>
								</NavDropdown.ItemText>

							</NavDropdown>
						</Nav>
					</Container>
				</Navbar >
			</div>
		</>
	);
};

export default Header;
