import { Icon } from 'AlignComponents';
import { useRef } from 'react'
import { Card, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDrag, useDrop } from "react-dnd";
import { BsThreeDotsVertical } from 'react-icons/bs';
import { IWorkspaceWidget } from '.';

interface IProps {
  tile: IWorkspaceWidget,
  index: number,
  moveTiles: (dragIndex: number, hoverIndex: number) => void,
  routeToScreen: (screenCode: string, gridView: string, objectType: string) => void,
  openUpdateWorkspace: (props: {
    widgetNo: string;
    widgetName: string;
    widgetType: string;
    icon?: string | undefined;
    widgetGroup?: string | undefined;
  }) => void,
  deleteWorkspace: (widgetNo: string) => void
}
export const Tile = ({ tile, index, moveTiles, routeToScreen, openUpdateWorkspace, deleteWorkspace }: IProps) => {
  const ref = useRef<any>(null);
  const icon: any = tile.icon || '';
  const [, drop] = useDrop({
    accept: 'Tiles',
    hover(item: { id: number, index: number }, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset: any = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY: any = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveTiles(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'Tiles',
    item: () => {
      return { id: tile.widgetNo, index: index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));


  return (
    <div ref={ref} className="d-flex">
      <Card role="button" key={tile.widgetName} onClick={(e: any) => { routeToScreen(tile.processCode, tile.gridView, tile.objectType) }}>
        <Card.Body>
          <div className="user-action-content">
            <span className="content-text">
              {tile.widgetName}
            </span>
            <span className="content-number d-flex align-items-center" style={{ color: !tile.showCount ? "transparent" : "unset" }}>
              <Icon icon={icon} className='me-2' size="28" color="#1E8BBF" />
              {tile.showCount ? tile.count : null}
            </span>
          </div>
        </Card.Body>
      </Card>
      <div>
        <Dropdown className="caret-none"	>
          <OverlayTrigger placement="top" overlay={<Tooltip>Menu</Tooltip>}>
            <Dropdown.Toggle id="workspace-menu-button" variant="white" className="p-0">
              <BsThreeDotsVertical size="18" />
            </Dropdown.Toggle>
          </OverlayTrigger>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => { openUpdateWorkspace({ widgetNo: tile.widgetNo, widgetName: tile.widgetName, widgetType: tile.widgetType, icon: tile.icon }) }}>Edit</Dropdown.Item>
            <Dropdown.Item onClick={() => { deleteWorkspace(tile.widgetNo) }}>Delete</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}
