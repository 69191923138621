import { useAppSelector } from "@/Hook/hooks";

const AlignLoader = () => {
  const loader = useAppSelector((x) => x.Loader);

  if (loader.toggle) {
    return (
      <div className="align-loader">
        {
          loader.loadingText ?
            <div className="position-absolute px-4 py-2 rounded mt-5 top-0 bg-white">{loader.loadingText}</div>
            : null
        }
        <div className="spinner"></div>
      </div>
    );
  }
  else
    return null;
};

export default AlignLoader;
