import { Card, Dropdown, OverlayTrigger, Tooltip, Row, Form, Button, Col } from "react-bootstrap";
import { MdDashboardCustomize, MdSave } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs"
import { ControlTranslation, Modal } from "AlignComponents"
import { AiOutlineAppstoreAdd } from "react-icons/ai";

interface IProps {
  userWorkspace: any;
  routeToWorkspace: any;
  openUpdateWorkspace: (workspaceNo: number, workspaceName: string) => void;
  openAddWorkspace: () => void;
  closeUpdateWorkspace: () => void;
  closeAddWorkspace: () => void;
  updateWorkspaceModalState: boolean;
  addWorkspaceModalState: boolean;
  addWorkspaceValidated: boolean;
  updateWorkspaceValidated: boolean;
  handleUpdateSubmit: (e: any) => void;
  handleAddSubmit: (e: any) => void;
  workspaceName: string;
  handleWorkspaceNameChange: (e: any) => void;
  deleteWorkspaceFunc: (workspaceNo: number) => void;
}
export const AlignUIRender = ({ userWorkspace, routeToWorkspace, openUpdateWorkspace, openAddWorkspace, closeUpdateWorkspace, closeAddWorkspace, updateWorkspaceModalState, addWorkspaceModalState, updateWorkspaceValidated, addWorkspaceValidated, handleUpdateSubmit, handleAddSubmit, workspaceName, handleWorkspaceNameChange, deleteWorkspaceFunc }: IProps) => (
  <>
    <Modal
      heading="Add Workspace"
      show={addWorkspaceModalState}
      close={closeAddWorkspace}
      bodyStyle={{ minHeight: "0px" }}
      footer={
        <Button form="addToWorkspace" variant="primary" type="submit">
          <MdSave className="me-2" size="16" />
          Save
        </Button>
      }
    >
      <Form id="addToWorkspace" noValidate validated={addWorkspaceValidated} onSubmit={handleAddSubmit}>
        <Form.Group className="my-1" controlId="workspaceName" as={Row}>
          <Form.Label column sm="3">Name:</Form.Label>
          <Col sm="9" className="d-flex align-items-center">
            <Form.Control required value={workspaceName} type="text" onChange={handleWorkspaceNameChange} />
          </Col>
        </Form.Group>
      </Form>
    </Modal>
    <Modal
      heading="Update Workspace"
      show={updateWorkspaceModalState}
      close={closeUpdateWorkspace}
      bodyStyle={{ minHeight: "0px" }}
      footer={
        <Button form="updateWorkspace" variant="primary" type="submit">
          <MdSave className="me-2" size="16" />
          Save
        </Button>
      }
    >
      <Form id="updateWorkspace" noValidate validated={updateWorkspaceValidated} onSubmit={handleUpdateSubmit}>
        <Form.Group className="mb-2" controlId="workspaceType" as={Row}>
          <Form.Label column sm="4">Name:</Form.Label>
          <Col sm="8" className="d-flex align-items-center">
            <Form.Control required value={workspaceName} type="text" onChange={handleWorkspaceNameChange} />
          </Col>
        </Form.Group>
      </Form>
    </Modal>
    <Col md="6" className="mt-2">
      <Card className="user-workspace">
        <Card.Header>
          <ControlTranslation system={true} controlId="lbluserworkspace" />
        </Card.Header>
        <Card.Body>
          <div className="d-flex gap-3">
            <Card className="user-workspace-add" role="button" onClick={openAddWorkspace}>
              <Card.Body>
                <AiOutlineAppstoreAdd color="#0080FF" size="30" />
                <span className="content-text">
                  <ControlTranslation system={true} controlId="lbladdworkspace" />
                </span>
              </Card.Body>
            </Card>
            {
              userWorkspace.map((x: any) => {
                return (
                  <>
                    <Card key={x.workspaceno} className="user-workspace-object" role="button" onClick={() => { routeToWorkspace(x.workspaceno) }}>
                      <Card.Body>
                        <span className="content-text">
                          {x.workspacestxt}
                        </span>
                        <Dropdown id={`workspace-dropdown-${x.workspaceNo}`} className="caret-none">
                          <OverlayTrigger placement="top" overlay={<Tooltip>Menu</Tooltip>}>
                            <Dropdown.Toggle id="workspace-menu-button" variant="white" className="p-0">
                              <BsThreeDotsVertical size="18" />
                            </Dropdown.Toggle>
                          </OverlayTrigger>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => { openUpdateWorkspace(x.workspaceno, x.workspacestxt) }}>Edit</Dropdown.Item>
                            <Dropdown.Item onClick={() => { deleteWorkspaceFunc(x.workspaceno) }}>Delete</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Card.Body>
                    </Card>
                  </>
                )
              })
            }
          </div>
        </Card.Body>
      </Card>
    </Col>
  </>
);
