import React from "react";
import { useAppDispatch, useAppSelector } from "@/Hook/hooks";

import { closeHelp, EHelpType, EHelpOpenFor, setHelpReturnValue } from "@/Redux/Ducks/helpSlice";
import { TableHelpUI } from "./tableHelpUI";
// @ts-ignore
import Fade from 'react-reveal/Fade';
import { Modal } from "react-bootstrap";
import { getDimensionValuesFromDimensionSetPromiseResolver } from "@/Redux/Ducks/entryFormSlice";
import { dimensionSetFields } from "@/Utils/constants";
import { ArrayKeyValueObject, KeyValueObject } from "@/UI/Interfaces/IBaseUI";
import ControlTranslation from "../ControlTranslation";

const SideHelpUI = () => {
  const dispatch = useAppDispatch();

  const helpConfig = useAppSelector((x) => {
    const activeHelp = x.Help[x.Tab.activeTabKey]?.loadedHelp;
    if (activeHelp) {
      if (activeHelp.helpType.isEqual(EHelpType.SideHelp) && activeHelp.helpOpenFor.isEqual(EHelpOpenFor.Field, EHelpOpenFor.Grid) && !activeHelp.isDropDown) {
        return activeHelp;
      }
    }
    return null;
  });
  const activeObjectId = useAppSelector((x) => x.Tab.activeTabKey);

  const closeHelpModal = () => {
    dispatch(closeHelp({ objectId: activeObjectId }));
  };

  const rowSelect = (selectedRow: any) => {

    try {
      if (!!helpConfig) {
        // if (helpConfig.helpOpenFor == EHelpOpenFor.Field) {
        //   fieldHelpSelected(selectedRow);
        // }
        // else if (helpConfig.helpOpenFor == EHelpOpenFor.Grid) {
        //   GridHelpSelected(selectedRow);
        // }
        if (helpConfig.multiSelect) {
          selectHelpData(selectedRow);
        }
        else {
          selectHelpData([selectedRow]);
        }
      }
    }
    catch (ex) {
      console.error(ex);
    }
  }

  const getDimension = (dimensionSets: string[]) => {
    return new Promise((resolve, reject) => dispatch(getDimensionValuesFromDimensionSetPromiseResolver({ dimensionSet: dimensionSets }, { resolve, reject })));
  }

  const selectHelpData = async (selectedRow: Array<any>) => {

    if (!!helpConfig) {
      const returnValue: Array<Array<any>> = [];

      const singlecolumn = helpConfig.helpConfig.Config.singlecolumn.trim();

      selectedRow.map((x: any) => {

        returnValue.push([{
          fieldId: helpConfig.returnParam.fieldId,
          fieldValue: x[singlecolumn]
        }]);

        if (helpConfig.helpConfig.Config.multiplereturn && helpConfig.helpConfig.Config.multiplecolumn) {
          helpConfig.helpConfig.Config.multiplecolumn.split(",").map(y => {
            if (!y.isEqual(helpConfig.returnParam.fieldId, singlecolumn)) {
              const fieldId = y.trim();

              returnValue[returnValue.length - 1].push({
                fieldId: fieldId,
                fieldValue: x[fieldId]
              });
            }
          });
        }

      });

      const dimensionSets: string[] = [];

      returnValue.map(x => {
        x.map(y => {
          if (y.fieldId.isEqual(...dimensionSetFields) && y.fieldValue)
            dimensionSets.push(y.fieldValue);
        });
      });


      if (dimensionSets.length) {
        const res: any = await getDimension(dimensionSets);

        returnValue.map(x => {
          x.map(y => {
            if (y.fieldId.isEqual(...dimensionSetFields)) {
              var findDimension = res.find((x: any) => x.dimensionset == y.fieldValue);

              if (findDimension)
                y.dimensionValues = findDimension.values;
            }
          });
        });

      }


      dispatch(setHelpReturnValue({ objectId: activeObjectId, returnValue, returnParam: helpConfig.returnParam }));
    }
  }

  // const fieldHelpSelected = (selectedRow: any) => {

  //   if (!!helpConfig) {
  //     const singlecolumn = helpConfig.helpConfig.Config.singlecolumn.trim();
  //     const multiplecolumn = helpConfig.helpConfig.Config.multiplecolumn.trim();

  //     const returnValue = [{
  //       fieldId: helpConfig.returnParam.fieldId,
  //       fieldValue: selectedRow[singlecolumn]
  //     }];

  //     if (helpConfig.helpConfig.Config.multiplereturn || multiplecolumn) {
  //       multiplecolumn.split(",").map(x => {
  //         const fieldId = x.trim();

  //         returnValue.push({
  //           fieldId: fieldId,
  //           fieldValue: selectedRow[fieldId]
  //         });
  //       });
  //     }

  //     dispatch(setHelpReturnValue({ objectId: activeObjectId, returnValue, returnParam: helpConfig.returnParam }));
  //   }
  // }

  // const GridHelpSelected = (selectedRow: Array<any>) => {

  //   if (!!helpConfig) {
  //     const returnValue: Array<Array<any>> = [];

  //     selectedRow.map((x: any) => {

  //       returnValue.push([{
  //         fieldId: helpConfig.returnParam.fieldId,
  //         fieldValue: x[helpConfig.helpConfig.Config.singlecolumn]
  //       }]);

  //       if (helpConfig.helpConfig.Config.multiplereturn && helpConfig.helpConfig.Config.multiplecolumn) {
  //         helpConfig.helpConfig.Config.multiplecolumn.split(",").map(y => {
  //           const fieldId = y.trim();

  //           returnValue[returnValue.length - 1].push({
  //             fieldId: fieldId,
  //             fieldValue: x[fieldId]
  //           });
  //         });
  //       }

  //     });

  //     dispatch(setHelpReturnValue({ objectId: activeObjectId, returnValue, returnParam: helpConfig.returnParam }));
  //   }
  // }
  if (!!helpConfig) {
    return (
      <Modal id="side-help-container" show={!!helpConfig} onHide={closeHelpModal} size="xl" centered>
        <Modal.Header closeButton>
          <h6 className="fw-bold m-0"><ControlTranslation system={true} controlId="lbldatalookup" /></h6>
        </Modal.Header>
        <TableHelpUI
          id={activeObjectId}
          modalBodyStyle={{ minHeight: "500px" }}
          columns={helpConfig.helpConfig.Header}
          data={helpConfig.helpConfig.Detail}
          rowSelect={rowSelect}
          multiSelect={helpConfig.multiSelect}
          helpType={helpConfig.helpType}
        />
      </Modal>
    );
  }
  else {
    return null;
  }
};

export default SideHelpUI;
