import { ControlTranslation, Icon } from "AlignComponents";
import { Form, Offcanvas } from "react-bootstrap";
import { BiData } from "react-icons/bi";
import { BsFileEarmarkCheck } from "react-icons/bs";
import { HiPencilAlt } from "react-icons/hi";
import { MdChevronRight } from "react-icons/md";

interface IProps {
  menuOpen: boolean,
  closeMenu: () => void,
  routeToScreen: (screenCode: string, applicationobjecttype: string) => void,
  filterUserFavorites: any,
  setFavoriteSearch: (value: string) => void,
}

export const AlignUIRender = ({ filterUserFavorites, menuOpen, closeMenu, routeToScreen, setFavoriteSearch }: IProps) => (
  <Offcanvas show={menuOpen} onHide={closeMenu} backdropClassName="backdrop" className="menu-favorite">
    <Offcanvas.Header className="border-bottom">
      <Offcanvas.Title>
        <Form.Control id="module-search" type="text" placeholder="Search" onChange={(e: any) => setFavoriteSearch(e.target.value)} />
      </Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body>
      <ul className="objects show my-2 ms-1">
        {
          filterUserFavorites.map((x: any, i: number) => (
            <li role="button" key={i} onClick={() => { routeToScreen(x.objectcontrollerno, x.applicationobjecttype) }} className="object">
              {
                x.applicationobjecttype == "I" ?
                  (
                    <BiData className="me-2" />
                  )
                  : x.applicationobjecttype == "R" ?
                    (
                      <BsFileEarmarkCheck className="me-2" />
                    ) : (
                      <HiPencilAlt className="me-2" />
                    )
              }
              {x.objectcontrollerstxt}
            </li>
          ))
        }
      </ul>
    </Offcanvas.Body>
  </Offcanvas >
);
