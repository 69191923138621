import { useAppSelector } from '@/Hook/hooks';
import { Accordion, FormDimension } from 'AlignComponents';
import React from 'react'

const ScreenHeader = (props: any) => {

  let visibleFormDimension = useAppSelector(state => {
    const screenConfig = state.EntryForm.objects[state.EntryForm.activeObjectId].ScreenConfig;

    return screenConfig.CompanyDimensionFields && screenConfig.CompanyDimensionFields.length && screenConfig.TcodeConfiguration.formdimension;
  });
  
  return (
    <div className="screen-header">
      {props.children}

      {
        visibleFormDimension ?
          <Accordion system={true} controlId="lblfinancialdimension">
            <FormDimension />
          </Accordion>
          : null
      }

    </div >
  )
}

export default ScreenHeader
