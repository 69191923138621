import { Suspense } from "react";
import DocumentHelpUI from "@/UI/BaseComponents/Help/documentHelpUI";

export const AlignUIRender = ({
  props,
  ScreenComponent,
  objectId,
  isConfigLoaded,
}: any) => (
  <>

    {
      isConfigLoaded ?
        <Suspense fallback={<div>Loading...</div>}>
          <ScreenComponent objectId={objectId} />
        </Suspense>
        : null
    }

    <DocumentHelpUI history={props.history} />

  </>
);
