import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import { toggleLoader } from "@/Redux/Ducks/loaderSlice";
import { useState } from "react";
import { AlignUIRender } from "./indexUI";
import { requestGetAllTodayActivity, requestGetFrequentlyUsed, requestGetUserApprovalActiveProcess } from "@/Redux/Sagas/Requests/home";
import { useHistory } from "react-router-dom";
import { getScreenConfig } from "@/Redux/Ducks/entryFormSlice";
import { toggleHidden } from "@/Redux/Ducks/userSlice";

const ActiveApprovals = () => {
  const dispatch = useAppDispatch();

  const [taskModalOpen, openTaskModal] = useState(false);
  const [userFrequentlyUsed, setUserFrequentlyUsed] = useState([]);
  const history = useHistory();

  //#region selector
  const userShortWidget = useAppSelector((state) => state.Home.userShortWidget);
  const hidden = useAppSelector((state) => state.User.hidden);

  const homeSettingObject = useAppSelector((state) => ({
    inbox: state.Inbox.messages?.["/approval/active"]?.data || [],
    inboxLoaded: !state.Inbox.messages?.["/approval/active"]?.dataLoading,
    dataLoaded: state.Home.dataLoaded
  }));

  const user: any = useAppSelector((state) => state.User.user.name);

  //#endregion

  const handleTaskModalClose = () => {
    openTaskModal(false);
  }

  const handleTaskModalOpen = async () => {
    try {
      dispatch(toggleLoader(true));
      const res = await requestGetFrequentlyUsed();

      openTaskModal(true);
      setUserFrequentlyUsed(res);
    }
    catch (ex) {
      console.error("Error while opening openAllActivity", ex);
    }
    finally {
      dispatch(toggleLoader(false));
    }
  }

  const routeToScreen = (screenCode: string, transactionNo: string = "") => {
    dispatch(getScreenConfig({ processCode: screenCode, transactionNo: transactionNo }));
  };

  const openMessage = (message: any) => {
    history.push({
      pathname: "approval/active",
      state: { activeMessage: message }
    });
  }

  const toggleHiddenFunc = (object: string) => {
    dispatch(toggleHidden(object))
  }

  return AlignUIRender({ homeSettingObject, user, history, userShortWidget, routeToScreen, handleTaskModalClose, handleTaskModalOpen, taskModalOpen, userFrequentlyUsed, openMessage, hidden, toggleHiddenFunc });
};

export default ActiveApprovals;

