import { useAppDispatch, useAppSelector } from '@/Hook/hooks';
import { upsertDimension } from '@/Redux/Ducks/entryFormSlice';
import { EActivity } from '@/UI/Interfaces/IBaseUI';
import { IFieldObject } from '@/UI/Interfaces/IFieldConfig';
import { AlignInput, AlignTab } from 'AlignComponents';

const FormDimension = () => {

  const dispatch = useAppDispatch();
  let dimensionFieldConfig = useAppSelector(state => state.EntryForm.objects[state.EntryForm.activeObjectId].ScreenConfig.CompanyDimensionFields);
  const isReadonly = useAppSelector(state => state.EntryForm.objects[state.EntryForm.activeObjectId].activity == EActivity.View);
  const dimensionValues = useAppSelector(state => state.EntryForm.objects[state.EntryForm.activeObjectId].dimension.find(x => x.dimensionRelations.includes("hdr-dimensionset"))?.dimensionValues);

  const BaseFieldStateSet = (fieldId: string, newValue: any) => {
    dispatch(upsertDimension({ dimensionValues: { [fieldId]: newValue } }));
  }

  return (
    <>
      {
        dimensionFieldConfig.filter(x => !x.fieldid.isEqual("affiliate")).map((x, i) => (
          <AlignInput
            key={x.fieldid}
            fieldId={x.fieldid}
            config={{ FieldConfig: [x], BaseFieldStateSet }}
            customFieldVisible={true}
            customFieldEnable={!isReadonly}
            customFieldHelpObject={x.helpobjectno}
            customFieldHelpWhere={x.helpwhere}
            customValidateMandatory={x.validatemandatory}
            uniqueKey={x.fieldid + "-dimension"}
            custom={true}
            customFieldValue={dimensionValues?.[x.fieldid] || ""}
            customHelpParams={{
              returnParams: {
                fieldId: x.fieldid,
                uniqueKey: x.fieldid + "-dimension",
                isDimension: true
              }
            }}
          />
        ))
      }

    </>
  )

}

export default FormDimension
