import { call, put, select } from "redux-saga/effects";
import { EHelpOpenFor, EHelpOpenIn, EHelpType, IGetHelp, setHelpConfig, setHelpDetailData } from "@/Redux/Ducks/helpSlice";
import { toggleLoader } from "@/Redux/Ducks/loaderSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/Redux/configureStore";
import { LoadHelpObject, LoadHelpObjectTags, requestGetDocumentHelp, requestGetLoadDocumentHelpData } from "../Requests/help";
import { setGlobalMessage } from "@/Redux/Ducks/messageSlice";
import { ISubGrid, KeyValueObject } from "@/UI/Interfaces/IBaseUI";
import { ICriteriaFieldValue } from "@/UI/Interfaces/IFieldConfig";

export function* handleGetHelpConfig({ payload }: PayloadAction<IGetHelp>): any {
  try {
    yield put(toggleLoader(true));

    const tabDetail = yield select((state: RootState) => state.Tab.tabs.find(x => x.tabKey == state.Tab.activeTabKey));
    const processcode = tabDetail?.tabActualKey as string;

    let findTags = yield call(handleGetScreenHelpRequiredTags, payload);

    const filterObject = {
      isAccountDimension: !!payload.isAccountDimension,
      dimensionValues: payload?.dimensionValues
    };


    const helpConfig = yield call(LoadHelpObject, payload.helpObject, payload.helpWhere, processcode, findTags, filterObject);

    const help = {
      config: helpConfig,
      tabKey: tabDetail.tabKey,
      returnParams: payload.returnParams,
      helpType: payload.helpType || EHelpType.SideHelp,
      helpOpenFor: payload.returnParams.gridno ? EHelpOpenFor.Grid : EHelpOpenFor.Field,
      isSubGrid: payload.isSubGrid || false,
      isDropDown: payload.isDropDown,
      helpOpenIn: payload.helpOpenIn || EHelpOpenIn.EntryForm,
      multiSelect: payload.multiSelect || false,
      shortHelpOptions: payload.shortHelpOptions || undefined
    };

    yield put(setHelpConfig(help));

  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {

    yield put(toggleLoader(false));

  }
}

export function* handlerReloadDocumentHelpData({ payload }: PayloadAction<{ objectId: string, filterApproval: string, filteredPrid: number }>): any {
  try {
    yield put(toggleLoader(true));

    const processcode = yield select((state: RootState) => state.Tab.tabs.find(x => x.tabKey == payload.objectId)?.tabActualKey);
    // const filteredPrid = yield select((state: RootState) => state.EntryForm.objects[payload.objectId].ScreenConfig.TcodeConfiguration.selectedDocumentHelpFilteredPrid);

    const dockeyConfig = yield select((state: RootState) => {
      const dockey = state.EntryForm.objects[payload.objectId].ScreenConfig.TcodeConfiguration.dockey;
      return state.EntryForm.objects[payload.objectId].ScreenConfig.Fields.FieldConfig.find(x => x.fieldid == dockey && x.activityno == "");
    });

    const filter = {
      filterapproval: payload.filterApproval,
      filterCriteria: {}
    }

    const response = yield call(requestGetLoadDocumentHelpData, dockeyConfig.helpobjectno, dockeyConfig.helpwhere, processcode, payload.filteredPrid, filter);

    yield put(setHelpDetailData({ tabKey: payload.objectId, data: response }));

  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

export function* handleGetScreenHelpRequiredTags(payload: IGetHelp): any {

  let helpTags = yield call(LoadHelpObjectTags, payload.helpObject, payload.helpWhere);

  let findTags: any = {};

  if (payload.helpOpenIn == EHelpOpenIn.EntryForm) {
    if (payload.isSubGrid) {

      let subGrids = yield select((state: RootState) => state.EntryForm.objects[state.EntryForm.activeObjectId].subGrid as Array<ISubGrid>);

      for (let i = subGrids.length - 1; i > -1; i--) {
        const element = subGrids[i] as ISubGrid;

        let rowIndex;
        if (i == subGrids.length - 1) { // for last grid
          rowIndex = payload.returnParams.gridRowIndex as number;
        }
        else { // for previous grid
          rowIndex = subGrids[i + 1].parentRowIndex;
        }

        const model = element.modelData[rowIndex];

        if (model)
          helpTags.map((x: any) => {
            if (model[x] != undefined)
              findTags[x] = model[x] || "";
          });

      }

    }

    if (payload.returnParams.gridno) {
      let model: any = {};
      const collectionName = payload.returnParams.gridno.getCollectionNameFromGridno();

      if (payload.isSubGrid)
        model = yield select((state: RootState) => {
          const allSubGrids = state.EntryForm.objects[state.EntryForm.activeObjectId].subGrid as Array<ISubGrid>;

          const parentGridno = allSubGrids[allSubGrids.length - 1].parentGridno.getCollectionNameFromGridno();
          const parentRowIndex = allSubGrids[allSubGrids.length - 1].parentRowIndex;

          return state.EntryForm.objects[state.EntryForm.activeObjectId].model[parentGridno]?.[parentRowIndex];
        });
      else
        model = yield select((state: RootState) => state.EntryForm.objects[state.EntryForm.activeObjectId].model[collectionName as string]?.[payload.returnParams.gridRowIndex as number]);

      if (model)
        helpTags.map((x: any) => {
          if (model && model[x] != undefined)
            findTags[x] = model[x] || "";
        });
    }
  }

  if (payload.helpOpenIn == EHelpOpenIn.EntryForm) {
    const model = yield select((state: RootState) => state.EntryForm.objects[state.EntryForm.activeObjectId].model);

    helpTags.map((x: any) => {
      if (findTags[x] == undefined)
        findTags[x] = model[x] || "";
    });

    if (!findTags["processcode"]) {
      const tabExists = yield select((state: RootState) => state.Tab.tabs.find(x => x.tabKey == state.Tab.activeTabKey));
      if (tabExists) {
        const processcode = tabExists.tabActualKey;
        findTags["processcode"] = processcode;
      }
    }
    if (!findTags["clientno"])
      delete findTags["clientno"];
  }
  else if (payload.helpOpenIn?.isEqual(EHelpOpenIn.ReportExplorer, EHelpOpenIn.DataAnalytics)) {
    let model: KeyValueObject<ICriteriaFieldValue>;

    if (payload.helpOpenIn?.isEqual(EHelpOpenIn.DataAnalytics))
      model = yield select((state: RootState) => state.DataAnalytics.dataAnalyticsDetailObject[state.DataAnalytics.activeDataAnalyticsObjectno].modelValue);
    else
      model = yield select((state: RootState) => state.ReportExplorer.reportDetailObject[state.ReportExplorer.activeReportObjectno].modelValue);

    helpTags.map((x: any) => {
      if (findTags[x] == undefined)
        findTags[x] = model[x]?.fromValue || "";
    });
  }

  if (!findTags["languageno"])
    delete findTags["languageno"];

  if (payload.helpOpenIn?.isEqual(EHelpOpenIn.DataAnalytics, EHelpOpenIn.ReportExplorer)) {
    if (!findTags["legalentityno"])
      delete findTags["legalentityno"];
  }

  return findTags;

}
