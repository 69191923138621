import { useEffect, useRef, useState } from 'react'
import * as wjInput from '@grapecity/wijmo.react.input';
import { useAppSelector } from '@/Hook/hooks';
import { IFieldProps } from '@/UI/Interfaces/IComponent';
import { useDispatch } from 'react-redux';
import { constant } from "@/Utils/constants";

const Field = ({ config, fieldValueChange, uniqueKey, enable, value, validateMandatory, hideLabel }: IFieldProps) => {
  const { fieldid } = config;
  const currentValue = useRef<Date>();
  let settingdate = new Date();
  settingdate.setHours(0, 0, 0, 0);

  const [fieldIsInEditing, setFieldIsInEditing] = useState<boolean>(false);

  const objectId = useAppSelector((x) => x.EntryForm.activeObjectId);
  const dataFormat = useAppSelector(state => {
    let dataFormat = constant.DefaultTimeFormat;

    state.User.CompanyDataFormat.map(x => {
      if (x.fielddataformatno == config.fielddataformatno)
        dataFormat = x.fielddataformatmasking;
    });

    if (state.EntryForm.objects?.[state.Tab.tabs?.[0]?.tabActualKey]) {
      const ProcessCodeDataFormat = state.EntryForm.objects[state.Tab.tabs[0].tabActualKey].ScreenConfig.ProcessCodeApplicationDataFormat;
      const finddataFormat = ProcessCodeDataFormat.find(x => x.fielddataformatno == config.fielddataformatno);
      if (finddataFormat != null)
        dataFormat = finddataFormat.fielddataformatmasking;
    }

    return dataFormat;
  });

  useEffect(() => {
    if (value === undefined) {
      callBaseMethod(settingdate);
    }
  }, [value]);


  const callBaseMethod = (value: Date) => {
    fieldValueChange(fieldid, value.toJSON());
  }

  const focus = (e: any) => {

    currentValue.current = e.value;

    setFieldIsInEditing(true);
  }

  const blur = (e: any) => {

    if (e.value != value) {
      callBaseMethod(e.value);
    }

    currentValue.current = new Date();
    setFieldIsInEditing(false);
  }


  if (!config) return <></>;
  const id = objectId + (uniqueKey ? `-${uniqueKey}` : "") + "-" + fieldid;
  const dateValue = new Date((fieldIsInEditing ? currentValue.current : value) || settingdate);
  return (
    <>
      {
        !hideLabel ?
          <label id={"lb-" + id} htmlFor={"field-" + id}>
            {config.fieldlabel}
            {validateMandatory ? (<span>*</span>) : null}
          </label>
          : null
      }
      <div className="input-time">
        <wjInput.InputTime gotFocus={focus} lostFocus={blur} isDisabled={!enable} format={dataFormat} id={"field-" + id} value={dateValue}>
        </wjInput.InputTime>
      </div>
    </>
  )

}

export default Field;

