import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import { useHistory } from "react-router-dom";
import { setActiveTab, closeTab, EObjectType } from "@/Redux/Ducks/tabSlice";
import { setActiveObjectId } from "@/Redux/Ducks/entryFormSlice";
import classNames from "classnames";
import { setActiveDataAnalyticsObjectId } from "@/Redux/Ducks/dataAnalyticsSlice";
import { setActiveReportObjectId } from "@/Redux/Ducks/reportExplorerSlice";
import { CloseButton, Nav, NavDropdown } from "react-bootstrap";
import { useRef } from "react";
import { IoMdTrash } from "react-icons/io";
import { openConfirmation } from "@/UI/BaseComponents/Alert";

const Tabs = (props: any) => {

  const activeTabKey = useAppSelector((x) => x.Tab.activeTabKey);

  const tabs = useAppSelector((x) => x.Tab.tabs.filter(x => !x.hidden));

  const history = useHistory();
  const dispatch = useAppDispatch();

  const routeToScreen = (object: any) => {
    if (activeTabKey != object.tabKey) {
      dispatch(setActiveTab(object.tabKey));

      if (tabs.find(x => x.tabKey == object.tabKey)?.objectType == EObjectType.EntryForm) {
        dispatch(setActiveObjectId(object.tabKey)); 
        // dispatch(setActiveDataAnalyticsObjectId(""));
        // dispatch(setActiveReportObjectId(""));
      }

      if (tabs.find(x => x.tabKey == object.tabKey)?.objectType == EObjectType.DataAnalytics) {
        dispatch(setActiveDataAnalyticsObjectId(object.tabKey));
        // dispatch(setActiveObjectId(""));
        // dispatch(setActiveReportObjectId(""));
      }

      if (tabs.find(x => x.tabKey == object.tabKey)?.objectType == EObjectType.ReportExplorer) {
        dispatch(setActiveReportObjectId(object.tabKey));
        // dispatch(setActiveObjectId(""));
        // dispatch(setActiveDataAnalyticsObjectId(""));
      }

      history.push(object.url);
    }
  };

  const closeCurrentTab = (e: any, object: any) => {
    e.stopPropagation();
    e.preventDefault();
    
    dispatch(closeTab(object.tabKey));
  };

  const closeAllTabs = async (e: any, key: string) => {
    e.stopPropagation();
    e.preventDefault();

    const res = await openConfirmation({ title: "Do you want to Close all Tabs ?", text: "This action can`t be reversed !" });
    const allTabs = tabs.filter(x => x.tabActualKey == key);

    if (res.cancel) {
      return;
    }
    else {
      allTabs.forEach(object => {
        dispatch(closeTab(object.tabKey));
      })
    }
  }

  return (
    <Nav variant="tabs" id="tabs" className="ps-2 align-items-end">
      {
        Object.entries(tabs.groupBy('tabActualKey')).map(([key], i) => (

          <NavDropdown title={
            <>
              {tabs.find(x => x.tabActualKey == key)?.tabName.replace(/ *\([^)]*\) */g, "")}
              <CloseButton className="me-3" style={{ position: 'absolute', right: "0" }} onClick={(e) => closeAllTabs(e, key)} />
            </>
          } key={key} className={classNames([{ active: key == tabs.find(x => x.tabKey == activeTabKey)?.tabActualKey }])}
          >
            {
              tabs.filter(x => x.tabActualKey == key).map((x, i) => (
                <NavDropdown.Item key={x.index} className={classNames([{ active: activeTabKey == x.tabKey }])} onClick={() => routeToScreen(x)}>
                  <div className="d-flex align-items-center">
                    <div className="w-100 pe-4">
                      {x.tabName.replace(/ *\([^)]*\) */g, "").concat(" - ")}
                      {
                        x.transactionno ? x.transactionno : "List"
                      }
                    </div>
                    <CloseButton className="h-100 position-absolute end-0 font-size-9" style={{ width: "30px", fontSize: "9px" }} variant={activeTabKey == x.tabKey ? "white" : undefined} onClick={(e) => closeCurrentTab(e, x)} />
                  </div>
                </NavDropdown.Item>
              ))
            }
          </NavDropdown>
        ))
      }
    </Nav >
  );
};

export default Tabs;