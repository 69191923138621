import { InputDate, InputTime, InputNumber } from '@grapecity/wijmo.input';
import { constant } from "@/Utils/constants";

export const date = (isRequired: boolean, DateFormat: string) => new InputDate(document.createElement('div'), {
  format: DateFormat,
  isRequired: isRequired
});

export const time = (TimeFormat: string) => new InputTime(document.createElement('div'), {
  format: TimeFormat
});

export const amount = () => new InputNumber(document.createElement('div'))