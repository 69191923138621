import { Text } from "recharts";
import { formatValue } from "./utils";

const CustomXAxisTick: any = ({ x, y, payload }: any) => {
  if (payload && payload.value) {
    return (
      <Text
        fontSize={"14px"}
        width={"14px"}
        x={x}
        y={y}
        textAnchor="middle"
        verticalAnchor="start"
      >{formatValue(payload.value)}</Text>
    );
  }
  return null;
};

const CustomYAxisTick: any = ({ x, y, payload }: any) => {
  if (payload && payload.value) {
    return (
      <Text
        fontSize={"14px"}
        width={"14px"}
        x={x}
        y={y}
        textAnchor="end"
        verticalAnchor="middle"
      >{formatValue(payload.value)}</Text>
    );
  }
  return null;
};

export { CustomXAxisTick, CustomYAxisTick }