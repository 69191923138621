import { useEffect, useState } from "react";
import { AlignUIRender } from "./indexUI";
import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import { getScreenConfig } from "@/Redux/Ducks/entryFormSlice";
import { getReportConfig } from "@/Redux/Ducks/reportExplorerSlice";
import { getDataAnalyticsConfig } from "@/Redux/Ducks/dataAnalyticsSlice";
import { useHistory } from "react-router-dom";
import { EMenu } from "@/Redux/Ducks/tabSlice";

const WorkspaceMenu = ({ closeMenu, activeMenu }: ComponentProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [menuOpen, toggleMenu] = useState<boolean>(false);
  const [workspaceSearch, setWorkspaceSearch] = useState<string>("");
  const userFavorites = useAppSelector(state => state.Workspace.userWorkspace);

  useEffect(() => {
    if (activeMenu == EMenu.Workspace) {
      toggleMenu(true);
    }
    else {
      toggleMenu(false);
    }
  }, [activeMenu]);

  const routeToWorkspace = (workspaceNo: number) => {
    history.push(`/workspace/${workspaceNo}`)
  }

  const filterUserWorkspace = userFavorites.filter(x => {
    const searchSplit = workspaceSearch.split(" ");
    if (!searchSplit || searchSplit.filter(y => x.workspacestxt.toLowerCase().includes(y.toLowerCase())).length == searchSplit.length)
      return true;
  });

  return AlignUIRender({ filterUserWorkspace, routeToWorkspace, menuOpen, closeMenu, setWorkspaceSearch });
};

export default WorkspaceMenu;

declare type StateFromProps = {
  closeMenu: () => void,
  activeMenu: EMenu
};

declare type ComponentProps = StateFromProps;