import React from 'react'
import { IconProps } from 'react-feather';
import * as FeatherIcon from 'react-feather';

const Icons = ({ icon, ...props }: IconProps & { icon: keyof typeof FeatherIcon }) => {

  const IconComponent = FeatherIcon[icon];

  if (IconComponent)
    return (
      <IconComponent {...props} />
    )
  return null;
}

export default Icons
