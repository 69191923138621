import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import { changeObjectActivity, copyDocumentByPrid, deleteDocument, getDocumentByPrid, getScreenConfig, removeUserDocumentHelp, saveUserDocumentHelpFilter, setViewDefaultUserDocumentHelp } from "@/Redux/Ducks/entryFormSlice";
import { closeHelp, EHelpOpenFor, EHelpType, IGridView, IHelpHeader, reloadDocumentHelpData, setGridView } from "@/Redux/Ducks/helpSlice";
import { loaderText, toggleLoader } from "@/Redux/Ducks/loaderSlice";
import { setTabMessage } from "@/Redux/Ducks/messageSlice";
import { getReportConfig } from "@/Redux/Ducks/reportExplorerSlice";
import { closeTab } from "@/Redux/Ducks/tabSlice";
import { requestGetScreenUploadingTemplate, requestUploadScreenDataFromTemplate } from "@/Redux/Sagas/Requests/entryForm";
import { TableHelpUI, THelpTableHelpHandle } from "@/UI/BaseComponents/Help/tableHelpUI";
import { ArrayKeyValueObject, EActivity, IDimension, IOtherState, ISaveParams, KeyValueObject } from "@/UI/Interfaces/IBaseUI";
import { IFieldConfig } from "@/UI/Interfaces/IFieldConfig";
import * as wjCore from "@grapecity/wijmo";
import BaseMethods from "AlignBaseUI/BaseMethods";
import { ControlTranslation, Icon, Modal, Workspace } from "AlignComponents";
import classNames from "classnames";
import * as H from "history";
import { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Dropdown, DropdownButton, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegCheckCircle } from "react-icons/fa";
import { FiCopy, FiDownload, FiFilter, FiUpload } from "react-icons/fi";
import { HiOutlinePrinter } from "react-icons/hi";
import { IoMdAdd, IoMdClose, IoMdRefresh, IoMdSave, IoMdStar, IoMdStarOutline, IoMdTrash } from "react-icons/io";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import XLSX from "xlsx-js-style";
import { openConfirmation, openErrorConfirmation } from "../Alert";
import { setUserFavorite } from "@/Redux/Ducks/homeSlice";
import { MdDashboardCustomize, MdSave } from "react-icons/md";
import { addWorkspace } from "@/Redux/Ducks/workspaceSlice";
import { GrInfo } from "react-icons/gr";
import md5 from "js-md5";

const DocumentHelpUI = ({ history }: { history: H.History }) => {

  const dispatch = useAppDispatch();

  // const defaultMenu = [
  //   {
  //     header: "Column Visibility",
  //     cmd: "columnVisibility",
  //     items: []
  //   },
  //   {
  //     header: "Clear View",
  //     cmd: "clearView",
  //   }
  // ];

  // const defaultMenuSource = useRef<any>(defaultMenu);

  const [filterModalOpen, toggleFilterModalOpen] = useState<boolean>(false);
  const [importModalOpen, toggleImportModalOpen] = useState<boolean>(false);
  const [importHeaderTemplate, setImportHeaderTemplate] = useState<(IHelpHeader & { mandatory: boolean })[]>([]);
  const [importTemplate, setImportTemplate] = useState<{
    allUploaded: boolean,
    header: typeof importHeaderTemplate,
    model: ArrayKeyValueObject,
    modelInGroup: ArrayKeyValueObject,
    dimensionSet: IDimension[]
  }>({ allUploaded: false, header: [], model: [], modelInGroup: [], dimensionSet: [] });

  const importFileRef = useRef<any>();
  const rowMenu = useRef<HTMLDivElement>(null);
  const gridRef = useRef<THelpTableHelpHandle>(null);
  const gridMenuOptionRef = useRef<any>();
  const settingSaveTimeout = useRef<any>(null);
  const [showGrid, setShowGrid] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [deleteBtn, showDeleteBtn] = useState<boolean>(false);
  const [activeRow, setActiveRow] = useState<any>(null);
  const [filterName, setFilterName] = useState<string>("");
  const [isDefault, toggleDefault] = useState<boolean>(false);
  const [objects, setObjects] = useState<Array<{ header: string, columnId: string, selected: boolean }>>([]);
  const [columnVisibilityModal, setModalColumnVisibility] = useState<boolean>(false);

  const helpConfig = useAppSelector((x) => x.Help[x.EntryForm.activeObjectId]?.loadedHelp);
  const activeTabObject = useAppSelector((x) => x.Tab.tabs.find(y => y.tabKey == x.Tab.activeTabKey));
  const activeObject = useAppSelector((x) => x.EntryForm.objects[x.EntryForm.activeObjectId]);
  const loginUsername = useAppSelector((x) => x.User.user.username) as string;
  const defaultReport = useAppSelector(x => x.EntryForm.objects[x.EntryForm.activeObjectId]?.ScreenConfig.ProcessReports.find(x => x.isdefault));
  const selectedLegalEntity = useAppSelector(x => x.User.selectedLegalEntity);

  let gridViewApplyTimeout: any;

  useEffect(() => {

    // defaultMenuSource.current = defaultMenu;
    setImportTemplate({
      allUploaded: false,
      header: [],
      model: [],
      modelInGroup: [],
      dimensionSet: []
    });

    if (settingSaveTimeout.current)
      clearTimeout(settingSaveTimeout.current);

    if (showGrid)
      setShowGrid(false);
    setTimeout(() => {
      setShowGrid(true);
    }, 160);
    // if (gridMenuOptionRef.current?.control)
    //   gridMenuOptionRef.current.control.dispose();
    // if (gridRef.current?.gridRef.current) {
    //   setTimeout(() => {
    //     gridRef.current?.gridRef.current.control.refresh(true);
    //     gridRef.current?.gridRef.current.control.itemsSource.refresh(true);
    //     gridRef.current?.gridRef.current.control.collectionView.refresh(true);
    //   }, 500);
    // }

  }, [history.location.pathname])

  const isHelpOpen = !!helpConfig;

  const criteriaUserFieldConfig = {
    controltypeno: "TXT",
    fieldid: "approvalusername",
    fieldlabel: "username",
    helpobjectno: "HELP_SAUS",
    helpwhere: "",
    fieldwidth: 25,
  } as IFieldConfig;

  // const singleRowSelect = (selectedRow: any) => {
  //   try {
  //     if (selectedRow.prid) {
  //       const processcode = activeTabObject?.tabActualKey as string;

  //       // TODO Change Function Name
  //       dispatch(getDocumentByPrid({ processcode: processcode, prid: selectedRow.prid }));

  //     }
  //     else {
  //       throw Error("Document Technical ID is not available");
  //     }

  //   }
  //   catch (ex) {
  //     console.error(ex);
  //   }
  // }

  const newDocument = () => {
    const objectId = activeTabObject?.tabKey as string;

    dispatch(closeHelp({ objectId }));
    dispatch(changeObjectActivity({ objectId, activity: EActivity.Create }));
  };

  const baseCloseTab = () => {
    if (activeTabObject)
      dispatch(closeTab(activeTabObject.tabKey));
  }
  // #region filter

  const handleFilterModalOpen = () => {
    toggleFilterModalOpen(true);
  }

  const handleFilterModalClose = () => {
    toggleFilterModalOpen(false);
  }

  const handleFilterSaveModal = () => {
    const filtersetting = {
      filterDefinition: gridRef.current?.gridFilterRef.current.control.filterDefinition,
      frozenColumns: gridRef.current?.gridRef.current.control.frozenColumns,
      columnIndex: gridRef.current?.gridRef.current.control.columns.map((x: any) => x.binding),
      columnWidth: gridRef.current?.gridRef.current.control.columns.map((x: any) => x.width),
      columnHidden: gridRef.current?.gridRef.current.control.columns.filter((x: any) => !x.visible).map((x: any) => x.binding)
    }

    const object = {
      processcode: activeTabObject?.tabActualKey,
      filterstxt: filterName,
      isdefault: isDefault,
      filterapproval: helpConfig?.gridView.filterApproval,
      filtersetting: JSON.stringify(filtersetting)
    };

    dispatch(saveUserDocumentHelpFilter(object));

    setFilterName(""), toggleDefault(false), toggleFilterModalOpen(false);
  }

  // #endregion

  const onChangeHandler = (e: any) => {
    switch (e.target.id) {
      case "filterName":
        setFilterName(e.target.value);
        break;
      case "isDefault":
        if (e.target.value == "on") {
          toggleDefault(true);
        }
        else {
          toggleDefault(false);
        }
        break;
    }
  }

  const deleteRecord = async () => {
    try {
      const Dockey = activeRow[activeObject.ScreenConfig.TcodeConfiguration.dockey];
      let message;
      if (Dockey)
        message = `You want to delete ${Dockey} entry`;
      else
        message = "You want to delete this entry";

      const res = await openErrorConfirmation({ title: "Are you sure?", text: message, confirmButtonText: "Yes, delete it!" });
      const prid = activeRow.prid as number;

      if (res.confirm && helpConfig) {
        dispatch(deleteDocument({ processcode: activeObject.ScreenConfig.TcodeConfiguration.processcode, prid }));
        reloadData(helpConfig.gridView.filterApproval);
      }

    }
    catch (ex) {
      dispatch(setTabMessage({ error: ex }))
    }
  }

  const openRecord = async (row: any) => {
    try {
      var prid = row.prid as number;
      if (prid) {
        const processcode = activeTabObject?.tabActualKey as string;

        // TODO Change Function Name
        dispatch(getDocumentByPrid({ processcode: processcode, prid: prid }));

      }
      else {
        throw Error("Document Technical ID is not available");
      }

    }
    catch (ex) {
      console.error(ex);
    }
  }

  const copyDocument = async () => {
    try {
      const prid = activeRow.prid as number;
      if (prid) {
        // TODO Change Function Name
        dispatch(copyDocumentByPrid({ prid: prid }));

      }
      else {
        throw Error("Document Technical ID is not available");
      }

    }
    catch (ex) {
      console.error(ex);
    }
  }


  const openDocumentation = (url: string) => {
    window.open(url, '_blank');
  }

  const printReport = (reportno: string) => {
    const dockey = activeObject.ScreenConfig.TcodeConfiguration.dockey;
    const dockeyValue = activeRow[dockey];
    dispatch(getReportConfig({ reportObjectno: reportno, criteria: { [dockey]: dockeyValue } }));
  }

  const openInNewTab = () => {
    const dockey = activeObject.ScreenConfig.TcodeConfiguration.dockey;
    const processcode = activeObject.ScreenConfig.TcodeConfiguration.processcode;
    const dockeyValue = activeRow[dockey];
    dispatch(getScreenConfig({ processCode: processcode, transactionNo: dockeyValue }));
  }

  const openHyperLink = (processcode: string, transactionNo: string) => {
    dispatch(getScreenConfig({ processCode: processcode, transactionNo: transactionNo }));
  }

  const removeView = (prid: number) => {
    dispatch(removeUserDocumentHelp({ prid }));
  }

  const setViewDefault = (prid: number, isDefault: boolean) => {
    dispatch(setViewDefaultUserDocumentHelp({ prid, processcode: activeTabObject?.tabActualKey as string, isDefault }));
  }

  const filterByApprover = (apply: boolean) => {
    dispatch(setGridView({ objectId: activeTabObject?.tabKey as string, filterApproval: apply }));
    reloadData(apply);
  }

  const reloadData = (filterApproval: boolean) => {
    dispatch(reloadDocumentHelpData({ objectId: activeTabObject?.tabKey as string, filteredPrid: 0, filterApproval }));
  }

  const initializeGrid = (GridControl: any, FilterControl: any) => {

    GridControl.allowPinning = "ColumnRange";
    if (helpConfig)
      applyGridSetting(GridControl, FilterControl, helpConfig.gridView.setting);

    GridControl.pinnedColumn.addHandler((s: any, e: any) => {
      storeGridSetting();
    });

    GridControl.resizedColumn.addHandler((s: any, e: any) => {
      storeGridSetting();
    });

    GridControl.draggedColumn.addHandler((s: any, e: any) => {
      storeGridSetting();
    });

    FilterControl.filterApplied.addHandler((s: any, e: any) => {

    });

    FilterControl.filterChanged.addHandler((s: any, e: any) => {
      storeGridSetting();
    });

    //#region Grid Events

    GridControl.hostElement.addEventListener("mouseover", (ev: any) => {
      let ht = GridControl.hitTest(ev), row = ht.getRow();
      var checkCell = wjCore.closest(ev.target, ".wj-cells");

      // show the menu for the current column
      if (GridControl.selection.col > -1) {
        ev.preventDefault(); // cancel the browser's default menu
        if (checkCell) {
          // var rowMenu = document.getElementById("rowMenu");
          if (rowMenu.current) {
            let row = GridControl.rows[ht.row].dataItem;
            setActiveRow(row)

            const deleteRights = activeObject.ScreenConfig.TcodeConfiguration.isdelete;
            if (deleteRights) {

              if (row.isreverse != undefined) {
                if (row.isreverse) {
                  showDeleteBtn(false)
                }
                else if (!row.isreverse) {
                  showDeleteBtn(true)
                }
              }
              else {
                showDeleteBtn(true)
              }
            }
            else {
              showDeleteBtn(false)
            }

            setShowMenu(true)

            rowMenu.current.style.top = `${ht.target.closest(".wj-cell").getBoundingClientRect().top}px`;
          }
        }
      }
    });

    GridControl.hostElement.addEventListener("mouseout", (ev: any) => {


      // var a = wjCore.closest(ev.target, ".wj-cells");

      if (!rowMenu.current?.contains(ev.relatedTarget)) {
        setShowMenu(false)
      }
      // setTimeout(() => {
      // setShowMenu(false)
      // }, 3000);
    });

    GridControl.hostElement.addEventListener("click", (ev: any) => {
      let ht = GridControl.hitTest(ev);
      if (ht.row != -1) {
        let row = GridControl.rows[ht.row].dataItem;
        var col = GridControl.columns[ht.col];
        var colConfig = helpConfig?.helpConfig.Header.find(x => x.ColumnID == col.binding);
        var checkCell = wjCore.closest(ev.target, ".wj-cells");

        if (GridControl.selection.col > -1 && ht.target.closest("#dockey")) {
          let row = GridControl.rows[ht.row].dataItem;
          openRecord(row);
        }
        if (ht.target.classList.value.includes("jumpto-link")) {
          var hyperlinkjump = colConfig?.hyperlinktransactioncode as string;
          var transactionNo = row[col.binding];
          if (transactionNo)
            openHyperLink(hyperlinkjump, transactionNo)
        }
      }
    });

    GridControl.formatItem.addHandler(function (s: any, e: any) {
      var col = s.columns[e.col];
      var colConfig = helpConfig?.helpConfig.Header.find(x => x.ColumnID == col.binding);

      if (!col)
        return;

      if (e.panel == s.cells) {
        const docKey = activeObject.ScreenConfig.TcodeConfiguration.dockey;
        const isDockey = col.binding == docKey;
        // var gridConfig = activeObject.ScreenConfig.Grids
        if (isDockey) {
          e.cell.setAttribute("role", "button");
          e.cell.innerHTML = `<span class="grid-hyperlink document-link" role="button">${e.cell.innerHTML}</span>`
          e.cell.id = "dockey"
        }
        else if (colConfig?.hyperlinktransactioncode && !isDockey) {
          e.cell.innerHTML = `<span class="grid-hyperlink jumpto-link" role="button">${e.cell.innerHTML}</span>`;
        }
      }
    });

    //#endregion

    configureGridOption(GridControl);
  }

  const applyGridSetting = (GridControl: any, FilterControl: any, view: IGridView["setting"]) => {
    if (gridViewApplyTimeout)
      clearTimeout(gridViewApplyTimeout);

    gridViewApplyTimeout = setTimeout(() => {
      const gridView = view;
      FilterControl.filterDefinition = gridView.filterDefinition;

      const userColumnIndex = gridView.columnIndex;

      let index = 0;
      userColumnIndex.map(x => {
        const column = GridControl.columns.getColumn(x);
        if (column) {
          GridControl.columns.moveElement(column.index, index);
          column.width = gridView.columnWidth[index];

          const hidden = gridView.columnHidden.find(x => x == column.binding);
          if (hidden)
            column.visible = false;
          else
            column.visible = true;

          //* this is important work don't remove it without understanding
          index++;
        }
      });

      GridControl.frozenColumns = gridView.frozenColumns
    }, 100);
  }

  const storeGridSetting = () => {

    if (settingSaveTimeout.current)
      clearTimeout(settingSaveTimeout.current);

    settingSaveTimeout.current = setTimeout(() => {
      const filtersetting = {
        filterDefinition: gridRef.current?.gridFilterRef.current.control.filterDefinition,
        frozenColumns: gridRef.current?.gridRef.current.control.frozenColumns,
        columnIndex: gridRef.current?.gridRef.current.control.columns.map((x: any) => x.binding),
        columnWidth: gridRef.current?.gridRef.current.control.columns.map((x: any) => x.width),
        columnHidden: gridRef.current?.gridRef.current.control.columns.filter((x: any) => !x.visible).map((x: any) => x.binding)
      }

      dispatch(setGridView({ objectId: activeTabObject?.tabKey as string, filterApproval: helpConfig?.gridView.filterApproval, setting: filtersetting }));
    }, 500);
  }

  const configureGridOption = (GridControl: any) => {
    if (helpConfig) {
      var updatedObjects: any = [];

      helpConfig.helpConfig.Header.map((x) => {

        const object: any = {
          header: x.ColumnText,
          columnId: x.ColumnID,
          selected: GridControl.columns.getColumn(x.ColumnID).visible,
        }

        updatedObjects.push(object);
      });

      setObjects(updatedObjects);
    }
  }

  const handleImportModalClose = async () => {
    if (importTemplate.allUploaded == false && importTemplate.model.length) {
      const res = await openConfirmation({ title: "Are you sure, you want to close Uploader?", text: "" });
      if (res.cancel)
        return;
    }

    toggleImportModalOpen(false);

    if (importTemplate.model.filter(x => x._isuploaded).length && helpConfig)
      reloadData(helpConfig.gridView.filterApproval);

    setImportTemplate({
      allUploaded: false,
      header: [],
      model: [],
      modelInGroup: [],
      dimensionSet: []
    });
  }

  const applyView = (viewSetting: IOtherState["userDocumentHelpFilter"][0]) => {
    const view: IGridView = {
      filterPrid: viewSetting.prid,
      filterApproval: viewSetting.filterapproval,
      setting: JSON.parse(viewSetting.filtersetting) as IGridView["setting"]
    };
    const filterApproval = helpConfig?.gridView.filterApproval;

    dispatch(setGridView({ objectId: activeTabObject?.tabKey as string, filterApproval: view.filterApproval, filterPrid: view.filterPrid, setting: view.setting }));

    if (filterApproval != view.filterApproval) {
      dispatch(reloadDocumentHelpData({ objectId: activeTabObject?.tabKey as string, filteredPrid: 0, filterApproval: view.filterApproval }));
    }
    applyGridSetting(gridRef.current?.gridRef.current.control, gridRef.current?.gridFilterRef.current.control, view.setting);

  }

  const clearView = () => {
    if (helpConfig) {
      const setting: IGridView["setting"] = {
        columnHidden: ["prid"],
        columnIndex: helpConfig.helpConfig.Header.map(x => x.ColumnID),
        columnWidth: helpConfig.helpConfig.Header.map(x => 180/*x.ColumnWidth*/),
        filterDefinition: "",
        frozenColumns: 0
      };

      const view = {
        filterapproval: false,
        filtersetting: JSON.stringify(setting),
        prid: 0
      } as IOtherState["userDocumentHelpFilter"][0];

      applyView(view);
    }
  }

  const updateObject = (columnId: string) => {
    const updatedObjects = objects.map((object) => {
      if (object.columnId == columnId) {
        object.selected = !object.selected;
      }

      return object;
    })
    setObjects(updatedObjects)
  }

  const closeColumnSetting = () => {
    document.getElementById("grid-menu-column")?.click();
    document.getElementById("grid-menu-button")?.click();

    configureGridOption(gridRef.current?.gridRef.current.control);
  }

  const saveColumnSetting = () => {
    gridRef.current?.gridRef.current.control.columns.forEach((column: any) => {
      const gridColumnObject: any = objects.find(x => x.columnId == column.binding);

      if (gridColumnObject) {
        if (column.visible != gridColumnObject?.selected) {
          column.visible = gridColumnObject?.selected;
        }
      }
    });

    storeGridSetting();
    configureGridOption(gridRef.current?.gridRef.current.control);
  }

  const downloadScreenUploadingTemplate = async () => {
    try {
      const processcode = activeObject?.ScreenConfig?.TcodeConfiguration?.processcode as string;
      const importLabel = activeObject.ScreenConfig.TcodeConfiguration.importlabelstxt as string;

      const importTemplate = await getScreenUploadingTemplateFunc(processcode);

      if (importTemplate.length) {

        const templateData: ArrayKeyValueObject = [];

        importTemplate.filter(x => !x.ColumnID.isEqual("_uploadingresponse", "_isuploaded", "_row_id", "_transactionno")).map((x) => {
          const cellInfo: KeyValueObject = { v: x.ColumnID, t: "s" };
          if (x.mandatory)
            cellInfo.s = {
              border: {
                top: { style: "thin", color: { rgb: "FF0000" } },
                bottom: { style: "thin", color: { rgb: "FF0000" } },
                right: { style: "thin", color: { rgb: "FF0000" } },
                left: { style: "thin", color: { rgb: "FF0000" } }
              }
            };

          templateData.push(cellInfo);
        });

        var workbook = XLSX.utils.book_new();
        var worksheet = XLSX.utils.aoa_to_sheet([templateData]);

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Export')
        XLSX.writeFile(workbook, `${importLabel} Template.xlsx`);

      }
      else {
        throw Error("No Template Found");
      }
    }
    catch (error) {
      dispatch(setTabMessage({ tabId: activeObject?.objectId, error }));
    }
  }

  const getScreenUploadingTemplateFunc = async (processcode: string) => {
    try {
      let importTemplate: typeof importHeaderTemplate = importHeaderTemplate;
      if (!importTemplate.length) {
        dispatch(toggleLoader(true));
        const importTemplateConfig = await requestGetScreenUploadingTemplate(processcode);

        importTemplate.push({
          ColumnID: "_uploadingresponse",
          ColumnText: "Uploading Response",
          ColumnWidth: 0,
          controltype: "text",
          hyperlinktransactioncode: "",
          ishidden: false,
          mandatory: false,
          fielddataformatno: ""
        });
        importTemplate.push({
          ColumnID: "_isuploaded",
          ColumnText: "Uploaded",
          ColumnWidth: 0,
          controltype: "checkbox",
          hyperlinktransactioncode: "",
          ishidden: true,
          mandatory: false,
          fielddataformatno: ""
        });
        importTemplate.push({
          ColumnID: "_row_id",
          ColumnText: "_row_id",
          ColumnWidth: 0,
          controltype: "text",
          hyperlinktransactioncode: "",
          ishidden: true,
          mandatory: false,
          fielddataformatno: ""
        });
        importTemplate.push({
          ColumnID: "_transactionno",
          ColumnText: "Transaction no",
          ColumnWidth: 0,
          controltype: "text",
          hyperlinktransactioncode: "",
          ishidden: false,
          mandatory: false,
          fielddataformatno: ""
        });

        importTemplateConfig.map((x: any) => {
          let heading = x.objectno;
          let controltype = "";
          let mandatory = false;
          if (x.gridno) {
            heading = (x.gridno as string).getCollectionNameFromGridno() + "/" + heading;

            const gridFieldObject = activeObject?.ScreenConfig?.Grids?.GridConfig?.[x.gridno]?.Header?.find(y => y.fieldid == x.objectno);
            controltype = gridFieldObject?.controltypeno || "TXT";
            mandatory = gridFieldObject?.validatemandatory || false;
          }
          else {
            const fieldObject = activeObject?.ScreenConfig?.Fields.FieldConfig?.find(y => y.fieldid == x.objectno);

            controltype = fieldObject?.controltypeno || "TXT";
            mandatory = fieldObject?.validatemandatory || false;
          }

          importTemplate.push({
            ColumnID: heading,
            ColumnText: heading,
            ColumnWidth: 0,
            controltype,
            hyperlinktransactioncode: "",
            ishidden: false,
            mandatory,
            fielddataformatno: ""
          });
        });

        setImportHeaderTemplate(importTemplate);
      }

      return importTemplate;
    }
    catch (ex) {
      throw ex;
    }
    finally {
      dispatch(toggleLoader(false));
    }
  }

  const readDataFromExcelAndPreview = async (e: any) => {

    try {
      const fileData: any = await BaseMethods.readFileAsync(e);
      e.target.value = null;
      const processcode = activeObject?.ScreenConfig?.TcodeConfiguration?.processcode as string;

      var input = new Uint8Array(fileData);
      var workbook = XLSX.read(input, { type: "array", cellDates: true });

      var worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const data: ArrayKeyValueObject = XLSX.utils.sheet_to_json(worksheet);

      // #region Date converting Funcations

      const getTimezoneOffsetMS = (date: Date) => {
        var time = date.getTime();
        var utcTime = Date.UTC(date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
          date.getSeconds(),
          date.getMilliseconds());
        return time - utcTime;
      }

      const importBugHotfixDiff = (function () {
        const basedate = new Date(1899, 11, 30, 0, 0, 0);
        const dnthreshAsIs = (new Date().getTimezoneOffset() - basedate.getTimezoneOffset()) * 60000;
        const dnthreshToBe = getTimezoneOffsetMS(new Date()) - getTimezoneOffsetMS(basedate);
        return dnthreshAsIs - dnthreshToBe;
      }());

      const fixPrecisionLoss = (date: Date) => {
        return (new Date(date.getTime() - importBugHotfixDiff));
      }

      data.map((arr) => {
        Object.entries(arr).map(([key, value]) => {
          if (value instanceof Date) {
            arr[key] = fixPrecisionLoss(value);
          }
        })
      });
      //#endregion

      if (data.length) {

        const importTemplate = await getScreenUploadingTemplateFunc(processcode);
        toggleImportModalOpen(true);

        const modelInGroup: ArrayKeyValueObject = [];
        const dimensionSet: IDimension[] = [];

        const groupingColumns: any[] = [];

        importTemplate.map((x) => {
          if (!x.ColumnID.includes("/") || x.ColumnID.startsWith("dimensionset/")) {
            groupingColumns.push(x.ColumnID);
          }
        });

        const dataWithGroup = data.groupBy(...groupingColumns);

        let indexOnGroup = 0;
        Object.entries(dataWithGroup).map(([key, value]) => {
          const model: KeyValueObject = {
            legalentityno: selectedLegalEntity
          };
          indexOnGroup++;

          value.map((x, i) => {

            x["_row_id"] = indexOnGroup;
            x["_isuploaded"] = false;
            x["_uploadingresponse"] = "";
            x["_transactionno"] = "";

            var dimensionObject: KeyValueObject = {};

            Object.keys(x).filter(y => y.includes("dimensionset/")).sort().map(key => {
              const code = key.substring(0, key.lastIndexOf('/'));
              const fieldid = key.substring(key.lastIndexOf('/') + 1);

              if (x[key]?.trim()) {
                if (!dimensionObject[code])
                  dimensionObject[code] = {};
                dimensionObject[code][fieldid] = x[key].trim();
              }
              delete x[key];
            });

            Object.entries(dimensionObject).map(([key, value]) => {

              var md5_code = md5(JSON.stringify(value).toLowerCase()).toLowerCase();
              if (!dimensionSet.find(x => x.dimensionSet == md5_code)) {
                dimensionSet.push({
                  dimensionSet: md5_code,
                  dimensionValues: value,
                  dimensionRelations: [],
                  isDraftDimension: false
                });
              }

              x[key] = md5_code;
              x[key.replace("dimensionset", "dimensionaccountnovalues")] = Object.keys(value).map(x => value[x]).join("-");

            });

            var keys = Object.keys(x);

            keys.map(headerFielid => {

              // Grid Fields
              if (headerFielid.includes("/")) {
                const gridid = headerFielid.split("/")[0];
                const fieldid = headerFielid.split("/")[1];

                if (!model[gridid])
                  model[gridid] = [];

                if (!model[gridid][i])
                  model[gridid].push({ lineid: i + 1 });

                if (fieldid != "lineid")
                  model[gridid][i][fieldid] = x[headerFielid];
              }
              // Header Normal Fields
              else {
                model[headerFielid] = x[headerFielid];
              }
            });

          });

          modelInGroup.push(model);
        });

        setImportTemplate({
          allUploaded: false,
          header: importTemplate,
          model: data,
          modelInGroup: modelInGroup,
          dimensionSet: dimensionSet
        });

      }
      else {
        throw Error("There is no data in Excel Template");
      }

    }
    catch (error) {
      dispatch(setTabMessage({ tabId: activeObject?.objectId, error }));
    }
  }

  const uploadScreenDataFromTemplateFunc = async () => {
    try {
      dispatch(toggleLoader(true));
      dispatch(loaderText("The task is being executed, Please wait until it is completed."));

      const ScreenConfig = activeObject.ScreenConfig.TcodeConfiguration;
      const processcode = ScreenConfig?.processcode as string;

      const model = importTemplate.modelInGroup.filter(x => importTemplate.model.findIndex(y => y._row_id == x._row_id && y._isuploaded == false) != -1);

      const saveOption: ISaveParams = {
        documentReleased: true,
        requestForApproval: ScreenConfig.hasCreateApproval
      };

      const response: ArrayKeyValueObject = await requestUploadScreenDataFromTemplate(processcode, model, importTemplate.dimensionSet, saveOption);

      const previousModel = importTemplate.model.cloneArray();
      response.map((x: any) => {
        previousModel.map(y => {
          if (y._row_id == x.row_id) {
            if (x.Error)
              y._uploadingresponse = x.Error.Message;
            else {
              y._uploadingresponse = "Uploaded";
              y._transactionno = x.DocKeyValue;
              y._isuploaded = true;
            }
          }
        });
      });
      const allUploaded = previousModel.length == previousModel.filter(x => x._isuploaded).length;

      setImportTemplate(previousState => ({ ...previousState, model: previousModel, allUploaded }));

    }
    catch (error) {
      dispatch(setTabMessage({ tabId: activeObject?.objectId, error }));
    }
    finally {
      dispatch(toggleLoader(false));
    }
  }

  const downloadUploadingRemain = () => {

    const importLabel = activeObject.ScreenConfig.TcodeConfiguration.importlabelstxt as string;

    const data = importTemplate.model.filter(y => y._isuploaded == false).cloneArray().map(x => {

      delete x._uploadingresponse;
      delete x._isuploaded;
      delete x._row_id;
      delete x._transactionno;

      return x;
    });

    var workbook = XLSX.utils.book_new();
    var worksheet = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Export')
    XLSX.writeFile(workbook, `${importLabel} Template.xlsx`);

  }

  // favorite
  const BaseSetUserFavorite = (objectcontrollerno: string, objectcontrollerstxt: string, applicationobjecttype: string) => {
    dispatch(setUserFavorite({ objectcontrollerno, objectcontrollerstxt, applicationobjecttype }))
  }

  if (isHelpOpen && helpConfig && helpConfig.helpOpenFor.isEqual(EHelpOpenFor.Document)) {
    const processConfig = activeObject.ScreenConfig.TcodeConfiguration;
    const hasApproval = processConfig.hasChangeApproval || processConfig.hasCreateApproval || processConfig.hasDeleteApproval || processConfig.hasReverseApproval;

    return (
      <>

        <input ref={importFileRef} onChange={(e) => readDataFromExcelAndPreview(e)} type="file" style={{ display: "none" }} />

        <Modal
          heading="Column Visibility"
          show={columnVisibilityModal}
          close={() => { setModalColumnVisibility(false); closeColumnSetting(); }}
          modalSize="sm"
          bodyStyle={{ maxHeight: "500px", overflow: "auto" }}
          footer={
            <>
              <Button variant="primary" onClick={saveColumnSetting}>
                Save
              </Button>
            </>
          }
        >
          <>
            {
              objects.map((item: any) =>
                <Dropdown.ItemText key={item.columnId}>
                  <Form.Check type="checkbox" onClick={(ev: any) => { updateObject(item.columnId) }} label={item.header} checked={item.selected} readOnly />
                </Dropdown.ItemText>
              )
            }
          </>
        </Modal>

        <Modal
          heading="Add Applied View +"
          show={filterModalOpen}
          close={handleFilterModalClose}
          modalSize="sm"
          footer={
            <>
              <Button variant="secondary" onClick={handleFilterModalClose}>
                <IoMdClose className="me-2" size="16" />
                Close
              </Button>
              <Button variant="primary" onClick={handleFilterSaveModal}>
                <IoMdSave className="me-2" size="16" />
                Save
              </Button>
            </>
          }
        >
          <Form>
            <Form.Group className="mb-3">
              <Form.Control id="filterName" value={filterName} onChange={onChangeHandler} type="text" placeholder="View Name" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check id="isDefault" checked={isDefault} onChange={onChangeHandler} type="checkbox" label="Default" />
            </Form.Group>
          </Form>
        </Modal>

        <Modal
          heading={`Import ${activeObject.ScreenConfig.TcodeConfiguration.importlabelstxt}`}
          show={importModalOpen}
          close={handleImportModalClose}
          modalSize="xl"
          footer={
            <>
              <Button variant="light" onClick={handleImportModalClose}>
                <IoMdClose className="me-2" size="16" />
                Close
              </Button>
              <Button disabled={!importTemplate.model.length || importTemplate.allUploaded} variant="secondary" onClick={uploadScreenDataFromTemplateFunc}>
                <IoMdSave className="me-2" size="16" />
                Upload
              </Button>
            </>
          }
        >
          <>
            <div className="d-flex">
              <Button variant="secondary" onClick={() => importFileRef.current?.click()}>
                <FiUpload className="me-2" size="16" />
                Re-Import File
              </Button>
              {
                importTemplate.model.filter(x => x._isuploaded == true).length && !importTemplate.allUploaded ?
                  <Button className="ms-2" variant="secondary" onClick={downloadUploadingRemain}>
                    <FiDownload className="me-2" size="16" />
                    Download Remain
                  </Button>
                  : null
              }

            </div>

            {
              importTemplate.header.length ?
                <TableHelpUI
                  id={activeTabObject?.tabKey + "_uploader" as string}
                  helpType={EHelpType.Normal}
                  // initializeGrid={initializeGrid}
                  columns={importTemplate.header}
                  data={importTemplate.model}
                  // rowSelect={singleRowSelect}
                  multiSelect={false}
                  paging={false}
                // modalStyle={{ height: "calc(100% - 51px)" }}
                // helpType={helpConfig.helpType}
                // extraParam={{ hasApproval }}
                // ref={gridRef}
                />
                : null
            }
          </>
        </Modal>

        <div id="document-help-container">
          <div className="document-help-grid">
            <div ref={rowMenu} className={classNames(["grid-row-menu", { "show": showMenu }])}>
              <div className="grid-row-controls">
                {
                  !activeObject.ScreenConfig.TcodeConfiguration.savemode.isEqual("L") && activeObject.ScreenConfig.TcodeConfiguration.iscreate && activeObject.ScreenConfig.TcodeConfiguration.clonerequired ? (
                    // <OverlayTrigger placement="bottom" overlay={<Tooltip>Copy Document</Tooltip>}>
                    <FiCopy color="#007db8" size="16" onClick={copyDocument} />
                    // </OverlayTrigger>
                  ) : null
                }

                {
                  activeObject.ScreenConfig.ProcessReports.length >= 1 ? (
                    // <OverlayTrigger placement="bottom" overlay={<Tooltip>Print {defaultReport?.reportstxt}</Tooltip>}>
                    <HiOutlinePrinter color="#007db8" size="16" onClick={() => printReport(defaultReport?.reportno as string)} />
                    // </OverlayTrigger>
                  ) : null
                }

                {
                  deleteBtn ?
                    // <OverlayTrigger placement="bottom" overlay={<Tooltip>Delete Document</Tooltip>}>
                    <IoMdTrash color="#007db8" size="16" onClick={deleteRecord} />
                    // </OverlayTrigger>
                    : null
                }

                {/* <OverlayTrigger placement="bottom" overlay={<Tooltip>Open in New Tab</Tooltip>}> */}
                <Icon icon="ExternalLink" color="#007db8" size="16" onClick={openInNewTab} />
                {/* </OverlayTrigger> */}
              </div>
            </div>
            <div className="m-3 mb-0 d-flex">
              <Button variant="danger" onClick={baseCloseTab}>
                <IoMdClose className="me-2" size="16" />
                <ControlTranslation system={true} controlId="btnclose" />
              </Button>
              {
                activeObject && processConfig.iscreate ?
                  <Button variant="primary" className="ms-2" onClick={newDocument}>
                    <IoMdAdd className="me-2" size="16" />
                    <ControlTranslation system={true} controlId="btnnew" />
                  </Button>
                  : null
              }
              
              {
                hasApproval ?
                  <Button variant="secondary" className={classNames(["ms-2", { "active": helpConfig.gridView.filterApproval }])} onClick={() => filterByApprover(!helpConfig.gridView.filterApproval)}>
                    <FaRegCheckCircle className="me-2" size="16" />
                    <ControlTranslation system={true} controlId="btnmyapproval" />
                  </Button>
                  : null
              }

              <OverlayTrigger placement="bottom" overlay={<Tooltip><ControlTranslation system={true} controlId="btnrefresh" /></Tooltip>}>
                <Button variant="secondary" className="ms-2" onClick={() => reloadData(helpConfig.gridView.filterApproval)}>
                  <IoMdRefresh size="16" />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger placement="bottom" overlay={<Tooltip><ControlTranslation system={true} controlId="lbladdtofavorite" /></Tooltip>}>
                <Button variant="secondary" className="ms-2" onClick={() => BaseSetUserFavorite(activeObject.ScreenConfig.TcodeConfiguration.processcode, activeObject.ScreenConfig.TcodeConfiguration.processcodestxt, activeObject.ScreenConfig.TcodeConfiguration.applicationobjecttype)}>
                  {
                    activeObject.ScreenConfig.TcodeConfiguration.isfavorite ? (
                      <AiFillStar size="16" />
                    ) : (
                      <AiOutlineStar size="16" />
                    )
                  }
                </Button>
              </OverlayTrigger>

              <OverlayTrigger placement="top" overlay={<Tooltip><ControlTranslation system={true} controlId="btnapplyfilter" /></Tooltip>}>
                <DropdownButton variant="secondary" className={classNames(["ms-2 lg-menu", { "active": helpConfig.gridView.filterPrid != 0 }])} title={<FiFilter size="16" className="me-1" />}>
                  {
                    activeObject.otherState.userDocumentHelpFilter.map((x, i) => (
                      <Dropdown.Item className={classNames([{ active: x.prid == helpConfig.gridView.filterPrid }])} key={x.prid} role="button">
                        <div className="d-flex">
                          <Form.Check type="checkbox" data-rh={x.isdefault ? "Remove from Default" : "Mark as Default"} checked={x.isdefault} onChange={() => setViewDefault(x.prid, !x.isdefault)} />
                          <div className="ms-3 w-100" onClick={() => applyView(x)}>
                            {x.filterstxt}
                            {
                              x.isdefault ?
                                <>
                                  <br />
                                  <small><em className="font-weight-light">Default</em></small>
                                </>
                                : null
                            }
                          </div>
                          <span onClick={() => removeView(x.prid)}>
                            <IoMdTrash size="16" />
                          </span>
                        </div>
                      </Dropdown.Item>
                    ))
                  }
                  {
                    activeObject.otherState.userDocumentHelpFilter.length >= 1 ?
                      <Dropdown.Divider />
                      : null
                  }
                  <Dropdown.Item role="button" className="text-center" onClick={handleFilterModalOpen}>Add Applied View +</Dropdown.Item>
                </DropdownButton>
              </OverlayTrigger>

              {
                activeObject.ScreenConfig.TcodeConfiguration.allowimport ?
                  <>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip>{activeObject.ScreenConfig.TcodeConfiguration.importlabelstxt} <ControlTranslation system={true} controlId="lblforupload" /></Tooltip>}>
                      <Button variant="secondary" className="ms-2" onClick={() => downloadScreenUploadingTemplate()}>
                        <FiDownload size="16" />
                      </Button>
                    </OverlayTrigger>

                    <OverlayTrigger placement="bottom" overlay={<Tooltip><ControlTranslation system={true} controlId="lblupload" /></Tooltip>}>
                      <Button variant="secondary" className="ms-2" onClick={() => importFileRef.current?.click()}>
                        <FiUpload size="16" />
                      </Button>
                    </OverlayTrigger>
                  </>
                  : null
              }

              {
                helpConfig.helpConfig ? (
                  <>
                    <Workspace objectType="E" />
                  </>
                )
                  : null
              }

              {
                activeObject.ScreenConfig.TcodeConfiguration.DocumentationUrls.length == 1 ? (
                  <OverlayTrigger placement="top" overlay={<Tooltip>Screen Documentation</Tooltip>}>
                    <Button
                      className="ms-2"
                      variant="secondary"
                      onClick={() => openDocumentation(activeObject.ScreenConfig.TcodeConfiguration.DocumentationUrls[0].documenturl)}
                    >
                      <GrInfo size="16" />
                    </Button>
                  </OverlayTrigger>
                ) : (activeObject.ScreenConfig.TcodeConfiguration.DocumentationUrls.length > 1 ? (
                  <OverlayTrigger placement="top" overlay={<Tooltip>Screen Documentation</Tooltip>}>
                    <DropdownButton
                      variant="secondary"
                      as={ButtonGroup}
                      className="menu-w-100 ms-2 "
                      title={<GrInfo size="16" />}
                    >
                      {
                        activeObject.ScreenConfig.TcodeConfiguration.DocumentationUrls.map((x, i) => {
                          return (
                            <Dropdown.Item key={x.documentstxt} onClick={() => openDocumentation(x.documenturl)}>
                              {x.documentstxt}
                            </Dropdown.Item>
                          )
                        })
                      }
                    </DropdownButton>
                  </OverlayTrigger>
                ) : null)
              }

            </div>

            <div className="grid-btn-group">
              <Dropdown id="grid-menu" className="caret-none" autoClose={false}>
                <OverlayTrigger placement="top" overlay={<Tooltip>Grid Menu</Tooltip>}>
                  <Dropdown.Toggle id="grid-menu-button" variant="white" className="p-0">
                    <BsThreeDotsVertical size="16" />
                  </Dropdown.Toggle>
                </OverlayTrigger>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => { setModalColumnVisibility(true) }}>Column Visibility</Dropdown.Item>
                  <Dropdown.Item onClick={clearView}>Clear View</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>


              {/* <MenuComponent
                ref={gridMenuOptionRef}
                contextMenuOf={undefined}
                dropDownCssClass="gridMenu"
                openOnHover={true}
                displayMemberPath="header"
                subItemsPath="items"
                itemsSource={defaultMenuSource.current}
                wjItemTemplate={(context: any) => (
                  context.item.selected == undefined ?
                    <>
                      {context.item.header}
                    </>
                    :
                    <Form.Check type="checkbox" label={context.item.header} checked={context.item.selected} readOnly />
                )}
              /> */}

            </div>

            {
              showGrid ?
                <TableHelpUI
                  id={activeTabObject?.tabKey as string}
                  initializeGrid={initializeGrid}
                  columns={helpConfig.helpConfig.Header}
                  data={helpConfig.helpConfig.Detail}
                  // rowSelect={singleRowSelect}
                  multiSelect={false}
                  modalStyle={{ height: "calc(100% - 51px)" }}
                  helpType={helpConfig.helpType}
                  extraParam={{ hasApproval }}
                  ref={gridRef}
                />
                : null
            }

          </div>
        </div >
      </>
    );
  }
  else return null;
};

export default DocumentHelpUI;
