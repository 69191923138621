import { useAppSelector } from "@/Hook/hooks";

interface IProps {
  controlId: string
	system?: boolean

}

const ControlTranslation = (props: IProps) => {

  const HeaderInformation = "headerinformation";

  const activeObjectId = useAppSelector((state) => state.EntryForm.activeObjectId);
  const controlTranslation = useAppSelector((state) => {

    if (props.system) {
      return state.User.SystemLanguages.find(x => x.objectno == props.controlId)?.objectlabel;
    }
    else {
      if (props.controlId == HeaderInformation) {
        const activeTab = state.Tab.tabs.find(x => x.tabKey == state.Tab.activeTabKey);
  
        let TabName = `${activeTab?.tabName}`;
  
        if (activeTab?.prefix)
          TabName = `${activeTab.prefix} ${TabName}`;
  
        return `${TabName} ${activeTab?.transactionno ? `- ${activeTab.transactionno}` : ""}`;
      }
      else {
        return state.EntryForm.objects[activeObjectId].ScreenConfig.ControlTranslation.find((x) => x.controlId == props.controlId)?.translation
      }
    }

  });
  const translation = controlTranslation || "Not Defined";

  return <>{translation}</>;
};

export default ControlTranslation;
