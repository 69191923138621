import { ControlTranslation, Icon } from "AlignComponents";
import classNames from "classnames";
import { Button, Col, Form, Offcanvas, Row, Spinner } from "react-bootstrap";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BiData } from "react-icons/bi";
import { BsBook, BsFileEarmarkCheck } from "react-icons/bs";
import { FaRegFilePowerpoint } from "react-icons/fa";
import { GiExpense, GiPayMoney } from "react-icons/gi";
import { HiPencilAlt } from "react-icons/hi";
import { MdChevronRight } from "react-icons/md";
import { RiAdminLine, RiOrganizationChart } from "react-icons/ri";
import { VscCollapseAll, VscExpandAll } from "react-icons/vsc";
import ModuleIcon from "./ModuleIcon";

interface IProps {
  routeToScreen: (screenCode: string, applicationobjecttype: string) => void,
  toggleModule: (moduleno: string) => Promise<void>,
  dataLoading: boolean,
  menuOpen: boolean,
  closeMenu: () => void,
  activeModule: string,
  setActiveModule: (value: React.SetStateAction<string>) => void,
  filterModules: any,
  setModuleSearch: (value: string) => void,
  setObjectSearch: (value: string) => void,
  objectSearch: string,
  objectDetails: any,
  reportObjects: any,
  toggleSubMenu: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void
  toggleAllSubMenu: (expand: boolean) => void,
  BaseSetUserFavorite: (objectcontrollerno: string, objectcontrollerstxt: string, applicationobjecttype: string) => void
}

export const AlignUIRender = ({ BaseSetUserFavorite, dataLoading, routeToScreen, toggleModule, menuOpen, closeMenu, activeModule, setActiveModule, filterModules, setModuleSearch, setObjectSearch, objectSearch, objectDetails, reportObjects, toggleSubMenu, toggleAllSubMenu }: IProps) => (
  <>
    <Offcanvas show={menuOpen} onHide={closeMenu} backdropClassName="backdrop" className="menu-module">
      <Offcanvas.Header className="border-bottom">
        <Offcanvas.Title>
          <Form.Control id="module-search" type="text" placeholder="Search" onChange={(e: any) => setModuleSearch(e.target.value)} />
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ul className="list-unstyled">
          {
            filterModules.map((x: any, idx: number) => (
              <li
                role="button" tabIndex={idx} className={classNames(["module", { active: activeModule == x.moduleno }])} onClick={() => { toggleModule(x.moduleno) }}>
                <ModuleIcon moduleno={x.moduleno} />
                {x.modulestxt}
              </li>
            ))
          }
        </ul>
        {
          dataLoading ?
            <div className="text-center">
              <Spinner animation="border" />
            </div>
            : null
        }
      </Offcanvas.Body>
    </Offcanvas>
    <Offcanvas
      show={!!activeModule}
      onHide={() => { setActiveModule("") }}
      backdropClassName="backdrop"
      className="menu-object">
      <div className="top-bar">
        <div role="button" onClick={() => toggleAllSubMenu(true)}>
          <VscExpandAll />
          <span><ControlTranslation system={true} controlId="lblexpandall" /></span>
        </div>
        <div role="button" onClick={() => toggleAllSubMenu(false)}>
          <VscCollapseAll />
          <span><ControlTranslation system={true} controlId="lblcollapseall" /></span>
        </div>
        <div className="ms-auto">
          <Form.Control id="object-search" type="text" placeholder="Search" onChange={(e: any) => setObjectSearch(e.target.value)} />
        </div>
      </div>
      <Offcanvas.Header className="pb-0">
        <Offcanvas.Title className="w-100">
          <Row>
            {
              objectDetails.map(([key, value]: any) => (
                <Col md={4}>
                  <h5>{key}</h5>
                </Col>
              ))
            }
            {
              reportObjects.length >= 1 ? (
                <Col md={4}>
                  <h5><ControlTranslation system={true} controlId="lblreports&analytics" /></h5>
                </Col>
              ) : null
            }
          </Row>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Row>
          {
            objectDetails.map(([keyx, valuex]: any) => (
              <Col md={4}>
                <ul id="entryform-menu" className="list-unstyled m-0">
                  {
                    // not rendering included reports
                    valuex.map(([keyy, valuey]: any) => (
                      !objectSearch || objectSearch ?
                        <>
                          <li role="button" onClick={toggleSubMenu} className="menu-group-heading fw-bold">
                            <MdChevronRight className="me-2" color="#575757" size={16} />
                            {keyy}
                          </li>
                          <ul className={classNames(["objects my-2 ms-3", { show: !!objectSearch }])}>
                            {
                              valuey.map((x: any) => (
                                <div className="d-flex">
                                  <li role="button" onClick={() => { routeToScreen(x.objectcontrollerno, x.applicationobjecttype) }} className="object w-100">
                                    <HiPencilAlt className="me-2" />
                                    {x.objectcontrollerstxt}
                                  </li>
                                  <button className="object ms-auto border-0" onClick={() => { BaseSetUserFavorite(x.objectcontrollerno, x.objectcontrollerstxt, x.applicationobjecttype) }}>
                                    {
                                      x.isfavorite ? (
                                        <AiFillStar />
                                      ) : (
                                        <AiOutlineStar />
                                      )
                                    }
                                  </button>
                                </div>
                              ))
                            }
                          </ul>
                        </>
                        : null
                    ))
                  }
                </ul>
              </Col>
            ))
          }
          {
            reportObjects.length >= 1 ? (
              <Col md={4}>
                <ul id="entryform-menu" className="list-unstyled">
                  {
                    reportObjects.map(([keyx, valuex]: any) => (
                      <>
                        <li role="button" onClick={toggleSubMenu} className="menu-group-heading fw-bold">
                          <MdChevronRight className="me-2" color="#575757" size={16} />
                          {keyx}
                        </li>
                        <ul className={classNames(["objects my-2 ms-3", { show: !!objectSearch }])}>
                          {
                            valuex.map(([keyy, valuey]: any) => (
                              <>
                                <li role="button" onClick={toggleSubMenu} className="menu-group-heading fw-bold">
                                  <MdChevronRight className="me-2" color="#575757" size={16} />
                                  {keyy}
                                </li>
                                <ul className={classNames(["objects mt-2 ms-3", { show: !!objectSearch }])}>
                                  {
                                    valuey.map((x: any) => (
                                      <div className="d-flex">
                                        <li role="button" onClick={() => { routeToScreen(x.objectcontrollerno, x.applicationobjecttype) }} className="object w-100">
                                          {
                                            x.applicationobjecttype == "I" ?
                                              (
                                                <BiData className="me-2" />
                                              ) : (
                                                <BsFileEarmarkCheck className="me-2" />
                                              )
                                          }
                                          {x.objectcontrollerstxt}
                                        </li>
                                        <button className="object ms-auto border-0" onClick={() => { BaseSetUserFavorite(x.objectcontrollerno, x.objectcontrollerstxt, x.applicationobjecttype) }}>
                                          {
                                            x.isfavorite ? (
                                              <AiFillStar />
                                            ) : (
                                              <AiOutlineStar />
                                            )
                                          }
                                        </button>
                                      </div>
                                    ))
                                  }
                                </ul>
                              </>
                            ))
                          }
                        </ul>
                      </>
                    ))
                  }
                </ul>
              </Col>
            ) : null
          }
        </Row>
      </Offcanvas.Body>
    </Offcanvas>
  </>
);
