import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import { toggleSummary } from "@/Redux/Ducks/entryFormSlice";
import classNames from "classnames";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import ReactDOM from 'react-dom';
import Modal from '../Modal';

const SummaryPopup = (props: {
  children: any,
  modalSize?: 'sm' | 'lg' | 'xl' | 'md'
}) => {
  const activeObjectId = useAppSelector(state => state.EntryForm.activeObjectId)
  const isSummaryPopupOpen = useAppSelector(state => state.EntryForm.objects[activeObjectId].otherState.isSummaryPopupOpen);
  const dispatch = useAppDispatch();

  const summaryPopupCloseHandler = () => {
    dispatch(toggleSummary(false))
  }


  return (
    <Modal
      close={summaryPopupCloseHandler}
      heading="Summary"
      show={isSummaryPopupOpen}
      modalSize={props.modalSize || "xl"}
      footer={
        <Button variant="secondary" onClick={summaryPopupCloseHandler}>
          <IoMdClose className="me-2" size="16" />
          Close
        </Button>
      }
    >
      {props.children}
    </Modal>
  )

  // if (isSummaryPopupOpen) {
  //   return ReactDOM.createPortal(
  //     <>
  //       <div className="fade modal-backdrop show"></div>
  //       <div className="fade modal show" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ paddingRight: 8, display: "block" }} onClick={summaryPopupCloseHandler}>
  //         <div className="modal-dialog" onClick={(e) => { e.stopPropagation() }}>
  //           <div className="modal-content">
  //             <div className="modal-header">
  //               {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
  //               <h6 className="fw-bold m-0">Summary</h6>
  //               <button type="button" className="btn-close" aria-label="Close" onClick={summaryPopupCloseHandler}></button>
  //             </div>
  //             <div className="modal-body">
  //               {props.children}
  //             </div>
  //             <div className="modal-footer">
  //               <Button variant="light" onClick={summaryPopupCloseHandler}>
  //                 <IoMdClose className="me-2" size="16" />
  //                 Close
  //               </Button>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </>
  //     , document.body);
  // }

  // return null;

  // return (
  //   <Modal show={isSummaryPopupOpen} onHide={summaryPopupCloseHandler} centered size="xl">
  //     <Modal.Header closeButton>
  //       <h6 className="fw-bold m-0">Summary</h6>
  //     </Modal.Header>
  //     <Modal.Body style={{ minHeight: "450px" }}>
  //       {props.children}
  //     </Modal.Body>
  //     <Modal.Footer>
  //       <Button variant="light" onClick={summaryPopupCloseHandler}>
  //         <IoMdClose className="me-2" size="16" />
  //         Close
  //       </Button>
  //     </Modal.Footer>
  //   </Modal>
  // );
};

export default SummaryPopup;
