import { call, delay, put, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { EObjectType, ITab, setActiveTab } from "@/Redux/Ducks/tabSlice";
import { closeEntryFormObject, setActiveObjectId } from "@/Redux/Ducks/entryFormSlice";
import { push } from "connected-react-router";
import { RootState } from "@/Redux/configureStore";
import { setGlobalMessage } from "@/Redux/Ducks/messageSlice";
import { closeReportExplorerObject, setActiveReportObjectId } from "@/Redux/Ducks/reportExplorerSlice";
import { closeDataAnalyticsObject, setActiveDataAnalyticsObjectId } from "@/Redux/Ducks/dataAnalyticsSlice";

export function* handleCloseTab({ payload }: PayloadAction<string>): any {

  const Tabs: ITab["tabs"] = yield select((state: RootState) => state.Tab.tabs);
  const activeTabId = yield select((state: RootState) => state.Tab.activeTabKey);

  try {

    if (Tabs.length) {
      if (activeTabId == payload) {
        const lastActiveTabId = Tabs[0].tabKey;
        yield put(setActiveTab(lastActiveTabId));


        if (Tabs.find(x => x.tabKey == lastActiveTabId)?.objectType == EObjectType.EntryForm)
          yield put(setActiveObjectId(lastActiveTabId));
        // else
        //   yield put(setActiveObjectId(""));

        if (Tabs.find(x => x.tabKey == lastActiveTabId)?.objectType == EObjectType.DataAnalytics)
          yield put(setActiveDataAnalyticsObjectId(lastActiveTabId));
        // else
        //   yield put(setActiveDataAnalyticsObjectId(""));

        if (Tabs.find(x => x.tabKey == lastActiveTabId)?.objectType == EObjectType.ReportExplorer)
          yield put(setActiveReportObjectId(lastActiveTabId));
        // else
        //   yield put(setActiveReportObjectId(""));

        yield put(push(Tabs[0].url));

      }
    }
    else
      yield put(push("/"));

    yield delay(100);

    yield put(closeEntryFormObject(payload));
    yield put(closeReportExplorerObject(payload));
    yield put(closeDataAnalyticsObject(payload));

  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }

}
