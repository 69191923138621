import { ForgotPassword } from "./";
export const AlignUIRender = ({
  forgotPassword,
  updateFieldState,
  state,
  handleKeyDown,
  props,
  navigateToLogin
}: ForgotPassword) => (
  <>
    <div className="page-links">
      <a className="active" role="button">Forgot Password</a>
    </div>
    <form>
      <input
        type="text"
        placeholder="Email"
        className="form-control"
        value={state.email}
        onChange={(e) => updateFieldState("email", e.target.value)}
        autoComplete="off"
      />
      <input
        type="text"
        placeholder="Company"
        className="form-control"
        value={state.clientno}
        onChange={(e) => updateFieldState("clientno", e.target.value)}
        onKeyDown={handleKeyDown}
        autoComplete="off"
      />
      <div className="form-button">
        <button className="ibtn" type="button" onClick={() => forgotPassword()}>Send Reset Link</button>
        <button className="ibtn" onClick={navigateToLogin}>Back</button>
      </div>
    </form>
  </>
)
