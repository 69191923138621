import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "@/Hook/hooks";

import { EActivity, EFieldErrorType } from "@/UI/Interfaces/IBaseUI";
import { AlignTab } from "AlignComponents";

import { toggleActivityModal } from "@/Redux/Ducks/entryFormSlice"
import classNames from "classnames";
import ReactTimeago from "react-timeago";
import { Modal, Table } from "react-bootstrap";

interface IProps {
    showFieldModal: boolean
    closeFieldModal: () => void
}

const AllFieldError = (props: IProps) => {
    const dispatch = useAppDispatch();
    var errorFields = useAppSelector(x => Object.values(x.EntryForm.objects[x.EntryForm.activeObjectId].fieldOptions).filter(x => x.errorType != EFieldErrorType.None).map(a => {
        var fieldLabel = x.EntryForm.objects[x.EntryForm.activeObjectId].ScreenConfig.Fields.FieldConfig.filter(y => y.fieldid == a.fieldId)[0].fieldlabel;
        return { ...a, fieldLabel }
    }));

    return (
        <>
            <Modal show={props.showFieldModal} onHide={props.closeFieldModal} centered>
                <Modal.Header closeButton>
                    <h6 className="fw-bold m-0">Field Errors Detail</h6>
                </Modal.Header>
                <Modal.Body style={{minHeight: '350px'}} className="p-0 pb-3">
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Field</th>
                                <th>Error</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                errorFields.filter(x => x.errorType != EFieldErrorType.None).map((x: any, i: number) => (
                                    <tr key={i}>
                                        <td>{x.fieldLabel}</td>
                                        <td>{x.errorType == EFieldErrorType.EmptyFieldError ? 'Mandatory Field' : 'Help Data not Exists'}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AllFieldError;
