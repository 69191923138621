
import { RootState } from '@/Redux/configureStore';
import { IReturnValues } from '@/Redux/Ducks/helpSlice';
import AlignHelp from '@/UI/BaseComponents/Help';
import { EActivity, EFieldErrorType, KeyValueObject } from '@/UI/Interfaces/IBaseUI';
import { IFieldConfig, IFieldObject } from '@/UI/Interfaces/IFieldConfig';
import BaseMethods from 'AlignBaseUI/BaseMethods';
import { FieldDimension } from 'AlignComponents';
import classNames from 'classnames';
import { Component } from 'react';
import { connect } from 'react-redux';
import AlignCheckField from './AlignCheckField';
import AlignColorPickerField from './AlignColorPickerField';
import AlignDateField from './AlignDateField';
import AlignImageField from './AlignImageField';
import AlignInputField from './AlignInputField';
import AlignNumberField from './AlignInputNumberField';
import AlignRichTextField from './AlignRichTextField';
import AlignTextAreaField from './AlignTextAreaField';
import AlignTimeField from './AlignTimeField';

class AlignInput extends Component<FieldFormProps, IState>  {
  FieldComponent;
  constructor(props: FieldFormProps) {
    super(props);
    const { fieldId, config } = props;

    this.state = {
      config: config.FieldConfig.filter(x => x.fieldid == fieldId)[0],
    }

    if (this.state.config) {
      const type = BaseMethods.getControlType(this.state.config.controltypeno);

      if (type.isEqual("text")) {
        if (this.state.config.objectlength >= 500)
          this.FieldComponent = AlignTextAreaField;
        else
          this.FieldComponent = AlignInputField;
      }
      else if (type.isEqual("richtextbox")) {
        this.FieldComponent = AlignRichTextField;
      }
      else if (type.isEqual("date"))
        this.FieldComponent = AlignDateField;
      else if (type.isEqual("checkbox"))
        this.FieldComponent = AlignCheckField;
      else if (type.isEqual("time"))
        this.FieldComponent = AlignTimeField;
      else if (type.isEqual("number"))
        this.FieldComponent = AlignNumberField;
      else if (type.isEqual("image"))
        this.FieldComponent = AlignImageField;
      else if (type.isEqual("colorpicker"))
        this.FieldComponent = AlignColorPickerField;
    }

    this.fieldValueChange = this.fieldValueChange.bind(this);
    this.fieldBeforeHelpReturn = this.fieldBeforeHelpReturn.bind(this);
    this.fieldFocusOut = this.fieldFocusOut.bind(this);
  }

  fieldFocusOut(fieldId: string, value: any) {
  }

  // Don't access any other props because on multi help return this method will trigger for other field state update
  async fieldValueChange(fieldId: string, value: any, helpSelected: boolean = false) {
    await (this.props.config.BaseFieldStateSet && this.props.config.BaseFieldStateSet(fieldId, value, helpSelected));
  }

  async fieldBeforeHelpReturn(fieldid: string, helpobject: string, helpReturnValues: IReturnValues[]) {
    await (this.props.config.BaseFieldBeforeHelpReturn && this.props.config.BaseFieldBeforeHelpReturn(fieldid, helpobject, helpReturnValues));
  }

  render() {
    const { config } = this.state;
    const { FieldComponent, props } = this;

    const MainComponentStyle: any = {};

    if (!this.props.visible)
      MainComponentStyle.display = "none";


    if (FieldComponent)
      return (
        <div className={classNames(["field-container", { "field-label": !props.hideLabel, "field-error": props.errorType.isEqual(EFieldErrorType.EmptyFieldError, EFieldErrorType.HelpDataNotFound) }])} style={MainComponentStyle} data-fieldid={props.fieldId}>

          <FieldComponent hideLabel={props.hideLabel} uniqueKey={props.uniqueKey} customHelpParams={props.customHelpParams} hyperLinkJump={props.hyperLinkJump} validateMandatory={props.validateMandatory} enable={props.enable} value={props.value} config={config} fieldValueChange={this.fieldValueChange} fieldFocusOut={this.fieldFocusOut} />

          {
            props.helpObject && !config.isdropdownhelpobject ?
              <AlignHelp validatingHelp={props.validatingHelp} customHelpParams={props.customHelpParams} custom={props.custom} uniqueKey={props.uniqueKey} helpWhere={props.helpWhere} fieldId={props.fieldId} enable={props.enable} helpObject={props.helpObject} fieldValueChange={this.fieldValueChange} fieldBeforeHelpReturn={this.fieldBeforeHelpReturn} />
              : <div style={{ width: "37.21px", visibility: "hidden" }}></div>
          }

          {
            props.dimensionrequired ?
              <FieldDimension enable={props.dimensionEnable} fieldid={props.fieldId} value={props.value as string} dimensionvalue={props.dimensionvalue} />
              : null
          }

        </div>
      )
    else
      return null;
  }
}


const mapStateToProps = (state: any, ownProps: any): IPropsFromState => {
  const parseState = state as RootState;
  const parseOwnProps = ownProps as IOwnProps;
  const activeObject = parseState?.EntryForm?.objects?.[parseState.EntryForm.activeObjectId];

  let value;
  let dimensionvalue = "";
  let enable = false;
  let visible = false;
  let helpObject = "";
  let helpWhere = "";
  let validateMandatory = false;
  let errorType = EFieldErrorType.None;
  let validatingHelp = false;
  let hyperLinkJump = "";
  let dimensionEnable = false;
  let dimensionrequired = false;


  if (parseOwnProps.customFieldValue !== undefined)
    value = parseOwnProps.customFieldValue;
  else
    value = activeObject.model[parseOwnProps.fieldId];

  if (!parseOwnProps.custom) {
    dimensionrequired = activeObject.fieldOptions[parseOwnProps.fieldId]?.dimensionrequired || false;
    dimensionvalue = activeObject.model[`dimension${parseOwnProps.fieldId}values`] || "";
  }

  if (parseOwnProps.customFieldEnable !== undefined)
    dimensionEnable = parseOwnProps.customFieldEnable || false;
  else {
    if (activeObject.activity == EActivity.Edit) {
      dimensionEnable = true
    }
    else {
      dimensionEnable = activeObject.fieldOptions[parseOwnProps.fieldId]?.enable || false;
    }
  }

  if (parseOwnProps.customFieldEnable !== undefined)
    enable = parseOwnProps.customFieldEnable || false;
  else
    enable = activeObject.fieldOptions[parseOwnProps.fieldId]?.enable || false;

  if (parseOwnProps.customFieldVisible !== undefined)
    visible = parseOwnProps.customFieldVisible || false;
  else
    visible = activeObject.fieldOptions[parseOwnProps.fieldId]?.visible || false;

  if (parseOwnProps.customFieldHelpObject !== undefined)
    helpObject = parseOwnProps.customFieldHelpObject || "";
  else
    helpObject = activeObject.fieldOptions[parseOwnProps.fieldId]?.helpObject || "";

  if (parseOwnProps.customFieldHelpWhere !== undefined)
    helpWhere = parseOwnProps.customFieldHelpWhere || "";
  else
    helpWhere = activeObject.fieldOptions[parseOwnProps.fieldId]?.helpWhere || "";

  if (parseOwnProps.customValidateMandatory !== undefined)
    validateMandatory = parseOwnProps.customValidateMandatory || false;
  else
    validateMandatory = activeObject.fieldOptions[parseOwnProps.fieldId]?.validateMandatory || false;

  if (parseOwnProps.customHyperLinkJump !== undefined)
    hyperLinkJump = parseOwnProps.customHyperLinkJump || "";
  else
    hyperLinkJump = activeObject.fieldOptions[parseOwnProps.fieldId]?.hyperLinkJump || "";

  if (parseOwnProps.custom)
    errorType = parseOwnProps.customErrorType || EFieldErrorType.None;
  else
    errorType = activeObject.fieldOptions[parseOwnProps.fieldId]?.errorType || EFieldErrorType.None;

  if (!parseOwnProps.custom)
    validatingHelp = activeObject.fieldOptions[parseOwnProps.fieldId]?.validatingHelp;
  else
    validatingHelp = false;


  return {
    enable,
    visible,
    helpObject,
    helpWhere,
    value,
    validateMandatory,
    errorType,
    validatingHelp,
    hyperLinkJump,
    dimensionvalue,
    dimensionEnable,
    dimensionrequired
  }
}

export default connect<IPropsFromState>(mapStateToProps)(AlignInput);


interface IOwnProps {
  config: IFieldObject;
  fieldId: string;
  hideLabel?: boolean;
  uniqueKey?: string;
  customFieldEnable?: boolean;
  customFieldVisible?: boolean;
  customFieldHelpObject?: string;
  customFieldHelpWhere?: string;
  customFieldValue?: any;
  customValidateMandatory?: boolean;
  customHyperLinkJump?: string;
  customErrorType?: EFieldErrorType;
  customHelpParams?: KeyValueObject;
  custom?: boolean;
}


interface IPropsFromState {
  dimensionEnable: boolean;
  dimensionrequired: boolean;
  enable: boolean;
  visible: boolean;
  helpObject: string;
  hyperLinkJump: string;
  helpWhere: string;
  validateMandatory: boolean;
  errorType: EFieldErrorType;
  value: any;
  dimensionvalue: string;
  validatingHelp: boolean;
}

export declare type FieldFormProps = IPropsFromState & IOwnProps;

interface IState {
  config: IFieldConfig;
}
