import { ArrayKeyValueObject } from '@/UI/Interfaces/IBaseUI';
import React, { useRef } from 'react';

function FieldValueInformation(props: { data: ArrayKeyValueObject | null, hideFieldInformationContainer: () => void, clearHideTimeout: () => void }) {
  const data = props.data;

  // not working
  const mouseLeave = (e: any) => {
    props.hideFieldInformationContainer();
  }

  // not working
  const mouseEnter = (e: any) => {
    props.clearHideTimeout();
  }

  if (!data || !data.length)
    return null;

  return (
    <div className="field-value-information-container" onMouseLeave={mouseLeave} onMouseEnter={mouseEnter}>
      {
        data.map((x, i) => (
          <div className="field-container" key={i}>
            <label>{x.label}</label>
            <div className="wj-control wj-content wj-state-disabled">
              <div className="wj-template">
                <div className="wj-input">
                  <div className="wj-input-group wj-input-btn-visible">

                    <input className="wj-form-control" disabled={true} type="text" value={x.value} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  );
}

export default FieldValueInformation;
