import { constant } from "./constants";
import storage from "./storage";


export const getTokens = () => ({
  AccessToken: storage.get(constant.ACCESS_TOKEN),
  RefreshToken: storage.get(constant.REFRESH_TOKEN),
});

export const hasTokens = () => {
  const { AccessToken, RefreshToken } = getTokens();

  return Boolean(AccessToken && RefreshToken);
};

export const setTokens = ({ AccessToken, RefreshToken }: any) => {
  if (AccessToken) storage.set(constant.ACCESS_TOKEN, AccessToken);
  if (RefreshToken) storage.set(constant.REFRESH_TOKEN, RefreshToken);
};

export const setCompanyDetails = ({ timezone, selectedLegalEntity }: any) => {
  storage.set(constant.TIME_ZONE, timezone);
  storage.set(constant.SELECTED_LEGAL_ENTITY, selectedLegalEntity);
};

export const getCompanyDetails = () => ({
  timezone: storage.get(constant.TIME_ZONE),
  selectedLegalEntity: storage.get(constant.SELECTED_LEGAL_ENTITY)
});
