import { KeyValueObject, ObjectConfiguration } from "@/UI/Interfaces/IBaseUI";
import { ICriteriaFieldConfig, ICriteriaFieldValue } from "@/UI/Interfaces/IFieldConfig";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import BaseMethods from "AlignBaseUI/BaseMethods";
import produce from "immer";

interface IReports {
  reportMenu: Array<IReportsObjects>;
  reportDetailObject: KeyValueObject<IReportDetailObject>;
  activeReportObjectno: string;
}
interface IReportDetailObject {
  criteria: Array<ICriteriaFieldConfig>;
  FICriteria: Array<ICriteriaFieldConfig>;
  modelValue: KeyValueObject<ICriteriaFieldValue>;
  objectConfiguration: ObjectConfiguration;
  isCriteriaOpen: boolean;
  requiredLetterHead: boolean;
  report: any;
  previousLoadedCriteriaValue: KeyValueObject<ICriteriaFieldValue>;

}
interface IReportsObjects {
  moduleno: string;
  modulestxt: string;
  objectcontrollerno: string;
  objectcontrollerstxt: string;
  objectcategorystxt: string;
}

const InitialState: IReports = {
  reportMenu: [],
  reportDetailObject: {},
  activeReportObjectno: ""
};

const ReportsSlice = createSlice({
  name: "ReportsExplorer",
  initialState: InitialState,
  reducers: {
    getReportMenu() { },
getReportConfig(state, action: PayloadAction<{ reportObjectno: string; criteria?: KeyValueObject; requiredLetterHead?: boolean, modelValue?: KeyValueObject<ICriteriaFieldValue>, withoutRedirect?: boolean, reportLoaded?: (reportObjectno: string, objectId: string) => void }>) { },    loadReport(state, action: PayloadAction<{ reportObjectno: string, requiredLetterHead: boolean, exportExcel: boolean }>) { },
    setReportMenu(state, action: PayloadAction<{ reportMenu: Array<IReportsObjects> }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        draftState.reportMenu = payload.reportMenu;
        // draftState.dataLoaded = true;
      });
    },
    setActiveReportObjectId(state, action: PayloadAction<string>) {
      return produce(state, (draftState) => {
        draftState.activeReportObjectno = action.payload;
      });
    },
    setReportConfig(state, action: PayloadAction<Pick<IReportDetailObject, 'FICriteria' | 'criteria' | 'objectConfiguration' | 'requiredLetterHead' | 'modelValue'> & { objectId: string }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {

        let modelValue: IReportDetailObject["modelValue"] = {};
        const criteria = payload.criteria.filter(x => payload.FICriteria.find(y => y.fieldid == x.fieldid) == null);
        const FICriteria = payload.FICriteria.map(x => ({ ...x, filteroption: true, multiplevalue: true }));

        [...criteria, ...FICriteria].map(x => {
          let value = null;

          if (BaseMethods.getControlType(x.controltypeno) == "checkbox")
            value = x.defaultvalue == "1" ? true : false;
          else if (BaseMethods.getControlType(x.controltypeno) == "number")
            value = x.defaultvalue ? Number(x.defaultvalue) : 0;
          else if (BaseMethods.getControlType(x.controltypeno) == "date") {
            if (x.validatemandatory) {
              value = new Date();
              value.setHours(0, 0, 0, 0);
              value = `${value.getFullYear()}-${("0" + (value.getMonth() + 1)).slice(-2)}-${("0" + value.getDate()).slice(-2)}T00:00:00`;
            }
          }
          else
            value = x.defaultvalue || "";

          const isDimension = FICriteria.find(y => y.fieldid == x.fieldid) != null;

          modelValue[x.fieldid] = {
            fromValue: value,
            toValue: "",
            isDimension,
            filterType: "=",
            caseSensitive: false
          };

        });

        draftState.reportDetailObject[payload.objectId] = {
          criteria: criteria,
          FICriteria: FICriteria,
          objectConfiguration: payload.objectConfiguration,
          isCriteriaOpen: true,
          modelValue: modelValue,
          requiredLetterHead: payload.requiredLetterHead,
          report: null,
          previousLoadedCriteriaValue: {}
        };
        draftState.activeReportObjectno = payload.objectId

        if (payload.modelValue) {
          draftState.reportDetailObject[payload.objectId].modelValue = payload.modelValue;
        }

      });
    },
    setReportModelValue(state, action: PayloadAction<{ fieldId: string, newValue: string, toValue: boolean }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        if (payload.toValue) {
          draftState.reportDetailObject[state.activeReportObjectno].modelValue[payload.fieldId].toValue = payload.newValue;
        }
        else {
          draftState.reportDetailObject[state.activeReportObjectno].modelValue[payload.fieldId].fromValue = payload.newValue;
        }
      });

    },
    toggleCriteria(state, action: PayloadAction<{ reportObjectno: string }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        const dataAnalyticsObjects = draftState.reportDetailObject[payload.reportObjectno];
        dataAnalyticsObjects.isCriteriaOpen = !dataAnalyticsObjects.isCriteriaOpen;
      });
    },
    setFieldFilter(state, action: PayloadAction<{ fieldId: string, filterType: string, caseSensitive: boolean }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        draftState.reportDetailObject[state.activeReportObjectno].modelValue[payload.fieldId].filterType = payload.filterType;
        draftState.reportDetailObject[state.activeReportObjectno].modelValue[payload.fieldId].caseSensitive = payload.caseSensitive;
      });

    },
    setReport(state, action: PayloadAction<any>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        draftState.reportDetailObject[state.activeReportObjectno].report = payload;
      });

    },
    setReportFavorite(state, action: PayloadAction<{ objectcontrollerno: string }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        // reflect favorite in open screens
        const openScreens = Object.entries(draftState.reportDetailObject).filter(([tabKey, object]) => object.objectConfiguration.processcode == payload.objectcontrollerno)
          .map(([tabKey, object]) => object.objectConfiguration.processcode);

        if (openScreens.length >= 1) {

          draftState.reportDetailObject = Object.entries(draftState.reportDetailObject).map(([tabKey, object]) => {
            if (openScreens.includes(object.objectConfiguration.processcode)) {
              object.objectConfiguration.isfavorite = !object.objectConfiguration.isfavorite;
            }

            return [tabKey, object];
          }).reduce((accum: any, [k, v]: any) => {
            accum[k] = v;
            return accum;
          }, {});

        }


        // if (drafttState.objects[payload.objectcontrollerno]) {
        //   drafttState.objects[payload.objectcontrollerno].ScreenConfig.TcodeConfiguration.isFavorite = !drafttState.objects[payload.objectcontrollerno].ScreenConfig.TcodeConfiguration.isFavorite;
        // }
      })
    },
    setReportPreviousCriteria(state, action: PayloadAction<KeyValueObject<ICriteriaFieldValue>>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        draftState.reportDetailObject[state.activeReportObjectno].previousLoadedCriteriaValue = payload;
      })
    },

    closeReportExplorerObject(state, action: PayloadAction<string>) {
      const objectId = action.payload;
      return produce(state, (draftState) => {

        if (draftState.reportDetailObject[objectId])
          delete draftState.reportDetailObject[objectId];

      });
    },
  }

});

export const { setReportFavorite, getReportMenu, getReportConfig, setReportMenu, setActiveReportObjectId, setReportConfig, setReportModelValue, toggleCriteria, setFieldFilter, loadReport, setReport, setReportPreviousCriteria, closeReportExplorerObject } = ReportsSlice.actions;

export default ReportsSlice.reducer;
