import Footer from "@/Screen/System/Footer";
import Header from "@/Screen/System/Header";
import Aside from "@/Screen/System/Aside";
import SideHelpUI from "@/UI/BaseComponents/Help/sideHelpUI";
import ShortHelpUI from "@/UI/BaseComponents/Help/shortHelpUI";
import { AlignLoader } from "AlignComponents";
import React from 'react';
// import ReactHintFactory from "react-hint";
import { Container } from "react-bootstrap";

export const AlignUIRender = ({ hasTabs, ...props }: any) => (
  <Container fluid className="p-0">
    <Aside />
    <Container fluid className="auth-container">
      <Header />
      <Container fluid className='content'>
        {props.children}
        <SideHelpUI />
        {/* <ShortHelpUI /> */}
      </Container>
    </Container>
  </Container>
);
