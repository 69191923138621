import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import TimeAgo from 'react-timeago';

import { EActivity } from "@/UI/Interfaces/IBaseUI";
import { AlignTab } from "AlignComponents";

import { addComment, toggleCommentsModal } from "@/Redux/Ducks/entryFormSlice"
import classNames from "classnames";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import BaseMethods from "AlignBaseUI/BaseMethods";
import { MdSend } from "react-icons/md";
import { Modal } from "AlignComponents";

interface IProps {
  onHide: () => void;
}

const ProcessComment = (props: IProps) => {
  const dispatch = useAppDispatch();

  const isCommentsModalOpen = useAppSelector(x => x.EntryForm.objects[x.EntryForm.activeObjectId].otherState.isCommentsModalOpen);
  const prid = useAppSelector(x => x.EntryForm.objects[x.EntryForm.activeObjectId].model.prid);
  const processCode = useAppSelector(x => x.EntryForm.objects[x.EntryForm.activeObjectId].ScreenConfig.TcodeConfiguration.processcode);
  const comments = useAppSelector(x => x.EntryForm.objects[x.EntryForm.activeObjectId].otherState.comments);
  const { username, userphoto } = useAppSelector(({ User }) => ({ username: User.user.username, userphoto: User.user.userphoto }));
  const [comment, setComment] = useState<string>('')

  const addCommentHandler = () => {
    dispatch(addComment({ commentstxt: comment, originprid: prid, originprocesscode: processCode, username }))
    setComment('');
  }
  const onChange = (e: any) => {
    setComment(e.target.value);
  }

  return (
    <>
      <Modal
        heading="Comments"
        show={isCommentsModalOpen}
        close={props.onHide}
        modalSize="lg"
        footer={
          <Button variant="secondary" onClick={props.onHide}>
            <IoMdClose className="me-2" size="16" />
            Close
          </Button>
        }
      >
        <div className="chatbox">
          <div>
            {
              comments.map((x, i) => {
                const messageProfile = BaseMethods.getUserPhoto(x.userphoto);

                return (
                  <div key={i} className={classNames(["d-flex w-fit-content flex-column align-items-end", { 'ms-auto': username?.toUpperCase() == x.createuser.toUpperCase() }])}>
                    <div className={classNames(["d-flex align-items-center", { 'flex-row-reverse': username?.toUpperCase() == x.createuser.toUpperCase() }])}>
                      <img src={messageProfile} alt="" className="rounded-circle thumb-xs mx-3" />
                      <div className={classNames(['message', { 'sent-message': username?.toUpperCase() == x.createuser.toUpperCase(), 'recieved-message': username?.toUpperCase() != x.createuser.toUpperCase() }])}>
                        {x.commentstxt}
                      </div>
                    </div>
                    <small className={classNames(["fs-12 w-fit-content", { 'me-4 text-end': username?.toUpperCase() == x.createuser.toUpperCase() }])}>
                      {username?.toUpperCase() == x.createuser.toUpperCase() ? 'You' : x.createuser}
                      <br />
                      <TimeAgo date={x.createdatetime} />
                    </small>
                  </div>
                )
              })
            }
          </div>
          <InputGroup className="chatbox-input mb-3">
            <FormControl value={comment} onChange={onChange} placeholder="Send A Message" />
            <Button variant="primary" onClick={addCommentHandler}>
              <MdSend size="16" />
            </Button>
          </InputGroup>
        </div>
      </Modal>
    </>
  );
};

export default ProcessComment;
