import { get, post } from "@/Utils/axios";
import { IUserForgotPassword, IUserLogin } from "@/Redux/Ducks/userSlice";

export function requestUserLogin(data: { username: string, password: string, languageno: string, clientno: string }): any {
  return post("Authentication/Login", data);
}

export function requestForgotPassword(email: string, clientno: string): any {
  return get("Authentication/ForgotPassword", { params: { email, clientno } });
}

export function requestResetPassword(email: string, token: string, password: string): any {
  return post("Authentication/ResetPassword", { email, token, password });
}

export function requestUserLogout(): any {
  return post("Authentication/Logout");
}

export function requestLoginBasicData(): any {
  return get("Authentication/getBasicData");
}

export function requestGetUserSpecificDetail(): any {
  return post("Configuration/UserSpecificDetail");
}

export function requestRefreshApplication(): any {
  return get("Configuration/RefreshApplication");
}

export function requestGetHidden() {
  return get(`Configuration/GetHidden`);
}

export function requestGetBannerHidden() {
  return get(`Configuration/GetBannerHidden`);
}

export function requestToggleHidden(object: string, toggle: boolean) {
  return get(`Configuration/ToggleHidden`, { params: { object, toggle } });
}

export function requestGetColors(): any {
  return get(`Configuration/GetColors`);
}