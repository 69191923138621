import { useAppDispatch, useAppSelector } from '@/Hook/hooks';
import React, { useEffect, useRef, useState } from 'react';
import { ComboBox } from '@grapecity/wijmo.react.input';
import { closeDimension, setAccountDimensionValue, setGridCellData, setFieldModelValue, setSubGridCellData, upsertDimension } from '@/Redux/Ducks/entryFormSlice';
import DimensionHelpUI from "@/UI/BaseComponents/Help/dimensionHelpUI";
import { closeHelp, EHelpOpenIn, EHelpType, getHelpConfig } from '@/Redux/Ducks/helpSlice';
import { EActivity, KeyValueObject } from '@/UI/Interfaces/IBaseUI';
import { IFieldConfig } from '@/UI/Interfaces/IFieldConfig';
import { Button } from 'react-bootstrap';
import { IoMdClose, IoMdRefresh } from 'react-icons/io';
import Modal from '../Modal';

// Only show in GLGE
const notEditableDimensions: string[] = ["affiliate"];

const DimensionPopup = () => {

  const dispatch = useAppDispatch();

  const [fieldIsInEditing, setFieldIsInEditing] = useState<KeyValueObject<boolean>>({});
  const currentValue = useRef<string>("");
  const dimensionFieldRef = useRef<KeyValueObject>({});

  // Processcode
  const objectcontrollerno = useAppSelector(x => x.Tab.tabs.find(y => y.tabKey == x.Tab.activeTabKey)?.tabActualKey);

  const activeObjectId = useAppSelector(x => x.EntryForm.activeObjectId);
  const screenActivity = useAppSelector(x => x.EntryForm.objects[x.EntryForm.activeObjectId].activity);
  const dimensionConfig = useAppSelector(x => x.EntryForm.objects[x.EntryForm.activeObjectId].otherState.accountDimensionSetting);

  const dimensionValuesRef = useRef<KeyValueObject>({});

  useEffect(() => {
    dimensionValuesRef.current = dimensionConfig?.dimensionValues || {};
  }, [dimensionConfig?.dimensionValues]);


  const change = (e: any, fieldId: string) => {
    currentValue.current = e.selectedValue;
  }

  const focus = (e: any, fieldId: string, dimensionFieldConfig: Array<IFieldConfig>) => {

    if (!screenActivity?.isEqual(EActivity.View) && notEditableDimensions.indexOf(fieldId) == -1) {
      const findConfig = dimensionFieldConfig.find(x => x.fieldid == fieldId);

      if (findConfig) {

        const dimensionValues = {
          accountno: dimensionConfig?.accountno,
          ...dimensionValuesRef.current
        };

        dispatch(closeHelp({ objectId: activeObjectId }));

        dispatch(getHelpConfig({
          helpObject: findConfig.helpobjectno,
          helpWhere: "",
          multiSelect: false,
          returnParams: {
            fieldId: fieldId
          },
          isSubGrid: false,
          helpType: EHelpType.DimensionHelp,
          helpOpenIn: EHelpOpenIn.EntryForm,
          isAccountDimension: true,
          dimensionValues
        }));

      }

      currentValue.current = e.selectedValue;

      setFieldIsInEditing(prevState => ({ ...prevState, [fieldId]: true }));
    }
  }

  const blur = (e: any, fieldId: string) => {

    if (e.selectedValue != dimensionConfig?.dimensionValues[fieldId]) {
      dispatch(setAccountDimensionValue({ fieldId, value: e.selectedValue }));
    }

    currentValue.current = "";
    setFieldIsInEditing(prevState => ({ ...prevState, [fieldId]: false }));
  }

  const rowSelect = (config: any, returnFieldId: any, selectedRow: KeyValueObject) => {
    if (dimensionConfig) {

      const singlecolumn = config.helpConfig.Config.singlecolumn.trim();

      dispatch(setAccountDimensionValue({ fieldId: returnFieldId, value: selectedRow[singlecolumn] }));

      const dimension = dimensionConfig.accountDimensions.find(x => x.accountno == dimensionConfig.accountno);

      if (dimension) {

        const findDimensionIndex = dimension.fieldConfig.findIndex(x => x.fieldid == returnFieldId);

        if (findDimensionIndex != -1) {
          if (dimension.fieldConfig[findDimensionIndex + 1]) {

            dimensionFieldRef.current[dimension.fieldConfig[findDimensionIndex + 1].fieldid].control.focus();

          }
        }

      }
    }

  }

  const closeDimensionPopup = () => {
    dispatch(closeHelp({ objectId: activeObjectId }));
    dispatch(closeDimension());
  }

  const saveCloseDimensionPopup = () => {
    if (dimensionConfig) {
      dispatch(closeHelp({ objectId: activeObjectId }));

      dispatch(upsertDimension({ dimensionValues: dimensionConfig.dimensionValues, dimensionSetFieldid: dimensionConfig.dimensionSetFieldid, dimensionSetting: { gridno: dimensionConfig.gridno as string, rowIndex: dimensionConfig.rowIndex as number } }));
      dispatch(closeDimension());

      const dimension = dimensionConfig.accountDimensions.find(x => x.accountno == dimensionConfig.accountno);

      const value = dimension?.fieldConfig?.map(x => dimensionConfig.dimensionValues[x.fieldid] || "")?.join("-");

      if (dimensionConfig.gridno && dimensionConfig.rowIndex != undefined) {

        if (dimensionConfig.isSubGrid)
          dispatch(setSubGridCellData({ gridno: dimensionConfig.gridno, rowIndex: dimensionConfig.rowIndex, columnId: `dimension${dimensionConfig.accountFieldId}values`, value }));
        else
          dispatch(setGridCellData({ gridno: dimensionConfig.gridno, rowIndex: dimensionConfig.rowIndex, columnId: `dimension${dimensionConfig.accountFieldId}values`, value }));
      }
      else {
        dispatch(setFieldModelValue({ objectId: activeObjectId, fieldId: `dimension${dimensionConfig.accountFieldId}values`, value }));
      }
    }
  }


  let dimensionFields = dimensionConfig?.accountDimensions.find(x => x.accountno == dimensionConfig?.accountno)?.fieldConfig;
  const dimensionValues = dimensionConfig?.dimensionValues;

  if (!objectcontrollerno?.isEqual("GLGE") && dimensionFields) {
    dimensionFields = dimensionFields.filter(x => notEditableDimensions.indexOf(x.fieldid) == -1);
  }

  return (
    <Modal
      heading={"Dimension - " + dimensionFields?.map(x => dimensionValues?.[x.fieldid])?.join(" | ")}
      close={closeDimensionPopup}
      show={!!dimensionFields}
      modalSize="xl"
      footer={
        <>
          <Button variant="light" onClick={closeDimensionPopup}>
            <IoMdClose className="me-2" size="16" />
            Close
          </Button>
          {
            !screenActivity?.isEqual(EActivity.View) ?
              <Button variant="secondary" onClick={saveCloseDimensionPopup}>
                <IoMdRefresh className="me-2" size="16" />
                Save &amp; Close
              </Button>
              : null
          }
        </>
      }
    >
      <div className="dimension-body h-100">

        {
          !screenActivity?.isEqual(EActivity.View) ?
            <div className="dimension-help">
              <DimensionHelpUI rowSelect={rowSelect} />
            </div>
            : null
        }

        <div className="dimension-fields">
          {
            dimensionFields?.map((x, i) => (
              <div className="field-container" key={x.fieldid}>
                <label id={"dimension-lb-" + activeObjectId + "-" + x.fieldid} htmlFor={"dimension-field-" + activeObjectId + "-" + x.fieldid} >{x.fieldlabel}
                  {
                    x.validatemandatory ?
                      <span>*</span>
                      :
                      null
                  }
                </label>
                <ComboBox type="text"
                  ref={(ref: any) => dimensionFieldRef.current[x.fieldid] = ref}
                  id={"dimension-field-" + activeObjectId + "-" + x.fieldid}
                  selectedValue={fieldIsInEditing[x.fieldid] ? currentValue.current : (dimensionValues?.[x.fieldid] || "")}
                  textChanged={(e: any) => change(e, x.fieldid)}
                  gotFocus={(e: any) => focus(e, x.fieldid, dimensionFields as Array<IFieldConfig>)}
                  lostFocus={(e: any) => blur(e, x.fieldid)}
                  isDisabled={screenActivity?.isEqual(EActivity.View) || notEditableDimensions.indexOf(x.fieldid) != -1}
                />
              </div>

            ))
          }

        </div>
      </div>
    </Modal>
  )

}

export default DimensionPopup;
