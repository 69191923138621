import { ArrayKeyValueObject, KeyValueObject, ObjectConfiguration } from "@/UI/Interfaces/IBaseUI";
import { ICriteriaFieldConfig, ICriteriaFieldValue } from "@/UI/Interfaces/IFieldConfig";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import BaseMethods from "AlignBaseUI/BaseMethods";
import produce from "immer";
import { IHelpHeader } from "./helpSlice";

interface IDataAnalytics {
  dataAnalyticsMenu: Array<IDataAnalyticsMenu>;
  expandedObject: string,
  dataAnalyticsDetailObject: KeyValueObject<IDataAnalyticsDetailObject>;
  activeDataAnalyticsObjectno: string;
}

interface IDataAnalyticsMenu {
  moduleno: string;
  modulestxt: string;
  objectcontrollerno: string;
  objectcontrollerstxt: string;
  objectcategorystxt: string;
}

export interface IGridView {
  allSearchText: string,
  filterDefinition: string,
  frozenColumns: number,
  columnIndex: string[],
  columnWidth: number[],
  columnHidden: string[],
  pivotDefinition: string,
  groupDescriptions: string[]
}

export interface IDataAnalyticsLayout {
  layoutno: number,
  layoutstxt: string,
  notes: string,
  dataanalyticsno: string,
  layout: string,
  isdefault: boolean,
  sharedto: string,
  layouttype: string,
  sharedby?: string
}

interface IDataAnalyticsDetailObject {
  criteria: Array<ICriteriaFieldConfig>;
  FICriteria: Array<ICriteriaFieldConfig>;
  modelValue: KeyValueObject<ICriteriaFieldValue>;
  objectConfiguration: ObjectConfiguration
  isCriteriaOpen: boolean;
  dataLoaded: boolean;
  activeTab: string;
  grid: IDataAnalyticsConfig | null;
  layouts: Array<IDataAnalyticsLayout>;
  gridView: IGridView;
  appliedLayout: number;
  appliedLayoutType: string;
}
type IDataAnalyticsConfig = {
  Header: Array<IHelpHeader & { showsummary: boolean }>;
  Detail: ArrayKeyValueObject;
}

const InitialState: IDataAnalytics = {
  dataAnalyticsMenu: [],
  expandedObject: "",
  dataAnalyticsDetailObject: {},
  activeDataAnalyticsObjectno: ""
};

const dataAnalyticsSlice = createSlice({
  name: "dataAnalytics",
  initialState: InitialState,
  reducers: {
    getDataAnalyticsMenu() { },
    getDataAnalyticsConfig(state, action: PayloadAction<{ dataAnalyticsno: string, gridView?: IGridView, modelValue?: KeyValueObject<ICriteriaFieldValue> }>) { },
    loadDataAnalytics(state, action: PayloadAction<{ dataAnalyticsno: string, onSuccess: () => void }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        const dataAnalyticsObjects = draftState.dataAnalyticsDetailObject[draftState.activeDataAnalyticsObjectno];
        dataAnalyticsObjects.dataLoaded = false;
      });
    },
    setActiveDataAnalyticsObjectId(state, action: PayloadAction<string>) {
      return produce(state, (draftState) => {
        draftState.activeDataAnalyticsObjectno = action.payload;
      });
    },
    setDataAnalyticsObjects(state, action: PayloadAction<{ dataAnalyticsObjects: Array<IDataAnalyticsMenu> }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        draftState.dataAnalyticsMenu = payload.dataAnalyticsObjects;
      });

    },
    setDataAnalyticsConfig(state, action: PayloadAction<Pick<IDataAnalyticsDetailObject, 'FICriteria' | 'criteria' | 'objectConfiguration' | 'modelValue' | 'gridView' | 'layouts'> & { objectId: string }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {

        let modelValue: IDataAnalyticsDetailObject["modelValue"] = {};
        const criteria = payload.criteria.filter(x => payload.FICriteria.find(y => y.fieldid == x.fieldid) == null);
        const FICriteria = payload.FICriteria.map(x => ({ ...x, filteroption: true, multiplevalue: true }));

        [...criteria, ...FICriteria].map(x => {
          let value = null;

          if (BaseMethods.getControlType(x.controltypeno) == "checkbox")
            value = x.defaultvalue == "1" ? true : false;
          else if (BaseMethods.getControlType(x.controltypeno) == "number")
            value = x.defaultvalue ? Number(x.defaultvalue) : 0;
          else if (BaseMethods.getControlType(x.controltypeno) == "date") {
            if (x.validatemandatory) {
              value = new Date();
              value.setHours(0, 0, 0, 0);
              value = `${value.getFullYear()}-${("0" + (value.getMonth() + 1)).slice(-2)}-${("0" + value.getDate()).slice(-2)}T00:00:00`;
            }
          }
          else
            value = x.defaultvalue || "";

          const isDimension = FICriteria.find(y => y.fieldid == x.fieldid) != null;

          modelValue[x.fieldid] = {
            fromValue: value,
            toValue: "",
            isDimension,
            filterType: "=",
            caseSensitive: false
          };

        });

        draftState.dataAnalyticsDetailObject[payload.objectId] = {
          FICriteria: FICriteria,
          criteria: criteria,
          objectConfiguration: payload.objectConfiguration,
          modelValue: modelValue,
          grid: null,
          isCriteriaOpen: true,
          dataLoaded: false,
          activeTab: "dataAnalytics",
          layouts: payload.layouts,
          gridView: {
            columnIndex: [],
            columnWidth: [],
            columnHidden: [],
            filterDefinition: "",
            pivotDefinition: "",
            frozenColumns: 0,
            allSearchText: "",
            groupDescriptions: []
          },
          appliedLayout: 0,
          appliedLayoutType: ""
        };
        draftState.activeDataAnalyticsObjectno = payload.objectId

        if (payload.gridView) {
          draftState.dataAnalyticsDetailObject[payload.objectId].gridView = payload.gridView;
        }

        if (payload.modelValue) {
          draftState.dataAnalyticsDetailObject[payload.objectId].modelValue = payload.modelValue;
        }
      });

    },
    setGridView(state, action: PayloadAction<{ objectId: string, gridView: IGridView }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        const dataAnalyticsObjects = draftState.dataAnalyticsDetailObject[payload.objectId];
        dataAnalyticsObjects.gridView = payload.gridView;
      });
    },
    toggleCriteria(state, action: PayloadAction<{ dataanalyticsno: string }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        const dataAnalyticsObjects = draftState.dataAnalyticsDetailObject[payload.dataanalyticsno];
        dataAnalyticsObjects.isCriteriaOpen = !dataAnalyticsObjects.isCriteriaOpen;
      });
    },
    setActiveTab(state, action: PayloadAction<{ dataanalyticsno: string, tab: string }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        const dataAnalyticsObjects = draftState.dataAnalyticsDetailObject[payload.dataanalyticsno];
        dataAnalyticsObjects.activeTab = payload.tab;
      });
    },
    setDataAnalyticsModelValue(state, action: PayloadAction<{ fieldId: string, newValue: string, toValue: boolean }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        if (payload.toValue) {
          draftState.dataAnalyticsDetailObject[state.activeDataAnalyticsObjectno].modelValue[payload.fieldId].toValue = payload.newValue;
        }
        else {
          draftState.dataAnalyticsDetailObject[state.activeDataAnalyticsObjectno].modelValue[payload.fieldId].fromValue = payload.newValue;
        }
      });

    },
    setFieldFilter(state, action: PayloadAction<{ fieldId: string, filterType: string, caseSensitive: boolean }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        draftState.dataAnalyticsDetailObject[state.activeDataAnalyticsObjectno].modelValue[payload.fieldId].filterType = payload.filterType;
        draftState.dataAnalyticsDetailObject[state.activeDataAnalyticsObjectno].modelValue[payload.fieldId].caseSensitive = payload.caseSensitive;
      });

    },
    setDataAnalyticsDetail(state, action: PayloadAction<IDataAnalyticsConfig>) {
      const payload = action.payload;

      return produce(state, (draftState) => {

        const dateTimeFields = payload.Header.filter((x: any) => BaseMethods.getControlType(x.controltype).isEqual("date", "time"));

        if (dateTimeFields.length) {
          payload.Detail.map((x: any) => {
            dateTimeFields.map((y: any) => {
              x[y.ColumnID] = new Date(x[y.ColumnID]);
            });
          });
        }


        draftState.dataAnalyticsDetailObject[draftState.activeDataAnalyticsObjectno].dataLoaded = true;
        draftState.dataAnalyticsDetailObject[draftState.activeDataAnalyticsObjectno].grid = {
          Header: payload.Header,
          Detail: payload.Detail
        };
      });

    },
    setExpandedObject(state, action: PayloadAction<{ module: string }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        draftState.expandedObject = payload.module;
      });

    },

    setDataAnalyticsFavorite(state, action: PayloadAction<{ objectcontrollerno: string }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        // reflect favorite in open screens
        const openScreens = Object.entries(draftState.dataAnalyticsDetailObject).filter(([tabKey, object]) => object.objectConfiguration.processcode == payload.objectcontrollerno)
          .map(([tabKey, object]) => object.objectConfiguration.processcode);

        if (openScreens.length >= 1) {

          draftState.dataAnalyticsDetailObject = Object.entries(draftState.dataAnalyticsDetailObject).map(([tabKey, object]) => {
            if (openScreens.includes(object.objectConfiguration.processcode)) {
              object.objectConfiguration.isfavorite = !object.objectConfiguration.isfavorite;
            }

            return [tabKey, object];
          }).reduce((accum: any, [k, v]: any) => {
            accum[k] = v;
            return accum;
          }, {});

        }


        // if (drafttState.objects[payload.objectcontrollerno]) {
        //   drafttState.objects[payload.objectcontrollerno].ScreenConfig.TcodeConfiguration.isFavorite = !drafttState.objects[payload.objectcontrollerno].ScreenConfig.TcodeConfiguration.isFavorite;
        // }
      })
    },

    storeLayout(state, action: PayloadAction<Omit<IDataAnalyticsLayout, "layoutno" | "layoutrefno" | "reportName">>) { },
    setLayout(state, action: PayloadAction<IDataAnalyticsLayout>) {
      const payload = action.payload;
      return produce(state, (draftState) => {
        draftState.dataAnalyticsDetailObject[draftState.activeDataAnalyticsObjectno].layouts.push(payload);
      });
    },
    updateLayout(state, action: PayloadAction<IDataAnalyticsLayout>) {
      const payload = action.payload;
      return produce(state, (draftState) => {
        draftState.dataAnalyticsDetailObject[draftState.activeDataAnalyticsObjectno].layouts = draftState.dataAnalyticsDetailObject[draftState.activeDataAnalyticsObjectno].layouts.map(x => {
          if (x.layoutno == payload.layoutno) {
            return { ...payload }
          }

          return x;
        });
      });
    },
    deleteLayout(state, action: PayloadAction<{ layoutno: number, layouttype: string }>) {
      const payload = action.payload;
      return produce(state, (draftState) => {
        var findIndex = draftState.dataAnalyticsDetailObject[draftState.activeDataAnalyticsObjectno].layouts.findIndex(x => x.layoutno == payload.layoutno);
        if (findIndex != -1) {
          draftState.dataAnalyticsDetailObject[draftState.activeDataAnalyticsObjectno].layouts.splice(findIndex, 1);
        }
      });
    },
    applyLayout(state, action: PayloadAction<{ layoutno: number, layouttype: string, onSuccess: (object: IDataAnalyticsDetailObject) => void }>) {
      const payload = action.payload;
      return produce(state, (draftState) => {
        var object = draftState.dataAnalyticsDetailObject[draftState.activeDataAnalyticsObjectno];
        var layout = object.layouts.find(x => x.layoutno == payload.layoutno && x.layouttype == payload.layouttype);
        let emptyModelValue: IDataAnalyticsDetailObject["modelValue"] = {};
        const criteria = object.criteria.filter(x => object.FICriteria.find(y => y.fieldid == x.fieldid) == null);
        const FICriteria = object.FICriteria.map(x => ({ ...x, filteroption: true, multiplevalue: true }));

        [...criteria, ...FICriteria].map(x => {
          let value = null;

          if (BaseMethods.getControlType(x.controltypeno) == "checkbox")
            value = x.defaultvalue == "1" ? true : false;
          else if (BaseMethods.getControlType(x.controltypeno) == "number")
            value = x.defaultvalue ? Number(x.defaultvalue) : 0;
          else if (BaseMethods.getControlType(x.controltypeno) == "date") {
            if (x.validatemandatory) {
              value = new Date();
              value.setHours(0, 0, 0, 0);
              value = `${value.getFullYear()}-${("0" + (value.getMonth() + 1)).slice(-2)}-${("0" + value.getDate()).slice(-2)}T00:00:00`;
            }
          }
          else
            value = x.defaultvalue || "";

          const isDimension = FICriteria.find(y => y.fieldid == x.fieldid) != null;

          emptyModelValue[x.fieldid] = {
            fromValue: value,
            toValue: "",
            isDimension,
            filterType: "=",
            caseSensitive: false
          };

        });

        if (layout) {
          object.appliedLayout = payload.layoutno;
          object.appliedLayoutType = payload.layouttype;
          const gridView = JSON.parse(layout?.layout).gridView;
          const modelValue = JSON.parse(layout?.layout).modelValue;

          if (modelValue) {
            Object.entries(object.modelValue).map(([key, value]) => {
              if (modelValue[key])
                object.modelValue[key] = modelValue[key];
              else
                object.modelValue[key] = emptyModelValue[key];
            });
          }
          else {
            object.modelValue = emptyModelValue;
          }


          if (object.isCriteriaOpen) {
            object.isCriteriaOpen = false;
          }

          if (gridView) {
            object.gridView = gridView;
          }
          else {
            object.gridView = {
              columnIndex: [],
              columnWidth: [],
              columnHidden: [],
              filterDefinition: "",
              pivotDefinition: "",
              frozenColumns: 0,
              allSearchText: "",
              groupDescriptions: []
            }
          }
        }
      });
    },
    closeDataAnalyticsObject(state, action: PayloadAction<string>) {
      const objectId = action.payload;
      return produce(state, (draftState) => {

        if (draftState.dataAnalyticsDetailObject[objectId])
          delete draftState.dataAnalyticsDetailObject[objectId];

      });
    },
  },
});

export const { setDataAnalyticsFavorite, getDataAnalyticsMenu, setDataAnalyticsDetail, setGridView, toggleCriteria, setActiveTab, setActiveDataAnalyticsObjectId, setDataAnalyticsObjects, setDataAnalyticsModelValue, setFieldFilter, setExpandedObject, getDataAnalyticsConfig, loadDataAnalytics, setDataAnalyticsConfig, storeLayout, setLayout, updateLayout, deleteLayout, applyLayout, closeDataAnalyticsObject } = dataAnalyticsSlice.actions;

export default dataAnalyticsSlice.reducer;
