import { useEffect, useRef, useState } from 'react'

import { IFieldConfig } from "@/UI/Interfaces/IFieldConfig";
import { useAppDispatch, useAppSelector } from '@/Hook/hooks';
import { IFieldProps } from '@/UI/Interfaces/IComponent';
import { getScreenConfig } from '@/Redux/Ducks/entryFormSlice';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const Field = ({ config, fieldValueChange, uniqueKey, enable, value }: IFieldProps) => {
  const { fieldid } = config;
  const dispatch = useAppDispatch();
  const richTextRef: any = useRef<any>();

  const [fieldIsInEditing, setFieldIsInEditing] = useState<boolean>(false);

  var __editorState = EditorState.createEmpty();

  const [editorState, setEditorState] = useState<any>(__editorState);

  const objectId = useAppSelector((x) => x.EntryForm.activeObjectId);


  useEffect(() => {
    const content = value || "";
    const __blocksFromHtml = htmlToDraft(content);

    if (__blocksFromHtml) {
      const __contentState = ContentState.createFromBlockArray(__blocksFromHtml.contentBlocks);
      const __editorState = EditorState.createWithContent(__contentState);
      setEditorState(__editorState)
    }
  }, [value]);

  useEffect(() => {

    if (value === undefined) {
      callBaseMethod("");
    }
  }, [value]);

  const callBaseMethod = (value: any) => {
    fieldValueChange(fieldid, value);
  }

  const editorStateChange = (e: any) => {
    setEditorState(e);
  }

  const focus = (e: any) => {
    setFieldIsInEditing(true);
  }

  const blur = (e: any) => {
    callBaseMethod(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    setFieldIsInEditing(false);
  }

  if (!config) return <></>;
  const id = objectId + (uniqueKey ? `-${uniqueKey}` : "") + "-" + fieldid;

  return (
    <>
      <Editor editorState={editorState} readOnly={!enable} onEditorStateChange={editorStateChange} onFocus={focus} onBlur={blur}
      />
    </>
  )

}





export default Field;

