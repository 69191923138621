import { useEffect, useState } from "react";
import { AlignUIRender } from "./indexUI";
import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import { getScreenConfig } from "@/Redux/Ducks/entryFormSlice";
import { getUserInsights, ILayout, saveUserDashboardLayout } from "@/Redux/Ducks/dashboardSlice";
import { ArrayKeyValueObject } from "@/UI/Interfaces/IBaseUI";

const Dashboard = () => {
	const dispatch = useAppDispatch();

	const [activeWidgetGroupNo, setActiveWidgetGroupNo] = useState('')
	const [layout, setLayout] = useState<any>('');
	const [options, setOptions] = useState<ArrayKeyValueObject>([]);

	const widgets = useAppSelector(state => state.Home.widgets);
	const layouts = useAppSelector((state) => state.Home.layout.filter(x => x.widgetgroupno == activeWidgetGroupNo));

	const widgetsEntries = Object.entries(widgets);

	useEffect(() => {
		if (widgetsEntries.length >= 1) {
			if (!activeWidgetGroupNo) {
				// setting first widget group as active widget group
				setActiveWidgetGroupNo(widgetsEntries[0][1][0].widgetgroupno);
			}

			if (activeWidgetGroupNo) {
				if (!(options.length >= 1)) {
					const widgetEntriesToOptions = (widgetsEntries.map(x => {
						if (x[1].filter(x => x.isdashboard).length >= 1) {
							return [x[0], x[1].filter(x => x.isinsight)]
						}
						else {
							return null;
						}
					}).filter(x => x && x[1].length) || []).map((x: any) => ({ value: x[0], name: x[1][0].widgetgrouplabel }));
					setOptions(widgetEntriesToOptions)
				}
			}
		}
	}, [widgetsEntries])

	useEffect(() => {
		if (activeWidgetGroupNo) {
			if (layouts.length >= 1) {
				var dashboardLayout = layouts.find(x => x.insightarea == 'H');
				var defaultLayout = dashboardLayout ? dashboardLayout.layout : layouts.find(x => x.insightarea == 'A')?.layout as string;
				setLayout(defaultLayout);
			}
		}
	}, [activeWidgetGroupNo])

	useEffect(() => {
		dispatch(getUserInsights());
	}, []);

	// routing methods
	const routeToScreen = (screenCode: string, transactionNo?: string) => {
		dispatch(getScreenConfig({ processCode: screenCode, transactionNo }));
	};

	const saveLayout = (widgetgroupno: string, layout: JSON, insightarea: string) => {
		dispatch(saveUserDashboardLayout({ widgetgroupno, layout, insightarea }));
	};


	const onChange = (e: any) => {
		setLayout('');
		setActiveWidgetGroupNo(e)
	}

	const onLayoutChange = (layout: any) => {
		setLayout(JSON.stringify(layout));
	}

	return AlignUIRender({ activeWidgetGroupNo, layout, options, widgets, routeToScreen, saveLayout, onChange, onLayoutChange });
};

export default Dashboard;
