import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import produce from "immer";

interface IColors {
  color: string,
  variable: string
}

export interface IUserObject {
  authToken: string | undefined,
  companytimezone: string,
  selectedLegalEntity: string,
  companyBannerImage: string,
  languageno: string,
  userFavorites: Array<IUserFavorite>,
  defaultPage: string,
  colors: Array<IColors>,
  companyBannerHidden: boolean,
  hidden: Array<string>,
  user: {
    username: string | undefined;
    name: string | undefined;
    email: string | undefined;
    usercategoryno: string | undefined;
    userphoto: string | undefined;
    allowmobile: boolean;
  },
  clientLanguage: Array<{
    languageno: string;
    languagenostxt: string;
    isdefault: boolean;
  }>,
  UserLegalEntity: Array<{
    legalentityno: string,
    legalentitystxt: string,
    isdefault: boolean,
    logoimage: string
  }>,
  CompanyDataFormat: Array<{
    fielddataformatno: string,
    fielddataformatconstant: string,
    fielddataformat: string,
    fielddataformatmasking: string,
    legalentityno: string,
    fielddataprocesscode: string,
    processcode: string
  }>,
  SystemLanguages: Array<{
    prid: string,
    objectno: string,
    objectlabel: string,
    objectdefinition: string,
    languageno: string,
  }>,
  CompanyLedgerInfo: {
    legalentitycalendarno: string | null,
    accountchartno: string | null,
    localcurrencyno: string | null,
    reportingcurrencyno: string | null,
    localexchangecategoryno: string | null,
    reportingexchangecategoryno: string | null,
  }
}

export interface IUserLogin {
  username: string;
  password: string;
  languageno: string
  clientno: string,
  redirectUrl?: string;
}

export interface IUserForgotPassword {
  email: string;
  clientno: string;
}

export interface IUserResetPassword {
  email: string;
  password: string;
  token: string;
}

interface IUserFavorite {
  objectcontrollerno: string;
  objectcontrollerstxt: string;
  applicationobjecttype: string;
}

const initialState: IUserObject = {
  authToken: undefined,
  companytimezone: "",
  selectedLegalEntity: "",
  userFavorites: [],
  companyBannerImage: "",
  languageno: "",
  clientLanguage: [],
  colors: [],
  UserLegalEntity: [],
  CompanyDataFormat: [],
  SystemLanguages: [],
  CompanyLedgerInfo: {
    accountchartno: null,
    legalentitycalendarno: null,
    localcurrencyno: null,
    localexchangecategoryno: null,
    reportingcurrencyno: null,
    reportingexchangecategoryno: null
  },
  user: {
    username: undefined,
    name: undefined,
    email: undefined,
    usercategoryno: undefined,
    userphoto: undefined,
    allowmobile: false
  },
  defaultPage: "",
  hidden: [],
  companyBannerHidden: false
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginUser(state, action: PayloadAction<IUserLogin>) { },
    forgotUserPassword(state, action: PayloadAction<IUserForgotPassword>) { },
    resetUserPassword(state, action: PayloadAction<IUserResetPassword>) { },
    logoutUser() { },
    getLoginBasicData() { },
    getUserSpecificDetail() { },
    setLoginBasicData(state, action) {
      const payload = action.payload;
      return { ...state, ...payload };
    },
    setUserObject(state, action) {
      const payload = action.payload;
      return { ...state, ...payload };
    },
    addUserFavorite(state, action: PayloadAction<IUserFavorite>) {
      const { applicationobjecttype, objectcontrollerstxt, objectcontrollerno } = action.payload;

      return produce(state, (draftState) => {
        const alreadyInFavorite = draftState.userFavorites.findIndex(x => x.objectcontrollerno == objectcontrollerno);

        if (alreadyInFavorite > -1) {
          draftState.userFavorites.splice(alreadyInFavorite, 1);
        }
        else {
          draftState.userFavorites.push({ objectcontrollerno, applicationobjecttype, objectcontrollerstxt })
        }
      })
    },
    getHidden() { },
    getBannerHidden() { },
    setHidden(state, action: PayloadAction<Array<string>>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        draftState.hidden = payload;
      })
    },
    setBannerHidden(state, action: PayloadAction<boolean>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        draftState.companyBannerHidden = payload;
      })
    },
    toggleHidden(state, action: PayloadAction<string>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        if (draftState.hidden.includes(payload)) {
          const findIndex = draftState.hidden.findIndex(x => x == payload);
          draftState.hidden.splice(findIndex, 1);
        }
        else {
          draftState.hidden.push(payload);
        }
      })
    },
    getColors() { },
    setColors(state, action: PayloadAction<Array<IColors>>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        draftState.colors = payload;
      })
    }
  }
});

export const { loginUser, forgotUserPassword, resetUserPassword, addUserFavorite, logoutUser, getLoginBasicData, setLoginBasicData, setUserObject, getUserSpecificDetail, getHidden, getBannerHidden, setHidden, setBannerHidden, toggleHidden, getColors, setColors } = userSlice.actions;

export default userSlice.reducer;
