import classNames from "classnames";
import SelectSearch, { fuzzySearch } from "react-select-search";

interface IProps {
  menuOpen: boolean,
  showOverlay: boolean,
  options: any,
  onChange: (val: any) => void,
  onKeyDown: (e: any) => void,
  filterOptions: (options: any) => any,
  searchInput: React.MutableRefObject<any>,
  closeMenu: () => void
}

export const AlignUIRender = ({ menuOpen, showOverlay, options, onChange, filterOptions, searchInput, onKeyDown, closeMenu }: IProps) => (
  <>
    {
      showOverlay ?
        <div className={classNames(["sidesearch-overlay", { show: menuOpen }])} onClick={closeMenu}></div>
        : null
    }
    <div onKeyDown={onKeyDown} className={classNames(["search", { show: menuOpen }])}>
      <SelectSearch
        autoFocus={true}
        autoComplete="off"
        ref={searchInput}
        options={options}
        onChange={onChange}
        search={true}
        filterOptions={filterOptions}
        placeholder="Search"
        printOptions="always"
      />
    </div>
  </>
);
