import { Worker, OpenFile, Viewer } from '@react-pdf-viewer/core';
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { SelectionMode } from "@react-pdf-viewer/selection-mode";
import { ToolbarSlot } from "@react-pdf-viewer/default-layout";

interface IProps {
  src: any;
  fileName: string;
}

const PdfViewer = (props: IProps) => {
  const toolbarPluginInstance = toolbarPlugin({
    getFilePlugin: {
      fileNameGenerator: (file: OpenFile) => {
        let fileName = props.fileName;
        return `${fileName}`;
      },
    },
    selectionModePlugin: {
      selectionMode: SelectionMode.Text,
    },
  });

  const { Toolbar } = toolbarPluginInstance;


  return (
    <>
      {props.src ? (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.9.359/build/pdf.worker.min.js">
          <div className="rpv-core__viewer report-viewer-container">
            <div className="report-viewer-toolbar" style={{}}>
              <Toolbar>
                {(props: ToolbarSlot) => {
                  const {
                    CurrentPageInput,
                    Download,
                    EnterFullScreen,
                    GoToNextPage,
                    GoToPreviousPage,
                    NumberOfPages,
                    Print,
                    ZoomIn,
                    ZoomOut,
                    ShowSearchPopover,
                  } = props;
                  return (
                    <>
                      <div style={{ padding: "0px 2px" }}>
                        <ShowSearchPopover />
                      </div>
                      <div style={{ padding: "0px 2px" }}>
                        <ZoomOut />
                      </div>
                      <div style={{ padding: "0px 2px" }}>
                        <ZoomIn />
                      </div>
                      <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                        <GoToPreviousPage />
                      </div>
                      <div style={{ padding: "0px 2px", width: "4rem" }}>
                        <CurrentPageInput />
                      </div>
                      <div style={{ padding: "0px 2px" }}>
                        / <NumberOfPages />
                      </div>
                      <div style={{ padding: "0px 2px" }}>
                        <GoToNextPage />
                      </div>
                      <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                        <EnterFullScreen />
                      </div>
                      <div style={{ padding: "0px 2px" }}>
                        <Download />
                      </div>
                      <div style={{ padding: "0px 2px" }}>
                        <Print />
                      </div>
                    </>
                  );
                }}
              </Toolbar>
            </div>
            <div className="report-viewer">
              <Viewer
                fileUrl={props.src}
                plugins={[toolbarPluginInstance]}
              />
            </div>
          </div>
        </Worker>
      ) : null}
    </>
  );
};

export default PdfViewer;
