import { lazy } from "react";

// Layout (Upper Wrapper)
import RouteAuthorize from "./routeAuthorize";
import RouteUnauthorize from "./routeUnauthorize";


import Home from "@/Screen/System/Home";
import Login from "@/Screen/System/Authentication/Login";
import ForgotPassword from "@/Screen/System/Authentication/ForgotPassword";
import DataAnalytics from "@/Screen/System/DataAnalytics";
import Report from "@/Screen/System/ReportExplorer";
import Inbox from "@/Screen/System/Inbox";
import EntryForm from "@/Screen/System/EntryForm";
import Dashboard from "@/Screen/System/Dashboard";
import ResetPassword from "@/Screen/System/Authentication/ResetPassword";
import OpenScreen from "@/Screen/System/OpenScreen"
import Approval from "@/Screen/System/Approval";
import Workspace from "@/Screen/System/Workspace"

export const ROUTES = [

  //#region UnAuthorized Routes

  {
    path: "/login",
    component: Login,
    routeComponent: RouteUnauthorize,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    routeComponent: RouteUnauthorize,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    routeComponent: RouteUnauthorize,
  },

  //#endregion

  //#region Authorized Routes

  {
    path: "/",
    component: Home,
    exact: true,
    routeComponent: RouteAuthorize,
  },

  {
    path: "/IE/:routeTo",
    component: DataAnalytics,
    routeComponent: RouteAuthorize,
    exact: true
  },

  {
    path: "/RE/:routeTo",
    component: Report,
    routeComponent: RouteAuthorize,
    exact: true
  },

  {
    path: "/Workspace/:workspaceNo",
    component: Workspace,
    routeComponent: RouteAuthorize,
    exact: true
  },

  {
    path: "/openscreen/:processCode/:transactionNo",
    component: OpenScreen,
    routeComponent: RouteAuthorize,
    exact: true
  },

  {
    path: "/processaproval",
    component: Approval,
    routeComponent: RouteAuthorize,
    exact: true,
    allowLayout: false
  },

  {
    path: "/EF/:routeTo",
    component: EntryForm,
    routeComponent: RouteAuthorize,
    exact: true,
  },

  {
    path: "/approval",
    component: Inbox,
    routeComponent: RouteAuthorize,
  },

  {
    path: "/approval/:subFolder",
    component: Inbox,
    routeComponent: RouteAuthorize,
  },

  {
    path: "/notification",
    component: Inbox,
    routeComponent: RouteAuthorize
  },

  {
    path: "/outbox",
    component: Inbox,
    routeComponent: RouteAuthorize
  },

  {
    path: "/outbox/:subFolder",
    component: Inbox,
    routeComponent: RouteAuthorize,
  },

  {
    path: "/trash",
    component: Inbox,
    routeComponent: RouteAuthorize
  },

  {
    path: "/important",
    component: Inbox,
    routeComponent: RouteAuthorize
  },

  {
    path: "/Dashboard",
    component: Dashboard,
    exact: true,
    routeComponent: RouteAuthorize,
  },
  //#endregion

];
