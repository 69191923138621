import { IUserApprovalActiveProcess } from "@/UI/Interfaces/IFavorite";
import { get } from "@/Utils/axios";

export function requestGetUserRights(): any {
  return get(`Configuration/GetUserRights`);
}

export function requestGetHomeUserSpecificData(): any {
  return get(`Configuration/GetHomeUserSpecificData`);
}

export function requestGetAllTodayActivity(): any {
  return get(`Configuration/GetAllTodayActivity`);
}

export function requestSetObjectFavorite(objectcontrollerno: string, applicationobjecttype: string): any {
  return get(`screen/SetObjectFavorite`, { params: { objectcontrollerno, applicationobjecttype } });
}

export function requestGetFrequentlyUsed(): any {
  return get(`Configuration/GetFrequentlyUsed`);
}

export function requestGetUserApprovalActiveProcess(): IUserApprovalActiveProcess[] {
  return get(`Configuration/GetUserApprovalActiveProcess`) as any;
}

