import "@/Assets/scss/app.scss";
import "@/Global/prototype";
import store from "@/Redux/configureStore";
import Routes from "@/Routes";
import history from "@/Utils/history";
import * as wijmo from "@grapecity/wijmo";
import { AlignLoader, Message } from "AlignComponents";
import { ConnectedRouter } from "connected-react-router";
import React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

wijmo.setLicenseKey("ebadwadood28@gmail.com,E826993416896391#B0hNLbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPR5mRwljWwkXU42kMrpGVXBVUj9UahJEeYdVaxB7ZyZTaQV7Ta3CajNlQ58GNJVVMvhFO6EmM8FlVuVnbp96Y5IjRqJVWpp4NwY7NxlHc8lzYTZzZpZ6cMR6SWZHM8dTOxoHV9RDRFBnTJF4QSl5T6QFR7p5UiZTcxEmeItUbNhDczIDSw5WVzllRztycllnWJl4Z436LYJjdRN6QYJHNlF7VGdkYrklNFpURvQDUi5Wdtp7N88ETvkDVQRzKXN7cxdjavIHMaRzVPlWYQN5SjlWWEdEU5tGUnpVYCdnR0RmaZdERMdkY52yMXBFaxhDTrMVOKdVbal6b9onaEFzYCRWYvRlbxJ5bpZ6LZdkRpFjd8I5Vu5md6pkM6I4dq9WN8oEa934VwEVNC3ySoFWNRtUb756RzxEZxVjdvZDa4hndBp6VMd5VZNXZGFTZPRlV8NnUltESiojITJCLiUEMxIjNBlTMiojIIJCL6kTM6EjMwITN0IicfJye#4Xfd5nIJBjMSJiOiMkIsIibvl6cuVGd8VEIgQXZlh6U8VGbGBybtpWaXJiOi8kI1xSfiUTSOFlI0IyQiwiIu3Waz9WZ4hXRgAicldXZpZFdy3GclJFIv5mapdlI0IiTisHL3JyS7gDSiojIDJCLi86bpNnblRHeFBCI73mUpRHb55EIv5mapdlI0IiTisHL3JCNGZDRiojIDJCLi86bpNnblRHeFBCIQFETPBCIv5mapdlI0IiTisHL3JyMDBjQiojIDJCLiUmcvNEIv5mapdlI0IiTisHL3JSV8cTQiojIDJCLi86bpNnblRHeFBCI4JXYoNEbhl6YuFmbpZEIv5mapdlI0IiTis7W0ICZyBlIsICMwMjMxADI4ADMxEjMwIjI0ICdyNkIsIyMwETMxIDMyIiOiAHeFJCLi46bj9CbpFWbnBEOyQ6bvRWY7RWYiVmI0ISYONkIsUWdyRnOiwmdFJCLiETOzYTO8YTM4MTO9YjM8IiOiQWSiwSfdtlOicGbmJCLiMjdxIDMyIiOiIXZcJ5L");


const App = () => (
  <React.StrictMode>
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>

        <Message />
        <AlignLoader />
      </DndProvider>
    </Provider>
  </React.StrictMode>
);

ReactDOM.render(<App />, document.getElementById("root"));
