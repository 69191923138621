import React, { useState } from 'react';
import {
  AreaChart as ReactAreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { CustomXAxisTick, CustomYAxisTick } from '../Custom';
import { fillPalette, formatValue, strokePalette } from '../utils';

interface IProps {
  columns: any;
  data: any;
}

const AreaChart = ({ columns, data }: IProps) => {
  // Extract the LBL and VAL column names from the "columns" variable
  const labelColumn = columns.find((column: any) => column.widgetobjecttypeno === "LBL")?.objectno || "";
  const valueColumns = columns
    .filter((column: any) => column.widgetobjecttypeno === "VAL")
    .map((column: any) => column.objectno);

  const initialOpacityState: any = {};
  valueColumns.forEach((col: any) => {
    initialOpacityState[col] = 1;
  });

  const [opacity, setOpacity] = useState<any>(initialOpacityState);

  const handleMouseEnter = (o: any) => {
    const { dataKey } = o;
    setOpacity({ ...opacity, [dataKey]: 0.8 });
  };

  const handleMouseLeave = (o: any) => {
    const { dataKey } = o;
    setOpacity({ ...opacity, [dataKey]: 1 })
  };

  return (
    <div className="row h-100">
      <div className="col-10 h-100">
        <ResponsiveContainer width="100%" height="100%">
          <ReactAreaChart
            data={data}
            margin={{
              top: 20,
              right: 20,
              left: 20,
              bottom: 60,
            }}
          >
            <CartesianGrid horizontal={true} strokeDasharray="3 3" />
            <XAxis
              dataKey={labelColumn}
              interval={0}
              tick={<CustomXAxisTick />}
            />
            <YAxis
              tick={<CustomYAxisTick />}
            />
            <Tooltip
              formatter={formatValue}
            />
            {
              valueColumns.map((col: any, idx: number) => (
                <Area
                  key={col} // Add a unique "key" prop to avoid warnings
                  type="monotone"
                  dataKey={col}
                  stroke={strokePalette[idx]}
                  fill={fillPalette[idx]}
                  opacity={opacity[col]}
                />
              ))
            }
            <Legend
              layout="horizontal"
              verticalAlign="top"
              align="center"
              wrapperStyle={{
                top: "0px"
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          </ReactAreaChart>
        </ResponsiveContainer>
      </div>
      <div className="col-2 h-100 border-start">
        {
          valueColumns.map((col: string, idx: number) => (
            <div className="count" key={col}>
              <h6 className="count-title" style={{ color: strokePalette[idx] }}>Total {col}</h6>
              <p className="count-value">{formatValue(data.reduce((sum: number, entry: any) => sum + entry[col], 0))}</p>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default AreaChart;