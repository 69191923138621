import { Tab, Table } from 'react-bootstrap';
import { IWorkspaceWidget } from '.';
import { Globalize } from "@grapecity/wijmo";

interface IProps {
  list: IWorkspaceWidget,
  openHyperlink: (screenCode: string, transactionNo: string) => void
}
export const ListContent = ({ list, openHyperlink }: IProps) => (
  <Tab.Pane eventKey={list.widgetName}>
    <Table hover>
      <thead>
        <tr>
          <th></th>
          {
            list.header.map((row: any) => (
              <th key={row.ColumnID}>{row.ColumnText}</th>
            ))
          }
        </tr>
      </thead>
      <tbody>
        {
          list.detail.map((row: any, idx: any) => (
            <tr key={idx}>
              <td></td>
              {
                Object.values(row).map((value: any, idx: any) => {
                  const header: any = list.header.find((x: any, hIdx: any) => hIdx == idx);
                  var _value = value;

                  if (header.controltype.isEqual("DTE")) {
                    if (value == "1900-01-01T00:00:00") {
                      _value = "";
                    }
                    else {
                      _value = Globalize.format(new Date(value), "dd - MMM - yyy");
                    }
                  }
                  else if (header.ColumnID.isEqual("approvalhigheststatus", "approvalstatusno")) {
                    if (value == "Approval In Process") {
                      _value = <span className="bg-info badge">Approval In Process</span>
                    }
                    else if (value == "Approved") {
                      _value = <span className="bg-success badge">Approved</span>
                    }
                    else if (value == "Rejected") {
                      _value = <span className="bg-danger badge">Rejected</span>
                    }
                    else if (value == "Hold") {
                      _value = <span className="bg-warning badge">Hold</span>
                    }
                    else {
                      _value = <span className="bg-primary badge">Not Initiated</span>
                    }
                  }
                  else if (header.controltype.isEqual('IMG')) {
                    _value = <img />;
                    if (value) {
                      var base64String = `data:image/jpeg;base64,${value}`;
                      _value.style.paddingTop = "0px";
                      _value.style.paddingBottom = "0px";
                      _value.style.textAlign = "center";
                      _value.innerHTML = `<img src="${base64String}" class="rounded-circle thumb-xs me-1" />`;
                    }
                    else {
                      _value.style.paddingTop = "0px";
                      _value.style.paddingBottom = "0px";
                      _value.style.textAlign = "center";
                      _value.innerHTML = `<img src="${require("@/Assets/Images/file_icon/no-picture.png").default}" class="rounded-circle thumb-xs me-1" />`;
                    }
                  }
                  else if (header.hyperlinkjump) {
                    _value = <span className="workspace-hyperlink document-link" onClick={() => { openHyperlink(header.hyperlinkjump, value) }}>{value}</span>
                  }

                  return (
                    <td key={idx}>
                      {_value}
                    </td>
                  )
                })
              }
            </tr>
          ))
        }
      </tbody>
    </Table>
  </Tab.Pane>
);