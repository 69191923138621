import { Component } from 'react'
import { connect } from 'react-redux';

import { setGlobalMessage } from "@/Redux/Ducks/messageSlice";
import { IUserLogin, loginUser, getLoginBasicData } from '@/Redux/Ducks/userSlice';
import { AlignUIRender } from './indexUI';
import { RootState } from '@/Redux/configureStore';


class Login extends Component<any> {
  render = () => AlignUIRender(this);
  constructor(props: any) {
    super(props);
    this.login = this.login.bind(this);
    this.updateFieldState = this.updateFieldState.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.navigateToForgotPassword = this.navigateToForgotPassword.bind(this);

    // document.querySelector("dropdown-submenu").click(function (event) {

    //   event.stopPropagation();

    //   $(this).find(".dropdown-submenu").classList.remove("open");

    //   $(this).parents(".dropdown-submenu").classList.add("open");

    //   $(this).classList.toggle("open");
    // });
  }

  componentDidMount() {
    this.props.getLoginBasicData();
  }

  navigateToForgotPassword() {
    const { history } = this.props;
    history.push('/forgot-password')
  }

  componentDidUpdate(prevProps: any) {
    if (!prevProps.clientLanguage.length && this.props.clientLanguage.length) {
      const findValue = this.props.clientLanguage.find((x: any) => x.isdefault);

      if (findValue)
        this.setState({ languageno: findValue.languageno });

    }

  }

  state = {
    username: "",
    password: "",
    languageno: "EN",
    clientno: ""
  }

  login() {
    const loginData: IUserLogin = this.state;
    const queryParams = new URLSearchParams(window.location.search);
    const redirectUrl: any = queryParams.get("redirecturl");

    if (!loginData.username) {
      this.props.setGlobalMessage({ error: Error("Please fill User Name") });
      return;
    }
    if (!loginData.password) {
      this.props.setGlobalMessage({ error: Error("Please fill Password") });
      return;
    }
    if (!loginData.clientno) {
      this.props.setGlobalMessage({ error: Error("Please fill Client Code") });
      return;
    }

    this.props.loginUser({ redirectUrl, ...loginData });
  }
  handleKeyDown(e: any) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.login();
    }
  }

  updateFieldState(fieldId: string, fieldValue: string) {
    this.setState({ [fieldId]: fieldValue });
  }

}

// for type
export { Login };

const mapStateToProps = (state: RootState, ownProps: any) => ({
  clientLanguage: state.User.clientLanguage
})

export default connect(mapStateToProps, {
  setGlobalMessage,
  loginUser,
  getLoginBasicData
})(Login);


//#region Components Props

declare type ComponentProps = DispatchFromProps;

interface DispatchFromProps {
  setGlobalMessage: (object: IUserLogin) => void;
  loginUser: (object: IUserLogin) => void;
}

//#endregion
