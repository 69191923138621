import { IShareDocument } from "@/Redux/Ducks/entryFormSlice";
import { ArrayKeyValueObject, KeyValueObject } from "@/UI/Interfaces/IBaseUI";
import { get, post, put, remove } from "@/Utils/axios";

export function requestGetScreenConfig(processcode: string, transactionNo?: string): any {
  return get(`Screen/GetScreenConfig`, { params: { processcode, transactionNo } });
}


//#region Screen CRUD
export function requestGetDocumentByPrid(processcode: string, prid: number): any {
  return get(`Screen/GetDocumentByPrid/${processcode}/${prid}`);
}
export function requestGetDocumentByDockey(processcode: string, transactionNo: string): any {
  return get(`Screen/GetDocumentByDockey/${processcode}/${transactionNo}`);
}
export function requestSaveDocument(processcode: string, model: any, dimension: any, options: KeyValueObject, approvalrequestmessage: string): any {
  return post(`Screen/SaveDocument`, { processcode, model: JSON.stringify(model), dimension: dimension, approvalrequestmessage }, { headers: options });
}
export function requestReverseDocument(processcode: string, model: any): any {
  return post(`Screen/ReverseDocument`, { processcode, model: JSON.stringify(model) });
}
export function requestDeleteDocument(processcode: string, prid: number): any {
  return post(`Screen/DeleteDocument`, { processcode, prid });
}

export function requestGetDimensionSetData(dimensionset: string[]): any {
  return post(`Screen/GetDimensionSetData`, { dimensionset });
}

//#endregion


export function requestGetAccountDimensions(accountno: string): any {
  return get(`Screen/GetAccountDimensions`, { params: { accountno } });
}

export function requestFieldHelpValidate(helpObjectno: string, helpWhere: string, fieldValue: string, helpTags: any): any {
  return post(`Configuration/FieldHelpValidate`, { helpObjectno, helpWhere, fieldValue, helpTags });
}

export function requestAddComment(originprid: string, originprocesscode: string, commentstxt: string): any {
  return get(`Screen/AddComment`, { params: { originprid, originprocesscode, commentstxt } });
}

export function requestGetCommentsByPrid(originprocesscode: string, originprid: number): any {
  return get(`Screen/GetCommentsByPrid`, { params: { originprocesscode, originprid } });
}

export function requestGetActivityByPrid(objectcontrollerno: string, parameterprid: number): any {
  return get(`Screen/GetActivityByPrid`, { params: { objectcontrollerno, parameterprid } });
}

export function requestReadComment(originprocesscode: string, originprid: number): any {
  return get(`Screen/ReadComments`, { params: { originprocesscode, originprid } });
}

export function requestSaveUserGridConfig(object: KeyValueObject): any {
  return post(`Configuration/SaveUserGridConfiguration`, object);
}

export function requestDeleteUserGridConfig(processcode: string, gridno: string, forcompany: boolean): any {
  return remove(`Configuration/DeleteUserGridConfig`, { params: { processcode, gridno, forcompany } });
}

export function requestSaveUserDocumentHelpFilter(object: KeyValueObject): any {
  return post(`Configuration/SaveUserDocumentHelpFilter`, object);
}

export function requestRemoveUserDocumentHelp(prid: number): any {
  return remove(`Configuration/RemoveUserDocumentHelp`, { params: { prid } });
}

export function requestSetViewDefaultUserDocumentHelp(prid: number, processcode: string, isdefault: boolean): any {
  return put(`Configuration/SetViewDefaultUserDocumentHelp`, { prid, processcode, isdefault });
}
export function requestGetDocumentApprovalDetailStatus(processcode: string, transactionprid: number): any {
  return get(`Configuration/GetDocumentApprovalDetailStatus`, { params: { processcode, transactionprid } });
}
export function requestGetScreenUploadingTemplate(processcode: string): any {
  return get(`Screen/GetScreenUploadingTemplate`, { params: { processcode } });
}
export function requestUploadScreenDataFromTemplate(processcode: string, model: ArrayKeyValueObject, dimension: any, options: KeyValueObject): any {
  return post(`Screen/UploadScreenDataFromTemplate`, { processcode, model: JSON.stringify(model), dimension }, { headers: options });
}

//#region Attachments

export function requestDownloadAttachment(prid: string): any {
  return get(`Configuration/DownloadAttachment`, { params: { prid } });
}

export function requestRemoveAttachment(attachmentIds: string[]): any {
  return remove(`Configuration/RemoveAttachment`, { params: { attachmentIds } });
}

export function requestGetAttachmentFileObject(fileObject: string, filename: string): any {
  return fetch(fileObject).then(r => r.blob()).then(blobFile => new File([blobFile], filename));
}
export function requestSaveAttachment(formData: any, onUploadProgress: any): any {

  return put(`Configuration/SaveAttachment`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress
  });
}
//#endregion

export function requestShareDocument(data: IShareDocument) {
  return post(`Screen/ShareDocument`, data);
}