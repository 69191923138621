import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import { getUserInsights } from "@/Redux/Ducks/dashboardSlice";
import { getHomeUserSpecificData, refreshHomeData } from "@/Redux/Ducks/homeSlice";
import { getNotification } from "@/Redux/Ducks/inboxSlice";
import { addWorkspace, getUserWorkspace } from "@/Redux/Ducks/workspaceSlice";
import { getBannerHidden, getHidden, toggleHidden } from "@/Redux/Ducks/userSlice";
import { useCallback, useEffect, useRef, useState } from "react";
import { AlignUIRender } from "./indexUI";

const Home = () => {
  const dispatch = useAppDispatch();
  const dataLoaded = useAppSelector(state => state.Home.dataLoaded);
  const bannerHidden = useAppSelector(state => state.User.companyBannerHidden);
  const hidden = useAppSelector(state => state.User.hidden);
  const [addWorkspaceModalState, toggleAddWorkspaceModal] = useState<boolean>(false);
  const [workspaceName, setWorkspaceName] = useState<string>('');
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e: any) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    }
    else {
      addWorkspaceFunc();
    }
  };

  const handleWorkspaceNameChange = (e: any) => {
    setWorkspaceName(e.target.value)
  }

  const refreshData = () => {
    dispatch(refreshHomeData())
  }

  const addWorkspaceFunc = () => {
    dispatch(addWorkspace(workspaceName));
    toggleAddWorkspaceModal(false);
  }

  //#region lifecycle methods
  useEffect(() => {
    if (!dataLoaded) {
      dispatch(getHomeUserSpecificData());
      dispatch(getUserInsights());
      dispatch(getNotification({ key: "/approval/active", reload: true, searchParam: '' }));
    }
  }, [dataLoaded]);

  useEffect(() => {
    dispatch(getHidden());
    dispatch(getBannerHidden());
  }, [])

  //#endregion

  const toggleHiddenFunc = (object: string) => {
    dispatch(toggleHidden(object));
  }

  return AlignUIRender({ refreshData, addWorkspaceModalState, toggleAddWorkspaceModal, workspaceName, handleWorkspaceNameChange, addWorkspaceFunc, validated, handleSubmit, hidden, toggleHiddenFunc, bannerHidden });
};

export default Home;
