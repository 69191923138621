import { useAppDispatch, useAppSelector } from '@/Hook/hooks';
import { getScreenConfig } from '@/Redux/Ducks/entryFormSlice';
import { EApprovalStatusType, getNotification, getUserInboxInformation, markImportant, markTrash, setMessageRead, setWorkflowApproval } from '@/Redux/Ducks/inboxSlice';
import { openInputAlert, showValidationMessage } from '@/UI/BaseComponents/Alert';
import { ComboBox } from '@grapecity/wijmo.react.input';
import React, { Component, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { AlignUIRender } from './indexUI';


const Inbox = ({ history, props }: any) => {

  const inboxSearchTimeout = useRef<any>();
  const approvalReasonRef = useRef<any>();
  const [activeMessage, setActiveMessage] = useState<any>();
  const [messageSelectedPrid, setMessageSelectedPrid] = useState<number[]>([]);
  const [inboxSearch, setInboxSearch] = useState<string>("");

  const pathname = history.location.pathname;

  const dispatch = useAppDispatch();
  const inbox = useAppSelector(state => ({
    data: state.Inbox.messages[pathname]?.data || [],
    page: state.Inbox.messages[pathname]?.page || 0,
    total: state.Inbox.messages[pathname]?.total || 0,
    dataLoading: state.Inbox.messages[pathname]?.dataLoading || false,
    userInboxInformationLoaded: state.Inbox.dataLoaded,
    totalUnreadCount: state.Inbox.unReadCount,
    userFolders: state.Inbox.folders,
    currentUserId: state.User.user.username
  }));

  useEffect(() => {
    if (history.location.state?.activeMessage) {
      openMessage(history.location.state.activeMessage);
      history.replace(history.location.pathname, {});
    }
  }, []);

  useEffect(() => {
    if (!inbox.data.length) {
      loadData(true);
    }
    if (!inbox.userInboxInformationLoaded)
      dispatch(getUserInboxInformation());
  }, [pathname]);

  useEffect(() => {
    if (inbox.data.length > 0 && !activeMessage) {
      setActiveMessage(inbox.data[0]);
    }
  }, [inbox.data]);

  useEffect(() => {

    document.getElementById("inbox-list")?.addEventListener('scroll', trackScrolling);
    if (!inbox.dataLoading) {
      const inbox = document.getElementById("inbox-list");
      if (inbox) {
        if (inbox.scrollHeight != inbox.clientHeight && (inbox.scrollHeight - inbox.clientHeight) <= 50) {
          loadData(false);
        }
      }
    }
    return function cleanup() {
      document.getElementById("inbox-list")?.removeEventListener('scroll', trackScrolling);
    };

  }, [inbox.dataLoading, !!activeMessage]);

  useEffect(() => {
    if (inboxSearchTimeout.current)
      clearTimeout(inboxSearchTimeout.current);

    inboxSearchTimeout.current = setTimeout(() => {
      loadData(true);
    }, 1000);
  }, [inboxSearch]);


  const handleSearchChange = (e: any) => {
    setInboxSearch(e.target.value);
  }

  const trackScrolling = (ev: any) => {
    if (ev.target.scrollTop != 0 && (ev.target.scrollHeight - ev.target.scrollTop - 100) <= ev.target.clientHeight) {
      if (!inbox.dataLoading) {
        loadData(false);
      }
    }
  };

  const loadData = (reload: boolean) => {
    dispatch(getNotification({ key: pathname, reload, searchParam: inboxSearch }));
  }

  const openMessage = (message: any) => {
    setActiveMessage(message);
    setMessageSelectedPrid([]);

    if (!pathname.isEqual("/outbox"))
      readMessage(true, message);
  }

  const closeMessage = () => {
    setActiveMessage(null);
  }

  const setApproval = async (approvalResponse: EApprovalStatusType, messageObject: any) => {
    let reason = "";

    if (approvalResponse.isEqual(EApprovalStatusType.Hold, EApprovalStatusType.Reject)) {

      const reasonFieldConfig = {
        fieldid: "approvalcomment",
        fieldlabel: "Comment",
      };

      const preConfirm = () => {
        if (!approvalReasonRef.current.value) {
          showValidationMessage("Please fill the comment");
        }
        else {
          return approvalReasonRef.current.value;
        }
      }

      const res = await openInputAlert<ReturnType<typeof preConfirm>>({
        title: "Please enter comments",
        extraOption: {
          input: undefined,
          preConfirm,
          html:
            <div className="field-container" data-fieldid={reasonFieldConfig.fieldid}>
              <label id={`lb-${reasonFieldConfig.fieldid}`} htmlFor={`field-${reasonFieldConfig.fieldid}`} >
                {reasonFieldConfig.fieldlabel}
                <span>*</span>
              </label>
              <textarea
                ref={approvalReasonRef}
                className="field-textarea"
                rows={6} cols={35}
                id={`field-${reasonFieldConfig.fieldid}`}
              />
            </div>
        }
      });
      if (res.cancel)
        return;

      reason = res.value;
    }
    dispatch(setWorkflowApproval({ approvalResponse, approvalprid: messageObject.refnoprid, reason, messagePrid: messageObject.prid, key: pathname }));
    setActiveMessage((prevState: any) => ({ ...prevState, actionable: false }));
  }

  const readMessage = (isRead: boolean, messageObject: any) => {
    dispatch(setMessageRead({ isRead, messagePrid: messageObject.prid, key: pathname }));
    setActiveMessage((prevState: any) => ({ ...prevState, viewied: isRead }));
  }

  const markAsImportant = () => {
    dispatch(markImportant({ messagePrid: activeMessage.prid, key: pathname }));
  }

  const markAsTrash = () => {
    dispatch(markTrash({ messagePrid: activeMessage.prid, key: pathname }));
    closeMessage();
  }

  const selectMessage = (checked: boolean, prid: number) => {
    if (checked)
      setMessageSelectedPrid((prevState: any) => ([...prevState, prid]));
    else
      setMessageSelectedPrid((prevState: any) => (prevState.filter((x: any) => x != prid)));
  }

  const contentClickHandler = (e: any) => {
    const targetLink = e.target.closest('a');
    if (!targetLink) return;
    e.preventDefault();

    let routingLink = targetLink.href;
    const baseLink = location.origin + "/";

    if (routingLink && routingLink.includes(baseLink)) {
      routingLink = routingLink.replace(baseLink, "");
      if (routingLink.includes("openscreen?")) {
        routingLink = routingLink.replace("openscreen", "");
        const urlSearchParams = new URLSearchParams(routingLink);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (params.processcode && params.transactionno) {
          dispatch(getScreenConfig({ processCode: params.processcode, transactionNo: params.transactionno }));
        }
      }

      // history.push(routingLink);
    }
  };

  const openTransactionDocument = (processcode: string, transactionno: string) => {
    dispatch(getScreenConfig({ processCode: processcode, transactionNo: transactionno }));
  }

  const changeInboxMenu = (route: string) => {
    closeMessage();
    history.push(`/${route}`);
  }

  return AlignUIRender({ contentClickHandler, openTransactionDocument, markAsTrash, inbox, handleSearchChange, inboxSearch, markAsImportant, closeMessage, readMessage, openMessage, loadData, changeInboxMenu, activeMessage, setApproval, activeSideMenu: pathname, selectMessage, messageSelectedPrid });

}

export default Inbox;