import { ENotificationType } from "@/Redux/Ducks/inboxSlice";
import { ArrayKeyValueObject, KeyValueObject } from "@/UI/Interfaces/IBaseUI";
import { constant } from "@/Utils/constants";
import BaseMethods from "AlignBaseUI/BaseMethods";
import { CardGroup, Card, Table, Badge, Nav, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import TimeAgo from 'react-timeago';
import { ControlTranslation, Icon, Modal } from "AlignComponents"
import { AiOutlineSend } from "react-icons/ai";
import { FiSend } from "react-icons/fi";
import { BiHide, BiMailSend, BiMessageSquareCheck, BiTask } from "react-icons/bi";
import { MdOutlineSendToMobile, MdOutlineTaskAlt } from "react-icons/md";
import { IoMdNotificationsOutline } from "react-icons/io";
import { BsBookmarkCheck } from "react-icons/bs";
import { TiMessages } from "react-icons/ti";

interface IProps {
  userShortWidget: any,
  history: any,
  handleTaskModalClose: () => void,
  handleTaskModalOpen: () => void,
  taskModalOpen: boolean,
  userFrequentlyUsed: any,
  homeSettingObject: {
    inbox: ArrayKeyValueObject;
    inboxLoaded: boolean;
    dataLoaded: boolean;
  },
  user: string,
  routeToScreen: (screenCode: string, transactionNo?: string) => void
  openMessage: (message: any) => void,
  hidden: Array<string>,
  toggleHiddenFunc: (object: string) => void
}

export const AlignUIRender = ({ homeSettingObject, user, routeToScreen, history, userShortWidget, handleTaskModalClose, handleTaskModalOpen, taskModalOpen, userFrequentlyUsed, openMessage, hidden, toggleHiddenFunc }: IProps) => (
  <Col md="6" className="mt-2">
    <Card className="active-approvals">
      <Card.Header>
        <ControlTranslation system={true} controlId="lblmyactiveapprovals" />

        <Nav.Link className="view-all" tabIndex={1} role="button" onClick={() => history.push("approval/active")}><ControlTranslation system={true} controlId="lblviewall" /></Nav.Link>
      </Card.Header>
      <Card.Body className="overflow-auto p-0 text-nowrap" style={{ height: '400px' }}>
        {
          homeSettingObject.inboxLoaded ?
            homeSettingObject.inbox.length ?

              <Table hover>
                <thead>
                  <tr className="position-sticky top-0">
                    <th><ControlTranslation system={true} controlId="lblrequester" /></th>
                    {/* <th>Type</th> */}
                    <th><ControlTranslation system={true} controlId="lblprocess" /></th>
                    <th><ControlTranslation system={true} controlId="lbldocumentno" /></th>
                    <th><ControlTranslation system={true} controlId="lblrequesteddate" /></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    homeSettingObject.inbox.map((x: any, idx: number) => (
                      <tr key={idx} onClick={(e: any) => { openMessage(x) }} role="button">
                        <td>
                          <div className="d-flex align-items-center mb-0">
                            <img src={BaseMethods.getUserPhoto(x.userphoto)} alt="" className="rounded-circle thumb-xs me-1" />
                            <div className="d-flex flex-column">
                              <p className="fw-bold m-0">
                                {x.messageuserfullname}
                              </p>
                              <p className="subject">
                                {x.subject}
                              </p>
                            </div>
                            <br />
                          </div>
                        </td>
                        <td>
                          {x.processstxt || "-"}
                        </td>
                        <td>
                          {x.parameters ? x.parameters.transactionno : "-"}
                          <Icon icon="ExternalLink" size="16" className="ms-2" onClick={(e) => { e.stopPropagation(); routeToScreen(x.parameters.processcode, x.parameters.transactionno) }} />
                        </td>
                        <td>
                          {BaseMethods.convertDateToCompanyFormat(BaseMethods.convertUTCDateToLocal(x.credate).toString(), constant.DefaultDateFormat)}
                          <br />
                          <TimeAgo date={BaseMethods.convertUTCDateToLocal(x.credate).toString()} />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
              :
              <div className="not-found">
                <img src={require("@/Assets/Images/not-found.png").default} alt="" />
                <p className="mb-0"><ControlTranslation system={true} controlId="lblnothingfound" /></p>
                <span><ControlTranslation system={true} controlId="lblyouhavenomailtoshow" /></span>
              </div>
            :
            <p className="text-center text-dark"><ControlTranslation system={true} controlId="lblloading" /> ...</p>
        }

      </Card.Body>
    </Card>
  </Col>
);
