import { useAppSelector } from '@/Hook/hooks';
import { IFieldProps } from '@/UI/Interfaces/IComponent';
import { constant } from "@/Utils/constants";
import * as wjInput from '@grapecity/wijmo.react.input';
import { useEffect, useRef, useState } from 'react';

const defaultDate = "1900-01-01T00:00:00";

const Field = ({ config, fieldValueChange, uniqueKey, enable, value, validateMandatory, hideLabel }: IFieldProps) => {
  const { fieldid } = config;
  const currentValue = useRef<string | null>();
  // let settingdate = new Date();

  const [fieldIsInEditing, setFieldIsInEditing] = useState<boolean>(false);

  const objectId = useAppSelector((x) => x.EntryForm.activeObjectId);
  const dataFormat = useAppSelector(state => {
    let dataFormat = constant.DefaultDateFormat;

    state.User.CompanyDataFormat.map(x => {
      if (x.fielddataformatno == config.fielddataformatno)
        dataFormat = x.fielddataformatmasking;
    });

    if (state.EntryForm.objects?.[state.Tab.tabs?.[0]?.tabActualKey]) {
      const ProcessCodeDataFormat = state.EntryForm.objects[state.Tab.tabs[0].tabActualKey].ScreenConfig.ProcessCodeApplicationDataFormat;
      const finddataFormat = ProcessCodeDataFormat.find(x => x.fielddataformatno == config.fielddataformatno);
      if (finddataFormat != null)
        dataFormat = finddataFormat.fielddataformatmasking;
    }

    return dataFormat;
  });

  useEffect(() => {
    if (value === undefined) {
      let settingdate = null;
      if (validateMandatory) {
        settingdate = new Date();
        settingdate.setHours(0, 0, 0, 0);
      }

      callBaseMethod(settingdate);
    }
    else if (value === null || new Date(value).getTime() === new Date(defaultDate).getTime()) {
      if (validateMandatory) {
        let settingdate = new Date();
        settingdate.setHours(0, 0, 0, 0);
        callBaseMethod(settingdate);
      }
    }
  }, [value, validateMandatory]);

  const callBaseMethod = (value: Date | null) => {
    let dateValue = defaultDate;
    if (value) {
      const d = new Date(value);
      dateValue = `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${("0" + d.getDate()).slice(-2)}T00:00:00`;
    }

    fieldValueChange(fieldid, dateValue);
  }

  const focus = (e: any) => {
    currentValue.current = e.value ? e.value.toJSON() : null;

    setFieldIsInEditing(true);
  }

  const blur = (e: any) => {

    if (e.value !== value) {
      callBaseMethod(e.value);
    }

    currentValue.current = null;
    setFieldIsInEditing(false);
  }

  if (!config) return <></>;
  const id = objectId + (uniqueKey ? `-${uniqueKey}` : "") + "-" + fieldid;

  const dateCurrentValue = fieldIsInEditing ? currentValue.current : value;

  let dateValue = null;

  if (dateCurrentValue && new Date(dateCurrentValue).getTime() != new Date(defaultDate).getTime())
    dateValue = new Date(dateCurrentValue);

  return (
    <>
      {
        !hideLabel ?
          <label id={"lb-" + id} htmlFor={"field-" + id}>
            {config.fieldlabel}
            {validateMandatory ? (<span>*</span>) : null}
          </label>
          : null
      }
      <div className="input-date">
        <wjInput.InputDate gotFocus={focus} isRequired={validateMandatory} lostFocus={blur} isDisabled={!enable} format={dataFormat} id={"field-" + id} value={dateValue}>
        </wjInput.InputDate>
      </div>
    </>
  )

}
export default Field;
