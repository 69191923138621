import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import { addWorkspace, deleteWorkspace, updateWorkspace } from "@/Redux/Ducks/workspaceSlice";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { AlignUIRender } from "./indexUI";

const Workspace = () => {
  const dispatch = useAppDispatch();
  const userWorkspace = useAppSelector(x => x.Workspace.userWorkspace);
  const history = useHistory();
  const [workspaceNo, setWorkspaceNo] = useState<number>(0);
  const [workspaceName, setWorkspaceName] = useState<string>('');
  const [updateWorkspaceModalState, toggleUpdateWorkspaceModal] = useState<boolean>(false);
  const [addWorkspaceModalState, toggleAddWorkspaceModal] = useState<boolean>(false);
  const [updateWorkspaceValidated, setUpdateWorkspaceValidated] = useState<boolean>(false);
  const [addWorkspaceValidated, setAddWorkspaceValidated] = useState<boolean>(false);

  const routeToWorkspace = (evt: any, workspaceNo: number) => {
    if (!evt.target.contains(`workspace-dropdown-${workspaceNo}`))
      history.push(`workspace/${workspaceNo}`)
  }

  const resetState = () => {
    setWorkspaceNo(0);
    setWorkspaceName("");
  }

  const openUpdateWorkspace = (workspaceNo: number, workspaceName: string) => {
    setWorkspaceNo(workspaceNo);
    setWorkspaceName(workspaceName);
    toggleUpdateWorkspaceModal(true);
  }

  const openAddWorkspace = () => {
    resetState();
    toggleAddWorkspaceModal(true);
  }

  const closeUpdateWorkspace = () => {
    toggleUpdateWorkspaceModal(false);
  }

  const closeAddWorkspace = () => {
    toggleAddWorkspaceModal(false);
  }

  const updateWorkspaceFunc = () => {
    dispatch(updateWorkspace({ workspaceNo, workspaceName }));
    toggleUpdateWorkspaceModal(false);
    resetState();
  }

  const addWorkspaceFunc = () => {
    dispatch(addWorkspace(workspaceName));
    toggleAddWorkspaceModal(false);
  }

  const handleUpdateSubmit = (e: any) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setUpdateWorkspaceValidated(true);
    }
    else {
      updateWorkspaceFunc();
    }
  };

  const handleAddSubmit = (e: any) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setAddWorkspaceValidated(true);
    }
    else {
      addWorkspaceFunc();
    }
  };

  const handleWorkspaceNameChange = (e: any) => {
    setWorkspaceName(e.target.value);
  }

  const deleteWorkspaceFunc = (workspaceNo: number) => {
    dispatch(deleteWorkspace(workspaceNo));
  }

  return AlignUIRender({ userWorkspace, routeToWorkspace, openUpdateWorkspace, openAddWorkspace, closeUpdateWorkspace, closeAddWorkspace, updateWorkspaceModalState, addWorkspaceModalState, updateWorkspaceValidated, addWorkspaceValidated, handleUpdateSubmit, handleAddSubmit, workspaceName, handleWorkspaceNameChange, deleteWorkspaceFunc });
};

export default Workspace;
