import React, { useState } from 'react';
import {
  PieChart as ReactPieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { formatValue, fillPalette } from '../utils'; // Assuming you have the required utils

interface IProps {
  columns: any;
  data: any;
  type: 'pie' | 'donut'; // Define the type prop as either 'pie' or 'donut';
}

const PieChart = ({ columns, data, type }: IProps) => {
  const labelColumn = columns.find((column: any) => column.widgetobjecttypeno === "LBL")?.objectno || "";
  const valueColumn = columns.find((column: any) => column.widgetobjecttypeno === "VAL")?.objectno || "";

  // Determine the innerRadius based on the type prop
  const innerRadius = type === 'donut' ? 60 : 0;

  const pieData = data.map((line: any) => {
    return {
      name: line[labelColumn],
      value: line[valueColumn]
    }
  })

  // const initialOpacityState: any = {};
  // data.forEach((line: any) => {
  //   initialOpacityState[line[labelColumn]] = 1;
  // });

  // const [opacity, setOpacity] = useState<any>(initialOpacityState);

  // const handleMouseEnter = (o: any) => {
  //   const { value } = o;
  //   setOpacity({ ...opacity, [value]: 0.5 });
  // };

  // const handleMouseLeave = (o: any) => {
  //   const { value } = o;
  //   setOpacity({ ...opacity, [value]: 1 })
  // };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ReactPieChart>
        <Pie
          data={pieData}
          dataKey="value"
          fill="#8884d8"
          // label={({ percent }) => `${(percent * 100).toFixed(2)}%`}
          innerRadius={innerRadius}
        >
          {pieData.map((line: any, index: any) => (
            <Cell
              key={`cell-${index}`}
              fill={fillPalette[index % fillPalette.length]}
            // opacity={opacity[line.name]}
            />
          ))}
        </Pie>
        <Tooltip formatter={formatValue} />
        <Legend
          layout="vertical"
          align="right"
          verticalAlign="middle"
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
        />
      </ReactPieChart>
    </ResponsiveContainer>
  );
};

export default PieChart;
