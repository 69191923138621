import { useAppDispatch } from '@/Hook/hooks';
import { KeyValueObject } from '@/UI/Interfaces/IBaseUI';
import { useRef, useState, useEffect } from 'react';
import { AlignUIRender } from './indexUI';


const Chart = ({ title, chartType, data, routeToScreen, widget }: any) => {
	// var dispatch = useAppDispatch();

	// const inputRef = useRef(new Array());
	// const chartRef = useRef(null);

	// var formatter = widget.widgetcontroltype.isEqual("PIE", 'DNT') ? (val: any, opts: any) => val.toPrecision(3) + "%" : undefined;
	// var dataLabelsEnabled = widget.widgetcontroltype.isEqual("PIE", 'DNT') ? true : false;
	// var initialState: IChartConf = {
	// 	name: widgetlabel,
	// 	type: 'bar',
	// 	width: "100%",
	// 	filter: false,
	// 	filterApplied: false,
	// 	filterColumns: widget.columns.filter((x: any) => x.filter).map((x: any) => x.objectno),
	// 	loaded: false,
	// 	data: widget.data,
	// 	structuredData: widget.data,
	// 	options: {
	// 		dataLabels: {
	// 			enabled: dataLabelsEnabled,
	// 			formatter: formatter
	// 		},
	// 		chart: {
	// 			id: widget.widgetno,
	// 			toolbar: {
	// 				show: false
	// 			},
	// 			zoom: {
	// 				enabled: false
	// 			}
	// 		},
	// 		xaxis: {
	// 			categories: [],
	// 			tickPlacement: 'on',
	// 		},
	// 		yaxis: {

	// 		},
	// 		plotOptions: {
	// 		},
	// 		stroke: { curve: 'smooth' }
	// 	},
	// 	series: []
	// }

	// const [chartConf, setChartConf] = useState<IChartConf>(initialState);

	// function _delete(obj: KeyValueObject, prop: any) {
	// 	if (obj[prop] && !obj[prop].length) delete obj[prop];
	// }

	// //#region lifecycle methods 
	// useEffect(() => {
	// 	var columns = widget.columns.filter((x: any) => !x.filter);

	// 	if (chartConf.filterColumns.length >= 1) {
	// 		//removing filter columns from data and restructurizing it
	// 		var structuredData = widget.data.map((row: KeyValueObject) => {
	// 			chartConf.filterColumns.forEach((objectno: string) => {
	// 				_delete(row, objectno)
	// 			})
	// 			return row;
	// 		})

	// 		// getting widgetobjecttype LBL & VAL for restructurizing
	// 		var LBLCol = widget.columns.filter((x: KeyValueObject) => x.widgetobjecttypeno == "LBL")[0].objectno;
	// 		var VALCol = widget.columns.filter((x: KeyValueObject) => x.widgetobjecttypeno == "VAL").map((x: KeyValueObject) => x.objectno);

	// 		// main structurizing
	// 		structuredData = Object.entries(structuredData.groupBy(LBLCol)).map((x: any) => {
	// 			var row: any = {};
	// 			row[LBLCol] = x[0];

	// 			VALCol.forEach((objectno: string) => {
	// 				var value = x[1].map((x: KeyValueObject) => x[objectno]).reduce((a: number, b: number) => a + b)
	// 				return row[objectno] = value;
	// 			})

	// 			return row;
	// 		})

	// 		var _chartConf = chartConf;
	// 		_chartConf.structuredData = structuredData;
	// 		_chartConf.filter = true;

	// 		setChartConf(_chartConf)
	// 	}

	// 	switch (chartType) {
	// 		case "PIE":
	// 			var _chartConf = chartConf;
	// 			_chartConf.type = 'pie';
	// 			setChartConf(_chartConf)
	// 			break;
	// 		case "DNT":
	// 			var _chartConf = chartConf;
	// 			_chartConf.type = 'donut';
	// 			setChartConf(_chartConf)
	// 			break;
	// 		case "ARA":
	// 			var _chartConf = chartConf;
	// 			_chartConf.type = 'area';
	// 			setChartConf(_chartConf)
	// 			break;
	// 		case "COL":
	// 			{
	// 				var _chartConf = chartConf;
	// 				_chartConf.type = 'bar';
	// 				_chartConf.options.plotOptions = {
	// 					bar: {
	// 						columnWidth: "40%"
	// 					}
	// 				}
	// 				if (_chartConf.options?.yaxis)
	// 					(_chartConf.options.yaxis as ApexYAxis).labels = {
	// 						formatter: function (value) {
	// 							return Number(value).toLocaleString();
	// 						}
	// 					};

	// 				_chartConf.options.tooltip = {
	// 					y: {
	// 						formatter: function (value) {
	// 							return Number(value).toLocaleString();
	// 						}
	// 					}
	// 				};
	// 			}
	// 			break;
	// 		case "LNE":
	// 			{
	// 				var _chartConf = chartConf;
	// 				_chartConf.type = 'line';
	// 				if (_chartConf.options?.yaxis)
	// 					(_chartConf.options.yaxis as ApexYAxis).labels = {
	// 						formatter: function (value) {
	// 							return Number(value).toLocaleString();
	// 						}
	// 					};

	// 				_chartConf.options.tooltip = {
	// 					y: {
	// 						formatter: function (value) {
	// 							return Number(value).toLocaleString();
	// 						}
	// 					}
	// 				};
	// 			}
	// 			break;
	// 		case "BAR":
	// 			{
	// 				var _chartConf = chartConf;
	// 				_chartConf.type = 'bar';
	// 				_chartConf.options.plotOptions = {
	// 					bar: {
	// 						horizontal: true,
	// 						columnWidth: "40%"
	// 					}
	// 				};
	// 				if (_chartConf.options?.xaxis)
	// 					_chartConf.options.xaxis.labels = {
	// 						formatter: function (value) {
	// 							return Number(value).toLocaleString();
	// 						}
	// 					};

	// 				_chartConf.options.tooltip = {
	// 					y: {
	// 						formatter: function (value) {
	// 							return Number(value).toLocaleString();
	// 						}
	// 					}
	// 				};
	// 			}
	// 			break;
	// 	}

	// 	columns.forEach((x: any) => {
	// 		if (x.widgetobjecttypeno.isEqual('LBL')) {
	// 			var labels = chartConf.structuredData.map((y: any) => {
	// 				return y[x.objectno];
	// 			})
	// 			console.log(labels)
	// 			var _chartConf = chartConf;
	// 			if (_chartConf.options.xaxis) {
	// 				if (widget.widgetcontroltype.isEqual("PIE", 'DNT')) {
	// 					_chartConf.options.labels = labels;
	// 				}
	// 				else {
	// 					_chartConf.options.xaxis.categories = labels;
	// 				}
	// 				setChartConf(_chartConf)
	// 			}
	// 		}
	// 		else if (x.widgetobjecttypeno.isEqual('VAL')) {
	// 			var values = chartConf.structuredData.map((y: any) => {
	// 				return y[x.objectno];
	// 			})
	// 			var _chartConf = chartConf;
	// 			if (widget.widgetcontroltype.isEqual("PIE", 'DNT')) {
	// 				_chartConf.series = values;
	// 			}
	// 			else {
	// 				_chartConf.series.push({ name: x.objectno, data: values });
	// 			}
	// 			setChartConf(chartConf)
	// 		}

	// 		setChartConf({ ...chartConf, loaded: true })
	// 	})

	// }, [])
	// //#endregion

	const hyperlinkHandler = () => {
		routeToScreen(widget.widgetprocesscode)
	} 

	return AlignUIRender({ title, chartType, widgetprocesscode: widget.widgetprocesscode, data, columns: widget.columns, hyperlinkHandler });
}

export default Chart;