import { Viewer, OpenFile, Worker } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { SelectionMode } from "@react-pdf-viewer/selection-mode";

import BaseMethods from "AlignBaseUI/BaseMethods";
import { Accordion, AlignInput, Icon, Workspace } from "AlignComponents";
import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import { useEffect, useRef, useState } from "react";
import {
  getReportConfig,
  setReportModelValue,
  loadReport,
  setFieldFilter,
  toggleCriteria
} from "@/Redux/Ducks/reportExplorerSlice";
import { ToolbarSlot } from "@react-pdf-viewer/default-layout";
// import { requestGetReport } from '@/Redux/Sagas/Requests/reportExplorer';
import { closeTab } from "@/Redux/Ducks/tabSlice";
import { useHistory } from "react-router-dom";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { EHelpOpenIn, IReturnParam, IReturnValues } from "@/Redux/Ducks/helpSlice";
import { ICriteriaFieldConfig } from "@/UI/Interfaces/IFieldConfig";
import classNames from "classnames";
import { filterOption } from "@/Utils/constants";
import { Breadcrumb, Button, ButtonGroup, Dropdown, DropdownButton, OverlayTrigger, Tooltip } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import { MdDownloading, MdImportExport } from "react-icons/md";
import { RiFilePaper2Line } from "react-icons/ri";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { setUserFavorite } from "@/Redux/Ducks/homeSlice";
import { setGlobalMessage } from "@/Redux/Ducks/messageSlice";

const ReportExplorer = (props: any) => {

  let { routeTo, modalReport } = props;
  if (!routeTo)
    routeTo = props.match.params.routeTo;

  const loadMenuRef = useRef<any>();
  let history = useHistory();

  const dispatch = useAppDispatch();
  // const [isCriteriaOpen, toggleCriteria] = useState(true);
  const [loadReportExplorerForce, setLoadReportExplorerForce] = useState<boolean>(false);
  const settingSaveTimeout = useRef<any>(null);


  const reportObject = useAppSelector((state) => state.ReportExplorer.reportDetailObject[routeTo]);
  // const activeTabKey = useAppSelector((state) => state.Tab.activeTabKey) || "";

  useEffect(() => {
    if (!reportObject)
      dispatch(getReportConfig({ reportObjectno: routeTo }));
  }, []);

  useEffect(() => {

    if (settingSaveTimeout.current)
      clearTimeout(settingSaveTimeout.current);
    if (loadReportExplorerForce)
      setLoadReportExplorerForce(false);
    setTimeout(() => {
      setLoadReportExplorerForce(true);
    }, 160);

  }, [history.location.pathname]);

  const toolbarPluginInstance = toolbarPlugin({
    getFilePlugin: {
      fileNameGenerator: (file: OpenFile) => {

        let fileName = reportObject.objectConfiguration.processcodestxt;

        const transactionfieldObject = reportObject.criteria.find(x => x.transactionfield);
        if (transactionfieldObject) {
          if (!reportObject.previousLoadedCriteriaValue[transactionfieldObject.fieldid].toValue) {
            const transactionno = reportObject.previousLoadedCriteriaValue[transactionfieldObject.fieldid].fromValue;
            if (transactionno && !transactionno.includes(","))
              fileName += `-${transactionno}`;
          }
        }

        return `${fileName}`;
      },
    },
    selectionModePlugin: {
      selectionMode: SelectionMode.Text,
    },
  });

  const { Toolbar } = toolbarPluginInstance;

  const base64toBlob = (data: string) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data;

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: "application/pdf" });
  };

  const BaseFieldStateSet = (fieldId: string, newValue: any, helpSelected: boolean = false, toValue: boolean = false) => {
    dispatch(setReportModelValue({ fieldId, newValue, toValue }));
  };

  const BaseSetFieldFilter = (fieldId: string, filterType: string, caseSensitive: boolean) => {
    dispatch(setFieldFilter({ fieldId, filterType, caseSensitive }));
    document.querySelectorAll(`.filter-container .dropdown-menu`).forEach((x) => x.classList.remove("show"))
  };

  const loadReportFunc = (requiredLetterHead: boolean, exportExcel: boolean) => {

    reportObject.criteria.map(x => {
      if (x.validatemandatory) {
        if (!reportObject.modelValue[x.fieldid]) {
          dispatch(setGlobalMessage({ error: new Error(`Please fill Mandatory field ${x.fieldlabel}`) }));
          throw null;
        }
      }
    });

    dispatch(loadReport({ reportObjectno: reportObject.objectConfiguration.reportobjectno, requiredLetterHead, exportExcel }));

    if (!exportExcel) {
      if (reportObject.isCriteriaOpen)
        dispatch(toggleCriteria({ reportObjectno: routeTo }));
    }

  };

  const closeReportExplorer = () => {
    dispatch(closeTab(routeTo));
  }

  const criteriaField = (field: ICriteriaFieldConfig, index: number) => {
    const fieldModel = reportObject.modelValue[field.fieldid];
    return (
      <div className="d-flex" key={field.fieldid}>
        <AlignInput
          fieldId={field.fieldid}
          config={{ FieldConfig: [field], BaseFieldStateSet }}
          customFieldValue={fieldModel.fromValue || null}
          customFieldVisible={true}
          customFieldEnable={true}
          customFieldHelpObject={field.helpobjectno || ""}
          customFieldHelpWhere={field.helpwhere || ""}
          customValidateMandatory={field.validatemandatory}
          customHyperLinkJump={field.hyperlinkjump || ""}
          custom={true}
          uniqueKey="from"
          customHelpParams={{
            helpOpenIn: EHelpOpenIn.ReportExplorer,
            multiSelect: field.multiplevalue
          }}
        />
        {
          field.filteroption ?
            <div className="filter-container">
              <DropdownButton
                variant="filter"
                as={ButtonGroup}
                drop="end"
                title={<Icon icon="Filter" size="14" color="#575757" className="mx-1" />}
                onClick={() => {
                  document.querySelectorAll(`.filter-container .dropdown-menu:not(#filter-option-${field.fieldid})`).forEach((x) => x.classList.remove("show"))
                  document.getElementById(`filter-option-${field.fieldid}`)?.classList.toggle("show")
                }}>
                {
                  filterOption.filter(x => !x.filterSymbol.isEqual("cs") && x.fieldType.includes(BaseMethods.getControlType(field.controltypeno))).map((x, idx) => (
                    <Dropdown.Item
                      key={x.filterSymbol}
                      className={classNames({ active: x.filterSymbol == fieldModel.filterType })}
                      role="button"
                      onClick={() => BaseSetFieldFilter(field.fieldid, x.filterSymbol, fieldModel.caseSensitive)}
                    >
                      {x.filterText}
                    </Dropdown.Item>
                  ))
                }
                <Dropdown.Divider />
                {
                  filterOption.filter(x => x.filterSymbol.isEqual("cs") && x.fieldType.includes(BaseMethods.getControlType(field.controltypeno))).map((x, idx) => (
                    <Dropdown.Item
                      key={x.filterSymbol}
                      className={classNames({ active: fieldModel.caseSensitive })}
                      role="button"
                      onClick={() => BaseSetFieldFilter(field.fieldid, fieldModel.filterType, !fieldModel.caseSensitive)}
                    >
                      {x.filterText}
                    </Dropdown.Item>
                  ))
                }
              </DropdownButton>
            </div>
            : null
        }

        <span className="to-value">
          {
            fieldModel.filterType.isEqual("not between", "between") ?
              <AlignInput
                key={`to-${field.fieldid}`}
                fieldId={field.fieldid}
                config={{ FieldConfig: [field], BaseFieldStateSet: (fieldId: string, newValue: any, helpSelected: boolean) => BaseFieldStateSet(fieldId, newValue, helpSelected, true) }}
                customFieldValue={fieldModel.toValue || null}
                customFieldVisible={true}
                customFieldEnable={true}
                customFieldHelpObject={field.helpobjectno || ""}
                customFieldHelpWhere={field.helpwhere || ""}
                customValidateMandatory={field.validatemandatory}
                customHyperLinkJump={field.hyperlinkjump || ""}
                custom={true}
                uniqueKey="to"
                customHelpParams={{
                  helpOpenIn: EHelpOpenIn.ReportExplorer
                }}
              />
              : null
          }
        </span>


      </div>
    );
  }

  const toggleCriteriaFunc = () => {
    dispatch(toggleCriteria({ reportObjectno: routeTo }));
  }

  // favorite
  const BaseSetUserFavorite = (objectcontrollerno: string, objectcontrollerstxt: string, applicationobjecttype: string) => {
    dispatch(setUserFavorite({ objectcontrollerno, objectcontrollerstxt, applicationobjecttype }))
  }

  if (reportObject && loadReportExplorerForce)
    return (
      <div className={classNames(["report-detail", { "modalReport": modalReport }])}>
        {
          !modalReport ?
            <>
              <div className="screen-action-panel">
                <Breadcrumb>
                  <Breadcrumb.Item>Report Explorer</Breadcrumb.Item>
                  <Breadcrumb.Item>{reportObject.objectConfiguration.modulestxt}</Breadcrumb.Item>
                  <Breadcrumb.Item>{reportObject.objectConfiguration.applicationobjectcategorystxt}</Breadcrumb.Item>
                  <Breadcrumb.Item className="active">{reportObject.objectConfiguration.processcodestxt}</Breadcrumb.Item>
                </Breadcrumb>
                <Button variant="primary" className="ms-auto" onClick={() => { loadReportFunc(false, false) }}>
                  <MdDownloading className="me-2" size="16" />
                  Load
                </Button>
                <div className="divider"></div>
                <OverlayTrigger placement="top" overlay={<Tooltip>Add to Favorite</Tooltip>}>
                  <Button variant="secondary" onClick={() => BaseSetUserFavorite(reportObject.objectConfiguration.processcode, reportObject.objectConfiguration.processcodestxt, reportObject.objectConfiguration.applicationobjecttype)} className="me-2">
                    {
                      reportObject.objectConfiguration.isfavorite ? (
                        <AiFillStar size="16" />
                      ) : (
                        <AiOutlineStar size="16" />
                      )
                    }
                  </Button>
                </OverlayTrigger>
                {
                  reportObject.requiredLetterHead ?
                    <Button variant="secondary" onClick={() => { loadReportFunc(true, false) }} className="me-2">
                      <RiFilePaper2Line className="me-2" size="16" />
                      Load Letterhead
                    </Button>
                    : null
                }
                <Button variant="secondary" onClick={() => { loadReportFunc(false, true) }} className="me-2">
                  <MdImportExport className="me-2" size="16" />
                  Export Excel
                </Button>
                <Workspace objectType="R" />
                <div className="divider"></div>
                <Button variant="danger" onClick={closeReportExplorer}>
                  <IoMdClose className="me-2" size="16" />
                  Close
                </Button>
              </div>
              <Accordion controlId="lblcriteria" system={true} customManageState={true} customActive={reportObject.isCriteriaOpen} toggleAccordion={(controlId, active) => toggleCriteriaFunc()}>
                <span className="criteria-fields">
                  {
                    reportObject.criteria.map((x, i) => (
                      criteriaField(x, i)
                    ))
                  }
                  {
                    reportObject.FICriteria.length ?
                      <span className="group-label">Financial Dimensions</span>
                      : null
                  }
                  {
                    reportObject.FICriteria.map((x, i) => (
                      criteriaField(x, i)
                    ))
                  }
                </span>
              </Accordion>
            </>
            :
            <>
              <div style={{ marginBottom: 10 }}>
                <span className="criteria-fields">
                  {
                    reportObject.criteria.filter(x => x.showinshortreport).map((x, i) => (
                      criteriaField(x, i)
                    ))
                  }
                </span>
                <Button variant="primary" className="ms-auto" onClick={() => { loadReportFunc(false, false) }}>
                  <MdDownloading className="me-2" size="16" />
                  Load
                </Button>
              </div>
            </>
        }

        {reportObject.report && (
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.9.359/build/pdf.worker.min.js">
            <div className="rpv-core__viewer report-viewer-container">
              <div className="report-viewer-toolbar" style={{}}>
                <Toolbar>
                  {(props: ToolbarSlot) => {
                    const {
                      CurrentPageInput,
                      Download,
                      EnterFullScreen,
                      GoToNextPage,
                      GoToPreviousPage,
                      NumberOfPages,
                      Print,
                      ZoomIn,
                      ZoomOut,
                      ShowSearchPopover,
                    } = props;
                    return (
                      <>
                        <div style={{ padding: "0px 2px" }}>
                          <ShowSearchPopover />
                        </div>
                        <div style={{ padding: "0px 2px" }}>
                          <ZoomOut />
                        </div>
                        <div style={{ padding: "0px 2px" }}>
                          <ZoomIn />
                        </div>
                        <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                          <GoToPreviousPage />
                        </div>
                        <div style={{ padding: "0px 2px", width: "4rem" }}>
                          <CurrentPageInput />
                        </div>
                        <div style={{ padding: "0px 2px" }}>
                          / <NumberOfPages />
                        </div>
                        <div style={{ padding: "0px 2px" }}>
                          <GoToNextPage />
                        </div>
                        <div style={{ padding: "0px 2px", marginLeft: "auto" }}>
                          <EnterFullScreen />
                        </div>
                        <div style={{ padding: "0px 2px" }}>
                          <Download />
                        </div>
                        <div style={{ padding: "0px 2px" }}>
                          <Print />
                        </div>
                      </>
                    );
                  }}
                </Toolbar>
              </div>
              <div className="report-viewer">
                <Viewer
                  fileUrl={"data:application/pdf;base64," + reportObject.report}
                  plugins={[toolbarPluginInstance]}
                />
              </div>
            </div>
          </Worker>
        )}
      </div>
    );
  else return null;
};

export default ReportExplorer;
