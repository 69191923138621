import { useHistory, withRouter } from "react-router-dom";
import { AlignUIRender } from './indexUI';


interface IProps {
  changeInboxMenu: (route: string) => void;
}

const Menu = ({ changeInboxMenu }: IProps) => {
  const history = useHistory();
  const pathname = history.location.pathname;


  return AlignUIRender({ changeRoute: changeInboxMenu, activeSideMenu: pathname });


}

export default Menu;