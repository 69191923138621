import { useCallback, useEffect, useState } from "react";
import { getScreenConfig } from "@/Redux/Ducks/entryFormSlice";
import { AlignUIRender } from "./indexUI";
import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import { getUserObjects, setUserFavorite } from "@/Redux/Ducks/homeSlice";
import { ArrayKeyValueObject } from "@/UI/Interfaces/IBaseUI";
import { getReportConfig } from "@/Redux/Ducks/reportExplorerSlice";
import { getDataAnalyticsConfig } from "@/Redux/Ducks/dataAnalyticsSlice";
import { EMenu } from "@/Redux/Ducks/tabSlice";

const EntryFormMenu = ({ closeMenu, activeMenu }: ComponentProps) => {
  const dispatch = useAppDispatch();

  //#region states
  const [activeModule, setActiveModule] = useState<string>('');
  const [userMenu, setUserMenu] = useState<any>([]);
  // const [modules, setModules] = useState<any>([]);
  const [moduleSearch, setModuleSearch] = useState<string>("");
  const [objectSearch, setObjectSearch] = useState<string>("");
  const [objectDetails, setUserObjects] = useState<ArrayKeyValueObject>([]);
  const [reportObjects, setReportObjects] = useState<ArrayKeyValueObject>([]);
  const [menuOpen, toggleMenu] = useState<boolean>(false);

  const { userObjects, dataLoading } = useAppSelector(x => ({ userObjects: x.Home.userObjects, dataLoading: x.Home.userObjectsLoading }));

  //#endregion
  const init = async () => {
    if (!dataLoading && !userObjects.length) {
      dispatch(getUserObjects());
    }
  }
  //#region lifecycle methods


  useEffect(() => {
    if (activeMenu == EMenu.Module) {
      init();
      toggleMenu(true);
    }
    else {
      closeMenuFunc();
    }
  }, [activeMenu]);

  useEffect(() => {
    const userobjects = Object.entries(userObjects.filter(x => x.moduleno == activeModule && !x.applicationobjecttype.isEqual("I", "R")).groupBy("objectcategorystxt"))
      .map(([key, value]) => { const newValue = Object.entries(value.groupBy("groupstxt")); return [key, newValue]; });
    const reportobjects = Object.entries(userObjects.filter(x => x.moduleno == activeModule && x.applicationobjecttype.isEqual("I", "R")).groupBy("objectcategorystxt"))
      .map(([key, value]) => { const newValue = Object.entries(value.groupBy("groupstxt")); return [key, newValue]; });

    setUserObjects(userobjects);
    setReportObjects(reportobjects);

  }, [activeModule, userObjects]);

  //#endregion

  //#region properties

  // favorite
  const BaseSetUserFavorite = (objectcontrollerno: string, objectcontrollerstxt: string, applicationobjecttype: string) => {
    dispatch(setUserFavorite({ objectcontrollerno, objectcontrollerstxt, applicationobjecttype }))
  }

  // routing method
  const routeToScreen = (screenCode: string, applicationobjecttype: string) => {
    if (applicationobjecttype == "E") {
      dispatch(getScreenConfig({ processCode: screenCode }));
    }
    else if (applicationobjecttype == "R") {
      dispatch(getReportConfig({ reportObjectno: screenCode }));
    }
    else if (applicationobjecttype == "I") {
      dispatch(getDataAnalyticsConfig({ dataAnalyticsno: screenCode }));
    }

    closeMenuFunc();
  };

  // module selection
  const toggleModule = async (moduleno: string) => {
    setActiveModule(moduleno);
    setTimeout(() => {
      toggleAllSubMenu(true);
    });
  }

  const closeMenuFunc = () => {
    if (activeModule) {
      // closing module menu
      setActiveModule('');

      setTimeout(() => {
        // closing object menu after module menu
        toggleMenu(false);
        setUserObjects([])
      }, 500);
    }
    else {
      toggleMenu(false);
    }
  }

  const toggleSubMenu = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    var sublistEl = (e.target as HTMLElement).nextSibling as HTMLElement;
    if (sublistEl) {
      sublistEl.classList.toggle('show')
    }
  }

  const toggleSublist = (element: any, expand: boolean) => {
    if (expand)
      element.classList.add('show');
    else
      element.classList.remove('show');

    if (element.querySelectorAll('.objects').length >= 1) {
      element.querySelectorAll('.objects').forEach((sel: any) => {
        toggleSublist(sel, expand)
      })
    }
  }

  const toggleAllSubMenu = (expand: boolean) => {
    var sublistEl = document.querySelectorAll("#entryform-menu > li");
    sublistEl.forEach(element => {
      var sublistEl = element.nextSibling as HTMLElement;
      if (sublistEl) {
        toggleSublist(sublistEl, expand);
      }
    });
  }


  //#endregion

  const modules = userObjects.uniqueBy("moduleno").map(x => ({ moduleno: x.moduleno, modulestxt: x.modulestxt }));

  const filterModules = modules.filter(x => {
    const searchSplit = moduleSearch.split(" ");
    if (!moduleSearch || searchSplit.filter(y => x.modulestxt.toLowerCase().includes(y.toLowerCase())).length == searchSplit.length)
      return true;
  });

  const filterObjects = objectDetails.map(([keyx, valuex]: any) => { // master , transaction
    const searchSplit = objectSearch.split(" ");

    let newValue = valuex.map(([keyy, valuey]: any) => {

      var filterData = valuey.filter((x: any) => {
        if (!objectSearch || searchSplit.filter(y => x.objectcontrollerstxt.toLowerCase().includes(y.toLowerCase())).length == searchSplit.length) {
          return true;
        }
      });

      if (filterData.length)
        return [keyy, filterData];

    });

    newValue = newValue.filter((x: any) => x);

    return [keyx, newValue];
  });

  const filterReportObjects = reportObjects.map(([keyx, valuex]: any) => { // master , transaction
    const searchSplit = objectSearch.split(" ");

    let newValue = valuex.map(([keyy, valuey]: any) => {

      var filterData = valuey.filter((x: any) => {
        if (!objectSearch || searchSplit.filter(y => x.objectcontrollerstxt.toLowerCase().includes(y.toLowerCase())).length == searchSplit.length) {
          return true;
        }
      });

      if (filterData.length)
        return [keyy, filterData];

    });

    newValue = newValue.filter((x: any) => x);

    return [keyx, newValue];
  });


  return AlignUIRender({ BaseSetUserFavorite, dataLoading, routeToScreen, toggleModule, menuOpen, closeMenu, activeModule, setActiveModule, filterModules, setModuleSearch, setObjectSearch, objectSearch, objectDetails: filterObjects, reportObjects: filterReportObjects, toggleSubMenu, toggleAllSubMenu });
};

export default EntryFormMenu;

declare type StateFromProps = {
  closeMenu: () => void,
  activeMenu:  EMenu
};

declare type ComponentProps = StateFromProps;