import { useEffect, useState } from "react";
import { AlignUIRender } from "./indexUI";
import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import { getScreenConfig } from "@/Redux/Ducks/entryFormSlice";
import { getUserWorkspaceObject, deleteWorkspaceObject, updateUserWorkspaceObject, IUserWorkspaceObject, reorderMenus, reorderLists, reorderTiles, updateMenuGroup } from "@/Redux/Ducks/workspaceSlice";
import { updateWorkspace, toggleColumnLayout } from "@/Redux/Ducks/workspaceSlice";
import { getReportConfig } from "@/Redux/Ducks/reportExplorerSlice";
import { getDataAnalyticsConfig } from "@/Redux/Ducks/dataAnalyticsSlice";

const Workspace = ({ history, match }: ComponentProps) => {
	const dispatch = useAppDispatch();
	const { workspaceNo } = match.params; // match
	const allWorkspace = useAppSelector(x => x.Workspace.userWorkspace);
	const currentWorkspace = useAppSelector(x => x.Workspace.userWorkspace.find(x => x.workspaceno.isEqual(workspaceNo)));
	const userSpecificLoaded = useAppSelector(x => x.User.user.name);
	const userWorkspaceObject = useAppSelector(x => x.Workspace.userWorkspaceObject);
	const [tiles, setTiles] = useState<Array<IUserWorkspaceObject>>([]);
	const [menus, setMenus] = useState<Array<IUserWorkspaceObject>>([]);
	const [menuGroups, setMenuGroups] = useState<Array<{ groupName: string, groupIndex: number }>>([]);
	const [lists, setLists] = useState<Array<IUserWorkspaceObject>>([]);
	const [updateWorkspaceModalState, toggleUpdateWorkspaceModal] = useState<boolean>(false);
	const [validated, setValidated] = useState<boolean>(false);
	// inputs
	const [workspaceName, setWorkspaceName] = useState<string>('');
	const [widgetNo, setWidgetNo] = useState<string>('');
	const [widgetName, setWidgetName] = useState<string>('');
	const [widgetType, setWidgetType] = useState<string>('');
	const [widgetGroup, setWidgetGroup] = useState<string>('');
	const [icon, setIcon] = useState<string>("BarChart");

	useEffect(() => {
		if (userSpecificLoaded) {
			if (currentWorkspace?.workspacestxt) {
				setWorkspaceName(currentWorkspace?.workspacestxt || '')
			}
			else {
				history.push("/")
			}
		}
	}, [currentWorkspace]);

	useEffect(() => {
		const _tiles = userWorkspaceObject.filter(x => x.widgetType == "Tile");
		if (_tiles.length >= 1) {
			setTiles(_tiles);
		}
		else {
			setTiles([]);
		}

		const _menus: Array<IUserWorkspaceObject> = userWorkspaceObject.filter(x => x.widgetType == "Menu");
		if (_menus.length >= 1) {
			const _menuGroups = [...new Set(_menus.map(menu => menu.widgetGroup))].map((x, idx) => ({ groupName: x, groupIndex: idx }));

			setMenus(_menus);
			setMenuGroups(_menuGroups);
		}
		else {
			setMenus([]);
			setMenuGroups([]);
		}

		const _lists = userWorkspaceObject.filter(x => x.widgetType == "List");
		if (_lists.length >= 1) {
			setLists(_lists);
		}
		else {
			setLists([]);
		}

	}, [userWorkspaceObject]);

	useEffect(() => {
		dispatch(getUserWorkspaceObject(workspaceNo))
	}, [])

	const routeToScreen = (screenCode: string, gridView: string, objectType: string) => {
		if (objectType == "E") {
			dispatch(getScreenConfig({ processCode: screenCode, gridView }));
		}
		else if (objectType == "R") {
			const gridViewObj = JSON.parse(gridView);
			dispatch(getReportConfig({ reportObjectno: screenCode, modelValue: gridViewObj }));
		}
		else if (objectType == "I") {
			const gridViewObj = JSON.parse(gridView).gridView;
			const modelValue = JSON.parse(gridView).modelValue;
			dispatch(getDataAnalyticsConfig({ dataAnalyticsno: screenCode, gridView: gridViewObj, modelValue }));
		}
	};

	const openHyperlink = (screenCode: string, transactionNo: string) => {
		dispatch(getScreenConfig({ processCode: screenCode, transactionNo }));
	};

	const toggleSubMenu = (e: any) => {
		var targetEl: any = (e.target as HTMLElement);
		var sublistEl;

		if (targetEl.tagName.toUpperCase() == 'LI') {
			sublistEl = targetEl.nextSibling as HTMLElement;
		}
		else {
			while (targetEl.tagName.toUpperCase() != 'LI') {
				targetEl = targetEl.parentElement;
			}

			sublistEl = targetEl.nextSibling as HTMLElement;
		}

		sublistEl.classList.toggle('show');
	}

	const resetState = () => {
		setWidgetNo("");
		setWidgetName("");
		setWidgetType("");
		setWidgetGroup("");
		setIcon('BarChart');
	}

	const deleteWorkspace = (widgetNo: string) => {
		dispatch(deleteWorkspaceObject(widgetNo));
	}

	const updateWorkspaceFunc = () => {
		dispatch(updateUserWorkspaceObject({ widgetNo, widgetName, icon, widgetGroup, widgetType }));
		toggleUpdateWorkspaceModal(false);
		resetState();
	}

	const closeUpdateWorkspace = () => {
		toggleUpdateWorkspaceModal(false);
		resetState();
	}

	const toggleColumnLayoutFunc = () => {
		dispatch(toggleColumnLayout(parseInt(workspaceNo)))
	}

	const openUpdateWorkspace = (props: { widgetNo: string, widgetName: string, widgetType: string, icon?: string, widgetGroup?: string }) => {
		setWidgetNo(props.widgetNo);
		setWidgetName(props.widgetName);
		setWidgetType(props.widgetType);

		if (props.widgetType == "Tile" && props.icon) {
			setIcon(props.icon);
		}

		if (props.widgetType == "Menu" && props.widgetGroup) {
			setWidgetGroup(props.widgetGroup);
		}

		toggleUpdateWorkspaceModal(true);
	}

	const handleSubmit = (e: any) => {
		const form = e.currentTarget;
		if (form.checkValidity() === false) {
			e.preventDefault();
			e.stopPropagation();
			setValidated(true);
		}
		else {
			updateWorkspaceFunc();
		}
	};

	// #region input change handler
	const handleWorkspaceNameChange = (val: string) => {
		if (!allWorkspace.map(x => x.workspacestxt).includes(val)) {
			dispatch(updateWorkspace({ workspaceNo, workspaceName: val }));
			setWorkspaceName(val);
		}
	}

	const handleWidgetNameChange = (e: any) => {
		setWidgetName(e.target.value)
	}

	const handleWidgetGroupChange = (e: any) => {
		setWidgetGroup(e.target.value)
	}

	const handleIconChange = (icon: string) => {
		setIcon(icon);
	}
	// #endregion

	const updateIndex = (objects: any, widgetType: string) => {
		if (widgetType == 'Tile') {
			dispatch(reorderTiles(objects));
		}
		else if (widgetType == 'List') {
			dispatch(reorderLists(objects));
		}
		else if (widgetType == 'Menu') {
			dispatch(reorderMenus(objects));
		}
	}

	const updateGroupIndex = (objects: any) => {
		var menusList = new Array();
		objects.forEach((x: any) => {
			var group = x.groupName;
			var menusByGroup = menus.filter(x => x.widgetGroup == group);

			menusList.push(...menusByGroup)
		})

		dispatch(reorderMenus(menusList));
	}

	const updateGroup = (dragCard: IUserWorkspaceObject, hoverCard: IUserWorkspaceObject) => {
		var widgetNo = dragCard.widgetNo;
		var widgetGroup = hoverCard.widgetGroup;
		
		dispatch(updateMenuGroup({ widgetNo, widgetGroup }));
	}

	return AlignUIRender({ workspaceName, handleWorkspaceNameChange, deleteWorkspace, toggleSubMenu, menus, menuGroups, tiles, updateIndex, updateGroupIndex, updateGroup, routeToScreen, lists, openHyperlink, updateWorkspaceModalState, closeUpdateWorkspace, openUpdateWorkspace, validated, handleSubmit, widgetName, handleWidgetNameChange, icon, handleIconChange, widgetType, widgetGroup, handleWidgetGroupChange, columnlayout: currentWorkspace?.columnlayout, toggleColumnLayoutFunc });
};

export default Workspace;

// //#region Components Props

declare type StateFromProps = {
	history: any;
	match: any;
};

declare type ComponentProps = StateFromProps;
