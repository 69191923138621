import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import { toggleLoader } from "@/Redux/Ducks/loaderSlice";
import { useState } from "react";
import { AlignUIRender } from "./indexUI";
import { requestGetFrequentlyUsed } from "@/Redux/Sagas/Requests/home";
import { useHistory } from "react-router-dom";
import { getScreenConfig } from "@/Redux/Ducks/entryFormSlice";

const UserAction = () => {
  const dispatch = useAppDispatch();

  const [taskModalOpen, openTaskModal] = useState(false);
  const [userFrequentlyUsed, setUserFrequentlyUsed] = useState([]);
  const history = useHistory();

  //#region selector
  const userShortWidget = useAppSelector((state) => state.Home.userShortWidget);

  //#endregion

  const handleTaskModalClose = () => {
    openTaskModal(false);
  }

  const handleTaskModalOpen = async () => {
    try {
      dispatch(toggleLoader(true));
      const res = await requestGetFrequentlyUsed();

      openTaskModal(true);
      setUserFrequentlyUsed(res);
    }
    catch (ex) {
      console.error("Error while opening openAllActivity", ex);
    }
    finally {
      dispatch(toggleLoader(false));
    }
  }

  const routeToScreen = (screenCode: string, transactionNo: string = "") => {
    dispatch(getScreenConfig({ processCode: screenCode, transactionNo: transactionNo }));
  };

  return AlignUIRender({ history, userShortWidget, routeToScreen, handleTaskModalClose, handleTaskModalOpen, taskModalOpen, userFrequentlyUsed });
};

export default UserAction;