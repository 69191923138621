import {
  configureStore,
  getDefaultMiddleware
} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { connectRouter, routerMiddleware } from 'connected-react-router';

import history from "@/Utils/history";

import { watcherSaga } from "./Sagas/rootSaga";
import reducer from "./Ducks";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: reducer,
  middleware: [...getDefaultMiddleware({
    serializableCheck: false,
    thunk: false
  }), 
  sagaMiddleware, 
  routerMiddleware(history)]
});
sagaMiddleware.run(watcherSaga);

export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof reducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
