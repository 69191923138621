import { RootState } from "@/Redux/configureStore";
import { toggleLoader } from "@/Redux/Ducks/loaderSlice";
import { setGlobalMessage } from "@/Redux/Ducks/messageSlice";
import { IAddToWorkspace, IUserWorkspaceObject, setUpdatedUserWorkspaceObject, setUpdatedWorkspace, setUserWorkspace, setUserWorkspaceObject } from "@/Redux/Ducks/workspaceSlice";
import { EMessageType } from "@/UI/Interfaces/IMessage";
import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select } from "redux-saga/effects";
import { requestAddListToWorkspace, requestAddMenuToWorkspace, requestAddTileToWorkspace, requestAddWorkspace, requestDeleteWorkspace, requestDeleteWorkspaceObject, requestGetUserWorkspace, requestGetUserWorkspaceObject, requestReorderLists, requestReorderTiles, requestReorderMenus, requestSetColumnLayout, requestUpdateWorkspace, requestUpdateWorkspaceObject, requestUpdateMenuGroup } from "../Requests/workspace";

export function* handleGetUserWorkspace(): any {
  try {

    yield put(toggleLoader(true));

    const response = yield requestGetUserWorkspace();
    const userWorkspace = response;

    yield put(setUserWorkspace(userWorkspace));

  } catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}
export function* handleAddWorkspace({ payload }: PayloadAction<string>): any {
  try {
    yield call(requestAddWorkspace, payload);
    yield call(handleGetUserWorkspace);
    yield put(setGlobalMessage({ message: 'Workspace successfully saved', messageType: EMessageType.Success }));
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}
export function* handleUpdateWorkspace({ payload }: PayloadAction<{ workspaceNo: number, workspaceName: string }>): any {
  try {
    yield call(requestUpdateWorkspace, payload.workspaceNo, payload.workspaceName);
    yield call(handleGetUserWorkspace);
    yield put(setUpdatedWorkspace(payload));
    yield put(setGlobalMessage({ message: 'Workspace successfully updated', messageType: EMessageType.Success }));
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}
export function* handleDeleteWorkspace({ payload }: PayloadAction<number>): any {
  try {
    yield call(requestDeleteWorkspace, payload);
    yield put(setGlobalMessage({ message: 'Workspace successfully deleted', messageType: EMessageType.Success }));
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}
export function* handleGetUserWorkspaceObject({ payload }: PayloadAction<number>): any {
  try {
    yield put(toggleLoader(true));
    const response = yield call(requestGetUserWorkspaceObject, payload);
    yield put(setUserWorkspaceObject(response));
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}
export function* handleAddToWorkspace({ payload }: PayloadAction<IAddToWorkspace>): any {
  try {
    if (payload.widgetType.isEqual("Tile")) {
      yield call(requestAddTileToWorkspace, payload.workspaceNo, payload.widgetName, payload.gridView, payload.processCode, payload.helpObject, payload.showCount, payload.icon, payload.objectType);
    }
    else if (payload.widgetType.isEqual("Menu")) {
      yield call(requestAddMenuToWorkspace, payload.workspaceNo, payload.widgetName, payload.gridView, payload.processCode, payload.helpObject, payload.widgetGroup, payload.objectType);
    }
    else if (payload.widgetType.isEqual("List")) {
      yield call(requestAddListToWorkspace, payload.workspaceNo, payload.widgetName, payload.gridView, payload.processCode, payload.helpObject, payload.columns, payload.objectType);
    }

    yield put(setGlobalMessage({ message: 'Workspace successfully saved', messageType: EMessageType.Success }));
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}
export function* handleUpdateUserWorkspaceObject({ payload }: PayloadAction<{ widgetNo: string, widgetName: string, icon?: string, widgetGroup?: string, widgetType: string }>): any {
  try {
    yield call(requestUpdateWorkspaceObject, payload.widgetNo, payload.widgetName, payload.icon || '', payload.widgetGroup || '');
    yield put(setUpdatedUserWorkspaceObject(payload));
    yield put(setGlobalMessage({ message: 'Workspace successfully updated', messageType: EMessageType.Success }));
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}
export function* handleDeleteWorkspaceObject({ payload }: PayloadAction<string>): any {
  try {
    yield call(requestDeleteWorkspaceObject, payload);
    yield put(setGlobalMessage({ message: 'Workspace successfully deleted', messageType: EMessageType.Success }));
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}
export function* handleToggleColumnLayout({ payload }: PayloadAction<number>): any {
  try {
    const columnlayout: boolean = yield select((state: RootState) => state.Workspace.userWorkspace.find(x => x.workspaceno == payload)?.columnlayout);
    yield call(requestSetColumnLayout, payload, columnlayout);
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}
export function* handleReorderTiles({ payload }: PayloadAction<Array<IUserWorkspaceObject>>): any {
  try {
    yield call(requestReorderTiles, payload.map(x => x.widgetNo));
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

export function* handleReorderLists({ payload }: PayloadAction<Array<IUserWorkspaceObject>>): any {
  try {
    yield call(requestReorderLists, payload.map(x => x.widgetNo));
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

export function* handleReorderMenus({ payload }: PayloadAction<Array<IUserWorkspaceObject>>): any {
  try {
    yield call(requestReorderMenus, payload.map(x => x.widgetNo));
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

export function* handleUpdateMenuGroup({ payload }: PayloadAction<{ widgetNo: string, widgetGroup: string }>): any {
  try {
    yield call(requestUpdateMenuGroup, payload.widgetNo, payload.widgetGroup);
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}