import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import produce from "immer";

import { EMessageOpenAs, EMessageType, IMessage, IMessageDetail, setTabMessageParams } from "@/UI/Interfaces/IMessage";

const initialState: IMessage = {
  sessionTimeoutPrompt: false,
  globalMessage: {
    title: '',
    message: '',
    messageOpenAs: 0,
    messageType: 0,
    open: false
  },
  tab: []
}

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setTabMessage(state, action: PayloadAction<Partial<setTabMessageParams & { error: any }>>) {
      const payload = action.payload;

      return produce(state, ({ tab }) => {

        const index = tab.findIndex(x => x.tabId == payload.tabId);

        if (index != -1)
          tab.splice(index, 1);

        let message = "";
        let messageType = payload.messageType || 0;
        const messageOpenAs = payload.messageOpenAs || EMessageOpenAs.Normal;

        if (payload.error) {
          const error = payload.error;
          if (error.response) {

            if (error.response.data)
              message = error.response.data.errMessage || error.response.data.ExceptionMessage;

            if (message == "")
              error.response.status = error.response.statusText;

            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);

          } else if (error.message) {
            message = error.message;
          } else if (error.Message) {
            message = error.Message;
          } else if (error.request) {

            console.log(error.request);
          }
          if (messageType == 0)
            messageType = EMessageType.Error;
        }
        else {
          message = payload.message || "";
        }

        if (messageType == 0)
          messageType = EMessageType.Success;

        if (payload.title)
          message = payload.title + "\n" + message;

        const data = {
          title: payload.title || "",
          message: message,
          messageOpenAs: messageOpenAs,
          messageType: messageType,
          tabId: payload.tabId as string,
          open: true
        };

        tab.push(data);

        if (message) {
          if (messageType == EMessageType.Error)
            toast.error(message);
          else if (messageType == EMessageType.Success)
            toast.success(message);
          else if (messageType == EMessageType.Information)
            toast.info(message);
          else if (messageType == EMessageType.Warning)
            toast.warn(message);
        }

      });
    },

    closeTabMessage(state, action: PayloadAction<string>) {
      const payload = action.payload;

      return produce(state, ({ tab }) => {

        const index = tab.findIndex(x => x.tabId == payload);

        if (index != -1)
          tab[index].open = false;

      });
    },

    setGlobalMessage(state, action: PayloadAction<Partial<IMessageDetail & { error: any }>>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        const { globalMessage } = draftState;

        let message = "";
        let messageType = payload.messageType || 0;
        const messageOpenAs = payload.messageOpenAs || EMessageOpenAs.Normal;

        if (payload.error instanceof Error && (payload.error as any)?.response?.status == 401) {
          draftState.sessionTimeoutPrompt = true;
        }
        else {
          if (payload.error) {
            const error = payload.error;
            if (error.response) {

              if (error.response.data)
                message = error.response.data.errMessage || error.response.data.ExceptionMessage;

              if (message == "")
                error.response.status = error.response.statusText;

              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);

            } else if (error.message) {
              message = error.message;
            } else if (error.request) {

              console.log(error.request);
            }
            if (messageType == 0)
              messageType = EMessageType.Error;
          }
          else {
            message = payload.message || "";
          }

          if (messageType == 0)
            messageType = EMessageType.Success;

          globalMessage.message = message;
          globalMessage.messageOpenAs = messageOpenAs;
          globalMessage.messageType = messageType;
          globalMessage.open = true;


          if (message) {
            if (messageType == EMessageType.Error)
              toast.error(message);
            else if (messageType == EMessageType.Success)
              toast.success(message);
            else if (messageType == EMessageType.Information)
              toast.info(message);
            else if (messageType == EMessageType.Warning)
              toast.warn(message);
          }

        }


      });
    },

    closeGlobalMessage(state, action: PayloadAction<IMessageDetail>) {
      const payload = action.payload;

      return produce(state, ({ globalMessage: global }) => {

        global.open = false;

      });
    },

    closeSessionTimeoutPrompt(state) {
      return produce(state, (draftState) => {
        draftState.sessionTimeoutPrompt = false;
      });
    }
  }
});

export const { setTabMessage, closeTabMessage, setGlobalMessage, closeGlobalMessage, closeSessionTimeoutPrompt } = messageSlice.actions;

export default messageSlice.reducer;
