import { useEffect, useRef, useState } from 'react'
import { useAppSelector } from '@/Hook/hooks';
import { IFieldProps } from '@/UI/Interfaces/IComponent';
import { useDispatch } from 'react-redux';
import { InputNumber } from '@grapecity/wijmo.react.input';
// import { FiExternalLink } from 'react-icons/fi';
import classNames from 'classnames';
import { constant } from '@/Utils/constants';


const Field = ({ config, fieldValueChange, fieldFocusOut, uniqueKey, enable, value, validateMandatory, hideLabel }: IFieldProps) => {
  const { fieldid } = config;
  const dispatch = useDispatch();
  const [currentValue, setCurrentValue] = useState<number>(0);
  // const [oldValue, setOldValue] = useState<string>("");
  const [fieldIsInEditing, setFieldIsInEditing] = useState<boolean>(false);

  const valueRef = useRef(value);

  const objectId = useAppSelector((x) => x.EntryForm.activeObjectId);
  const dataFormat = useAppSelector(state => {
    let dataFormat = constant.DefaultNumber;

    state.User.CompanyDataFormat.map(x => {
      if (x.fielddataformatno == config.fielddataformatno)
        dataFormat = x.fielddataformatmasking;
    });

    if (state.EntryForm.objects?.[state.Tab.tabs?.[0]?.tabActualKey]) {
      const ProcessCodeDataFormat = state.EntryForm.objects[state.Tab.tabs[0].tabActualKey].ScreenConfig.ProcessCodeApplicationDataFormat;
      const finddataFormat = ProcessCodeDataFormat.find(x => x.fielddataformatno == config.fielddataformatno);
      if (finddataFormat != null)
        dataFormat = finddataFormat.fielddataformatmasking;
    }

    return dataFormat;
  });

  useEffect(() => {
    if (value === undefined) {
      callBaseMethod(0);
    }
    valueRef.current = value;
  }, [value]);


  const callBaseMethod = (changeValue: any) => {
    fieldValueChange(fieldid, changeValue);
  }

  const change = (e: any) => {
    setCurrentValue(e.value);
  }

  const focus = (e: any) => {

    setCurrentValue(e.value);
    setFieldIsInEditing(true);
  }

  const blur = (e: any) => {

    if (e.value != valueRef.current) {
      callBaseMethod(e.value);
    }
    setCurrentValue(0);
    setFieldIsInEditing(false);

    fieldFocusOut(fieldid, e.value);
  }

  if (!config) return <></>;
  const id = objectId + (uniqueKey ? `-${uniqueKey}` : "") + "-" + fieldid;
  return (
    <>
      {
        !hideLabel ?
          <label id={"lb-" + id} htmlFor={"field-" + id}>
            {config.fieldlabel}
            {validateMandatory ? (<span>*</span>) : null}
            {/* {config.hyperlinkjump != '' ? (<span><FiExternalLink/></span>) : null} */}
          </label>
          : null
      }
      <div className="input-sm input-container">
        <InputNumber isDisabled={!enable} id={"field-" + id} gotFocus={(e: any) => focus(e)} value={fieldIsInEditing ? currentValue : (value || 0)} textChanged={(e: any) => change(e)} lostFocus={(e: any) => blur(e)} format={dataFormat} />
      </div>

    </>
  )

}





export default Field;

