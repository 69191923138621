import { useEffect, useRef } from 'react'
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDrag, useDrop } from "react-dnd";
import { BiData } from 'react-icons/bi';
import { BsFileEarmarkCheck, BsThreeDotsVertical } from 'react-icons/bs';
import { HiPencilAlt } from 'react-icons/hi';
import { IWorkspaceWidget } from '.';

interface IProps {
  menu: IWorkspaceWidget,
  moveMenuContent: (drag: { dragIndex: number, dragId: string }, hover: { hoverIndex: number, hoverId: string }) => void,
  index: number,
  routeToScreen: (screenCode: string, gridView: string, objectType: string) => void,
  openUpdateWorkspace: (props: {
    widgetNo: string;
    widgetName: string;
    widgetType: string;
    icon?: string | undefined;
    widgetGroup?: string | undefined;
  }) => void,
  deleteWorkspace: (widgetNo: string) => void,
  groupName: string,
  menus: Array<IWorkspaceWidget>,
}
export const MenuContent = ({ menu, moveMenuContent, index, routeToScreen, openUpdateWorkspace, deleteWorkspace, groupName, menus }: IProps) => {
  const ref = useRef<any>(null);

  const [, drop] = useDrop({
    accept: 'MenuContent',
    hover(item: { id: string, index: number }, monitor) {
      if (!ref.current) {
        return;
      }
      const dragId = menus.find(x => x.widgetNo == item.id)?.widgetNo || '';
      const dragIndex = menus.findIndex(x => x.widgetNo == item.id) || 0;
      const hoverId = menus.find(x => x.widgetNo == menu.widgetNo)?.widgetNo || '';
      const hoverIndex = menus.findIndex(x => x.widgetNo == menu.widgetNo) || 0;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset: any = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY: any = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveMenuContent({ dragIndex, dragId }, { hoverIndex, hoverId });
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'MenuContent',
    item: () => {
      return { id: menu.widgetNo, index: index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1;

    drag(drop(ref));

  return (
    <div ref={ref} className="d-flex" key={menu.widgetName} style={{ width: "320px" }}>
      <li role="button" onClick={() => { routeToScreen(menu.processCode, menu.gridView, menu.objectType) }} className="link w-100">
        {
          menu.objectType == "I" ?
            (
              <BiData className="me-2" />
            ) : menu.objectType == "E" ? (
              <HiPencilAlt className="me-2" />
            ) : (
              <BsFileEarmarkCheck className="me-2" />
            )
        }
        {menu.widgetName}
      </li>
      <div>
        <Dropdown className="caret-none" drop="end">
          <OverlayTrigger placement="top" overlay={<Tooltip>Menu</Tooltip>}>
            <Dropdown.Toggle id="workspace-menu-button" variant="white" className="p-0">
              <BsThreeDotsVertical size="16" />
            </Dropdown.Toggle>
          </OverlayTrigger>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => { openUpdateWorkspace({ widgetNo: menu.widgetNo, widgetName: menu.widgetName, widgetType: menu.widgetType, widgetGroup: menu.widgetGroup }) }}>Edit</Dropdown.Item>
            <Dropdown.Item onClick={() => { deleteWorkspace(menu.widgetNo) }}>Delete</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}
