import axiosBase from "axios";
import { API_URL } from "./constants";
import { getCompanyDetails, getTokens, setTokens } from "./auth";

const axios: any = axiosBase.create({
  baseURL: API_URL,
  // timeout: 20000,
});

// for multiple requests
let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axios.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    const originalRequest = error.config;

    // eslint-disable-next-line no-underscore-dangle
    if (error.response && error.response.status === 401 && !originalRequest._retry && originalRequest.url != "Authentication/RefreshToken") {

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true;
      isRefreshing = true;

      const tokens = getTokens();
      return new Promise((resolve, reject) => {
        axios
          .post("Authentication/RefreshToken", tokens)
          .then(({ data }: any) => {
            setTokens(data);
            axios.defaults.headers.Authorization = `Bearer ${data.AccessToken}`;
            originalRequest.headers.Authorization = `Bearer ${data.AccessToken}`;
            resolve(axios(originalRequest));
            processQueue(null, data.AccessToken);

          })
          .catch((err: any) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  },
);

const execute = async (method: string, ...args: any) => {
  try {

    if (getTokens().AccessToken)
      axios.defaults.headers.Authorization = `Bearer ${getTokens().AccessToken}`;
    if (getCompanyDetails().selectedLegalEntity)
      axios.defaults.headers.LegalEntity = getCompanyDetails().selectedLegalEntity;

    const response = await axios[method](...args);
    return Promise.resolve(response.data);
  } catch (error: any) {

    if (error.response) {

      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log('Error', error.message);
    }

    console.log({ ...error });
    console.log(error.message);

    return Promise.reject(error);
  }
}

const get = (...args: any) => {
  return execute("get", ...args);
}

const post = (...args: any) => {
  return execute("post", ...args);
}

const put = (...args: any) => {
  return execute("put", ...args);
}

const remove = (...args: any) => {
  return execute("delete", ...args);
}

export { get, post, put, remove };

export default execute;
