import { Login } from "./";
// @ts-ignore
import PasswordMask from 'react-password-mask';
import { Icon } from "AlignComponents";
import { Button } from "react-bootstrap";

export const AlignUIRender = ({
  login,
  updateFieldState,
  state,
  handleKeyDown,
  props,
  navigateToForgotPassword
}: Login) => {
  return (
    <form>
      <input
        id="username"
        type="text"
        placeholder="User Name"
        className="form-control mb-3"
        value={state.username}
        onChange={(e: any) => updateFieldState("username", e.target.value)}
        onKeyDown={handleKeyDown}
        autoComplete="off"
      />
      <PasswordMask
        id="password"
        placeholder="Password"
        inputClassName="form-control mb-3"
        value={state.password}
        onKeyDown={handleKeyDown}
        onChange={(e: any) => updateFieldState("password", e.target.value)}
        showButtonContent={
          <Icon icon="Eye" size="18" color="#8D8D8D" />
        }
        hideButtonContent={
          <Icon icon="EyeOff" size="18" color="#8D8D8D" />
        }
        buttonStyles={{ background: 'transparent', marginTop: '-18px' }}
      />
      <input
        id="clientno"
        type="text"
        placeholder="Company"
        className="form-control mb-3"
        value={state.clientno}
        onChange={(e) => updateFieldState("clientno", e.target.value)}
        onKeyDown={handleKeyDown}
        autoComplete="off"
      />
      <select
        id="languageno"
        name="languageno"
        className="form-control"
        defaultValue={state.languageno}
        onChange={(e) => updateFieldState("languageno", e.target.value)}
        onKeyDown={handleKeyDown}
      >
        {props.clientLanguage.map((x: any) => (
          <option value={x.languageno}>{x.languagenostxt}</option>
        ))}
      </select>
      <div className="d-flex gap-2">
        <Button variant="primary" className="w-100" onClick={() => login()}>Login</Button>
        <Button variant="secondary" className="w-100" onClick={navigateToForgotPassword}>Forgot Password</Button>
      </div>
    </form>
  );
};
