import { ArrayKeyValueObject, KeyValueObject } from "@/UI/Interfaces/IBaseUI";

declare global {
  interface String {
    isEqual(...keys: any[]): Boolean;
    getCollectionNameFromGridno(): string;
    toLineIdFormat(): string;
  }
  interface Number extends String {
    isInRange(fromNumber: number, toNumber: number): Boolean;
  }
  interface Array<T> {
    sum(key: keyof T): number;
    groupBy(...key: (keyof T)[]): KeyValueObject<Array<T>>;
    uniqueBy(...key: (keyof T)[]): Array<T>;
    sortByObjectKey(key: keyof T): Array<T>;
    cloneArray(): Array<T>
  }
  interface HTMLElement {
    getFirstChild(): any;
  }
  interface Date {
    addDays(days: number): Date
    addMonth(month: number): Date
    getDaysDifferenceFrom(date: Date): number
    getMonthsDifferenceFrom(date: Date): number
    getHoursTimeDifferenceFrom(date: Date, round?: boolean): number
    getLastDate(): number
  }
}

function isEqual(this: string, ...keys: any[]) {
  try {
    var str = "^" + this + "$";
    var regex = new RegExp(str, "i");
    var result = false;
    for (var i = 0; i < keys.length; i++) {
      if (regex.test(keys[i])) {
        result = true;
      }
    }
    return result;
  }
  catch (ex) {
    return false;
  }
};

function getFirstChild(this: HTMLElement) {
  try {
    return this.firstChild;
  }
  catch (ex) {
    throw ex;
  }
}

function toLineIdFormat(this: string) {
  try {
    return (this).toString().padStart(4, '0');
  }
  catch (ex) {
    throw ex;
  }
};

function getCollectionNameFromGridno(this: string) {
  try {
    return this.trim().toLowerCase().replace('flex', '');
  }
  catch (ex) {
    throw ex;
  }
};

function isInRange(this: number, fromNumber: number, toNumber: number) {
  try {
    return this >= fromNumber && this <= toNumber;
  }
  catch (ex) {
    throw ex;
  }
};

function groupBy(this: ArrayKeyValueObject, ...key: string[]) {
  return this.reduce(function (rv, x) {
    const keyFromValue = key.map(y => x[y]).join(",");
    (rv[keyFromValue] = rv[keyFromValue] || []).push(x);
    return rv;
  }, {});
};


function uniqueBy(this: ArrayKeyValueObject, ...key: string[]) {
  return [...new Map(this.map(item => [key.map(x => item[x]).join(""), item])).values()];
};

function sum(this: ArrayKeyValueObject, key: string) {
  return this.reduce((a, b) => a + (b[key] || 0), 0);
};

function sortByObjectKey(this: ArrayKeyValueObject, key: string) {
  return this.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
};

function cloneArray(this: ArrayKeyValueObject) {
  return JSON.parse(JSON.stringify(this));
};

function addDays(this: Date, days: number) {
  var date = this;
  date.setDate(date.getDate() + days);
  return date;
}

function addMonth(this: Date, month: number) {
  var date = this;
  date.setMonth(date.getMonth() + month);
  return date;
}

function getDaysDifferenceFrom(this: Date, secondDate: Date) {
  const date1 = new Date(this);
  const date2 = new Date(secondDate);
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);

  const diffTime = date2.getTime() - date1.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));

  return diffDays;
}

function getMonthsDifferenceFrom(this: Date, secondDate: Date) {
  var months;
  const d1 = new Date(this);
  const d2 = new Date(secondDate);
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

function getHoursTimeDifferenceFrom(this: Date, secondDate: Date, round: boolean = false) {
  const d1 = new Date();
  const d2 = new Date();

  d1.setHours(this.getHours());
  d1.setMinutes(this.getMinutes());

  d2.setHours(secondDate.getHours());
  d2.setMinutes(secondDate.getMinutes());

  var difference = d2.getTime() - d1.getTime();
  if (round)
    difference = Math.abs(difference);

  var hours = difference / 36e5;

  return hours;
}

function getLastDate(this: Date) {
  var date = new Date(this), y = date.getFullYear(), m = date.getMonth();
  var lastDay = new Date(y, m + 1, 0);

  return lastDay.getDate();
}

String.prototype.getCollectionNameFromGridno = getCollectionNameFromGridno;
String.prototype.toLineIdFormat = toLineIdFormat;
String.prototype.isEqual = isEqual;

Number.prototype.isEqual = isEqual;
Number.prototype.toLineIdFormat = toLineIdFormat;
Number.prototype.isInRange = isInRange;

Date.prototype.addDays = addDays;
Date.prototype.addMonth = addMonth;
Date.prototype.getDaysDifferenceFrom = getDaysDifferenceFrom;
Date.prototype.getMonthsDifferenceFrom = getMonthsDifferenceFrom;
Date.prototype.getHoursTimeDifferenceFrom = getHoursTimeDifferenceFrom;
Date.prototype.getLastDate = getLastDate;

HTMLElement.prototype.getFirstChild = getFirstChild;

Object.defineProperty(Array.prototype, 'sum', {
  enumerable: false,
  value: sum
});

Object.defineProperty(Array.prototype, 'groupBy', {
  enumerable: false,
  value: groupBy
});
Object.defineProperty(Array.prototype, 'uniqueBy', {
  enumerable: false,
  value: uniqueBy
});
Object.defineProperty(Array.prototype, 'sortByObjectKey', {
  enumerable: false,
  value: sortByObjectKey
});
Object.defineProperty(Array.prototype, 'cloneArray', {
  enumerable: false,
  value: cloneArray
});

export { }