import { useAppSelector } from "@/Hook/hooks";
import classNames from "classnames";
import { CSSProperties } from "react";
import ControlTranslation from "../ControlTranslation";

interface IProps {
	controlId: string
	style?: CSSProperties
}

const GroupHeadingLabel = (props: IProps) => {

	const activeObjectId = useAppSelector((state) => state.EntryForm.activeObjectId);
	const isHide = useAppSelector((state) => state.EntryForm.objects[activeObjectId].otherState.groupLabelHide.indexOf(props.controlId) != -1);

	return (
		<span style={props.style || {}} className={classNames([{ "d-none": isHide }, "group-label"])}><ControlTranslation controlId={props.controlId} /></span>
	);
};

export default GroupHeadingLabel;
