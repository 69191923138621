import { IUserWorkspaceObject } from '@/Redux/Ducks/workspaceSlice';
import { useCallback } from 'react';
import update from 'immutability-helper';
import { Col, Nav, Tab } from 'react-bootstrap';
import { ListHeader } from './ListHeader';
import { ListContent } from './ListContent'

export interface IWorkspaceWidget extends IUserWorkspaceObject {
	index: number
}
interface IProps {
	lists: Array<IWorkspaceWidget>,
	updateIndex: (objects: any, widgetType: string) => void
	columnlayout: boolean,
	routeToScreen: (screenCode: string, gridView: string, objectType: string) => void,
	openUpdateWorkspace: (props: {
		widgetNo: string;
		widgetName: string;
		widgetType: string;
		icon?: string | undefined;
		widgetGroup?: string | undefined;
	}) => void,
	deleteWorkspace: (widgetNo: string) => void,
	openHyperlink: (screenCode: string, transactionNo: string) => void
}
export const Lists = (props: IProps) => {
	const moveListHeader = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			const dragCard = props.lists[dragIndex];

			props.updateIndex(
				update(props.lists, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragCard],
					],
				}), 'List');

		},
		[props.lists]);

	return (
		<Col md={!props.columnlayout ? 12 : 3} className="mt-3">
			{
				props.lists.length >= 1 ? (
					<>
						<h6 className="fw-bold">Lists</h6>
						<div className="workspace-lists">
							<Tab.Container
								defaultActiveKey={props.lists[0].widgetName}
							>
								<Nav variant="tabs">
									{
										props.lists.map((list, idx: number) => {
											return (
												<ListHeader key={list.widgetNo} index={idx} list={list} routeToScreen={props.routeToScreen} openUpdateWorkspace={props.openUpdateWorkspace} deleteWorkspace={props.deleteWorkspace} moveListHeader={moveListHeader} />
											)
										})
									}
								</Nav>
								<Tab.Content>
									{
										props.lists.map((list, idx: number) => {
											return (
												<ListContent key={list.widgetNo} list={list} openHyperlink={props.openHyperlink} />
											)
										})
									}
								</Tab.Content>
							</Tab.Container>
						</div>
					</>) : null
			}
		</Col>
	)
}
