import { KeyValueObject } from "@/UI/Interfaces/IBaseUI";
import { get, post } from "@/Utils/axios";

export function requestBLForData(processcode: string, methodName: string, param: KeyValueObject): any {
  return post(`Screen/RequestBLForData`, { processcode, methodName, param });
}

export function requestGetFieldValueInformation(processcode: string, value: string): any {
  return get(`Configuration/GetFieldValueInformation`, { params: { processcode, value } });
}

export function requestGetUserSearchObjects(): any {
  return get(`Configuration/GetUserSearchObjects`);
}