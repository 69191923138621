import { Button, Dropdown, OverlayTrigger, Tooltip, Form, Col, Row } from 'react-bootstrap';
import EdiText from 'react-editext';
import { MdChevronRight, MdSave } from 'react-icons/md';
import { BsFileEarmarkCheck, BsThreeDotsVertical } from 'react-icons/bs';
import { ControlTranslation, Icon, IconPicker, Modal } from 'AlignComponents';
import { BiData } from 'react-icons/bi';
import { HiPencilAlt } from 'react-icons/hi';
import { BsLayoutThreeColumns } from "react-icons/bs"
import classNames from 'classnames';
import { Tiles } from "./Tiles"
import { Lists } from "./Lists"
import { Menus } from "./Menus"

export const AlignUIRender = ({ workspaceName, handleWorkspaceNameChange, deleteWorkspace, menus, menuGroups, tiles, updateIndex, updateGroupIndex, updateGroup, routeToScreen, toggleSubMenu, lists, openHyperlink, updateWorkspaceModalState, closeUpdateWorkspace, openUpdateWorkspace, validated, handleSubmit, widgetName, handleWidgetNameChange, icon, handleIconChange, widgetType, widgetGroup, handleWidgetGroupChange, columnlayout, toggleColumnLayoutFunc }: any) => {
	return (
		<>
			<Modal
				modalSize='md'
				heading="Update Workspace"
				show={updateWorkspaceModalState}
				close={closeUpdateWorkspace}
				footer={
					<Button form="updateWorkspace" variant="primary" type="submit">
						<MdSave className="me-2" size="16" />
						Save
					</Button>
				}
			>
				<Form id="updateWorkspace" noValidate validated={validated} onSubmit={handleSubmit}>
					<Form.Group className="mb-2" controlId="workspaceType" as={Row}>
						<Form.Label column sm="3">Name</Form.Label>
						<Col sm="9" className="d-flex align-items-center">
							<Form.Control required value={widgetName} type="text" onChange={handleWidgetNameChange} />
						</Col>
					</Form.Group>
					{
						widgetType == "Tile" ?
							(
								<Form.Group className="mb-2" controlId="workspaceIcon" as={Row}>
									<Form.Label column sm="3">Icon</Form.Label>
									<Col sm="9" className="d-flex align-items-center">
										<IconPicker defaultIcon={icon} onChange={handleIconChange} />
									</Col>
								</Form.Group>
							) : null
					}
					{
						widgetType == "Menu" ?
							(
								<Form.Group className="mb-2" controlId="workspaceType" as={Row}>
									<Form.Label column sm="3">Group</Form.Label>
									<Col sm="9" className="d-flex align-items-center">
										<Form.Control required={widgetType == "Menu" ? true : false} value={widgetGroup} type="text" onChange={handleWidgetGroupChange} />
									</Col>
								</Form.Group>
							) : null
					}
				</Form>
			</Modal>
			<div className="workspace">
				<EdiText type="text" value={workspaceName} onSave={handleWorkspaceNameChange} />
				<div className="mt-2 mx-3">
					<Button className="ms-auto" variant="primary" onClick={toggleColumnLayoutFunc}>
						<BsLayoutThreeColumns style={{ transform: columnlayout ? "none" : "rotate(90deg)" }} className="m-1" size="16" />
					</Button>
				</div>
				<Row className={classNames(["mx-4", { "flex-column": !columnlayout }])}>
					<Tiles tiles={tiles} updateIndex={updateIndex} columnlayout={columnlayout} routeToScreen={routeToScreen} openUpdateWorkspace={openUpdateWorkspace} deleteWorkspace={deleteWorkspace} />
					<Menus menus={menus} menuGroups={menuGroups} updateIndex={updateIndex} updateGroupIndex={updateGroupIndex} updateGroup={updateGroup} columnlayout={columnlayout} routeToScreen={routeToScreen} openUpdateWorkspace={openUpdateWorkspace} deleteWorkspace={deleteWorkspace} openHyperlink={openHyperlink} toggleSubMenu={toggleSubMenu} />
					<Lists lists={lists} updateIndex={updateIndex} columnlayout={columnlayout} routeToScreen={routeToScreen} openUpdateWorkspace={openUpdateWorkspace} deleteWorkspace={deleteWorkspace} openHyperlink={openHyperlink} />
				</Row>

				{
					!(tiles.length >= 1 || menus.length >= 1 || lists.length >= 1) ? (
						<div className="not-found">
							<img src={require("@/Assets/Images/not-found.png").default} alt="" />
							<p className="mb-0"><ControlTranslation system={true} controlId="lblnothingfound" /></p>
							<span>Add widget from the Entry Form, Data Analytics or Report workspace menu.</span>
						</div>
					) : null
				}
			</div>
		</>
	)
}