import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import {
  applyLayout,
  deleteLayout, getDataAnalyticsConfig, IDataAnalyticsLayout, IGridView, loadDataAnalytics, setActiveTab, setDataAnalyticsModelValue, setFieldFilter, setGridView, storeLayout, toggleCriteria, updateLayout
} from "@/Redux/Ducks/dataAnalyticsSlice";
import { getScreenConfig } from "@/Redux/Ducks/entryFormSlice";
import { EHelpOpenIn, IHelpHeader } from "@/Redux/Ducks/helpSlice";
import { setUserFavorite } from "@/Redux/Ducks/homeSlice";
import { loaderText, toggleLoader } from "@/Redux/Ducks/loaderSlice";
import { setGlobalMessage } from "@/Redux/Ducks/messageSlice";
import { closeTab } from "@/Redux/Ducks/tabSlice";
import { requestGetUsersList } from "@/Redux/Sagas/Requests/dataAnalytics";
import { ICriteriaFieldConfig } from "@/UI/Interfaces/IFieldConfig";
import { constant, filterOption } from "@/Utils/constants";
import { Aggregate, Color, DataType, format as wijmoFormat, Globalize, isNumber as wijmoIsNumber, CollectionView, SortDescription } from "@grapecity/wijmo";
import { AllowSorting, GroupRow, CellType } from '@grapecity/wijmo.grid';
import * as wjcGridPdf from '@grapecity/wijmo.grid.pdf';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import * as wjcOlap from "@grapecity/wijmo.olap";
import * as wjcPdf from '@grapecity/wijmo.pdf';
import * as Grid from "@grapecity/wijmo.react.grid";
import { Menu, MenuItem, MenuSeparator } from '@grapecity/wijmo.react.input';
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import * as Input from "@grapecity/wijmo.react.input";
import * as Olap from "@grapecity/wijmo.react.olap";
import "@grapecity/wijmo.styles/wijmo.css";
import "@grapecity/wijmo.touch"; // support drag/drop on touch devices
import { GroupPanel } from '@grapecity/wijmo.react.grid.grouppanel';
import BaseMethods from "AlignBaseUI/BaseMethods";
import { Accordion, AlignInput, Icon, Modal, PdfViewer, Workspace } from "AlignComponents";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { Breadcrumb, Button, ButtonGroup, Col, Dropdown, DropdownButton, Form, FormControl, OverlayTrigger, Row, Tab, Tabs, Tooltip } from "react-bootstrap";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BiChevronsLeft, BiChevronsRight, BiSelectMultiple } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoMdClose, IoMdSave, IoMdTrash } from "react-icons/io";
import { MdDownloading, MdEdit, MdOutlineCancel, MdRestore, MdSave } from "react-icons/md";
import { SiMicrosoftexcel } from "react-icons/si";
import { TbJpg, TbPdf, TbPng, TbSvg } from "react-icons/tb";
import { useHistory } from "react-router-dom";
import ReactSelect from "react-select";

interface Options {
  label: string;
  value: string;
}

//#region PDF Report Interfaces
interface IDrawPrintInformation {
  doc: wjcPdf.PdfDocument,
  font: wjcPdf.PdfFont,
  brush: wjcPdf.PdfSolidBrush
}

interface ICriteriaFieldProps {
  label: string;
  fromValue: string;
  filterType: string;
  toValue: string;
  controlType: string;
  doc: wjcPdf.PdfDocument;
  font: wjcPdf.PdfFont;
  brush: wjcPdf.PdfSolidBrush;
  y: number;
  prevFieldWidth?: number;
}

interface ICriteriaFieldInfo {
  field: IFieldInfo;
  fromValue: IFieldInfo;
  filterType: IFieldInfo;
  toValue: IFieldInfo;
}

interface IDrawFieldProps {
  label: string;
  value: string;
  doc: wjcPdf.PdfDocument;
  font: wjcPdf.PdfFont;
  brush: wjcPdf.PdfSolidBrush;
  y: number;
  prevFieldWidth?: number;
}

interface IFieldInfo {
  charCount: number;
  size: {
    width: number;
    height: number;
  };
}

interface IPrintFieldInfo {
  field: IFieldInfo;
  value: IFieldInfo;
}

//#endregion

const DataAnalyticsDetail = (props: any) => {
  // #region init
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { routeTo } = props.match.params;
  const chartTypes = "Column,Bar,Line,Area,Pie".split(",");

  const dataAnalytics = useAppSelector((state) => state.DataAnalytics.dataAnalyticsDetailObject[state.DataAnalytics.activeDataAnalyticsObjectno]);
  const activeTabKey = useAppSelector((state) => state.Tab.activeTabKey);
  const tabKey = useAppSelector((state) => state.Tab.tabs.find(x => x.tabKey == activeTabKey)?.tabActualKey) || "";
  const dataFormat = useAppSelector(state => state.User.CompanyDataFormat);
  const clientLogo = useAppSelector(state => state.User.UserLegalEntity.find(x => x.legalentityno == state.User.selectedLegalEntity)?.logoimage || '')
  const user: string = useAppSelector(state => state.User.user.username) || "";
  const loader: boolean = useAppSelector(state => state.Loader.toggle);
  const [showRowsTotal, setShowRowsTotal] = useState(false);

  const mainGridRef = useRef<any>();
  const groupPanelRef = useRef<any>();
  const gridSearchRef: any = useRef();
  const filterRef: any = useRef(null);
  const pivotPanelRef = useRef<any>();
  const pivotGridRef = useRef<any>();
  const pivotChartRef = useRef<any>();
  const contextMenuRef = useRef<any>();

  const settingSaveTimeout = useRef<any>(null);
  const gridViewApplyTimeout = useRef<any>(null);

  const [dataAnalyticPivot, setDataAnalyticPivot] = useState<any>();
  const [columns, setColumns] = useState<any>([]);
  const [objects, setObjects] = useState<Array<{ header: string, columnId: string, selected: boolean }>>([]); // for column hidden information

  const [showLookupInReport, toggleLookupInReport] = useState<boolean>(true);
  const [showPivotInReport, togglePivotInReport] = useState<boolean>(true);
  const [reportName, setReportName] = useState<string>("");
  const [report, setReport] = useState<any>("");
  const [columnLoaded, setColumnLoaded] = useState<boolean>(false);
  const [filterApplying, setFilterApplying] = useState<boolean>(false);
  const [showPanel, setShowPanel] = useState<boolean>(true);
  const [logText, setLogText] = useState<string>("Please select a range on the grid");

  const [columnVisibilityModalState, toggleModalColumnVisibility] = useState<boolean>(false);
  const [addLayoutModalState, toggleAddLayoutModal] = useState<boolean>(false);
  const [updateLayoutModalState, toggleUpdateLayoutModal] = useState<boolean>(false);

  const [layoutNo, setLayoutNo] = useState<number>(0);
  const [layoutName, setLayoutName] = useState<string>("");
  const [layoutSharedBy, setLayoutSharedBy] = useState<string>("");
  const [layoutNotes, setLayoutNotes] = useState<string>("");
  const [layoutDefault, setLayoutDefault] = useState<boolean>(false);
  const [layoutUsers, setLayoutUsers] = useState<Array<Options>>([]);
  const [layoutType, setLayoutType] = useState<string>("");
  const [usersList, setUsersList] = useState<Array<Options>>([]);
  const [addLayoutValidated, setAddLayoutValidated] = useState<boolean>(false);
  const [updateLayoutValidated, setUpdateLayoutValidated] = useState<boolean>(false);
  const [objectSearch, setObjectSearch] = useState<string>("");
  const [groupIndex, setGroupIndex] = useState<any>(null);
  const [itemsSource, setItemsSource] = useState<any>(new CollectionView([]));
  const [printOrientation, setPrintOrientation] = useState<any>({
    label: "Portrait",
    value: wjcPdf.PdfPageOrientation.Portrait
  });

  const printOrientationOptions: any = [
    {
      label: "Portrait",
      value: wjcPdf.PdfPageOrientation.Portrait
    },
    {
      label: "Landscape",
      value: wjcPdf.PdfPageOrientation.Landscape
    }
  ]

  const filterObjects = objects.filter(x => {
    const searchSplit = objectSearch.split(" ");
    if (!objectSearch || searchSplit.filter(y => x.header.toLowerCase().includes(y.toLowerCase())).length == searchSplit.length)
      return true;
  });
  // #endregion

  // #region lifecycle events

  useEffect(() => {
    if (settingSaveTimeout.current)
      clearTimeout(settingSaveTimeout.current);
    setColumnLoaded(false);
    setColumns([]);

  }, [history.location.pathname]);

  useEffect(() => {
    if (!dataAnalytics)
      dispatch(getDataAnalyticsConfig({ dataAnalyticsno: routeTo }));
  }, []);

  useEffect(() => {
    if (dataAnalytics?.grid && dataAnalytics?.dataLoaded && !columnLoaded) {
      const gridColumns = getColumns(dataAnalytics.grid.Header);
      const pivotFields: any = [];

      gridColumns.map((x: any) => {
        if (x.header.trim() != "") {
          const findSameColumn = gridColumns.find((y: any) => y.header == x.header && y.binding != x.binding);
          let aggregate = Aggregate.Cnt;

          let header = x.header;
          if (findSameColumn)
            header += ` (${x.binding})`;

          if (x.dataType == DataType.Number)
            aggregate = Aggregate.Sum;

          pivotFields.push({
            header,
            binding: x.binding,
            width: x.width,
            dataType: x.dataType,
            format: x.format,
            visible: x.visible,
            aggregate: aggregate,
            showAs: 0,
            descending: false,
            isContentHtml: false,
            key: header,
          });
        }
      });
      if (dataAnalytics.gridView?.pivotDefinition) {

        const pivotDefinition = JSON.parse(dataAnalytics.gridView.pivotDefinition);
        pivotDefinition.fields = pivotFields;

        pivotDefinition.columnFields.items = pivotDefinition.columnFields.items.filter((x: any) => pivotFields.findIndex((y: any) => y.key == x) != -1);
        pivotDefinition.filterFields.items = pivotDefinition.filterFields.items.filter((x: any) => pivotFields.findIndex((y: any) => y.key == x) != -1);
        pivotDefinition.rowFields.items = pivotDefinition.rowFields.items.filter((x: any) => pivotFields.findIndex((y: any) => y.key == x) != -1);
        pivotDefinition.valueFields.items = pivotDefinition.valueFields.items.filter((x: any) => pivotFields.findIndex((y: any) => y.key == x) != -1);

        dispatch(setGridView({
          objectId: activeTabKey as string, gridView: {
            ...dataAnalytics.gridView,
            pivotDefinition: JSON.stringify(pivotDefinition)
          }
        }));
      }

      setDataAnalyticPivot(
        new wjcOlap.PivotEngine({
          itemsSource: dataAnalytics.grid.Detail,
          autoGenerateFields: false,
          fields: pivotFields
        })
      );

      setColumns(gridColumns);
      setItemsSource(new CollectionView(dataAnalytics.grid.Detail))
      setColumnLoaded(true);
    }
  }, [dataAnalytics, dataAnalytics?.dataLoaded, columnLoaded]);

  const getUser = async () => {
    const response = await requestGetUsersList();
    setUsersList(response);
    return response;
  }

  useEffect(() => {
    if ((addLayoutModalState || updateLayoutModalState) && layoutUsers.length < 1) {
      getUser();
    }
  }, [addLayoutModalState, updateLayoutModalState]);

  // useEffect(() => {
  //   if (dataAnalytics?.appliedLayout && dataAnalytics?.grid && mainGridRef.current) {
  //     applyGridSetting(mainGridRef.current, filterRef.current.control, gridSearchRef.current.control, dataAnalytics.gridView);
  //   }
  // }, [dataAnalytics?.appliedLayout]);
  // #endregion

  // #region initializers
  const GridInitialized = (GridControl: any) => {
    mainGridRef.current = GridControl;
    var FilterControl = filterRef.current.control;
    let AllSearch = gridSearchRef.current.control;

    GridControl.allowPinning = "ColumnRange";
    GridControl.columnFooters.rows.push(new GroupRow());

    if (dataAnalytics) {
      applyGridSetting(GridControl, FilterControl, AllSearch, dataAnalytics.gridView);
      initObjects(GridControl);
    }

    FilterControl.filterChanged.addHandler((s: any, e: any) => {
      storeGridSetting();
    });

    AllSearch.grid = GridControl;
    AllSearch.lostFocus.addHandler((s: any, e: any) => {
      storeGridSetting();
    });
    AllSearch._getItemText = function (item: any) {
      let vals = [],
        cols = this._g.columns;
      for (let i = 0; i < cols.length; i++) {
        let col = cols[i],
          binding = col._binding;
        if (col.visible && binding) {
          let val = binding.getValue(item);
          if (col.dataMap) {
            val = col.dataMap.getDisplayValue(val);
          } else {
            val = Globalize.format(val, col.format);
          }
          vals.push(val);
        }
      }
      return vals.join(" ");
    };

    AllSearch.gotFocus.addHandler((s: any, e: any) => {
      // console.log("Refereshed");
    });

    GridControl.pinnedColumn.addHandler((s: any, e: any) => {
      storeGridSetting();
    });

    GridControl.resizedColumn.addHandler((s: any, e: any) => {
      storeGridSetting();
    });

    GridControl.draggedColumn.addHandler((s: any, e: any) => {
      storeGridSetting();
    });

    GridControl.collectionView.groupDescriptions.collectionChanged.addHandler((s: any, e: any) => {
      storeGridSetting();
    });

    GridControl.formatItem.addHandler(function (s: any, e: any) {
      var col = s.columns[e.col];
      var colConfig = dataAnalytics.grid?.Header.find(x => x.ColumnID == col.binding);

      if (!col) return;

      if (e.panel == s.cells) {
        if (colConfig?.hyperlinktransactioncode) {
          e.cell.innerHTML = `<span class="grid-hyperlink jumpto-link" role="button">${e.cell.innerHTML}</span>`;
        }
      }
    });

    GridControl.cells.hostElement.addEventListener("click", (e: any) => {
      var ht = GridControl.hitTest(e);
      if (ht.row != -1) {
        var col = GridControl.columns[ht.col];
        var colConfig = dataAnalytics.grid?.Header.find(x => x.ColumnID == col.binding);
        let row = GridControl.rows[ht.row].dataItem;

        if (ht.target.classList.value.includes("jumpto-link")) {
          var hyperlinkjump = colConfig?.hyperlinktransactioncode as string;
          var transactionNo = row[col.binding];
          if (transactionNo)
            openHyperLink(hyperlinkjump, transactionNo)
        }
      }
    });

    GridControl.selectionChanged.addHandler(() => {
      if (!GridControl.selection.isSingleCell) {
        let stats = getSelectionStats(GridControl);
        let fmt = stats.sum != null
          ? "Avg: <b>{avg:n2}</b>, Count: <b>{cnt:n0}</b>, Sum: <b>{sum:n2}</b>"
          : "Count: <b>{cnt:n0}</b>";
        setLogText(wijmoFormat(fmt, stats));
      }
      else {
        setLogText("Please select a range on the grid");
      }
    });

  }

  const PivotGridInitialized = (PivotGridControl: any) => {
    pivotGridRef.current = PivotGridControl;
  }

  const PivotPanelInitialized = (PivotControl: any) => {
    pivotPanelRef.current = PivotControl;

    PivotControl.viewDefinitionChanged.addHandler((s: any, e: any) => {
      storeGridSetting();
    });
  }

  const pivotChartInitialized = (ChartControl: any) => {
    pivotChartRef.current = ChartControl;

    if (ChartControl?.flexChart) {
      ChartControl.flexChart.palette = [
        'rgba(1, 70, 129, 1)',
        'rgba(0, 154, 174, 1)',
        'rgba(0, 192, 156, 1)',
        'rgba(135, 225, 127, 1)',
        'rgba(249, 248, 113, 1)',
        'rgba(100, 149, 237, 1)',
        'rgba(70, 130, 180, 1)',
        'rgba(32, 178, 170, 1)',
        'rgba(127, 255, 0, 1)',
        'rgba(255, 255, 0, 1)',
        'rgba(205, 92, 92, 1)',
        'rgba(128, 0, 0, 1)',
        'rgba(144, 238, 144, 1)',
        'rgba(46, 139, 87, 1)',
        'rgba(240, 230, 140, 1)',
        'rgba(255, 215, 0, 1)',
        'rgba(205, 133, 63, 1)',
        'rgba(233, 150, 122, 1)',
        'rgba(255, 99, 71, 1)',
        'rgba(255, 165, 0, 1)'
      ]
    }

    if (ChartControl?.flexPie) {
      ChartControl.flexPie.pallete = [
        'rgba(1, 70, 129, 1)',
        'rgba(0, 154, 174, 1)',
        'rgba(0, 192, 156, 1)',
        'rgba(135, 225, 127, 1)',
        'rgba(249, 248, 113, 1)',
        'rgba(100, 149, 237, 1)',
        'rgba(70, 130, 180, 1)',
        'rgba(32, 178, 170, 1)',
        'rgba(127, 255, 0, 1)',
        'rgba(255, 255, 0, 1)',
        'rgba(205, 92, 92, 1)',
        'rgba(128, 0, 0, 1)',
        'rgba(144, 238, 144, 1)',
        'rgba(46, 139, 87, 1)',
        'rgba(240, 230, 140, 1)',
        'rgba(255, 215, 0, 1)',
        'rgba(205, 133, 63, 1)',
        'rgba(233, 150, 122, 1)',
        'rgba(255, 99, 71, 1)',
        'rgba(255, 165, 0, 1)'
      ]
    }
  };

  const GroupPanelMenuInitialized = (MenuControl: any) => {
    contextMenuRef.current = MenuControl;
  }

  const GroupPanelInitialized = (GroupPanelControl: any) => {
    groupPanelRef.current = GroupPanelControl;

    GroupPanelControl.hostElement.addEventListener('contextmenu', (e: any) => {
      var _a;
      let groupDescription = GroupPanelControl.hitTest(e), cv = GroupPanelControl.collectionView;
      if (groupDescription) {
        setGroupIndex(cv.groupDescriptions.indexOf(groupDescription));
        (_a = contextMenuRef.current) === null || _a === void 0 ? void 0 : _a.show(e);
      }
      e.preventDefault();
    });
  }

  const chartExportToSvg = () => {
    pivotChartRef.current.saveImageToFile("FlexChart.svg");
  }

  const chartExportToPng = () => {
    pivotChartRef.current.saveImageToFile("FlexChart.png");
  }

  const chartExportToJpg = () => {
    pivotChartRef.current.saveImageToFile("FlexChart.jpg");
  }
  // #endregion

  // #region methods


  const PivotGridShowRowTotalsClick = (e: any) => {
    pivotGridRef.current.engine.showRowTotals = e.target.checked ? wjcOlap.ShowTotals.Subtotals : wjcOlap.ShowTotals.None;
    setShowRowsTotal(!showRowsTotal);
  }

  // commons
  const blobToBase64 = async (blob: any) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const getColumns = (columns: Array<IHelpHeader & { showsummary: boolean }>) => {
    var obj: any = columns.map((x) => {
      const header = x.ColumnText;
      const binding = x.ColumnID;
      let dataType = DataType.String;
      const visible = !x.ishidden;
      const width = 180;
      let format = null;
      let cssClass = "";
      let aggregate = Aggregate.None;
      // let wordWrap = true;
      // let multiLine = true;

      if (BaseMethods.getControlType(x.controltype) == "checkbox") {
        cssClass = "switch";
        dataType = DataType.Boolean;
      } else if (BaseMethods.getControlType(x.controltype) == "date") {
        format = dataFormat.find(y => y.fielddataformatno == x.fielddataformatno)?.fielddataformatmasking || constant.DefaultDateFormat;
        dataType = DataType.Date;
      } else if (BaseMethods.getControlType(x.controltype) == "time") {
        format = dataFormat.find(y => y.fielddataformatno == x.fielddataformatno)?.fielddataformatmasking || constant.DefaultTimeFormat;
        dataType = DataType.Date;
      } else if (BaseMethods.getControlType(x.controltype) == "number") {
        format = dataFormat.find(y => y.fielddataformatno == x.fielddataformatno)?.fielddataformatmasking || constant.DefaultNumber;
        dataType = DataType.Number;
        
        if (x.showsummary)
          aggregate = Aggregate.Sum
      }

      return {
        header,
        binding,
        visible: binding == "prid" ? false : visible,
        width,
        dataType,
        cssClass,
        format,
        aggregate,
        // wordWrap,
        // multiLine
      };
    });

    obj.push({
      header: " ",
      binding: "endcol",
      width: "*",
      dataType: DataType.String,
      cssClass: "",
      format: "",
      visible: true
    });

    return obj;
  };

  const loadDataAnalyticsFunc = () => {

    dataAnalytics.criteria.map(x => {
      if (x.validatemandatory) {
        if (!dataAnalytics.modelValue[x.fieldid].fromValue) {
          dispatch(setGlobalMessage({ error: new Error(`Please fill Mandatory field ${x.fieldlabel}`) }));
          throw null;
        }
      }
    });

    setColumnLoaded(false);

    setFilterApplying(true);
    dispatch(loadDataAnalytics({
      dataAnalyticsno: dataAnalytics.objectConfiguration.dataanalyticsno, onSuccess: () => {
        if (dataAnalytics.isCriteriaOpen)
          dispatch(toggleCriteria({ dataanalyticsno: activeTabKey }));
      }
    }));
  };

  const closeDataAnalytics = () => {
    dispatch(closeTab(activeTabKey));
  }

  const setActiveTabFunc = (tab: string) => {
    dispatch(setActiveTab({ dataanalyticsno: activeTabKey, tab }));
  }

  const criteriaField = (field: ICriteriaFieldConfig, index: number) => {
    const fieldModel = dataAnalytics.modelValue[field.fieldid];
    return (
      <div className="d-flex" key={index}>
        <AlignInput
          key={`from-${field.fieldid}`}
          fieldId={field.fieldid}
          config={{ FieldConfig: [field], BaseFieldStateSet }}
          customFieldValue={fieldModel.fromValue || null}
          customFieldVisible={true}
          customFieldEnable={true}
          customFieldHelpObject={field.helpobjectno || ""}
          customFieldHelpWhere={field.helpwhere || ""}
          customValidateMandatory={field.validatemandatory}
          customHyperLinkJump={field.hyperlinkjump || ""}
          custom={true}
          uniqueKey="from"
          customHelpParams={{
            helpOpenIn: EHelpOpenIn.DataAnalytics,
            multiSelect: field.multiplevalue
          }}
        />
        {
          field.filteroption ?
            <div className="filter-container">
              <DropdownButton
                className="ms-2"
                variant="filter"
                as={ButtonGroup}
                title={<Icon icon="Filter" size="14" color="#575757" className="mx-1" />}
                onClick={() => {
                  document.querySelectorAll(`.filter-container .dropdown-menu:not(#filter-option-${field.fieldid})`).forEach((x) => x.classList.remove("show"))
                  document.getElementById(`filter-option-${field.fieldid}`)?.classList.toggle("show")
                }}>
                {
                  filterOption.filter(x => !x.filterSymbol.isEqual("cs") && x.fieldType.includes(BaseMethods.getControlType(field.controltypeno))).map((x, i) => (
                    <Dropdown.Item
                      key={x.filterSymbol}
                      className={classNames({ active: x.filterSymbol == fieldModel.filterType })}
                      role="button"
                      onClick={() => BaseSetFieldFilter(field.fieldid, x.filterSymbol, fieldModel.caseSensitive)}
                    >
                      {x.filterText}
                    </Dropdown.Item>
                  ))
                }
                <Dropdown.Divider />
                {
                  filterOption.filter(x => x.filterSymbol.isEqual("cs") && x.fieldType.includes(BaseMethods.getControlType(field.controltypeno))).map((x, i) => (
                    <Dropdown.Item
                      key={x.filterSymbol}
                      className={classNames({ active: fieldModel.caseSensitive })}
                      role="button"
                      onClick={() => BaseSetFieldFilter(field.fieldid, fieldModel.filterType, !fieldModel.caseSensitive)}
                    >
                      {x.filterText}
                    </Dropdown.Item>
                  ))
                }
              </DropdownButton>
            </div>
            : null
        }

        <span className="to-value">
          {
            fieldModel.filterType.isEqual("not between", "between") ?
              <AlignInput
                key={`to-${field.fieldid}`}
                fieldId={field.fieldid}
                config={{ FieldConfig: [field], BaseFieldStateSet: (fieldId: string, newValue: any, helpSelected: boolean) => BaseFieldStateSet(fieldId, newValue, helpSelected, true) }}
                customFieldValue={fieldModel.toValue || null}
                customFieldVisible={true}
                customFieldEnable={true}
                customFieldHelpObject={field.helpobjectno || ""}
                customFieldHelpWhere={field.helpwhere || ""}
                customValidateMandatory={field.validatemandatory}
                customHyperLinkJump={field.hyperlinkjump || ""}
                custom={true}
                uniqueKey="to"
                customHelpParams={{
                  helpOpenIn: EHelpOpenIn.DataAnalytics
                }}
              />
              : null
          }
        </span>
      </div>
    );
  }

  const toggleCriteriaFunc = () => {
    dispatch(toggleCriteria({ dataanalyticsno: activeTabKey }));
  }

  const BaseFieldStateSet = (fieldId: string, newValue: any, helpSelected: boolean = false, toValue: boolean = false) => {
    dispatch(setDataAnalyticsModelValue({ fieldId, newValue, toValue }));
  };

  const BaseSetFieldFilter = (fieldId: string, filterType: string, caseSensitive: boolean) => {
    dispatch(setFieldFilter({ fieldId, filterType, caseSensitive }));
    document.querySelectorAll(`.filter-container .dropdown-menu`).forEach((x) => x.classList.remove("show"))
  };

  // grid
  const handleItemClicked = (menu: any) => {
    let cv = mainGridRef.current.collectionView;
    switch (menu.selectedIndex) {
      case 0: // expand all
        mainGridRef.current.collapseGroupsToLevel(groupIndex + 1);
        break;
      case 1: // collapse all
        mainGridRef.current.collapseGroupsToLevel(groupIndex);
        break;
      case 3: // sort asc
      case 4: // sort desc
      case 5: // no sort
        cv.deferUpdate(() => {
          cv.sortDescriptions.clear();
          if (menu.selectedIndex != 5) {
            let binding = cv.groupDescriptions[groupIndex].propertyName;
            cv.sortDescriptions.push(new SortDescription(binding, menu.selectedIndex == 3));
          }
        });
        break;
      case 7: // remove group
        cv.groupDescriptions.removeAt(groupIndex);
        break;
    }
  };

  const gridExportToExcel = function () {
    // create book with current view
    var book = wjcGridXlsx.FlexGridXlsxConverter.saveAsync(mainGridRef.current, {
      includeColumnHeaders: true
    });
    book.sheets[0].name = 'PivotGrid';
    // save the book
    book.saveAsync(`${dataAnalytics.objectConfiguration.processcodestxt}.xlsx`);
  }

  //#region Grid Export to PDF

  const generatePDFReport = () => {
    let doc = createPDFInstance(reportName);

    doc.moveDown(15);

    doc.ended.removeAllHandlers();
    doc.ended.addHandler((sender: any, args: any) => {
      blobToBase64(args.blob)
        .then(base64String => setReport(base64String));
    });

    const font = new wjcPdf.PdfFont('samsungOne');
    const brush = new wjcPdf.PdfSolidBrush();
    // print information section
    drawPrintInformation({ doc, font, brush });
    drawCriteriaInformation({ doc, font, brush });

    font.size = 10;
    font.weight = 'bold';
    brush.color = new Color("#000");

    if (showLookupInReport) {
      (document.getElementsByClassName("main_grid_tab") as any)[0].style.display = "block";
      (document.getElementsByClassName("main_grid_tab") as any)[0].style.position = "absolute";

      doc.drawText("Lookup", 0, doc.y + 15, {
        font,
        brush
      }).size.height;

      doc.moveDown();

      drawGridForPDF(doc, mainGridRef.current, false);

      (document.getElementsByClassName("main_grid_tab") as any)[0].style.display = "";
      (document.getElementsByClassName("main_grid_tab") as any)[0].style.position = "";
    }

    if (showPivotInReport) {
      (document.getElementsByClassName("pivot_grid_tab") as any)[0].style.display = "block";
      (document.getElementsByClassName("pivot_grid_tab") as any)[0].style.position = "absolute";
      doc.drawText("Pivot", 0, doc.y + 15, {
        font,
        brush
      });

      doc.moveDown();

      drawGridForPDF(doc, pivotGridRef.current, true);

      (document.getElementsByClassName("pivot_grid_tab") as any)[0].style.display = "";
      (document.getElementsByClassName("pivot_grid_tab") as any)[0].style.position = "";
    }

    doc.end();
  }

  const gridExportToPdf = (gridRef: any, isPivot: boolean) => {
    // Create a new PDF document

    let doc = createPDFInstance();

    drawGridForPDF(doc, gridRef.current, isPivot);
    doc.end();
  };

  const createPDFInstance = (customReportName: string = "") => {

    var reportTitle: any = "";
    if (customReportName)
      reportTitle = customReportName;
    else
      reportTitle = dataAnalytics.appliedLayout == 0 ? dataAnalytics.objectConfiguration.processcodestxt : dataAnalytics.layouts.find(x => x.layoutno == dataAnalytics.appliedLayout)?.layoutstxt;

    let doc = new wjcPdf.PdfDocument({
      footer: {
        declarative: {
          text: '\t&[Page]\\&[Pages]\tPrinted with Businessflo | www.businessflo.com',
          brush: '#000',
          font: new wjcPdf.PdfFont('samsungOne', 10)
        }
      },
      info: {
        title: reportTitle
      },
      pageSettings: {
        layout: printOrientation.value,
        size: wjcPdf.PdfPageSize.A4,
        margins: {
          top: 80,
          bottom: 10,
          right: 10,
          left: 10
        }
      },
      ended: (sender: any, args: any) => {
        wjcPdf.saveBlob(args.blob, `${reportTitle}.pdf`);
      }
    });

    // load fonts
    doc.registerFont({
      source: require("@/Assets/Fonts/SamsungOne-400.ttf").default,
      name: 'samsungOne',
      style: 'normal',
      weight: 'normal',
      sansSerif: true
    });
    doc.registerFont({
      source: require("@/Assets/Fonts/SamsungOne-700.ttf").default,
      name: 'samsungOne',
      style: 'normal',
      weight: 'bold',
      sansSerif: true
    });

    // add header on every page
    doc.pageAdded.addHandler((args: any) => {
      createHeader(doc, reportTitle);
      doc.moveDown(15);
    });

    createHeader(doc, reportTitle);


    return doc;
  }

  const createHeader = (doc: wjcPdf.PdfDocument, reportTitle: string) => {

    // init variables
    const font = new wjcPdf.PdfFont('samsungOne', 10);
    const brush = new wjcPdf.PdfSolidBrush();
    const logoImg = `data:image/png;base64,${clientLogo}`;
    var reportSubtitle = dataAnalytics.appliedLayout == 0 ? 'Default Layout' : dataAnalytics.layouts.find((x: any) => x.layoutno == dataAnalytics.appliedLayout && x.layouttype == dataAnalytics.appliedLayoutType)?.layoutstxt || "";

    // draw company logo
    doc.header.drawImage(logoImg, 0, -60, {
      width: 60
    });

    // draw vertical separator
    // Set the line coordinates and color
    const separatorX = 70; // X-coordinate of the vertical separator line
    const separatorY1 = -60; // Y-coordinate of the top of the vertical separator line
    const separatorY2 = 0; // Y-coordinate of the bottom of the vertical separator line
    const lineWidth = 1; // Adjust the line width as needed
    const lineColor = new Color('#CBCBCB'); // Set color to #CBCBCB

    const pen = new wjcPdf.PdfPen(lineColor, lineWidth);

    // Draw the vertical separator line
    doc.header.paths
      .moveTo(separatorX, separatorY1)
      .lineTo(separatorX, separatorY2)
      .stroke(pen);

    // data analytics name
    font.weight = 'bold';
    font.size = 12;
    brush.color = new Color("#007db8");

    doc.header.drawText(reportTitle, 80, -36, {
      font,
      brush
    });

    // layout name
    font.weight = 'normal'
    font.size = 10;
    brush.color = new Color("#000");

    doc.header.drawText(reportSubtitle, 80, -24, {
      font,
      brush
    })
  }

  const drawGridForPDF = (doc: wjcPdf.PdfDocument, gridControl: any, isPivot: boolean) => {

    if (!isPivot)
      gridControl.headersVisibility = "Column";

    gridControl.autoRowHeights = true;

    const originalColumnWidths = gridControl.columns.map((col: any) => col.width);

    gridControl.columns.forEach((col: any) => {
      col.wordWrap = true;
      col.multiLine = true;
      if (col.dataType.isEqual(DataType.Number, DataType.Date)) {
        col.width = 120;
      }
    });

    if (isPivot)
      gridControl.rowHeaders.columns.forEach((col: any) => {
        col.wordWrap = true;
      });

    gridControl.autoSizeRows();


    wjcGridPdf.FlexGridPdfConverter.draw(gridControl, doc, doc.width, undefined, {
      formatItem: (args: any) => {

        var row = args.panel.rows[args.row];

        const font = new wjcPdf.PdfFont("samsungOne");
        args.style.verticalAlign = "top";
        args.style.font = font;
        args.style.color = "#000";
        args.style.borderColor = "rgba(34,36,38,.15)";

        if (
          args.panel.cellType == CellType.ColumnHeader ||
          args.panel.cellType == CellType.RowHeader ||
          args.panel.cellType == CellType.TopLeft ||
          args.panel.cellType == CellType.ColumnFooter ||
          (args.panel.cellType == CellType.Cell && row instanceof GroupRow)
        ) {
          font.weight = "bold";
          args.style.backgroundColor = "#eee";
        }

      },
      progress: updateProgress,

    });

    if (!isPivot)
      gridControl.headersVisibility = "All";

    gridControl.autoRowHeights = false;

    gridControl.columns.forEach((col: any, index: number) => {
      col.width = originalColumnWidths[index];
      col.wordWrap = false;
      col.multiLine = false;
    });

    if (isPivot)
      gridControl.rowHeaders.columns.forEach((col: any) => {
        col.wordWrap = false;
      });

    gridControl.autoSizeRows();

  }

  const drawPrintField = ({ label, value, doc, font, brush, y, prevFieldWidth }: IDrawFieldProps) => {
    var emptyFieldInfo = {
      charCount: 0,
      size: {
        width: 0,
        height: 0
      }
    }
    var fieldInfo: IPrintFieldInfo = {
      field: emptyFieldInfo,
      value: emptyFieldInfo
    };

    var fieldX, valueX: number = 0;
    var gap = 5;
    var prevFieldGap = prevFieldWidth ? prevFieldWidth + 40 : 0;

    fieldX = prevFieldGap;
    font.weight = "bold";
    fieldInfo.field = doc.drawText(label, fieldX, y, {
      font,
      brush
    });

    if (value) {
      valueX = fieldInfo.field.size.width + gap + fieldX;
      font.weight = "normal";
      fieldInfo.value = doc.drawText(value, valueX, y, {
        font,
        brush
      });
    }

    var totalGap = fieldX + valueX + fieldInfo.value.size.width;

    return totalGap;
  }

  const drawPrintInformation = ({ doc, brush, font }: IDrawPrintInformation) => {
    var dataAnalyticsName = dataAnalytics.objectConfiguration.processcodestxt;
    var layoutName = dataAnalytics.layouts.find(x => x.layoutno == dataAnalytics.appliedLayout && x.layouttype == dataAnalytics.appliedLayoutType)?.layoutstxt || "";

    font.size = 10;
    font.weight = 'bold';
    brush.color = new Color("#000");

    doc.drawText("Print Information", 0, 0, {
      font,
      brush
    });

    // fields
    font.size = 8;

    const fieldProps: IDrawFieldProps[] = [
      { label: "Data Analytics:", value: dataAnalyticsName, doc, font, brush, y: 20 },
      { label: "Print User:", value: user, doc, font, brush, y: 20 },
      { label: "Layout:", value: layoutName, doc, font, brush, y: 30 },
      { label: "Print Date:", value: BaseMethods.convertDateToCompanyFormat(new Date().toDateString(), constant.DefaultDateFormat), doc, font, brush, y: 30 },
    ];

    let maxPrevFieldWidth: number = 0;
    let prevFieldWidth: number = 0;
    fieldProps.forEach((fieldProp, index) => {
      if ((index + 1) % 2 === 1) {
        prevFieldWidth = drawPrintField({ ...fieldProp });
        if (prevFieldWidth > maxPrevFieldWidth) {
          maxPrevFieldWidth = prevFieldWidth;
        }
      }
    });

    fieldProps.forEach((fieldProp, index) => {
      if ((index + 1) % 2 === 0) {
        drawPrintField({ ...fieldProp, prevFieldWidth: maxPrevFieldWidth });
      }
    });
  }

  const drawCriteriaField = ({ label, fromValue, filterType, toValue, controlType, doc, font, brush, y, prevFieldWidth }: ICriteriaFieldProps) => {
    var emptyFieldInfo = {
      charCount: 0,
      size: {
        width: 0,
        height: 0
      }
    }
    var fieldInfo: ICriteriaFieldInfo = {
      field: emptyFieldInfo,
      fromValue: emptyFieldInfo,
      filterType: emptyFieldInfo,
      toValue: emptyFieldInfo,
    };

    var fieldX: number, fromValueX: number, filterTypeX: number, toValueX: number;
    fieldX = fromValueX = filterTypeX = toValueX = 0;

    var gap = 5;
    var prevFieldGap = prevFieldWidth ? prevFieldWidth + 40 : 0;

    fieldX = prevFieldGap;

    font.weight = "bold";
    brush.color = new Color("#000");
    fieldInfo.field = doc.drawText(label, fieldX, y, {
      font,
      brush
    });

    fromValueX = fieldInfo.field.size.width + gap + fieldX;
    if (fromValue) {
      let val1 = fromValue;
      if (BaseMethods.getControlType(controlType) == "date") {
        val1 = BaseMethods.convertDateToCompanyFormat(val1, constant.DefaultDateFormat);
      }
      else if (BaseMethods.getControlType(controlType) == "time") {
        val1 = BaseMethods.convertDateToCompanyFormat(val1, constant.DefaultTimeFormat);
      }
      else if (BaseMethods.getControlType(controlType) == "checkbox") {
        if ((val1 || "").toString().toLowerCase() == "true")
          val1 = "Yes";
        else
          val1 = "No";
      }

      font.weight = "normal";
      fieldInfo.fromValue = doc.drawText(val1, fromValueX, y, {
        font,
        brush
      });

      filterTypeX = fieldInfo.fromValue.size.width + gap + fromValueX;
      font.weight = "normal";
      brush.color = new Color("#007db8");
      fieldInfo.filterType = doc.drawText(filterType, filterTypeX, y, {
        font,
        brush
      });

      if (toValue) {

        let val2 = toValue;
        if (BaseMethods.getControlType(controlType) == "date") {
          val2 = BaseMethods.convertDateToCompanyFormat(val2, constant.DefaultDateFormat);
        }
        else if (BaseMethods.getControlType(controlType) == "time") {
          val2 = BaseMethods.convertDateToCompanyFormat(val2, constant.DefaultTimeFormat);
        }
        else if (BaseMethods.getControlType(controlType) == "checkbox") {
          if ((val2 || "").toString().toLowerCase() == "true")
            val2 = "Yes";
          else
            val2 = "No";
        }

        toValueX = fieldInfo.filterType.size.width + gap + filterTypeX;
        font.weight = "normal";
        fieldInfo.toValue = doc.drawText(val2, toValueX, y, {
          font,
          brush
        });
      }
    }

    var totalGap = fieldX + fromValueX + filterTypeX + toValueX;

    return totalGap;
  }

  const drawCriteriaInformation = ({ doc, brush, font }: IDrawPrintInformation) => {
    const criteriaObjects = [...dataAnalytics.FICriteria, ...dataAnalytics.criteria];
    const yStart = 70;
    var y = yStart;
    const criteriaFields = criteriaObjects.map((object, index) => {
      let id = object.fieldid;
      let label = object.fieldlabel + ":";
      let controlType = object.controltypeno;

      let modelValue = dataAnalytics.modelValue;
      let fieldModel = modelValue[id];
      let filterType = filterOption.find(x => x.filterSymbol == fieldModel.filterType)?.filterText || "";
      let fromValue = fieldModel.fromValue?.toString();
      let toValue = fieldModel.toValue;

      let criteriaFieldInfo: ICriteriaFieldProps = { label, fromValue, filterType, controlType, toValue, doc, font, brush, y };

      // Increment y for even pairs
      if ((index + 1) % 2 === 0) {
        y += 10;
      }

      return criteriaFieldInfo;
    })

    if (criteriaObjects.length) {
      font.size = 10;
      font.weight = 'bold';
      brush.color = new Color("#000");

      doc.drawText("Criteria Information", 0, 50, {
        font,
        brush
      });
    }

    // fields
    font.size = 8;
    brush.color = new Color("#000");

    let maxPrevFieldWidth: number = 0;
    let prevFieldWidth: number = 0;
    criteriaFields.forEach((fieldProp, index) => {
      if ((index + 1) % 2 === 1) {
        prevFieldWidth = drawCriteriaField({ ...fieldProp });
        if (prevFieldWidth > maxPrevFieldWidth) {
          maxPrevFieldWidth = prevFieldWidth;
        }
      }
    });

    criteriaFields.forEach((fieldProp, index) => {
      if ((index + 1) % 2 === 0) {
        drawCriteriaField({ ...fieldProp, prevFieldWidth: maxPrevFieldWidth });
      }
    });
  }

  const updateProgress = (exportProgress: any) => {
    if (exportProgress < 1 && !loader) {
      dispatch(toggleLoader(true));
      dispatch(loaderText("The task is being executed, Please wait until it is completed."))
    }
    else {
      dispatch(toggleLoader(false));
    }
  }

  //#endregion

  const openHyperLink = (processcode: string, transactionNo: string) => {
    dispatch(getScreenConfig({ processCode: processcode, transactionNo: transactionNo }));
  }

  const getSelectionStats = (grid: any) => {
    let sel = grid.selection, cnt = 0, ncnt = 0, sum = 0;
    for (let r = sel.topRow; r <= sel.bottomRow; r++) {
      for (let c = sel.leftCol; c <= sel.rightCol; c++) {
        let val = grid.cells.getCellData(r, c, false);
        if (val != null) {
          cnt++;
          if (wijmoIsNumber(val)) {
            ncnt++;
            sum += val;
          }
        }
      }
    }
    return {
      cnt: cnt,
      sum: ncnt > 0 ? sum : null,
      avg: ncnt > 0 ? sum / ncnt : null
    };
  }

  const applyGridSetting = (GridControl: any, FilterControl: any, AllSearch: any, view: IGridView) => {
    if (gridViewApplyTimeout.current)
      clearTimeout(gridViewApplyTimeout.current);

    gridViewApplyTimeout.current = setTimeout(() => {
      const gridView = view;

      if (gridView.filterDefinition) {
        FilterControl.filterDefinition = gridView.filterDefinition;
      }

      let collection = new CollectionView(dataAnalytics?.grid?.Detail || [], {
        groupDescriptions: gridView.groupDescriptions,
      })

      collection.groupDescriptions.collectionChanged.addHandler((s: any, e: any) => {
        storeGridSetting();
      });

      setItemsSource(collection);

      if (gridView.pivotDefinition) {
        pivotPanelRef.current.viewDefinition = gridView.pivotDefinition;
      }

      AllSearch.text = gridView.allSearchText;

      const userColumnIndex = gridView.columnIndex;

      let index = 0;
      userColumnIndex.map(x => {
        const column = GridControl.columns.getColumn(x);
        if (column) {
          GridControl.columns.moveElement(column.index, index);
          column.width = gridView.columnWidth[index];

          const hidden = gridView.columnHidden.find(x => x == column.binding);
          if (hidden)
            column.visible = false;
          else
            column.visible = true;
          // * this is important work don't remove it without understanding
          index++;
        }
      });

      GridControl.frozenColumns = gridView.frozenColumns
    }, 100);
  }

  const storeGridSetting = () => {
    if (settingSaveTimeout.current)
      clearTimeout(settingSaveTimeout.current);

    settingSaveTimeout.current = setTimeout(() => {
      const filtersetting: IGridView = {
        allSearchText: gridSearchRef.current.control.text,
        filterDefinition: filterRef.current.control.filterDefinition,
        groupDescriptions: Object.values(mainGridRef.current.collectionView.groupDescriptions)
          .filter((item: any) => item._bnd)
          .map((item: any) => item._bnd._key as string),
        frozenColumns: mainGridRef.current.frozenColumns,
        columnIndex: mainGridRef.current.columns.map((x: any) => x.binding),
        columnWidth: mainGridRef.current.columns.map((x: any) => x.width),
        columnHidden: mainGridRef.current.columns.filter((x: any) => !x.visible).map((x: any) => x.binding),
        pivotDefinition: pivotPanelRef.current.viewDefinition
      }

      dispatch(setGridView({ objectId: activeTabKey as string, gridView: filtersetting }));
    }, 500);
  }

  // pivot 
  const pivotExportToExcel = function () {
    // create book with current view
    var book = wjcGridXlsx.FlexGridXlsxConverter.saveAsync(pivotGridRef.current, {
      includeColumnHeaders: true,
      includeRowHeaders: true
    });
    book.sheets[0].name = 'PivotGrid';
    // save the book
    book.saveAsync(`${dataAnalytics.objectConfiguration.processcodestxt}.xlsx`);
  }

  const onChartTypeChanged = (combo: any) => {
    pivotChartRef.current.chartType = wjcOlap.PivotChartType[combo.text];
  };

  // favorite
  const BaseSetUserFavorite = (objectcontrollerno: string, objectcontrollerstxt: string, applicationobjecttype: string) => {
    dispatch(setUserFavorite({ objectcontrollerno, objectcontrollerstxt, applicationobjecttype }))
  }

  // column visibility 
  const clearAll = () => {
    setObjects(objects.map(x => ({ ...x, selected: false })));
  }

  const selectAll = () => {
    setObjects(objects.map(x => ({ ...x, selected: true })));
  }

  const initObjects = (GridControl: any, firstTime: boolean = false) => {
    const Header: any = dataAnalytics.grid?.Header;

    var updatedObjects: any = Header.map((x: any) => ({
      header: x.ColumnText,
      columnId: x.ColumnID,
      selected: GridControl.columns.getColumn(x.ColumnID)?.visible,
    }));

    setObjects(updatedObjects);
  }

  const updateObject = (columnId: string) => {
    const updatedObjects = objects.map((object) => {
      if (object.columnId == columnId) {
        object.selected = !object.selected;
      }

      return object;
    })
    setObjects(updatedObjects)
  }

  const saveColumnVisibilitySetting = () => {
    mainGridRef.current.columns.forEach((column: any) => {
      const gridColumnObject: any = objects.find(x => x.columnId == column.binding);

      if (gridColumnObject) {
        if (column.visible != gridColumnObject.selected) {
          column.visible = gridColumnObject.selected;
        }
      }
    });

    storeGridSetting();
    initObjects(mainGridRef.current);
  }

  const openColumnSetting = () => {
    initObjects(mainGridRef.current)
    toggleModalColumnVisibility(true);
  }

  const closeColumnSetting = () => {
    document.getElementById("grid-menu-column")?.click();
    document.getElementById("grid-menu-button")?.click();
    initObjects(mainGridRef.current);
  }

  // modals
  const handleAddLayoutModalOpen = () => {
    toggleAddLayoutModal(true);
  }

  const handleAddLayoutModalClose = () => {
    toggleAddLayoutModal(false);
  }

  const handleUpdateLayoutModalOpen = ({ layoutno, layoutstxt, sharedto, isdefault, notes, layouttype, sharedby }: { layoutno: number, layoutstxt: string, sharedto: string, isdefault: boolean, notes: string, layouttype: string, sharedby?: string }) => {
    setLayoutNo(layoutno);
    setLayoutName(layoutstxt);
    setLayoutDefault(isdefault);
    setLayoutNotes(notes);
    setLayoutType(layouttype);

    if (sharedto.split(',').length >= 1) {
      let selectedUsers = usersList.filter(x => sharedto.split(',').includes(x.value));
      setLayoutUsers(selectedUsers);
    }

    if (sharedby) {
      setLayoutSharedBy(sharedby);
    }

    toggleUpdateLayoutModal(true);
  }

  const handleUpdateLayoutModalClose = () => {
    toggleUpdateLayoutModal(false);
    resetState();
  }

  const handleColumnVisibilityModalClose = () => {
    toggleModalColumnVisibility(false);
    closeColumnSetting();
  }

  // layout
  const addLayout = () => {
    const dataanalyticsno = dataAnalytics.objectConfiguration.dataanalyticsno;
    const layout = JSON.stringify({ modelValue: dataAnalytics.modelValue, gridView: dataAnalytics.gridView, reportName });

    dispatch(storeLayout({ layoutstxt: layoutName, notes: layoutNotes, dataanalyticsno, layout, isdefault: layoutDefault, sharedto: layoutUsers.map(x => x.value).join(","), layouttype: "Mine" }));
    toggleAddLayoutModal(false);
    resetState();
  }

  const handleAddLayout = (e: any) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setAddLayoutValidated(true);
      handleAddLayoutModalClose();
    }
    else {
      addLayout();
    }
  };

  const updateLayoutFunc = () => {
    const dataanalyticsno = dataAnalytics.objectConfiguration.dataanalyticsno;
    const layout = JSON.stringify({ modelValue: dataAnalytics.modelValue, gridView: dataAnalytics.gridView });

    dispatch(updateLayout({ layoutno: layoutNo, layoutstxt: layoutName, notes: layoutNotes, dataanalyticsno, layout, isdefault: layoutDefault, sharedto: layoutUsers.map(x => x.value).join(","), layouttype: layoutType }));
    handleUpdateLayoutModalClose();
    resetState();
  }

  const handleUpdateLayout = (e: any) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setUpdateLayoutValidated(true);
    }
    else {
      updateLayoutFunc();
    }
  };

  const onLayoutNameChange = (e: any) => {
    setLayoutName(e.target.value);
  }

  const onLayoutNotesChange = (e: any) => {
    setLayoutNotes(e.target.value);
  }

  const onLayoutDefaultChange = (e: any) => {
    setLayoutDefault(e.target.checked);
  }

  const onLayoutUsersSelectionChange = (e: any) => {
    setLayoutUsers(e);
  }

  const onPrintOrientationChange = (e: any) => {
    setPrintOrientation(e);
  }

  const resetState = () => {
    setLayoutName("");
    setLayoutNotes("");
    setLayoutSharedBy("");
    setLayoutDefault(false);
    setLayoutUsers([]);
    setLayoutType("");
  }

  const applyLayoutFunc = (layout: IDataAnalyticsLayout) => {
    dispatch(applyLayout({
      layoutno: layout.layoutno, layouttype: layout.layouttype, onSuccess: (object: any) => {
        object.criteria.map((x: any) => {
          if (x.validatemandatory) {
            if (!object.modelValue[x.fieldid].fromValue) {
              dispatch(setGlobalMessage({ error: new Error(`Please fill Mandatory field ${x.fieldlabel}`) }));
              throw null;
            }
          }
        });

        dispatch(loadDataAnalytics({
          dataAnalyticsno: dataAnalytics.objectConfiguration.dataanalyticsno, onSuccess: () => {
            if (mainGridRef.current) {
              applyGridSetting(mainGridRef.current, filterRef.current.control, gridSearchRef.current.control, JSON.parse(layout.layout).gridView);

              const reportName = JSON.parse(layout.layout).reportName;
              setReportName(reportName);
            }
          }
        }));
      }
    }));

    setFilterApplying(true);
  }
  // #endregion

  // #endregion



  if (dataAnalytics) {
    return (
      <>
        <Modal
          heading="Column Visibility"
          show={columnVisibilityModalState}
          close={handleColumnVisibilityModalClose}
          modalSize="sm"
          bodyStyle={{ maxHeight: "500px", overflow: "auto" }}
          footer={
            <>
              <Button variant="primary" onClick={saveColumnVisibilitySetting}>
                Save
              </Button>
            </>
          }
        >
          <>
            <div className="d-flex align-items-center">
              <FormControl id="search" type="text" placeholder="Search" onChange={(e: any) => setObjectSearch(e.target.value)} />
              <OverlayTrigger placement="top" overlay={<Tooltip>Select All</Tooltip>}>
                <Button variant="icon-light" className="ms-2" onClick={selectAll}>
                  <BiSelectMultiple size="16" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger placement="top" overlay={<Tooltip>Clear All</Tooltip>}>
                <Button variant="icon-light" className="ms-2" onClick={clearAll}>
                  <MdOutlineCancel size="16" />
                </Button>
              </OverlayTrigger>
            </div>
            <hr />
            {
              filterObjects.map((object: any) =>
                <Dropdown.ItemText key={object.columnId}>
                  <Form.Check type="checkbox" onClick={(ev: any) => { updateObject(object.columnId) }} label={object.header} checked={object.selected} readOnly />
                </Dropdown.ItemText>
              )
            }
          </>
        </Modal>
        <Modal
          modalSize='md'
          heading="Update Workspace"
          show={updateLayoutModalState}
          close={handleUpdateLayoutModalClose}
          footer={
            <Button form="updateLayout" variant="primary" type="submit">
              <MdSave className="me-2" size="16" />
              Save
            </Button>
          }
        >
          <Form id="updateLayout" validated={updateLayoutValidated} noValidate onSubmit={handleUpdateLayout}>
            <Form.Group className="mb-3" as={Row}>
              <Form.Label column sm="3">Name:</Form.Label>
              <Col sm="9" className="d-flex align-items-center">
                <Form.Control required value={layoutName} type="text" onChange={onLayoutNameChange} />
              </Col>
            </Form.Group>
            <Form.Group className="mb-3" as={Row}>
              <Form.Label column sm="3">Notes:</Form.Label>
              <Col sm="9" className="d-flex align-items-center">
                <Form.Control as="textarea" rows={5} value={layoutNotes} type="text" onChange={onLayoutNotesChange} />
              </Col>
            </Form.Group>
            <Form.Group className="mb-3" as={Row}>
              <Form.Label column sm="3">Default:</Form.Label>
              <Col sm="9" className="d-flex align-items-center">
                <Form.Check checked={layoutDefault} onChange={onLayoutDefaultChange} />
              </Col>
            </Form.Group>
            <Form.Group className="mb-3" as={Row}>
              <Form.Label column sm="3">Share To:</Form.Label>
              <Col sm="9" className="d-flex align-items-center">
                <ReactSelect className="w-100" options={usersList} isMulti value={layoutUsers} onChange={onLayoutUsersSelectionChange} />
              </Col>
            </Form.Group>

            {
              layoutSharedBy ? (
                <Form.Group className="mb-3" as={Row}>
                  <Form.Label column sm="3">Shared By:</Form.Label>
                  <Col sm="9" className="d-flex align-items-center">
                    <Form.Control disabled value={layoutSharedBy} type="text" />
                  </Col>
                </Form.Group>
              ) : null
            }
          </Form>
        </Modal>
        <Modal
          heading="Add Layout +"
          show={addLayoutModalState}
          close={handleAddLayoutModalClose}
          footer={
            <>
              <Button form="addLayout" variant="secondary" type="submit">
                <IoMdSave className="me-2" size="16" />
                Save
              </Button>
            </>
          }
        >
          <Form id="addLayout" validated={addLayoutValidated} noValidate onSubmit={handleAddLayout}>
            <Form.Group className="mb-3" as={Row}>
              <Form.Label column sm="3">Name:</Form.Label>
              <Col sm="9" className="d-flex align-items-center">
                <Form.Control required value={layoutName} type="text" onChange={onLayoutNameChange} />
              </Col>
            </Form.Group>
            <Form.Group className="mb-3" as={Row}>
              <Form.Label column sm="3">Notes:</Form.Label>
              <Col sm="9" className="d-flex align-items-center">
                <Form.Control as="textarea" rows={5} value={layoutNotes} type="text" onChange={onLayoutNotesChange} />
              </Col>
            </Form.Group>
            <Form.Group className="mb-3" as={Row}>
              <Form.Label column sm="3">Default:</Form.Label>
              <Col sm="9" className="d-flex align-items-center">
                <Form.Check checked={layoutDefault} onChange={onLayoutDefaultChange} />
              </Col>
            </Form.Group>
            <Form.Group className="mb-3" as={Row}>
              <Form.Label column sm="3">Share To:</Form.Label>
              <Col sm="9" className="d-flex align-items-center">
                <ReactSelect className="w-100" options={usersList} isMulti value={layoutUsers} onChange={onLayoutUsersSelectionChange} />
              </Col>
            </Form.Group>
          </Form>
        </Modal>
        <div className="data-analytics-detail">
          <div className="screen-action-panel">
            <Breadcrumb>
              <Breadcrumb.Item>Data Analytic</Breadcrumb.Item>
              <Breadcrumb.Item>{dataAnalytics.objectConfiguration.modulestxt}</Breadcrumb.Item>
              <Breadcrumb.Item>{dataAnalytics.objectConfiguration.applicationobjectcategorystxt}</Breadcrumb.Item>
              <Breadcrumb.Item className="active">{dataAnalytics.objectConfiguration.processcodestxt}</Breadcrumb.Item>
            </Breadcrumb>
            <Button variant="primary" className="ms-auto" onClick={loadDataAnalyticsFunc}>
              <MdDownloading className="me-2" size="16" />
              Load
            </Button>
            <div className="divider"></div>
            <OverlayTrigger placement="top" overlay={<Tooltip>Add to Favorite</Tooltip>}>
              <Button variant="secondary" onClick={() => BaseSetUserFavorite(dataAnalytics.objectConfiguration.processcode, dataAnalytics.objectConfiguration.processcodestxt, dataAnalytics.objectConfiguration.applicationobjecttype)}>
                {
                  dataAnalytics.objectConfiguration.isfavorite ? (
                    <AiFillStar size="16" />
                  ) : (
                    <AiOutlineStar size="16" />
                  )
                }
              </Button>
            </OverlayTrigger>
            <Button variant="secondary" disabled={dataAnalyticPivot ? false : true} onClick={handleAddLayoutModalOpen} className="ms-2">
              <MdSave className="me-2" size="16" />
              Store Layout
            </Button>
            <DropdownButton
              variant="secondary"
              className="lg-menu mx-2"
              as={ButtonGroup}
              disabled={dataAnalytics.layouts.length >= 1 ? false : true}
              title={
                <>
                  <MdRestore className="me-2" size="16" />
                  Restore Layout
                </>
              }>

              {
                Object.entries(dataAnalytics.layouts.groupBy("layouttype")).map(([layouttype, layouts], idx) => (
                  <div key={idx}>
                    <h6 className="m-2 ms-3 fw-bold">{layouttype}</h6>
                    {
                      layouts.map((x, i) => (
                        <Dropdown.Item key={i} className={classNames([{ active: x.layoutno == dataAnalytics.appliedLayout && x.layouttype == dataAnalytics.appliedLayoutType }])} role="button">
                          <div className="d-flex">
                            <div className="d-flex flex-column w-100" onClick={() => applyLayoutFunc(x)}>
                              <p className="fs-14 mb-0">
                                {x.layoutstxt}
                              </p>
                              <p className="fs-12 mb-0" style={{ overflow: "hidden", textOverflow: 'elipsis', whiteSpace: 'nowrap' }}>
                                {x.notes}
                              </p>
                            </div>
                            <span className="me-2" onClick={() => handleUpdateLayoutModalOpen({ ...x })}>
                              <MdEdit size="16" />
                            </span>
                            <span onClick={() => dispatch(deleteLayout({ layoutno: x.layoutno, layouttype: x.layouttype }))}>
                              <IoMdTrash size="16" />
                            </span>
                          </div>
                        </Dropdown.Item>
                      ))
                    }
                  </div>
                ))
              }

            </DropdownButton>
            <Workspace objectType="I" />
            <div className="divider"></div>
            <Button variant="danger" onClick={closeDataAnalytics}>
              <IoMdClose className="me-2" size="16" />
              Close
            </Button>
          </div>
          <Accordion controlId="lblcriteria" system={true} customManageState={true} customActive={dataAnalytics.isCriteriaOpen} toggleAccordion={(controlId, active) => toggleCriteriaFunc()}>
            <span className="criteria-fields">
              {
                dataAnalytics.criteria.map((x, i) => (
                  criteriaField(x, i)
                ))
              }
              {
                dataAnalytics.FICriteria.length ?
                  <span className="group-label">Financial Dimensions</span>
                  : null
              }
              {
                dataAnalytics.FICriteria.map((x, i) => (
                  criteriaField(x, i)
                ))
              }
            </span>
          </Accordion>


          {
            dataAnalytics.grid ?
              <div className="screen-detail">
                <Tabs defaultActiveKey={dataAnalytics.activeTab} onSelect={(k) => setActiveTabFunc((k as string))}>
                  <Tab eventKey="dataAnalytics" title="Data Analytics" className="mt-3 main_grid_tab">
                    {
                      (columns.length && columnLoaded) ?
                        <>
                          <div className="top-bar">
                            <div className="exports">
                              <b className="fs-14">Export to:</b>
                              <OverlayTrigger placement="bottom" overlay={<Tooltip>Export to Excel</Tooltip>}>
                                <Button variant="icon-light" className="ms-2" onClick={gridExportToExcel}>
                                  <SiMicrosoftexcel size="16" />
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger placement="bottom" overlay={<Tooltip>Export to Pdf</Tooltip>}>
                                <Button variant="icon-light" className="ms-2" onClick={() => gridExportToPdf(mainGridRef, false)}>
                                  <TbPdf size="16" />
                                </Button>
                              </OverlayTrigger>
                            </div>
                            <FlexGridSearch ref={gridSearchRef} placeholder="Search" />
                          </div>

                          <div className="grid-btn-group">
                            <Dropdown id="grid-menu" className="caret-none" autoClose={false}>
                              <OverlayTrigger placement="bottom" overlay={<Tooltip>Grid Menu</Tooltip>}>
                                <Dropdown.Toggle id="grid-menu-button" variant="white" className="p-0">
                                  <BsThreeDotsVertical size="16" />
                                </Dropdown.Toggle>
                              </OverlayTrigger>

                              <Dropdown.Menu>
                                <Dropdown.Item onClick={openColumnSetting}>Column Visibility</Dropdown.Item>
                                {/* <Dropdown.Item onClick={clearView}>Clear View</Dropdown.Item> */}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <Menu style={{ display: 'none' }} dropDownCssClass='ctx-menu' initialized={GroupPanelMenuInitialized} itemClicked={handleItemClicked}>
                            <MenuItem>
                              <span className='wj-glyph-down-right'></span> Expand All
                            </MenuItem>
                            <MenuItem>
                              <span className='wj-glyph-right'></span> Collapse All
                            </MenuItem>
                            <MenuSeparator />
                            <MenuItem>
                              <span className='wj-glyph-up'></span> Sort Ascending
                            </MenuItem>
                            <MenuItem>
                              <span className='wj-glyph-down'></span> Sort Descending
                            </MenuItem>
                            <MenuItem>
                              <span className='wj-glyph-circle'></span> Remove Sort
                            </MenuItem>
                            <MenuSeparator />
                            <MenuItem>
                              <span>&times;</span> Remove Group
                            </MenuItem>
                          </Menu>
                          <GroupPanel initialized={GroupPanelInitialized} className="group-panel" grid={mainGridRef.current} placeholder="Drag columns here to create groups" />
                          <Grid.FlexGrid
                            initialized={GridInitialized}
                            allowSorting={AllowSorting.SingleColumn}
                            autoGenerateColumns={false}
                            // autoRowHeights={true}
                            itemsSource={itemsSource}
                            columns={columns}
                            autoClipboard={true}
                            stickyHeaders={true}
                            keyActionTab={2}
                            autoSearch={true}
                            isReadOnly={true}
                            alternatingRowStep={0}
                            allowPdfExport={true}
                          >

                            <FlexGridFilter ref={filterRef} showSortButtons={false} />

                          </Grid.FlexGrid>
                          <div className="grid-pager">
                            <h6 className="ms-auto my-2 me-3 fs-14" dangerouslySetInnerHTML={{ __html: logText }}></h6>
                          </div>
                        </>
                        : null
                    }
                  </Tab>
                  <Tab eventKey="pivot" title="Pivot" className="pivot-tab pivot_grid_tab">
                    <div className="pivot-container row w-100 m-0">
                      <div className={classNames(["pivot-panel-container col-3 p-0", { show: showPanel }])}>
                        <Olap.PivotPanel initialized={PivotPanelInitialized} itemsSource={dataAnalyticPivot}>
                        </Olap.PivotPanel>
                        <div>
                          show Row Totals:
                          <input id="showRowTotals" type="checkbox" checked={showRowsTotal} onChange={PivotGridShowRowTotalsClick} />
                        </div>
                      </div>
                      <div className={classNames(["grid-container col-auto p-0", { "col-9": showPanel }, { "w-100": !showPanel }])}>
                        <div className="pivot-grid">
                          {
                            showPanel ? (
                              <OverlayTrigger placement="right" overlay={<Tooltip>Collapse Panel</Tooltip>}>
                                <Button className="mb-2 panel-button hide" variant="icon-light" onClick={() => setShowPanel(!showPanel)}>
                                  <BiChevronsLeft size="22" />
                                </Button>
                              </OverlayTrigger>
                            ) : (
                              <OverlayTrigger placement="right" overlay={<Tooltip>Show Panel</Tooltip>}>
                                <Button className="mb-2 panel-button show" variant="icon-light" onClick={() => setShowPanel(!showPanel)}>
                                  <BiChevronsRight size="22" />
                                </Button>
                              </OverlayTrigger>
                            )
                          }

                          <div className="top-bar">
                            <div className="exports">
                              <b className="fs-14">Export to:</b>
                              <OverlayTrigger placement="bottom" overlay={<Tooltip>Export to Excel</Tooltip>}>
                                <Button variant="icon-light" className="ms-2" onClick={pivotExportToExcel}>
                                  <SiMicrosoftexcel size="16" />
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger placement="bottom" overlay={<Tooltip>Export to Pdf</Tooltip>}>
                                <Button variant="icon-light" className="ms-2" onClick={() => gridExportToPdf(pivotGridRef, true)}>
                                  <TbPdf size="16" />
                                </Button>
                              </OverlayTrigger>
                            </div>
                          </div>
                          <div className="pivot-panel-grid">
                            <Olap.PivotGrid autoGenerateColumns={false} allowSorting={false} itemsSource={dataAnalyticPivot} initialized={PivotGridInitialized}>
                            </Olap.PivotGrid>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="chart" title="Chart" className="chart-tab">
                    <div className="d-flex flex-column-reverse">
                      <Olap.PivotChart itemsSource={dataAnalyticPivot} initialized={pivotChartInitialized} showLegend="Auto"></Olap.PivotChart>
                      <div className="top-bar">
                        <label className="fs-13">
                          Chart Type
                          <Input.ComboBox className="mx-2" textChanged={onChartTypeChanged} itemsSource={chartTypes}>
                          </Input.ComboBox>
                          <br />
                        </label>
                        <div className="exports ms-auto">
                          <b className="fs-14">Export to:</b>
                          <OverlayTrigger placement="bottom" overlay={<Tooltip>Export to PNG</Tooltip>}>
                            <Button variant="icon-light" className="ms-2" onClick={chartExportToPng}>
                              <TbPng size="16" />
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger placement="bottom" overlay={<Tooltip>Export to JPG</Tooltip>}>
                            <Button variant="icon-light" className="ms-2" onClick={chartExportToJpg}>
                              <TbJpg size="16" />
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger placement="bottom" overlay={<Tooltip>Export to SVG</Tooltip>}>
                            <Button variant="icon-light" className="ms-2" onClick={chartExportToSvg}>
                              <TbSvg size="16" />
                            </Button>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="Report" title="Report" className="report-tab">
                    <div className="d-flex">
                      <div className="sidebar">
                        <div className="sidebar-input-group flex-column align-items-start">
                          <h6>Report Name:</h6>
                          <Form.Control
                            onChange={(e: any) => { setReportName(e.target.value) }}
                            value={reportName}
                            className="mt-1 ms-auto me-4"
                          />
                        </div>
                        <div className="sidebar-input-group flex-column align-items-start">
                          <h6>Report Orientation:</h6>
                          <ReactSelect
                            className="w-100"
                            options={printOrientationOptions}
                            value={printOrientation}
                            onChange={onPrintOrientationChange} />
                        </div>
                        <div className="sidebar-input-group">
                          <h6>Required Data Analytics:</h6>
                          <Form.Check
                            onChange={(e: any) => { toggleLookupInReport(e.target.checked) }}
                            checked={showLookupInReport}
                            className="ms-auto me-4"
                            type="checkbox"
                          />
                        </div>
                        <div className="sidebar-input-group">
                          <h6>Required Pivot:</h6>
                          <Form.Check
                            onChange={(e: any) => { togglePivotInReport(e.target.checked) }}
                            checked={showPivotInReport}
                            className="ms-auto me-4"
                            type="checkbox"
                          />
                        </div>

                        <OverlayTrigger placement="bottom" overlay={<Tooltip>Generate Report</Tooltip>}>
                          <Button variant="primary" className="mt-4" onClick={generatePDFReport}>
                            Generate Report
                          </Button>
                        </OverlayTrigger>
                      </div>
                      {
                        report ? (
                          <PdfViewer src={report} fileName={dataAnalytics.objectConfiguration.processcodestxt} />
                        ) : null
                      }
                    </div>
                  </Tab>
                </Tabs>
              </div>
              : null
          }
        </div>
      </>
    );
  }

  return null;
};

export default DataAnalyticsDetail;
