import React from "react";
import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import { closeHelp, EHelpType, EHelpOpenFor, IHelpHeader } from "@/Redux/Ducks/helpSlice";
import { TableHelpUI } from "./tableHelpUI";
import { ArrayKeyValueObject } from "@/UI/Interfaces/IBaseUI";

const DimensionHelpUI = (props: any) => {

  const dispatch = useAppDispatch();

  const helpConfig = useAppSelector((x) => {
    const activeHelp = x.Help[x.EntryForm.activeObjectId]?.loadedHelp;
    if (activeHelp) {
      if (activeHelp.helpType.isEqual(EHelpType.DimensionHelp) && activeHelp.helpOpenFor.isEqual(EHelpOpenFor.Field, EHelpOpenFor.Grid) && !activeHelp.isDropDown) {
        return x.Help[x.EntryForm.activeObjectId].loadedHelp;
      }
    }
    return null;
  });

  const activeObjectId = useAppSelector((x) => x.EntryForm.activeObjectId);


  const rowSelect = (selectedRow: any) => {

    try {
      if (!!helpConfig) {
        props.rowSelect(helpConfig, helpConfig?.returnParam?.fieldId, selectedRow);
      }
    }
    catch (ex) {
      console.error(ex);
    }
  }

  if (!!helpConfig)
    return (
      <TableHelpUI
        id={activeObjectId}
        columns={helpConfig.helpConfig.Header as Array<IHelpHeader>}
        data={helpConfig.helpConfig.Detail as ArrayKeyValueObject}
        rowSelect={rowSelect}
        multiSelect={false}
        helpType={helpConfig.helpType}
      />
    );
  else
    return null;
};

export default DimensionHelpUI;
