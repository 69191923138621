import { KeyValueObject } from "@/UI/Interfaces/IBaseUI";
import { get, post } from "@/Utils/axios";

export function requestGetDocumentHelp(helpobjectno: string, helpwhere: string, processcode: string, filter: KeyValueObject): any {
  return post(`Configuration/GetDocumentHelp`, { helpobjectno, helpwhere, processcode, filter });
}

export function requestGetLoadDocumentHelpData(helpobjectno: string, helpwhere: string, processcode: string, filterPRID: number, filterObject: KeyValueObject): any {
  return post(`Configuration/GetLoadDocumentHelpData`, { helpobjectno, helpwhere, processcode, filterPRID, filter: filterObject });
}

export function LoadHelpObject(helpobjectno: string, helpwhere: string, processcode: string, helpTags: any, filterObject: KeyValueObject = {}): any {
  return post(`Configuration/LoadHelpObject`, { helpobjectno, helpwhere, processcode, helpTags, filter: filterObject });
}

export function LoadHelpObjectTags(helpobjectno: string, helpWhere: string): any {
  return get(`Configuration/LoadHelpObjectTags`, { params: { helpobjectno, helpWhere } });
}