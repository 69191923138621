const fillPalette = [
  'rgba(0, 128, 255, 0.8)',
  'rgba(0, 101, 223, 0.8)',
  'rgba(0, 76, 192, 0.8)',
  'rgba(0, 53, 162, 0.8)',
  'rgba(0, 32, 132, 0.8)'
];

const strokePalette = [
  'rgba(0, 128, 255, 0.8)',
  'rgba(0, 101, 223, 0.8)',
  'rgba(0, 76, 192, 0.8)',
  'rgba(0, 53, 162, 0.8)',
  'rgba(0, 32, 132, 0.8)'
];

const formatValue = (val: any) => {
  if (typeof val === "number") {
    const isNegative = val < 0;
    val = Math.abs(val);

    if (val >= 1000000000) {
      return (isNegative ? '-' : '') + (val / 1000000000).toFixed(2) + "B";
    } else if (val >= 1000000) {
      return (isNegative ? '-' : '') + (val / 1000000).toFixed(2) + "M";
    } else if (val >= 1000) {
      return (isNegative ? '-' : '') + (val / 1000).toFixed(2) + "K";
    } else {
      return (isNegative ? '-' : '') + val;
    }
  } else {
    return val;
  }
};

export { strokePalette, fillPalette, formatValue };