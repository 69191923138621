import classNames from "classnames";
import TimeAgo from 'react-timeago';
import { EApprovalStatusType, ENotificationType } from "@/Redux/Ducks/inboxSlice";
import { Icon as AlignIcon } from "AlignComponents";
import BaseMethods from "AlignBaseUI/BaseMethods";
import { MdMailOutline, MdRefresh, MdMarkEmailUnread, MdOutlineDelete, MdOutlineStar, MdOutlineCheckCircleOutline, MdNotifications, MdOutlineNotificationImportant, MdInfoOutline, MdSend, MdOutbox, MdSearch, MdChevronLeft } from 'react-icons/md';
import { IoMdClose } from 'react-icons/io';
import { constant } from "@/Utils/constants";
import { Badge, Button, Col, Container, Form, OverlayTrigger, Row, ToggleButton, Tooltip } from "react-bootstrap";
import Menu from "./Menu"
import TableView from "./TableView"
import { IoIosArrowBack } from "react-icons/io";
import { Icon } from "@/Assets/Icons";

export const AlignUIRender = ({ contentClickHandler, markAsImportant, markAsTrash, openTransactionDocument, inbox, messageSelectedPrid, inboxSearch, handleSearchChange, readMessage, closeMessage, activeSideMenu, selectMessage, openMessage, loadData, changeInboxMenu, setApproval, activeMessage }: any) => {
	return (
		<>
			<Container fluid className='inbox'>
				<div className='inbox-container'>
					<Menu changeInboxMenu={changeInboxMenu} />
					{/* // <TableView inbox={inbox} openMessage={openMessage} markAsImportant={markAsImportant} closeMessage={closeMessage} loadData={loadData} handleSearchChange={handleSearchChange} inboxSearch={inboxSearch} /> */}
					{/* <div className="w-100 d-flex">
						<div className="pb-0 bg-light">
						</div>
				</div> */}

					<div className="inbox-list-view">
						<div className="px-3 py-2 border-bottom">
							<Form.Control type="text" placeholder="Search" value={inboxSearch} onChange={handleSearchChange} />
						</div>
						{
							activeSideMenu.isEqual("/approval", "/approval/active") ? (
								<div className="px-3 py-2 border-bottom">
									<ToggleButton
										id="active-approval"
										type="checkbox"
										variant="outline-secondary"
										checked={activeSideMenu.isEqual("/approval/active")}
										value="1"
										onChange={(e: any) => {
											console.log(e.currentTarget.checked)
											if (e.currentTarget.checked) {
												changeInboxMenu("approval/active")
											}
											else {
												changeInboxMenu("approval")
												e.target.blur()
											}
										}}
									>
										Active
									</ToggleButton>
								</div>
							) : activeSideMenu.isEqual("/outbox", "/outbox/approval", "/outbox/notification") ? (
								<div className="px-3 py-2 border-bottom">
									<ToggleButton
										id="outbox-approval"
										type="checkbox"
										variant="outline-secondary"
										checked={activeSideMenu.isEqual("/outbox/approval")}
										value="2"
										onChange={(e: any) => {
											console.log(e.currentTarget.checked)
											if (e.currentTarget.checked) {
												changeInboxMenu("outbox/approval")
											}
											else {
												changeInboxMenu("outbox")
												e.target.blur()
											}
										}}
									>
										Approvals
									</ToggleButton>
									<ToggleButton
										id="outbox-notification"
										type="checkbox"
										variant="outline-secondary"
										className="ms-2"
										checked={activeSideMenu.isEqual("/outbox/notification")}
										value="3"
										onChange={(e: any) => {
											console.log(e.currentTarget.checked)
											if (e.currentTarget.checked) {
												changeInboxMenu("outbox/notification")
											}
											else {
												changeInboxMenu("outbox")
												e.target.blur()
											}
										}}
									>
										Notifications
									</ToggleButton>
								</div>
							) : null
						}
						<div className="inbox-list">
							{
								activeMessage ? inbox.data.map((x: any, idx: number) => (
									<div
										key={idx}
										className={classNames(["list-item", { unread: !x.viewied && !activeSideMenu.isEqual("/outbox"), active: x.prid == activeMessage.prid }])}
										onClick={(e: any) => { e.target.id != `mail-checkbox-${idx}` ? openMessage(x) : null }}>
										<img src={BaseMethods.getUserPhoto(x.userphoto)} alt="" className="rounded-circle thumb-md" />
										<div className="text-container">
											<div className="d-flex align-items-start">
												<div className="d-flex flex-column">
													<span className="user-text">
														{x.messageuserfullname}
														{x.isimportant ? <MdInfoOutline size={18} className="ms-2" /> : null}
													</span>
													<span className="time-ago">
														<TimeAgo date={BaseMethods.convertUTCDateToLocal(x.credate).toString()} />
													</span>
												</div>
												{
													x.notificationtype == ENotificationType.Approval && x.actionable ?
														<Badge className="ms-auto" bg="success">Approval</Badge>
														:
														x.notificationtype == ENotificationType.Information ?
															<Badge className="ms-auto" bg="secondary">Information</Badge>
															:
															x.notificationtype == ENotificationType.Notification ?
																<Badge className="ms-auto" bg="warning">Notification</Badge>
																:
																null
												}
											</div>
											<div className="d-flex gap-2">
												{
													x.processstxt ? (
														<span className="badge badge-light fs-8">{x.processstxt}</span>
													) : null
												}
												{
													x.parameters ? (
														<span className="badge badge-light fs-8">{x.parameters.transactionno}</span>
													) : null
												}
											</div>
											<p className="subject">{x.subject}</p>
										</div>
									</div>
								)) : null
							}
						</div>
					</div>

					{
						activeMessage ? (
							<div className="active-message">
								<div className="top-bar">
									<span className="subject">
										{activeMessage.subject}
									</span>
									<br />
									<span className="detail">
										{activeMessage.messageuserfullname}
										&nbsp; - &nbsp;
										<TimeAgo date={BaseMethods.convertUTCDateToLocal(activeMessage.credate).toString()} />
									</span>
								</div>
								<div className="button-bar">
									{
										activeMessage.tousername == inbox.currentUserId &&
											activeMessage.notificationtype == ENotificationType.Approval &&
											activeMessage.actionable ?
											<>
												<Button variant="primary" onClick={() => setApproval(EApprovalStatusType.Approve, activeMessage)}>Approve</Button>
												<Button variant="danger" className="ms-2" onClick={() => setApproval(EApprovalStatusType.Reject, activeMessage)}>Reject</Button>
												<Button variant="secondary" className="ms-2" onClick={() => setApproval(EApprovalStatusType.Hold, activeMessage)}>Hold</Button>
											</>
											:
											null
									}

									<OverlayTrigger placement="top" overlay={<Tooltip>Important</Tooltip>}>
										<Button variant="secondary" className="ms-auto" onClick={markAsImportant} dangerouslySetInnerHTML={{ __html: Icon.Important }} />
									</OverlayTrigger>
									<OverlayTrigger placement="top" overlay={<Tooltip>Trash</Tooltip>}>
										<Button variant="secondary" className="ms-2" onClick={markAsTrash} dangerouslySetInnerHTML={{ __html: Icon.Trash }} />
									</OverlayTrigger>
									{
										activeMessage.parameters ?
											<span className="ms-3 hyperlink" role="button" onClick={() => openTransactionDocument(activeMessage.parameters.processcode, activeMessage.parameters.transactionno)}>
												Review {activeMessage.parameters.transactionno}
												<AlignIcon icon="ExternalLink" size="16" className="ms-2" />
											</span>
											: null
									}
								</div>
								<div style={{
									height: `calc(100% - ${activeMessage.tousername == inbox.currentUserId &&
										activeMessage.notificationtype == ENotificationType.Approval &&
										activeMessage.actionable ? '140px' : '75px'})`, overflowY: 'auto'
								}}>
									<div className="mailEl" onClick={contentClickHandler} dangerouslySetInnerHTML={{ __html: activeMessage.message }}></div>
								</div>
							</div>
						) : null
					}
				</div>

				{/* <div className="w-100 border-start bg-white">
							<div className="pb-3 py-2 bg-white" style={{ flex: 1 }}>
								<div className="d-flex align-items-center mx-3">
									<div className="d-flex flex-grow-1">
								</div>
								<hr className="mt-2" />
							
							</div>
						</div> */}

			</Container>
		</>
	)
}