import React from 'react'
import classNames from "classnames";
import { useAppSelector } from '@/Hook/hooks';

const ScreenDetail = (props: any) => {

  const screenOptions = useAppSelector(x => x.EntryForm.objects[x.EntryForm.activeObjectId].screenOptions);

  return (
    <div className={classNames(["screen-detail", { "d-none": !screenOptions.isEntered }])}>
      {props.children}
    </div>
  )
}

export default ScreenDetail
