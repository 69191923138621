import { IUserWorkspaceObject } from '@/Redux/Ducks/workspaceSlice';
import { useCallback } from 'react';
import update from 'immutability-helper';
import { Col } from 'react-bootstrap';
import { MenuHeader } from './MenuHeader';
import { MdChevronRight } from 'react-icons/md';

export interface IWorkspaceWidget extends IUserWorkspaceObject {
	index: number
}
interface IProps {
	menus: Array<IWorkspaceWidget>,
	menuGroups: Array<{ groupName: string, groupIndex: number }>,
	updateIndex: (objects: any, widgetType: string) => void,
	updateGroupIndex: (objects: any) => void,
	columnlayout: boolean,
	routeToScreen: (screenCode: string, gridView: string, objectType: string) => void,
	openUpdateWorkspace: (props: {
		widgetNo: string;
		widgetName: string;
		widgetType: string;
		icon?: string | undefined;
		widgetGroup?: string | undefined;
	}) => void,
	deleteWorkspace: (widgetNo: string) => void,
	openHyperlink: (screenCode: string, transactionNo: string) => void,
	toggleSubMenu: (e: any) => void,
	updateGroup: (dragCard: IUserWorkspaceObject, hoverCard: IUserWorkspaceObject) => void
}
export const Menus = (props: IProps) => {
	const moveMenuHeader = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			const dragCard = props.menuGroups[dragIndex];

			props.updateGroupIndex(
				update(props.menuGroups, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dragCard],
					],
				}));

		},
		[props.menuGroups]);

	return (
		<Col md={!props.columnlayout ? 12 : 3} className="mt-3">
			{
				props.menuGroups.length >= 1 ? (
					<>
						<h6 className="fw-bold">Menu</h6>
						<ul id="workspace-links" className="list-unstyled">
							{
								props.menuGroups.map((menuGroup, idx: number) => (
									<MenuHeader menus={props.menus} group={menuGroup} index={idx} moveMenuHeader={moveMenuHeader} toggleSubMenu={props.toggleSubMenu} deleteWorkspace={props.deleteWorkspace} openUpdateWorkspace={props.openUpdateWorkspace} routeToScreen={props.routeToScreen} updateIndex={props.updateIndex} updateGroup={props.updateGroup} />
								))
							}
						</ul>
					</>
				) : null
			}
		</Col>
	)
}
