import { Card, Table as BsTable, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import { BiLinkExternal } from "react-icons/bi";
import { GiMove } from "react-icons/gi";
import Marker from './Marker'
import GoogleMapReact from 'google-map-react';
import classNames from "classnames";
// @ts-ignore
import Fade from 'react-reveal/Fade';
interface IProps {
	widgetlabel: string;
	detail: any;
	widgetprocesscode: string;
	routeToScreen: any;
	widget: any;
}

const defaultProps = {
	center: {
		lat: 59.955413,
		lng: 30.337844
	},
	zoom: 11
};

const Map = (props: IProps) => {
	const { maps } = (window as any).google;

	const bounds = new maps.LatLngBounds();

	const tooltip = props.widget.columns.find((x: any) => x.widgetobjecttypeno == 'TIP')?.objectno;
	const markerText = props.widget.columns.find((x: any) => x.widgetobjecttypeno == 'MRK')?.objectno;
	const lat = props.widget.columns.find((x: any) => x.widgetobjecttypeno == 'LAT')?.objectno;
	const lng = props.widget.columns.find((x: any) => x.widgetobjecttypeno == 'LNG')?.objectno;

	return (
		<div className="chart-card">
			<Fade>
				<Card>
					<Card.Header>
						{props.widgetlabel}

						{
							props.widgetprocesscode ? (
								<OverlayTrigger placement="bottom" overlay={<Tooltip>Hyperlink</Tooltip>}>
									<div className="hyperlink" onClick={() => { props.routeToScreen(props.widgetprocesscode) }}>
										<BiLinkExternal size={16} />
									</div>
								</OverlayTrigger>
							) : null
						}
						<OverlayTrigger placement="bottom" overlay={<Tooltip>Move</Tooltip>}>
							<div className="drag-handle">
								<GiMove size={16} />
							</div>
						</OverlayTrigger>

					</Card.Header>
					<Card.Body>
						<GoogleMapReact
							key="AIzaSyBC7Zgw_CfKS2pEOJyjUUJzeb4X6L1k_ZM"
							bootstrapURLKeys={{ key: "AIzaSyBC7Zgw_CfKS2pEOJyjUUJzeb4X6L1k_ZM", language: "en", region: "PK" }}
							defaultCenter={defaultProps.center}
							defaultZoom={defaultProps.zoom}
							yesIWantToUseGoogleMapApiInternals
							onGoogleApiLoaded={({ map }) => map.fitBounds(bounds)}
						>
							{
								props.detail.map((line: any, i: any) => {
									const location = new maps.LatLng(
										line[lat],
										line[lng]
									);
									bounds.extend(location);

									return (
										<Marker key={i} lat={line[lat]} lng={line[lng]} text={line[markerText]} tooltip={line[tooltip]} />
									)
								})
							}
						</GoogleMapReact>
					</Card.Body>
				</Card>
			</Fade>
		</div>
	)
}

export default Map;