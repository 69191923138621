import { IUserWorkspaceObject } from "@/Redux/Ducks/workspaceSlice";
import { get, put } from "@/Utils/axios";

export function requestGetUserWorkspace(): any {
  return get("Workspace/GetUserWorkspace");
}
export function requestAddWorkspace(workspaceName: string): any {
  return get(`Workspace/AddWorkspace`, { params: { workspaceName } });
}
export function requestUpdateWorkspace(workspaceNo: number, workspaceName: string): any {
  return get(`Workspace/UpdateWorkspace`, { params: { workspaceNo, workspaceName } });
}
export function requestDeleteWorkspace(workspaceNo: number) {
  return get(`Workspace/DeleteWorkspace`, { params: { workspaceNo } });
}
export function requestGetUserWorkspaceObject(workspaceNo: number): any {
  return get(`Workspace/GetUserWorkspaceObject`, { params: { workspaceNo } });
}
export function requestAddTileToWorkspace(workspaceNo: number, widgetName: string, gridView: string, processCode: string, helpObject: string, showCount: boolean, icon: string, objectType: string): any {
  return get(`Workspace/AddTileToWorkspace`, { params: { workspaceNo, widgetName, gridView, processCode, helpObject, showCount, icon, objectType } });
}
export function requestAddMenuToWorkspace(workspaceNo: number, widgetName: string, gridView: string, processCode: string, helpObject: string, widgetGroup: string, objectType: string): any {
  return get(`Workspace/AddMenuToWorkspace`, { params: { workspaceNo, widgetName, gridView, processCode, helpObject, widgetGroup, objectType } });
}
export function requestAddListToWorkspace(workspaceNo: number, widgetName: string, gridView: string, processCode: string, helpObject: string, columns: string, objectType: string): any {
  return get(`Workspace/AddListToWorkspace`, { params: { workspaceNo, widgetName, gridView, processCode, helpObject, listColumns: columns, objectType } });
}
export function requestUpdateWorkspaceObject(widgetNo: string, widgetName: string, icon: string, widgetGroup: string) {
  return get(`Workspace/UpdateWorkspaceObject`, { params: { widgetNo, widgetName, icon, widgetGroup } });
}
export function requestDeleteWorkspaceObject(widgetNo: string) {
  return get(`Workspace/DeleteWorkspaceObject`, { params: { widgetNo } });
}
export function requestSetColumnLayout(workspaceNo: number, columnlayout: boolean) {
  return get(`Workspace/SetColumnLayout`, { params: { workspaceNo, columnlayout } });
}
export function requestReorderTiles(tiles: Array<string>) {
  return get(`Workspace/ReorderTiles`, { params: { tiles: tiles.join(',') } })
}

export function requestReorderLists(lists: Array<string>) {
  return get(`Workspace/ReorderLists`, { params: { lists: lists.join(',') } })
}

export function requestReorderMenus(menus: Array<string>) {
  return get(`Workspace/ReorderMenus`, { params: { menus: menus.join(',') } })
}

export function requestUpdateMenuGroup(widgetNo: string, widgetGroup: string) {
  return get(`Workspace/UpdateMenuGroup`, { params: { widgetNo, widgetGroup } })
}