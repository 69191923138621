import { ArrayKeyValueObject } from "@/UI/Interfaces/IBaseUI";
import { Card, Table, Row, Col } from "react-bootstrap";
import { ControlTranslation, Modal } from "AlignComponents"
import { Icon } from "@/Assets/Icons";

interface IProps {
  userShortWidget: any,
  history: any,
  handleTaskModalClose: () => void,
  handleTaskModalOpen: () => void,
  taskModalOpen: boolean,
  userFrequentlyUsed: any,
  routeToScreen: (screenCode: string, transactionNo?: string) => void
}

export const AlignUIRender = ({ history, userShortWidget, handleTaskModalClose, handleTaskModalOpen, taskModalOpen, userFrequentlyUsed }: IProps) => (
  <>
    <Modal
      heading={<ControlTranslation system={true} controlId="lbltop10frequentlyused" />}
      show={taskModalOpen}
      close={handleTaskModalClose}
      modalSize="md"
      bodyStyle={{ padding: 0 }}
    >
      <Table hover>
        <thead>
          <tr>
            <th><ControlTranslation system={true} controlId="lblmodule" /></th>
            <th><ControlTranslation system={true} controlId="lblprocesscode" /></th>
            <th><ControlTranslation system={true} controlId="lblprocessname" /></th>
          </tr>
        </thead>
        <tbody>
          {
            userFrequentlyUsed.map((x: any, idx: number) => (
              <tr key={idx}>
                <td>{x.modulestxt}</td>
                <td>{x.objectcontrollerno}</td>
                <td>{x.objectcontrollerstxt}</td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </Modal>
    <Col md="2">
      <Card className="user-action" role="button" onClick={() => history.push("approval/active")}>
        <Card.Body>
          <div className="user-action-content">
            <div dangerouslySetInnerHTML={{ __html: Icon.RequestsForApproval }} />
            <span className="content-text">
              <ControlTranslation system={true} controlId="lblrequestsforapproval" />
            </span>
            <span className="content-number">
              {userShortWidget.requestToApproveCount}
            </span>
          </div>
        </Card.Body>
      </Card>
    </Col>
    <Col md="2">
      <Card className="user-action" role="button" onClick={() => history.push("outbox")}>
        <Card.Body>
          <div className="user-action-content">
            <div dangerouslySetInnerHTML={{ __html: Icon.SentForApproval }} />
            <span className="content-text">
              <ControlTranslation system={true} controlId="lblsentforapproval" />
            </span>
            <span className="content-number">
              {userShortWidget.requestSentForApproveCount}
            </span>
          </div>
        </Card.Body>
      </Card>
    </Col>
    <Col md="2">
      <Card className="user-action" role="button">
        <Card.Body>
          <div className="user-action-content">
            <div dangerouslySetInnerHTML={{ __html: Icon.Tasks }} />
            <span className="content-text">
              <ControlTranslation system={true} controlId="lbltasks" />
            </span>
            <span className="content-number">
              {userShortWidget.taskCount}
            </span>
          </div>
        </Card.Body>
      </Card>
    </Col>
    <Col md="2">
      <Card className="user-action" role="button" onClick={handleTaskModalOpen}>
        <Card.Body>
          <div className="user-action-content">
            <div dangerouslySetInnerHTML={{ __html: Icon.FrequentlyUsed }} />
            <span className="content-text">
              <ControlTranslation system={true} controlId="lbltop10frequentlyused" />
            </span>
          </div>
        </Card.Body>
      </Card>
    </Col>
    <Col md="2">
      <Card className="user-action" role="button" onClick={() => history.push("notification")}>
        <Card.Body>
          <div className="user-action-content">
            <div dangerouslySetInnerHTML={{ __html: Icon.Notifications }} />
            <span className="content-text">
              <ControlTranslation system={true} controlId="lblnotifications" />
            </span>
            <span className="content-number">
              {userShortWidget.notificationCount}
            </span>
          </div>
        </Card.Body>
      </Card>
    </Col>
    <Col md="2">
      <Card className="user-action" role="button">
        <Card.Body>
          <div className="user-action-content">
            <div dangerouslySetInnerHTML={{ __html: Icon.Messages }} />
            <span className="content-text">
              <ControlTranslation system={true} controlId="lblmessages" />
            </span>
            <span className="content-number">
              0
            </span>
          </div>
        </Card.Body>
      </Card>
    </Col>
  </>
);
