
import { changeActivityToCreate, changeObjectActivity, changeScreenOptions, changeScreenTabVisibility, deleteDocument, fieldHelpValidate, forceDisableActionButton, fillDimensionFromDimensionSet, getScreenConfig, gridHelpValidate, loadDocumentHelp, openSubGrid, reverseDocument, saveDocument, setCustomState, setDocumentModelValue, setFieldEditable, setFieldError, setFieldHelpObject, setFieldMandatory, setFieldModelValue, setFieldVisibility, setGridColumnEditable, setGridColumnHelpObject, setGridColumnHyperLinkJump, setGridColumnLabel, setGridColumnMandatory, setGridColumnVisibility, setGridRowError, setGroupHeadingLabelVisibility, setToolbarSetting, toggleReverseModal, toggleScreenAccordionByControlIds, toggleSummary, upsertDimension } from "@/Redux/Ducks/entryFormSlice";
import { IReturnValues } from "@/Redux/Ducks/helpSlice";
import { toggleLoader } from "@/Redux/Ducks/loaderSlice";
import { setTabMessage } from "@/Redux/Ducks/messageSlice";
import { closeTab } from "@/Redux/Ducks/tabSlice";

import { IUserObject } from "@/Redux/Ducks/userSlice";
import { IGridObject } from "@/UI/Interfaces/IGrid";
import { IFieldConfig, IFieldObject } from "./IFieldConfig";
export interface IBaseUI<TModel> {
  Config: ScreenConfig;
  // State: Object,
  readonly Model: TModel;

  // AlignRender(Config: ScreenConfig): JSX.Element
  
  //#region Grid State Manage
  OnFieldBeforeHelpReturn(fieldid: string, helpobject: string, helpReturnValues: IReturnValues[]): void;
  //#endregion

  //#region Grid State Manage
  OnGridAfterHelpSelected(gridno: string, columnId: string, selectedRowIndex: number, helpObject: string, helpSelectedRows: ArrayKeyValueObject): void;
  OnGridBeforeCellChange(gridno: string, columnId: string, rowIndex: number, value: any, gridRow: KeyValueObject, ev: KeyValueObject): void;
  OnGridCellChange(gridno: string, columnId: string, rowIndex: number, oldValue: any, newValue: any, gridRow: KeyValueObject, helpSelected: boolean): Promise<Boolean | void> | void;
  OnGridCellBeginningEdit(gridno: string, columnId: string, rowIndex: number, value: any, gridRow: KeyValueObject, event: any): void;
  OnSaveSubGridForm(subGridno: string, parentGridno: string, parentGridColumnId: string, parentRowIndex: number, subGridRow: ArrayKeyValueObject): void;
  OnGridRowSelectionChanged(gridno: string, rowIndex: number, gridRow: KeyValueObject): void;
  OnGridBeforeDeleteRow(gridno: string, rowIndex: number, gridRow: KeyValueObject, evt: KeyValueObject): void;
  OnGridHyperLinkClick(gridno: string, columnId: string, row: number, gridRow: KeyValueObject): void;
  OnAfterGridInitialized(gridno: string, GridControl: any): void;

  //#endregion

  //#region Other Method
  OnToolbarClicked(toolbarKey: string, params?: IToolbarParam): void;
  OnActionClicked(actionno: string): void;
  RenderOnActionPanel(): any;

  //#endregion

  //#region Regular Methods

  AfterDisplay(): void;
  AfterEdit(): void;
  BeforeSave(options: ISaveParams): void;
  AfterSave(): void;
  BeforeCreate(): void;
  AfterCreate(): void;
  CalculateSummary(): void;

  //#endregion
}

export interface IToolbarParam {
  gridno: string,
  rowIndex: number
}

export interface ISaveParams {
  documentReleased?: boolean;
  requestForApproval?: boolean;
  toolbarno?: string;
}

export interface ObjectConfiguration {
  prid: number;
  moduleno: string;
  modulestxt: string;
  applicationobjecttype: string;
  applicationobjectcategory: string;
  applicationobjectcategorystxt: string;
  savemode: string;
  iscreate: boolean;
  isedit: boolean;
  isview: boolean;
  isdelete: boolean;
  isreverse: boolean;
  isfavorite: boolean;
  releasedrequired: boolean;
  processcode: string;
  legalentityrequired: boolean;
  translationrequired: boolean;
  formdimension: boolean;
  processcodestxt: string;
  reportobjectno: string;
  dataanalyticsno: string;
  headercollapseoncreate: boolean;
}

interface IDocumentationUrls {
  documentstxt: string;
  documenturl: string;
}

export interface ScreenObjectConfiguration extends ObjectConfiguration {
  numberrangeisapplied: boolean;
  hasCreateApproval: boolean;
  hasChangeApproval: boolean;
  hasDeleteApproval: boolean;
  hasReverseApproval: boolean;
  requiredsendercomments: boolean;
  requiredcommentsonapproved: boolean;
  requiredcommentsonhold: boolean;
  requiredcommentsonreject: boolean;
  // documentationurl: string;
  DocumentationUrls : Array<IDocumentationUrls>;
  videourl: string;
  attachmentrequired: boolean;
  totalrequired: boolean;
  clonerequired: boolean;
  dockey: string;
  selectedDocumentHelpFilteredPrid: number;
  allowimport: boolean;
  importlabelstxt: string;

}

export interface ScreenConfig {
  TcodeConfiguration: ScreenObjectConfiguration;
  Fields: IFieldObject;
  Grids: IGridObject;
  ControlTranslation: Array<IControlTranslation>;
  CompanyDimensionFields: Array<IFieldConfig>;
  Toolbar: Array<IToolbar>;
  ActionProcess: Array<IActionProcess>;
  GridUserConfig: Array<IGridUserConfig>;
  UserDefaultParameter: ArrayKeyValueObject;
  ProcessReports: {
    reportno: string;
    requiredonletterhead: boolean;
    reportstxt: string;
    isdefault: boolean;
  }[];
  ProcessCodeApplicationDataFormat: IUserObject["CompanyDataFormat"];
}

export interface IActionProcess {
  objectno: string;
  objectlabel: string;
  objectindex: number;
}

export interface IControlTranslation {
  controlId: string;
  translation: string;
}

export interface IToolbar {
  objectno: string;
  parentindex: number;
  toolbarindex: string;
  toolbarsubindex: string;
  objectlabel: string;
  icon: string;
}

export interface IGridUserConfig {
  processcode: string;
  username: number;
  gridno: string;
  gridconfig: string;
}

export interface ISubGrid {
  gridno: string;
  parentGridno: string,
  parentColumnId: string,
  parentRowIndex: number
  modelData: ArrayKeyValueObject;
  parentRowData: KeyValueObject;
  readOnly: boolean;
  allowAddNewRow: boolean;
  allowDeleteRow: boolean;
  allowCopyRow: boolean;
  // dimension: Array<{
  //   key: string;
  //   dimensionValues: KeyValueObject<string>;
  // }>
}

// New

export interface IEntryFormReduxState<TModel = KeyValueObject> {
  objects: {
    [key: string]: Omit<IEntryFromObject<TModel>, "CompanyLedgerInfo" | "selectedLegalEntity">
  },
  activeObjectId: string,
  isConfigLoaded: boolean
}

export interface IComment {
  commentstxt: string;
  userphoto: string;
  createdatetime: string;
  isread: boolean;
  createuser: string;
}

export interface IActivity {
  userphoto: string;
  activitydatetime: string;
  message: string;
}

export type IOtherState = {

  isCommentsModalOpen: boolean;
  isActivityModalOpen: boolean;

  userDocumentHelpFilter: {
    prid: number,
    isdefault: boolean,
    filterstxt: string,
    filterapproval: boolean,
    filtersetting: string
  }[];
  userApprovalOption: {
    isApprover: boolean;
    approverPRID: number;
    status: string;
  };

  activeScreenTab: { controlId: Array<string> };
  screenTab: Array<{ controlId: string; visible: boolean }>;
  toolbarSetting: Array<{ toolbarKey: string, gridno: string, visible: boolean, enable: boolean }>;
  groupLabelHide: Array<string>;

  isSummaryPopupOpen: boolean;
  isReversePopupOpen: boolean;

  customState: KeyValueObject;
  comments: Array<IComment>;

  forceDisabledButton: {
    edit: boolean;
    delete: boolean;
    reverse: boolean;
  };
  requestInQueue: {
    [key: string]: any;
  }
  screenAccordion: {
    [key: string]: boolean;
  },
  accountDimensionSetting?: TAccountDimensionSetting
}

export type TDimensionSetting = {
}

export type TAccountDimensionSetting = {
  isOpen: boolean;
  position: { x: number, y: number };
  accountFieldId: string;
  dimensionValues: KeyValueObject;
  accountno: string;
  dimensionSetFieldid: string;
  isSubGrid: boolean;

  gridno?: string;
  rowIndex?: number;
  accountDimensions: Array<{
    accountno: string;
    fieldConfig: Array<IFieldConfig>
  }>
} & TDimensionSetting

export interface IScreenOptions {
  ScreenInitialized: boolean;
  isEntered: boolean;
}

export interface IEntryFromObject<TModel> {

  //#region not changeable or not update data
  readonly objectId: string;
  readonly ScreenConfig: ScreenConfig;
  readonly selectedLegalEntity: string;

  // ! Temporary not conform to use in this Component
  readonly userInfo?: IUserObject["user"];
  readonly CompanyLedgerInfo: IUserObject["CompanyLedgerInfo"];
  //#endregion

  readonly activity: EActivity;
  readonly model: TModel;
  readonly fieldOptions: IFieldOptions<TModel>;
  readonly gridOptions: IGridOptions<TModel>;
  readonly subGrid?: ISubGrid[];
  readonly screenOptions: IScreenOptions;
  readonly Attachment: {
    uploadingAttachments: (IAttachment & {
      uploadingProgress: number;
      isUploadingError: boolean;
      uploadingErrorMessage: string;
    })[];
    attachments: IAttachment[];
    isSavingModalOpen: boolean;
    isModalOpen: boolean;
    openModalGridno: string;
    openModalGridLineId: number;
    removeAttachmentIds: string[];
  };
  readonly dimension: IDimension[];

  readonly otherState: IOtherState;
}

export type IDimension = {
  dimensionSet: string,
  dimensionRelations: Array<string>, // To identify how many (lines / header) have this combinations if no relation found then delete this dataset object from array
  dimensionValues: KeyValueObject<string>,
  isDraftDimension: boolean
}

export interface IAttachment {
  prid: string;
  filename: string;
  gridno: string;
  lineid: number;
  fileextension: string;
  filesize: string;
  createdatetime: string;
  createuser: string;
  isNew: boolean;
  fileObject?: any;
  linestxt: string;
}

export type IFieldOptions<TModel> = {
  [k in keyof TModel]: ITModelFieldProperties<TModel>;
};

export type IGridOptions<TGridModel> = {
  [k: string]: {
    [j: string]: ITModelGridProperties<KeyValueObject>;
  };
};

export interface IEntryFormState<TModel> extends Omit<IEntryFromObject<TModel>, "objectId" | "otherState" | "Attachment"> {
  currentSubGridObject?: ISubGrid;
  customState: IEntryFromObject<TModel>["otherState"]["customState"];
  requestInQueue: IEntryFromObject<TModel>["otherState"]["requestInQueue"];
}

// export type asd<TModel> = Array<ITModel<TModel>> & {
//   [k in keyof TModel]: Omit<ITModelProperties<TModel>, 'value' | 'oldvalue'>
// }

// export type ITModel<TModel> = {
//   [Key in keyof TModel]: TModel[Key] extends Array<infer T> ? Array<IArrayTModel<T>> : TModel[Key] extends String | Number | Date ? TModel[Key]: ITModel<TModel[Key]>
// }

// export type IArrayTModel<TModel> = {
//   [Key in keyof TModel]: TModel[Key] extends Array<infer T> ? Array<IArrayTModel<T>> : TModel[Key]
// }

export interface ITModelFieldProperties<TModel> extends ITModelBasicProperties<TModel> {
  errorType: EFieldErrorType;
  validatingHelp: boolean;
}

export interface ITModelGridProperties<TModel> extends ITModelBasicProperties<TModel> {
  columnLabel: string;
  validatingHelpRows: Array<{
    rowIndex: number;
  }>;
  errorTypeInRows: Array<{
    rowIndex: number;
    columnId: string;
    errorType: EFieldErrorType;
  }>;
}

export interface ITModelBasicProperties<TModel> {
  fieldId: keyof TModel;
  visible: boolean;
  enable: boolean;
  // ! Dimension Required is not working for grid
  dimensionrequired: boolean;
  helpObject: string;
  helpWhere: string;
  hyperLinkJump: string;
  validateMandatory: boolean;
}

// export interface ISetModelPayload<TModel> extends ITModelProperties<TModel> {
//   objectId: string
// }

export type TFieldValue = {
  fieldId: string;
  objectId: string;
  value: any;
};

export declare type BaseEntryFormProps<TModel> = IEntryFormState<TModel> &
  DispatchFromProps &
  OwnProps;

export interface OwnProps {
  objectId: string;
}

export interface DispatchFromProps {
  fieldHelpValidate: typeof fieldHelpValidate;
  setFieldModelValue: typeof setFieldModelValue;
  setFieldVisibility: typeof setFieldVisibility;
  setFieldEditable: typeof setFieldEditable;
  setFieldHelpObject: typeof setFieldHelpObject;
  setFieldMandatory: typeof setFieldMandatory;
  setFieldError: typeof setFieldError;


  setGridColumnHelpObject: typeof setGridColumnHelpObject;
  setGridColumnHyperLinkJump: typeof setGridColumnHyperLinkJump;
  setGridColumnMandatory: typeof setGridColumnMandatory;
  setGridColumnVisibility: typeof setGridColumnVisibility;
  setGridColumnEditable: typeof setGridColumnEditable;
  gridHelpValidate: typeof gridHelpValidate;
  setGridRowError: typeof setGridRowError;
  setGridColumnLabel: typeof setGridColumnLabel;
  // gridHelpValidate: typeof gridHelpValidate;

  openSubGrid: typeof openSubGrid;
  loadDocumentHelp: typeof loadDocumentHelp;
  getScreenConfig: typeof getScreenConfig;

  saveDocument: typeof saveDocument;
  reverseDocument: typeof reverseDocument;
  deleteDocument: typeof deleteDocument;
  closeTab: typeof closeTab;
  changeObjectActivity: typeof changeObjectActivity;
  setDocumentModelValue: typeof setDocumentModelValue;
  changeScreenOptions: typeof changeScreenOptions;
  changeActivityToCreate: typeof changeActivityToCreate;
  toggleLoader: typeof toggleLoader;
  setTabMessage: typeof setTabMessage;

  toggleSummary: typeof toggleSummary;
  toggleReverseModal: typeof toggleReverseModal;
  setCustomState: typeof setCustomState;
  forceDisableActionButton: typeof forceDisableActionButton;
  changeScreenTabVisibility: typeof changeScreenTabVisibility;
  toggleScreenAccordionByControlIds: typeof toggleScreenAccordionByControlIds;
  setGroupHeadingLabelVisibility: typeof setGroupHeadingLabelVisibility;
  setToolbarSetting: typeof setToolbarSetting;

  upsertDimension: typeof upsertDimension;
  fillDimensionSet: typeof fillDimensionFromDimensionSet;
}

export interface BaseEntryFormState {
  toolbar: any;
}

export enum EActivity {
  Create = "C",
  Edit = "E",
  View = "V",
  Delete = "D",
  Reverse = "R",
  None = ""
}

export enum EFieldErrorType {
  None = 0,
  EmptyFieldError = 1,
  HelpDataNotFound = 2,
}

export enum EFieldTriggerEvents {
  OnEdit = "OnEdit",
  OnNew = "OnNew",
  OnValueChange = "OnValueChange",
}

export interface IGridIndex { row: number; col: number }


export type KeyValueObject<type = any> = {
  [key: string]: type
}

export type ArrayKeyValueObject = Array<{
  [key: string]: any
}>

// export interface StoreState {
//   enthusiasmLevel: number;
// }

// // merged type
// export declare type ComponentProps<T> = StateFromProps & OwnProps<T> & DispatchFromProps;

// // the type we want to make variable
// export interface OwnProps<T> {
//   name: T;
// }

// export interface DispatchFromProps {
//   onIncrement: () => void;
// }

// export interface IEntryForm<TModel> {
//   model: TModel;
//   OnChange(fieldid: string, oldvalue: any, newvalue: any): Boolean | void
//   fieldConfig: IField;

// }
