import { Redirect, Route } from "react-router-dom";

import AuthorizeLayout from "@/UI/Layout/Authorize";
import { getTokens } from "@/Utils/auth";

const RouteAuthorize = ({ component: Component, children, allowLayout, ...rest }: any) => {
  const currentUser = getTokens().AccessToken;
  const path = rest.location.pathname + rest.location.search;

  if (!currentUser) return <Redirect to={`/login${path != "/" ? `?redirecturl=${encodeURIComponent(path)}` : ""}`} />;

  return (
    <Route {...rest} render={(props) => {
      return allowLayout != false ?

        <AuthorizeLayout {...props}>
          <Component {...props} />
        </AuthorizeLayout>
        :
        <Component {...props} />
        ;

    }} />
  )
}


export default RouteAuthorize;
