import { call, put, select } from "redux-saga/effects";
import { setGlobalMessage } from "@/Redux/Ducks/messageSlice";
import { toggleLoader } from "@/Redux/Ducks/loaderSlice";
import { requestGetUserInsight, requestSaveUserLayout } from "../Requests/dashboard";
import { setUserInsightsDashboard } from "@/Redux/Ducks/dashboardSlice";
import { setUserInsightsHome } from "@/Redux/Ducks/homeSlice";
import { PayloadAction } from "@reduxjs/toolkit";

export function* handleGetUserInsights(): any {

  try {
    yield put(toggleLoader(true));

    var response = yield call(requestGetUserInsight);

    response.widgetList = response.widgetList.groupBy("widgetgroupno");

    yield put(setUserInsightsDashboard(response));
    yield put(setUserInsightsHome(response));

  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }

}

export function* handleSaveUserDashboardLayout({ payload }: PayloadAction<{ widgetgroupno: string, layout: JSON, insightarea: string }>): any {
  try {
    yield call(requestSaveUserLayout, payload.widgetgroupno, payload.layout, payload.insightarea);
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
}