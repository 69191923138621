import { Nav } from "react-bootstrap";
import { BsGlobe } from "react-icons/bs";

export const AlignUIRender = (props: any) => (
  <div id="unAuthContent">
    {/* <div className="website-logo">
        <a role="button">
          <div className="logo">
            <img className="logo-size" src={require("@/Assets/Images/logo-light.png").default} alt="" />
          </div>
        </a>
      </div> */}
    <div className="info-container">
      <div>
        <h3>Simplify Your Business Process</h3>
        <p>Industry Specific ERP, Built To Meet Your Needs.</p>
      </div>
      <img src={require("@/Assets/Images/login.png").default} alt="" />
    </div>

    <div className="form-container">
      <div className="form-box">
        <img src={require("@/Assets/Images/logo-dark.png").default} alt="" />
        {props.children}
      </div>
    </div>
  </div>
);
