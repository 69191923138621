import { useEffect, useRef, useState } from "react";
import { AlignUIRender } from "./indexUI";
import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import { getScreenConfig } from "@/Redux/Ducks/entryFormSlice";
import { requestGetUserSearchObjects } from "@/Redux/Sagas/Requests/others";
import { ArrayKeyValueObject } from "@/UI/Interfaces/IBaseUI";
import { getDataAnalyticsConfig } from "@/Redux/Ducks/dataAnalyticsSlice";
import { getReportConfig } from "@/Redux/Ducks/reportExplorerSlice";
import { EMenu } from "@/Redux/Ducks/tabSlice";

const Search = ({ closeMenu, activeMenu }: ComponentProps) => {
  const dispatch = useAppDispatch();
  const [menuOpen, toggleMenu] = useState<boolean>(false);
  const [objects, setObjects] = useState<any>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  //#endregion
  const searchInput = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (activeMenu == EMenu.Search) {
      setShowOverlay(true);
      loadData();
      toggleMenu(true);
      searchInput.current?.getElementsByTagName("input")[0].focus();
    }
    else {
      setTimeout(() => {
        setShowOverlay(false);
      }, 500);
      toggleMenu(false);
    }
  }, [activeMenu]);

  const loadData = async () => {
    if (!loaded) {
      var response: ArrayKeyValueObject = await requestGetUserSearchObjects();
      var userSearchObjects = Object.entries(response.groupBy("objecttype")).map(([objecttype, objects]) => {
        return {
          name: objecttype,
          type: "group",
          items: objects.map(object => {
            let name = object.objectcontroller;

            if (!objecttype.isEqual("ENTRY FORM")) {
              var index = name.indexOf(" - ");
              name = name.substring(index + 3);
            }

            return { value: object.objectcontroller.split(" - ")[0] + " - " + object.objecttype, name }
          })
        }
      })

      setObjects(userSearchObjects);
      setLoaded(true);
    }
  }

  const routeToObject = (objectcontrollerno: string, objecttype: string) => {
    switch (objecttype) {
      case "ENTRY FORM":
        dispatch(getScreenConfig({ processCode: objectcontrollerno }))
        break;
      case "DATA ANALYTICS":
        dispatch(getDataAnalyticsConfig({ dataAnalyticsno: objectcontrollerno }))
        break;
      case "REPORTS":
        dispatch(getReportConfig({ reportObjectno: objectcontrollerno }))
        break;
    }
  }

  const onChange = (val: string) => {
    var objectcontrollerno = val.split(" - ")[0];

    var objecttype = val.split(" - ")[1].toUpperCase();

    routeToObject(objectcontrollerno, objecttype)
    closeMenu();
  };

  const filterOptions = (groups: any) => {
    return (searchValue: any) => {
      if (searchValue.length === 0) {
        return objects;
      }
      const updatedItems = groups.map((items: any) => {
        const newItems = items.items.filter((item: any) => {

          const line = item.name.toLowerCase().trim();
          const searchText = searchValue.toLowerCase().trim();

          const searchArray: string[] = searchText.split(/\s+/);

          const findCount = searchArray.filter(x => line.includes(x));

          return findCount.length == searchArray.length;
        });
        return { ...items, items: newItems };
      });

      return updatedItems;
    };
  }

  const onKeyDown = ({ key }: any) => {
    if (key.isEqual("Escape")) {
      closeMenu();
    }
  }

  return AlignUIRender({ menuOpen, showOverlay, options: objects, onChange, filterOptions, searchInput, onKeyDown, closeMenu });
};

export default Search;

declare type StateFromProps = {
  closeMenu: () => void,
  activeMenu: EMenu
};

declare type ComponentProps = StateFromProps;