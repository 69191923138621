import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import produce from "immer";

export enum EMenu {
  Module = "lblmodule",
  Favorite = "lblfavorite",
  Dashboard = "lbldashboard",
  Search = "lblsearch",
  Home = "lblhome",
  RecentActivity = "lblrecentactivity",
  Workspace = "lblworkspace",
  None = "None"
}

export enum EObjectType {
  EntryForm = 1,
  Dashboard = 2,
  DataAnalytics = 3,
  ReportExplorer = 4
}

export interface ITab {
  activeTabKey: string,
  tabs: Array<{
    index: number,
    tabActualKey: string,
    transactionno: string,
    tabKey: string,
    tabName: string,
    prefix: string,
    url: string,
    objectType: EObjectType,
    hidden: boolean
  }>
}

const InitialState: ITab = {
  activeTabKey: "",
  tabs: []
}

const TabSlice = createSlice({
  name: "tab",
  initialState: InitialState,
  reducers: {

    setActiveTab(state, action: PayloadAction<string>) {
      return produce(state, (draftState) => {
        draftState.activeTabKey = action.payload;
      });
    },

    closeTab(state, action: PayloadAction<string>) {
      const tabKey = action.payload;

      return produce(state, (draftState) => {

        const index = draftState.tabs.findIndex(x => x.tabKey == tabKey);
        if (index != -1)
          draftState.tabs.splice(index, 1);

      });
    },

    setTabConfig(state, action: PayloadAction<{ tabActualKey: string, tabIndex: number, tabKey: string, url: string, name: string, objectType: EObjectType, hidden: boolean }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {

        const sameTabs = draftState.tabs.filter(x => x.tabActualKey == payload.tabActualKey);
        let tabName = `${payload.name}`;

        if (sameTabs.length == 1)
          sameTabs[0].tabName += ` (${sameTabs[0].index})`;

        if (sameTabs.length)
          tabName += ` (${payload.tabIndex})`;

        draftState.tabs.push({
          tabActualKey: payload.tabActualKey,
          tabKey: payload.tabKey,
          url: payload.url,
          tabName,
          prefix: "View",
          index: payload.tabIndex,
          transactionno: "",
          objectType: payload.objectType,
          hidden: payload.hidden
        });

        if (!payload.hidden)
          draftState.activeTabKey = payload.tabKey;

      });
    },

    setTabPrefixName(state, action: PayloadAction<string>) {
      const name = action.payload;

      return produce(state, (draftState) => {

        const activeTab = draftState.tabs.find(x => x.tabKey == state.activeTabKey);

        if (activeTab) {
          activeTab.prefix = `${name}`;
        }

      });
    },

    setTransactionNoOnActiveTab(state, action: PayloadAction<{ transactionno: string }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {

        const activeTab = draftState.tabs.find(x => x.tabKey == state.activeTabKey);

        if (activeTab)
          activeTab.transactionno = payload.transactionno;

      });
    }
  }
});

export const { setTabConfig, setActiveTab, closeTab, setTabPrefixName, setTransactionNoOnActiveTab } = TabSlice.actions;

export default TabSlice.reducer;
