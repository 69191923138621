import { call, put, select } from "redux-saga/effects";
import { setGlobalMessage } from "@/Redux/Ducks/messageSlice";
import { requestGetReportConfig, requestGetReportMenu, requestLoadReport } from "@/Redux/Sagas/Requests/reportExplorer";
import { toggleLoader } from "@/Redux/Ducks/loaderSlice";
import { loadReport, setReport, setReportConfig, setReportMenu, setReportModelValue, setReportPreviousCriteria } from "@/Redux/Ducks/reportExplorerSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { RootState } from "@/Redux/configureStore";
import { EObjectType, setTabConfig } from "@/Redux/Ducks/tabSlice";
import { KeyValueObject } from "@/UI/Interfaces/IBaseUI";
import { ICriteriaFieldValue } from "@/UI/Interfaces/IFieldConfig";

export function* handleGetReportMenu(): any {

  try {
    yield put(toggleLoader(true));

    const response = yield call(requestGetReportMenu);

    yield put(setReportMenu({ reportMenu: response.UserModules }));

  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

export function* handleGetReportConfig({ payload }: PayloadAction<{ reportObjectno: string; criteria?: KeyValueObject; requiredLetterHead?: boolean, modelValue: KeyValueObject<ICriteriaFieldValue>, withoutRedirect?: boolean, reportLoaded?: (reportObjectno: string, objectId: string) => void }>): any {

  const reportObjectno = payload.reportObjectno;

  let reportConfig;
  let tabKey = reportObjectno;
  let tabIndex = 1;
  let url = "/RE/" + reportObjectno;

  try {

    yield put(toggleLoader(true));

    const tabs = yield select((state: RootState) => state.Tab.tabs.filter(x => x.tabActualKey == reportObjectno));

    if (tabs.length) {
      const object = yield select((state: RootState) => state.ReportExplorer.reportDetailObject[tabs[0].tabKey]);

      reportConfig = {
        criteria: object.criteria,
        FICriteria: object.FICriteria,
        objectConfiguration: object.objectConfiguration,
        requiredLetterHead: object.requiredLetterHead,
      };

      tabIndex = tabs[tabs.length - 1].index + 1;

      url += "_" + tabIndex;

    }
    else {

      const response = yield call(requestGetReportConfig, reportObjectno);

      reportConfig = {
        criteria: response.criteria,
        FICriteria: response.FICriteria,
        objectConfiguration: response.objectConfiguration,
        requiredLetterHead: response.requiredLetterHead,
      };

    }

    tabKey += "_" + tabIndex;

    yield put(setTabConfig({ tabActualKey: reportObjectno, tabIndex, tabKey, url, name: reportConfig.objectConfiguration.processcodestxt, objectType: EObjectType.ReportExplorer, hidden: !!payload.withoutRedirect }));
    yield put(setReportConfig({ ...reportConfig, objectId: tabKey, modelValue: payload.modelValue }));

    // this condition will work when report is opening from screen
    if (payload.criteria && Object.keys(payload.criteria).length) {
      const requiredLetterHead = payload.requiredLetterHead || false;

      const fieldid = Object.keys(payload.criteria)[0] as string;
      const value = Object.values(payload.criteria)[0] as string;
      yield put(setReportModelValue({ fieldId: fieldid, newValue: value, toValue: false }));

      yield call(handleLoadReport, { payload: { reportObjectno, requiredLetterHead, exportExcel: false }, type: "" });
    }

    if (payload.modelValue) {
      yield call(handleLoadReport, { payload: { reportObjectno, requiredLetterHead: false, exportExcel: false }, type: "" });
    }

    if (payload.withoutRedirect != true)
      yield put(push(url));

  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
    yield put(push("/")); // set the condition

  }
  finally {

    if (payload.reportLoaded)
      payload.reportLoaded(reportObjectno, tabKey);

    yield put(toggleLoader(false));
    // yield put(setObjectLoaded());

  }
}


export function* handleLoadReport({ payload }: PayloadAction<{ reportObjectno: string, requiredLetterHead: boolean, exportExcel: boolean }>): any {

  try {
    yield put(toggleLoader(true));

    const modelValue = yield select((state: RootState) => state.ReportExplorer.reportDetailObject[state.ReportExplorer.activeReportObjectno].modelValue);
    const reportObjectno = payload.reportObjectno;

    const response = yield call(requestLoadReport, modelValue, reportObjectno, payload.requiredLetterHead, payload.exportExcel);

    yield put(setReportPreviousCriteria(modelValue));
    if (payload.exportExcel) {
      const reportName = yield select((state: RootState) => state.ReportExplorer.reportDetailObject[state.ReportExplorer.activeReportObjectno].objectConfiguration.processcodestxt);

      ReportExportToExcel(response, reportName);
    }
    else {
      yield put(setReport(response));
    }

  }
  catch (error: any) {
    yield put(setReport(null));
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}


function ReportExportToExcel(response: any, reportName: string) {
  var fileName = reportName;
  var a = document.createElement("a");
  var mediaType = "data:application/vnd.ms-excel;base64,";

  var url = mediaType + response;
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
}

