import React, { Component } from 'react';
import { AlignUIRender } from './indexUI';

class UnAuthorize extends Component {
  constructor(props: any) {
    super(props);
  }

  render = () => AlignUIRender(this.props);

}

export default UnAuthorize;
