import React from 'react'

interface ITabProps {
  controlId: string,
  translationControlId?: string,
  label?: string,
  children: any,
  system?: boolean
}

const Tab = (props: ITabProps) => {
  return props.children
}

export default Tab