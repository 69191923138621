import { useAppSelector } from '@/Hook/hooks';
import { IDimension, KeyValueObject } from '@/UI/Interfaces/IBaseUI';
import { IFieldConfig, IFieldObject } from '@/UI/Interfaces/IFieldConfig';
import { IGridHeaderColumns } from '@/UI/Interfaces/IGrid';
import { Accordion, AlignInput, AlignTab } from 'AlignComponents';
import { DataType } from "@grapecity/wijmo";
import React, { useEffect, useState } from 'react'
import BaseMethods from 'AlignBaseUI/BaseMethods';

const financialdimension = "lblfinancialdimension";

function LineInformation(props: { header: Array<IGridHeaderColumns>, setCellData(rowIndex: number, columnId: string, value: any, helpSelected: boolean, preventValidateHelp: boolean, preventChangeToGrid: boolean): void, wijmoUpdateDimensionValue(columnId: string, value: any, rowIndex: number): void, wijmoGridDimensionObject: IDimension[], isSubGrid: boolean, isReadonly: boolean, gridRef: any, linegrouping: boolean, isgridfinancialdimension: boolean, gridno: string }) {

  const [lineGroup, setLineGroup] = useState<[string, [string, Partial<IGridHeaderColumns>[]][]][]>([]);
  // const [selectedRowData, setSelectedRowData] = useState<KeyValueObject>({});
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

  const companyFinancialDimension = useAppSelector(state => props.isgridfinancialdimension ? state.EntryForm.objects[state.EntryForm.activeObjectId].ScreenConfig.CompanyDimensionFields : []);
  const gridOptions = useAppSelector((state) => state.EntryForm.objects[state.EntryForm.activeObjectId].gridOptions[props.gridno]);

  useEffect(() => {

    let lineGroup: [string, [string, Partial<IGridHeaderColumns>[]][]][] = [];
    if (props.linegrouping) {

      const filteredLineGroup = props.header.filter(x => x.linegroupno);

      const groupOnLineGroup = filteredLineGroup.sort((a, b) => (a.linegroupposition - b.linegroupposition || a.linegroupno.localeCompare(b.linegroupno))).groupBy("linegroupno");

      lineGroup = Object.entries(groupOnLineGroup).map(([key, value]) => {
        const groupOnLineSubGroup = value.sort((a, b) => (a.linesubgroupposition - b.linesubgroupposition || a.linesubgroupno.localeCompare(b.linesubgroupno))).groupBy("linesubgroupno");
        return [key, Object.entries(groupOnLineSubGroup)];
      });

    }

    if (props.isgridfinancialdimension && companyFinancialDimension.length) {
      lineGroup = lineGroup.concat([[financialdimension, [["", companyFinancialDimension.filter(x => !x.fieldid.isEqual("affiliate")).map(x => ({ ...x, linegroupno: financialdimension }))]]]]);
    }
    // console.log(lineGroup);
    // const lineGroupByObject = Object.entries(lineGroup.groupBy("linegroupno"));
    setLineGroup(lineGroup);

  }, []);


  useEffect(() => {
    if (props.gridRef) {
      props.gridRef.selectionChanged.addHandler(function (s: any, e: any) {
        let rowIndex = -1;
        if (props.gridRef.selectedRows.length) {
          if (props.gridRef.selectedRows[0].dataItem) {
            rowIndex = props.gridRef.selectedRows[0].dataIndex;
          }
        }

        setSelectedRowIndex(rowIndex);
      });

      return () => {
        props.gridRef.selectionChanged.removeHandler();
      };
    }
  }, [props.gridRef]);

  // const getSelectedRowIndex = () => {

  //   const gridControl = props.gridRef;
  //   let selectedIndex = gridControl.selectedRows?.[0]?.dataIndex;
  //   selectedIndex = selectedIndex == undefined ? -1 : selectedIndex;

  //   return selectedIndex;
  // }

  const BaseFieldStateSet = (fieldid: string, newValue: any, helpSelected: boolean, isDimension: boolean) => {
    // const selectedIndex = getSelectedRowIndex();

    let lineidValue = props.gridRef.getCellData(selectedRowIndex, "lineid");

    if (lineidValue) {
      if (isDimension) {
        const value = newValue || null;
        props.wijmoUpdateDimensionValue(fieldid, value, selectedRowIndex);
      }
      else {
        let value = newValue;
        let col = props.gridRef.getColumn(fieldid);

        if (col.dataType == DataType.String)
          value = newValue ? newValue : null;

        props.setCellData(selectedRowIndex, fieldid, value, helpSelected, helpSelected, false);
      }
    }
  }

  if (lineGroup.length) {
    const selectedRowData = props.gridRef.rows[selectedRowIndex]?.dataItem;
    return (
      <div className="line-tab">
        <Accordion controlId="lbllineinformation" uniqueKey={props.gridno} system={true}>
          <AlignTab.TabPanel allowCustomLabel={true}>
            {
              lineGroup.map(([keyi, valuex], i) => (
                <AlignTab.Tab key={keyi} controlId={`${props.gridno}-${keyi}`} translationControlId={keyi as string} label={valuex[0][1][0].linegrouplabel} system={keyi == financialdimension}>
                  {
                    <div className="row">
                      {
                        valuex.map(([keyj, valuey], j) => {
                          return (
                            <div key={keyi + keyj} className="col-lg-6 col-xl-auto">
                              {
                                valuey[0].linesubgrouplabel ?
                                  <span className={"group-label"}>{valuey[0].linesubgrouplabel}</span>
                                  : null
                              }

                              {
                                valuey.map((valuez, k) => {

                                  let gridColumn = gridOptions[valuez.fieldid as string];

                                  let lineid = selectedRowData?.lineid;
                                  let value = null;
                                  if (lineid && selectedRowData) {
                                    if (keyi == financialdimension) {
                                      value = props.wijmoGridDimensionObject.find(x => x.dimensionRelations.indexOf(`${props.gridno}-${selectedRowIndex}-dimensionset`) != -1)?.dimensionValues?.[valuez.fieldid as string];
                                    }
                                    else {
                                      value = selectedRowData[valuez.fieldid as string];
                                    }
                                  }

                                  if (!value) {
                                    if (BaseMethods.getControlType(valuez.controltypeno as string) == "checkbox")
                                      value = false;
                                    else if (BaseMethods.getControlType(valuez.controltypeno as string) == "number")
                                      value = 0;
                                    else if (BaseMethods.getControlType(valuez.controltypeno as string) == "date")
                                      value = "1900-01-01T00:00:00";
                                    else if (BaseMethods.getControlType(valuez.controltypeno as string) == "time")
                                      value = "1900-01-01T00:00:00";
                                    else
                                      value = null;
                                  }

                                  let config: IFieldObject = {
                                    FieldConfig: [valuez as IFieldConfig]
                                  };

                                  if (keyi == financialdimension) {
                                    gridColumn = {
                                      enable: props.isReadonly ? false : true,
                                      dimensionrequired: true,
                                      fieldId: valuez.fieldid as string,
                                      helpObject: valuez.helpobjectno as string,
                                      helpWhere: "",
                                      validateMandatory: false,
                                      visible: true,
                                      errorTypeInRows: [],
                                      validatingHelpRows: [],
                                      columnLabel: "",
                                      hyperLinkJump: valuez.hyperlinkjump as string
                                      // validatingHelp: false,
                                      // errorType: EFieldErrorType.None,
                                    }

                                    config.BaseFieldStateSet = (fieldId: string, newValue: any, helpSelected: boolean = false) => BaseFieldStateSet(fieldId, newValue, helpSelected, true);
                                  }
                                  else {
                                    config.BaseFieldStateSet = (fieldId: string, newValue: any, helpSelected: boolean = false) => BaseFieldStateSet(fieldId, newValue, helpSelected, false);
                                  }

                                  return (
                                    <AlignInput
                                      key={keyi + keyj + valuez.fieldid}
                                      uniqueKey={props.gridno}
                                      fieldId={valuez.fieldid as string}
                                      config={config}
                                      customFieldEnable={!lineid ? false : gridColumn.enable}
                                      customFieldVisible={true}
                                      customFieldHelpObject={gridColumn.helpObject}
                                      customFieldHelpWhere={gridColumn.helpWhere}
                                      customHyperLinkJump={gridColumn.hyperLinkJump}
                                      customFieldValue={value}
                                      customValidateMandatory={false}
                                      customHelpParams={{
                                        returnParams: {
                                          fieldId: valuez.fieldid as string,
                                          gridno: props.gridno,
                                          gridRowIndex: selectedRowIndex,
                                          isDimension: keyi == financialdimension
                                        },
                                        isSubGrid: props.isSubGrid || false
                                      }}
                                      custom={true}
                                    />
                                  )
                                })
                              }
                            </div>
                          )
                        })
                      }
                    </div>
                  }
                </AlignTab.Tab>
              ))
            }
          </AlignTab.TabPanel>
        </Accordion>
      </div>
    )
  }
  else
    return null;
}

export default LineInformation
