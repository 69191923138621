import { call, put } from "redux-saga/effects";
import { setGlobalMessage } from "@/Redux/Ducks/messageSlice";
import { requestGetUserRights, requestGetHomeUserSpecificData, requestSetObjectFavorite } from "@/Redux/Sagas/Requests/home";
import { toggleLoader } from "@/Redux/Ducks/loaderSlice";
import { setHomeUserSpecificData, setUserDataAnalytics, setUserEntryForms, setUserObjects, setUserReport } from "@/Redux/Ducks/homeSlice";
import { PayloadAction } from "@reduxjs/toolkit"
import { requestGetDataAnalyticsObjectMenu } from "../Requests/dataAnalytics";
import { requestGetReportMenu } from "../Requests/reportExplorer";
import { addUserFavorite } from "@/Redux/Ducks/userSlice";
import { setScreenFavorite } from "@/Redux/Ducks/entryFormSlice";
import { setDataAnalyticsFavorite } from "@/Redux/Ducks/dataAnalyticsSlice";
import { setReportFavorite } from "@/Redux/Ducks/reportExplorerSlice";

export function* handleSetUserFavorite({ payload }: PayloadAction<{ objectcontrollerno: string, objectcontrollerstxt: string, applicationobjecttype: string }>): any {
  const { objectcontrollerno, objectcontrollerstxt, applicationobjecttype } = payload;

  try {
    yield put(toggleLoader(true));

    yield call(requestSetObjectFavorite, objectcontrollerno, applicationobjecttype);
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    if (applicationobjecttype == "E") {
      yield put(setScreenFavorite({ objectcontrollerno }));
    }
    else if (applicationobjecttype == "I") {
      yield put(setDataAnalyticsFavorite({ objectcontrollerno }))
    }
    else if (applicationobjecttype == "R") {
      yield put(setReportFavorite({ objectcontrollerno }))
    }

    yield put(addUserFavorite({ applicationobjecttype, objectcontrollerno, objectcontrollerstxt }));

    yield put(toggleLoader(false));
  }
}

export function* handleGetHomeUserSpecificData(): any {

  try {
    yield put(toggleLoader(true));
    const response = yield call(requestGetHomeUserSpecificData);

    yield put(setHomeUserSpecificData({ userTodayActivity: response.userTodayActivity, announcements: response.announcements, useraction: response.useraction }));

  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

export function* handleGetUserEntryForms(): any {
  try {
    const response = yield call(requestGetUserRights);

    yield put(setUserEntryForms({ userEntryForms: response }));
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

export function* handleGetUserObjects(): any {
  try {
    const response = yield call(requestGetUserRights);

    yield put(setUserObjects({ userObjects: response }));
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

export function* handleGetUserDataAnalytics(): any {
  try {
    const response = yield call(requestGetDataAnalyticsObjectMenu);

    yield put(setUserDataAnalytics({ userDataAnalytics: response }));
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

export function* handleGetUserReport(): any {
  try {
    const response = yield call(requestGetReportMenu);

    yield put(setUserReport({ userReport: response }));
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}