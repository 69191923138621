import { Icon } from "AlignComponents";
import { ResetPassword } from ".";
// @ts-ignore
import PasswordMask from 'react-password-mask';
export const AlignUIRender = ({
  resetPassword,
  updateFieldState,
  state,
  handleKeyDown,
  props,
  navigateToLogin
}: ResetPassword) => (
  <>
    <div className="page-links">
      <a className="active" role="button">Reset Password</a>
    </div>
    <form>
      <PasswordMask
        placeholder="New Password"
        inputClassName="form-control"
        value={state.password}
        onKeyDown={handleKeyDown}
        onChange={(e: any) => updateFieldState("password", e.target.value)}
        showButtonContent={
          <Icon icon="Eye" size="18" color="#8D8D8D" />
        }
        hideButtonContent={
          <Icon icon="EyeOff" size="18" color="#8D8D8D" />
        }
        buttonStyles={{ background: 'transparent', marginTop: '-18px' }}
      />
      <PasswordMask
        placeholder="Confirm Password"
        inputClassName="form-control"
        value={state.confirmpassword}
        onKeyDown={handleKeyDown}
        onChange={(e: any) => updateFieldState("confirmpassword", e.target.value)}
        showButtonContent={
          <Icon icon="Eye" size="18" color="#8D8D8D" />
        }
        hideButtonContent={
          <Icon icon="EyeOff" size="18" color="#8D8D8D" />
        }
        buttonStyles={{ background: 'transparent', marginTop: '-18px' }}
      />
      <div className="form-button">
        <button className="ibtn" type="button" onClick={() => resetPassword()}>Change Password</button>
        <button className="ibtn" onClick={navigateToLogin}>Back to Login</button>
      </div>
    </form>
  </>
)
