import { EApprovalStatusType } from "@/Redux/Ducks/inboxSlice";
import { get } from "@/Utils/axios";

export function requestGetApproval(page: number, lastprid: number, searchParam: string): any {
  return get(`Configuration/GetApproval`, { params: { page, lastprid, searchParam } });
}

export function requestGetOutbox(page: number, lastprid: number, searchParam: string): any {
  return get(`Configuration/GetOutbox`, { params: { page, lastprid, searchParam } });
}

export function requestGetOutboxApprovals(page: number, lastprid: number, searchParam: string): any {
  return get(`Configuration/GetOutboxApprovals`, { params: { page, lastprid, searchParam } });
}

export function requestGetOutboxNotifications(page: number, lastprid: number, searchParam: string): any {
  return get(`Configuration/GetOutboxNotifications`, { params: { page, lastprid, searchParam } });
}

export function requestGetImportant(page: number, lastprid: number, searchParam: string): any {
  return get(`Configuration/GetImportant`, { params: { page, lastprid, searchParam } });
}

export function requestGetTrash(page: number, lastprid: number, searchParam: string): any {
  return get(`Configuration/GetTrash`, { params: { page, lastprid, searchParam } });
}

export function requestGetNotifications(page: number, lastprid: number, searchParam: string): any { // Temporary
  return get(`Configuration/GetNotifications`, { params: { page, lastprid, searchParam } });
}

export function requestGetActiveApprovals(page: number, lastprid: number, searchParam: string): any { // Temporary
  return get(`Configuration/GetActiveApprovals`, { params: { page, lastprid, searchParam } });
}

export function requestSetWorkflowApproval(approvalStatus: EApprovalStatusType, approvalprid: number, reason: string): any {
  return get(`Configuration/SetWorkflowApproval`, { params: { approvalStatus, approvalprid, reason } });
}

export function requestGetUserInboxInformation(): any {
  return get(`Configuration/GetUserInboxInformation`);
}

export function requestSetMessageRead(isRead: boolean, messagePrid: number): any {
  return get(`Configuration/SetMessageRead`, { params: { isRead, messagePrid } });
}

export function requestMarkImportant(messagePrid: number): any {
  return get(`Configuration/MarkImportant`, { params: { messagePrid } });
}

export function requestMarkTrash(messagePrid: number): any {
  return get(`Configuration/MarkTrash`, { params: { messagePrid } });
}
