import { useRef } from 'react'
import { Dropdown, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDrag, useDrop } from "react-dnd";
import { BsThreeDotsVertical } from 'react-icons/bs';
import { RiExternalLinkLine } from 'react-icons/ri';
import { IWorkspaceWidget } from '.';

interface IProps {
  list: IWorkspaceWidget,
  index: number,
  moveListHeader: (dragIndex: number, hoverIndex: number) => void,
  routeToScreen: (screenCode: string, gridView: string, objectType: string) => void,
  openUpdateWorkspace: (props: {
    widgetNo: string;
    widgetName: string;
    widgetType: string;
    icon?: string | undefined;
    widgetGroup?: string | undefined;
  }) => void,
  deleteWorkspace: (widgetNo: string) => void
}
export const ListHeader = ({ list, index, moveListHeader, routeToScreen, openUpdateWorkspace, deleteWorkspace }: IProps) => {
  const ref = useRef<any>(null);

  const [, drop] = useDrop({
    accept: 'ListHeader',
    hover(item: { id: number, index: number }, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset: any = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY: any = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveListHeader(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'ListHeader',
    item: () => {
      return { id: list.widgetNo, index: index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <Nav.Item ref={ref}>
      <Nav.Link eventKey={list.widgetName}>
        {list.widgetName}
        <RiExternalLinkLine className="ms-2" style={{ color: '#007db8', margin: '0px 3px' }} size="16" onClick={() => { routeToScreen(list.processCode, list.gridView, list.objectType) }} />
        <Dropdown className="caret-none" autoClose={false}>
          <OverlayTrigger placement="top" overlay={<Tooltip>Menu</Tooltip>}>
            <Dropdown.Toggle id="workspace-menu-button" variant="white" className="p-0">
              <BsThreeDotsVertical size="16" />
            </Dropdown.Toggle>
          </OverlayTrigger>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => { openUpdateWorkspace({ widgetNo: list.widgetNo, widgetName: list.widgetName, widgetType: list.widgetType }) }}>Edit</Dropdown.Item>
            <Dropdown.Item onClick={() => { deleteWorkspace(list.widgetNo) }}>Delete</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Nav.Link>
    </Nav.Item>
  )
}
