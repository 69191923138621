import { IDataAnalyticsLayout } from "@/Redux/Ducks/dataAnalyticsSlice";
import { get, post } from "@/Utils/axios";

export function requestGetDataAnalyticsObjectMenu(): any {
  return get(`DataAnalytics/GetDataAnalyticsObjectMenu`);
}

export function requestLoadDataAnalyticsDetail(model: any, dataanalyticsno: string): any {
  return post(`DataAnalytics/LoadDataAnalyticsDetail`, { model, dataanalyticsno });
}

export function requestDataAnalyticsConfig(dataanalyticsno: string): any {
  return get(`DataAnalytics/GetDataAnalyticsConfig/${dataanalyticsno}`);
}

export function requestStoreLayout(params: Omit<IDataAnalyticsLayout, "layoutno" | "layoutrefno">): any {
  return post(`DataAnalytics/StoreLayout`, { ...params });
}

export function requestUpdateLayout(params: IDataAnalyticsLayout): any {
  return post(`DataAnalytics/UpdateLayout`, { ...params });
}

export function requestDeleteLayout(layoutno: number, layouttype: string) {
  return get(`DataAnalytics/DeleteLayout`, { params: { layoutno, layouttype } });
}

export function requestGetUsersList(): any {
  return get(`DataAnalytics/GetUsersList`);
}