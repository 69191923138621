import { Icon } from "@/Assets/Icons";
import classNames from "classnames";
import TimeAgo from 'react-timeago';
import { EApprovalStatusType, ENotificationType } from "@/Redux/Ducks/inboxSlice";
import BaseMethods from "AlignBaseUI/BaseMethods";
import { MdMailOutline, MdRefresh, MdMarkEmailUnread, MdOutlineDelete, MdOutlineStar, MdOutlineCheckCircleOutline, MdNotifications, MdOutlineNotificationImportant, MdInfoOutline, MdSend, MdOutbox, MdSearch, MdChevronLeft } from 'react-icons/md';
import { constant } from "@/Utils/constants";
import { Button, Col, Container, Row } from "react-bootstrap";

export const AlignUIRender = ({ activeSideMenu, changeRoute }: any) => {
	return (
		<div className="menu">
			<div className={classNames(["menu-item", { active: activeSideMenu.isEqual("/approval", "/approval/active") }])} onClick={() => changeRoute("approval")}>
				<div dangerouslySetInnerHTML={{ __html: Icon.Inbox }} />
				Approvals
			</div>
			<div className={classNames(["menu-item", { active: activeSideMenu == "/notification" }])} onClick={() => changeRoute("notification")}>
				<div dangerouslySetInnerHTML={{ __html: Icon.Notifications }} />
				Notifications
			</div>
			<div className={classNames(["menu-item", { active: activeSideMenu.isEqual("/outbox", "/outbox/approval", "/outbox/notification") }])} onClick={() => changeRoute("outbox")}>
				<div dangerouslySetInnerHTML={{ __html: Icon.SentForApproval }} />
				Outbox
			</div>
			<div className={classNames(["menu-item", { active: activeSideMenu == "/important" }])} onClick={() => changeRoute("important")}>
				<div dangerouslySetInnerHTML={{ __html: Icon.Important }} />
				Important
			</div>
			<div className={classNames(["menu-item", { active: activeSideMenu == "/trash" }])} onClick={() => changeRoute("trash")}>
				<div dangerouslySetInnerHTML={{ __html: Icon.Trash }} />
				Trash
			</div>
		</div>
	)
}