import { RiAdminLine, RiCustomerServiceLine, RiOrganizationChart, RiProductHuntLine, RiShip2Line } from "react-icons/ri";
import { BsBook, BsCashCoin, BsFillHouseDoorFill, BsTools, BsTruck } from "react-icons/bs";
import { FaHandHolding, FaRegFilePowerpoint, FaRegUser, FaWarehouse } from "react-icons/fa";
import { MdAttachMoney, MdOutlineInventory2, MdOutlinePayments, MdPointOfSale } from "react-icons/md";
import { AiFillBank, AiFillCar, AiOutlineFundProjectionScreen } from "react-icons/ai";
import { TbBuildingFactory } from "react-icons/tb";
import { BiBrain, BiPurchaseTagAlt, BiTimeFive } from "react-icons/bi";
import { HiOutlineReceiptTax } from "react-icons/hi";

const ModuleIcon = (props: { moduleno: string }) => {
  const { moduleno } = props;

  return (
    <>
      {
        moduleno == "SA" ? (
          <RiAdminLine className="me-2" />
        ) : moduleno == "OS" ? (
          <RiOrganizationChart className="me-2" />
        ) : moduleno == "GL" ? (
          <BsBook className="me-2" />
        ) : moduleno == "AP" ? (
          <FaHandHolding style={{ transform: "rotate(180deg)" }} className="me-2" />
        ) : moduleno == "AR" ? (
          <FaHandHolding className="me-2" />
        ) : moduleno == "EM" ? (
          <MdAttachMoney className="me-2" />
        ) : moduleno == "FA" ? (
          <AiFillCar className="me-2" />
        ) : moduleno == "PI" ? (
          <RiProductHuntLine className="me-2" />
        ) : moduleno == "SD" ? (
          <MdPointOfSale className="me-2" />
        ) : moduleno == "PA" ? (
          <AiOutlineFundProjectionScreen className="me-2" />
        ) : moduleno == "SM" ? (
          <RiCustomerServiceLine className="me-2" />
        ) : moduleno == "PC" ? (
          <TbBuildingFactory className="me-2" />
        ) : moduleno == "FF" ? (
          <BsTruck className="me-2" />
        ) : moduleno == "RE" ? (
          <BsFillHouseDoorFill className="me-2" />
        ) : moduleno == "TM" ? (
          <BiTimeFive className="me-2" />
        ) : moduleno == "FM" ? (
          <RiShip2Line className="me-2" />
        ) : moduleno == "EP" ? (
          <FaRegUser className="me-2" />
        ) : moduleno == "TC" ? (
          <HiOutlineReceiptTax className="me-2" />
        ) : moduleno == "PY" ? (
          <MdOutlinePayments className="me-2" />
        ) : moduleno == "WM" ? (
          <FaWarehouse className="me-2" />
        ) : moduleno == "CB" ? (
          <AiFillBank className="me-2" />
        ) : moduleno == "BU" ? (
          <BsCashCoin className="me-2" />
        ) : moduleno == "MP" ? (
          <BiBrain className="me-2" />
        ) : moduleno == "PS" ? (
          <BiPurchaseTagAlt className="me-2" />
        ) : moduleno == "IC" ? (
          <MdOutlineInventory2 className="me-2" />
        ) : moduleno == "PL" ? (
          <FaWarehouse className="me-2" />
        ) : moduleno == "PM" ? (
          <BsTools className="me-2" />
        ) : (
          <FaRegFilePowerpoint className="me-2" />
        )
      }
    </>
  )

}

export default ModuleIcon