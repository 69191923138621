import Chart from "@/UI/BaseComponents/Chart"
import Table from "@/UI/BaseComponents/Table"
import Information from "@/UI/BaseComponents/Information"
import Map from "@/UI/BaseComponents/Map"
import { Accordion } from "AlignComponents"
import { useAppSelector } from "@/Hook/hooks"
import Select from 'react-select-search'
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css"
import { useEffect, useState } from "react"
// @ts-ignore
import Slide from 'react-reveal/Slide';
import { ArrayKeyValueObject } from "@/UI/Interfaces/IBaseUI"
import { Button, Container, Row } from "react-bootstrap"

interface IProps {
	activeWidgetGroupNo: string,
	layout: any,
	options: any,
	widgets: any,
	routeToScreen: (screenCode: string, transactionNo?: string | undefined) => void,
	saveLayout: (widgetgroupno: string, layout: JSON, insightarea: string) => void,
	onChange: (e: any) => void,
	onLayoutChange: (layout: any) => void
}

const GridLayout = WidthProvider(RGL);

export const AlignUIRender = ({ activeWidgetGroupNo, layout, options, widgets, routeToScreen, saveLayout, onChange, onLayoutChange }: IProps) => {
	if (layout && activeWidgetGroupNo) {
		return (
			<div className="dashboard">
				{
					Object.entries(widgets).map(([key, value], i) => (
						(value as any).filter((x: any) => x.isdashboard && x.widgetgroupno == activeWidgetGroupNo).length >= 1 ? (
							<div className="m-3" key={key}>
								<div className="d-flex">
									<Select onChange={onChange} value={activeWidgetGroupNo} options={options} />
									<Button variant="primary" className="ms-auto" onClick={() => { saveLayout((value as any)[0].widgetgroupno, layout, 'H') }}>Save Layout</Button>
								</div>
								<GridLayout layout={JSON.parse(layout)} cols={12} rowHeight={50} onLayoutChange={onLayoutChange} draggableHandle=".drag-handle">
									{
										(value as any).map((x: any, i: number) => {
											if (x.isdashboard) {
												if (x.widgettype == 'CHRT') {
													return (
														<div key={x.widgetno} style={{ overflow: 'hidden' }}>
															<Chart chartType={x.widgetcontroltype} data={x.data} title={x.widgetlabel} widget={x} routeToScreen={routeToScreen} />
														</div>
													)
												}
												else if (x.widgettype == 'TBLE') {
													return (
														<div key={x.widgetno} style={{ overflow: 'hidden' }}>
															<Table detail={x.data} controlid={x.widgetlabel} widgetlabel={x.widgetlabel} widgetprocesscode={x.widgetprocesscode} routeToScreen={routeToScreen} />
														</div>
													)
												}
												else if (x.widgettype == 'INFO') {
													return (
														<div key={x.widgetno} style={{ overflow: 'hidden' }}>
															<Information value={x.data} widgetlabel={x.widgetlabel} widgetprocesscode={x.widgetprocesscode} widget={x} routeToScreen={routeToScreen} />
														</div>
													)
												}
												else if (x.widgettype == 'MAP') {
													return (
														<div key={x.widgetno} style={{ overflow: 'hidden' }}>
															<Map detail={x.data} widgetlabel={x.widgetlabel} widgetprocesscode={x.widgetprocesscode} widget={x} routeToScreen={routeToScreen} />
														</div>
													)
												}
											}
										})
									}
								</GridLayout>
							</div>
						) : null
					))
				}
			</div>
		)
	}
	else {
		return null
	}
}