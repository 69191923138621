import { useEffect, useState } from 'react'

import { useAppDispatch, useAppSelector } from '@/Hook/hooks';
import { IFieldProps } from '@/UI/Interfaces/IComponent';

const Field = ({ config, fieldValueChange, fieldFocusOut, uniqueKey, enable, value, validateMandatory, hideLabel }: IFieldProps) => {
  const { fieldid } = config;
  const dispatch = useAppDispatch();
  const [currentValue, setCurrentValue] = useState<string>("");

  const [fieldIsInEditing, setFieldIsInEditing] = useState<boolean>(false);

  const objectId = useAppSelector((x) => x.EntryForm.activeObjectId);

  useEffect(() => {
    if (value === undefined) {
      callBaseMethod("");
    }
  }, [value]);

  const callBaseMethod = (value: any) => {
    fieldValueChange(fieldid, value);
  }

  const change = (e: any) => {

    setCurrentValue(e.target.value);
  }

  const focus = (e: any) => {

    setCurrentValue(e.target.value);
    setFieldIsInEditing(true);
  }

  const blur = (e: any) => {

    if (e.target.value != value)
      callBaseMethod(e.target.value);

    setCurrentValue("");
    setFieldIsInEditing(false);

    fieldFocusOut(fieldid, e.target.value);
  }

  if (!config) return <></>;
  const id = objectId + (uniqueKey ? `-${uniqueKey}` : "") + "-" + fieldid;

  return (
    <>
      {
        !hideLabel ?
          <label id={"lb-" + id} htmlFor={"field-" + id} >
            {config.fieldlabel}
            {validateMandatory ? (<span>*</span>) : null}
          </label>
          : null
      }
      <textarea className="field-textarea" rows={6} cols={35} disabled={!enable} id={"field-" + id} onFocus={(e: any) => focus(e)} value={fieldIsInEditing ? currentValue : (value || "")} onChange={(e: any) => change(e)} onBlur={(e: any) => blur(e)} />
    </>
  )

}
export default Field;
