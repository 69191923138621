import { useAppDispatch, useAppSelector } from '@/Hook/hooks';
import { getScreenConfig, setActiveObjectId } from '@/Redux/Ducks/entryFormSlice';
import { setTabConfig } from '@/Redux/Ducks/tabSlice';
// import { SetTabComponent } from '@/Redux/Ducks/tabSlice';
import { lazy, useEffect } from 'react'

import { AlignUIRender } from './indexUI';

let countRender: any = {};

const EntryForm = (props: any) => {

  const dispatch = useAppDispatch();

  const { routeTo } = props.match.params;

  const module = routeTo.substr(0, 2);
  const processCode = routeTo.split("_")[0].toUpperCase();

  // const activeObjectId = useAppSelector((state) => state.EntryForm.activeObjectId);
  const activeTabKey = useAppSelector((state) => state.Tab.activeTabKey);
  const activeObjectId = useAppSelector((state) => state.EntryForm.activeObjectId);
  const isConfigLoaded = useAppSelector((state) => state.EntryForm.isConfigLoaded);
  const authTokenLoaded = useAppSelector((state) => !!state.User.authToken);

  useEffect(() => {
    // this will work when direct call from URL
    if (!activeTabKey && !activeObjectId && authTokenLoaded)
      dispatch(getScreenConfig({ processCode }));

    countRender[routeTo] ? countRender[routeTo]++ : countRender[routeTo] = 1;
    console.log(`%cRender Entry Form Component ${routeTo} ${countRender[routeTo]}`, "color:red;font-size: 20px;font-weight: bold;text-align: center");
  }, [routeTo, authTokenLoaded]);


  const ScreenComponent = lazy(() =>
    import(`@/Screen/EntryForm/${module}/${processCode}`).catch((ex) => {
      const errorMessage = "Error while opening screen";
      console.error(errorMessage, ex);

      return { default: () => <div>{errorMessage}</div> };
    })
  );

  return AlignUIRender({ props, ScreenComponent, objectId: activeObjectId, isConfigLoaded });

}

export default EntryForm;


// import React, { Component } from 'react'
// import { connect } from 'react-redux';
// import { RootState } from '@/Redux/configureStore';

// class EntryForm extends Component<any> {

//   state = {
//     screenComponent: ""
//   }
//   constructor(props: any) {
//     super(props);


//     // const { routeTo } = this.props.match.params;
//     // const module = routeTo.substr(0, 2);

//     // this.objectId = routeTo;
//     // if (!this.props.ActiveTabObject.Component && !this.props.ActiveTabObject.Config) {
//     //   this.props.getScreenConfig({ routeTo, objectId: this.objectId });

//     // const ScreenComponent = lazy(() =>
//     //   import(`@/Screen/EntryForm/${module}/${routeTo}`).catch((ex) => {
//     //     const errorMessage = "Error while opening screen";
//     //     console.error(errorMessage, ex);

//     //     return { default: () => <div>{errorMessage}</div> };
//     //   })
//     // );

//     // const ComponentObject = {
//     //   TabKey: routeTo,
//     //   Component: ScreenComponent
//     // };

//     // this.props.SetTabComponent(ComponentObject);

//     // }


//   }

//   componentDidMount() {
//     this.loadComponent();
//   }

//   componentDidUpdate(prevProps: any) {
//     if (prevProps.match.params.routeTo != this.props.match.params.routeTo || !this.state.screenComponent) {
//       this.loadComponent();
//     }
//   }

//   componentWillUnmount() {
//     this.props.setActiveObjectId("");
//   }

//   loadComponent() {
//     const { routeTo } = this.props.match.params;
//     // const url = this.props.match.url;
//     const module = routeTo.substr(0, 2);
//     const processCode = routeTo.split("_")[0];

//     if (!this.props.isConfigLoaded) {
//       this.props.getScreenConfig({ processCode });
//     }
//     // else {
//     //   this.props.setActiveObjectId(routeTo);
//     //   this.props.setTabConfig({});
//     // }

//     const screenComponent = lazy(() =>
//       import(`@/Screen/EntryForm/${module}/${processCode}`).catch((ex) => {
//         const errorMessage = "Error while opening screen";
//         console.error(errorMessage, ex);

//         return { default: () => <div>{errorMessage}</div> };
//       })
//     );

//     this.setState({ screenComponent });
//   }

//   render() {
//     const { routeTo } = this.props.match.params;
//     countRender[routeTo] ? countRender[routeTo]++ : countRender[routeTo] = 1;
//     console.log(`%cRender Entry Form Component ${routeTo} ${countRender[routeTo]}`, "color:red;font-size: 20px;font-weight: bold;text-align: center");

//     const isConfigLoaded = !!this.props.isConfigLoaded && !!this.state.screenComponent;
//     const ScreenComponent = this.state.screenComponent;

//     return AlignUIRender({ props: this.props, ScreenComponent, objectId: routeTo, isConfigLoaded });
//   }
// }


// const MapStateToProps = (state: RootState, ownProps: any) => {

//   // const routeTo = ownProps.match.params.routeTo.split("_")[0];

//   // const isConfigLoaded = Object.keys(state.EntryForm.objects).filter(x => x.split("_")[0].includes(routeTo));
//   // const isTabLoaded = state.Tab.tabs;

//   return {
//     activeObjectId: state.EntryForm.activeObjectId,
//     isConfigLoaded: !!state.Tab.activeTabKey
//     // ActiveTabKey: state.Tab.ActiveTabKey,
//     // ActiveTabObject: state.Tab.Tabs[state.Tab.ActiveTabKey] || {}
//   }
// }

// const MapDispatchToProps = {
//   getScreenConfig,
//   setActiveObjectId,
//   setTabConfig
// };

// // export default connect(MapStateToProps, MapDispatchToProps)(EntryForm);

