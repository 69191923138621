import classNames from "classnames";
import menus from "./menus";
import Menu from "./Menu";
import FavoriteMenu from "./FavoriteMenu";
import Search from "./Search";
import { Button, Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ControlTranslation, Modal } from "AlignComponents"
import BaseMethods from "AlignBaseUI/BaseMethods";
import ReactTimeago from "react-timeago";
import WorkspaceMenu from "./WorkspaceMenu";
import { EMenu } from "@/Redux/Ducks/tabSlice";

interface IProps {
	Icon: {
		[key: string]: string
	},
	toggleMenu: (applicationobjecttype: EMenu) => void,
	activeMenu: EMenu,
	closeMenu: () => void,
	activityModalState: boolean,
	toggleActivityModal: any,
	userTodayActivity: any,
	user: string,
	routeToScreen: (screenCode: string, transactionNo?: string) => void,
}

export const AlignUIRender = ({ Icon, toggleMenu, activeMenu, closeMenu, activityModalState, toggleActivityModal, userTodayActivity, user, routeToScreen }: IProps) => (
	<>
		<Modal
			className="timeline-modal"
			heading="Recent Activity"
			show={activityModalState}
			close={() => {
				toggleMenu(EMenu.None)
				toggleActivityModal(false)
			}}
		>
			{
				userTodayActivity.length ?
					<div className="timeline">
						{
							userTodayActivity.slice(0, 4).map((x: any, i: number) => (
								<div key={i} className="timeline-item">
									<span className="timeline-bullet"></span>
									<span className="timeline-text">
										<p className="d-flex align-items-center mb-2">User: {user} {x.activitystxt}ed {x.objectcontrollerstxt}: <Nav.Link className="fs-13 p-0 ms-1" role="button" onClick={() => { routeToScreen(x.objectcontrollerno, x.parameterdocvalue) }}>{x.parameterdocvalue}</Nav.Link></p>
										<p className="text-muted mb-1">
											<ReactTimeago date={BaseMethods.convertUTCDateToLocal(x.activitydatetime).toString()} />
										</p>
									</span>
								</div>
							))
						}
					</div>
					:
					<div className="not-found">
						<img src={require("@/Assets/Images/not-found.png").default} alt="" />
						<p className="mb-0">YOU HAVE NO ACTIVITY</p>
						<span>You have no Activity to show. Proceed to a process to view Activity.</span>
					</div>
			}
		</Modal>
		<div id="aside">
			<img
				src={require("@/Assets/Images/logo-light.png").default}
				className="aside-logo"
			/>
			<ul className="aside-navigation">
				{
					menus.map((menu, idx) => {
						var tabIndex = 2 + idx;
						return (
							<OverlayTrigger key={menu} placement="right" overlay={<Tooltip><ControlTranslation system={true} controlId={menu} /></Tooltip>}>
								<li id={`Menu-${menu}`} tabIndex={tabIndex} role="button" className={classNames(["aside-item", { active: activeMenu == menu }])} dangerouslySetInnerHTML={{ __html: Icon[menu] }} onClick={() => toggleMenu(menu)}></li>
							</OverlayTrigger>
						)
					})
				}
			</ul>
		</div>

		<Menu closeMenu={closeMenu} activeMenu={activeMenu} />
		<FavoriteMenu closeMenu={closeMenu} activeMenu={activeMenu} />
		<Search closeMenu={closeMenu} activeMenu={activeMenu} />
		<WorkspaceMenu closeMenu={closeMenu} activeMenu={activeMenu} />
	</>
);
