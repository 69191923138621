import { RootState } from "@/Redux/configureStore";
import { toggleLoader } from "@/Redux/Ducks/loaderSlice";
import { setGlobalMessage } from "@/Redux/Ducks/messageSlice";
import { IUserForgotPassword, IUserLogin, IUserResetPassword, setBannerHidden, setColors, setHidden, setLoginBasicData, setUserObject } from "@/Redux/Ducks/userSlice";
import { setUserWorkspace } from "@/Redux/Ducks/workspaceSlice";
import { requestForgotPassword, requestGetBannerHidden, requestGetColors, requestGetHidden, requestGetUserSpecificDetail, requestLoginBasicData, requestResetPassword, requestToggleHidden, requestUserLogin, requestUserLogout } from "@/Redux/Sagas/Requests/user";
import { openAlert } from "@/UI/BaseComponents/Alert";
import { getCompanyDetails, getTokens, setCompanyDetails, setTokens } from "@/Utils/auth";
import storage from "@/Utils/storage";
import { PayloadAction } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { call, put, select } from "redux-saga/effects";

export function* handleLoginUser({ payload }: PayloadAction<IUserLogin>): any {
  try {

    yield put(toggleLoader(true));

    const response = yield requestUserLogin({ password: payload.password, clientno: payload.clientno, username: payload.username, languageno: payload.languageno });
    const data = response;

    yield put(setUserObject({ user: data.UserInfo, defaultPage: data.UserInfo.defaultPage, languageno: payload.languageno }));

    setTokens({ RefreshToken: data.RefreshToken, AccessToken: data.AccessToken });

    const redirectTo = payload.redirectUrl || data.UserInfo.defaultPage;

    yield put(push(redirectTo));

  } catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

export function* handleForgotPassword({ payload }: PayloadAction<IUserForgotPassword>): any {
  try {

    yield put(toggleLoader(true));

    yield requestForgotPassword(payload.email, payload.clientno);

    openAlert({ icon: "info", title: "Password Reset Request Sent", html: "A password reset message was sent to your email address. Please click the link in that message to reset your password. <br /><br /> <b>This link will expire in 10 minutes</b>" })
  } catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

export function* handleResetPassword({ payload }: PayloadAction<IUserResetPassword>): any {
  try {

    yield put(toggleLoader(true));

    yield requestResetPassword(payload.email, payload.token, payload.password);

    openAlert({ icon: "success", title: "Password Successfully Reset", text: "Your password has been succesfully reset. Now you can login with the new password." })

    yield put(push("/"));

  } catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

export function* handleGetUserSpecificDetail(): any {
  try {

    yield put(toggleLoader(true));

    const response = yield requestGetUserSpecificDetail();
    const data = response;

    const defaultLegalEntity = data.UserLegalEntity.filter((x: any) => x.isdefault)[0].legalentityno;
    const selectedLegalEntity = getCompanyDetails().selectedLegalEntity || defaultLegalEntity;
    const user = data.user[0];
    const userFavorites = data.userFavorites;
    const userWorkspace = data.userWorkspace;

    delete data.userFavorites;
    delete data.userWorkspace;

    if (data.CompanyLedgerInfo.length)
      data.CompanyLedgerInfo = data.CompanyLedgerInfo[0];
    else
      delete data.CompanyLedgerInfo;


    yield put(setUserObject({ ...data, user, authToken: getTokens().AccessToken, selectedLegalEntity, userFavorites }));
    yield put(setUserWorkspace(userWorkspace));

    setCompanyDetails({ timezone: data.companytimezone, selectedLegalEntity });

  } catch (error: any) {
    yield put(setGlobalMessage({ error }));
    yield put(push("/"));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

export function* handleLogoutUser(): any {
  try {
    yield put(toggleLoader(true));

    storage.clear();
    yield put(push("/login"));

    yield call(requestUserLogout);

  } catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

export function* handleGetLoginBasicData(): any {
  try {
    yield put(toggleLoader(true));

    const response = yield requestLoginBasicData();

    yield put(setLoginBasicData(response));


  } catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));

  }
}

export function* handleGetHidden(): any {
  try {
    const response = yield call(requestGetHidden);
    yield put(setHidden(response));
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

export function* handleGetBannerHidden(): any {
  try {
    const response = yield call(requestGetBannerHidden);
    yield put(setBannerHidden(response));
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

export function* handleGetColors(): any {
  try {
    const response = yield call(requestGetColors);
    yield put(setColors(response));
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

export function* handleToggleHidden({ payload }: PayloadAction<string>): any {
  try {
    debugger;
    const toggle = yield select((state: RootState) => state.User.hidden.includes(payload));
    yield call(requestToggleHidden, payload, toggle);
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}
