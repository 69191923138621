import { KeyValueObject } from "@/UI/Interfaces/IBaseUI";
import { ControlTranslation, Icon } from "AlignComponents";
import { Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiHide } from "react-icons/bi";
import { VscRefresh } from "react-icons/vsc"

interface IProps {
  userSettingObject: {
    companyBannerImage: string;
    clientLogo: string;
    username: string;
    legalEntityName: string;
  },
  refreshData: () => void,
  toggleHiddenFunc: () => void
}

export const AlignUIRender = ({ userSettingObject, refreshData, toggleHiddenFunc }: IProps) => (
  <Container fluid className="banner">
    <div
      className="banner-image"
      style={{ backgroundImage: `url(data:image/jpeg;base64,${userSettingObject.companyBannerImage})` }}
    />
    <div className="banner-group">
      <img className="client-image" src={`data:image/jpeg;base64,${userSettingObject.clientLogo}`} alt="" />
      <div className="banner-text">
        <span className="user-name"><ControlTranslation system={true} controlId="lblhello" /> {userSettingObject.username},</span>
        <span className="legalentity">{userSettingObject.legalEntityName}</span>
      </div>
      <OverlayTrigger placement="bottom" overlay={<Tooltip><ControlTranslation system={true} controlId="btnrefresh" /></Tooltip>}>
        <Button className="banner-refresh" variant="secondary" onClick={refreshData}>
          <VscRefresh size={18} />
        </Button>
      </OverlayTrigger>
    </div>
    {/* <OverlayTrigger placement="bottom" overlay={<Tooltip>Hide</Tooltip>}>
      <span className="banner-hide">
        <BiHide color="#fff" size="18" onClick={toggleHiddenFunc} />
      </span>
    </OverlayTrigger> */}
  </Container>
);
