import { RootState } from "@/Redux/configureStore";
import {
  changeObjectActivity, changeScreenOptions, clearAttachmentRemoveIds, fieldHelpValidateEnd, forceDisableActionButton, getDimensionValuesFromDimensionSetPromiseResolver, gridHelpValidateEnd, IShareDocument, pushUserDocumentHelpFilter, queueEnd, queueStart,
  resetScreenOptions, setAccountDimensions, setAttachmentError, setAttachmentObject, setAttachmentProgress, setComments, setDimensionSetModel, setDocumentModelValue, setFieldError,
  setFieldModelValue, setGridCellData, setGridRowError, setNewUploadingAttachments, setObjectLoaded, setScreenObject, setSubGridCellData, setUserHaveApprovalOption, toggleAttachmentUploadingModal, toggleReverseModal, updateAttachmentObject, uploadAttachment, upsertDimension
} from "@/Redux/Ducks/entryFormSlice";
import { closeHelp, EHelpOpenFor, EHelpOpenIn, EHelpType, getHelpConfig, IGetHelp, IGridView, IReturnParam, IReturnValues, setGridView, setHelpConfig, setHelpReturnValue } from "@/Redux/Ducks/helpSlice";
import { toggleLoader } from "@/Redux/Ducks/loaderSlice";
import { setGlobalMessage, setTabMessage } from "@/Redux/Ducks/messageSlice";
import { EObjectType, setTabConfig, setTabPrefixName, setTransactionNoOnActiveTab } from "@/Redux/Ducks/tabSlice";
import { IUserObject } from "@/Redux/Ducks/userSlice";
import { requestAddComment, requestDeleteDocument, requestFieldHelpValidate, requestGetAccountDimensions, requestGetAttachmentFileObject, requestGetCommentsByPrid, requestGetDimensionSetData, requestGetDocumentByDockey, requestGetDocumentByPrid, requestGetScreenConfig, requestRemoveAttachment, requestRemoveUserDocumentHelp, requestReverseDocument, requestSaveAttachment, requestSaveDocument, requestSaveUserDocumentHelpFilter, requestSetViewDefaultUserDocumentHelp, requestShareDocument } from "@/Redux/Sagas/Requests/entryForm";
import { requestGetDocumentHelp } from "@/Redux/Sagas/Requests/help";
import { openConfirmation } from "@/UI/BaseComponents/Alert";
import { ArrayKeyValueObject, EActivity, EFieldErrorType, IActionProcess, IAttachment, IControlTranslation, IDimension, IEntryFromObject, IGridUserConfig, IOtherState, IToolbar, KeyValueObject, ScreenConfig, ScreenObjectConfiguration } from "@/UI/Interfaces/IBaseUI";
import { IFieldConfig } from "@/UI/Interfaces/IFieldConfig";
import { IGrid } from "@/UI/Interfaces/IGrid";
import { EMessageType } from "@/UI/Interfaces/IMessage";
import { END, eventChannel } from "@redux-saga/core";
import { PayloadAction } from "@reduxjs/toolkit";
import BaseMethods from "AlignBaseUI/BaseMethods";
import { push } from "connected-react-router";
import { all, call, fork, put, select, take } from "redux-saga/effects";
import { handleGetScreenHelpRequiredTags } from "./help";


export function* handleGetScreenConfig({ payload }: PayloadAction<{ processCode: string, transactionNo?: string, gridView?: string }>): any {

  try {

    yield put(toggleLoader(true));

    const processCode = payload.processCode.toUpperCase();
    let transactionNo = payload.transactionNo;
    let model;
    let dimensionData;
    let Attachment;

    let screenConfig: { TcodeConfiguration: ScreenObjectConfiguration; FieldConfig: IFieldConfig[]; GridConfig: IGrid[]; ControlTranslation?: IControlTranslation[]; CompanyDimensionFields?: any; Toolbar?: IToolbar[]; ActionProcess?: IActionProcess[], UserDefaultParameter: ArrayKeyValueObject, ProcessReports: ScreenConfig["ProcessReports"], userDocumentHelpFilter: IOtherState["userDocumentHelpFilter"], ProcessCodeApplicationDataFormat: IUserObject["CompanyDataFormat"] };
    let tabKey = processCode;
    let tabIndex = 1;
    let url = "/EF/" + processCode;
    let documentHelpConfig;
    let CurrentUserApproverPrid;
    let CurrentUserApproverStatus;

    const tabs = yield select((state: RootState) => state.Tab.tabs.filter(x => x.tabActualKey == processCode));
    const selectedLegalEntity = yield select((state: RootState) => state.User.selectedLegalEntity);

    if (tabs.length) {
      const object: ScreenConfig = yield select((state: RootState) => state.EntryForm.objects[tabs[0].tabKey].ScreenConfig);
      const userDocumentHelpFilter = yield select((state: RootState) => state.EntryForm.objects[tabs[0].tabKey].otherState.userDocumentHelpFilter);

      screenConfig = {
        TcodeConfiguration: object.TcodeConfiguration,
        GridConfig: object.Grids.GridConfig,
        FieldConfig: object.Fields.FieldConfig,
        ControlTranslation: object.ControlTranslation,
        CompanyDimensionFields: object.CompanyDimensionFields,
        Toolbar: object.Toolbar,
        ActionProcess: object.ActionProcess,
        UserDefaultParameter: object.UserDefaultParameter,
        userDocumentHelpFilter: userDocumentHelpFilter,
        ProcessReports: object.ProcessReports,
        ProcessCodeApplicationDataFormat: object.ProcessCodeApplicationDataFormat,
      };

      const docKey = screenConfig.TcodeConfiguration.dockey;

      if (screenConfig.TcodeConfiguration.savemode.isEqual("L"))
        transactionNo = selectedLegalEntity

      if (!transactionNo) {
        if (screenConfig.TcodeConfiguration.isview || screenConfig.TcodeConfiguration.isdelete || screenConfig.TcodeConfiguration.isreverse || screenConfig.TcodeConfiguration.isedit) {

          const helpobjectno = screenConfig.FieldConfig.find(x => x.fieldid == docKey && x.activityno == "")?.helpobjectno as string;
          const helpwhere = screenConfig.FieldConfig.find(x => x.fieldid == docKey && x.activityno == "")?.helpwhere as string;

          const filter = { filterApproval: false };
          const viewFind = screenConfig.userDocumentHelpFilter.find(x => x.isdefault == true);
          if (viewFind != null)
            filter.filterApproval = viewFind.filterapproval;

          const response = yield call(requestGetDocumentHelp, helpobjectno, helpwhere, processCode, filter);

          documentHelpConfig = response;
        }
      }
      else {
        const response = yield call(requestGetDocumentByDockey, processCode, transactionNo);
        if (response) {
          model = response.Record;
          dimensionData = response.DimensionData;
          Attachment = response.Attachment;
          CurrentUserApproverPrid = response.CurrentUserApproverPrid;
          CurrentUserApproverStatus = response.CurrentUserApproverStatus;
        }
      }

      tabIndex = tabs[tabs.length - 1].index + 1;

      url += "_" + tabIndex;

    }
    else {

      const response = yield call(requestGetScreenConfig, processCode, transactionNo);

      if (response.TcodeConfiguration.savemode.isEqual("L"))
        transactionNo = selectedLegalEntity

      if (transactionNo && response.Document) {
        model = response.Document.Record;
        dimensionData = response.Document.DimensionData;
        Attachment = response.Document.Attachment;
        CurrentUserApproverPrid = response.Document.CurrentUserApproverPrid;
        CurrentUserApproverStatus = response.Document.CurrentUserApproverStatus;
      }

      screenConfig = {
        TcodeConfiguration: response.TcodeConfiguration,
        GridConfig: response.GridConfig,
        FieldConfig: response.FieldConfig,
        ControlTranslation: response.ControlTranslation,
        CompanyDimensionFields: response.CompanyDimensionFields,
        Toolbar: response.Toolbar,
        ActionProcess: response.ActionProcess,
        UserDefaultParameter: response.UserDefaultParameter,
        userDocumentHelpFilter: response.UserDocumentHelpFilter,
        ProcessReports: response.ProcessReports,
        ProcessCodeApplicationDataFormat: response.ProcessCodeApplicationDataFormat,
      };
      documentHelpConfig = response.DocumentHelp;

      //#region Configure User Settings on Grid

      const GridUserConfig: IGridUserConfig[] = response.GridUserConfig;

      screenConfig.GridConfig.map((x) => {

        const userGridConfig = GridUserConfig.filter((y) => y.gridno == x.Config.gridno);
        const companyFormat = JSON.parse(userGridConfig.find((x) => !x.username)?.gridconfig || "{}");
        const userFormat = JSON.parse(userGridConfig.find((x) => x.username)?.gridconfig || "{}");

        x.Config.companyGridCustomization = {
          columnHidden: (companyFormat.columnHidden || []) as string[]
        };

        x.Config.userGridCustomization = {
          columnHidden: (userFormat.columnHidden || []) as string[],
          columnIndex: (userFormat.columnIndex || []) as string[],
          columnWidth: (userFormat.columnWidth || []) as number[],
          frozenColumns: (userFormat.frozenColumns || 0) as number
        }

      });

      //#endregion

    }

    tabKey += "_" + tabIndex;

    yield put(setTabConfig({ tabActualKey: processCode, tabIndex, tabKey, url, name: screenConfig.TcodeConfiguration.processcodestxt, objectType: EObjectType.EntryForm, hidden: false }));
    yield put(setScreenObject({ ...screenConfig, objectId: tabKey }));

    if (transactionNo && model) {

      if (CurrentUserApproverPrid != 0)
        yield put(setUserHaveApprovalOption({ approverPRID: CurrentUserApproverPrid, status: CurrentUserApproverStatus }));

      yield put(setDocumentModelValue(model));
      yield put(setDimensionSetModel(dimensionData));
      yield put(setAttachmentObject({ newAttachments: Attachment }));
      yield put(changeScreenOptions({ isEntered: true }));
      yield put(changeObjectActivity({ objectId: tabKey, activity: EActivity.View }));

      yield handleGetCommentsByPrid({ originprocesscode: processCode, originprid: model.prid });

    }
    else if (screenConfig.TcodeConfiguration.savemode.isEqual("L")) {
      yield put(changeScreenOptions({ isEntered: true }));
      yield put(changeObjectActivity({ objectId: tabKey, activity: EActivity.Create }));
    }
    // if only create open screen
    else if (screenConfig.TcodeConfiguration.iscreate && !screenConfig.TcodeConfiguration.isedit && !screenConfig.TcodeConfiguration.isdelete && !screenConfig.TcodeConfiguration.isview && !screenConfig.TcodeConfiguration.isreverse) {
      yield put(changeObjectActivity({ objectId: tabKey, activity: EActivity.Create }));
    }
    else if (!transactionNo || !model) {
      const findView = screenConfig.userDocumentHelpFilter.find(x => x.isdefault);
      let view: IGridView | undefined = undefined;
      if (findView) {
        view = {
          filterPrid: findView.prid,
          filterApproval: findView.filterapproval,
          setting: JSON.parse(findView.filtersetting) as IGridView["setting"]
        }
      }

      if (payload.gridView) {
        view = JSON.parse(payload.gridView);
      }

      yield put(setHelpConfig({ config: documentHelpConfig, tabKey: tabKey, returnParams: {} as IReturnParam, helpOpenFor: EHelpOpenFor.Document, helpType: EHelpType.DocumentHelp, helpOpenIn: EHelpOpenIn.EntryForm, isSubGrid: false, multiSelect: false, view }));
    }

    if (transactionNo && !model) {
      yield put(setGlobalMessage({ message: "No Document Found", messageType: EMessageType.Error }));
    }

    yield put(push(url));

  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
    yield put(push("/")); // set the condition
  }
  finally {
    yield put(toggleLoader(false));
    yield put(setObjectLoaded());
  }
}

export function* handleChangeObjectActivity({ payload }: PayloadAction<{ objectId: string, activity: EActivity }>): any {
  const tabId = yield select((state: RootState) => state.Tab.activeTabKey);
  const activeObjectId = yield select((state: RootState) => state.EntryForm.activeObjectId)
  const processCode = yield select((state: RootState) => state.EntryForm.objects[activeObjectId].ScreenConfig.TcodeConfiguration.processcode);

  if (payload.activity == EActivity.Edit) {
    const prid = yield select((state: RootState) => state.EntryForm.objects[activeObjectId].model.prid);
    yield put(setTabMessage({ tabId, message: "Edit mode has been Enabled", messageType: EMessageType.Information }));
  }
  if (payload.activity == EActivity.Create) {
    yield put(setTabPrefixName("Create"));
  }
  else if (payload.activity == EActivity.Edit) {
    yield put(setTabPrefixName("Edit"));
  }
  else if (payload.activity == EActivity.View) {
    yield put(setTabPrefixName("View"));
  }

  if (payload.activity.isEqual(EActivity.View)) {
    const transactionNo = yield select((state: RootState) => state.EntryForm.objects[activeObjectId].model[state.EntryForm.objects[state.EntryForm.activeObjectId].ScreenConfig.TcodeConfiguration.dockey]);

    yield put(setTransactionNoOnActiveTab({ transactionno: transactionNo }));
  }

}

export function* handleGetDocumentByPrid({ payload }: PayloadAction<{ processcode: string, prid: number }>): any {

  try {

    yield put(toggleLoader(true));

    const response = yield call(requestGetDocumentByPrid, payload.processcode, payload.prid);

    if (!response)
      throw Error("Data Not Found");

    const objectId = yield select((state: RootState) => state.EntryForm.activeObjectId);
    const dockey = yield select((state: RootState) => state.EntryForm.objects[state.EntryForm.activeObjectId].ScreenConfig.TcodeConfiguration.dockey);

    yield put(setDocumentModelValue(response.Record));
    yield put(setDimensionSetModel(response.DimensionData));
    yield put(setAttachmentObject({ newAttachments: response.Attachment }));

    if (response.CurrentUserApproverPrid)
      yield put(setUserHaveApprovalOption({ approverPRID: response.CurrentUserApproverPrid, status: response.CurrentUserApproverStatus }));

    yield put(closeHelp({ objectId }));
    yield put(changeScreenOptions({ isEntered: true }));
    yield put(changeObjectActivity({ objectId, activity: EActivity.View }));

    yield handleGetCommentsByPrid({ originprocesscode: payload.processcode, originprid: payload.prid });



  }
  catch (error: any) {
    const tabId = yield select((state: RootState) => state.Tab.activeTabKey);
    yield put(setTabMessage({ tabId, error }));
  }
  finally {

    yield put(toggleLoader(false));

  }

}

export function* handleCopyDocumentByPrid({ payload }: PayloadAction<{ prid: number }>): any {

  try {
    const activeObjectId = yield select((state: RootState) => state.EntryForm.activeObjectId);
    const processcode = yield select((state: RootState) => state.EntryForm.objects[activeObjectId].ScreenConfig.TcodeConfiguration.processcode);

    yield put(toggleLoader(true));

    const response = yield call(requestGetDocumentByPrid, processcode, payload.prid);

    if (!response)
      throw Error("Data Not Found");

    const dockey = yield select((state: RootState) => state.EntryForm.objects[activeObjectId].ScreenConfig.TcodeConfiguration.dockey);

    var Record = response.Record;
    BaseMethods.clearDefaultColumns(Record);
    Record[dockey] = null;

    yield put(setDocumentModelValue(Record));
    yield put(setDimensionSetModel(response.DimensionData));

    yield put(closeHelp({ objectId: activeObjectId }));
    yield put(changeScreenOptions({ isEntered: true }));
    yield put(changeObjectActivity({ objectId: activeObjectId, activity: EActivity.Create }));
  }
  catch (error: any) {
    const tabId = yield select((state: RootState) => state.Tab.activeTabKey);
    yield put(setTabMessage({ tabId, error }));
  }
  finally {
    yield put(toggleLoader(false));
  }

}

export function* handleSaveDocument(action: PayloadAction<{ objectId: string, options: KeyValueObject, approvalrequestmessage: string }, string, { afterSave: () => void } | undefined>): any {
  try {
    const payload = action.payload;

    yield put(toggleLoader(true));

    const { processcode, model, dockey, dimension } = yield select((state: RootState) => ({
      processcode: state.EntryForm.objects[payload.objectId].ScreenConfig.TcodeConfiguration.processcode,
      model: state.EntryForm.objects[payload.objectId].model,
      dockey: state.EntryForm.objects[payload.objectId].ScreenConfig.TcodeConfiguration.dockey,
      dimension: state.EntryForm.objects[payload.objectId].dimension
    }));

    // let dimensionProper: KeyValueObject = {};

    // dimension.map((x: any) => {
    //   dimensionProper[x.dimensionSet] = x.dimensionValues;
    // })

    const response = yield call(requestSaveDocument, processcode, model, dimension, payload.options, payload.approvalrequestmessage);
    const responseModel = response.Record;

    yield put(setDocumentModelValue(responseModel));

    yield put(changeObjectActivity({ objectId: payload.objectId, activity: EActivity.View }));

    yield put(setTabMessage({ message: "Your Document has been Saved" }));

    if (response.DocumentReleasedException)
      yield put(setTabMessage({ messageType: EMessageType.Warning, error: response.DocumentReleasedException, title: "Error While Releasing this Document" }));

    yield put(uploadAttachment());

    if (action.meta?.afterSave)
      yield call(action.meta.afterSave);

  }
  catch (error: any) {
    type identityFunction<T> = (exception: IErrorMessage | any, handlerFunction: (action: T) => any, handlerParams: T) => any;
    yield call<identityFunction<typeof action>>(handleErrorMessage, error, handleSaveDocument, action);

  }
  finally {
    yield put(toggleLoader(false));
  }

}

export function* handleReverseDocument({ payload }: PayloadAction<{ processcode: string, model: any }>): any {

  try {
    const objectId = yield select((state: RootState) => state.EntryForm.activeObjectId);

    yield put(toggleLoader(true));

    const response = yield call(requestReverseDocument, payload.processcode, payload.model);

    yield put(changeObjectActivity({ objectId, activity: EActivity.Reverse }));

    yield put(setDocumentModelValue(response));

    yield put(toggleReverseModal(false));

    yield put(changeObjectActivity({ objectId, activity: EActivity.View }));

    yield put(setTabMessage({ message: "Your Document has been Reversed" }));
  }
  catch (error: any) {
    const tabId = yield select((state: RootState) => state.Tab.activeTabKey);
    yield put(setTabMessage({ tabId, error }));
  }
  finally {

    yield put(toggleLoader(false));

  }

}

export function* handleDeleteDocument({ payload }: PayloadAction<{ processcode: string, prid: number }>): any {

  try {

    yield put(toggleLoader(true));

    const response = yield call(requestDeleteDocument, payload.processcode, payload.prid);

    yield put(forceDisableActionButton({ disableAll: true }));

    yield put(setTabMessage({ message: "Your Document has been Deleted" }));
  }
  catch (error: any) {
    const tabId = yield select((state: RootState) => state.Tab.activeTabKey);
    yield put(setTabMessage({ tabId, error }));
  }
  finally {
    yield put(toggleLoader(false));
  }

}





export function* handlerGetDimensionValuesFromDimensionSetPromiseResolver({ payload, meta }: PayloadAction<{ dimensionSet: string[] }, string, { resolve: (value: unknown) => void, reject: (value: unknown) => void } | undefined>): any {

  const tabId = yield select((state: RootState) => state.Tab.activeTabKey);
  try {

    var dimensionValues = yield call(findDimension, payload.dimensionSet);

    if (meta?.resolve)
      yield call(meta.resolve, dimensionValues);

  }
  catch (error: any) {
    yield put(setTabMessage({ tabId, error }));
    if (meta?.reject)
      yield call(meta.reject, error);
  }
}

function* findDimension(dimensionSet: string[]): any {
  let dimensionValues: {
    dimensionset: string,
    values: KeyValueObject
  }[] = [];

  const requestDimensionSet = [];

  if (dimensionSet && dimensionSet.length) {
    const dimensionSetFind: IDimension[] = yield select((state: RootState) => state.EntryForm.objects[state.EntryForm.activeObjectId].dimension.filter(x => dimensionSet.indexOf(x.dimensionSet) != -1));

    requestDimensionSet.push(...dimensionSet.filter(ds => dimensionSetFind.findIndex(x => x.dimensionSet == ds) == -1));

    dimensionSetFind.map(x => {
      dimensionValues.push({
        dimensionset: x.dimensionSet,
        values: x.dimensionValues
      });
    });

    if (requestDimensionSet.length) {
      const response = yield call(requestGetDimensionSetData, requestDimensionSet);
      response.map((x: any) => {

        const values: KeyValueObject = {};

        Object.entries(x).map(([key, value]) => {
          // Removing key of dimensionset Column of Table
          if (!key.isEqual("dimensionset") && value) {
            values[key] = value;
          }
        });

        dimensionValues.push({
          dimensionset: x.dimensionset,
          values: values
        });

      });

    }
  }

  return dimensionValues;
}

export function* handlerFillDimensionFromDimensionSet({ payload }: PayloadAction<{ dimensionSet: string, relationKey?: string, dimensionSetFieldid?: string, dimensionSetting?: { gridno: string, rowIndex: number } }>): any {
  const tabId = yield select((state: RootState) => state.Tab.activeTabKey);
  try {

    var dimension = yield call(findDimension, [payload.dimensionSet]);

    let dimensionValues: KeyValueObject = {};
    if (dimension.length) {
      dimensionValues = dimension[0].values;
    }

    yield put(upsertDimension({ dimensionValues: dimensionValues, relationKey: payload.relationKey, dimensionSetFieldid: payload.dimensionSetFieldid, dimensionSetting: { gridno: payload.dimensionSetting?.gridno, rowIndex: payload.dimensionSetting?.rowIndex }, replace: true }));

  }
  catch (error: any) {
    yield put(setTabMessage({ tabId, error }));
  }
}

export function* handleGetAccountDimensions({ payload }: PayloadAction<{ accountno: string, accountFieldId: string, dimensionSetFieldid: string, isSubGrid: boolean, gridno?: string, rowIndex?: number }>): any {

  const tabId = yield select((state: RootState) => state.Tab.activeTabKey);
  try {

    yield put(toggleLoader(true));

    const response = yield call(requestGetAccountDimensions, payload.accountno);

    if (response.length) {

      const subGrid = yield select((state: RootState) => state.EntryForm.objects[state.EntryForm.activeObjectId].subGrid);
      let haveSubGrid = false;

      if (subGrid && subGrid.length)
        haveSubGrid = true;

      if (response[0].helpobjectno) {

        const dimensionValues = {
          accountno: payload.accountno,
        };

        yield put(getHelpConfig({
          helpObject: response[0].helpobjectno,
          helpWhere: "",
          multiSelect: false,
          returnParams: {
            fieldId: response[0].fieldid
          },
          isSubGrid: haveSubGrid,
          helpType: EHelpType.DimensionHelp,
          helpOpenIn: EHelpOpenIn.EntryForm,
          isAccountDimension: true,
          dimensionValues
        }));

      }

      yield put(setAccountDimensions({ fieldConfig: response, ...payload }));

    }
    else {
      yield put(setTabMessage({ tabId, message: `No Dimension is Configure of this Account (${payload.accountno})`, messageType: EMessageType.Warning }));
    }

  }
  catch (error: any) {
    yield put(setTabMessage({ tabId, error }));
  }
  finally {
    yield put(toggleLoader(false));
  }

}


export function* handleFieldHelpValidate({ payload }: PayloadAction<{ fieldId: string; fieldValue: string; helpObject: string; helpWhere: string; }>): any {

  const tabId = yield select((state: RootState) => state.Tab.activeTabKey);
  try {

    const getHelpParam: IGetHelp = {
      helpObject: payload.helpObject,
      helpWhere: payload.helpWhere,
      helpOpenIn: EHelpOpenIn.EntryForm,
      returnParams: {
        fieldId: payload.fieldId,
      },
    };

    yield put(queueStart({ queueName: payload.fieldId }));

    let findTags = yield call(handleGetScreenHelpRequiredTags, getHelpParam);
    let returnValue: IReturnValues[] = [];

    const response = yield call(requestFieldHelpValidate, payload.helpObject, payload.helpWhere, payload.fieldValue, findTags);

    if (response.data.length) {

      yield put(setFieldError({ errorType: EFieldErrorType.HelpDataNotFound, fieldId: payload.fieldId, isError: false }));

      const data = response.data[0];
      const singleReturnColumn = response.singleReturnColumn.trim();

      yield put(setFieldModelValue({ objectId: tabId, fieldId: payload.fieldId, value: data[singleReturnColumn] }));

      delete data[singleReturnColumn];

      returnValue = Object.entries(data).map(([key, value]: any) => ({
        fieldId: key,
        fieldValue: value
      }));

    }
    else {

      if (payload.fieldValue) {
        const findConfig = yield select((state: RootState) => state.EntryForm.objects[state.EntryForm.activeObjectId].ScreenConfig.Fields.FieldConfig.find(x => x.fieldid == payload.fieldId));
        if (!findConfig || !findConfig.helpnotmandatory) {
          yield put(setFieldError({ errorType: EFieldErrorType.HelpDataNotFound, fieldId: payload.fieldId, isError: true }));
          yield put(setTabMessage({ tabId, messageType: EMessageType.Warning, message: `${payload.fieldValue} this Data does't exists in ${payload.fieldId}` }));
        }
      }
      // else {
      //   yield put(setFieldError({ errorType: EFieldErrorType.EmptyFieldError, fieldId: payload.fieldId, isError: true }));
      //   yield put(setTabMessage({ tabId, messageType: EMessageType.Warning, message: `You can't make ${payload.fieldId} Field Empty` }));
      // }

      const multiReturnColumn = response.multiReturnColumn.trim() as string;
      const singleReturnColumn = response.singleReturnColumn.trim();

      const returnValueObject = multiReturnColumn.split(",").filter(x => x.trim() && x.trim() != singleReturnColumn);

      if (returnValueObject.length) {
        returnValue = returnValueObject.map(key => ({
          fieldId: key.trim(),
          fieldValue: ""
        }));
      }


    }

    if (returnValue.length) {

      let stopLoop = false;
      while (!stopLoop) {

        const exists = yield select((state: RootState) => state.Help[tabId]?.returnOption);
        if (!exists) {
          stopLoop = true;

          yield put(setHelpReturnValue({
            objectId: tabId, returnValue: [returnValue], returnParam: {
              fieldId: payload.fieldId,
            }
          }));
        }
      }

    }


  }
  catch (error: any) {
    yield put(setTabMessage({ tabId, error }));
  }
  finally {
    yield put(fieldHelpValidateEnd({ fieldId: payload.fieldId }));
    yield put(queueEnd({ queueName: payload.fieldId }));
  }

}

export function* handleGridHelpValidate({ payload }: PayloadAction<{ gridno: string; rowIndex: number; columnId: string; columnValue: string, isSubGrid: boolean, helpObject: string; helpWhere: string; }>): any {

  const tabId = yield select((state: RootState) => state.Tab.activeTabKey);
  const queueName = `${payload.gridno}-${payload.rowIndex}-${payload.rowIndex}`;
  try {

    const getHelpParam: IGetHelp = {
      helpObject: payload.helpObject,
      helpWhere: payload.helpWhere,
      isSubGrid: payload.isSubGrid,
      helpOpenIn: EHelpOpenIn.EntryForm,
      returnParams: {
        fieldId: payload.columnId,
        gridRowIndex: payload.rowIndex,
        gridno: payload.gridno
      },
    };


    yield put(queueStart({ queueName }));

    let findTags = yield call(handleGetScreenHelpRequiredTags, getHelpParam);
    let returnValue: IReturnValues[] = [];

    const response = yield call(requestFieldHelpValidate, payload.helpObject, payload.helpWhere, payload.columnValue, findTags);

    if (response.data.length) {

      yield put(setGridRowError({ errorType: EFieldErrorType.HelpDataNotFound, gridno: payload.gridno, rowIndex: payload.rowIndex, columnId: payload.columnId, isError: false }));

      const data = response.data[0];
      const singleReturnColumn = response.singleReturnColumn.trim();

      if (payload.isSubGrid) {
        yield put(setSubGridCellData({ gridno: payload.gridno, rowIndex: payload.rowIndex, columnId: payload.columnId, value: data[singleReturnColumn] }));
      }
      else {
        yield put(setGridCellData({ gridno: payload.gridno, rowIndex: payload.rowIndex, columnId: payload.columnId, value: data[singleReturnColumn] }));
      }

      delete data[singleReturnColumn];

      returnValue = Object.entries(data).map(([key, value]: any) => ({
        fieldId: key,
        fieldValue: value
      }));

    }
    else {

      if (payload.columnValue) {
        const findConfig = yield select((state: RootState) => state.EntryForm.objects[state.EntryForm.activeObjectId].ScreenConfig.Grids.GridConfig.find(x => x.Config.gridno == payload.gridno)?.Header.find(x => x.fieldid == payload.columnId));
        if (!findConfig || !findConfig.helpnotmandatory) {
          yield put(setGridRowError({ errorType: EFieldErrorType.HelpDataNotFound, gridno: payload.gridno, rowIndex: payload.rowIndex, columnId: payload.columnId, isError: true }));
          yield put(setTabMessage({ tabId, messageType: EMessageType.Warning, message: `${payload.columnValue} this Data does't exists in ${payload.columnId}` }));
        }
      }
      // else {
      //   yield put(setGridRowError({ errorType: EFieldErrorType.EmptyFieldError, gridno: payload.gridno, rowIndex: payload.rowIndex, columnId: payload.columnId, isError: true }));
      //   yield put(setTabMessage({ tabId, messageType: EMessageType.Warning, message: `You can't make ${payload.columnId} Field Empty` }));
      // }

      const multiReturnColumn = response.multiReturnColumn.trim() as string;
      const singleReturnColumn = response.singleReturnColumn.trim();

      returnValue = multiReturnColumn.split(",").filter(x => x.trim() && x.trim() != singleReturnColumn).map(key => ({
        fieldId: key.trim(),
        fieldValue: ""
      }));

    }

    if (returnValue.length) {

      let stopLoop = false;
      while (!stopLoop) {

        const exists = yield select((state: RootState) => state.Help[tabId]?.returnOption);
        if (!exists) {
          stopLoop = true;


          yield put(setHelpReturnValue({ objectId: tabId, returnValue: [returnValue], returnParam: getHelpParam.returnParams }));
        }
      }

    }


  }
  catch (error: any) {
    yield put(setTabMessage({ tabId, error }));
  }
  finally {
    yield put(gridHelpValidateEnd({ gridno: payload.gridno, rowIndex: payload.rowIndex, columnId: payload.columnId }));
    yield put(queueEnd({ queueName }));
  }

}

export function* handleChangeActivityToCreate({ payload }: PayloadAction<{ objectId: string }>) {

  // Sequence matter
  // yield put(forceDisableActionButton({ disableAll: false }));
  yield put(setTransactionNoOnActiveTab({ transactionno: "" }));
  yield put(resetScreenOptions());
  yield put(changeObjectActivity({ objectId: payload.objectId, activity: EActivity.Create }));
  yield put(setDocumentModelValue({}));

}

export function* handleLoadDocumentHelp({ payload }: PayloadAction<string>): any {

  const objectId = payload;
  try {
    yield put(toggleLoader(true));

    const tabActualKey = yield select((state: RootState) => state.Tab.tabs.find(x => x.tabKey == objectId)?.tabActualKey);

    const dockeyConfig = yield select((state: RootState) => {
      const dockey = state.EntryForm.objects[objectId].ScreenConfig.TcodeConfiguration.dockey;
      return state.EntryForm.objects[objectId].ScreenConfig.Fields.FieldConfig.find(x => x.fieldid == dockey && x.activityno == "");
    });

    // const returnParams = {
    //   fieldId: ""
    // }

    const filter = { filterApproval: false };
    const userDocumentHelpFilter = (yield select((state: RootState) => state.EntryForm.objects[objectId].otherState.userDocumentHelpFilter)) as IOtherState["userDocumentHelpFilter"];
    const viewFind = userDocumentHelpFilter.find(x => x.isdefault == true);

    if (viewFind != null)
      filter.filterApproval = viewFind.filterapproval;

    let view: IGridView | undefined = undefined;
    if (viewFind) {
      view = {
        filterPrid: viewFind.prid,
        filterApproval: viewFind.filterapproval,
        setting: JSON.parse(viewFind.filtersetting) as IGridView["setting"]
      }
    }

    // Sequence matter
    const documentHelpConfig = yield call(requestGetDocumentHelp, dockeyConfig.helpobjectno, dockeyConfig.helpwhere, tabActualKey, filter);

    yield put(setHelpConfig({ config: documentHelpConfig, tabKey: objectId, returnParams: {} as IReturnParam, helpOpenFor: EHelpOpenFor.Document, helpType: EHelpType.DocumentHelp, helpOpenIn: EHelpOpenIn.EntryForm, isSubGrid: false, multiSelect: false, view }));
    // yield put(forceDisableActionButton({ disableAll: false }));
    yield put(setTransactionNoOnActiveTab({ transactionno: "" }));
    yield put(changeObjectActivity({ objectId, activity: EActivity.None }));
    yield put(resetScreenOptions());
    yield put(setDocumentModelValue({}));

  }
  catch (error: any) {
    yield put(setTabMessage({ tabId: objectId, error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

//#region comment 
export function* handleAddComment({ payload }: PayloadAction<{ originprid: string, originprocesscode: string, commentstxt: string }>): any {

  try {

    yield put(toggleLoader(true));

    yield call(requestAddComment, payload.originprid, payload.originprocesscode, payload.commentstxt);


  }
  catch (error: any) {
  }
  finally {

    yield put(toggleLoader(false));

  }
}

export function* handleGetCommentsByPrid(payload: { originprocesscode: string, originprid: number }): any {
  try {

    // yield put(toggleLoader(true));
    const response = yield call(requestGetCommentsByPrid, payload.originprocesscode, payload.originprid);

    yield put(setComments(response));

  }
  catch (error: any) {
  }
  finally {

    // yield put(toggleLoader(false));

  }
}

//#endregion

export function* handlerSaveUserDocumentHelpFilter({ payload }: KeyValueObject): any {

  const tabId = yield select((state: RootState) => state.Tab.activeTabKey);
  try {
    yield put(toggleLoader(true));

    const response = yield call(requestSaveUserDocumentHelpFilter, payload);

    yield put(pushUserDocumentHelpFilter(response));
    yield put(setGridView({ objectId: tabId, filterPrid: response[0].prid }));

  }
  catch (error: any) {
    yield put(setTabMessage({ tabId, error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

export function* handlerRemoveUserDocumentHelp({ payload }: PayloadAction<{ prid: number }>): any {

  const tabId = yield select((state: RootState) => state.Tab.activeTabKey);
  try {
    yield put(toggleLoader(true));

    yield call(requestRemoveUserDocumentHelp, payload.prid);
  }
  catch (error: any) {
    yield put(setTabMessage({ tabId, error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

export function* handlerSetViewDefaultUserDocumentHelp({ payload }: PayloadAction<{ prid: number, processcode: string, isDefault: boolean }>): any {

  const tabId = yield select((state: RootState) => state.Tab.activeTabKey);
  try {
    yield put(toggleLoader(true));

    yield call(requestSetViewDefaultUserDocumentHelp, payload.prid, payload.processcode, payload.isDefault);
  }
  catch (error: any) {
    yield put(setTabMessage({ tabId, error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}


//#region Attachments

export function* handlerUploadAttachment(): any {

  const Attachment: IEntryFromObject<{}>["Attachment"] = yield select((state: RootState) => state.EntryForm.objects[state.EntryForm.activeObjectId].Attachment);
  const { processcode, dockeyValue, documentPRID } = yield select((state: RootState) => {
    const object = state.EntryForm.objects[state.EntryForm.activeObjectId];

    return {
      processcode: object.ScreenConfig.TcodeConfiguration.processcode,
      dockeyValue: object.model[object.ScreenConfig.TcodeConfiguration.dockey],
      documentPRID: object.model.prid
    }
  });

  if (Attachment.removeAttachmentIds.length) {
    yield call(requestRemoveAttachment, Attachment.removeAttachmentIds);
    yield put(clearAttachmentRemoveIds());
  }

  const newAttachments = Attachment.attachments.filter(x => x.isNew);

  if (newAttachments.length) {
    yield put(setNewUploadingAttachments());
    yield put(toggleAttachmentUploadingModal({ open: true }));

    yield all(newAttachments.map((x, i) => call(handlerUploadAttachmentProgress, x, i, dockeyValue, documentPRID, processcode)));
  }

}

function* handlerUploadAttachmentProgress(att: IAttachment, i: number, dockeyValue: string, documentPRID: string, processcode: string): any {
  try {

    const file = yield call(requestGetAttachmentFileObject, att.fileObject as string, att.filename);

    var formData = new FormData();
    formData.append("file", file);
    formData.append("size", att.filesize);
    formData.append("linestxt", att.linestxt);
    formData.append("originno", dockeyValue);
    formData.append("originprid", documentPRID);
    formData.append("originprocesscode", processcode);
    formData.append("gridno", att.gridno);
    formData.append("lineid", att.lineid.toString());

    let emit: (input: unknown) => void;
    const chan = eventChannel(emitter => {
      emit = emitter;
      return () => { }
    });

    yield fork(handlerUploadAttachmentProgressWatcher, chan);

    const onUploadProgress = (progressEvent: any) => {
      let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

      emit({ attachmentId: att.prid, progress: percentCompleted });
      if (percentCompleted == 100)
        emit(END);
    }

    const uploadedData = yield call(requestSaveAttachment, formData, onUploadProgress);

    const updatedObject = uploadedData[0] as IAttachment;

    yield put(updateAttachmentObject({ attachmentId: att.prid, updatedObject }));
  }
  catch (ex: any) {

    yield put(setAttachmentError({ attachmentId: att.prid, errorMessage: ex?.response?.data?.errMessage || ex.message }));

    console.error(ex);
  }
}

function* handlerUploadAttachmentProgressWatcher(chan: any): any {
  while (true) {
    const data = yield take(chan);
    yield put(setAttachmentProgress({ attachmentId: data.attachmentId, progress: data.progress }));
  }
}

//#endregion

interface IErrorMessage {
  flexobject: string,
  lineid: number,
  Values: Array<any>,
  Number: number,
  ErrorSource: string,
  errMessage: string,
  Field: Array<string>,
  Module: string,
  Type: string,
  Method: string,
  LineNo: number,
  File: string,
  Library: string,
  Class: string,
  confirmationParamKey: string
}

export function* handleErrorMessage<T>(exceptionParam: any, handlerFunction: (action: T) => any, handlerParams: T): any {

  const exception = exceptionParam.response?.data;

  const tabId = yield select((state: RootState) => state.Tab.activeTabKey);

  try {
    var message = exception.errMessage;

    if (!exception.Type) {
      yield put(setTabMessage({ tabId, error: exceptionParam }));
    }
    else {
      if (!exception.flexobject) {
        for (let index = 0; index < (exception.Field || []).length; index++) {
          const activeObjectId = yield select((state: RootState) => state.EntryForm.activeObjectId);
          const fieldLabel = yield select((state: RootState) => state.EntryForm.objects[activeObjectId].ScreenConfig.Fields.FieldConfig.find(x => x.fieldid == exception.Field[index])?.fieldlabel);
          message = message.replace(`{${index}}`, fieldLabel)
        }

        for (let index = 0; index < (exception.Values || []).length; index++) {
          message = message.replace(`{V${index}}`, exception.Values[index])
        }
      }
      else {
        for (let index = 0; index < (exception.Field || []).length; index++) {
          const activeObjectId = yield select((state: RootState) => state.EntryForm.activeObjectId);
          const fieldLabel = yield select((state: RootState) => state.EntryForm.objects[activeObjectId].gridOptions[exception.flexobject][exception.Field[index]].columnLabel);
          message = message.replace(`{${index}}`, fieldLabel)
        }

        for (let index = 0; index < (exception.Values || []).length; index++) {
          message = message.replace(`{V${index}}`, exception.Values[index])
        }
      }


      if (exception.Type.isEqual("Confirmation")) {
        yield put(toggleLoader(false));
        const messageResponse = yield call(openConfirmation, { text: message, title: "Confirmation" });
        if (messageResponse.cancel)
          return;
        else {
          const params = {
            ...handlerParams,
            payload: {
              ...(handlerParams as any).payload,
              options: {
                ...(handlerParams as any).payload?.options,
                confirmationParam: JSON.stringify({
                  ...(handlerParams as any).payload?.options?.confirmationParam,
                  [exception.confirmationParamKey]: true
                })
              }
            }
          }

          yield call<typeof handlerFunction>(handlerFunction, params);
        }
      }
      else {
        yield put(setTabMessage({ tabId, messageType: EMessageType.Error, message: message }));
      }

    }
  } catch (error) {
    console.error(error);
    yield put(setTabMessage({ tabId, messageType: EMessageType.Error, message: "An Error occurred while processing your request, Please contact to the administrator" }));
  }

}

export function* handleShareDocument({ payload }: PayloadAction<IShareDocument>): any {
  try {

    yield requestShareDocument(payload);

  } catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(setGlobalMessage({ message: 'Document Successfully Shared', messageType: EMessageType.Success }));
  }
}