import { KeyValueObject } from "@/UI/Interfaces/IBaseUI";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import produce from "immer";
import { ComponentType, LazyExoticComponent } from "react";

interface IColumns {
  filter: boolean;
  objectno: string;
  widgetobjecttypeno: string;
  helpobjectno: string;
}

export interface IHelpHeader {
  ColumnText: string;
  ColumnID: string;
  controltype: string;
  ColumnWidth: number;
  ishidden: boolean;
  hyperlinktransactioncode: string;
}

export interface IWidget {
  [key: string]: {
    objectcontrollerno: string;
    widgetlabel: string;
    widgettype: string;
    widgetcontroltype: string;
    layout: string;
    widgetno: string;
    isinsight: boolean;
    widgeticon: string;
    widgetcolor: string;
    isdashboard: boolean;
    widgetgroupno: string;
    widgetgrouplabel: string;
    widgetprocesscode: string;
    data: any;
    columns: Array<IColumns>;
    Header: Array<IHelpHeader>;
  }[]
}

export interface ILayout {
  layout: string;
  insightarea: string;
  widgetgroupno: string;
}

interface IDashboard {
  widgets: IWidget;
  layout: Array<ILayout>;
}

const InitialState: IDashboard = {
  widgets: {},
  layout: []
}

const DashboardSlice = createSlice({
  name: "dashboard",
  initialState: InitialState,
  reducers: {
    saveUserDashboardLayout(state, action: PayloadAction<{ widgetgroupno: string, layout: JSON, insightarea: string }>) { },
    getUserInsights() { },
    setUserInsightsDashboard(state, action: PayloadAction<{ widgetList: IWidget, layout: Array<ILayout> }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        draftState.widgets = payload.widgetList;
        draftState.layout = payload.layout;
      });

    }
  },
});

export const { getUserInsights, setUserInsightsDashboard, saveUserDashboardLayout } = DashboardSlice.actions;

export default DashboardSlice.reducer;
