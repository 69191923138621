
import Swal, { SweetAlertIcon, SweetAlertOptions } from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

type TAlert = {
  title: string;
}

type TAlertReturn = {
  confirm: boolean;
  cancel: boolean;
}

export const openErrorConfirmation = async (params: { title: string, text: string, icon?: SweetAlertIcon, confirmButtonText?: string }): Promise<TAlertReturn> => {

  const res = await MySwal.fire({
    title: params.title,
    text: params.text,
    showCancelButton: true,
    icon: params.icon || 'error',
    confirmButtonColor: '#d33',
    cancelButtonColor: '#3085d6',
    confirmButtonText: params.confirmButtonText || 'Yes, delete it!'
  });

  return {
    confirm: res.isConfirmed,
    cancel: res.isDismissed
  };

}

export const openConfirmation = async (params: { title: string, text: string, icon?: SweetAlertIcon }): Promise<TAlertReturn> => {

  const res = await MySwal.fire({
    title: params.title,
    text: params.text,
    showCancelButton: true,
    cancelButtonColor: '#919191',
    icon: params.icon || 'question',
  });

  return {
    confirm: res.isConfirmed,
    cancel: res.isDismissed
  };

}

export const showValidationMessage = (message: string) => {
  return MySwal.showValidationMessage(message);
}

export const openInputAlert = async <T extends unknown = string>(params: { title: string | HTMLElement | JQuery, icon?: SweetAlertIcon, extraOption?: SweetAlertOptions }): Promise<TAlertReturn & { value: T }> => {

  const res = await MySwal.fire<T>({
    title: params.title,
    input: 'text',
    showCancelButton: true,
    cancelButtonColor: '#919191',
    ...(params.extraOption || {})
    // icon: params.icon || 'question',
  });

  return {
    confirm: res.isConfirmed,
    cancel: res.isDismissed,
    value: res.value as T
  };

}

export const openAlert = (params: { title: string, text?: string, icon?: SweetAlertIcon, html?: string }) => {

  if (params.html) {
    return MySwal.fire({
      html: params.html,
      title: params.title,
      icon: params.icon || 'info',
    });
  }
  else {
    return MySwal.fire({
      title: params.title,
      text: params.text || '',
      icon: params.icon || 'info',
    });
  }

}
