import { Component } from 'react'
import { connect } from 'react-redux';

import { IUserForgotPassword, forgotUserPassword } from '@/Redux/Ducks/userSlice';
import { setGlobalMessage } from '@/Redux/Ducks/messageSlice';
import { AlignUIRender } from './indexUI';
import { RootState } from '@/Redux/configureStore';

class ForgotPassword extends Component<any> {
  render = () => AlignUIRender(this);
  constructor(props: any) {
    super(props);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.updateFieldState = this.updateFieldState.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.navigateToLogin = this.navigateToLogin.bind(this);
  }
  state = {
    email: "",
    clientno: ""
  }

  forgotPassword() {
    if (this.state.email && this.state.clientno)
      this.props.forgotUserPassword({ email: this.state.email, clientno: this.state.clientno });
    else
      this.props.setGlobalMessage({ error: { message: "Email & Company Code can not be empty" } });
  }
  handleKeyDown(e: any) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.forgotPassword();
    }
  }

  navigateToLogin() {
    const { history } = this.props;
    history.push('/login')
  }

  updateFieldState(fieldId: string, fieldValue: string) {
    this.setState({ [fieldId]: fieldValue });
  }

}

// for type
export { ForgotPassword };

const mapStateToProps = (state: RootState, ownProps: any) => ({
  clientLanguage: state.User.clientLanguage
})

export default connect(mapStateToProps, {
  forgotUserPassword,
  setGlobalMessage
})(ForgotPassword);


//#region Components Props

declare type ComponentProps = DispatchFromProps;

interface DispatchFromProps {
  forgotPassword: (object: IUserForgotPassword) => void;
}

//#endregion
