import { Icon } from "AlignComponents";
import { Form, Offcanvas } from "react-bootstrap";
import { BiData } from "react-icons/bi";
import { BsFileEarmarkCheck } from "react-icons/bs";
import { HiPencilAlt } from "react-icons/hi";
import { MdChevronRight } from "react-icons/md";

interface IProps {
  menuOpen: boolean,
  closeMenu: () => void,
  routeToWorkspace: (workspaceNo: number) => void,
  filterUserWorkspace: any,
  setWorkspaceSearch: (value: string) => void,
}

export const AlignUIRender = ({ filterUserWorkspace, menuOpen, closeMenu, routeToWorkspace, setWorkspaceSearch }: IProps) => (
  <Offcanvas show={menuOpen} onHide={closeMenu} backdropClassName="backdrop" className="menu-workspace">
    <Offcanvas.Header className="border-bottom">
      <Offcanvas.Title>
        <Form.Control id="module-search" type="text" placeholder="Search" onChange={(e: any) => setWorkspaceSearch(e.target.value)} />
      </Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body>
      <ul className="objects show my-2 ms-1">
        {
          filterUserWorkspace.map((x: any, i: number) => (
            <li role="button" key={i} onClick={() => { routeToWorkspace(x.workspaceno) }} className="object">
              {x.workspacestxt}
            </li>
          ))
        }
      </ul>
    </Offcanvas.Body>
  </Offcanvas >
);
