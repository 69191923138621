import { useEffect, useState } from "react";
import { AlignUIRender } from "./indexUI";
import { Icon } from "@/Assets/Icons"
import { useHistory } from "react-router";
import { EMenu, setActiveTab } from "@/Redux/Ducks/tabSlice";
import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import useKeyboardShortcut from "use-keyboard-shortcut";
import { requestGetAllTodayActivity } from "@/Redux/Sagas/Requests/home";
import { toggleLoader } from "@/Redux/Ducks/loaderSlice";
import { getScreenConfig } from "@/Redux/Ducks/entryFormSlice";

const Aside = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const user: any = useAppSelector((state) => state.User.user.name);
  // const activeMenu = useAppSelector((state) => state.Tab.activeSideBarKey);
  const [activeMenu, setActiveMenu] = useState(EMenu.None);
  const [userTodayActivity, setUserTodayActivity] = useState([]);
  const [activityModalState, toggleActivityModal] = useState<boolean>(false);

  //#region lifecycle methods
  useEffect(() => {
    setCurrentLocation();
  }, [location])
  //#endregion

  const setCurrentLocation = () => {
    switch (location.pathname) {
      case "/":
        setActiveMenu(EMenu.Home);
        break;
      case "/Dashboard":
        setActiveMenu(EMenu.Dashboard);
        break;
      default:
        setActiveMenu(EMenu.None);
        break;
    }
  }

  //#region properties

  // routing method
  const routeToSpecific = (screenCode: string) => {
    toggleMenu(EMenu.None);
    dispatch(setActiveTab(""));
    history.push(`/${screenCode}`);
  };

  // menu methods
  const toggleMenu = (applicationobjecttype: EMenu) => {
    if (!activeMenu.isEqual(applicationobjecttype)) {
      // dispatch(setActiveSideBarKey(applicationobjecttype))
      setActiveMenu(applicationobjecttype);

      if (applicationobjecttype.isEqual(EMenu.Home)) {
        routeToSpecific("");
      }
      else if (applicationobjecttype.isEqual(EMenu.Dashboard)) {
        routeToSpecific("Dashboard");
      }
      else if (applicationobjecttype.isEqual(EMenu.RecentActivity)) {
        handleActivityOpen();
      }
    }
    else {
      setCurrentLocation();
    }
  }

  const closeMenu = () => {
    setCurrentLocation();
    // toggleMenu(activeMenu)
  }

  //#endregion

  const handleActivityOpen = async () => {
    try {
      dispatch(toggleLoader(true));
      const res = await requestGetAllTodayActivity();

      toggleActivityModal(true);
      setUserTodayActivity(res);
    }
    catch (ex) {
      console.error("Error while opening openAllActivity", ex);
    }
    finally {
      dispatch(toggleLoader(false));
    }
  }

  const routeToScreen = (screenCode: string, transactionNo: string = "") => {
    dispatch(getScreenConfig({ processCode: screenCode, transactionNo: transactionNo }));
  };

  return AlignUIRender({ Icon, toggleMenu, activeMenu, closeMenu, activityModalState, toggleActivityModal, userTodayActivity, user, routeToScreen });
};

export default Aside;

declare type StateFromProps = {
  history: any;
};

declare type ComponentProps = StateFromProps;