import { useCallback, useRef } from 'react'
import { useDrag, useDrop } from "react-dnd";
import { MdChevronRight } from 'react-icons/md';
import { IWorkspaceWidget } from '.';
import { MenuContent } from './MenuContent'
import update from 'immutability-helper';
import { IUserWorkspaceObject } from '@/Redux/Ducks/workspaceSlice';

interface IProps {
  menus: Array<IWorkspaceWidget>,
  group: { groupName: string, groupIndex: number },
  moveMenuHeader: (dragIndex: number, hoverIndex: number) => void,
  index: number,
  toggleSubMenu: (e: any) => void,
  routeToScreen: (screenCode: string, gridView: string, objectType: string) => void,
  openUpdateWorkspace: (props: {
    widgetNo: string;
    widgetName: string;
    widgetType: string;
    icon?: string | undefined;
    widgetGroup?: string | undefined;
  }) => void,
  deleteWorkspace: (widgetNo: string) => void,
  updateIndex: (objects: any, widgetType: string) => void,
  updateGroup: (dragCard: IUserWorkspaceObject, hoverCard: IUserWorkspaceObject) => void
}
export const MenuHeader = ({ menus, group, index, moveMenuHeader, toggleSubMenu, routeToScreen, openUpdateWorkspace, deleteWorkspace, updateIndex, updateGroup }: IProps) => {
  const ref = useRef<any>(null);

  const [, drop] = useDrop({
    accept: 'MenuHeader',
    hover(item: { id: number, index: number }, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset: any = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY: any = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveMenuHeader(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'MenuHeader',
    item: () => {
      return { id: group.groupIndex, index: index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref));

  const moveMenuContent = useCallback(
    (drag: { dragIndex: number, dragId: string }, hover: { hoverIndex: number, hoverId: string }) => {
      const dragCard: any = menus.find(x => x.widgetNo == drag.dragId);
      const hoverCard: any = menus.find(x => x.widgetNo == hover.hoverId);

      if (dragCard.widgetGroup != hoverCard.widgetGroup) {
        updateGroup(dragCard, hoverCard);
      }
      else {
        updateIndex(
          update(menus, {
            $splice: [
              [drag.dragIndex, 1],
              [hover.hoverIndex, 0, dragCard],
            ],
          }), 'Menu');
      }

    },
    [menus]);

  return (
    <div>
      <li ref={ref} role="button" onClick={toggleSubMenu} key={group.groupName} className="menu-group-heading fw-bold">
        <MdChevronRight className="me-2" color="#575757" size={16} />
        {group.groupName}
      </li>
      <ul className="links mt-2 ms-3">
        {
          menus.filter((x: IUserWorkspaceObject) => x.widgetGroup == group.groupName).map((menu, idx) => (
            <MenuContent menu={menu} index={idx} moveMenuContent={moveMenuContent} deleteWorkspace={deleteWorkspace} openUpdateWorkspace={openUpdateWorkspace} routeToScreen={routeToScreen} groupName={group.groupName} menus={menus} />
          ))
        }
      </ul>
    </div>
  )
}
