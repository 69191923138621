import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/Hook/hooks';
import { IFieldProps } from '@/UI/Interfaces/IComponent';
import { ChromePicker } from 'react-color'

const Field = ({ config, fieldValueChange, fieldFocusOut, uniqueKey, enable, value, validateMandatory, hideLabel }: IFieldProps) => {
  const { fieldid } = config;
  const dispatch = useAppDispatch();
  const [currentValue, setCurrentValue] = useState<string>("");

  const [fieldIsInEditing, setFieldIsInEditing] = useState<boolean>(false);

  const objectId = useAppSelector((x) => x.EntryForm.activeObjectId);

  useEffect(() => {
    if (value === undefined) {
      callBaseMethod("");
    }
  }, [value]);

  const callBaseMethod = (value: any) => {
    fieldValueChange(fieldid, value);
  }

  const change = (color: any) => {
    setCurrentValue(color.hex);
  }

  const blur = (color: any) => {
    if (color.hex != value)
      callBaseMethod(color.hex);

    setCurrentValue(color.hex);
    setFieldIsInEditing(false);

    fieldFocusOut(fieldid, color.hex);
  }

  if (!config) return <></>;
  const id = objectId + (uniqueKey ? `-${uniqueKey}` : "") + "-" + fieldid;

  return (
    <>
      {
        !hideLabel ?
          <label id={"lb-" + id} htmlFor={"field-" + id} >
            {config.fieldlabel}
            {validateMandatory ? (<span>*</span>) : null}
          </label>
          : null
      }
      {
        enable ? (
          <ChromePicker color={currentValue} onChange={change} onChangeComplete={blur} />
        ) : (
          <div className="color-viewer">
            <div className="color" style={{ backgroundColor: currentValue }} />
          </div>
        )
      }
    </>
  )

}
export default Field;
