import { useEffect, useState } from "react";
import { AlignUIRender } from "./indexUI";
import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import { getScreenConfig } from "@/Redux/Ducks/entryFormSlice";
import { getReportConfig } from "@/Redux/Ducks/reportExplorerSlice";
import { getDataAnalyticsConfig } from "@/Redux/Ducks/dataAnalyticsSlice";
import { EMenu } from "@/Redux/Ducks/tabSlice";

const FavoriteMenu = ({ closeMenu, activeMenu }: ComponentProps) => {
  const dispatch = useAppDispatch();
  const [menuOpen, toggleMenu] = useState<boolean>(false);
  const [favoriteSearch, setFavoriteSearch] = useState<string>("");
  const userFavorites = useAppSelector(state => state.User.userFavorites);

  useEffect(() => {
    if (activeMenu == EMenu.Favorite) {
      toggleMenu(true);
    }
    else {
      toggleMenu(false);
    }
  }, [activeMenu]);

  const routeToScreen = (screenCode: string, applicationobjecttype: string) => {
    if (applicationobjecttype == "E") {
      dispatch(getScreenConfig({ processCode: screenCode }));
    }
    else if (applicationobjecttype == "R") {
      dispatch(getReportConfig({ reportObjectno: screenCode }));
    }
    else if (applicationobjecttype == "I") {
      dispatch(getDataAnalyticsConfig({ dataAnalyticsno: screenCode }));
    }

    closeMenu();
  };

  const filterUserFavorites = userFavorites.filter(x => {
    const searchSplit = favoriteSearch.split(" ");
    if (!searchSplit || searchSplit.filter(y => x.objectcontrollerstxt.toLowerCase().includes(y.toLowerCase())).length == searchSplit.length)
      return true;
  });

  return AlignUIRender({ menuOpen, filterUserFavorites, routeToScreen, closeMenu, setFavoriteSearch });
};

export default FavoriteMenu;

declare type StateFromProps = {
  closeMenu: () => void,
  activeMenu: EMenu
};

declare type ComponentProps = StateFromProps;