import { call, put, select } from "redux-saga/effects";
import { setGlobalMessage } from "@/Redux/Ducks/messageSlice";
import { requestGetDataAnalyticsObjectMenu, requestLoadDataAnalyticsDetail, requestDataAnalyticsConfig, requestStoreLayout, requestUpdateLayout, requestDeleteLayout } from "@/Redux/Sagas/Requests/dataAnalytics";
import { toggleLoader } from "@/Redux/Ducks/loaderSlice";
import { setDataAnalyticsObjects, setDataAnalyticsConfig, setDataAnalyticsDetail, IGridView, loadDataAnalytics, setLayout, IDataAnalyticsLayout } from "@/Redux/Ducks/dataAnalyticsSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { EObjectType, setTabConfig } from "@/Redux/Ducks/tabSlice";
import { RootState } from "@/Redux/configureStore";
import { ICriteriaFieldValue } from "@/UI/Interfaces/IFieldConfig";
import { KeyValueObject } from "@/UI/Interfaces/IBaseUI";
import { requestDeleteWorkspaceObject } from "../Requests/workspace";
import { EMessageType } from "@/UI/Interfaces/IMessage";

export function* handleGetDataAnalyticsMenu(): any {

  try {
    yield put(toggleLoader(true));

    const response = yield call(requestGetDataAnalyticsObjectMenu);

    yield put(setDataAnalyticsObjects({ dataAnalyticsObjects: response.UserModules }));

  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }

}

export function* handleGetDataAnalyticsConfig({ payload }: PayloadAction<{ dataAnalyticsno: string, gridView?: IGridView, modelValue?: KeyValueObject<ICriteriaFieldValue> }>): any {

  try {
    yield put(toggleLoader(true));

    const dataAnalyticsObjectno = payload.dataAnalyticsno;

    let dataAnalyticsConfig: any;
    let tabKey = dataAnalyticsObjectno;
    let tabIndex = 1;
    let url = "/IE/" + dataAnalyticsObjectno;

    const tabs = yield select((state: RootState) => state.Tab.tabs.filter(x => x.tabActualKey == dataAnalyticsObjectno));

    if (tabs.length) {
      const object = yield select((state: RootState) => state.DataAnalytics.dataAnalyticsDetailObject[tabs[0].tabKey]);

      dataAnalyticsConfig = {
        criteria: object.criteria,
        FICriteria: object.FICriteria,
        objectConfiguration: object.objectConfiguration,
        layouts: object.layouts
      };

      if (payload.gridView) {
        dataAnalyticsConfig.gridView = payload.gridView;
      }

      if (payload.modelValue) {
        dataAnalyticsConfig.modelValue = payload.modelValue;
      }

      tabIndex = tabs[tabs.length - 1].index + 1;

      url += "_" + tabIndex;

    }
    else {

      const response = yield call(requestDataAnalyticsConfig, dataAnalyticsObjectno);
      dataAnalyticsConfig = {
        criteria: response.criteria,
        FICriteria: response.FICriteria,
        objectConfiguration: response.objectConfiguration,
        layouts: response.layouts
      };

      if (payload.gridView) {
        dataAnalyticsConfig.gridView = payload.gridView;
      }

      if (payload.modelValue) {
        dataAnalyticsConfig.modelValue = payload.modelValue;
      }

    }

    tabKey += "_" + tabIndex;

    yield put(setTabConfig({ tabActualKey: dataAnalyticsObjectno, tabIndex, tabKey, url, name: dataAnalyticsConfig.objectConfiguration.processcodestxt, objectType: EObjectType.DataAnalytics, hidden: false }));
    yield put(setDataAnalyticsConfig({ ...dataAnalyticsConfig, objectId: tabKey }));

    if (payload.gridView) {
      yield put(loadDataAnalytics({ dataAnalyticsno: payload.dataAnalyticsno, onSuccess: () => { } }));
    }

    yield put(push(url));

  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }

}

export function* handleLoadDataAnalytics({ payload }: PayloadAction<{ dataAnalyticsno: string, onSuccess: () => void }>): any {
  try {
    yield put(toggleLoader(true));

    const object = yield select((state: RootState) => state.DataAnalytics.dataAnalyticsDetailObject[state.DataAnalytics.activeDataAnalyticsObjectno]);
    const modelValue = object.modelValue;

    const response = yield call(requestLoadDataAnalyticsDetail, modelValue, payload.dataAnalyticsno);

    yield put(setDataAnalyticsDetail(response));

    if (payload.onSuccess)
      payload.onSuccess();
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

export function* handleStoreLayout({ payload }: PayloadAction<Omit<IDataAnalyticsLayout, "layoutno" | "layoutrefno">>): any {
  try {
    yield put(toggleLoader(true));

    const layoutno: any = yield call(requestStoreLayout, payload);
    yield put(setLayout({ ...payload, layoutno }));
    yield put(setGlobalMessage({ message: 'Layout successfully saved', messageType: EMessageType.Success }));
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

export function* handleUpdateLayout({ payload }: PayloadAction<IDataAnalyticsLayout>): any {
  try {
    yield put(toggleLoader(true));
    yield call(requestUpdateLayout, payload);
    yield put(setGlobalMessage({ message: 'Layout successfully updated', messageType: EMessageType.Success }));
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

export function* handleDeleteLayout({ payload }: PayloadAction<{ layoutno: number, layouttype: string }>): any {
  try {
    yield put(toggleLoader(true));
    yield call(requestDeleteLayout, payload.layoutno, payload.layouttype);
    yield put(setGlobalMessage({ message: 'Layout successfully deleted', messageType: EMessageType.Success }));
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}

export function* handleApplyLayout({ payload }: PayloadAction<{ layoutno: number, layouttype: string, onSuccess: (object: any) => void }>): any {
  try {
    yield put(toggleLoader(true));

    const object = yield select(({ DataAnalytics }: RootState) => DataAnalytics.dataAnalyticsDetailObject[DataAnalytics.activeDataAnalyticsObjectno]);
    if (payload.onSuccess) {
      payload.onSuccess(object)
    }
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
  finally {
    yield put(toggleLoader(false));
  }
}