import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import { clearAllGridLineAttachment, getAccountDimensions, getScreenConfig, moveAfterGridLineAttachment, setGridColumnVisibility, setUserGridColumnSetting, toggleAttachment, fillDimensionFromDimensionSet, deleteGridRowDimensionSet } from "@/Redux/Ducks/entryFormSlice";
import { EHelpOpenIn, getHelpConfig } from "@/Redux/Ducks/helpSlice";
import { requestDeleteUserGridConfig, requestSaveUserGridConfig } from "@/Redux/Sagas/Requests/entryForm";
import { ArrayKeyValueObject, EActivity, IDimension, ITModelGridProperties, KeyValueObject } from "@/UI/Interfaces/IBaseUI";
import { IGrid, IGridObjectConfig, IWijmoGridHandle } from "@/UI/Interfaces/IGrid";
import { constant, dimensionSetFields } from "@/Utils/constants";
import * as wjCore from "@grapecity/wijmo";
import { DataType, Tooltip } from "@grapecity/wijmo";
import { AllowSorting, GroupRow, Row as WijmoRow, _NewRowTemplate } from "@grapecity/wijmo.grid";
import * as wjcGridDetail from '@grapecity/wijmo.grid.detail';
import { copyObject } from "@grapecity/wijmo.grid.immutable";
import { Menu } from "@grapecity/wijmo.input";
import * as wjGrid from "@grapecity/wijmo.react.grid";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { ImmutabilityProvider } from "@grapecity/wijmo.react.grid.immutable";
import { Menu as MenuComponent } from "@grapecity/wijmo.react.input";
import "@grapecity/wijmo.styles/wijmo.css";
import BaseMethods from "AlignBaseUI/BaseMethods";
import classNames from "classnames";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Button, Dropdown, Form } from 'react-bootstrap';
import ReactDOMServer from "react-dom/server";
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaAsterisk } from 'react-icons/fa';
import { RiAttachment2, RiSearchLine } from 'react-icons/ri';
import XLSX from 'xlsx-js-style';
import { date, time } from "./input";
import LineInformation from "./lineInformation";
import { Icon, Modal } from 'AlignComponents'

const helpIcon = ReactDOMServer.renderToString(<span className="help-icon"><RiSearchLine /></span>);
const AttachmentIcon = ReactDOMServer.renderToString(<span className="attachment-icon"><RiAttachment2 size={20} /></span>);
const mandatoryIcon = ReactDOMServer.renderToString(<span className="mandatory-icon"><FaAsterisk style={{ fontSize: '12px' }} /></span>);

const financialdimension = "lblfinancialdimension";


const WijmoGrid = forwardRef<IWijmoGridHandle, IWijmoGridProps>((props, ref) => {

  const detailMenuSource = useRef<any>([
    {
      header: 'Company Column Visibility',
      cmd: "companyColumnVisibility",
      items: []
    },
    {
      header: 'Column Visibility',
      cmd: "columnVisibility"
    },
    {
      header: 'Apply System Default View',
      cmd: "systemDefaultView"
    },
    {
      header: '-'
    },
    {
      header: 'Download Template',
      cmd: 'downloadTemplate'
    },
    {
      header: 'Export Excel',
      cmd: 'exportExcel'
    },
    {
      header: 'Import Excel',
      cmd: 'importExcel',
    },
    {
      header: 'Clear Grid Data',
      cmd: 'clearGridData',
    }
  ]);

  const dispatch = useAppDispatch();
  const [columns, setColumns] = useState<any>([]);
  const [toolbar, setToolbar] = useState<any>([]);
  const selectedRowIndex = useRef<number>(-1);

  const gridConfigSaveTimeout = useRef<any>();
  const editorValueInUpdate = useRef<boolean>(false);
  const gridMenuOptionRef = useRef<any>();
  const fileRef = useRef<any>();
  const doubleClickPrevent = useRef<boolean>(false);

  // * columnVisibility
  const [columnVisibilityModal, setModalColumnVisibility] = useState<boolean>(false);
  const [objects, setObjects] = useState<Array<{ header: string, columnId: string, selected: boolean }>>([]);

  // TODO: fix this

  const cellChangedHistory = useRef<Array<any>>([]);

  const subMenuEl = useRef<any>();

  const usercategoryno = useAppSelector(state => state.User.user.usercategoryno) as string;

  const activity = useAppSelector(state => state.EntryForm.objects[state.EntryForm.activeObjectId].activity);
  // const companyFinancialDimension = useAppSelector(state => props.gridConfig.Config.isgridfinancialdimension ? state.EntryForm.objects[state.EntryForm.activeObjectId].ScreenConfig.CompanyDimensionFields : []);
  const attachmentGrid = useAppSelector(state => state.EntryForm.objects[state.EntryForm.activeObjectId].Attachment.attachments.filter(x => x.gridno == props.gridConfig.Config.gridno));
  const toolbarSetting = useAppSelector(state => state.EntryForm.objects[state.EntryForm.activeObjectId].otherState.toolbarSetting.filter(x => x.gridno == props.gridConfig.Config.gridno));
  const dataFormat = useAppSelector(state => {
    const dataFormat = state.User.CompanyDataFormat.cloneArray();
    const processcodeDataFormat = state.EntryForm.objects[state.EntryForm.activeObjectId].ScreenConfig.ProcessCodeApplicationDataFormat;

    processcodeDataFormat.map(x => {
      const findIndex = dataFormat.findIndex(y => y.fielddataformatno == x.fielddataformatno);
      if (findIndex != -1)
        dataFormat.splice(findIndex, 1, x);
    });

    return dataFormat;
  });
  // const dateColumnConfig = props.gridConfig.Header.filter((y) => BaseMethods.getControlType(y.controltypeno).isEqual("date", "time"));

  const isReadonly = props.isReadOnly;
  const allowAddNew = props.allowAddNewRow;
  const allowDelete = props.allowDeleteRow;
  const allowCopyRow = useRef<boolean>(props.allowCopyRow);

  const gridOptions = useRef<typeof props.gridOptions>(props.gridOptions);
  const attachmentGridRef = useRef<typeof attachmentGrid>(attachmentGrid);

  // const dateObjectColumn = props.gridConfig.Header.filter(x => BaseMethods.getControlType(x.controltypeno).isEqual("date", "Time"));
  let gridData = copyObject(props.gridData);

  useImperativeHandle(ref, () => ({
    setCellData,
    getSelectedRowIndex
  }));

  useEffect(() => {
    setColumns(getColumns(props.gridConfig));

    const toolbar: any = [];
    props.gridConfig.Toolbar.map(x => ({ toolbarindex: x.toolbarindex })).uniqueBy("toolbarindex").map(x => {
      let b = getToolbarObject(x.toolbarindex);
      toolbar.push(...b);
    });

    setToolbar(toolbar);

    if (!usercategoryno.isEqual("CON")) {
      const index = detailMenuSource.current.findIndex((x: any) => x.cmd == "companyColumnVisibility");
      if (index != -1)
        detailMenuSource.current.splice(index, 1);
    }

    selectedRowIndex.current = -1;

  }, []);

  useEffect(() => {

    if (props.wijmoRef.current) {
      const gridColumnOptions = Object.entries(props.gridOptions);
      gridOptions.current = props.gridOptions;

      gridColumnOptions.map(([i, x]) => {

        const gridColumn = props.wijmoRef.current.control.columns.find((y: any) => y.binding == x.fieldId);

        if (gridColumn) {
          if (gridColumn.isReadOnly != !x.enable) {
            gridColumn.isReadOnly = !x.enable;
          }
          if (gridColumn.visible != x.visible) {
            gridColumn.visible = x.visible;

            objects.map((y) => {
              if (y.columnId == x.fieldId)
                y.selected = x.visible;
            });
          }
          // ! this have to change
          if (gridColumn.header != x.columnLabel) {
            gridColumn.header = x.columnLabel;
          }
        }

      });
    }

  }, [props.gridOptions]);

  useEffect(() => {

    attachmentGridRef.current = attachmentGrid;

  }, [attachmentGrid]);

  useEffect(() => {
    allowCopyRow.current = props.allowCopyRow;
  }, [props.allowCopyRow]);


  useEffect(() => {

    if (usercategoryno.isEqual("CON")) {
      const findData = detailMenuSource.current.find((x: any) => x.cmd == "companyColumnVisibility");

      findData.items.map((x: any) => {
        const visible = props.gridConfig.Config.companyGridCustomization.columnHidden.indexOf(x.columnId as string) == -1;
        x.selected = visible;
      });
    }

  }, [props.gridConfig.Config.companyGridCustomization]);

  const getColumns = (config: IGrid) => {
    var GridColumns: any = [];
    const { gridOptions } = props;

    if (config) {
      let headers: typeof config.Header = [];

      const userColumnIndex = config.Config.userGridCustomization.columnIndex;
      const newColumns = config.Header.filter((x) => userColumnIndex.indexOf(x.fieldid) == -1);

      userColumnIndex.map((y, i) => {
        const findColumn = config.Header.find((z) => z.fieldid == y);
        if (findColumn)
          headers.push(findColumn);
      });

      headers = headers.concat(newColumns);

      Object.entries(headers.groupBy("fieldid")).map(([key, x], index) => {
        const conf = x[0];

        const header = conf.fieldlabel;
        const binding = conf.fieldid;
        const isReadOnly = !gridOptions[conf.fieldid].enable || conf.fieldhyperlink;
        let width = conf.fieldid != "endcol" ? conf.fieldwidth : "*";
        const visible = gridOptions[conf.fieldid].visible;
        const allowDragging = conf.moveable;
        const isRequired = conf.validatemandatory;

        if (conf.fieldid != "endcol" && config.Config.userGridCustomization.columnWidth[index])
          width = config.Config.userGridCustomization.columnWidth[index];

        let dataType = DataType.String;
        let editor = null;
        let format = null;
        let cssClass = "";

        let columnConfig: KeyValueObject = {};

        if (BaseMethods.getControlType(conf.controltypeno) == "checkbox") {
          cssClass = "switch";
          dataType = DataType.Boolean;
        } else if (BaseMethods.getControlType(conf.controltypeno) == "date") {
          format = dataFormat.find(x => x.fielddataformatno == conf.fielddataformatno)?.fielddataformatmasking || constant.DefaultDateFormat;
          editor = date(isRequired, format);
          dataType = DataType.Date;
        } else if (BaseMethods.getControlType(conf.controltypeno) == "time") {
          format = dataFormat.find(x => x.fielddataformatno == conf.fielddataformatno)?.fielddataformatmasking || constant.DefaultTimeFormat;
          editor = time(format);
          dataType = DataType.Date;
        } else if (BaseMethods.getControlType(conf.controltypeno) == "number") {
          // editor = amount();
          format = dataFormat.find(x => x.fielddataformatno == conf.fielddataformatno)?.fielddataformatmasking || constant.DefaultNumber;
          dataType = DataType.Number;

          if (conf.totalsum)
            columnConfig.aggregate = "Sum";

        } else if (conf.fieldhyperlink) {
          dataType = DataType.Object;
        }

        if (conf.fieldid.isEqual("lineid")) {
          // editor = amount();
          format = "D4";
          dataType = DataType.Number;
        }
        columnConfig = {
          ...columnConfig,
          header,
          binding,
          isReadOnly,
          width,
          dataType,
          editor,
          visible,
          cssClass,
          format,
          allowDragging,
          isRequired
        }

        GridColumns.push(columnConfig);

        if (x.find(y => y.dimensionrequired)) {
          GridColumns.push({
            header: gridOptions[`dimension${binding}values`].columnLabel,
            binding: `dimension${binding}values`,
            isReadOnly: true,
            width: 200,
            visible: visible,
            dataType: DataType.String
          });
        }

      });

      if (config.Config.allowlineattachment) {
        const lineidIndex = GridColumns.findIndex((x: any) => x.binding == "lineid");
        const attachmentColumn = {
          header: "Attachment",
          binding: "attachment",
          isReadOnly: true,
          width: 90,
          visible: true,
          allowDragging: false
        };
        GridColumns.splice(lineidIndex + 1, 0, attachmentColumn);
      }

    }
    GridColumns.push({
      header: " ",
      binding: "endcol",
      isReadOnly: true,
      width: "*",
      visible: true,
      allowDragging: false
    });

    return GridColumns;
  };

  const setGridSystemDefaultView = () => {
    const GridControl = props.wijmoRef.current.control;
    const gridConfig = props.gridConfig;
    GridControl.frozenColumns = 0;

    Object.entries(gridConfig.Header.groupBy("fieldid")).map(([fieldid, x], index) => {
      const config = x[0];
      let visible = false;

      if (activity == EActivity.Create)
        visible = config.createvisible;
      else
        visible = config.changevisible;

      const column = GridControl.columns.getColumn(fieldid);
      GridControl.columns.moveElement(column.index, index);

      if (column.visible != visible) {
        dispatch(setGridColumnVisibility({
          columnId: config.fieldid,
          gridId: gridConfig.Config.gridno,
          visible: visible
        }));
      }

      column.width = config.fieldwidth;
    });

    const gridCustomization = {
      frozenColumns: 0,
      columnHidden: [],
      columnIndex: [],
      columnWidth: []
    }

    dispatch(setUserGridColumnSetting({ gridno: props.gridConfig.Config.gridno, gridCustomization, forCompany: false }));
    requestDeleteUserGridConfig(props.gridConfig.Config.processcode, props.gridConfig.Config.gridno, false);

    if (usercategoryno.isEqual("CON")) {
      dispatch(setUserGridColumnSetting({ gridno: props.gridConfig.Config.gridno, gridCustomization, forCompany: true }));
      requestDeleteUserGridConfig(props.gridConfig.Config.processcode, props.gridConfig.Config.gridno, true);
    }

  }

  const setCellData = async (rowIndex: number, columnId: string, value: any, helpSelected: boolean = false, preventValidateHelp: boolean = false, preventCellChange: boolean = false) => {

    const gridControl = props.wijmoRef.current.control;
    const rows = gridControl.rows;

    const existHistory = cellChangedHistory.current.find((x: any) => x.row == rowIndex && x.columnId == columnId);
    if (!existHistory)
      cellChangedHistory.current.push({ row: rowIndex, columnId });

    const oldValue = rows[rowIndex]?.dataItem?.[columnId] || null;

    const gridColumnObject = gridControl.getColumn(columnId);
    if (gridColumnObject && gridColumnObject.dataType == DataType.String) {
      value = (value || "").toString().trim() ? value.toString().trim() : null;
    }
    else if ((gridColumnObject && gridColumnObject.dataType == DataType.Object) || Array.isArray(value)) {
      const parentDimensionRelationKey = props.parentDimensionRelationKey || "";
      let relationKeyForDimension = `${parentDimensionRelationKey}${props.gridConfig.Config.gridno}-${rowIndex}`;
      const fillMandatoryValues = (data: ArrayKeyValueObject, columnId: string, relationKeyForDimension: string) => {
        return data.map((x, i) => {
          x.lineid = i + 1;

          relationKeyForDimension += `-flex${columnId}-${i}`;

          Object.entries(x).map(([key, value]) => {
            if (key.isEqual(...dimensionSetFields) && x[key]) {
              dispatch(deleteGridRowDimensionSet({ gridno: x[key], rowIndex: i, relationKey: relationKeyForDimension.split("-").slice(0, -1).join("-") }));
              dispatch(fillDimensionFromDimensionSet({ dimensionSet: x[key], dimensionSetFieldid: key, relationKey: relationKeyForDimension }));
            }

            if (Array.isArray(value))
              x[key] = fillMandatoryValues(value, key, relationKeyForDimension);
          });
          return x;
        });
      }

      value = fillMandatoryValues((value || []).cloneArray(), columnId, relationKeyForDimension);

      // value = (value || []).map((x: any, i: number) => {
      //   x.lineid = i + 1;
      //   return x;
      // });
    }

    if (gridColumnObject) {

      const ev = { value };
      props.wijmoBeforeCellChanged(columnId, rowIndex, oldValue, value, rows[rowIndex].dataItem, ev);
      value = ev.value;

      gridControl.setCellData(rowIndex, columnId, value);
    }

    if (columnId == "lineid") {
      wijmoAddGridNewRow(rows[rowIndex].dataItem, rowIndex, columnId, value);
    }
    else {
      const lineidCheck = rows[rowIndex] && rows[rowIndex].dataItem && rows[rowIndex].dataItem.lineid;
      if (!lineidCheck) {
        const rowsLength = rows.filter((x: any) => x.dataItem && x.dataItem.lineid).length;
        setCellData(rowIndex, "lineid", rowsLength + 1);
      }

      wijmoChangeGridRow(rows[rowIndex].dataItem, rowIndex, columnId, value);

    }

    if (!existHistory)
      await props.wijmoCellChanged(columnId, rowIndex, oldValue, value, rows[rowIndex].dataItem, helpSelected, preventValidateHelp, preventCellChange);

    cellChangedHistory.current.pop();
  }

  const wijmoAddGridNewRow = (row: any, rowIndex: number, columnId: string, value: any) => {

    const emptyHeaderObject: any = {};

    props.gridConfig.Header.map((x) => {
      if (BaseMethods.getControlType(x.controltypeno) == "checkbox")
        emptyHeaderObject[x.fieldid] = false;
      else if (BaseMethods.getControlType(x.controltypeno) == "number")
        emptyHeaderObject[x.fieldid] = 0;
      else if (BaseMethods.getControlType(x.controltypeno) == "date")
        emptyHeaderObject[x.fieldid] = "1900-01-01T00:00:00";
      else if (BaseMethods.getControlType(x.controltypeno) == "time")
        emptyHeaderObject[x.fieldid] = "1900-01-01T00:00:00";
      else if (x.fieldhyperlink)
        emptyHeaderObject[x.fieldid] = [];
      else
        emptyHeaderObject[x.fieldid] = null;
    });

    const newRow = { ...emptyHeaderObject, ...row, [columnId]: value };

    const gridControl = props.wijmoRef.current.control;

    const rowInstance = new WijmoRow(newRow);
    if (gridControl.rows[rowIndex] instanceof _NewRowTemplate) {
      const index = gridControl.rows.filter((x: any) => x.dataItem && x.dataItem.lineid).length;

      gridControl.rows.insert(index, rowInstance);
    }
    else {
      gridControl.rows[rowIndex] = rowInstance;
    }

    props.wijmoAddGridNewRow(JSON.parse(JSON.stringify(newRow)), rowIndex + 1, false, true);
  }

  const wijmoChangeGridRow = (row: any, rowIndex: number, columnId: string, value: any) => {

    const newRow = { ...row, [columnId]: value };

    const gridControl = props.wijmoRef.current.control;

    gridControl.rows[rowIndex].dataItem = newRow

    props.wijmoChangeGridRow(JSON.parse(JSON.stringify(newRow)), rowIndex);
    // props.wijmoChangeGridRow(newRow, rowIndex);

  }

  const wijmoDeleteGridRow = (row: any, rowIndex: number) => {

    props.wijmoDeleteGridRow(row, rowIndex);

  }

  const getSelectedRowIndex = () => {

    const gridControl = props.wijmoRef.current.control;
    let selectedIndex = gridControl.selectedRows?.[0]?.dataIndex;
    selectedIndex = selectedIndex == undefined ? -1 : selectedIndex;

    return selectedIndex;
  }

  const deleteSelectedRow = () => {
    const gridControl = props.wijmoRef.current.control;
    const selectedIndex = getSelectedRowIndex();

    wijmoDeleteGridRow(gridControl.rows[selectedIndex].dataItem, gridControl.rows[selectedIndex].index);

  }

  const cloneSelectedRow = () => {
    const gridControl = props.wijmoRef.current.control;
    const selectedIndex = getSelectedRowIndex();

    let rowData = gridControl.rows[selectedIndex]?.dataItem;

    if (rowData) {
      const lineId = rowData.lineid;
      rowData = JSON.parse(JSON.stringify(rowData));

      BaseMethods.clearDefaultColumns(rowData);

      const rowInstance = new WijmoRow(rowData);

      gridControl.rows.insert(selectedIndex + 1, rowInstance);

      props.wijmoAddGridNewRow(rowData, selectedIndex + 1, true, false);

      dispatch(moveAfterGridLineAttachment({ gridno: props.gridConfig.Config.gridno, lineId }));

    }

  }

  const AddNewRow = () => {
    const gridControl = props.wijmoRef.current.control;
    const selectedIndex = getSelectedRowIndex();

    let rowData = gridControl.rows[selectedIndex]?.dataItem;

    if (rowData) {
      rowData = { lineid: 0 };
      const rowInstance = new WijmoRow(rowData);

      gridControl.rows.insert(selectedIndex, rowInstance);

      props.wijmoAddGridNewRow(rowData, selectedIndex, false, false);
    }
  }

  const getToolbarObject = (toolbarIndex: string, sequence: string = "1") => {
    var a: any = [];

    props.gridConfig.Toolbar.filter(x => x.toolbarindex == toolbarIndex).map(x => {
      if (x.toolbarsubindex == sequence) {
        const b: typeof x & { child: Array<typeof x> } = {
          ...x,
          child: []
        };

        const subToolbar = getToolbarObject(toolbarIndex, sequence + "." + 1);
        b.child.push(...subToolbar);

        a.push(b);
      }

    });

    let sequenceSplit = sequence.split(".");
    let nextSequence = [...sequenceSplit.slice(0, sequenceSplit.length - 1), (Number(sequenceSplit[sequenceSplit.length - 1]) + 1)].join(".");

    if (props.gridConfig.Toolbar.filter(x => x.toolbarindex == toolbarIndex).find(x => x.toolbarsubindex == nextSequence) != null) {
      const subToolbar = getToolbarObject(toolbarIndex, nextSequence);
      a.push(...subToolbar);
    }

    return a;
  }

  const toolbarClicked = (param: any) => {
    const gridControl = props.wijmoRef.current.control;
    const selectedIndex = getSelectedRowIndex();

    props.wijmoToolbarClicked(param.objectno, props.gridConfig.Config.gridno, selectedIndex);
  }

  const getToolbarElement = (list: any, first: boolean = false) => {
    if (!list.length)
      return null;

    var listElement = list.map((x: any, i: number) => {
      const setting = toolbarSetting.find(y => y.toolbarKey == x.objectno);

      return (
        <li key={i} className="toolbar-btn">
          <span className={classNames([{ "d-none": !setting?.visible, disabled: !setting?.enable }, "toolbar-name"])} onClick={() => setting?.enable ? toolbarClicked(x) : null}>
            {x.icon ? <Icon size="14" icon={x.icon} className="me-1" /> : null} {x.objectlabel}
          </span>
          {getToolbarElement(x.child)}
        </li>
      )
    })

    listElement = (
      <ul className={first ? "grid-toolbar" : "grid-toolbar-child"}>
        {listElement}
      </ul>
    )

    return listElement
  }

  const initializeGrid = (GridControl: any) => {

    const config = props.gridConfig;
    const gridno = props.gridConfig.Config.gridno;

    let toolTip = new Tooltip();

    GridControl.frozenColumns = props.gridConfig.Config.userGridCustomization.frozenColumns;

    if (props.gridConfig.Header.filter(x => x.totalsum).length) {
      GridControl.columnFooters.rows.push(new GroupRow());
      GridControl.bottomLeftCells.setCellData(0, 0, 'Σ');
    }


    //#region Handlers

    GridControl.formatItem.addHandler(function (s: any, e: any) {
      var col = s.columns[e.col];

      // stop format item when cell is in edit 
      if ((s.editRange && s.editRange.row == e.row && s.editRange.col == e.col) || s.rows[e.row] instanceof wjcGridDetail.DetailRow)
        return;

      if (!col)
        return;

      const columnOptions = gridOptions.current[col.binding];

      if (e.panel == s.cells) {

        const lineId = GridControl.getCellData(e.row, "lineid");
        const colConfig = config.Header.find((e) => e.fieldid == col.binding);
        if (colConfig) {
          const isHyperLink = colConfig.fieldhyperlink;
          if (isHyperLink == true && lineId != null) {
            var html = `<div class="grid-hyperlink">${colConfig.hyperlinklabel}</div>`;
            e.cell.innerHTML = html;
          }
          else if (columnOptions.hyperLinkJump) {
            var html = `<span class="grid-hyperlink jumpto-link" role="button">${e.cell.innerHTML}</span>`;
            e.cell.innerHTML = html;
          }
        }


        const errorExist = Object.values(gridOptions.current).filter(x => x.errorTypeInRows.find(y => y.rowIndex == e.row));

        if (errorExist.length) {
          if (errorExist.find(x => x.fieldId == col.binding))
            e.cell.classList.add("cell-error");

          s.rows[e.row].cssClass = "row-error";
        }
        else {
          s.rows[e.row].cssClass = "";
        }

        if (col.binding == "attachment" && lineId) {
          const lineAttachments = attachmentGridRef.current.filter(x => x.lineid == lineId);

          let html = `<span class="badge bg-info">${lineAttachments.length}</span>`;
          if (lineAttachments.filter(x => x.isNew).length)
            html += `<span class="badge bg-success">new ${lineAttachments.filter(x => x.isNew).length}</span>`;

          e.cell.innerHTML = html;
        }

        if (col.dataType == DataType.Date && e.cell && e.cell.innerText) {
          e.cell.innerText = wjCore.Globalize.format(new Date(e.cell.innerText), col.format);
        }

      }
      else {

        const colConfig = config.Header.find((e) => e.fieldid == col.binding);
        if (colConfig) {
          if (e.panel == GridControl.columnHeaders) {
            var flexHeaders = GridControl.columnHeaders;

            if (gridOptions.current[col.binding].helpObject) {
              var id = gridno + "-HelpColumn-" + col.binding;

              var icon = helpIcon;

              e.cell.classList.add("help-column");
              const div = document.createElement('DIV');
              div.innerHTML = icon;

              (e.cell as HTMLElement).appendChild(div.getFirstChild());
            }
          }

        }


        if (col.binding == "attachment") {
          var icon = AttachmentIcon;
          e.cell.classList.add("text-center");
          e.cell.innerHTML = icon;
        }

        // if (colConfig.validatemandatory) {
        //   e.cell.classList.add("mandatory-column");
        //   const div = document.createElement('DIV');
        //   div.innerHTML = mandatoryIcon;

        //   (e.cell as HTMLElement).appendChild(div.getFirstChild());
        // }

      }
    });

    GridControl.selectionChanged.addHandler((s: any, e: any) => {

      let previousRowIndex = selectedRowIndex.current;
      let rowIndex = -1;
      if (props.wijmoRef.current.control.selectedRows.length) {
        if (props.wijmoRef.current.control.selectedRows[0].dataItem) {
          rowIndex = props.wijmoRef.current.control.selectedRows[0].index;
        }
      }

      if (previousRowIndex != rowIndex)
        props.wijmoRowSelectionChanged(rowIndex);

      selectedRowIndex.current = rowIndex;

    });

    GridControl.deletedRow.addHandler((s: any, e: any) => {
      e.cancel = true;

      wijmoDeleteGridRow(s.rows[e.row].dataItem, s.rows[e.row].index);
      props.wijmoRowSelectionChanged(e.row);

    });

    GridControl.copying.addHandler((s: any, e: any) => {
      e.cancel = true;
      const data = e.panel._activeCell.textContent;
      wjCore.Clipboard.copy(data);

      // let text = "";
      // for (let i = e.range.topRow; i <= e.range.bottomRow; i++) {
      //   for (let j = e.range.leftCol; j <= e.range.rightCol; j++) {
      //     let data = s.getCellData(i, j, true) || "";
      //     if (e.panel.columns[j].header == "Copies link") {
      //       data = this.convertToLink(data);
      //     }
      //     text += j == e.range.leftCol ? data : "\t" + data;
      //   }
      //   if (i != e.range.bottomRow) {
      //     text += "\n";
      //   }
      // }
      // text += JSON.stringify({a: "asdasd"})
      // console.log(text);
      // wjCore.Clipboard.copy(text);

    });

    GridControl.pasting.addHandler((s: any, e: any) => {
      e.cancel = true;

      debugger;
    });

    GridControl.beginningEdit.addHandler((s: any, e: any) => {
      if (e.row != 0) {
        const previousRowIndex = e.row - 1;
        if (!GridControl.getCellData(previousRowIndex, "lineid")) {
          e.cancel = true;
        }
        // if (!GridControl.getCellData(e.row, "lineid")) {
        //   // GridControl.allowAddNew = false;
        // }
      }

      let col = s.columns[e.col];
      const colConfig = config.Header.find((e) => e.fieldid == col.binding);

      if (colConfig) {
        // if (colConfig?.fieldhyperlink)
        //   e.cancel = true;

        if (!colConfig.fieldhyperlink) {
          const value = GridControl.rows[e.row]?.dataItem?.[col.binding] || "";

          props.wijmoCellBeginningEdit(col.binding, e.row, value, e);
          if (e.cancel) {
            doubleClickPrevent.current = true;
            setTimeout(() => {
              doubleClickPrevent.current = false
            }, 300);
          }
        }
      }

    });

    GridControl.cellEditEnding.addHandler((s: any, e: any) => {

      if (!editorValueInUpdate.current) {
        let col = s.columns[e.col];

        const upperDataItem = GridControl.rows[e.row - 1];

        if ((e.row == 0 || (upperDataItem?.dataItem?.lineid)) && GridControl.activeEditor.value != e.data && (GridControl.activeEditor.value || e.data)) {
          editorValueInUpdate.current = true;
          let value = GridControl.activeEditor.value;

          if (col.dataType == DataType.Number) {
            const stringConvert = (GridControl.activeEditor.value || "").toString();
            value = parseFloat(stringConvert.replaceAll(",", "") || 0);
            value = isNaN(value) ? 0 : value;
          }

          else if (col.dataType == DataType.Boolean)
            value = GridControl.activeEditor.checked;

          else if (col.dataType == DataType.Date) {
            if (value) {
              const colConfig = config.Header.find((e) => e.fieldid == col.binding);
              if (BaseMethods.getControlType(colConfig?.controltypeno as string) == "date") {
                const d = wjCore.Globalize.parseDate(value, col.format);
                value = `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${("0" + d.getDate()).slice(-2)}T00:00:00`;
              }
              else
                value = wjCore.Globalize.parseDate(value, col.format);
            }
            else {
              value = "1900-01-01T00:00:00";
            }
          }
          else if (col.dataType == DataType.String)
            value = GridControl.activeEditor.value ? GridControl.activeEditor.value : null;

          setCellData(e.row, col.binding, value);

          editorValueInUpdate.current = false;

        }
        else if (!GridControl.rows[e.row]?.dataItem?.lineid && !GridControl.activeEditor.value) {
          e.cancel = true;
        }
      }

    });

    GridControl.cellEditEnded.addHandler((s: any, e: any) => {

      // let col = s.columns[e.col];
      // const upperDataItem = GridControl.rows[e.row - 1];
      // let newValue = s.getCellData(e.row, e.col);

      // debugger;

      // if ((e.row == 0 || (upperDataItem.dataItem && upperDataItem.dataItem.lineid)) && newValue != e.data && (newValue || e.data)) {
      //   editorValueInUpdate.current = true;
      //   let value = newValue;

      //   if (col.dataType == DataType.Number)
      //     value = newValue;

      //   else if (col.dataType == DataType.Boolean)
      //     value = newValue;

      //   else if (col.dataType == DataType.Date) {
      //     if (value) {
      //       if (col.format == constant.DateFormat) {
      //         const d = value;
      //         value = `${d.getFullYear()}-${("0" + (d.getMonth() + 1)).slice(-2)}-${("0" + d.getDate()).slice(-2)}T00:00:00`;
      //       }
      //       else
      //         value = value;
      //     }
      //     else {
      //       value = "1900-01-01T00:00:00";
      //     }
      //   }
      //   else if (col.dataType == DataType.String)
      //     value = newValue ? newValue : null;

      //   setCellData(e.row, col.binding, value, true);

      //   editorValueInUpdate.current = false;

      // }

    });

    GridControl.pinnedColumn.addHandler((s: any, e: any) => {
      saveGridConfig(false);
    });

    GridControl.resizingColumn.addHandler((s: any, e: any) => {
      if (process.env.NODE_ENV == "development") {
        let cell = s.columnHeaders.getCellElement(0, e.col);
        let col = e.panel.columns[e.col];
        let tip = wjCore.format('DEV ONLY<br/>Objectno: <b>{objectno}</b><br/>Column: <b>{col}</b>, Width: <b>{wid:n0}px</b>', {
          col: col.header || '[no header]',
          wid: col.width,
          objectno: col.binding
        });
        toolTip.show(cell, tip);
      }
    });

    GridControl.resizedColumn.addHandler((s: any, e: any) => {
      if (process.env.NODE_ENV == "development") {
        toolTip.hide();
      }
      saveGridConfig(false);
    });

    GridControl.draggedColumn.addHandler((s: any, e: any) => {
      saveGridConfig(false);
    });

    //#endregion

    //#region Events

    // GridControl.hostElement.addEventListener('wheel', function (e: any) {
    //   let root = GridControl.hostElement.querySelector('[wj-part="root"]');
    //   if (root) {
    //     root.scrollTop += 32 * (e.deltaY < 0 ? -1 : +1);
    //     e.preventDefault();
    //   }

    // });

    const headerMenuSource = [
      { header: 'Hide for company', cmd: 'hidecompany' },
      { header: 'Hide for me', cmd: 'hideme' },
    ];

    const detailMenuSource = [
      { header: 'Add Above New Row', cmd: 'addRow' },
      { header: 'Clone Selected Row', cmd: 'cloneRow' },
      { header: 'Delete Selected Row', cmd: 'deleteRow' },
    ];

    let headerMenu = _buildMenu(GridControl, headerMenuSource);
    let detailMenu = _buildMenu(GridControl, detailMenuSource);

    GridControl.hostElement.addEventListener("contextmenu", (ev: any) => {
      let ht = GridControl.hitTest(ev), row = ht.getRow();
      var checkCell = wjCore.closest(ev.target, ".wj-cells");

      switch (ht.panel) {
        case GridControl.columnHeaders:
        case GridControl.cells:
          GridControl.select(ht.row, ht.col);
          break;
        default:
          break;
      }

      // show the menu for the current column
      if (GridControl.selection.col > -1) {
        ev.preventDefault(); // cancel the browser's default menu
        if (!checkCell) {
          headerMenu.show(ev);
        }
        else {
          detailMenu.show(ev);
        }
      }

    }, true);

    // GridControl.hostElement.addEventListener("keydown", (ev: any) => {
    //   let sel = GridControl.selection;
    //   let selectedRow = GridControl.rows?.[sel.row]?.dataItem;

    //   // if (ev.keyCode == wjCore.Key.Delete && allowDelete.current && sel.row != -1 && selectedRow?.lineid) {
    //   //   ev.preventDefault();

    //   //   wijmoDeleteGridRow(selectedRow, sel.row);
    //   // }

    // }, true);

    GridControl.cells.hostElement.addEventListener("click", (e: any) => {
      var ht = GridControl.hitTest(e);
      if (ht.row != -1) {
        var col = GridControl.columns[ht.col];
        const gridConfig = config.Header.find((e) => e.fieldid == col.binding);
        const columnOptions = gridOptions.current[col.binding];

        if (!col || !gridConfig) return;

        if (ht.target.classList.value.includes("jumpto-link")) {
          const value = GridControl.getCellData(ht.row, col.binding);
          if (value)
            dispatch(getScreenConfig({ processCode: columnOptions.hyperLinkJump, transactionNo: value }));
        }
      }
    });

    GridControl.cells.hostElement.addEventListener("dblclick", (e: any) => {
      if (doubleClickPrevent.current)
        return;

      var ht = GridControl.hitTest(e);
      if (ht.row != -1) {

        var col = GridControl.columns[ht.col];

        if (!col) return;

        const columnOptions = gridOptions.current[col.binding];
        let columnid = col.binding;
        let row = GridControl.rows[ht.row].dataItem;

        const gridConfig = config.Header.find((e) => e.fieldid == col.binding);
        if (gridConfig) {

          if (gridConfig.fieldhyperlink && row?.lineid) {
            props.wijmoHyperLinkClick(columnid, ht.row);
          }
          else if (columnOptions.helpObject && !col.isReadOnly) {
            dispatch(getHelpConfig({
              helpObject: columnOptions.helpObject,
              helpWhere: columnOptions.helpWhere,
              helpOpenIn: EHelpOpenIn.EntryForm,
              multiSelect: true,
              returnParams: {
                fieldId: columnOptions.fieldId as string,
                gridno: gridno,
                gridRowIndex: ht.row
              },
              isSubGrid: props.isSubGrid || false
            }));
          }
        }


        if (columnid == "attachment" && row?.lineid) {
          dispatch(toggleAttachment({ open: true, openModalGridno: gridno, openModalGridLineId: row.lineid }));
        }

        const isDimension = config.Header.find((e) => `dimension${e.fieldid}values` == col.binding);
        if (isDimension) {
          if (isDimension.dimensionrequired) { // extra validation no need 
            const accountno = GridControl.getCellData(ht.row, isDimension.fieldid);
            if (accountno) {
              let dimensionSetFieldid = "dimensionset";
              if (isDimension.separatedimensionsetrequired)
                dimensionSetFieldid = isDimension.fieldid.replace("accountno", "") + "dimensionset";

              dispatch(getAccountDimensions({ accountno: accountno, gridno, accountFieldId: isDimension.fieldid, dimensionSetFieldid, isSubGrid: !!props.isSubGrid, rowIndex: ht.row }));

            }

          }
        }
      }
    });

    GridControl.hostElement.addEventListener("mouseover", (e: any) => {
      let ht = GridControl.hitTest(e),
        rng = null;
      if (!ht.range.equals(rng)) {
        if (ht.cellType == 2) {

          if (process.env.NODE_ENV == "development") {
            let cell = GridControl.columnHeaders.getCellElement(0, ht.col);
            let col = GridControl.columns[ht.col];
            let tip = wjCore.format('DEV ONLY<br/>Grid: <b>{gridno}</b><br/>Objectno: <b>{objectno}</b><br/>Column: <b>{col}</b>, Width: <b>{wid:n0}px</b>', {
              col: col.header || '[no header]',
              wid: col.width,
              objectno: col.binding,
              gridno: props.gridConfig.Config.gridno
            });
            toolTip.show(cell, tip);
          }

        }
      }
    });

    GridControl.hostElement.addEventListener("mouseout", (e: MouseEvent) => {
      toolTip.hide();
    });

    //#endregion

    configureGridOption();

    props.wijmoGridInitialized?.(gridno, GridControl);
  }

  const closeColumnSetting = () => {
    configureGridOption();
  }

  const saveColumnSetting = (forCompany: boolean = false) => {
    props.wijmoRef.current.control.columns.forEach((column: any) => {
      if (objects.map(x => x.columnId).includes(column.binding)) {
        const gridColumnObject: any = objects.find(x => x.columnId == column.binding);
        if (column.visible != gridColumnObject?.selected) {
          setUserColumnVisibility({ columnId: column.binding, currentVisibility: !gridColumnObject?.selected, forCompany: forCompany });
        }
      }
    });

    // configureGridOption();
  }

  const updateObject = (columnId: string) => {
    const updatedObjects = objects.map((object) => {
      if (object.columnId == columnId) {
        object.selected = !object.selected;
      }

      return object;
    })
    setObjects(updatedObjects)
  }

  const setUserColumnVisibility = (param: { columnId: string, currentVisibility: boolean, forCompany: boolean }) => {
    const gridId = props.gridConfig.Config.gridno;

    dispatch(setGridColumnVisibility({
      columnId: param.columnId,
      gridId: gridId,
      visible: !param.currentVisibility
    }));
    saveGridConfig(param.forCompany);

    // if (param.forCompany) {
    //   const findData = detailMenuSource.current.find((x: any) => x.cmd == "companyColumnVisibility");
    //   if (findData)
    //     findData.items.map((x: any) => {
    //       if (x.columnId == param.columnId)
    //         x.selected = !param.currentVisibility;
    //     });
    // }
  }

  const configureGridOption = () => {
    var updatedObjects: any = [];

    props.gridConfig.Header.map((x) => {
      if (
        !x.linegroupno &&
        !x.fieldid.isEqual("lineid")
      ) {

        // const object = {
        //   header: x.fieldlabel,
        //   cmd: "columnVisibility",
        //   columnId: x.fieldid,
        //   selected: props.gridOptions[x.fieldid].visible,
        //   forCompany: false
        // }

        // var findData = detailMenuSource.current.find((x: any) => x.cmd == "companyColumnVisibility");
        // if (findData) {
        //   object.forCompany = true;
        //   findData.items?.push(object);
        // }

        const object: any = {
          header: x.fieldlabel,
          columnId: x.fieldid,
          selected: props.gridOptions[x.fieldid].visible,
        }

        updatedObjects.push(object);

      }

    })

    setObjects(updatedObjects);

    gridMenuOptionRef.current.control.command = {
      executeCommand: (param: any) => menuClicked(param, props.wijmoRef.current.control),
      canExecuteCommand: (param: any) => {
        const selectedIndex = getSelectedRowIndex();
        const allowAddNew = props.wijmoRef.current.control.allowAddNew;
        const allowDelete = props.wijmoRef.current.control.allowDelete;

        if (param.cmd) {
          if (param.cmd.isEqual("importExcel")) {
            if (!allowAddNew)
              return false;
          }
          else if (param.cmd.isEqual("clearGridData")) {
            if (!allowDelete)
              return false;
          }
        }
        return true;
      }
    }

  }

  const importToGrid = async (e: any) => {
    try {
      const fileData: any = await BaseMethods.readFileAsync(e);
      e.target.value = null;

      var input = new Uint8Array(fileData);
      var workbook = XLSX.read(input, { type: "array", cellDates: true });

      var worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const data: any = XLSX.utils.sheet_to_json(worksheet);

      props.wijmoSetGridData(data);

    }
    catch (error) {
      // dispatch(setTabMessage({ tabId: activeObject?.objectId, error }));
    }
  }

  const exportToExcel = function () {
    const gridControl: any = props.wijmoRef.current.control;

    var cols: string[] = gridControl.columns.filter((x: any) => !x.binding.isEqual("endcol") && x.visible).map((x: any) => x.binding);

    var sheetData = gridControl.rows.map((x: KeyValueObject) => {
      var row: KeyValueObject = {};
      if (x.dataItem && x.dataItem["lineid"]) {
        cols.forEach(key => {
          if (typeof (x.dataItem[key]) != 'object') {
            row[key] = x.dataItem[key];
          }
        });
      }
      return row;
    });

    var workbook = XLSX.utils.book_new();
    var worksheet = XLSX.utils.json_to_sheet(sheetData, { header: cols });

    for (const property in worksheet) {
      if (cols.includes(worksheet[property].v)) {
        worksheet[property].s = {
          fill: {
            patternType: 'solid',
            fgColor: { rgb: "538DD5" }
          },
          font: {
            sz: 12,
            bold: true,
            color: { rgb: "FFFFFF" }
          },
        };
      }
    }

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Export')
    XLSX.writeFile(workbook, "export.xlsx");
  }

  const downloadTemplate = function () {
    var cols: Array<string> = props.gridConfig.Header.filter(x => x.templatefield).map(x => x.fieldid);
    const endcol = cols.indexOf('endcol');
    cols.splice(endcol, 1)
    const sheetData: any = [];
    var workbook = XLSX.utils.book_new();
    var worksheet = XLSX.utils.json_to_sheet(sheetData, { header: cols });

    const ref = worksheet['!ref'];
    delete worksheet["!ref"];
    for (const property in worksheet) {
      worksheet[property].s = {
        fill: {
          patternType: 'solid',
          fgColor: { rgb: "538DD5" }
        },
        font: {
          sz: 12,
          bold: true,
          color: { rgb: "FFFFFF" }
        },
      };
    }
    worksheet['!ref'] = ref;

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Template')
    XLSX.writeFile(workbook, "template.xlsx");
  }

  const _buildMenu = (grid: any, itemsSource: any) => {
    let menu = new Menu(document.createElement('div'), {
      owner: grid.hostElement,
      displayMemberPath: 'header',
      subItemsPath: 'items',
      dropDownCssClass: 'ctx-menu',
      openOnHover: true,
      closeOnLeave: true,
      itemsSource,
      command: {
        executeCommand: (param: any) => menuClicked(param, grid),
        canExecuteCommand: (param: any) => {
          const selectedIndex = getSelectedRowIndex();
          const allowAddNew = props.wijmoRef.current.control.allowAddNew;
          const allowDelete = props.wijmoRef.current.control.allowDelete;

          if (param.cmd) {
            if (param.cmd.isEqual("deleteRow", "cloneRow", "addRow")) {
              if (selectedIndex == -1) {
                return false;
              }
              else {
                if (param.cmd.isEqual("cloneRow") && !allowCopyRow.current)
                  return false;
                else if (param.cmd.isEqual("addRow") && !allowAddNew)
                  return false;
                else if (param.cmd.isEqual("deleteRow") && !allowDelete)
                  return false;
              }
            }
          }
          return true;
        }
      }
    });
    return menu;
  }

  const clearGridData = () => {
    props.wijmoSetGridData([]);
    dispatch(clearAllGridLineAttachment({ gridno: props.gridConfig.Config.gridno }));
  }

  const menuClicked = (param: any, grid: any) => {

    let view = grid.collectionView, cols = grid.columns, col = cols[grid.selection.col], sd = view.sortDescriptions, gd = view.groupDescriptions;

    switch (param.cmd) {
      case "hidecompany":
        setUserColumnVisibility({ columnId: col.binding, currentVisibility: col.visible, forCompany: true });
        break;
      case "hideme":
        setUserColumnVisibility({ columnId: col.binding, currentVisibility: col.visible, forCompany: false });
        break;
      case "downloadTemplate":
        downloadTemplate();
        break;
      case "exportExcel":
        exportToExcel();
        break;
      case "exportExcelWithHiddenColumns":
        exportToExcel();
        break;
      case "importExcel":
        fileRef.current?.click();
        break;
      case "clearGridData":
        clearGridData();
        break;
      case "deleteRow":
        deleteSelectedRow();
        break;
      case "cloneRow":
        cloneSelectedRow();
        break;
      case "addRow":
        AddNewRow();
        break;
      case "columnVisibility":
        setModalColumnVisibility(true);
        break;
      case "systemDefaultView":
        setGridSystemDefaultView();
        break;
      default:
        break;
    }
  }

  const saveGridConfig = (forcompany: boolean) => {

    if (gridConfigSaveTimeout.current)
      clearTimeout(gridConfigSaveTimeout.current);

    gridConfigSaveTimeout.current = setTimeout(() => {

      const gridconfig = {
        frozenColumns: 0,
        columnHidden: [],
        columnIndex: [],
        columnWidth: []
      };

      gridconfig.columnHidden = props.wijmoRef.current.control.columns.filter((x: any) => !x.visible).map((x: any) => x.binding);

      if (!forcompany) {
        gridconfig.frozenColumns = props.wijmoRef.current.control.frozenColumns;
        gridconfig.columnIndex = props.wijmoRef.current.control.columns.map((x: any) => x.binding);
        gridconfig.columnWidth = props.wijmoRef.current.control.columns.map((x: any) => x.width);
      }

      const saveObject = {
        processcode: props.gridConfig.Config.processcode,
        gridno: props.gridConfig.Config.gridno,
        gridconfig: JSON.stringify(gridconfig),
        forcompany
      };

      requestSaveUserGridConfig(saveObject);

      dispatch(setUserGridColumnSetting({ gridno: props.gridConfig.Config.gridno, gridCustomization: gridconfig as IGridObjectConfig["userGridCustomization"], forCompany: forcompany }));

    }, 2000);

  }

  if (columns.length) {


    // let lineGroup: Array<Partial<IGridHeaderColumns>> = [];
    // if (props.gridConfig.Config.linegrouping) {
    //   lineGroup = props.gridConfig.Header.filter(x => x.linegroupno).sort((a, b) => (a.linegroupposition - b.linegroupposition || a.linegroupno.localeCompare(b.linegroupno)));
    // }
    // if (props.gridConfig.Config.isgridfinancialdimension) {
    //   lineGroup = lineGroup.concat(companyFinancialDimension.map(x => ({ ...x, linegroupno: financialdimension })));
    // }

    // const lineGroupByObject = Object.entries(lineGroup.groupBy("linegroupno"));

    return (
      <>
        <Modal
          heading="Column Visibility"
          show={columnVisibilityModal}
          close={() => { setModalColumnVisibility(false); closeColumnSetting(); }}
          modalSize="sm"
          bodyStyle={{ maxHeight: "500px", overflow: "auto" }}
          footer={
            <>
              <Button variant="primary" onClick={() => { saveColumnSetting(false) }}>
                Save
              </Button>
            </>
          }
        >
          <>
            {
              objects.map((item) =>
                <Dropdown.ItemText key={item.columnId}>
                  <Form.Check type="checkbox" onClick={(ev: any) => { updateObject(item.columnId) }} label={item.header} checked={item.selected} readOnly />
                </Dropdown.ItemText>
              )
            }
          </>
        </Modal>
        <div className={classNames(["grid-container", { "grid-disabled": isReadonly }])}>

          {getToolbarElement(toolbar, true)}

          <input ref={fileRef} onChange={(e) => importToGrid(e)} type="file" style={{ display: "none" }} />

          <div className="grid-btn-group">
            <Button variant="white" id={`${props.gridConfig.Config.gridno}-options`}
              onMouseUp={(ev: any) => {
                ev = new MouseEvent("click", ev);
                gridMenuOptionRef.current.control.show(ev);
              }}>
              <BsThreeDotsVertical size="16" />
            </Button>

            <MenuComponent
              ref={gridMenuOptionRef}
              contextMenuOf={undefined}
              dropDownCssClass="gridMenu"
              openOnHover={true}
              displayMemberPath="header"
              subItemsPath="items"
              itemsSource={detailMenuSource.current}
              wjItemTemplate={(context: any) => (
                context.item.selected == undefined ?
                  <>
                    {context.item.header}
                  </>
                  :
                  <label>
                    <input type="checkbox" checked={context.item.selected} readOnly />&nbsp;&nbsp;{context.item.header}
                  </label>
              )}
            />

          </div>

          <wjGrid.FlexGrid
            id={props.gridConfig.Config.gridno}
            ref={props.wijmoRef}
            allowSorting={AllowSorting.None}
            initialized={initializeGrid}
            columns={columns}
            allowAddNew={allowAddNew}
            allowDelete={allowDelete}
            multiSelect={false}
            stickyHeaders={true}
            keyActionTab={2}
            autoGenerateColumns={false}
            selectionMode="Row"
            headersVisibility="Column"
            autoClipboard={true}
            alternatingRowStep={0}
            allowPinning="ColumnRange"
          >
            <ImmutabilityProvider itemsSource={gridData} />

            <FlexGridFilter showSortButtons={false} /*filterColumns={columns.filter((e: any) => e.binding != "endcol").map((e: any) => e.binding)}*/ />

          </wjGrid.FlexGrid>

          {
            props.gridConfig.Config.linegrouping || props.gridConfig.Config.isgridfinancialdimension ?
              <LineInformation
                gridRef={props.wijmoRef.current?.control}
                gridno={props.gridConfig.Config.gridno}
                header={props.gridConfig.Header}
                isReadonly={isReadonly}
                isSubGrid={!!props.isSubGrid}
                isgridfinancialdimension={props.gridConfig.Config.isgridfinancialdimension}
                linegrouping={props.gridConfig.Config.linegrouping}
                wijmoGridDimensionObject={props.wijmoGridDimensionObject}
                wijmoUpdateDimensionValue={props.wijmoUpdateDimensionValue}
                setCellData={setCellData}
              />
              : null
          }

        </div>
      </>
    );
  }

  return null;

})

export default WijmoGrid;


interface IWijmoGridProps {
  // objectId: string;
  // ref: any;
  gridConfig: IGrid;
  gridData: Array<KeyValueObject>;
  isReadOnly: boolean;
  allowAddNewRow: boolean
  allowDeleteRow: boolean
  allowCopyRow: boolean
  gridOptions: {
    [j: string]: ITModelGridProperties<KeyValueObject>;
  };
  wijmoRef: any;
  isSubGrid?: boolean;
  wijmoGridDimensionObject: IDimension[];
  parentDimensionRelationKey?: string;
  wijmoAddGridNewRow(newItem: any, rowIndex: number, clone: boolean, addOnLastLine: boolean): void
  wijmoDeleteGridRow(oldItem: any, itemIndex: number): void
  wijmoChangeGridRow(newItem: any, itemIndex: number): void
  wijmoBeforeCellChanged(columnId: string, rowIndex: number, oldValue: any, newValue: any, gridRow: KeyValueObject, ev: KeyValueObject): void
  wijmoCellChanged(columnId: string, rowIndex: number, oldValue: any, newValue: any, gridRow: KeyValueObject, helpSelected: boolean, preventValidateHelp: boolean, preventCellChange: boolean): void
  wijmoHyperLinkClick(columnId: string, rowIndex: number): void
  wijmoSetGridData(gridRows: ArrayKeyValueObject): void
  wijmoCellBeginningEdit(columnId: string, rowIndex: number, value: any, event: any): void
  wijmoRowSelectionChanged(rowIndex: number): void
  wijmoUpdateDimensionValue(columnId: string, value: any, rowIndex: number): void
  wijmoToolbarClicked(toolbarkey: string, gridno: string, rowIndex: number): void
  wijmoGridInitialized?(gridno: string, GridControl: any): void
}
