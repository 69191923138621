import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import { toggleActiveScreenTab, pushScreenTabs } from "@/Redux/Ducks/entryFormSlice";
import classNames from "classnames";
import { useEffect } from "react";
import Tab from "./Tab";

interface ITabPanelProps {
  children: any;
  allowCustomLabel?: boolean;
  onChange?: (previousActiveTab: string, newActiveTab: string) => void;
}

interface IActive {
  activeTabs: { [key: string]: boolean };
}

const TabPanel = (props: ITabPanelProps) => {
  const dispatch = useAppDispatch();

  const allControl: any = [];
  (props.children.length ? props.children : [props.children]).map((x: any) => {
    if (x.type == Tab) {
      allControl.push(x);
    }
    else {
      const subTabs = x.type(x.props);

      if (subTabs && subTabs.props) {
        if (Array.isArray(subTabs.props.children)) {

          subTabs.props.children.map((y: any) => {
            if (y) {
              if (y.type.name.isEqual("FormDimension")) {
                const formDimensionTab = y.type(y.props);
                if (formDimensionTab)
                  allControl.push(formDimensionTab);
              }
              else {
                allControl.push(y);
              }
            }
          })

          // allControl.push(...subTabs.props.children.filter((x: any) => x));
        }
        else if (subTabs.props.children) {
          allControl.push(subTabs.props.children);
        }
      }

    }
  });

  // const allControl = (props.children.length ? props.children : [props.children]).map((x: any) => (x.type != Tab ? x.type(x.props) : x)).filter((x: any) => x);

  const allControlIds = allControl.map((x: any) => x.props.controlId);
  const tabsConfig = useAppSelector(state => state.EntryForm.objects[state.EntryForm.activeObjectId].otherState.screenTab)
  const activeObjectId = useAppSelector((state) => state.EntryForm.activeObjectId);
  const activeTabId = useAppSelector((state) => state.EntryForm.objects[activeObjectId].otherState.activeScreenTab.controlId.find((x) => allControlIds.includes(x)));
  const systemLabel = useAppSelector((state) => state.User.SystemLanguages);
  const processLabel = useAppSelector((state) => state.EntryForm.objects[activeObjectId].ScreenConfig.ControlTranslation);

  useEffect(() => {
    if (!activeTabId) {
      const screenTabs = allControlIds.map((x: any) => ({ controlId: x, visible: true }))

      setActiveTab(allControlIds[0]);
      dispatch(pushScreenTabs(screenTabs));
    }
  }, []);

  useEffect(() => {
    if (!activeTabId) {
      const getVisibleControlIds = allControlIds.filter((x: any) => tabsConfig.find(y => y.controlId == x && y.visible == true) != null);

      setActiveTab(getVisibleControlIds[0]);
    }
  }, [activeTabId]);

  const setActiveTab = (controlId: string) => {
    if (controlId != activeTabId) {
      if (props.onChange && activeTabId)
        props.onChange(activeTabId, controlId);
        
      dispatch(toggleActiveScreenTab({ controlId, activeTabId }));
    }
  }

  const getControlTranslation = (controlId: string, translationControlId: string, isSystem: boolean) => {
    const translationId = translationControlId || controlId;
    if (props.allowCustomLabel && !isSystem) {
      const findProps = allControl.map((x: any) => x.props).find((x: any) => (x.translationControlId || x.controlId) == translationId);
      if (findProps)
        if (findProps.label)
          return findProps.label;
    }

    if (isSystem) {
      const label = systemLabel.find(x => x.objectno == translationId);
      return label?.objectlabel || "Not Defined";
    }
    else {
      const controlTranslation = processLabel.find((x) => x.controlId == translationId);
      return controlTranslation?.translation || "Not Defined";
    }
  }

  return (
    <>
      <ul className="nav nav-tabs mb-3" role="tablist">

        {
          allControl.map((x: any, i: any) => {
            let tab = x;
            let tabConfig = tabsConfig.filter(x => x.controlId == tab.props.controlId)[0];

            return (
              <li key={`heading-${tab.props.controlId}`} className={classNames([{ "d-none": !tabConfig?.visible }, "nav-item"])} role="presentation">
                <button
                  className={classNames([{ active: tab.props.controlId == activeTabId }, "nav-link"])}
                  id={`tab-header-${tab.props.controlId}`}
                  type="button"
                  role="tab"
                  aria-controls={tab.props.controlId}
                  aria-selected={tab.props.controlId == activeTabId}
                  data-toggle="tab"
                  data-target={`#tab-content-${tab.props.controlId}`}
                  onClick={() => setActiveTab(tab.props.controlId)}
                >
                  {getControlTranslation(tab.props.controlId, tab.props.translationControlId, tab.props.system)}
                </button>
              </li>
            )
          })
        }
      </ul>
      <div className="tab-content">

        {
          allControl.map((x: any, i: any) => {
            let tab = x;
            let tabConfig = tabsConfig.filter(x => x.controlId == tab.props.controlId)[0];

            return (
              <div key={`content-${tab.props.controlId}`}
                className={classNames([{ "show active": tab.props.controlId == activeTabId }, "tab-pane fade"])}
                id={`#tab-content-${tab.props.controlId}`}
                role="tabpanel"
                aria-labelledby={`tab-header-${tab.props.controlId}`}
              >
                {tab.props.children}
              </div>
            )
          })
        }
      </div>
    </>
  )

  // return (
  //   <Tabs defaultActiveKey={activeTabId} className="mb-3">
  //     {allControl.map((x: any, i: any) => {
  //       let tab = x;
  //       let tabConfig = tabsConfig.filter(x => x.controlId == tab.props.controlId)[0];

  //       return (
  //         <BsTab eventKey={tab.props.controlId} title={getControlTranslation(tab.props.controlId, tab.props.system)} onClick={() => setActiveTab(tab.props.controlId)}>
  //           {tab.props.children}
  //         </BsTab>
  //       )
  //     })
  //     }
  //   </Tabs>
  // );
};

export default TabPanel;
