import { AnyAction, combineReducers } from "@reduxjs/toolkit";
import DashoardReducer from "./dashboardSlice";
import DataAnalyticsReducer from "./dataAnalyticsSlice";
import EntryFormReducer from "./entryFormSlice";
import HelpReducer from "./helpSlice";
import HomeReducer from "./homeSlice";
import InboxReducer from "./inboxSlice";
import LoaderReducer from "./loaderSlice";
import MessageReducer from "./messageSlice";
import ReportExplorerReducer from "./reportExplorerSlice";
import TabReducer from "./tabSlice";
import UserReducer, { logoutUser } from "./userSlice";
import WorkspaceReducer from './workspaceSlice';
import history from "@/Utils/history";
import { connectRouter } from 'connected-react-router';

const reducer = {
  EntryForm: EntryFormReducer,
  User: UserReducer,
  Tab: TabReducer,
  Help: HelpReducer,
  Loader: LoaderReducer,
  Message: MessageReducer,
  Home: HomeReducer,
  DataAnalytics: DataAnalyticsReducer,
  ReportExplorer: ReportExplorerReducer,
  Inbox: InboxReducer,
  Dashboard: DashoardReducer,
  Workspace: WorkspaceReducer
}

const appReducer = combineReducers({
  router: connectRouter(history),
  ...reducer
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: AnyAction) => {
  if (action.type === logoutUser.type) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
