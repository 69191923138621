import { Card } from "react-bootstrap";
import { GiMove } from "react-icons/gi";
import { BiLinkExternal } from "react-icons/bi";
// @ts-ignore
import Fade from 'react-reveal/Fade';
// import ApexChart from "react-apexcharts";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import AreaChart from "./AreaChart";
import PieChart from "./PieChart";

const renderChart = ({ chartType, ...restProps }: any) => {
  console.log(chartType)
  if (chartType.isEqual("BAR", "COL")) {
    return (
      <BarChart {...restProps} />
    )
  }
  else if (chartType.isEqual("LNE")) {
    return (
      <LineChart {...restProps} />
    )
  }
  else if (chartType.isEqual("ARA")) {
    return (
      <AreaChart {...restProps} />
    )
  }
  else if (chartType.isEqual("PIE", "DNT")) {
    return (
      <PieChart {...restProps} type={chartType.isEqual("PIE") ? "pie" : "donut"} />
    )
  }
  else
    return null;
}

export const AlignUIRender = ({
  title,
  chartType,
  widgetprocesscode,
  data,
  columns,
  hyperlinkHandler
}: any) => (
  <div className="chart-card">
    <Fade>
      <Card>
        <Card.Header>
          {title}

          {
            widgetprocesscode ? (
              <OverlayTrigger placement="bottom" overlay={<Tooltip>Hyperlink</Tooltip>}>
                <div className="hyperlink" onClick={() => { hyperlinkHandler() }}>
                  <BiLinkExternal size={16} />
                </div>
              </OverlayTrigger>
            ) : null
          }

          <OverlayTrigger placement="bottom" overlay={<Tooltip>Move</Tooltip>}>
            <div className="drag-handle">
              <GiMove size={16} />
            </div>
          </OverlayTrigger>
        </Card.Header>
        <Card.Body>
          <div style={{ height: "100%", padding: "10px 16px" }}>
            {
              renderChart({ chartType, data, columns })
            }
          </div>
        </Card.Body>
      </Card>
    </Fade>
  </div>
);
