const lblhome = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 18V15C10 14.4695 10.2107 13.9608 10.5858 13.5858C10.9609 13.2107 11.4696 13 12 13C12.5304 13 13.0391 13.2107 13.4142 13.5858C13.7893 13.9608 14 14.4695 14 15V18M2 7.99998L11.732 3.13398C11.8152 3.09243 11.907 3.0708 12 3.0708C12.093 3.0708 12.1848 3.09243 12.268 3.13398L22 7.99998" stroke="92A1E0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20 11V19C20 19.5304 19.7893 20.0391 19.4142 20.4142C19.0391 20.7893 18.5304 21 18 21H6C5.46957 21 4.96086 20.7893 4.58579 20.4142C4.21071 20.0391 4 19.5304 4 19V11" stroke="92A1E0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
const lblsearch = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 17L21 21M3 11C3 13.1217 3.84285 15.1566 5.34315 16.6569C6.84344 18.1571 8.87827 19 11 19C13.1217 19 15.1566 18.1571 16.6569 16.6569C18.1571 15.1566 19 13.1217 19 11C19 8.87827 18.1571 6.84344 16.6569 5.34315C15.1566 3.84285 13.1217 3 11 3C8.87827 3 6.84344 3.84285 5.34315 5.34315C3.84285 6.84344 3 8.87827 3 11Z" stroke="#92A1E0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
const lblmodule = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 11.5C13.0609 11.5 14.0783 11.0786 14.8284 10.3284C15.5786 9.57828 16 8.56087 16 7.5C16 6.43913 15.5786 5.42172 14.8284 4.67157C14.0783 3.92143 13.0609 3.5 12 3.5C10.9391 3.5 9.92172 3.92143 9.17157 4.67157C8.42143 5.42172 8 6.43913 8 7.5C8 8.56087 8.42143 9.57828 9.17157 10.3284C9.92172 11.0786 10.9391 11.5 12 11.5ZM7 20.5C8.06087 20.5 9.07828 20.0786 9.82843 19.3284C10.5786 18.5783 11 17.5609 11 16.5C11 15.4391 10.5786 14.4217 9.82843 13.6716C9.07828 12.9214 8.06087 12.5 7 12.5C5.93913 12.5 4.92172 12.9214 4.17157 13.6716C3.42143 14.4217 3 15.4391 3 16.5C3 17.5609 3.42143 18.5783 4.17157 19.3284C4.92172 20.0786 5.93913 20.5 7 20.5ZM17 20.5C18.0609 20.5 19.0783 20.0786 19.8284 19.3284C20.5786 18.5783 21 17.5609 21 16.5C21 15.4391 20.5786 14.4217 19.8284 13.6716C19.0783 12.9214 18.0609 12.5 17 12.5C15.9391 12.5 14.9217 12.9214 14.1716 13.6716C13.4214 14.4217 13 15.4391 13 16.5C13 17.5609 13.4214 18.5783 14.1716 19.3284C14.9217 20.0786 15.9391 20.5 17 20.5Z" stroke="#92A1E0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
const lblfavorite = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 12H23M12 2V1M12 23V22M20 20L19 19M20 4L19 5M4 20L5 19M4 4L5 5M1 12H2M9.952 9.623L11.511 6.318C11.553 6.22337 11.6216 6.14295 11.7084 6.08652C11.7952 6.03008 11.8965 6.00003 12 6.00003C12.1035 6.00003 12.2048 6.03008 12.2916 6.08652C12.3784 6.14295 12.447 6.22337 12.489 6.318L14.048 9.623L17.533 10.156C17.98 10.224 18.158 10.8 17.835 11.13L15.313 13.7L15.908 17.331C15.985 17.798 15.517 18.153 15.117 17.933L12 16.218L8.883 17.933C8.483 18.153 8.015 17.798 8.092 17.331L8.687 13.701L6.165 11.13C5.842 10.8 6.02 10.224 6.467 10.156L9.952 9.623Z" stroke="#92A1E0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
const lbldashboard = `
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 4V8M12 4C6.47701 4 2.00001 8.477 2.00001 14C1.99661 16.1646 2.69872 18.2713 4.00001 20.001H8.50001M12 4C17.523 4 22 8.477 22 14C22 16.252 21.256 18.33 20 20.001L15.5 20M4.00001 8L6.50001 10.5M17.5 10.5L20 8M3.00001 17H6.00001M12 17L13 11M18 17H21" stroke="#92A1E0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 23C12.7956 23 13.5587 22.6839 14.1213 22.1213C14.6839 21.5587 15 20.7956 15 20C15 19.2044 14.6839 18.4413 14.1213 17.8787C13.5587 17.3161 12.7956 17 12 17C11.2044 17 10.4413 17.3161 9.87868 17.8787C9.31607 18.4413 9 19.2044 9 20C9 20.7956 9.31607 21.5587 9.87868 22.1213C10.4413 22.6839 11.2044 23 12 23Z" stroke="#92A1E0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
const lblrecentactivity = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 12H6L9 3L15 21L18 12H21" stroke="#92A1E0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
const lblworkspace = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 17H3V4H21V17H18" stroke="#92A1E0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.62199 19.067L11.5 14.75C11.5548 14.6679 11.629 14.6006 11.7161 14.5541C11.8031 14.5075 11.9003 14.4832 11.999 14.4832C12.0977 14.4832 12.1949 14.5075 12.2819 14.5541C12.369 14.6006 12.4432 14.6679 12.498 14.75L15.378 19.068C15.4383 19.1584 15.473 19.2635 15.4782 19.3721C15.4834 19.4806 15.4591 19.5886 15.4077 19.6844C15.3564 19.7802 15.28 19.8603 15.1867 19.916C15.0934 19.9718 14.9867 20.0012 14.878 20.001H9.11999C9.0113 20.0012 8.9046 19.9718 8.8113 19.916C8.718 19.8603 8.6416 19.7802 8.59026 19.6844C8.53892 19.5886 8.51456 19.4806 8.5198 19.3721C8.52504 19.2635 8.55967 19.1584 8.61999 19.068L8.62199 19.067Z" stroke="#92A1E0" stroke-width="1.5"/>
</svg>`;

const LeftChevron = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 6L9 12L15 18" stroke="#929292" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
const RightChevron = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 6L15 12L9 18" stroke="#929292" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

const Inbox = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 9L12 12.5L17 9" stroke="#929292" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2 17V7C2 6.46957 2.21071 5.96086 2.58579 5.58579C2.96086 5.21071 3.46957 5 4 5H20C20.5304 5 21.0391 5.21071 21.4142 5.58579C21.7893 5.96086 22 6.46957 22 7V17C22 17.5304 21.7893 18.0391 21.4142 18.4142C21.0391 18.7893 20.5304 19 20 19H4C3.46957 19 2.96086 18.7893 2.58579 18.4142C2.21071 18.0391 2 17.5304 2 17Z" stroke="#929292" stroke-width="1.5"/>
</svg>`;
const DefaultPage = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16 16V8M12 16V11M8 16V13" stroke="#929292" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 20.4V3.6C3 3.44087 3.06321 3.28826 3.17574 3.17574C3.28826 3.06321 3.44087 3 3.6 3H20.4C20.5591 3 20.7117 3.06321 20.8243 3.17574C20.9368 3.28826 21 3.44087 21 3.6V20.4C21 20.5591 20.9368 20.7117 20.8243 20.8243C20.7117 20.9368 20.5591 21 20.4 21H3.6C3.44087 21 3.28826 20.9368 3.17574 20.8243C3.06321 20.7117 3 20.5591 3 20.4Z" stroke="#929292" stroke-width="1.5"/>
</svg>`;
const Help = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z" stroke="#929292" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9 9C9 5.5 14.5 5.5 14.5 9C14.5 11.5 12 11 12 14M12 18.01L12.01 17.999" stroke="#929292" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

const RequestsForApproval = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 9L9.5 12L14 9" stroke="#0080FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V7C1 6.46957 1.21071 5.96086 1.58579 5.58579C1.96086 5.21071 2.46957 5 3 5H16C16.5304 5 17.0391 5.21071 17.4142 5.58579C17.7893 5.96086 18 6.46957 18 7V9" stroke="#0080FF" stroke-width="1.5" stroke-linecap="round"/>
<path d="M23 14H17M17 14L20 11M17 14L20 17" stroke="#0080FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
const SentForApproval = `<svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 5L9.5 8L14 5" stroke="#0080FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17 15H3C2.46957 15 1.96086 14.7893 1.58579 14.4142C1.21071 14.0391 1 13.5304 1 13V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H16C16.5304 1 17.0391 1.21071 17.4142 1.58579C17.7893 1.96086 18 2.46957 18 3V5" stroke="#0080FF" stroke-width="1.5" stroke-linecap="round"/>
<path d="M17 10H23M23 10L20 7M23 10L20 13" stroke="#0080FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
const Tasks = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.5 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H12M15.5 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V15" stroke="#0080FF" stroke-width="1.5" stroke-linecap="round"/>
<path d="M8 6.4V4.5C8 4.36739 8.05268 4.24021 8.14645 4.14645C8.24021 4.05268 8.36739 4 8.5 4C8.776 4 9.004 3.776 9.052 3.504C9.2 2.652 9.774 1 12 1C14.226 1 14.8 2.652 14.948 3.504C14.996 3.776 15.224 4 15.5 4C15.6326 4 15.7598 4.05268 15.8536 4.14645C15.9473 4.24021 16 4.36739 16 4.5V6.4C16 6.55913 15.9368 6.71174 15.8243 6.82426C15.7117 6.93679 15.5591 7 15.4 7H8.6C8.44087 7 8.28826 6.93679 8.17574 6.82426C8.06321 6.71174 8 6.55913 8 6.4Z" stroke="#0080FF" stroke-width="1.5" stroke-linecap="round"/>
<path d="M15.5 20.5L17.5 22.5L22.5 17.5" stroke="#0080FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
const FrequentlyUsed = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 22H17" stroke="#0080FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2 17V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H20C20.5304 2 21.0391 2.21071 21.4142 2.58579C21.7893 2.96086 22 3.46957 22 4V17C22 17.5304 21.7893 18.0391 21.4142 18.4142C21.0391 18.7893 20.5304 19 20 19H4C3.46957 19 2.96086 18.7893 2.58579 18.4142C2.21071 18.0391 2 17.5304 2 17Z" stroke="#0080FF" stroke-width="1.5"/>
<path d="M9 10.5L11 12.5L15 8.5" stroke="#0080FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
const Notifications = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.73 21C13.5544 21.3033 13.3021 21.5552 12.9985 21.7302C12.6948 21.9053 12.3505 21.9974 12 21.9974C11.6495 21.9974 11.3052 21.9053 11.0015 21.7302C10.6979 21.5552 10.4456 21.3033 10.27 21M18 8.4C18 6.703 17.368 5.075 16.243 3.875C15.117 2.675 13.59 2 12 2C10.409 2 8.883 2.674 7.757 3.875C6.632 5.075 6 6.703 6 8.4C6 15.867 3 18 3 18H21C21 18 18 15.867 18 8.4Z" stroke="#0080FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
const Messages = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.73 21C13.5544 21.3033 13.3021 21.5552 12.9985 21.7302C12.6948 21.9053 12.3505 21.9974 12 21.9974C11.6495 21.9974 11.3052 21.9053 11.0015 21.7302C10.6979 21.5552 10.4456 21.3033 10.27 21M18 8.4C18 6.703 17.368 5.075 16.243 3.875C15.117 2.675 13.59 2 12 2C10.409 2 8.883 2.674 7.757 3.875C6.632 5.075 6 6.703 6 8.4C6 15.867 3 18 3 18H21C21 18 18 15.867 18 8.4Z" stroke="#0080FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

const ActiveApprovals = `<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.66663 8.83341L6.66663 10.8334L11.3333 6.16675" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.99998 15.1667C11.682 15.1667 14.6666 12.182 14.6666 8.50004C14.6666 4.81804 11.682 1.83337 7.99998 1.83337C4.31798 1.83337 1.33331 4.81804 1.33331 8.50004C1.33331 12.182 4.31798 15.1667 7.99998 15.1667Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
const Important = `<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.00002 5.1667V8.50004M8.00002 11.1734L8.00669 11.166M3.33336 12.5L2.08669 3.77537C2.06297 3.60929 2.10299 3.44039 2.19872 3.30262C2.29444 3.16485 2.43877 3.06841 2.60269 3.03271L7.71069 1.89737C7.90125 1.85501 8.09879 1.85501 8.28936 1.89737L13.3974 3.03337C13.7374 3.10871 13.9627 3.43071 13.9134 3.77604L12.6667 12.5C12.62 12.83 12.3334 14.8334 8.00002 14.8334C3.66669 14.8334 3.38002 12.83 3.33336 12.5Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
const Trash = `<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.3333 6.50004L12.0033 14.064C11.9491 14.373 11.7877 14.6528 11.5475 14.8545C11.3072 15.0562 11.0036 15.1667 10.69 15.1667H5.31C4.99636 15.1667 4.69275 15.0562 4.45255 14.8545C4.21235 14.6528 4.05093 14.373 3.99667 14.064L2.66667 6.50004M14 4.50004H10.25M10.25 4.50004V3.16671C10.25 2.81309 10.1095 2.47395 9.85948 2.2239C9.60943 1.97385 9.27029 1.83337 8.91667 1.83337H7.08333C6.72971 1.83337 6.39057 1.97385 6.14052 2.2239C5.89048 2.47395 5.75 2.81309 5.75 3.16671V4.50004M10.25 4.50004H5.75M2 4.50004H5.75" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

export const Icon: { [key: string]: string } = {
  lblhome,
  lblsearch,
  lblmodule,
  lblfavorite,
  lbldashboard,
  lblrecentactivity,
  lblworkspace,

  LeftChevron,
  RightChevron,

  Inbox,
  DefaultPage,
  Help,

  RequestsForApproval,
  SentForApproval,
  Tasks,
  FrequentlyUsed,
  Notifications,
  Messages,

  ActiveApprovals,
  Important,
  Trash
}
