import { get, post } from "@/Utils/axios";

export function requestGetReportMenu(): any {
  return get(`Reports/GetReportMenu`);
}

export function requestGetReportConfig(reportObjectno: string): any {
  return get(`Reports/GetReportConfig`, { params: { reportObjectno } });
}

export function requestLoadReport(model: any, reportObjectno: string, requiredLetterHead: boolean, exportExcel: boolean): any {
  return post(`Reports/LoadReport`, { reportObjectno, model, requiredLetterHead, exportExcel });
}