import { Icon } from 'AlignComponents';
import React, { useEffect, useState } from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Modal } from "AlignComponents";
import icons from "./icons"

interface IProps {
  defaultIcon?: string,
  onChange: (icon: string) => void,
}

const IconPicker = (props: IProps) => {
  const [icon, setIcon] = useState<any>("HelpCircle");
  const [iconSearch, setIconSearch] = useState<string>("");
  const [pickerState, togglePickerState] = useState<boolean>(false);

  useEffect(() => {
    if (props.defaultIcon) {
      setIcon(props.defaultIcon)
    }
  }, []);

  useEffect(() => {
    props.onChange(icon);
  }, [icon]);

  const handleIconSearch = (e: any) => {
    setIconSearch(e.target.value);
  }

  const openPicker = () => {
    togglePickerState(true);
  }

  const closePicker = () => {
    togglePickerState(false);
  }

  const selectIcon = (e: any) => {
    var el = e.target;

    while (el.tagName.toUpperCase() != 'BUTTON') {
      el = el.parentElement;
    }

    closePicker();
    setIcon(el.id);
  }

  var filterIcons = icons.filter((x: any) => {
    const searchSplit = iconSearch.split(" ");
    if (!iconSearch || searchSplit.filter((y: any) => x.toLowerCase().includes(y.toLowerCase())).length == searchSplit.length)
      return true;
  });

  return (
    <>
      <Modal
        modalSize='lg'
        heading="Pick Icon"
        show={pickerState}
        close={closePicker}
        bodyStyle={{height: '60vh'}}
      >
        <>
          <InputGroup>
            <InputGroup.Text>
              <Icon icon="Search" size="18" />
            </InputGroup.Text>
            <Form.Control placeholder="Search" value={iconSearch} type="text" onChange={handleIconSearch} />
          </InputGroup>
          <hr />
          <div className="icon-picker-container">
            {
              filterIcons.map((icon: any) => (
                <Button id={icon} variant="icon-picker" onClick={selectIcon} style={{width: "calc((100% - 90px) / 5)"}}>
                  <Icon icon={icon} size="18" />
                  {icon}
                </Button>
              ))
            }
          </div>
        </>
      </Modal>
      <Button variant="icon-picker" onClick={openPicker}>
        <Icon icon={icon} size="18" />
      </Button>
    </>
  )
}

export default IconPicker;