import { useAppDispatch } from "@/Hook/hooks";
import { getScreenConfig } from "@/Redux/Ducks/entryFormSlice";
import { EApprovalStatusType, setWorkflowApproval } from "@/Redux/Ducks/inboxSlice";
import { requestSetWorkflowApproval } from "@/Redux/Sagas/Requests/inbox";
import { openInputAlert, showValidationMessage } from "@/UI/BaseComponents/Alert";
import { ReactPropTypes, useEffect, useRef, useState } from "react"
import { Button } from "react-bootstrap";
import { BiErrorCircle, BiLoader } from "react-icons/bi";
import { BsCheck2Circle } from "react-icons/bs";
import { RiLoaderFill } from "react-icons/ri";

const Approval = (props: any) => {
  const dispatch = useAppDispatch();
  const approvalReasonRef = useRef<any>();
  const [documentDetails, setDocumentDetails] = useState({
    transactionno: "",
    requestType: "",
    processDate: "",
    allocationDate: ""
  });

  const [approvalResponse, setApprovalResponse] = useState({
    isError: false,
    isSuccess: false,
    message: "",
    detailMessage: ""
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const approvalprid: number = Number(queryParams.get("approvalprid"));
    const action: EApprovalStatusType = queryParams.get("action") as EApprovalStatusType;

    const transactionno = queryParams.get("transactionno") as string;
    const requestType = queryParams.get("requestType") as string;
    const processDate = queryParams.get("processDate") as string;
    const allocationDate = queryParams.get("allocationDate") as string;

    setDocumentDetails({
      transactionno,
      requestType,
      processDate,
      allocationDate
    });

    requestForApproval(action, approvalprid);

    // dispatch(setWorkflowApproval({ approvalResponse: action, approvalprid, reason: "Approved from Email", messagePrid: 0, key: "" }));
  }, []);

  const requestForApproval = async (action: EApprovalStatusType, approvalprid: number) => {

    try {
      // const res = await requestSetWorkflowApproval(action, approvalprid, "Approved from Email");

      // setApprovalResponse({
      //   isError: false,
      //   isSuccess: true,
      //   message: "Successfully Processed",
      //   detailMessage: ""
      // });
      let reason = "";

      if (action.isEqual(EApprovalStatusType.Hold, EApprovalStatusType.Reject)) {

        const reasonFieldConfig = {
          fieldid: "approvalcomment",
          fieldlabel: "Comment",
        };

        const preConfirm = () => {
          if (!approvalReasonRef.current.value) {
            showValidationMessage("Please fill the comment");
          }
          else {
            return approvalReasonRef.current.value;
          }
        }

        const res = await openInputAlert<ReturnType<typeof preConfirm>>({
          title: "Please enter the reason",
          extraOption: {
            input: undefined,
            preConfirm,
            html:
              <div className="field-container" data-fieldid={reasonFieldConfig.fieldid}>
                <label id={`lb-${reasonFieldConfig.fieldid}`} htmlFor={`field-${reasonFieldConfig.fieldid}`} >
                  {reasonFieldConfig.fieldlabel}
                  <span>*</span>
                </label>
                <textarea
                  ref={approvalReasonRef}
                  className="field-textarea"
                  rows={6} cols={35}
                  id={`field-${reasonFieldConfig.fieldid}`}
                />
              </div>
          }
        });
        if (res.cancel)
          return;

        reason = res.value;
      }

      const appRes = await requestSetWorkflowApproval(action, approvalprid, reason);
      setApprovalResponse({
        isError: false,
        isSuccess: true,
        message: "Successfully Processed",
        detailMessage: ""
      });
    }
    catch (ex: any) {
      // console.log(ex);
      // console.log(...ex);
      const errorMessage = ex?.response?.data?.errMessage || ex.message

      setApprovalResponse({
        isError: true,
        isSuccess: false,
        message: "Error While Processing for approval",
        detailMessage: errorMessage
      });
    }

  }

  const closeScreen = () => {
    window.close();
  }


  return (
    <div className="my-4">
      <div className="d-flex justify-content-center mb-3">
        <img src={require("@/Assets/Images/logo-light.png").default} style={{ width: 100 }} />
      </div>
      <div className="d-flex flex-column align-center align-items-center"
        style={{ width: "100%" }}
      >

        <div>
          <div style={{ marginBottom: 10 }}>
            <div className="fw-bold fs-15">Request type</div>
            <div>{documentDetails.requestType}</div>
          </div>
          <div style={{ marginBottom: 10 }}>
            <div className="fw-bold fs-15">Transaction no.</div>
            <div>{documentDetails.transactionno}</div>
          </div>
          <div style={{ marginBottom: 10 }}>
            <div className="fw-bold fs-15">Process Date</div>
            <div>{documentDetails.processDate}</div>
          </div>
          <div style={{ marginBottom: 10 }}>
            <div className="fw-bold fs-15">Allocation Date</div>
            <div>{documentDetails.allocationDate}</div>
          </div>
        </div>

        {
          approvalResponse.isError ?
            <div>
              <BiErrorCircle size={100} color="red" />
            </div>
            :
            approvalResponse.isSuccess ?
              <div>
                <BsCheck2Circle size={100} color="green" />
              </div>
              :
              <div>
                <RiLoaderFill size={100} style={{ animation: "2s linear infinite spinner-border" }} />
              </div>
        }

        {
          approvalResponse.isError ?
            <>
              <div className="fw-bold fs-25">
                {approvalResponse.message}

              </div>
              <div className="alert alert-danger" role="alert">
                {approvalResponse.detailMessage}
              </div>
            </>
            :
            approvalResponse.isSuccess ?
              <div className="fw-bold fs-25">
                <div>
                  {approvalResponse.message}
                </div>
                <Button variant="outline-primary" style={{ margin: "auto", padding: "5px 20px" }} onClick={closeScreen}>
                  Close
                </Button>
              </div>
              :
              <div className="fw-bold fs-25">
                Requesting
              </div>
        }

      </div>
    </div>
  )
}

export default Approval