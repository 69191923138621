import { useAppDispatch, useAppSelector } from '@/Hook/hooks';
import { getHomeUserSpecificData } from '@/Redux/Ducks/homeSlice';
import { getNotification, getUserInboxInformation } from '@/Redux/Ducks/inboxSlice';
import { getColors, getUserSpecificDetail } from '@/Redux/Ducks/userSlice';
import { useEffect } from 'react';
import { AlignUIRender } from './indexUI';

const Authorize = (props: any) => {

  const dispatch = useAppDispatch();

  const hasTabs = useAppSelector((state) => !!state.Tab.tabs.length);
  const userColors = useAppSelector((state) => state.User.colors);
  const authTokenLoaded = useAppSelector((state) => state.User.authToken);

  // useEffect(() => {
  //   if (!dataLoaded) {
  //     // dispatch(getHomeUserSpecificData());
  //   }
  // }, [dataLoaded]);

  const init = () => {
    dispatch(getColors());
  }

  useEffect(() => {
    if (!authTokenLoaded)
      dispatch(getUserSpecificDetail());

    // dispatch(getUserInboxInformation());

    // init();
  }, []);

  const setColor = () => {
    var root: any = document.querySelector(":root");

    if (root) {
      userColors.map(({ variable, color }) => {
        root.style.setProperty(`--${variable}`, color);
      })
    }
  }

  useEffect(() => {
    setColor();
  }, [userColors])

  return AlignUIRender({ ...props, hasTabs });

}

export default Authorize;

