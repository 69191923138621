
export enum EMessageType {
  Error = 1,
  Warning = 2,
  Success = 3,
  Information = 4,
}

export enum EMessageOpenAs {
  Normal = 1
}

export interface IMessageDetail {
  title: string;
  message: string;
  messageType: EMessageType;
  messageOpenAs: EMessageOpenAs;
  open: boolean;

}
export interface IMessage {
  sessionTimeoutPrompt: boolean;
  globalMessage: IMessageDetail;
  tab: Array<IMessageDetail & { tabId: string }>;
}

export interface setTabMessageParams extends IMessageDetail {
  tabId: string;
}