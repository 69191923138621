import { useAppDispatch } from '@/Hook/hooks';
import { getAccountDimensions } from '@/Redux/Ducks/entryFormSlice';
import React from 'react'
import { TbDimensions } from 'react-icons/tb';

interface IProps {
  enable: boolean;
  fieldid: string;
  value: string;
  dimensionvalue: string;
}

const FieldDimension = (props: IProps) => {

  const dispatch = useAppDispatch();

  const openAccountDimensionPopup = () => {

    let dimensionSetFieldid = props.fieldid.replace("accountno", "") + "dimensionset";

    dispatch(getAccountDimensions({ accountno: props.value, gridno: "", accountFieldId: props.fieldid, dimensionSetFieldid, isSubGrid: false, rowIndex: -1 }));
  }

  return (
    <div>
      <button
        className="field-dimension-btn ms-2"
        disabled={!props.enable}
        onClick={openAccountDimensionPopup}
        tabIndex={-1}
      >
        <TbDimensions
          color="#575757"
          size={16}
        />
      </button>
      <span className="fw-bold ms-2 font-monospace field-dimension-value">{props.dimensionvalue}</span>
    </div>
  );
}

export default FieldDimension