import { useAppDispatch } from "@/Hook/hooks";
import { getScreenConfig } from "@/Redux/Ducks/entryFormSlice";
import { ReactPropTypes, useEffect } from "react"

const OpenScreen = (props: any) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const { processCode, transactionNo } = props.match.params;
    dispatch(getScreenConfig({processCode, transactionNo}))
  }, [])
  

  return (
    <div>Redirecting</div>
  )
}

export default OpenScreen