import { useEffect, useRef } from 'react';
import { ToastContainer } from 'react-toastify';
import { openInputAlert } from '../Alert';

import { useAppDispatch, useAppSelector } from '@/Hook/hooks';

import { closeSessionTimeoutPrompt } from '@/Redux/Ducks/messageSlice';
import { logoutUser } from '@/Redux/Ducks/userSlice';
import { useHistory } from 'react-router';
import "react-toastify/dist/ReactToastify.css";

const Message = () => {

  const history = useHistory();
  const dispatch = useAppDispatch();
  const promptOpen = useRef(false);
  const sessionTimeoutPrompt = useAppSelector(x => x.Message.sessionTimeoutPrompt);

  useEffect(() => {
    if (sessionTimeoutPrompt && promptOpen.current == false) {
      promptOpen.current = true;
      (async () => {
        var res = await openInputAlert({
          title: "Session Expired",
          icon: "warning",
          extraOption: {
            text: "Your Session has Expired, Please re-login",
            input: undefined,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: "Re-Login",
            confirmButtonAriaLabel: 'Login',
          }
        });

        if (res.confirm) {
          dispatch(closeSessionTimeoutPrompt());
          promptOpen.current = false;
          dispatch(logoutUser());
        }


      })()

    }
  }, [sessionTimeoutPrompt]);

  return (
    <>
      {/* <button onClick={() => toast.dismiss(tabRef)}>Dismiss</button> */}
      <ToastContainer
        position="bottom-right"
        // autoClose={true}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        theme="light"
      // transition={}
      />
    </>
  );
}

export default Message;
