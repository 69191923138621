import { ArrayKeyValueObject } from "@/UI/Interfaces/IBaseUI";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import produce from "immer";
import { ComponentType, LazyExoticComponent } from "react";
import { IWidget } from "./dashboardSlice";

export interface ILayout {
  layout: string;
  insightarea: string;
  widgetgroupno: string;
}

interface IHome {
  dataLoaded: boolean,
  userObjects: Array<IUserMenu>,
  userObjectsLoading: boolean,
  entryFormObjects: Array<IEntryFormMenu>,
  entryFormObjectsLoading: boolean,
  dataAnalyticsObjects: Array<IDataAnalyticsMenu>,
  dataAnalyticsObjectsLoading: boolean,
  reportObjects: Array<IReportsMenu>,
  reportObjectsLoading: boolean,
  announcements: Array<{
    banner: string
  }>;
  userShortWidget: {
    taskCount: number,
    notificationCount: number,
    requestToApproveCount: number,
    requestSentForApproveCount: number,
  }
  widgets: IWidget;
  layout: Array<ILayout>;
}
interface IUserTodayActivity {
  objectcontrollerstxt: string;
  objectcontrollerno: string;
  applicationobjecttype: string;
  objectactivityno: string;
  activitydatetime: string;
  parameterdocvalue: string;
  parameterprid: number;
}

interface IUserMenu {
  moduleno: string;
  modulestxt: string;
  groupindex: number;
  groupstxt: string;
  objectcontrollerno: string;
  objectcontrollerstxt: string;
  processcodeindex: number;
  objectcategorystxt: string;
  applicationobjecttype: string;
  isfavorite: boolean;
}

interface IEntryFormMenu {
  moduleno: string;
  modulestxt: string;
  groupindex: number;
  groupstxt: string;
  objectcontrollerno: string;
  objectcontrollerstxt: string;
  processcodeindex: number;
  objectcategorystxt: string;
}
interface IDataAnalyticsMenu {
  moduleno: string;
  modulestxt: string;
  objectcontrollerno: string;
  objectcontrollerstxt: string;
  objectcategorystxt: string;
}
interface IReportsMenu {
  moduleno: string;
  modulestxt: string;
  objectcontrollerno: string;
  objectcontrollerstxt: string;
  objectcategorystxt: string;
}

const InitialState: IHome = {
  userObjects: [],
  userObjectsLoading: false,
  entryFormObjects: [],
  entryFormObjectsLoading: false,
  dataAnalyticsObjects: [],
  dataAnalyticsObjectsLoading: false,
  reportObjects: [],
  reportObjectsLoading: false,
  announcements: [],
  widgets: {},
  layout: [],
  userShortWidget: {
    notificationCount: 0,
    requestSentForApproveCount: 0,
    requestToApproveCount: 0,
    taskCount: 0
  },
  dataLoaded: false
};

const HomeSlice = createSlice({
  name: "home",
  initialState: InitialState,
  reducers: {
    getHomeUserSpecificData() { },
    setUserInsightsHome(state, action: PayloadAction<{ widgetList: IWidget, layout: Array<ILayout> }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        draftState.widgets = payload.widgetList;
        draftState.layout = payload.layout;
      });

    },
    setHomeUserSpecificData(state, action: PayloadAction<{ userTodayActivity: Array<IUserTodayActivity>, announcements: Array<any>, useraction: Array<any> }>) {
      const payload = action.payload;
      return produce(state, (draftState) => {
        draftState.announcements = payload.announcements;
        draftState.userShortWidget = {
          notificationCount: payload.useraction?.[0].unreadnotification || 0,
          requestSentForApproveCount: payload.useraction?.[0].sendforapprove || 0,
          requestToApproveCount: payload.useraction?.[0].requesttoapprove || 0,
          taskCount: payload.useraction?.[0].activetask || 0
        };
        draftState.dataLoaded = true;
      });

    },

    getUserObjects(state) {
      return produce(state, (draftState) => {
        draftState.entryFormObjectsLoading = true;
      });
    },
    setUserObjects(state, action: PayloadAction<{ userObjects: Array<IUserMenu> }>) {
      const payload = action.payload;
      return produce(state, (draftState) => {
        draftState.userObjectsLoading = false;
        draftState.userObjects = payload.userObjects;
      });
    },

    getUserEntryForms(state) {
      return produce(state, (draftState) => {
        draftState.entryFormObjectsLoading = true;
      });
    },
    setUserEntryForms(state, action: PayloadAction<{ userEntryForms: Array<IEntryFormMenu> }>) {
      const payload = action.payload;
      return produce(state, (draftState) => {
        draftState.entryFormObjectsLoading = false;
        draftState.entryFormObjects = payload.userEntryForms;
      });
    },

    getUserDataAnalytics(state) {
      return produce(state, (draftState) => {
        draftState.dataAnalyticsObjectsLoading = true;
      });
    },
    setUserDataAnalytics(state, action: PayloadAction<{ userDataAnalytics: Array<IDataAnalyticsMenu> }>) {
      const payload = action.payload;
      return produce(state, (draftState) => {
        draftState.dataAnalyticsObjectsLoading = false;
        draftState.dataAnalyticsObjects = payload.userDataAnalytics;
      });
    },

    getUserReport(state) {
      return produce(state, (draftState) => {
        draftState.reportObjectsLoading = true;
      });
    },
    setUserReport(state, action: PayloadAction<{ userReport: Array<IReportsMenu> }>) {
      const payload = action.payload;
      return produce(state, (draftState) => {
        draftState.reportObjectsLoading = false;
        draftState.reportObjects = payload.userReport;
      });
    },

    refreshHomeData(state) {
      return produce(state, (draftState) => {
        draftState.dataLoaded = false;
      })
    },

    setUserFavorite(state, action: PayloadAction<{ objectcontrollerno: string, objectcontrollerstxt: string, applicationobjecttype: string }>) {
      const payload = action.payload;

      return produce(state, (draftState) => {
        draftState.userObjects = draftState.userObjects.map(x => {
          if (x.objectcontrollerno == payload.objectcontrollerno) {
            x.isfavorite = !x.isfavorite
          }

          return x;
        });
      })
    }
  },
});

export const { getUserObjects, setUserObjects, getUserEntryForms, setUserEntryForms, getUserDataAnalytics, setUserDataAnalytics, getUserReport, setUserReport, setUserInsightsHome, getHomeUserSpecificData, setHomeUserSpecificData, refreshHomeData, setUserFavorite } = HomeSlice.actions;

export default HomeSlice.reducer;
