import { call, delay, put, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { addNotification, EApprovalStatusType, setUserInboxInformation } from "@/Redux/Ducks/inboxSlice";
import { push } from "connected-react-router";
import { RootState } from "@/Redux/configureStore";
import { setGlobalMessage } from "@/Redux/Ducks/messageSlice";
import { requestGetActiveApprovals, requestGetApproval, requestGetNotifications, requestGetOutbox, requestGetImportant, requestGetUserInboxInformation, requestSetMessageRead, requestSetWorkflowApproval, requestMarkImportant, requestMarkTrash, requestGetTrash, requestGetOutboxApprovals, requestGetOutboxNotifications } from "../Requests/inbox";

export function* handleGetNotification({ payload }: PayloadAction<{ key: string, reload: boolean, searchParam: string }>): any {
  try {
    const { page, lastprid } = yield select((state: RootState) => ({
      page: payload.reload ? 0 : state.Inbox.messages[payload.key].page,
      lastprid: payload.reload ? 0 : (state.Inbox.messages[payload.key].data[state.Inbox.messages[payload.key].data.length - 1]?.prid || 0),
    }));

    let response = [];
    if (payload.key == "/approval") {
      response = yield requestGetApproval(page, lastprid, payload.searchParam);
    }
    else if (payload.key == "/approval/active") { // Temporary
      response = yield requestGetActiveApprovals(page, lastprid, payload.searchParam);
    }
    else if (payload.key == "/notification") { // Temporary
      response = yield requestGetNotifications(page, lastprid, payload.searchParam);
    }
    else if (payload.key == "/outbox") {
      response = yield requestGetOutbox(page, lastprid, payload.searchParam);
    }
    else if (payload.key == "/outbox/approval") {
      response = yield requestGetOutboxApprovals(page, lastprid, payload.searchParam);
    }
    else if (payload.key == "/outbox/notification") {
      response = yield requestGetOutboxNotifications(page, lastprid, payload.searchParam);
    }
    else if (payload.key == "/important") {
      response = yield requestGetImportant(page, lastprid, payload.searchParam);
    }
    else if (payload.key == "/trash") {
      response = yield requestGetTrash(page, lastprid, payload.searchParam);
    }

    response = response.map((x: any) => {
      if (x.parameters)
        x.parameters = JSON.parse(x.parameters);
      else
        x.parameters = null;
      return x;
    });

    yield put(addNotification({ data: response, key: payload.key, reload: payload.reload }));
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }

}

export function* handleSetWorkflowApproval({ payload }: PayloadAction<{ approvalResponse: EApprovalStatusType, reason: string, approvalprid: number }>): any {
  try {
    yield requestSetWorkflowApproval(payload.approvalResponse, payload.approvalprid, payload.reason);
    yield put(setGlobalMessage({ message: "Done!" }));
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
}

export function* handleGetUserInboxInformation(): any {
  try {
    const res = yield requestGetUserInboxInformation();
    yield put(setUserInboxInformation(res));
  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }
}

export function* handleSetMessageRead({ payload }: PayloadAction<{ isRead: boolean, messagePrid: number }>): any {

  try {

    yield requestSetMessageRead(payload.isRead, payload.messagePrid);

  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }

}

export function* handleMarkImportant({ payload }: PayloadAction<{ messagePrid: number }>): any {

  try {

    yield requestMarkImportant(payload.messagePrid);

  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }

}

export function* handleMarkTrash({ payload }: PayloadAction<{ messagePrid: number }>): any {

  try {

    yield requestMarkTrash(payload.messagePrid);

  }
  catch (error: any) {
    yield put(setGlobalMessage({ error }));
  }

}