import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import "@grapecity/wijmo.styles/wijmo.css";
import { IGrid, IGridObject, IGridRef, IWijmoGridHandle } from "@/UI/Interfaces/IGrid";
import { RootState } from "@/Redux/configureStore";
import { setGridInitialModel, addGridNewRow, changeGridRow, deleteGridRow, setGridRowsState, setGridRowStateByStartingRow, removeGridRowError, upsertDimension, addGridRowDimensionSet, deleteGridRowDimensionSet, fillDimensionFromDimensionSet, deleteGridLineAttachment } from "@/Redux/Ducks/entryFormSlice";
import WijmoGrid from "../WijmoGrid";
import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import { ArrayKeyValueObject, EActivity, KeyValueObject } from "@/UI/Interfaces/IBaseUI";
import BaseMethods from "AlignBaseUI/BaseMethods";
import { closeHelp, EHelpOpenFor, EHelpType, IReturnParam, IReturnValues } from "@/Redux/Ducks/helpSlice";
import { dimensionSetFields } from "@/Utils/constants";

// import {Alert} from "../Alert/index"

const AlignGrid = forwardRef<IGridRef, IGridProps>((props, ref) => {

  const dispatch = useAppDispatch();
  const wijmoRef = useRef();
  const string = '';
  const ownRef = useRef<IWijmoGridHandle>(null);
  const [gridConfig, setGridConfig] = useState<IGrid>();
  const collectionName = props.gridno.getCollectionNameFromGridno();

  const gridDimensionObject = useAppSelector((state: RootState) => state.EntryForm.objects[state.EntryForm.activeObjectId].dimension.filter(x => x.dimensionRelations.findIndex(y => y.split("-").length == 3 && y.includes(props.gridno)) != -1));
  // const gridDataProper = useAppSelector((state: RootState) => {
  //   const gridModel = state.EntryForm.objects[state.EntryForm.activeObjectId].model[collectionName];
  //   const dateColumnConfig = gridConfig?.Header.filter((y) => BaseMethods.getControlType(y.controltypeno).isEqual("date", "time")) || [];

  //   return (gridModel || []).map((x: any) => {
  //     const data: any = {};
  //     dateColumnConfig.map(y => {
  //       if (x[y.fieldid])
  //         data[y.fieldid] = new Date(x[y.fieldid]);
  //     });

  //     return { ...x, ...data };
  //   })
  // });

  // const dateColumnConfig = gridConfig?.Header.filter((y) => BaseMethods.getControlType(y.controltypeno).isEqual("date", "time")) || [];
  const gridDataFromState = useAppSelector((state: RootState) => state.EntryForm.objects[state.EntryForm.activeObjectId].model[collectionName]);
  const gridDataProper = gridDataFromState || [];
  // const gridDataProper = (gridDataFromState || []).map((x: any) => {
  //   const data: any = {};
  //   dateColumnConfig.map(y => {
  //     if (x[y.fieldid])
  //       data[y.fieldid] = new Date(x[y.fieldid]);
  //   });

  //   return { ...x, ...data };
  // });

  const gridOptions = useAppSelector((state: RootState) => state.EntryForm.objects[state.EntryForm.activeObjectId].gridOptions[props.gridno]);
  const activity = useAppSelector(state => state.EntryForm.objects[state.EntryForm.activeObjectId].activity);

  const isReadonly = activity == EActivity.View || props.isReadOnly || false;
  const allowAddNewRow = isReadonly ? false : (props.allowAddNewRow != undefined ? props.allowAddNewRow : true);
  const allowDeleteRow = isReadonly ? false : (props.allowDeleteRow != undefined ? props.allowDeleteRow : true);
  const allowCopyRow = isReadonly ? false : (props.allowCopyRow != undefined ? props.allowCopyRow : true);

  const gridDataRef = useRef<any>();
  gridDataRef.current = gridDataProper;

  const activeTabId = useAppSelector(state => state.Tab.activeTabKey);

  const helpSelected = useAppSelector(state => {
    const returnOption = state.Help[state.Tab.activeTabKey]?.returnOption;
    const loadedHelp = state.Help[state.Tab.activeTabKey]?.loadedHelp;

    // if (help && !help.isOpen && help.helpOpenFor == EHelpOpenFor.Grid && help.helpType.isEqual(EHelpType.SideHelp, EHelpType.ShortHelp)) {
    if (returnOption && returnOption.returnParam.gridno) {
      if (returnOption.returnParam.gridno == props.gridno && returnOption.returnValues && returnOption.returnValues.length) {
        return {
          helpObject: loadedHelp?.helpConfig.Config.helpobjectno as string,
          ...returnOption
        };
      }
    }

    return null;
  });

  useEffect(() => {
    if (gridDataFromState === undefined) {
      dispatch(setGridInitialModel({ gridno: props.gridno }));
    }
    if (gridConfig === undefined) {
      const config = props.config.GridConfig.find((x) => x.Config.gridno == props.gridno);
      if (config) {
        setGridConfig(config);
      }
    }
  }, [gridDataFromState]);

  useEffect(() => {

    if (helpSelected) {
      const helpReturnValues = helpSelected.returnValues as Array<Array<IReturnValues>>;
      const rowIndex = helpSelected.returnParam.gridRowIndex as number;
      const isDimensionHelp = helpSelected.returnParam["isDimension" as keyof IReturnParam];
      // when line information dimension help select
      if (isDimensionHelp) {
        updateDimensionValue(helpReturnValues[0][0].fieldId, helpReturnValues[0][0].fieldValue, rowIndex);
      }
      else {
        Promise.all(helpReturnValues.map(async (x, i) => {
          await Promise.all(x.map(async (y) => {
            if (y.fieldId) {  
              if (y.fieldId.isEqual(...dimensionSetFields)) {
                if (y.dimensionValues)
                  dispatch(upsertDimension({ dimensionValues: y.dimensionValues, dimensionSetFieldid: y.fieldId, dimensionSetting: { gridno: props.gridno, rowIndex: (rowIndex + i) } }));
              }
              else {
                const preventValidateHelp = helpSelected.returnParam.fieldId == y.fieldId;
                await (ownRef.current?.setCellData((rowIndex + i), y.fieldId, y.fieldValue, true, preventValidateHelp));
              }
            }
          }));
        }));
      }

      dispatch(closeHelp({ objectId: activeTabId }));

      if (!isDimensionHelp) {
        const helpReturnArrayObject = helpReturnValues.map(x => {
          const obj = {} as KeyValueObject;
          x.map(y => {
            obj[y.fieldId] = y.fieldValue;
          });
          return obj;
        });

        props.config.BaseGridAfterHelpSelected && props.config.BaseGridAfterHelpSelected(props.gridno, helpSelected.returnParam.fieldId, rowIndex, helpSelected.helpObject, helpReturnArrayObject);
      }
    }

  }, [helpSelected]);

  useImperativeHandle(ref, () => ({
    ownRef,
    wijmoRef,
    setGridDataByStartingRow,
    setGridData
  }));

  const addNewRow = (newItem: any, rowIndex: number, clone: boolean, addOnLastLine: boolean) => {
    dispatch(addGridNewRow({
      gridno: props.gridno,
      data: newItem,
      rowIndex: rowIndex
    }));

    if (!addOnLastLine)
      addNewDimensionInternal(rowIndex, clone);
  }

  const deleteRow = async (oldItem: any, rowIndex: number) => {
    var evt: any = {
      cancel: false
    };

    await (props.config.BaseGridBeforeDeleteRow && props.config.BaseGridBeforeDeleteRow(props.gridno, rowIndex, oldItem, evt));

    if (!evt.cancel) {
      dispatch(removeGridRowError({ gridno: props.gridno, rowIndex: rowIndex }));

      dispatch(deleteGridRow({
        gridno: props.gridno,
        rowIndex: rowIndex
      }));

      deleteGridDimensionRelationInternal(rowIndex);

      dispatch(deleteGridLineAttachment({ gridno: props.gridno, lineId: oldItem?.lineid }));
    }
  }

  const changeRow = (newItem: any, rowIndex: number) => {
    dispatch(changeGridRow({
      gridno: props.gridno,
      newItem,
      rowIndex
    }));
  }

  const HyperLinkClick = (columnId: string, rowIndex: number) => {
    const gridRow = {
      ...(gridDataRef.current[rowIndex] || {})
    }
    props.config.BaseGridHyperLinkClick && props.config.BaseGridHyperLinkClick(props.gridno, columnId, rowIndex, gridRow);
  }

  const beforeCellChanged = (columnId: string, rowIndex: number, oldValue: any, newValue: any, gridRow: KeyValueObject, ev: KeyValueObject) => {
    (props.config.BaseGridBeforeCellChanged && props.config.BaseGridBeforeCellChanged(props.gridno, columnId, rowIndex, oldValue, newValue, false, gridRow, ev));
  }

  const cellChanged = async (columnId: string, rowIndex: number, oldValue: any, newValue: any, gridRow: KeyValueObject, helpSelected: boolean, preventValidateHelp: boolean, preventCellChange: boolean) => {
    // const gridRow = {
    //   ...(gridDataRef.current[rowIndex] || {}),
    //   [columnId]: newValue
    // }
    await (props.config.BaseGridCellChanged && props.config.BaseGridCellChanged(props.gridno, columnId, rowIndex, oldValue, newValue, helpSelected, preventValidateHelp, preventCellChange, false, gridRow));
  }

  const cellBeginningEdit = (columnId: string, rowIndex: number, value: any, event: any) => {
    const gridRow = {
      ...(gridDataRef.current[rowIndex] || {}),
      [columnId]: value
    }
    props.config.BaseGridCellBeginningEdit && props.config.BaseGridCellBeginningEdit(props.gridno, columnId, rowIndex, value, gridRow, event);
  }

  const setGridDataByStartingRow = (startingRow: number, gridRows: ArrayKeyValueObject) => {

    // gridRows.map((x, i) => {
    //   gridConfig?.Header.map(y => {
    //     if (BaseMethods.getControlType(y.controltypeno) == "date") {
    //       if (x[y.fieldid] instanceof Date)
    //         x[y.fieldid] = x[y.fieldid].toJSON();
    //     }
    //     else if (BaseMethods.getControlType(y.controltypeno) == "text") {
    //       if (!x[y.fieldid])
    //         x[y.fieldid] = null;
    //     }
    //     x["lineid"] = startingRow + i + 1;
    //   });
    // });

    gridRows.map((x, i) => {
      dimensionSetFields.map(y => {
        if (x[y])
          dispatch(fillDimensionFromDimensionSet({ dimensionSet: x[y], dimensionSetFieldid: y, dimensionSetting: { gridno: props.gridno, rowIndex: (startingRow + i) } }));
      });
    });

    dispatch(setGridRowStateByStartingRow({ gridno: props.gridno, gridRows: JSON.parse(JSON.stringify(gridRows)), startingRow }));
  }

  const setGridData = (gridRows: ArrayKeyValueObject) => {

    // gridRows.map((x, i) => {
    //   gridConfig?.Header.map(y => {
    //     if (BaseMethods.getControlType(y.controltypeno) == "date") {
    //       if (x[y.fieldid] instanceof Date)
    //         x[y.fieldid] = x[y.fieldid].toJSON();
    //     }
    //     else if (BaseMethods.getControlType(y.controltypeno) == "text") {
    //       if (!x[y.fieldid])
    //         x[y.fieldid] = null;
    //     }
    //     x["lineid"] = i + 1;
    //   });
    // });

    gridRows.map((x, i) => {
      dimensionSetFields.map(y => {
        if (x[y])
          dispatch(fillDimensionFromDimensionSet({ dimensionSet: x[y], dimensionSetFieldid: y, dimensionSetting: { gridno: props.gridno, rowIndex: i } }));
      });
    });

    dispatch(setGridRowsState({ gridno: props.gridno, gridRows: JSON.parse(JSON.stringify(gridRows)) }));

    gridRows.map((x, i) => {
      const keys = Object.keys(x);

      const dimensionValues: KeyValueObject = {};
      keys.map(y => {
        if (y.startsWith("dimensionset/")) {
          dimensionValues[y.replace("dimensionset/", "")] = x[y];
        }
      });

      dispatch(upsertDimension({ dimensionValues: dimensionValues, dimensionSetting: { gridno: props.gridno, rowIndex: i }, replace: true }));

    });

  }

  const rowSelectionChanged = (rowIndex: number) => {
    const gridRow = {
      ...(gridDataRef.current[rowIndex] || {}),
    };
    props.config.BaseRowSelectionChanged && props.config.BaseRowSelectionChanged(props.gridno, rowIndex, gridRow);
  }

  const updateDimensionValue = (columnId: string, value: any, rowIndex: number) => {
    dispatch(upsertDimension({ dimensionValues: { [columnId]: value }, dimensionSetting: { gridno: props.gridno, rowIndex } }));
  }

  const addNewDimensionInternal = (rowIndex: number, clone: boolean) => {
    rowIndex = clone ? rowIndex - 1 : rowIndex;
    dispatch(addGridRowDimensionSet({ gridno: props.gridno, rowIndex, clone }));
  }

  const deleteGridDimensionRelationInternal = (rowIndex: number) => {
    dispatch(deleteGridRowDimensionSet({ gridno: props.gridno, rowIndex }));
  }

  const toolbarClicked = (toolbarKey: string, gridno: string, rowIndex: number) => {
    props.config.BaseToolbarClicked && props.config.BaseToolbarClicked(toolbarKey, { gridno, rowIndex });
  }


  if (gridConfig)
    return (
      <WijmoGrid
        ref={ownRef}
        wijmoRef={wijmoRef}
        gridConfig={gridConfig}
        gridData={gridDataProper}
        gridOptions={gridOptions}
        wijmoAddGridNewRow={addNewRow}
        wijmoDeleteGridRow={deleteRow}
        wijmoChangeGridRow={changeRow}
        wijmoBeforeCellChanged={beforeCellChanged}
        wijmoCellChanged={cellChanged}
        wijmoCellBeginningEdit={cellBeginningEdit}
        wijmoHyperLinkClick={HyperLinkClick}
        wijmoSetGridData={setGridData}
        wijmoRowSelectionChanged={rowSelectionChanged}
        wijmoToolbarClicked={toolbarClicked}
        wijmoGridInitialized={props.config.BaseGridInitialized}

        wijmoUpdateDimensionValue={updateDimensionValue}
        // wijmoAddNewDimension={addNewDimensionInternal}
        // wijmoUpdateDimension={updateGridDimensionIndexInternal}
        // wijmoDeleteDimension={deleteGridDimensionRelationInternal}
        wijmoGridDimensionObject={gridDimensionObject}
        isReadOnly={isReadonly}
        allowAddNewRow={allowAddNewRow}
        allowDeleteRow={allowDeleteRow}
        allowCopyRow={allowCopyRow}
      />
    );
  else
    return null;

})

export default (props: IGridProps) => <AlignGrid {...props} ref={props.config.ref[props.gridno]} />


interface IGridProps {
  config: IGridObject;
  gridno: string;
  isReadOnly?: boolean
  allowAddNewRow?: boolean
  allowDeleteRow?: boolean
  allowCopyRow?: boolean
}


