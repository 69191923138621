import { Card, Table as BsTable, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiLinkExternal } from "react-icons/bi";
import { GiMove } from "react-icons/gi";
// @ts-ignore
import Fade from 'react-reveal/Fade';

interface IProps {
	detail: Array<{ [key: string]: any }>,
	controlid: string,
	widgetlabel: string,
	widgetprocesscode: string,
	routeToScreen: any
}

const Table = (props: IProps) => {
	return (
		<div className="chart-card">
			<Fade>
				<Card>
					<Card.Header>
						{props.widgetlabel}

						{
							props.widgetprocesscode ? (
								<OverlayTrigger placement="bottom" overlay={<Tooltip>Hyperlink</Tooltip>}>
									<div className="hyperlink" onClick={() => { props.routeToScreen(props.widgetprocesscode) }}>
										<BiLinkExternal size={16} />
									</div>
								</OverlayTrigger>
							) : null
						}
						<OverlayTrigger placement="bottom" overlay={<Tooltip>Move</Tooltip>}>
							<div className="drag-handle">
								<GiMove size={16} />
							</div>
						</OverlayTrigger>

					</Card.Header>
					<Card.Body>
						{
							props.detail.length ?
								<BsTable hover>
									<thead>
										<tr>
											{
												Object.keys(props.detail[0]).map((colid: string) => (
													<th>{colid}</th>
												))
											}
										</tr>
									</thead>
									<tbody>
										{
											props.detail.map((row: any) => (
												<tr>
													{
														Object.values(row).map((value: any) => (
															<td>
																{value}
															</td>
														))
													}
												</tr>
											))
										}
									</tbody>
								</BsTable>
								: null
						}
					</Card.Body>
				</Card>
			</Fade>
		</div>
	)
}

export default Table;