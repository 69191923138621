import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import { refreshHomeData } from "@/Redux/Ducks/homeSlice";
import { toggleHidden } from "@/Redux/Ducks/userSlice";
import { AlignUIRender } from "./indexUI";

const Banner = () => {
  const dispatch = useAppDispatch();

  //#region selector
  const userSettingObject = useAppSelector((state) => ({
    companyBannerImage: state.User.companyBannerImage,
    clientLogo: state.User.UserLegalEntity.find(x => x.legalentityno == state.User.selectedLegalEntity)?.logoimage || '',
    username: state.User.user.name?.split(' ').map(x => x.charAt(0).toUpperCase() + x.slice(1)).join(' ') || '',
    legalEntityName: state.User.UserLegalEntity.find(x => x.legalentityno == state.User.selectedLegalEntity)?.legalentitystxt || ''
  }));

  //#endregion

  const refreshData = () => {
    dispatch(refreshHomeData())
  }

  const toggleHiddenFunc = () => {
    dispatch(toggleHidden("banner"))
  }

  return AlignUIRender({ userSettingObject, refreshData, toggleHiddenFunc });
};

export default Banner;
