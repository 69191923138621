import classNames from 'classnames';
import { CSSProperties, useEffect } from 'react';
import { Modal as BsModal } from 'react-bootstrap'
import ReactDOM from 'react-dom';

const Modal = (props: {
  children: JSX.Element,
  footer?: JSX.Element,
  show: boolean,
  notCentered?: boolean,
  heading: string | JSX.Element,
  close: () => void,
  className?: string,
  // modalStyle?: CSSProperties,
  bodyStyle?: CSSProperties,
  headerStyle?: CSSProperties,
  backdropStyle?: CSSProperties,
  modalDialogStyle?: CSSProperties,
  modalSize?: 'sm' | 'lg' | 'xl' | 'md'
}) => {

  useEffect(() => {
    const modalOpen = document.querySelectorAll("body > .modal");
    if (props.show) {
      var scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    }
    else {
      if (modalOpen.length == 0) {
        document.body.style.overflow = "";
        document.body.style.paddingRight = "";
      }
    }

  }, [props.show]);

  const closeModal = (e: any) => {
    if (e.currentTarget.querySelector(".wj-flexgrid")) {
      if (document.querySelector(".wj-dropdown-panel"))
        return;
    }
    props.close();
  }

  if (props.show) {
    return ReactDOM.createPortal(
      <>
        <div className="fade modal-backdrop show" style={props.backdropStyle}></div>
        <div className={classNames([props.className, "fade modal show"])} aria-hidden="true" style={{ paddingRight: 8, display: "block" }} onClick={closeModal}>
          <div className={classNames([`modal-dialog modal-${props.modalSize}`, { ["modal-dialog-centered"]: !props.notCentered }])} style={props.modalDialogStyle} onClick={(e) => { e.stopPropagation() }}>
            <div className="modal-content">
              <div className="modal-header" style={props.headerStyle}>
                {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
                <h6 className="fw-bold m-0">{props.heading}</h6>
                <button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
              </div>
              <div className="modal-body" style={{ minHeight: "200px", ...props.bodyStyle }}>
                {props.children}
              </div>
              {
                props.footer ?
                  <div className="modal-footer">
                    {props.footer}
                  </div>
                  : null
              }
            </div>
          </div>
        </div>
      </>,
      document.body);
  }

  return null;

  // return (
  //   <BsModal show={props.show} onHide={props.close} size={props.modalSize || "lg"} style={props.modalStyle} centered scrollable>
  //     <BsModal.Header closeButton>
  //       <h6 className="fw-bold m-0">{props.heading}</h6>
  //     </BsModal.Header>
  //     <BsModal.Body style={{ minHeight: "200px", ...props.bodyStyle }}>
  //       {props.children}
  //     </BsModal.Body>
  //     {
  //       props.footer ?
  //         <BsModal.Footer>
  //           {props.footer}
  //         </BsModal.Footer>
  //         : null
  //     }
  //   </BsModal>
  // );

}

export default Modal;
