import { useAppDispatch, useAppSelector } from '@/Hook/hooks';
import { reverseDocument, toggleReverseModal } from '@/Redux/Ducks/entryFormSlice';
import { IFieldObject } from '@/UI/Interfaces/IFieldConfig';
import { AlignInput } from 'AlignComponents';
import classNames from 'classnames';
import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { IoMdClose, IoMdRefresh, IoMdSave } from 'react-icons/io';

const ReversePopup = (props: { Fields: IFieldObject, BaseReverse: VoidFunction }) => {
  // const activeObjectId = useAppSelector(state => state.EntryForm.activeObjectId)
  const isReversePopupOpen = useAppSelector(state => state.EntryForm.objects[state.EntryForm.activeObjectId].otherState.isReversePopupOpen);
  // const objectDetail = useAppSelector(state => ({
  //   model: state.EntryForm.objects[state.EntryForm.activeObjectId].model,
  //   processcode: state.EntryForm.objects[state.EntryForm.activeObjectId].ScreenConfig.TcodeConfiguration.processcode
  // }));
  const dispatch = useAppDispatch();

  const BaseReverse = () => {
    props.BaseReverse();
  }

  return (
    <Modal id="reverse-modal" show={isReversePopupOpen} onHide={() => { dispatch(toggleReverseModal(false)) }} centered size="lg">
      <Modal.Header closeButton>
        <h6 className="fw-bold m-0">Reverse</h6>
      </Modal.Header>
      <Modal.Body style={{ minHeight: "500px" }}>
        <div className="row">
          <div className="col-12">
            <AlignInput fieldId="reversedate" config={props.Fields} customFieldEnable={true} />
            <AlignInput fieldId="reversereasonno" config={props.Fields} customFieldEnable={true} customFieldHelpObject="HELP_OSRO_Reverse" />
            <AlignInput fieldId="reverseremarks" config={props.Fields} customFieldEnable={true} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => { dispatch(toggleReverseModal(false)) }}>
          <IoMdClose className="me-2" size="16" />
          Close
        </Button>
        <Button variant="danger" onClick={BaseReverse}>
          <IoMdRefresh className="me-2" size="16" />
          Reverse
        </Button>
      </Modal.Footer>
    </Modal>
  );

}

export default ReversePopup;
