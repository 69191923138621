import { Globalize } from "@grapecity/wijmo";
import { KeyValueObject } from "../Interfaces/IBaseUI";


// -------------------------------------------String to Specific ID

// Unique Id Generator
let UIGenerator: any = {};
UIGenerator.lz = function (i: any, c: any) {
  if (typeof c != 'number' || c <= 0 || (typeof i != 'number' && typeof i != 'string')) { return i; }
  i += '';

  while (i.length < c) { i = '0' + i; }
  return i;
}


UIGenerator.getHashCode = function (s: any) {
  var hash = 0, c = (typeof s == 'string') ? s.length : 0, i = 0;
  while (i < c) {
    hash = ((hash << 5) - hash) + s.charCodeAt(i++);
    //hash = hash & hash; // Convert to 32bit integer
  }

  return (hash < 0) ? ((hash * -1) + 0xFFFFFFFF) : hash; // convert to unsigned
};

UIGenerator.uniqueId = function (s: any, bres: any) {
  if (s == undefined || typeof s != 'string') {
    if (!UIGenerator.___uqidc) { UIGenerator.___uqidc = 0; }
    else { ++UIGenerator.___uqidc; }
    var od = new Date(),
      i: any = s = od.getTime() + '' + UIGenerator.___uqidc;
  }
  else { var i = UIGenerator.getHashCode(s); }
  return ((bres) ? 'res:' : '') + i.toString(32) + '-' + UIGenerator.lz((s.length * 4).toString(16), 3);
};

const convertUTCDateToLocal = (date: string) => {
  return new Date(date + "z");
}

const convertDateToCompanyFormat = (date: string, companyFormat: string) => {
  return Globalize.format(new Date(date), companyFormat);
}

const clearDefaultColumns = (rowData: KeyValueObject) => {
  rowData.prid = 0;
  rowData.revision = 0;
  rowData.readytoprocess = 0;
  rowData.documentcompleted = 0;
  rowData.processapprovalno = null;
  rowData.approvalhigheststatus = null;
  if (rowData.frid != undefined)
    rowData.frid = 0;

  Object.values(rowData).map(x => {
    if (Array.isArray(x)) {
      x.map(y => {
        clearDefaultColumns(y);
      });
    }
  });

}

const getControlType = (type: string) => {
  switch (type) {
    case "DTE":
      return "date";
    case "TME":
    case "T24":
      return "time";
    case "CHK":
      return "checkbox";
    case "RTB":
      return "richtextbox";
    case "COL":
      return "colorpicker";
    case "AMT":
    case "PRI":
    case "PER":
    case "QTY":
    case "NUM":
      return "number";
    case "IMG":
      return "image";
    default:
      return "text";
  }
}

const getUserPhoto = (_userphoto: string | undefined) => {
  var userphoto;

  if (_userphoto) {
    userphoto = `data:image/jpeg;base64,${_userphoto}`;
  }
  else {
    userphoto = require("@/Assets/Images/user.png").default;
  }

  return userphoto;
}

const getDatesDifference = (_datefrom: Date, _dateto: Date) => {
  var datefrom = new Date(_datefrom);
  var dateto = new Date(_dateto);
  let diffInMilliSeconds = Math.abs(dateto.valueOf() - datefrom.valueOf()) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = '';
  if (days > 0) {
    difference += (days === 1) ? `${days} day, ` : `${days} days, `;
  }

  if (hours > 0)
    difference += hours === 1 ? `${hours} hour, ` : `${hours} hours, `;

  difference += (minutes === 0 || hours === 1) ? `${minutes} minutes` : `${minutes} minutes`;

  return difference;
}

function readFileAsync(file: any) {
  return new Promise((resolve, reject) => {

    if (!file.target || !file.target.files?.length) {
      reject("No File Found");
    }
    else {
      let reader = new FileReader();

      var files = file.target.files, f = files?.[0];

      reader.onload = (e: any) => {
        resolve(e.target.result);
      };

      reader.onerror = reject;

      reader.readAsArrayBuffer((f as Blob));
    }

  });
}

export default {

  getDatesDifference: getDatesDifference,
  getUserPhoto: getUserPhoto,
  getControlType: getControlType,
  uniqueIdGenerator: UIGenerator.uniqueId,
  convertUTCDateToLocal: convertUTCDateToLocal,
  convertDateToCompanyFormat: convertDateToCompanyFormat,
  clearDefaultColumns: clearDefaultColumns,
  readFileAsync

}