import { useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from '@/Hook/hooks';
import { IFieldProps } from '@/UI/Interfaces/IComponent';
import { useDropzone } from 'react-dropzone';
import { IAttachment } from '@/UI/Interfaces/IBaseUI';
import { Icon } from 'AlignComponents';
import ReactTimeago from 'react-timeago';

const doc = require("@/Assets/Images/file_icon/file_doc_icon.png").default;
const img = require("@/Assets/Images/file_icon/file_img_icon.png").default;
const pdf = require("@/Assets/Images/file_icon/file_pdf_icon.png").default;
const ppt = require("@/Assets/Images/file_icon/file_ppt_icon.png").default;
const txt = require("@/Assets/Images/file_icon/file_txt_icon.png").default;
const xls = require("@/Assets/Images/file_icon/file_xls_icon.png").default;
const zip = require("@/Assets/Images/file_icon/file_zip_icon.png").default;
const unknown = require("@/Assets/Images/file_icon/file_unknown_icon.png").default;

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  justifyContent: "center"
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const toBase64 = (file: any) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

const Field = ({ config, fieldValueChange, fieldFocusOut, uniqueKey, enable, value, validateMandatory, hideLabel }: IFieldProps) => {
  const { fieldid } = config;
  const dispatch = useAppDispatch();
  // const [currentImage, setCurrentImage] = useState<any>(null);

  const objectId = useAppSelector((x) => x.EntryForm.activeObjectId);

  useEffect(() => {
    if (value === undefined) {
      callBaseMethod("");
    }
  }, [value]);

  const callBaseMethod = (value: any) => {
    fieldValueChange(fieldid, value);
  }

  const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }


  const calculateImageSize = (image: any) => {
    let y = 1;
    if (image.endsWith('==')) {
      y = 2
    }
    const x_size = (image.length * (3 / 4)) - y
    return Math.round(x_size / 1024)
  }

  const reduceImageFileSize = async (base64String: string) => {
    let resizedBase64 = await new Promise((resolve) => {
      let img = new Image()
      img.src = base64String
      img.onload = () => {
        let canvas = document.createElement('canvas')
        let width = img.width
        let height = img.height

        if (width > height) {
          if (width > 450) {
            height *= 450 / width
            width = 450
          }
        } else {
          if (height > 450) {
            width *= 450 / height
            height = 450
          }
        }
        canvas.width = width
        canvas.height = height
        let ctx = canvas.getContext('2d') as any
        ctx.drawImage(img, 0, 0, width, height)
        resolve(canvas.toDataURL()) // this will return base64 image results after resize
      }
    });
    return resizedBase64;
  }

  const onDrop = async (files: any) => {
    if (files.length > 0) {
      let file = files[0];
      var base64String: any = await toBase64(file).catch(e => Error(e));
      const imageSize = calculateImageSize(base64String);
      if (imageSize > 300) {
        const minifiedImage = await reduceImageFileSize(base64String);
        const minifiedImageSize = calculateImageSize(minifiedImage)
        base64String = minifiedImage;
      }
      base64String = base64String.replace(/^data:image\/[a-z]+;base64,/, "");
      callBaseMethod(base64String);
    }
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    isFocused,
  } = useDropzone({ onDrop, disabled: !enable });

  const style: any = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive || isFocused ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept,
    isFocused
  ]);
  //#endregion

  if (!config) return <></>;
  const id = objectId + (uniqueKey ? `-${uniqueKey}` : "") + "-" + fieldid;

  return (
    <>
      {
        !hideLabel ?
          <label id={"lb-" + id} htmlFor={"field-" + id} >
            {config.fieldlabel}
            {validateMandatory ? (<span>*</span>) : null}
          </label>
          : null
      }
      <div className="d-flex" style={{ height: '220px', width: '220px' }}>
        <div {...getRootProps({ style })}>
          <input disabled={!enable} id={"field-" + id} {...getInputProps()} accept="image/*" />
          {
            value ? (
              <img src={`data:image/jpeg;base64,${value}`} style={{ objectFit: "cover", width: "188px", height: "188px" }} />
            ) : !value && !enable ?
              <img src={require("@/Assets/Images/file_icon/no-picture.png").default} style={{ objectFit: "cover", width: "188px", height: "188px" }} />
              : <div className="text-center">
                <Icon icon="UploadCloud" className="upload-icon ms-auto" />
                <header>
                  {
                    isDragActive ? 'Drop the files here ...' : 'Drag and drop file here, or click to select file'
                  }
                </header>
              </div>

          }
        </div>
      </div>
    </>
  )

}
export default Field;