import * as wjCore from "@grapecity/wijmo";
import { CollectionViewNavigator } from "@grapecity/wijmo.react.input";
import { CollectionView, DataType, Globalize, Control } from "@grapecity/wijmo";
import { FlexGrid } from "@grapecity/wijmo.react.grid";
import { forwardRef, RefObject, useEffect, useImperativeHandle, useRef, useState } from "react";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { Selector } from "@grapecity/wijmo.grid.selector";
import BaseMethods from "AlignBaseUI/BaseMethods";
import { AllowSorting } from "@grapecity/wijmo.grid";
import { constant } from "@/Utils/constants";
import { EHelpType, IHelpHeader } from "@/Redux/Ducks/helpSlice";
import { EApprovalStatusType } from '@/Redux/Ducks/inboxSlice';
import { KeyValueObject } from "@/UI/Interfaces/IBaseUI";
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import { useAppDispatch, useAppSelector } from "@/Hook/hooks";
import classNames from "classnames";
import { Button } from "react-bootstrap";
import { setActiveObjectId } from "@/Redux/Ducks/entryFormSlice";

interface IProps {
  initializeGrid?: (GridControl: any, FilterControl: any) => void
  id: string;
  columns: Array<IHelpHeader>;
  data: Array<any>;
  multiSelect: boolean;
  paging?: boolean;
  allSearch?: boolean;
  multiSelectButton?: boolean;
  helpType: EHelpType;
  rowSelect?(selectedRow: any): void;
  extraParam?: KeyValueObject;
  modalBodyStyle?: React.CSSProperties;
  modalStyle?: React.CSSProperties;
  searchText?: string;
}

export type THelpTableHelpHandle = {
  gridRef: RefObject<any>
  gridFilterRef: RefObject<any>
  selectedRowRef: RefObject<any>
}

const TableHelpUI = forwardRef<THelpTableHelpHandle, IProps>((props, ref) => {
  const [gridHeader, setGridHeader] = useState<any>([]);
  const [gridRows, setGridRows] = useState<any>();

  const pageSizes = [25, 50, 100];
  const [pageSize, setPageSize] = useState<number>(25);
  const [totalRowsCount, setTotalRowsCount] = useState<number>(0);
  const [pageStartIndex, setPageStartIndex] = useState<number>(0);
  const [pageEndIndex, setPageEndIndex] = useState<number>(0);

  // const [lastSelectedRowsOnView, setLastSelectedRowsOnView] = useState([]);
  // const [selectedItems, setSelectedItems] = useState([]);

  const selectedItems = useRef<Array<any>>([]);
  const lastSelectedRowsOnView = useRef<Array<any>>([]);

  const [isSelectorRefUpdated, setSelectorRefStatus] = useState<boolean>(false);
  const [selectionComplete, setSelectionComplete] = useState(true);

  const [isRefUpdated, setRefStatus] = useState<boolean>(false);

  const dataFormat = useAppSelector(state => state.User.CompanyDataFormat);
  // const activeObjectId = useAppSelector((x) => x.Tab.activeTabKey);
  // const searchText = useAppSelector(state => state.Help[activeObjectId].loadedHelp?.shortHelpOptions?.searchText) || "";

  let gridRef: any = useRef();
  let filterRef: any = useRef();
  let gridSelectorRef: any = useRef();
  let gridSearchRef: any = useRef();

  useEffect(() => {
    setGridHeader(getColumns(props.columns));
    // if (gridRef.current)
    //   gridRef.current.dispose();
    // if (filterRef.current)
    //   filterRef.current.dispose();
  }, [props.columns]);

  useEffect(() => {
    const data = props.data.map((x) => {
      const row_id = BaseMethods.uniqueIdGenerator(JSON.stringify(x));
      return { ...x, row_id };
    });

    const option: KeyValueObject = {};

    if (props.paging) {
      option.pageSize = pageSize;
      option.pageChanged = (s: any, e: any) => {
        pageChangeHandler(s);
      }
      option.collectionChanged = (s: any) => {
        pageChangeHandler(s);
      }
      
    }

    const collectionRow = new CollectionView(data, option);
    pageChangeHandler(collectionRow);
    setGridRows(collectionRow);

  }, [props.data, isRefUpdated, pageSize]);

  useEffect(() => {
    if (gridRef.current) {
      const GridControl = gridRef.current.control;

      GridControl.loadedRows.addHandler((s: any, e: any) => {
        // if (selectionComplete) {
        s.rows.map((x: any) => {
          x.isSelected = !!selectedItems.current.find(
            (y: any) => y.dataItem.row_id == x.dataItem.row_id
          );
        });

        // if (findMatch(lastSelectedRowsOnView.map((x: any) => x.dataItem.row_id), s.rows.map((x: any) => x.dataItem.row_id)).not_match.length)
        lastSelectedRowsOnView.current = s.rows.filter(
          (x: any) => x.isSelected
        );
        // }
      });

      GridControl.copying.addHandler((s: any, e: any) => {
        e.cancel = true;
        const data = s.getCellData(s.selection.row, s.selection.col, true);
        wjCore.Clipboard.copy(data);
      });

      GridControl.cells.hostElement.addEventListener("dblclick", function (e: any) {
        var ht = GridControl.hitTest(e);
        if (ht.row != -1) {
          var ColID = GridControl.columns[ht.col];
          if (!ColID) return;

          if (!props.multiSelectButton)
            return;

          if (props.multiSelect) {
            props.rowSelect?.([GridControl.rows[ht.row].dataItem]);
          } else {
            props.rowSelect?.(GridControl.rows[ht.row].dataItem);
          }
        }
      });

      return () => {
        GridControl.loadedRows.removeHandler();
        GridControl.cells.hostElement.removeEventListener("dblclick", () => { });
      };
    }
  }, [isRefUpdated]);

  useEffect(() => {
    if (gridSelectorRef.current) {
      gridSelectorRef.current.itemChecked.addHandler((s: any, e: any) => {
        const GridControl = gridRef.current.control;

        const currentSelectedRowsOnView = GridControl.rows.filter(
          (x: any) => x.isSelected
        );

        const newSelectedRows = currentSelectedRowsOnView.filter(
          (x: any) =>
            lastSelectedRowsOnView.current.findIndex(
              (y: any) => y.dataItem.row_id == x.dataItem.row_id
            ) == -1
        );

        const DeSelectedRows = lastSelectedRowsOnView.current.filter(
          (x: any) =>
            currentSelectedRowsOnView.findIndex(
              (y: any) => y.dataItem.row_id == x.dataItem.row_id
            ) == -1
        );

        selectedItems.current = selectedItems.current.concat(newSelectedRows);
        selectedItems.current = selectedItems.current.filter(
          (x: any) =>
            DeSelectedRows.findIndex(
              (y: any) => y.dataItem.row_id == x.dataItem.row_id
            ) == -1
        );

        if (props.multiSelect && !props.multiSelectButton)
          props.rowSelect?.(selectedItems.current);

        lastSelectedRowsOnView.current = currentSelectedRowsOnView;
      });

      return () => {
        gridSelectorRef.current.itemChecked.removeHandler();
      };
    }
  }, [isSelectorRefUpdated]);

  useImperativeHandle(ref, () => ({
    gridRef: gridRef,
    gridFilterRef: filterRef,
    selectedRowRef: selectedItems
  }));

  const pageChangeHandler = (collectionRow: any) => {
    if (collectionRow.pageCount === 1) {
      setPageStartIndex(1);
      setPageEndIndex(collectionRow.totalItemCount);
      setTotalRowsCount(collectionRow.totalItemCount);
    }
    else {
      let start = collectionRow.pageSize * collectionRow.pageIndex;
      let end = start + collectionRow.pageSize - 1;
      if (end >= collectionRow.totalItemCount) {
        end -= (end % collectionRow.totalItemCount) + 1;
      }

      setPageStartIndex(start + 1);
      setPageEndIndex(end + 1);
      setTotalRowsCount(collectionRow.totalItemCount);
    }
  }

  const getColumns = (columns: Array<IHelpHeader>) => {
    var obj: any = columns.map((x) => {
      const header = x.ColumnText;
      const binding = x.ColumnID;
      let dataType = DataType.String;
      const width = 180;
      let format = null;
      let cssClass = "";
      let visible = binding == "prid" ? false : !x.ishidden;

      if (BaseMethods.getControlType(x.controltype) == "checkbox") {
        cssClass = "switch";
        dataType = DataType.Boolean;
      } else if (BaseMethods.getControlType(x.controltype) == "date") {
        format = dataFormat.find(y => y.fielddataformatno == x.fielddataformatno)?.fielddataformatmasking || constant.DefaultDateFormat;
        dataType = DataType.Date;
      } else if (BaseMethods.getControlType(x.controltype) == "time") {
        format = dataFormat.find(y => y.fielddataformatno == x.fielddataformatno)?.fielddataformatmasking || constant.DefaultTimeFormat;
        dataType = DataType.Date;
      } else if (BaseMethods.getControlType(x.controltype) == "number") {
        format = dataFormat.find(y => y.fielddataformatno == x.fielddataformatno)?.fielddataformatmasking || constant.DefaultNumber;
        dataType = DataType.Number;
      }

      if (x.ColumnID.isEqual("approvalhigheststatus", "approvaltime")) {
        visible = false;
        if (props.helpType.isEqual(EHelpType.DocumentHelp)) {
          if (props.extraParam && props.extraParam.hasApproval == true) {
            visible = true;
          }
        }
      }

      return {
        header,
        binding,
        visible,
        width,
        dataType,
        cssClass,
        format,
      };
    });

    obj.push({
      header: " ",
      binding: "endcol",
      width: "*",
      dataType: DataType.String,
      cssClass: "",
      format: "",
      visible: true,
      allowDragging: false,
      allowSorting: false
    });

    return obj;
  };

  const initGrid = (GridControl: any) => {

    if (props.allSearch) {
      let theSearch = gridSearchRef.current.control;

      filterRef.current.control.filterColumns = GridControl.columns.filter((x: any) => x.binding != 'endcol' && !props.columns.find((y: any) => y.ColumnID == x.binding)?.controltype?.isEqual('IMG')).map((x: any) => x.binding);
      theSearch.grid = GridControl;
      theSearch._getItemText = function (item: any) {
        let vals = [],
          cols = this._g.columns;
        for (let i = 0; i < cols.length; i++) {
          let col = cols[i],
            binding = col._binding;
          var colConfig = props.columns.find((x: any) => x.ColumnID == col.binding);

          if (col.visible && binding && !colConfig?.controltype?.isEqual('IMG')) {
            let val = binding.getValue(item);
            if (col.dataMap) {
              val = col.dataMap.getDisplayValue(val);
            }
            else {
              val = Globalize.format(val, col.format);
            }
            vals.push(val);
          }
        }
        return vals.join(" ");
      };
    }


    if (props.multiSelect) {
      gridSelectorRef.current = new Selector(GridControl);
      setSelectorRefStatus(true);
    }


    GridControl.formatItem.addHandler(function (s: any, e: any) {
      var col = s.columns[e.col];

      if (!col) return;

      if (e.panel == s.cells) {
        var colConfig = props.columns.find((x: any) => x.ColumnID == col.binding);
        var item = s.rows[e.row].dataItem;

        if (col.dataType == DataType.Date) {
          if (e.cell.innerHTML == "1900-01-01T00:00:00") {
            e.cell.innerHTML = "";
          }
          else {
            e.cell.innerHTML = BaseMethods.convertDateToCompanyFormat(item[col.binding], col.format);
          }
        }
        else if (col.binding.isEqual("approvalhigheststatus")) {
          let html;
          if (e.cell.innerHTML == EApprovalStatusType.Pending) {
            html = `<span class="bg-info badge">Approval In Process</span>`
          }
          else if (e.cell.innerHTML == EApprovalStatusType.Approve) {
            html = `<span class="bg-success badge">Approved</span>`
          }
          else if (e.cell.innerHTML == EApprovalStatusType.Reject) {
            html = `<span class="bg-danger badge">Rejected</span>`
          }
          else if (e.cell.innerHTML == EApprovalStatusType.Hold) {
            html = `<span class="bg-warning badge">Hold</span>`
          }
          else {
            html = `<span class="bg-primary badge">Not Initiated</span>`
          }

          e.cell.innerHTML = html
        }
        else if (col.binding.isEqual("approvalstatusno")) {
          let html;
          if (e.cell.innerHTML == EApprovalStatusType.PendingText) {
            html = `<span class="bg-info badge">${e.cell.innerHTML}</span>`
          }
          else if (e.cell.innerHTML == EApprovalStatusType.ApproveText) {
            html = `<span class="bg-success badge">${e.cell.innerHTML}</span>`
          }
          else if (e.cell.innerHTML == EApprovalStatusType.RejectText) {
            html = `<span class="bg-danger badge">${e.cell.innerHTML}</span>`
          }
          else if (e.cell.innerHTML == EApprovalStatusType.HoldText) {
            html = `<span class="bg-warning badge">${e.cell.innerHTML}</span>`
          }
          else {
            html = `<span class="bg-primary badge">${e.cell.innerHTML}</span>`
          }

          e.cell.innerHTML = html
        }
        else if (colConfig?.controltype.isEqual('IMG')) {
          if (e.cell.innerText) {
            var base64String = `data:image/jpeg;base64,${e.cell.innerText}`;
            (e.cell as HTMLElement).style.paddingTop = "0px";
            (e.cell as HTMLElement).style.paddingBottom = "0px";
            (e.cell as HTMLElement).style.textAlign = "center";
            (e.cell as HTMLElement).innerHTML = `<img src="${base64String}" class="rounded-circle thumb-xs me-1" />`;
          }
          else {
            (e.cell as HTMLElement).style.paddingTop = "0px";
            (e.cell as HTMLElement).style.paddingBottom = "0px";
            (e.cell as HTMLElement).style.textAlign = "center";
            (e.cell as HTMLElement).innerHTML = `<img src="${require("@/Assets/Images/file_icon/no-picture.png").default}" class="rounded-circle thumb-xs me-1" />`;
          }
        }
      }
    });

    if (props.initializeGrid)
      props.initializeGrid(GridControl, filterRef.current.control);
  };

  const updateRef = (e: any) => {
    gridRef.current = e;
    setRefStatus(true);
  };

  const selectMultiRow = () => {
    props.rowSelect?.(selectedItems.current.map((x: any) => x.dataItem));
  };

  const filter = (e: any, binding: string) => {
    if (gridRef.current?.control?.collectionView && e.text && e.text != "") {
      let filter = e.text.toLowerCase();
      gridRef.current.control.collectionView.filter = (item: any) => {
        return (
          filter.length == 0 || item[binding].toLowerCase().indexOf(filter) > -1
        );
      };
    } else {
      gridRef.current.control.collectionView.filter = null;
    }
  };

  const onPageSizeChange = (e: any) => {
    setPageSize(Number(e.target.value))
  }

  const modalBodyStyle = Object.assign({}, props.modalBodyStyle) || {};
  const modalStyle = Object.assign({}, props.modalStyle) || {};

  if (!modalStyle.height)
    modalStyle.height = "100%";

  if (!modalBodyStyle.height)
    modalBodyStyle.height = "100%";

  if (props.multiSelect)
    modalBodyStyle.height = `calc(${modalBodyStyle.height} - 58px)`;

  if (gridHeader.length)
    return (
      <>
        <div style={modalStyle}>
          <div className="modal-body" style={modalBodyStyle}>
            {
              props.allSearch ?
                <div className="search-help" >
                  <FlexGridSearch ref={gridSearchRef} placeholder="Search" /*text={searchText}*/ />
                </div>
                : null
            }

            <FlexGrid
              id={props.id}
              ref={updateRef}
              initialized={initGrid}
              isReadOnly={true}
              alternatingRowStep={0}
              itemsSource={gridRows}
              selectionMode="Row"
              headersVisibility={props.multiSelect ? "All" : "Column"}
              columns={gridHeader}
              autoSearch={true}
              allowSorting={AllowSorting.SingleColumn}
              multiSelect={false}
              stickyHeaders={false}
              keyActionTab={2}
              autoGenerateColumns={false}
              autoClipboard={true}
            >
              <FlexGridFilter
                // filterColumns={gridHeader.filter((e: any) => e.binding != "endcol").map((e: any) => e.binding)}
                ref={filterRef}
              />
            </FlexGrid>
            {
              props.paging ?
                <div className="grid-pager">
                  {
                    gridRef.current?.control?.collectionView ? (
                      <>
                        {
                          totalRowsCount === 0 ? (
                            <h6 className="wj-count">
                              No Data Found
                            </h6>
                          ) : (
                            <h6 className="wj-count">
                              Showing {pageStartIndex} to {pageEndIndex} of {totalRowsCount} entries
                            </h6>
                          )
                        }
                      </>
                    ) : null
                  }
                  <div className="wj-pagesize wj-control">
                    <div className="wj-template">
                      <div className="wj-input">
                        <div className="wj-input-group">
                          Show
                          <select className="wj-form-control" defaultValue={pageSize} onChange={onPageSizeChange}>
                            {
                              pageSizes.map((x, i) => (
                                <option key={x} value={x}>{x}</option>
                              ))
                            }
                          </select>
                          Entries
                        </div>
                      </div>
                    </div>
                  </div>
                  <CollectionViewNavigator
                    byPage={true}
                    headerFormat="Page {currentPage:n0} of {pageCount:n0}"
                    cv={gridRows}
                  />
                </div>
                : null
            }
          </div>
        </div>
        {props.multiSelect && props.multiSelectButton ? (
          <div className="modal-footer">
            <div>
              <Button variant="primary" onClick={selectMultiRow}>
                Select
              </Button>
            </div>
          </div>
        ) : null}
      </>
    );
  else return null;
});

TableHelpUI.defaultProps = {
  multiSelectButton: true,
  paging: true,
  allSearch: true,
}

export { TableHelpUI };

