import React from "react";
import { useAppSelector } from "@/Hook/hooks";

import { EActivity } from "@/UI/Interfaces/IBaseUI";
import { AlignTab, ControlTranslation, FormDimension } from "AlignComponents";
import { toggleCommentsModal } from "@/Redux/Ducks/entryFormSlice"
import BaseMethods from "AlignBaseUI/BaseMethods";
import { constant } from "@/Utils/constants";
import TimeAgo from 'react-timeago';
import { Card, CardGroup } from "react-bootstrap";

const ScreenAdministration = () => {
  const model = useAppSelector(x => x.EntryForm.objects[x.EntryForm.activeObjectId].model);
  const activity = useAppSelector(x => x.EntryForm.objects[x.EntryForm.activeObjectId].activity);

  // let visibleFormDimension = useAppSelector(state => {
  //   const screenConfig = state.EntryForm.objects[state.EntryForm.activeObjectId].ScreenConfig;

  //   return screenConfig.CompanyDimensionFields && screenConfig.CompanyDimensionFields.length && screenConfig.TcodeConfiguration.formdimension;
  // });

  let version = 0;
  let revision = 0;

  let createuser = "-";
  let createdate = "";
  let createterminal = "-";
  let createip = "-";

  let changeuser = "-";
  let changedate = "";
  let changeterminal = "-";
  let changeip = "-";

  if (activity != EActivity.Create && model) {
    version = model.version;
    revision = model.revision;

    createuser = model.createuser;
    createdate = model.createdatetime;
    createterminal = model.createterminal;
    createip = model.createip;

    changeuser = model.changeuser || "-";
    changedate = new Date(model.changedatetime).toJSON() == new Date("1900-01-01T00:00:00").toJSON() ? "" : model.changedatetime;
    changeterminal = model.changeterminal || "-";
    changeip = model.changeip || "-";
  }

  return (
    <>
      {/* {
        visibleFormDimension ?
          <AlignTab.Tab system={true} controlId="lblfinancialdimension">
            <FormDimension />
          </AlignTab.Tab>
          : null
      } */}

      <AlignTab.Tab system={true} controlId="lbladministration">
        <CardGroup className="mb-3">
          <Card>
            <Card.Header>
              <h6 className="fw-bold fs-13 m-0"><ControlTranslation system={true} controlId="lblrecordlog" /></h6>
            </Card.Header>
            <Card.Body className="fs-13">
              <div className="row mb-3">
                <div className="d-flex col-6">
                  <b><ControlTranslation system={true} controlId="lblversion" />:</b>
                  <div>&nbsp;&nbsp;&nbsp;&nbsp;{version}</div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="d-flex col-6">
                  <b><ControlTranslation system={true} controlId="lblrevision" />:</b>
                  <div>&nbsp;&nbsp;&nbsp;&nbsp;{revision}</div>
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>
              <h6 className="fw-bold fs-13 m-0"><ControlTranslation system={true} controlId="lblcreatelog" /></h6>
            </Card.Header>
            <Card.Body className="fs-13">
              <div className="row mb-3">
                <div className="d-flex col-6">
                  <b><ControlTranslation system={true} controlId="lblcreateuser" />:</b>
                  <div>&nbsp;&nbsp;&nbsp;&nbsp;{createuser}</div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="d-flex col-6">
                  <b><ControlTranslation system={true} controlId="lblcreatedate" />:</b>
                  <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {
                      createdate ?
                        <>
                          {BaseMethods.convertDateToCompanyFormat(createdate, constant.DefaultDateFormat)}<br />
                          <TimeAgo date={createdate} />
                        </>
                        : "-"
                    }
                  </div>
                </div>
                <div className="d-flex col-6">
                  <b><ControlTranslation system={true} controlId="lblcreatetime" />:</b>
                  <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {
                      createdate ?
                        BaseMethods.convertDateToCompanyFormat(createdate, constant.DefaultTimeFormat)
                        : "-"
                    }
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="d-flex col-6">
                  <b><ControlTranslation system={true} controlId="lblcreateterminal" />:</b>
                  <div>&nbsp;&nbsp;&nbsp;&nbsp;{createterminal}</div>
                </div>
                <div className="d-flex col-6">
                  <b><ControlTranslation system={true} controlId="lblcreateip" />:</b>
                  <div>&nbsp;&nbsp;&nbsp;&nbsp;{createip}</div>
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>
              <h6 className="fw-bold fs-13 m-0"><ControlTranslation system={true} controlId="lblchangelog" /></h6>
            </Card.Header>
            <Card.Body className="fs-13">
              <div className="row mb-3">
                <div className="d-flex col-6">
                  <b><ControlTranslation system={true} controlId="lblchangeuser" />:</b>
                  <div>&nbsp;&nbsp;&nbsp;&nbsp;{changeuser}</div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="d-flex col-6">
                  <b><ControlTranslation system={true} controlId="lblchangedate" />:</b>
                  <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {
                      changedate ?
                        <>
                          {BaseMethods.convertDateToCompanyFormat(changedate, constant.DefaultDateFormat)}<br />
                          <TimeAgo date={changedate} />
                        </>
                        : "-"
                    }
                  </div>
                </div>
                <div className="d-flex col-6">
                  <b><ControlTranslation system={true} controlId="lblchangetime" />:</b>
                  <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {
                      changedate ?
                        BaseMethods.convertDateToCompanyFormat(changedate, constant.DefaultTimeFormat)
                        : "-"
                    }
                    <br />
                  </div>
                </div>
              </div>
              
              <div className="row mb-3">
                <div className="d-flex col-6">
                  <b><ControlTranslation system={true} controlId="lblchangeterminal" />:</b>
                  <div>&nbsp;&nbsp;&nbsp;&nbsp;{changeterminal}</div>
                </div>
                <div className="d-flex col-6">
                  <b><ControlTranslation system={true} controlId="lblchangeip" />:</b>
                  <div>&nbsp;&nbsp;&nbsp;&nbsp;{changeip}</div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </CardGroup>
      </AlignTab.Tab >
    </>
  );
};

export default ScreenAdministration;
